import * as React from 'react';

import BreadcrumbComponent from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Creator from '../Creator';

class Create extends React.Component<any, any> {
  componentDidUpdate(nextProps) {
    const { history, shouldRouteBack, route } = this.props;
    if (shouldRouteBack) {
      history.push('/users');
    }
  }
  handleSave = body => {
    const { createWithPermission } = this.props;
    createWithPermission(body);
  };
  render() {
    return (
      <>
        <BreadcrumbComponent>
          Home <KeyboardArrowRight /> Users <KeyboardArrowRight /> Create
        </BreadcrumbComponent>
        <ContentPage>
          <Creator onSave={this.handleSave} />
        </ContentPage>
      </>
    );
  }
}

export default Create;
