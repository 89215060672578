import * as React from 'react';
import { Popover, List, ListItem, TextField, CircularProgress, InputAdornment } from '@material-ui/core';

import ArrowBack from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import SwipeableViews from 'react-swipeable-views';
import { Scrollbars } from 'react-custom-scrollbars';
import { findDOMNode } from 'react-dom';
import apisService from 'src/shared/apis';
import { SelectDatabaseProps, SelectDatabaseState } from './models';
import Styled from './Styled';

class SelectDatabase extends React.Component<SelectDatabaseProps, SelectDatabaseState> {
  state: SelectDatabaseState = {
    filterTablesText: '',
    showTablesFilter: false,
    showDatabasesFilter: false,
    selectedDatabase: undefined,
    selectedTable: undefined,
    tables: [],
    isLoadingTables: true,
  };

  componentDidMount() {
    this.reloadDataSourceIfNeeded();
    const elm = findDOMNode(this.refs.controlButtonWrap);
    this.setState({ isOpen: this.props.open, anchorEl: elm });
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== undefined && prevProps.open && !this.props.open) {
      this.setState({ isOpen: false, anchorEl: null });
    }
  }

  componentWillReceiveProps(nextProps: SelectDatabaseProps) {
    if (nextProps.databasesList.length > 5) {
      this.setState({ showDatabasesFilter: true });
    }
    const current = {
      db: this.state.selectedDatabase,
      table: this.state.selectedTable,
    };
    if (current.db && nextProps.selectedDatabase && current.db.id != nextProps.selectedDatabase.id) {
      this.setState({ selectedDatabase: nextProps.selectedDatabase });
    }
    if (current.table && nextProps.selectedTable && current.table.id != nextProps.selectedTable.id) {
      this.setState({ selectedTable: nextProps.selectedTable });
    }
  }
  togglePopover = e => {
    this.setState({ isOpen: true, anchorEl: e.target });
    this.reloadDataSourceIfNeeded();
  };

  reloadDataSourceIfNeeded() {
    if (!this.props.databasesList.length) {
      ///ToDo:  load databases !
      this.props.getAllDatabases();
    }
  }

  handleDbFilterChange = e => {
    let v = e.target.value;
    this.setState({ filterDbText: v });
  };

  onSelectDataBase = db => e => {
    this.setState({ isLoadingTables: true, tables: [], selectedDatabase: db, filterDbText: '' });
    apisService.getDatabasePublicTables(db.id).then(
      tables => {
        if ((tables || []).length > 2) {
          this.setState({ showTablesFilter: true });
        }
        this.setState({ tables: tables, isLoadingTables: false });
        this.props.onSelectDb(db, tables);
      },
      e => {
        this.setState({ isLoadingTables: false });
      }
    );
  };

  render() {
    const { filterTablesText, filterDbText, selectedDatabase, showDatabasesFilter } = this.state;
    // const isOpen = this.state.isOpen || this.props.isOpen;
    const { classes } = this.props;
    let dataBasesSource = this.props.databasesList;
    if (filterDbText) {
      let filter = filterDbText.toLowerCase();
      dataBasesSource = dataBasesSource.filter(d => d.displayName && d.displayName.toLowerCase().includes(filter));
    }
    let tables = this.state.tables || [];
    if (filterTablesText) {
      let filter = filterTablesText.toLowerCase();
      tables = tables.filter(d => d.displayName && d.displayName.toLowerCase().includes(filter));
    }
    return (
      <>
        <div onClick={this.togglePopover} ref='controlButtonWrap'>
          {this.props.children}
        </div>
        <Popover
          open={Boolean(this.state.isOpen)}
          anchorEl={this.refs.controlButtonWrap as any}
          onClose={this.props.onClose}
          onBackdropClick={e => this.setState({ isOpen: false })}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.slidingWrap}>
            <SwipeableViews axis={'x'} index={Boolean(selectedDatabase) ? 1 : 0}>
              <div className={classes.slide}>
                <div className={classes.stepHeader}>Select Database</div>
                <div className={classes.slideContentWrap}>
                  {this.props.isLoadingList && (
                    <div className='loading'>
                      {' '}
                      <CircularProgress /> Loading Databases...{' '}
                    </div>
                  )}
                  {showDatabasesFilter && (
                    <TextField
                      type='search'
                      fullWidth
                      value={this.state.filterDbText}
                      onChange={this.handleDbFilterChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  <Scrollbars className={classes.scrollBar}>
                    <List component='nav'>
                      {dataBasesSource.map((databaseItem, i) => (
                        <ListItem key={i} button onClick={this.onSelectDataBase(databaseItem)}>
                          {' '}
                          {databaseItem.displayName}{' '}
                        </ListItem>
                      ))}
                      {(!this.props.isLoadingList && !dataBasesSource) ||
                        (!dataBasesSource.length && <ListItem button> No Items </ListItem>)}
                    </List>
                  </Scrollbars>
                </div>
              </div>
              <div className={classes.slide}>
                <div
                  className={classes.stepHeader}
                  onClick={e => {
                    this.setState({ selectedDatabase: undefined });
                  }}
                >
                  <ArrowBack /> &nbsp; {selectedDatabase && selectedDatabase.displayName}
                </div>
                <div className={classes.slideContentWrap}>
                  {this.state.showTablesFilter && (
                    <TextField
                      type='search'
                      fullWidth
                      value={this.state.filterTablesText}
                      onChange={e => {
                        let v = e.target.value;
                        this.setState({ filterTablesText: v });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  <Scrollbars className={classes.scrollBar}>
                    <List component='nav'>
                      {this.state.isLoadingTables && (
                        <div className='loading'>
                          {' '}
                          <CircularProgress /> Loading Tables...{' '}
                        </div>
                      )}
                      {tables.map((table, i) => (
                        <ListItem
                          key={table.id || i}
                          button
                          onClick={e => {
                            this.props.onSelectTable(table);
                            this.setState({ filterTablesText: '', selectedDatabase: undefined, isOpen: false });
                          }}
                        >
                          {' '}
                          {table.displayName}{' '}
                        </ListItem>
                      ))}
                      {!tables || (!tables.length && <ListItem button> No Items </ListItem>)}
                    </List>
                  </Scrollbars>
                </div>
              </div>
            </SwipeableViews>
          </div>
        </Popover>
      </>
    );
  }
}

export default Styled(SelectDatabase);
