import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import style from './PermissionStyle';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Scrollbars } from 'react-custom-scrollbars';
import { USER_ROLES } from 'src/shared/models/USER_ROLES';

interface item {
  category: string;
  createdBy: boolean;
  createdDate: any;
  description: string;
  id: number;
  name: string;
  predefined: boolean;
}
interface permissions {
  groupName: string;
  items: Array<item>;
}

interface IProps {
  classes: any;
  onChange: (e: any) => void;
  isEdit?: boolean;
  permissions: Array<permissions>;
  userRoles?: Array<string>;
  userPermissions: Array<string>;
  permissionsLoader: boolean;
  userPermissionsLoader: boolean;
}
const defaultPermissions = ['question.view', 'dashboard.view'];
const Permissions = (props: IProps) => {
  const { classes, onChange, isEdit = false, permissions, userPermissions, permissionsLoader, userPermissionsLoader, userRoles } = props;
  const [checked, setChecked] = React.useState([] as Array<string>);

  React.useEffect(() => {
    const initialPermissions = defaultPermissions.slice();
    onChange(initialPermissions);
    setChecked(initialPermissions);
  }, []);

  React.useEffect(
    () => {
      if (isEdit) {
        let cloned = userPermissions || [];
        onChange(cloned);
        setChecked(cloned);
      }
    },
    [userPermissions]
  );

  React.useEffect(
    () => {
      //to  reset permissions when ever user role changed
      if (userRoles && userRoles.includes(USER_ROLES.USER)) {
        const permissions = checked.filter(p => !p.toLowerCase().startsWith('user.'));
        setChecked(permissions);
        onChange(permissions);
      }
    },
    [userRoles]
  );

  const handleToggle = value => {
    let clone = checked.slice();
    if (clone.includes(value)) {
      clone = clone.filter(c => c != value);
    } else {
      clone.push(value);
    }
    setChecked(clone);
    onChange(clone);
  };

  const handleToggleAll = group => {
    const shouldBeChecked = !isGroupChecked(group);
    const items = group.items;
    const clone = checked.slice();
    items.forEach((item: item) => {
      if (shouldBeChecked) {
        if (!isItemChecked(item)) {
          clone.push(item.name);
        }
      } else {
        const itemIndex = clone.indexOf(item.name);
        if (itemIndex > -1) {
          if (!defaultPermissions.includes(item.name)) {
            clone.splice(itemIndex, 1);
          }
        }
      }
    });
    setChecked(clone);
    onChange(clone);
  };

  function isGroupChecked(group): boolean {
    const groupCheckedItems = group.items.filter(item => isItemChecked(item));
    return groupCheckedItems.length == group.items.length;
  }
  function isItemChecked(item: item): boolean {
    return checked.includes(item.name);
  }

  return (
    <div className={classes.container}>
      <Scrollbars>
        {permissionsLoader && <CircularProgress size={30} color='primary' style={{ margin: 20 }} />}

        {permissions
          .filter(p => {
            if (props.userRoles && props.userRoles.includes(USER_ROLES.USER)) {
              return p.groupName.toLowerCase() !== 'user';
            }
            return true;
          })
          .map(permission => (
            <div key={permission.groupName} className={classes.group}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      indeterminate={userPermissionsLoader}
                      color='primary'
                      checked={isGroupChecked(permission)}
                      onChange={e => handleToggleAll(permission)}
                    />
                  }
                  label={
                    <Typography variant='h6'>
                      {permission.groupName} {userPermissionsLoader && <CircularProgress size={20} color='primary' />}
                    </Typography>
                  }
                />
              </div>

              <div className={classes.itemWrapper}>
                {permission.items.map((item: any) => {
                  return (
                    <FormControlLabel
                      className={classes.item}
                      key={`item-${item.id}`}
                      control={
                        <Checkbox
                          indeterminate={userPermissionsLoader}
                          color='primary'
                          classes={{ root: classes.root, disabled: classes.disabled }}
                          disabled={defaultPermissions.includes(item.name)}
                          checked={isItemChecked(item)}
                          onChange={e => handleToggle(item.name)}
                        />
                      }
                      label={item.description.replace(permission.groupName, '')}
                    />
                  );
                })}
              </div>
            </div>
          ))}
      </Scrollbars>
    </div>
  );
};

const styled = withStyles(style)(Permissions);
export default styled;
