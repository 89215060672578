export enum OiFunction {
  NONE = 'NONE',
  RAW_DATA = 'ROW_DATA',
  ROW_COUNT = 'COUNT',
  STD_DEV = 'STD_DEV',
  UNIQUE_COUNT = 'UNIQUE_COUNT',
  UNIQUE_VALUES = 'UNIQUE_VALUES',
  DATE_DIFF = 'DATE_DIFF',
  AVG_DATE_DIFF = 'AVG_DATE_DIFF',
  MIN = 'MIN',
  MAX = 'MAX',
  SUM = 'SUM',
  AVG = 'AVG',
  DATE_BY_DAY = 'DATE_BY_DAY',
  DATE_BY_MONTH = 'DATE_BY_MONTH',
  DATE_BY_YEAR = 'DATE_BY_YEAR',
  DATE_BY_QUARTER = 'DATE_BY_QUARTER',
}
