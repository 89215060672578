import * as React from 'react';
import Delete from '@material-ui/icons/Delete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Tooltip, withStyles } from '@material-ui/core';
import OiIcon from 'src/assets/icons';
import OiIconButton from 'src/components/OiIconButton';
import { QueryCalculatedColumnItem } from 'src/modules/questions/studio/models';
import { Colors } from 'src/shared/theme';
import TitleInput from '../TitleInput';

interface IProps {
  classes: any;
  expanded: boolean;
  model: QueryCalculatedColumnItem;
  toggleExpansion: (e?: any) => any;
  onPinChanged: (e?: any) => any;
  onDelete: (e?: any) => any;
  onTitleChanged: (title?: string) => any;
}
function ExpressionListItemHeader({ classes, toggleExpansion, onPinChanged, onTitleChanged, onDelete, expanded, model, ...props }: IProps) {
  const { displayName } = model;
  return (
    <div className={classes.headingItem}>
      <OiIconButton onClick={toggleExpansion}> {expanded ? <ExpandLess /> : <ExpandMore />} </OiIconButton>
      <Tooltip title='Pin column'>
        <OiIconButton onClick={onPinChanged}>
          <OiIcon viewBox='0 0 24 24' path={'Pin'} color={model.pin ? '#048edd' : '#a3a3a3'} />
        </OiIconButton>
      </Tooltip>
      <TitleInput onTitleChanged={v => onTitleChanged(v || '')} value={displayName} />
      <Tooltip title='Delete column'>
        <OiIconButton onClick={onDelete}>
          <Delete />
        </OiIconButton>
      </Tooltip>
    </div>
  );
}
export default withStyles({
  headingItem: {
    display: 'flex',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleInput: {
    border: 'none',
    height: 'auto',
    color: Colors.brandDarkGrey,
    fontSize: 16,
  },
})(ExpressionListItemHeader);
