import { REDUCER_NAME } from './constant';

export const GET_DB = `${REDUCER_NAME}/GET`;
export const CREATE_DB = `${REDUCER_NAME}/CREATE`;
export const DELETE_DB = `${REDUCER_NAME}/DELETE`;
export const SHARE_DB = `${REDUCER_NAME}/SHARE_DB`;
// export const SHARE_TABLE = `${REDUCER_NAME}/SHARE_TABLE`;

export default {
  GET_DB,
  CREATE_DB,
  DELETE_DB,
  SHARE_DB,
  // SHARE_TABLE
};
