import { useState, useEffect } from 'react';
import { ShareableUser } from 'src/shared/models/ShareableUser';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';
import toastr from 'src/components/toastr';
import discoveryApis from 'src/shared/apis/discoveryApis';
import { SQLTable } from 'src/shared/models/SqlTable';
import { DatabaseAccessListDto } from 'src/shared/models/DatabaseAccessListDto';

export default function useLoadDbTablesAcl(dbId) {
  const [tablesAcl, setTablesAcl] = useState({
    tables: [],
    usernames: [],
  } as DatabaseAccessListDto);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      setIsLoading(true);
      discoveryApis.getDatabaseAccessList(dbId).then(
        tablesAcl => {
          setIsLoading(false);
          setTablesAcl(tablesAcl);
        },
        () => {
          setIsLoading(false);
          toastr.error('Error loading tables access list of db ' + dbId);
        }
      );
    },
    [dbId]
  );

  return [tablesAcl, isLoading];
}
