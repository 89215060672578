//Note: this file is imported by webpack.config.js

import { Lexer, extendToken } from 'chevrotain';

export const VALID_OPERATORS = new Set(['+', '-', '*', '/']);
const t = t => t;
export const VALID_AGGREGATIONS = new Map(
  Object.entries({
    // "cum-count": t`CumulativeCount`,
    // "cum-sum": t`CumulativeSum`,
    // stddev: t`StandardDeviation`,
    count: 'Count',
    distinct: 'Distinct',
    sum: 'Sum',
    avg: 'Average',
    min: 'Min',
    max: 'Max',
  })
);

export const NULLARY_AGGREGATIONS = []; //"cum-count"
export const UNARY_AGGREGATIONS = [
  'count',
  // "cum-sum",
  'distinct',
  'sum',
  // "stddev",
  'avg',
  'min',
  'max',
];

export const AdditiveOperator = extendToken('AdditiveOperator', Lexer.NA);
export const Plus = extendToken('Plus', /\+/, AdditiveOperator);
export const Minus = extendToken('Minus', /-/, AdditiveOperator);

export const MultiplicativeOperator = extendToken('MultiplicativeOperator', Lexer.NA);
export const Multi = extendToken('Multi', /\*/, MultiplicativeOperator);
export const Div = extendToken('Div', /\//, MultiplicativeOperator);

export const Aggregation = extendToken('Aggregation', Lexer.NA);

export const NullaryAggregation = extendToken('NullaryAggregation', Aggregation);
const nullaryAggregationTokens = NULLARY_AGGREGATIONS.map(short =>
  extendToken(VALID_AGGREGATIONS.get(short)!, new RegExp(VALID_AGGREGATIONS.get(short)!, 'i'), NullaryAggregation)
);

export const UnaryAggregation = extendToken('UnaryAggregation', Aggregation);
const unaryAggregationTokens = UNARY_AGGREGATIONS.map(short =>
  extendToken(VALID_AGGREGATIONS.get(short)!, new RegExp(VALID_AGGREGATIONS.get(short)!, 'i'), UnaryAggregation)
);

export const Identifier = extendToken('Identifier', /\w+/);
export const NumberLiteral = extendToken('NumberLiteral', /-?(0|[1-9]\d*)(\.\d*)?([eE][+-]?\d+)?/);
export const StringLiteral = extendToken('StringLiteral', /"(?:[^\\"]+|\\(?:[bfnrtv"\\/]|u[0-9a-fA-F]{4}))*"/);

export const Comma = extendToken('Comma', /,/);
Comma.LABEL = 'comma';

export const ColumnName = extendToken('ColumnName', /[a-zA-Z][a-zA-Z1-9_\-\s]*\w/);
ColumnName.LABEL = 'ColumnName';

export const LParen = extendToken('LParen', /\(/);
LParen.LABEL = 'opening parenthesis';

export const RParen = extendToken('RParen', /\)/);
RParen.LABEL = 'closing parenthesis';

export const PlainValue = extendToken('PlainValue', /[0-9]+/);
PlainValue.LABEL = 'Plain Vlaue';

export const aggsPattern = '(?:max)|(?:min)|(?:sum)|(?:count)|(?:avg)';
export const lParPattern = '\\(';
export const rParPattern = '\\)';
export const columnNamePattern = '\\w[a-zA-Z_1-9\\s]*\\w';
export const aggWithColPattern = `(${aggsPattern})\\s*${lParPattern}\\s*(${columnNamePattern})*\\s*${rParPattern}`;

export const AggregationWithColumnName = extendToken('AggregationWithColumnName', new RegExp(aggWithColPattern, 'i'));
AggregationWithColumnName.LABEL = 'AggregationWithColumnName';

export const WhiteSpace = extendToken('WhiteSpace', /\s+/);
WhiteSpace.GROUP = Lexer.SKIPPED;

// whitespace is normally very common so it is placed first to speed up the lexer
export const allTokens = [
  WhiteSpace,
  LParen,
  RParen,
  Comma,
  Plus,
  Minus,
  Multi,
  Div,
  AdditiveOperator,
  MultiplicativeOperator,
  // Aggregation,
  // NullaryAggregation,
  // ...nullaryAggregationTokens,
  // UnaryAggregation,
  // ...unaryAggregationTokens,
  AggregationWithColumnName,
  ColumnName,
  // StringLiteral,
  NumberLiteral,
  Identifier,
];
