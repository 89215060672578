import { Toolbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import CustomSearch from 'src/components/CustomSearch';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import GDAFormModal from '../GDAFilterForm/GDAFormModal';
interface IProps {
  selectedItems: any[];
  search: string;
  ////////
  onAdd: (e: any) => any;
  handleDeleteSelected: (e: any) => any;
  handleSearch: (e: any) => any;
}
function GDAListToolbar(props: IProps) {
  const { selectedItems, search } = props;
  const { onAdd, handleDeleteSelected, handleSearch } = props;
  return (
    <Toolbar>
      <GDAFormModal
        onSave={onAdd}
        renderOpener={open => (
          <TooltipedIconButton tooltip={'Add new Filter'} rounded aria-label='Add'>
            <AddIcon />
          </TooltipedIconButton>
        )}
      />
      <TooltipedIconButton
        tooltip={'Delete selected filters'}
        disabled={!(selectedItems.length > 0)}
        rounded
        aria-label='remove'
        onClick={handleDeleteSelected}
      >
        <DeleteIcon />
      </TooltipedIconButton>
      <div className='flex' />

      <CustomSearch onChange={e => handleSearch((e.target.value || '').toLowerCase())} value={search} placeholder='Search' />
    </Toolbar>
  );
}
export default GDAListToolbar;
