import { StyleRulesCallback, Theme } from '@material-ui/core/styles';

const style: StyleRulesCallback = (theme: Theme) => ({
  textField: {
    maxWidth: '500px',
    width: '100%',
    margin: '15px 15px 15px 0px',
    // display: 'block',
  },
  errorDiv: {
    marginTop: '15px',
  },
  dividerErrorColor: {
    backgroundColor: `${theme.palette.error.main} `,
  },
});
export default style;
