import * as React from 'react';

import './style.scss';
import { DetailsProps } from './index';
import { Scrollbars } from 'react-custom-scrollbars';
import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import {
  List,
  ListItem,
  Grid,
  Card,
  Button,
  CardContent,
  CardHeader,
  Typography,
  CircularProgress,
  TextField,
  Paper,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import InsertChartIcon from '@material-ui/icons/InsertChart';

import OiIconButton from 'src/components/OiIconButton';
import ActionFooter from 'src/layout/ActionFooter';

import { saveTableService, refreshService, getRefreshService } from 'modules/databases/data-models/data-model.service';
import SideControlButtons from 'modules/databases/data-models/Side-Control-Buttons';
import ColumnFormComponent from 'modules/databases/data-models/Column-Form';
import ColumnStatistics from 'modules/databases/data-models/Column-Statistics';
import EditTableDialog from 'modules/databases/data-models/Edit-Table-Dialog';
import toastr from 'src/components/toastr';
import ShareWithUsersPopup from 'src/components/ShareUsersPopup';

export interface DetailsState {
  db: any;
  table: any;
  column: any;
  tables: Array<any>;
  databases: Array<any>;
  columns: Array<any>;
  isLoadingDB: boolean;
  isLoadingTables: boolean;
  isLoadingColumns: Boolean;
  filterColumns: string;
  isOpenDialog: Boolean;
  loadingSave: Boolean;
  isForeign: Boolean;
  isSpecial: Boolean;
  isFilter: Boolean;
  foreign: Array<any>;
  error: String;
  isLoadRefresh: Boolean;
  isRefreshSchedule: Boolean;
  collapse: Boolean;
}

export default class DataModelStudio extends React.Component<DetailsProps, DetailsState> {
  state = {
    databases: [],
    db: {} as any,
    table: {} as any,
    tables: [],
    column: {} as any,
    columns: [],
    filterColumns: '',
    isLoadingDB: true,
    isLoadingTables: true,
    isLoadingColumns: true,
    isOpenDialog: false,
    loadingSave: false,
    isForeign: false,
    isSpecial: false,
    isFilter: false,
    isLoadRefresh: false,
    isRefreshSchedule: false,
    foreign: [],
    error: '',
    collapse: false,
  };

  componentDidMount() {
    this.props.databasesList && this.props.databasesList.length > 0
      ? this.setState({ databases: this.props.databasesList })
      : this.props.getAllDatabases();
  }

  componentWillUpdate(nextProps) {
    if (this.props != nextProps && nextProps.databasesList.length) {
      this.setState({ databases: nextProps.databasesList });
    }
  }
  componentDidCatch(err) {
    this.setState({ error: err });
  }

  handleSelectTable = data => {
    const foreignList = data.foreign.reduce((cols, t) => {
      if (t.columns.length) {
        const columns = t.columns.map(c => ({
          ...c,
          tableDisplayName: t.table,
        }));
        return cols.concat(columns);
      }
      return cols;
    }, []);
    this.setState({
      table: data.table,
      foreign: foreignList || [],
      columns: data.columns || [],
      column: data.columns[0] || {},
      isLoadingColumns: false,
      isLoadingTables: false,
    });
  };

  selectColumn(column) {
    this.setState({
      column: column,
      isForeign: column.fkSqlColumnId != null ? true : false,
      isSpecial: column.valueDisplayFormat != null ? true : false,
      isFilter: column.filterComponentType != null ? true : false,
      isRefreshSchedule: column.analysisSyncSchedule != null ? true : false,
    });
  }

  handleEditTableOpen() {
    this.setState({ isOpenDialog: true });
  }

  handleSave = e => {
    let props = this.props as any;
    let closeLoadingToastr = toastr.loading('Saving database model');

    saveTableService(this.state.table)
      .then(response => {
        closeLoadingToastr();
        toastr.success('Model updated successfully');
        this.setState({ loadingSave: true });
        props.history.push('/databases');
      })
      .catch(err => {
        closeLoadingToastr();
        toastr.error('Failed to update database model');
        this.setState({ error: err });
        console.log(err);
      });
  };

  handleCancel = e => {
    let props = this.props as any;
    props.history.push('/databases');
  };

  get VisibleColumn() {
    let couter = 0;
    this.state.columns.forEach((column: any) => {
      if (column.active) {
        couter++;
      }
    });
    return couter;
  }

  refresh(col) {
    this.setState({ isLoadRefresh: true });
    let closeLoadingToastr = toastr.loading('Refreshing database analysis');

    refreshService(col.id)
      .then(response => {
        closeLoadingToastr();
        toastr.success('Database anaylsis refreshed successfully');
        getRefreshService(col.id)
          .then(analysis => {
            let column = { ...this.state.column };
            column.analyses = [analysis];
            this.setState({ column: column, isLoadRefresh: false });
          })
          .catch(error => {
            console.log(error.message);
            this.setState({ isLoadRefresh: false });
          });
      })
      .catch(err => {
        closeLoadingToastr();
        toastr.error('Failed to refresh Database anaylsis.');
        console.log(err.message);
        this.setState({ isLoadRefresh: false });
      });
  }
  handleShare = users => {
    const { table } = this.state;
    const { share } = this.props;

    share({ usernames: users, tableIds: [table.id] });
  };

  render() {
    let {
      databases,
      columns,
      column,
      table,
      isOpenDialog,
      isLoadingColumns,
      isForeign,
      isSpecial,
      isFilter,
      foreign,
      isLoadRefresh,
      collapse,
      isRefreshSchedule,
    } = this.state;

    const visible_columns = this.VisibleColumn;
    const hidden_columns = columns.length - visible_columns;
    const pathId = (this.props as any).match.params.id;

    const columnsList: any = this.state.filterColumns
      ? columns.filter((el: any) => el.displayName.toLowerCase().includes(this.state.filterColumns.toLowerCase())) || []
      : columns || [];

    return (
      <>
        <>
          <BreadCrumb>
            Home <KeyboardArrowRight /> Databases <KeyboardArrowRight /> Data Model
          </BreadCrumb>
          <ContentPage>
            <EditTableDialog
              data={table}
              handleOpen={isOpenDialog}
              handleClose={e => {
                this.setState({ isOpenDialog: false });
              }}
              handleSave={e => {}}
            />

            <div className='db-container'>
              <div className='db-side-cards'>
                <SideControlButtons databases={databases} handleSelectTable={this.handleSelectTable} id={pathId} />
              </div>

              <Grid className='db-grid' container spacing={8}>
                {this.state.error ? (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography color='error'>{this.state.error}</Typography>
                        {this.state.loadingSave ? (
                          <span>
                            <CircularProgress color='primary' />
                          </span>
                        ) : (
                          <></>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={3} md={2} lg={2} className='db-columns'>
                      <Card className='db-columns-card'>
                        <CardHeader
                          className='column-card-header'
                          title={table.displayName}
                          action={
                            <React.Fragment>
                              <OiIconButton
                                className='header-edit-table-btn'
                                onClick={e => {
                                  this.handleEditTableOpen();
                                }}
                              >
                                <EditIcon />
                              </OiIconButton>

                              <ShareWithUsersPopup onDone={this.handleShare} />
                            </React.Fragment>
                          }
                          subheader={
                            <>
                              <h4>{visible_columns} Visible Columns</h4>
                              <h4>{hidden_columns} Hidden Columns</h4>
                            </>
                          }
                        />
                        <CardContent className='column-card-content'>
                          <TextField
                            id='column-search'
                            name='search'
                            label='Search'
                            type='search'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            value={this.state.filterColumns}
                            style={{ marginBottom: '15px' }}
                            onChange={event => {
                              this.setState({ filterColumns: event.target.value });
                            }}
                          />
                          <Scrollbars className='Scrollbars'>
                            <List className='db-column-list'>
                              {isLoadingColumns ? (
                                <div className='text-center'>
                                  <CircularProgress color='primary' />
                                  <div>Loading ...</div>
                                </div>
                              ) : columnsList.length == 0 ? (
                                <ListItem> No Columns! </ListItem>
                              ) : (
                                columnsList.map((el: any, i) => (
                                  <ListItem
                                    component={Button}
                                    className={`table-list-item ${(column && column.id) == el.id ? 'active' : ''}`}
                                    key={i}
                                    onClick={e => this.selectColumn(el)}
                                    autoCapitalize='false'
                                  >
                                    {el.displayName ? el.displayName : el.name}
                                  </ListItem>
                                ))
                              )}
                            </List>
                          </Scrollbars>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid className='db-column-info' item xs={collapse ? 9 : 5} md={collapse ? 10 : 5} lg={collapse ? 10 : 5}>
                      {column && (
                        <>
                          <Paper className='db-column-info-card'>
                            <CardHeader
                              className='db-info-header'
                              title={
                                <>
                                  {!isLoadingColumns ? column.displayName : ''}
                                  <OiIconButton
                                    className='collapse-btn'
                                    style={{ float: 'right' }}
                                    onClick={e => {
                                      this.setState({ collapse: !collapse });
                                    }}
                                  >
                                    {collapse ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                  </OiIconButton>
                                </>
                              }
                            />
                            <CardContent className='db-info-content'>
                              <Scrollbars className='Scrollbars'>
                                {isLoadingColumns ? (
                                  <div>Loading... </div>
                                ) : column != {} ? (
                                  <ColumnFormComponent
                                    column={column}
                                    onChange={column => {
                                      let columnIndex = table.columns.findIndex(c => c.id == column.id);
                                      table.columns[columnIndex] = column;
                                      this.setState({ column: column, table: table });
                                    }}
                                    foreignList={foreign}
                                    isForeign={isForeign}
                                    isSpecial={isSpecial}
                                    isFilter={isFilter}
                                    onChangeFlag={flag => {
                                      this.setState(Object.assign({}, (this.state[flag.name] = flag.value)));
                                    }}
                                    collapse={collapse}
                                  />
                                ) : (
                                  <Typography component='h3' title='Select Column' />
                                )}
                              </Scrollbars>
                            </CardContent>
                          </Paper>
                        </>
                      )}
                    </Grid>

                    <Grid
                      item
                      className={collapse ? 'db-column-info hidden' : 'db-column-info'}
                      xs={collapse ? false : 4}
                      md={collapse ? false : 5}
                      lg={collapse ? false : 5}
                    >
                      {column && (
                        <Card className='db-column-info-card'>
                          <CardHeader
                            className='db-info-header'
                            title={
                              <>
                                <Typography noWrap component={'span'} variant='headline'>
                                  <span
                                    style={{
                                      margin: '10px',
                                      position: 'relative',
                                      top: '5px',
                                    }}
                                  >
                                    <InsertChartIcon />
                                  </span>
                                  {column.displayName} statistics
                                </Typography>
                              </>
                            }
                          />
                          <CardContent className='db-info-content'>
                            <Scrollbars className='Scrollbars'>
                              {isLoadingColumns ? (
                                <div>Loading... </div>
                              ) : column != {} ? (
                                <ColumnStatistics
                                  column={column}
                                  isRefreshSchedule={isRefreshSchedule}
                                  onCheckSchedule={value => {
                                    this.setState({ isRefreshSchedule: value });
                                  }}
                                  onChange={column => {
                                    let columnIndex = table.columns.findIndex(c => c.id == column.id);
                                    table.columns[columnIndex] = column;
                                    this.setState({ column: column, table: table });
                                  }}
                                />
                              ) : (
                                <Typography component='h3' title='Select Column' />
                              )}
                            </Scrollbars>
                          </CardContent>
                        </Card>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </ContentPage>

          <ActionFooter
            handleSave={e => {
              this.handleSave(e);
            }}
            handleCancel={e => {
              this.handleCancel(e);
            }}
          >
            <Button
              disabled={isLoadRefresh}
              className='statistics-refresh btn-rounded'
              size='large'
              variant='contained'
              color='secondary'
              onClick={e => this.refresh(column)}
            >
              {isLoadRefresh ? <CircularProgress size={14} color='primary' style={{ fontSize: 14 }} /> : <></>}
              Refresh Now
            </Button>
          </ActionFooter>
        </>
      </>
    );
  }
}
