import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import { getFavoriteQuestions, getAllQuestions } from 'src/store/questions/questions.actions';
import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import { AddQuestionsPopup } from './AddQuestionPopup';

export interface IQuestionPoupProps extends DispatchProps, StateProps {
  AddQuestion: (a: OiQuestionDto) => any;
}
export interface IQuestionPoupState {
  anchorEl: any;
  search: any;
}

const mapStateToProps = ({ questions }: IRootState) => ({
  favoriteQuestionsList: questions.favoriteQuestions,
  isLoadingFav: questions.loadingFav,
  questions: questions.questionsList,
  loading: questions.loading,
});

const mapDispatchToProps = {
  getAllQuestions,
  getFavoriteQuestions,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(AddQuestionsPopup);
