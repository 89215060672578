import React from 'react';
import { withStyles } from '@material-ui/core';
const ExpressionTextInput = ({ classes, onFocus, onInput, onKeyDown, onKeyUp, ...props }) => (
  <div
    contentEditable={true}
    onFocus={onFocus}
    onSelect={onFocus}
    onInput={onInput}
    onKeyDown={onKeyDown}
    onKeyUp={onKeyUp}
    {...props}
    className={classes.expressionTextEditor as any}
  />
);

export default withStyles({
  expressionTextEditor: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: 2,
    '&:focus': {
      borderColor: 'red',
      borderBottom: '1px solid',
      outline: 'none',
    },
  },
})(ExpressionTextInput);
