import * as React from 'react';
import { Popover, Button } from '@material-ui/core';

import classNames from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import ColumnSelectList from 'src/modules/questions/studio/components/ColumnSelectList';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { Scrollbars } from 'react-custom-scrollbars';
import { QueryFilterViewItem, QueryViewItem } from 'src/modules/questions/studio/models';
import { SQLColumn, OceanDataType } from 'src/shared/models/SqlColumn';
import { oiQueryFiltersSelectOptions, isEmptinessOperator, ColumnDefaultComparisonOperator } from 'src/shared/models/OiFilter';
import { OiFilterDropdownItem } from 'src/shared/models/OiFilterDropdownItem';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import AddFilterSelectOperatorValueStep from './AddFilterSelectOperatorValueStep';
import { IProps, IState } from './models';
import Styled from './Styled';
import { OiFunction } from 'src/shared/models/OiFunction';
class AddOiFilterWizard extends React.Component<IProps, IState> {
  STEPS_COUNT = 2;

  state: IState = {
    step: 0,
    anchorEl: undefined,
    isOpen: false,
    column: {} as SQLColumn,
    aggregateFunction: OiFunction.NONE,
    selectedFilter: '',
    values: [],
  };

  componentDidMount() {
    this.reInitializeWidget();
  }

  reInitializeWidget() {
    this.setState({
      step: 0,
      column: {} as SQLColumn,
      selectedFilter: '',
      values: [],
    });
  }

  goToNextStep = () => {
    const step = this.state.step;
    this.setState({ step: (step + 1) % this.STEPS_COUNT });
  };

  goToPrevStep = () => {
    const step = this.state.step;
    this.setState({ step: step - 1 > -1 ? step - 1 : 0 });
  };

  onSelectColumn = (column: SQLColumn) => {
    this.goToNextStep();
    let defaultOperator = ColumnDefaultComparisonOperator(column);
    let values: any[] = [];
    if (defaultOperator && defaultOperator.operator == ComparisonOperator.LAST) {
      values = ['7', ComparisonOperator.LAST_DAYS];
    }
    if (column.oceanDataType == OceanDataType.BOOLEAN) {
      values = ['1'];
    }
    if (defaultOperator) {
      this.setState({ selectedFilter: defaultOperator.operator });
    }
    this.setState({ column, values });
    this.props.onSelectColumn && this.props.onSelectColumn(column);
  };

  onSelectAggregateColumn = (view: QueryViewItem) => {
    const aggregateFunction = view.functionItem!.value;
    const column = { ...view.column, displayName: aggregateFunction.toLowerCase() + ' ' + view.column.displayName };

    this.setState({ aggregateFunction }, () => {
      this.onSelectColumn(column);
    });
  };

  onSelectOperator = (operatorItem: OiFilterDropdownItem) => {
    this.setState({ values: operatorItem!.defaultValue });
    if (isEmptinessOperator(operatorItem && operatorItem.operator)) {
      const { column } = this.state;
      this.onDone({
        column: column,
        filterOperator: operatorItem,
        values: [],
      });
    }
    if (operatorItem) {
      this.setState({
        selectedFilter: operatorItem.operator,
        values: operatorItem.defaultValue,
      });
    }
    this.props.onSelectFilterOperator && this.props.onSelectFilterOperator(operatorItem);
  };

  onDone = (model?: QueryFilterViewItem) => {
    const { column, selectedFilter, values } = this.state;
    const operatorItem = oiQueryFiltersSelectOptions.find(t => t.operator == selectedFilter);
    if (!model) {
      model = {
        column: column,
        filterOperator: operatorItem as OiFilterDropdownItem,
        values: values,
        functionType: this.state.aggregateFunction,
      };
    } else {
      model.functionType = this.state.aggregateFunction;
    }
    this.props.onDone && this.props.onDone(model);
    this.onClosePopover();
  };

  openWidgetPopup = () => {
    this.setState({ isOpen: true, anchorEl: this.refs.clickRef });
  };

  onClosePopover = () => {
    this.reInitializeWidget();
    this.setState({ step: 0, isOpen: false });
  };

  onAddAdvanceFilter = () => {
    this.onClosePopover();
    this.props.onAddAdvanceFilter && this.props.onAddAdvanceFilter();
  };
  render() {
    const props = this.props;
    const classes = props.classes;
    let tables = props.tables;
    let openedTable = props.opendTable || (tables && tables[0]);
    const { step } = this.state;

    const selectedColumn = (this.state.column as SQLColumn) || {};
    return (
      <React.Fragment>
        <span ref='clickRef' onClick={this.openWidgetPopup}>
          {this.props.buttonComponent || this.props.children}
        </span>
        <Popover
          open={Boolean(this.state.isOpen)}
          anchorEl={this.state.anchorEl}
          onBackdropClick={this.onClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <SwipeableViews index={step} enableMouseEvents={false} className={classes.slide}>
            <div key={0} className={classNames(classes.slideWrap, classes.slide)}>
              <div className={classes.stepHeader}>
                {' '}
                Select Column <Button onClick={this.onAddAdvanceFilter}>Advance Filters</Button>
              </div>

              <Scrollbars style={{ height: step !== 0 ? 0 : '350px' }} className={classes.slide}>
                <ColumnSelectList
                  tables={tables}
                  opendTable={openedTable}
                  onSelectColumn={this.onSelectColumn}
                  aggregateColumns={this.props.aggregateColumns}
                  onSelectAggregateColumn={this.onSelectAggregateColumn}
                />
              </Scrollbars>
            </div>

            <div key={1} className={classNames(classes.slideWrap, classes.slide)}>
              <div className={classes.stepHeader}>
                <>
                  <ArrowBack onClick={this.goToPrevStep} />
                  &nbsp;
                  {selectedColumn.displayName}
                </>
              </div>
              <div className={classes.slideContent}>
                <AddFilterSelectOperatorValueStep
                  onDone={this.onDone}
                  selectedColumn={selectedColumn}
                  onSelectFilterOperator={this.onSelectOperator}
                />
              </div>
            </div>
          </SwipeableViews>
          <div className={classes.dotsWrapper}>
            {Array(this.STEPS_COUNT)
              .fill(0)
              .map((d, i) => (
                <span key={i} className={classNames(classes.dot, { [classes.dotActive]: step == i })} />
              ))}
          </div>
        </Popover>
      </React.Fragment>
    );
  }
}
export default Styled(AddOiFilterWizard);
