import * as React from 'react';
import * as PropTypes from 'prop-types';

import { memoize, Getter, Template, TemplatePlaceholder, TemplateConnector, Plugin } from '../../core';
import {
  tableColumnsWithDataRows,
  tableRowsWithDataRows,
  tableCellColSpanGetter,
  isNoDataTableRow,
  isNoDataTableCell,
  isDataTableCell,
  isHeaderStubTableCell,
  isDataTableRow,
  getMessagesFormatter,
} from '../../core';

const RowPlaceholder = props => <TemplatePlaceholder name='tableRow' params={props} />;
const CellPlaceholder = props => <TemplatePlaceholder name='tableCell' params={props} />;

const tableHeaderRows = [];
const tableBodyRowsComputed = ({ rows, getRowId }) => tableRowsWithDataRows(rows, getRowId);
const tableFooterRows = [];

const pluginDependencies = [{ name: 'DataTypeProvider', optional: true }];

export class TableBase extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tableColumnsComputed = memoize(columnExtensions => ({ columns }) => tableColumnsWithDataRows(columns, columnExtensions));
  }

  render() {
    const {
      layoutComponent: Layout,
      cellComponent: Cell,
      rowComponent: Row,
      noDataRowComponent: NoDataRow,
      noDataCellComponent: NoDataCell,
      stubRowComponent: StubRow,
      stubCellComponent: StubCell,
      stubHeaderCellComponent: StubHeaderCell,
      columnExtensions,
      messages,
      containerComponent,
      tableComponent,
      headComponent,
      bodyComponent,
      footerComponent,
    } = this.props;

    const getMessage = getMessagesFormatter(messages);
    const tableColumnsComputed = this.tableColumnsComputed(columnExtensions);

    return (
      <Plugin name='Table' dependencies={pluginDependencies}>
        <Getter name='tableHeaderRows' value={tableHeaderRows} />
        <Getter name='tableBodyRows' computed={tableBodyRowsComputed} />
        <Getter name='tableFooterRows' value={tableFooterRows} />
        <Getter name='tableColumns' computed={tableColumnsComputed} />
        <Getter name='getTableCellColSpan' value={tableCellColSpanGetter} />

        <Template name='body'>
          <TemplatePlaceholder name='table' />
        </Template>
        <Template name='table'>
          <TemplateConnector>
            {({
              tableHeaderRows: headerRows,
              tableBodyRows: bodyRows,
              tableFooterRows: footerRows,
              tableColumns: columns,
              getTableCellColSpan,
            }) => (
              <Layout
                tableComponent={tableComponent}
                headComponent={headComponent}
                bodyComponent={bodyComponent}
                footerComponent={footerComponent}
                containerComponent={containerComponent}
                headerRows={headerRows}
                bodyRows={bodyRows}
                footerRows={footerRows}
                columns={columns}
                rowComponent={RowPlaceholder}
                cellComponent={CellPlaceholder}
                getCellColSpan={getTableCellColSpan}
              />
            )}
          </TemplateConnector>
        </Template>
        <Template name='tableCell'>
          {params => (
            <TemplateConnector>
              {({ tableHeaderRows: headerRows }) =>
                isHeaderStubTableCell(params.tableRow, headerRows) ? <StubHeaderCell {...params} /> : <StubCell {...params} />
              }
            </TemplateConnector>
          )}
        </Template>
        <Template name='tableCell' predicate={({ tableRow, tableColumn }) => isDataTableCell(tableRow, tableColumn)}>
          {params => (
            <TemplateConnector>
              {({ getCellValue }) => {
                const columnName = params.tableColumn.column.name;
                const value = getCellValue(params.tableRow.row, columnName);
                return (
                  <TemplatePlaceholder
                    name='valueFormatter'
                    params={{
                      row: params.tableRow.row,
                      column: params.tableColumn.column,
                      value,
                    }}
                  >
                    {content => (
                      <Cell {...params} row={params.tableRow.row} column={params.tableColumn.column} value={value}>
                        {content}
                      </Cell>
                    )}
                  </TemplatePlaceholder>
                );
              }}
            </TemplateConnector>
          )}
        </Template>
        <Template name='tableCell' predicate={({ tableRow }) => isNoDataTableRow(tableRow)}>
          {params => (
            <TemplateConnector>
              {({ tableColumns }) => {
                if (isNoDataTableCell(params.tableColumn, tableColumns)) {
                  return <NoDataCell {...params} getMessage={getMessage} />;
                }
                return null;
              }}
            </TemplateConnector>
          )}
        </Template>
        <Template name='tableRow'>{params => <StubRow {...params} />}</Template>
        <Template name='tableRow' predicate={({ tableRow }) => isDataTableRow(tableRow)}>
          {params => <Row {...params} row={params.tableRow.row} />}
        </Template>
        <Template name='tableRow' predicate={({ tableRow }) => isNoDataTableRow(tableRow)}>
          {params => <NoDataRow {...params} />}
        </Template>
      </Plugin>
    );
  }
}

TableBase.propTypes = {
  layoutComponent: PropTypes.func.isRequired,
  tableComponent: PropTypes.func.isRequired,
  headComponent: PropTypes.func.isRequired,
  bodyComponent: PropTypes.func.isRequired,
  footerComponent: PropTypes.func.isRequired,
  containerComponent: PropTypes.func.isRequired,
  cellComponent: PropTypes.func.isRequired,
  rowComponent: PropTypes.func.isRequired,
  noDataCellComponent: PropTypes.func.isRequired,
  noDataRowComponent: PropTypes.func.isRequired,
  stubRowComponent: PropTypes.func.isRequired,
  stubCellComponent: PropTypes.func.isRequired,
  stubHeaderCellComponent: PropTypes.func.isRequired,
  columnExtensions: PropTypes.array,
  messages: PropTypes.object,
};

TableBase.defaultProps = {
  columnExtensions: undefined,
  messages: {},
};
