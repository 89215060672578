import { OiIconPaths } from 'src/assets/icons';
import { SQLColumn, OceanDataType } from '../models/SqlColumn';
export function isDateColumn(col: SQLColumn) {
  return col.oceanDataType == OceanDataType.DATE; // || ~col.dbName.indexOf("date");
}
export function isNumericColumn(col: SQLColumn) {
  return col.oceanDataType == OceanDataType.NUMBER;
}
export function isStringColumn(col: SQLColumn) {
  return col.oceanDataType == OceanDataType.STRING; //ColumnDataType.STRING == col.dbType.toUpperCase() || /.*(char)|(string)|(text).*/i.test(col.dbType);
}
export function isBooleanColumn(col: SQLColumn) {
  return col.oceanDataType == OceanDataType.BOOLEAN; //ColumnDataType.STRING == col.dbType.toUpperCase() || /.*(char)|(string)|(text).*/i.test(col.dbType);
}
export function getColumnValueType(col: SQLColumn) {
  if (isNumericColumn(col)) return OceanDataType.NUMBER;
  if (isStringColumn(col)) return OceanDataType.STRING;
  if (isDateColumn(col)) return OceanDataType.DATE;
  if (isBooleanColumn(col)) return OceanDataType.BOOLEAN;
}
export function getColumnTypeIconClass(col: SQLColumn): OiIconPaths {
  if (isNumericColumn(col)) return 'HashTagBold';
  if (isBooleanColumn(col)) return 'QuestionBold';
  if (isStringColumn(col)) return 'TypingT';
  if (isDateColumn(col)) return 'Calendar1';
  return 'TypingT';
}
