import { Theme, StyleRulesCallback } from '@material-ui/core';

const style: StyleRulesCallback = (theme: Theme) => ({
  PopoverWrapper: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    width: '480px',
    padding: '20px',
    flexDirection: 'column',
  },
  FieldsStyle: {
    marginTop: 15,
    marginBottom: 15,
  },
  BtnsWrapper: {
    marginTop: '15px',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  btn: {
    marginRight: '5px',
    marginLeft: '5px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
});
export default style;
