import * as React from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { SORT_DIRECTION } from 'src/shared/models';
import { debounce } from 'src/modules/questions/studio/utils';
import questionStudioServices from 'src/modules/questions/studio/questionStudioServices';
import ExpressionListItemHeader from './ComputedColumnListItemHeader';
import ComputedColumnExpressionBuilder from './ComputedColumnExpressionBuilder/ComputedColumnExpressionBuilder';
import { IProps, IState } from './models';
import Styled from './Styled';

class ComputedColumnListItem extends React.Component<IProps, IState> {
  lastItem: any = null;
  constructor(props) {
    super(props);
    this.state = {
      name: 'New computed Column',
      displayName: '',
      expanded: true,
      fancyMode: false,
      expression: {
        t: 'group' as any,
        value: [],
        parentId: 0,
      },
    };
  }

  componentWillMount() {
    this.setState(Object.assign({ pin: false, grouped: false }, this.props.item));
    this.lastItem = JSON.stringify(this.props.item);
  }

  componentDidUpdate() {
    const jsonedPropItems = JSON.stringify(this.props.item);
    if (this.lastItem !== jsonedPropItems) {
      this.lastItem = jsonedPropItems;
      this.setState(Object.assign({}, this.props.item));
    }
  }

  onExpressionUpdated = (expTree, expTxt) => {
    this.setState({ ...this.state, expression: expTree, expressionText: expTxt }, () => {
      this.triggerChange();
    });
  };

  onPinChanged = () => {
    this.setState(
      s => ({ pin: !s.pin }),
      () => {
        this.triggerChange();
      }
    );
  };

  handleLabelChanged = title => {
    this.setState({ displayName: title }, () => {
      this.triggerChange();
    });
  };

  handleSortRadioChanged = e => {
    this.setState({ orderDirection: e.target.value }, () => {
      this.triggerChange();
    });
  };

  triggerChange = () => {
    this.debouncedOnUpdate();
  };

  debouncedOnUpdate: any = debounce(
    result => {
      const { onChanged } = this.props;
      onChanged && onChanged(Object.assign({}, this.state));
    },
    250,
    false
  );

  toggleExpansion = () => {
    this.setState(s => ({ expanded: !s.expanded }));
  };

  render() {
    const { classes, tables, onDelete } = this.props;
    const { expanded, fancyMode, ...model } = this.state;
    return (
      <div className={classes.wrapper}>
        <ExpressionListItemHeader
          onDelete={onDelete}
          expanded={expanded}
          model={model}
          onTitleChanged={this.handleLabelChanged}
          onPinChanged={this.onPinChanged}
          toggleExpansion={this.toggleExpansion}
        />
        {expanded && (
          <div className={classes.editorWrapper}>
            <ComputedColumnExpressionBuilder
              tables={tables}
              operators={questionStudioServices.operatorsMenuOptions as any}
              aggregators={questionStudioServices.aggregatorsMenuOptions as any}
              expression={this.state.expression}
              onExpressionUpdated={(expTree, expText) => {
                this.onExpressionUpdated(expTree, expText);
              }}
            />

            <RadioGroup
              aria-label='sort'
              name='sort'
              className={classes.radioGroup}
              value={model.orderDirection}
              onChange={this.handleSortRadioChanged}
            >
              <FormControlLabel value={SORT_DIRECTION.NONE} control={<Radio color={'primary'} />} label='No sort' />
              <FormControlLabel value={SORT_DIRECTION.ASC} control={<Radio color={'primary'} />} label='Asc' />
              <FormControlLabel value={SORT_DIRECTION.DESC} control={<Radio color={'primary'} />} label='Desc' />
            </RadioGroup>
          </div>
        )}
      </div>
    );
  }
}
export default Styled(ComputedColumnListItem);
