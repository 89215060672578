export interface WidgetSettingsItem {
  id?: WIDGET_SETTINGS_ITEM;
  displayName: string;
  displayType: 'NUMBER' | 'TOGGLE' | 'CUSTOM';
  value?: boolean;
  fieldName: string;
  appliesFor?: any[];
  Render?: any;
}
export enum WIDGET_SETTINGS_ITEM {
  DATA_ZOOMING = 'DATA_ZOOMING',
  LEGEND = 'LEGEND',
  SMOOTH = 'SMOOTH',
  ROSE = 'ROSE',
  AREA = 'AREA',
  MAX_MARK_LINE = 'MAX_MARK_LINE',
  MIN_MARK_LINE = 'MIN_MARK_LINE',
  AVG_MARK_LINE = 'AVG_MARK_LINE',
  MAX_MARK_POINT = 'MAX_MARK_POINT',
  MIN_MARK_POINT = 'MIN_MARK_POINT',
  AVG_MARK_POINT = 'AVG_MARK_POINT',
  SINGLE_SERIES_COLOR = 'SINGLE_SERIES_COLOR',
  COLOR_SCHEMA = 'COLOR_PALLETTE',
  STACK = 'STACK',
  HORIZONTAL_BAR = 'HORIZONTAL_BAR',
  BAR_LABEL = 'BAR_LABEL',
  PERCENTAGE_BAR = 'PERCENTAGE_BAR',
  AUTO_GROUP = 'AUTO_GROUP',
}
export interface ChartCustomizationOptions {
  dimensionalGrouping?: boolean;
  enableDataZooming?: boolean;
  area?: boolean;
  smooth?: boolean;
  legend?: boolean;
  maxLine?: boolean;
  maxPoint?: boolean;
  avgLine?: boolean;
  avgPoint?: boolean;
  minLine?: boolean;
  minPoint?: boolean;
  rose?: boolean;
  doughnut?: boolean;
  kpiColor?: string;
  colorSchemaIndex?: number;
  chartGroup?: any;
  stack?: boolean;
  isHorizontalBar?: boolean;
  barLabel?: boolean;
  percentageBar?: boolean;
}

export enum QUESTION_DRILL_DOWN_OPERATION {
  DRILL_DOWN = 'DRILL_DOWN',
  DISTRIBUTION = 'DISTRIBUTION',
  UNIQUE_VALUES = 'UNIQUE_VALUES',
}
