import * as React from 'react';
import {
  Popover,
  Typography,
  TextField,
  Button,
  Chip,
  withStyles,
  StyleRulesCallback,
  Theme,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  InputLabel,
  FormControl,
  Select,
  Input,
  MenuItem,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import OiIconButton from 'src/components/OiIconButton';
import CronEditor from 'components/CronEditor';
import toastr, { alert } from 'components/toastr';
import AddIcon from '@material-ui/icons/Add';
import { EMAIL_REGEX } from 'src/store/authentication/constant';
import { KEYCODES } from 'src/shared/util/keyboard';
import CanDo from '../Permissions/Can';
import taskAccess from 'src/services/permissions/abilities/tasks';
type IProps = {
  icon?: any;
  onDone: (model: { email: string }) => any;
};
class ShareQuestionPopup extends React.Component<any> {
  state = {
    anchorEl: undefined,
    emails: [],
    email: '',
    emailError: '',
    title: '',
    isOpen: false,
    cronExpression: '',
    isScheduler: false,
    fileFormat: 'xlsx',
  };

  close = () => {
    this.setState({ anchorEl: undefined });
  };

  done = () => {
    const { isScheduler, cronExpression, emails, title, fileFormat } = this.state;

    let model = {
      email: emails,
      title: title,
      cron: isScheduler ? cronExpression : undefined,
      format: fileFormat,
    };
    if (!model.email) {
      toastr.warn('invalid data, please validate title & email');
      return;
    }
    this.props.onDone(model);
    this.close();
  };

  onEmailFieldChange = e => {
    this.setState({ email: e.target.value });
  };

  onTitleFieldChange = e => {
    this.setState({ title: e.target.value });
  };

  deleteEmail = email => {
    this.setState((state: any) => ({ emails: state.emails.filter(m => m !== email) }));
  };

  addEmail = () => {
    if (!this.isValidEmail()) {
      this.setState({ emailError: 'Please enter valid email' });
    } else {
      this.setState((state: any) => ({
        emailError: '',
        email: '',
        emails: [...state.emails, state.email],
      }));
    }
  };

  isValidEmail = () => {
    return EMAIL_REGEX.test(this.state.email);
  };

  handleChangeFormat = e => {
    this.setState({ fileFormat: e.target.value });
  };
  render() {
    const { classes } = this.props;
    const { isScheduler, cronExpression, email, title, emails, emailError } = this.state;
    return (
      <>
        <OiIconButton
          aria-label='type'
          aria-owns='type-menu'
          aria-haspopup='true'
          onClick={event => this.setState({ anchorEl: event.currentTarget })}
        >
          {this.props.icon || <ShareIcon />}
        </OiIconButton>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onBackdropClick={this.close}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
        >
          <div className={classes.IconsWrapper}>
            <Typography variant={'title'}> Share Question With: </Typography>

            <React.Fragment>
              <TextField
                label='Email'
                type='email'
                fullWidth
                helperText='Press Enter to add email'
                value={this.state.email}
                onChange={this.onEmailFieldChange}
                InputProps={{
                  endAdornment: this.isValidEmail() && (
                    <InputAdornment position='end'>
                      <OiIconButton
                        onClick={e => {
                          this.addEmail();
                        }}
                      >
                        <AddIcon />
                      </OiIconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={e => {
                  if (e.keyCode == KEYCODES.ENTER) {
                    this.addEmail();
                  }
                }}
              />
            </React.Fragment>
            {emailError && (
              <div className={classes.errMsg}>
                <Typography color={'error'} variant='body1'>
                  {emailError}
                </Typography>
              </div>
            )}

            {emails.length > 0 && (
              <div className={classes.emailsList}>
                <Typography variant='subtitle1'>To: </Typography>
                <div>
                  {emails.map((mail, index) => (
                    <Chip className={classes.chipStyle} color='primary' key={index} label={mail} onDelete={e => this.deleteEmail(mail)} />
                  ))}
                </div>
              </div>
            )}
            <div className={classes.fileFormatWrapper}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor='file-format'>
                  File format
                </InputLabel>
                <Select
                  value={this.state.fileFormat}
                  onChange={this.handleChangeFormat}
                  input={<Input name='format' id='file-format' />}
                  displayEmpty
                  name='format'
                >
                  <MenuItem value={'xlsx'}>Excel</MenuItem>
                  <MenuItem value={'csv'}>CSV</MenuItem>
                </Select>
              </FormControl>
            </div>

            <CanDo action={taskAccess.create}>
              <div className={classes.SchedulerWrapper} style={{ margin: '15px -2px 0px -2px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isScheduler}
                      onChange={event => {
                        this.setState({
                          isScheduler: event.target.checked,
                        });
                      }}
                      value='isScheduler'
                    />
                  }
                  label='Scheduler'
                />
              </div>
            </CanDo>
            <div style={{ marginBottom: 20 }}>
              {isScheduler && (
                <TextField
                  // margin={'normal'}
                  fullWidth
                  label='Task Title'
                  value={this.state.title}
                  onChange={this.onTitleFieldChange}
                />
              )}
            </div>
            <div className={classes.SchedulerWrapper} style={{ margin: '0px -2px', fontSize: '13px' }}>
              {isScheduler && (
                <CronEditor
                  handleChange={cron => {
                    this.setState({ cronExpression: cron });
                  }}
                />
              )}
            </div>

            <div className={classes.BtnsWrapper}>
              <Button className={classes.btn} type='button' onClick={this.close}>
                Cancel
              </Button>
              <Button
                className={classes.btn}
                disabled={!emails.length}
                variant='contained'
                type='button'
                color='primary'
                onClick={this.done}
              >
                Done
              </Button>
            </div>
          </div>
        </Popover>
      </>
    );
  }
}

const style: StyleRulesCallback = (theme: Theme) => ({
  SchedulerWrapper: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'pre',
  },

  IconsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '480px',
    padding: '20px',
    // textAlign: 'center',
    flexDirection: 'column',
  },
  BtnsWrapper: {
    marginTop: '15px',
    textAlign: 'right',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  btn: {
    marginRight: '5px',
    marginLeft: '5px',
  },
  emailsList: {
    textAlign: 'left',
    marginTop: '15px',
    marginBottom: '15px',
  },
  errMsg: {
    textAlign: 'left',
  },
  chipStyle: {
    marginRight: '5px',
    marginTop: '5px',
  },
  fileFormatWrapper: {
    margin: '15px 0px',
  },
});
type styleType = typeof style;

export default withStyles(style)(ShareQuestionPopup);
