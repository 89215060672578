import { Theme, withStyles, createStyles } from '@material-ui/core';
import { StyleRulesCallback } from 'src/layout/main.style';
import { Colors } from 'src/shared/theme';

export default withStyles((theme => ({
  kpiToolbar: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
    display: 'flex',
    width: '100%',
    '& button': {
      color: 'white',
      '& .toolbar-icon': {
        color: 'white',
      },
    },
    '& button:hover': {
      color: `${Colors.brandDarkGrey} !important`,
      '& .toolbar-icon': {
        color: `${Colors.brandDarkGrey} !important`,
      },
    },
  },
  leftButtons: {
    margin: 'auto',
    marginLeft: 0,
  },
  rightButtons: {
    margin: 'auto',
    marginRight: 0,
  },
  showOnHoverOnly: {
    display: 'block',
    position: 'relative',
    '& .question-toolbar': {
      background: Colors.white,
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      zIndex: 500,
      display: 'none',
    },
    '&:hover': {
      '& .question-toolbar': {
        display: 'block',
      },
    },
  },
  Wrapper: {
    width: '100%',
    height: '100%',
    '& .preview-toolbar': {
      height: 50,
      margin: 0,
      overflow: 'hidden',
    },
    background: 'white',
    overflow: 'auto',
    '& .flex': {
      width: '100%',
      justifyContent: 'center',
    },
    '& table>tbody>tr:nth-child(odd)': {
      background:
        '#f7f7f                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         0.0.0.7',
    },
  },
  PreviewWrap: {
    height: 'calc(100% - 49px)',
    width: '100%',
    position: 'relative',
    background: 'white',
    '& .flex': {
      width: '100%',
      justifyContent: 'center',
    },
  },
  previewButton: {
    margin: '10px auto',
  },
  showPreviewButton: {
    height: 'calc(100% - 110px)',
  },
  LoadingWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
})) as StyleRulesCallback);
