import * as React from 'react';

import { MenuItem, FormControl, FormLabel, TextField } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';

import FilterValuePresenter from 'src/modules/questions/studio/components/QuestionFilter/filterValuePresenter';
import { QueryFilterViewItem, QueryViewItem } from 'src/modules/questions/studio/models';
import { SQLColumn, OceanDataType } from 'src/shared/models/SqlColumn';
import {
  isOperatorCurrentOrLast,
  oiQueryFiltersSelectOptions,
  isInListOperator,
  isEqualityOperator,
  getDateColumnDefaultValue,
  getFilterDefaultValue,
} from 'src/shared/models/OiFilter';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import OiIconButton from 'src/components/OiIconButton';
import ColumnSelectPopup from '../../components/ColumnSelectPopup';
import Styled from './Styled';
import { IProps, IState } from './models';
import ItemHeading from './ItemHeading';
import { OiFunction } from 'src/shared/models/OiFunction';

class QuestionFilteringItem extends React.Component<IProps, IState> {
  state: IState = {
    expanded: false,
    // model: {} as OIQueryFilter,
    // values: ["", ""]
  };

  triggerChange(model) {
    this.props.onUpdated && this.props.onUpdated(Object.assign({}, this.props.item, model), this.props.itemIndex);
  }

  onSelectColumn = (columnItem: SQLColumn) => {
    let model = Object.assign({}, this.props.item);
    model.column = columnItem;
    model.functionType = OiFunction.NONE;
    this.triggerChange(model);
  };

  onSelectAggregateColumn = (view: QueryViewItem) => {
    const column = { ...view.column, displayName: view.functionItem!.value.toLowerCase() + ' ' + view.column.displayName };
    let model = Object.assign({}, this.props.item);
    model.functionType = view.functionItem!.value;
    model.column = column;
    this.triggerChange(model);
  };

  saveValue = values => {
    let model = this.props.item;
    model.values = values.slice();
    this.triggerChange(model);
  };

  componentDidMount() {
    // this.setState({ model: { ...this.props.item } })
  }

  onChangeFilterOperation = (e: any) => {
    //filtersOperators
    let filtersOperators = oiQueryFiltersSelectOptions || [];
    const operator = filtersOperators.find(t => t.operator === e.target.value);
    let model = Object.assign({}, this.props.item);
    model.filterOperator = operator!;
    model.values = getFilterDefaultValue(model.column, model.filterOperator) || [];
    this.triggerChange(model);
  };

  onDynamicChanged = () => {
    this.triggerChange({ dynamic: !this.props.item.dynamic } as QueryFilterViewItem);
  };

  render() {
    const { expanded } = this.state;

    let model = this.props.item;
    const classes = this.props.classes;

    const filtersOperators = oiQueryFiltersSelectOptions.filter(
      item => item.isGeneric || (model && model.column && item.appliesFor.indexOf(model.column.oceanDataType) > -1)
    );
    const column: SQLColumn = model.column;
    const operator = model.filterOperator.operator;
    let values = operator == ComparisonOperator.BETWEEN || isOperatorCurrentOrLast(operator) ? model.values : [model.values[0]];
    if (isInListOperator(operator)) {
      values = model.values;
    }

    let columnSelect = (
      <FormControl fullWidth className={classes.row}>
        <FormLabel> Select Column </FormLabel>
        <ColumnSelectPopup
          tables={this.props.tables}
          onSelectColumn={this.onSelectColumn}
          aggregateColumns={this.props.aggregateColumns}
          onSelectAggregateColumn={this.onSelectAggregateColumn}
        >
          <div className={classes.wizardButtonWrap}>
            {column.displayName || 'Select Field'}
            <OiIconButton className={classes.expandButton}>
              <ExpandMore />
            </OiIconButton>
          </div>
        </ColumnSelectPopup>
      </FormControl>
    );

    let filterOperatorItem = (
      <TextField
        className={classes.row}
        fullWidth
        id='operator-select'
        label='operator '
        placeholder=' Select Comparison'
        name='operator'
        margin='dense'
        variant='outlined'
        value={model.filterOperator && model.filterOperator.operator}
        InputLabelProps={{ shrink: true }}
        onChange={this.onChangeFilterOperation}
        select
      >
        {filtersOperators.map((operatorItem, key) => (
          <MenuItem button key={key} value={operatorItem.operator}>
            {operatorItem.displayName}
          </MenuItem>
        ))}
      </TextField>
    );

    const valuesItems = Boolean(model) && (
      <FormControl fullWidth className={classes.row}>
        <FilterValuePresenter
          autoOpenDatePicker={false}
          columnId={column.id}
          onValueUpdated={this.saveValue}
          filterOperator={model.filterOperator.operator}
          values={values}
          valueType={column.oceanDataType}
          onSave={this.saveValue}
        />
      </FormControl>
    );

    const titleValues = (() => {
      let valuesDisplay = '';
      let operator = model.filterOperator.operator;
      if (isEqualityOperator(operator) && column.oceanDataType == OceanDataType.BOOLEAN) {
        valuesDisplay = values[0] === '1' ? 'True' : values[0] === '0' ? 'False' : values[0];
      } else if (isOperatorCurrentOrLast(operator)) {
        let opName = values[1].replace(/LAST_|CURRENT_/g, '').toLowerCase();
        valuesDisplay = values[0] + ' ' + opName;
      } else {
        valuesDisplay = values.join(' , ');
      }
      return <> {valuesDisplay} </>;
    })();
    const isInQuestionStudio = this.props.source === 'questionStudio';
    return (
      <div className={classes.wrapper}>
        <ItemHeading
          headingClass={classes.heading}
          onDynamicChanged={this.onDynamicChanged}
          onDelete={this.props.onDelete}
          expanded={expanded}
          model={model}
          displayName={column.displayName}
          titleValues={titleValues}
          isInQuestionStudio={isInQuestionStudio}
          toggleExpanded={e => {
            this.setState({
              expanded: !expanded,
            });
          }}
        />

        {expanded && (
          <div className={classes.editorWrapping}>
            {isInQuestionStudio ? (
              columnSelect
            ) : (
              <>
                <div>
                  {' '}
                  Column: <br />{' '}
                </div>
                {model.column.displayName}
              </>
            )}

            {filterOperatorItem}

            {valuesItems}
          </div>
        )}
      </div>
    );
  }
}
export default Styled(QuestionFilteringItem);
