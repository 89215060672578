import React from 'react';
import { withStyles } from '@material-ui/core';
function EmptyResultSetView({ classes }) {
  return (
    <div className={classes.noDataWrap}>
      <div className={classes.noData}>No results found try different Question or change Filters</div>
    </div>
  );
}

export default withStyles({
  noDataWrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noData: {
    fontSize: 24,
  },
})(EmptyResultSetView);
