import { Colors } from 'src/shared/theme';
import { StyleRulesCallback, Theme, withStyles, createStyles } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    innerList: {
      paddingLeft: '20px',
    },
    textField: {},
    tableIcon: {
      marginRight: 10,
    },
    search: {
      padding: '0 10px',
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 10,
      background: 'white',
    },
    listItem: {
      padding: 5,
    },
    tableNameWrap: {
      borderBottom: `1px solid ${Colors.brandGreyBorders}`,
      color: Colors.brandBlue,
    },
  });

export default withStyles(styles);
