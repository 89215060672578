import { useState, useEffect } from 'react';
import { ShareableUser } from 'src/shared/models/ShareableUser';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';
import toastr from 'src/components/toastr';
import discoveryApis from 'src/shared/apis/discoveryApis';
import { SQLTable } from 'src/shared/models/SqlTable';

export default function useLoadDbTables(dbId) {
  const [tables, setTables] = useState([] as Array<SQLTable>);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    () => {
      setIsLoading(true);
      discoveryApis.getDatabasePublicTables(dbId).then(
        tables => {
          setIsLoading(false);
          setTables(tables);
        },
        () => {
          setIsLoading(false);
          toastr.error('Error loading tables of db ' + dbId);
        }
      );
    },
    [dbId]
  );

  return [tables, isLoading];
}
