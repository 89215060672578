import types from './actionTypes';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as columnsMapHeplers from './helpers';
import { setColumnsMap } from './actions';
import { IRootState } from '../rootReducer';
import { store } from 'src/constants';
import databaseAccess from 'src/services/permissions/abilities/database';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';

function* loadAndCacheTablesSaga() {
  const state: IRootState = store.getState();
  try {
    // get user account
    const account = yield oceanAuthApis.getCurrentUserAccount().then(account => account.data);

    if (!state.columnsMap.isLoaded && !state.columnsMap.isLoading) {
      // check if user have database view permission
      if (databaseAccess.view.every(p => account.permissions.includes(p))) {
        const columnsMap = yield call(columnsMapHeplers.loadAndCacheTables);
        if (columnsMap) {
          yield put(setColumnsMap(columnsMap));
        }
      }
    } else {
      //do nothing...
    }
  } catch (e) {
    console.error(e);
  }
}

export default [takeEvery(types.FETCH_COLUMNS_MAP as any, loadAndCacheTablesSaga)];
