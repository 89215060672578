import OiIcon from 'src/assets/icons';
import * as React from 'react';

export enum VIEW_TYPE {
  //KPI, TABLE, PIE, BAR, LINE_BAR, LINE, DOUGHNUT_CHART, ROSE_CHART, AREA_CHART, SMOOTH_LINE_CHART
  TABLE = 'TABLE',
  // KPI, TABLE, PIE, BAR, LINE_BAR, LINE, DONUT
  PIE_CHART = 'PIE',
  H_BAR_CHART = 'H_BAR_CHART',

  DOUGHNUT_CHART = 'DOUGHNUT_CHART',
  BAR_CHART = 'BAR',
  LINE_CHART = 'LINE',
  KPI = 'KPI',

  // ROSE_CHART = 'ROSE_CHART',
  // SMOOTH_LINE_CHART = 'SMOOTH_LINE_CHART'
  // AREA_CHART = 'AREA_CHART',
}

export type ViewTypeItem = {
  displayName: string;
  icon: React.Component | React.StatelessComponent;
  chartType?: 'line' | 'bar' | 'hbar' | 'pie' | 'area';
  type: VIEW_TYPE;
  customize?: any;
};

const PieChartIcon = (props: any) => {
  return <OiIcon path={'PieChart'} {...props} />;
};
const PieChart60DegIcon = (props: any) => {
  return <OiIcon path={'PieChart60Deg'} {...props} />;
};

const DoughnutChartIcon = (props: any) => {
  return <OiIcon path={'DoughnutChart'} {...props} />;
};

const LineChartIcon = props => {
  return <OiIcon path={'LineChart'} {...props} />;
};

const TableIcon = props => {
  return <OiIcon path={'Table'} {...props} />;
};

const BarChartIcon = (props: any) => {
  return <OiIcon path={'BarChart'} {...props} />;
};
const HBarChartIcon = (props: any) => {
  return <OiIcon path={'BarChart'} style={{ transform: 'rotate(90deg)' }} {...props} />;
};
export const viewTypesOptionsList: ViewTypeItem[] = [
  {
    type: VIEW_TYPE.TABLE,
    displayName: 'Table',
    icon: TableIcon,
  },
  {
    type: VIEW_TYPE.KPI,
    displayName: 'Number View',
    icon: p => <OiIcon path='Kpi' {...p} />,
  },
  {
    type: VIEW_TYPE.BAR_CHART,
    displayName: 'Bar chart',
    chartType: 'bar',
    icon: BarChartIcon,
  },
  {
    type: VIEW_TYPE.H_BAR_CHART,
    displayName: 'Horizontal Bar chart',
    chartType: 'hbar',
    icon: HBarChartIcon,
    customize: {},
  },
  {
    type: VIEW_TYPE.LINE_CHART,
    displayName: 'Line chart',
    chartType: 'line',
    icon: LineChartIcon,
    customize: {
      // smooth: false
    },
  },
  {
    type: VIEW_TYPE.DOUGHNUT_CHART,
    displayName: 'Doughnut chart',
    chartType: 'pie',
    icon: DoughnutChartIcon,
    customize: {
      // rose: false,
      doughnut: true,
    },
  },
  {
    type: VIEW_TYPE.PIE_CHART,
    displayName: 'Pie chart',
    chartType: 'pie',
    icon: PieChartIcon,

    customize: {
      // rose: false,
      doughnut: false,
    },
  },
];
