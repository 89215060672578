import { connect } from 'react-redux';
import { sharedFilter, dashboardFiltersByTab } from 'src/store/dashboards/selectors';

import { addFilterToDashboard, removeFilterFromDashboard } from 'src/store/dashboards/actions';
import FiltersList from './FiltersList';

const mapState = state => ({
  sharedFilter: sharedFilter(state),
  filters: dashboardFiltersByTab(state),
});

const mapDispatch = dispatch => {
  return {
    addFilter: filter => dispatch(addFilterToDashboard(filter)),
    removeFilter: filter => dispatch(removeFilterFromDashboard(filter)),
  };
};

export type StateProps = ReturnType<typeof mapState>;
export type DispatchProps = ReturnType<typeof mapDispatch>;

const FiltersListContaier = connect(
  mapState,
  mapDispatch
)(FiltersList);

export default FiltersListContaier;
