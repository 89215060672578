import { Paper, Modal, Button, DialogTitle, Typography, Toolbar } from '@material-ui/core';
import React, { useState } from 'react';
import GDAFilterFormContainer from './GDAFilterFormContainer';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function GDAFormModal(props) {
  const { renderOpener, onSave, onCancel, itemModel, autoOpen } = props;
  const [isOpen, setIsOpen] = useState(autoOpen);
  const handleOnCancel = () => {
    setIsOpen(false);
    onCancel && onCancel();
  };
  return (
    <>
      {renderOpener && <div onClick={setIsOpen.bind({}, true)}>{renderOpener(setIsOpen.bind({}, true))}</div>}

      <Modal onClose={handleOnCancel} aria-labelledby='gda-filter-title' open={Boolean(isOpen)} disableBackdropClick={true}>
        <Paper
          style={{
            width: 400,
            margin: '0 auto',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            right: 0,
          }}
        >
          <Toolbar>
            GDA Form
            <div style={{ flexGrow: 1 }} />
            <TooltipedIconButton tooltip='Close' onClick={handleOnCancel}>
              <CloseIcon />
            </TooltipedIconButton>
          </Toolbar>
          <div style={{ padding: '0px 20px' }}>
            <GDAFilterFormContainer
              model={itemModel}
              onCancel={handleOnCancel}
              onSaveAndClose={e => {
                setIsOpen(false);
                onSave(e);
              }}
            />
          </div>
        </Paper>
      </Modal>
    </>
  );
}
