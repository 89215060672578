import { Paper, Modal, Button, DialogTitle, Typography, Toolbar } from '@material-ui/core';
import React, { useState } from 'react';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import BackupFormContainer from './BackupFormContainer';

interface IProps {
  onSave: (options: any) => any;
  renderOpener: (open: any) => any;
}
export default function BackupFormModal(props: IProps) {
  const { renderOpener, onSave } = props;
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const classes = useStyle();
  return (
    <>
      <div onClick={openModal}>{renderOpener(openModal)}</div>

      <Modal onClose={closeModal} aria-labelledby='Backup-filter-title' open={Boolean(isOpen)} disableBackdropClick={true}>
        <Paper className={classes.modalPaper}>
          <Toolbar>
            Backup Form
            <div style={{ flexGrow: 1 }} />
            <TooltipedIconButton tooltip='Close' onClick={closeModal}>
              <CloseIcon />
            </TooltipedIconButton>
          </Toolbar>
          <div className={classes.contentWrap}>
            <BackupFormContainer
              onCancel={closeModal}
              onSaveAndClose={e => {
                closeModal();
                onSave(e);
              }}
            />
          </div>
        </Paper>
      </Modal>
    </>
  );
}

const useStyle = makeStyles({
  modalPaper: {
    width: '60vw',
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    right: 0,
  },
  contentWrap: {
    padding: '0px 20px',
  },
});
