import React, { useState, useEffect } from 'react';
import { Modal, Paper, Toolbar } from '@material-ui/core';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import CloseIcon from '@material-ui/icons/Close';
import EntitiesSharedWithUserController from './EntitiesSharedWithUserController';
import { makeStyles } from '@material-ui/styles';
import { Colors } from 'src/shared/theme';
import { ShareableUser } from 'src/shared/models/ShareableUser';

const useStyle = makeStyles({
  toolbar: {
    backgroundColor: Colors.brandBlue,
    fontSize: 22,
    color: 'white',
  },
  modalPaper: {
    width: '600px',
    minHeight: '400px',
    maxHeight: '600px',
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    right: 0,
  },
  modalContent: {
    paddingTop: 20,
  },
});

interface IProps {
  userItem?: ShareableUser;
  open: boolean;
  onClose: (e?: any) => any;
}
export default function EntitiesSharedWithUserControllerModal(props: IProps) {
  const { open, onClose, userItem } = props;
  const classes = useStyle();
  return (
    <div>
      <Modal open={Boolean(open && userItem && userItem.username)} onClose={onClose} disableBackdropClick={true}>
        <Paper className={classes.modalPaper}>
          <Toolbar className={classes.toolbar}>
            <>User Shared Entities</>
            <div style={{ flexGrow: 1 }} />
            <TooltipedIconButton tooltip='Close' onClick={onClose}>
              <CloseIcon nativeColor='white' />
            </TooltipedIconButton>
          </Toolbar>
          <div className={classes.modalContent}>{open && <EntitiesSharedWithUserController user={userItem!} />}</div>
        </Paper>
      </Modal>
    </div>
  );
}
