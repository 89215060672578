import * as React from 'react';
// router
import { NavLink as Link } from 'react-router-dom';
// Styles
import { styles } from './drawer.style';
import { withStyles } from '@material-ui/core';
// material components
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';

export interface DrawerLayoutProps {
  items: Array<any>;
  isOpen: boolean;
}
class DrawerComponent extends React.Component<any, DrawerLayoutProps> {
  render() {
    const { classes } = this.props;

    return (
      <Drawer
        className='drawer-bar'
        variant='persistent'
        open={this.props.isOpen}
        PaperProps={{ elevation: 1, style: { height: '100%' } }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List>
          {this.props.items.map((item, i) => (
            <ListItem
              key={i}
              button
              color='primary'
              component={p => (
                <Link
                  activeClassName='active'
                  isActive={(e, loc) => loc.pathname.indexOf(item.linkTo) === 0}
                  to={item.linkTo}
                  {...p as any}
                />
              )}
            >
              {(item.icon && <ListItemIcon>{item.icon}</ListItemIcon>) || <></>}
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    );
  }
}

export default withStyles(styles)(DrawerComponent);
