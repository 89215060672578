import * as React from 'react';
import { Toolbar, Card, TextField, InputAdornment } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Search from '@material-ui/icons/Search';
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import moment from 'moment';

import SimpleTable from 'components/simple-table';
import { ISimpleTableColumn } from 'components/simple-table/models';
import apisService from 'src/shared/apis';
import { DownloadableFile } from 'src/shared/models/DownloadableFile';
import { openConfirmationPopup } from 'store/confirmationPopup';
import OiIconButton from 'src/components/OiIconButton';
import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import toastr from 'components/toastr';
import { TableSearchField } from 'src/components/TableSearchField';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import downloadAccess from 'src/services/permissions/abilities/downloads';
import CanDo from 'src/components/Permissions/Can';

interface IProps extends StateProps, DispatchProps {}
interface IDownloadsList {
  isLoadingDownloads: boolean;
  files: DownloadableFile[];
  search: string;
  allFiles: DownloadableFile[];
}
class DownloadsList extends React.Component<IProps, IDownloadsList> {
  state: IDownloadsList = {
    isLoadingDownloads: true,
    files: [],
    search: '',
    allFiles: [],
  };
  columns: ISimpleTableColumn[] = [
    {
      field: 'displayName',
      title: 'Name',
      cellRenderer: v => (v === null ? '-' : v),
    },
    {
      field: 'questionName',
      title: 'Question Name',
      cellRenderer: v => (v === null ? '-' : v),
    },
    { field: 'extention', title: 'extension' },
    {
      field: 'createdDate',
      title: 'creation Date',
      cellRenderer: v => moment(v, moment.defaultFormat).format('MMM DD, YYYY'),
    },
    {
      field: 'id',
      title: 'actions',
      cellRenderer: (v, row, column) => (
        <>
          <CanDo action={downloadAccess.donwnload}>
            <TooltipedIconButton tooltip={'Download file'}>
              <FileDownloadIcon onClick={e => this.downloadFile(row)} />
            </TooltipedIconButton>
          </CanDo>
          <CanDo action={downloadAccess.delete}>
            <TooltipedIconButton tooltip={'Delete file'}>
              <DeleteIcon
                onClick={e => {
                  const { confirm } = this.props;
                  confirm({
                    msg: `Are you sure you want to delete this File (${row.displayName})?`,
                    ok: () => {
                      this.deleteFile(v);
                    },
                  });
                }}
              />
            </TooltipedIconButton>
          </CanDo>
        </>
      ),
    },
  ];

  componentWillMount() {
    this.getDownloadsList();
  }

  deleteFile = id => {
    apisService.deleteExportedFile(id).then(
      resp => {
        let files = this.state.files;
        this.setState({ files: files.filter(q => q.id !== id) });
        toastr.success('File deleted successfully');
      },
      e => {
        toastr.error('Failed to deleted file');
      }
    );
  };

  getDownloadsList() {
    apisService.getDownloadsList().then(
      files => {
        files.sort((a, b) => {
          return a.createdDate > b.createdDate ? -1 : 1;
        });
        this.setState({ files, allFiles: files, isLoadingDownloads: false });
      },
      () => {
        this.setState({ isLoadingDownloads: false });
      }
    );
  }

  downloadFile = (file: DownloadableFile) => {
    let closeLoadingToastr = toastr.loading('downloading file ' + file.displayName);

    apisService.downloadExportedFile(file).then(
      f => {
        closeLoadingToastr();
        toastr.success('file downloaded successfully');
      },
      e => {
        console.error('Error downloading file', file, e);
        closeLoadingToastr();
        toastr.error('File download failed');
      }
    );
  };

  handleSearch = e => {
    const keyWord = (e.target.value || '').toLowerCase();
    let _data = this.state.allFiles.filter(value => (value.displayName || '').toLowerCase().includes(keyWord));
    _data.sort((a, b) => {
      return a.createdDate > b.createdDate ? -1 : 1;
    });
    this.setState({ search: e.target.value, files: _data });
  };

  render() {
    const { isLoadingDownloads, files } = this.state;

    return (
      <>
        <BreadCrumb>
          Home <KeyboardArrowRight /> Downloads
        </BreadCrumb>
        <ContentPage>
          <Card>
            <Toolbar>
              <div className='flex' />
              <TableSearchField search={this.state.search} handleSearch={this.handleSearch} />
            </Toolbar>
            <SimpleTable columns={this.columns} data={files} showLoading={isLoadingDownloads} />
          </Card>
        </ContentPage>
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  confirm: openConfirmationPopup,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadsList);
