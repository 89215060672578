import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from 'modules/databases/home';
import Createform from './connection-create/DbConnectionCreate';
import EditForm from './connection-edit';
// import DataBaseDetails from 'modules/databases/db-details';
import DataModels from './data-models';
import CanActivateModule from 'src/components/Permissions/CanActivateModule';

import { AclModules } from 'src/services/permissions/enum';
import { store } from 'src/constants';
import { connectedCanDo } from 'src/services/permissions/utils';
import databaseAccess from 'src/services/permissions/abilities/database';
import NotAuthorizedPage from 'src/layout/NotAuthorized';

const Routes = ({ match }) => {
  return (
    <CanActivateModule moduleName={AclModules.dashboards}>
      <Switch>
        <Route
          path={match.url + '/new'}
          component={connectedCanDo(databaseAccess.create) ? props => <Createform {...props} /> : NotAuthorizedPage}
        />
        <Route
          path={match.url + '/edit/:id'}
          component={connectedCanDo(databaseAccess.edit) ? props => <EditForm {...props} /> : NotAuthorizedPage}
        />

        <Route
          path={match.url + '/details/:id'}
          component={connectedCanDo(databaseAccess.view) ? props => <DataModels {...props} /> : NotAuthorizedPage}
        />
        {/* <Route path={match.url + '/details/:id'} component={props => <DataBaseDetails {...props} />} /> */}
        <Route path={match.url} component={Home} />
      </Switch>
    </CanActivateModule>
  );
};

export default Routes;
