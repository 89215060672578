import { Theme } from '@material-ui/core/styles';
import { measures, StyleRulesCallback } from '../main.style';

export const styles: StyleRulesCallback = (theme: Theme) => ({
  drawerPaper: {
    position: 'relative',
    width: measures.drawerWidth,
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
  },
});
