import environment from 'src/envReader';
import { setToken } from 'src/store/authentication/actions';
import { getIsCheckingAuth } from 'src/store/authentication/selectors';
import * as Keycloak from 'keycloak-js/dist/keycloak.min';
import { AUTH_TOKEN_KEY } from 'src/store/authentication/constant';
declare let communityName: any;

interface IAuth {
  loggedIn: boolean;
  authz: Keycloak;
  logoutUrl: string;
}
export let auth: any = {
  loggedIn: false,
};
export function initKeycloakAuth(): Promise<IAuth> {
  //
  const keycloakAuth: any = Keycloak({
    realm: environment.communityName,
    url: environment.KeycloakServerUrl,
    clientId: environment.keycloakOptions.resource,
    resource: environment.keycloakOptions.resource,
    'ssl-required': environment.keycloakOptions['ssl-required'],
    credentials: {
      secret: environment.keycloakOptions.clientSecret,
    },
    'confidential-port': 0,
    'use-resource-role-mappings': true,
    // {
    //   "realm": "ocean",
    //   "auth-server-url": "http://keycloak-sso.assistahealth.com/auth",
    //   "ssl-required": "none",
    //   "resource": "oi",
    //   "verify-token-audience": true,

    //   "confidential-port": 0
    // }
  });

  auth.loggedIn = false;
  return new Promise((resolve, reject) => {
    keycloakAuth
      .init({ onLoad: 'login-required' })
      .success(() => {
        auth.loggedIn = true;
        auth.authz = keycloakAuth;
        auth.logoutUrl =
          keycloakAuth.authServerUrl +
          '/realms/' +
          environment.communityName +
          '/protocol/openid-connect/logout?redirect_uri=' +
          document.baseURI;

        localStorage[AUTH_TOKEN_KEY] = keycloakAuth.token;
        localStorage.username = keycloakAuth.tokenParsed.preferred_username;
        localStorage.userKey = keycloakAuth.subject;
        localStorage.userCommunity = keycloakAuth.tokenParsed.realmName; // used for system admin user
        localStorage.userTenantId = keycloakAuth.tokenParsed.tenant_id;
        if (keycloakAuth.tokenParsed.resource_access && keycloakAuth.tokenParsed.resource_access[keycloakAuth.clientId]) {
          localStorage.permissionList = keycloakAuth.tokenParsed.resource_access[keycloakAuth.clientId].roles;
        } else {
          localStorage.permissionList = [];
        }
        resolve(auth);
      })
      .error(() => {
        reject();
      });
  });
}

export function logout() {
  console.log('**  LOGOUT');
  if (auth.authz) {
    auth.loggedIn = false;
    auth.authz = null;
    window.location.href = auth.logoutUrl;
  }
}

export function getUsername(): string {
  return auth.authz.tokenParsed.preferred_username;
}

export function getFullName(): string {
  return auth.authz.tokenParsed.name;
}

export function getToken(): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    if (auth.authz.token) {
      auth.authz
        .updateToken(5)
        .success(() => {
          resolve(<string>auth.authz.token);
        })
        .error(() => {
          reject('Failed to refresh token');
        });
    } else {
      reject('Not logged in');
    }
  });
}
