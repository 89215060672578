import RawDataWizard from './RawDataWizard';
import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import questionStudioSelectors from 'src/store/questionStudio/selectors';
import questionStudioActions from 'src/store/questionStudio/questionStudioActions';

const mapState = (store: IRootState) => ({
  tables: questionStudioSelectors.unSelectedRawTable(store),
  isDisabled: !store.questionStudio.baseTable,
});
const mapDispatch = {
  onDone: questionStudioActions.pickRawColumns,
};

export type StateProps = ReturnType<typeof mapState>;
export type DispatchProps = typeof mapDispatch;

export default connect(
  mapState,
  mapDispatch
)(RawDataWizard);
