import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import SharedEntitiesList, { SharedEntityDto } from './SharedEntitiesList';

export interface EntitiesSharedWithUserLoaders {
  tables?: boolean;
  dashboards?: boolean;
  questions?: boolean;
}
interface EntitiesSharedWithUserProps {
  loaders: EntitiesSharedWithUserLoaders;
  questions: Array<SharedEntityDto>;
  dashboards: Array<SharedEntityDto>;
  tables: Array<SharedEntityDto>;
  //////
  onUnshareDashboards: (ids: number[]) => any;
  onUnshareQuestions: (ids: number[]) => any;
  onUnshareTables: (ids: number[]) => any;
}
const EntitiesSharedWithUser: React.SFC<EntitiesSharedWithUserProps> = ({
  loaders,
  questions,
  dashboards,
  tables,
  onUnshareDashboards,
  onUnshareQuestions,
  onUnshareTables,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const onChangeIndex = index => {
    setActiveTabIndex(index);
  };
  return (
    <div style={{ maxWidth: 600 }}>
      <Tabs value={activeTabIndex} onChange={(e, v) => onChangeIndex(v)} indicatorColor='primary' textColor='primary' variant='fullWidth'>
        <Tab label='Database Tables' />
        <Tab label='Dashboards' />
        <Tab label='Questions' />
      </Tabs>

      <SwipeableViews animate={false} index={activeTabIndex} onChangeIndex={onChangeIndex}>
        <SharedEntitiesList isLoading={loaders.tables} entities={tables} onUnshareSelectedEntities={onUnshareTables} />
        <SharedEntitiesList isLoading={loaders.dashboards} entities={dashboards} onUnshareSelectedEntities={onUnshareDashboards} />
        <SharedEntitiesList isLoading={loaders.questions} entities={questions} onUnshareSelectedEntities={onUnshareQuestions} />
      </SwipeableViews>
    </div>
  );
};
const defaultProps: Partial<EntitiesSharedWithUserProps> = {
  questions: [],
  dashboards: [],
  tables: [],
};
EntitiesSharedWithUser.defaultProps = defaultProps;
export default EntitiesSharedWithUser;
