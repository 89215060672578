import React, { useState } from 'react';

import { Input, CircularProgress, Tooltip, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
interface IProps {
  onSubmit: (file) => any;
  isUploading: boolean;
  uploadPrgoress: number;
}
export default function RestoreItemsForm(props: IProps) {
  const { onSubmit, isUploading, uploadPrgoress } = props;
  const [selectedFile, setSelectedFile] = useState(null as any);
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(selectedFile);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Input
        type='file'
        name='file'
        onChange={(e: any) => {
          setSelectedFile(e.target.files[0]);
        }}
      />
      {isUploading && <CircularProgress size={14} />}
      <Tooltip title='Restore a backup / import'>
        <Button type='submit' disabled={!selectedFile || isUploading}>
          <CloudUploadIcon color='secondary' style={{ marginRight: 10 }} /> Restore
          {isUploading && `uploading: ${uploadPrgoress}%`}
        </Button>
      </Tooltip>
    </form>
  );
}
