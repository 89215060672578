import * as React from 'react';
import * as echarts from 'echarts';
import { debounce } from 'src/modules/questions/studio/utils';
import { getChartOptions } from './utils';
import { ChartCustomizationOptions } from 'src/components/QuestionPreviewer/models';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { PieChartClickEvent } from 'src/shared/models/EchartsClickEvents';

interface IProps {
  customize?: ChartCustomizationOptions;
  data: Array<any>;
  meta: Array<any>;
  currentGroup?: ColumnMetadatum;
  onSegmentClicked: (e: PieChartClickEvent) => any;
  myRef?: (e: { resize: () => any }) => void;
  setChartRef: (e) => any;
}
const PieChart = (props: IProps) => {
  const { data = [], meta = [], customize = {}, myRef, currentGroup, onSegmentClicked } = props;
  const pieChartMainDiv = React.useRef<HTMLDivElement>(null);
  const [pieChart, setPieChart] = React.useState(undefined as echarts.ECharts);
  const [chartOptions, setChartOptions] = React.useState(getChartOptions(props));
  const onChartClicked = (e: PieChartClickEvent) => {
    onSegmentClicked && onSegmentClicked(e);
  };

  const updatechartOptions = () => {
    let options = getChartOptions(props);
    setChartOptions(options);
  };

  const refreshChart = () => {
    if (pieChart && !pieChart.isDisposed()) {
      pieChart.setOption(chartOptions);
      pieChart.resize();
    }
  };

  React.useEffect(
    () => {
      refreshChart();
    },
    [chartOptions]
  );

  React.useEffect(() => {
    let element = pieChartMainDiv;
    if (!pieChart) {
      const p = echarts.init(element.current);
      setPieChart(p);
      p.on('click', onChartClicked);
      props.setChartRef(p);
    }
    updatechartOptions();
    myRef && myRef({ resize: this.myChart.resize });
    return () => {
      if (pieChart) {
        pieChart.off('click');
        pieChart.dispose();
      }
    };
  }, []);

  React.useEffect(() => updatechartOptions(), [data, meta, customize, currentGroup]);
  return <div ref={pieChartMainDiv} style={{ height: '100%', width: '100%' }} />;
};
export default PieChart;
