import * as React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Checkbox, TextField, CardContent, CircularProgress } from '@material-ui/core';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import classNames from 'classnames';
import { ISimplePaginate, ISimpleTableProps } from 'components/simple-table/models';
import OiIconButton from 'src/components/OiIconButton';
import Styled from './styles';
import { getPaginationState } from './utils';

const headerCell = column => {
  if (typeof column.headerRenderer === 'object') {
    return column.headerRenderer(column);
  } else if (typeof column.headerRenderer === 'function') {
    const ColumnRenderer = column.headerRenderer;
    return <ColumnRenderer column={column} />;
  }
  return '' + column.title;
};
interface ISimpleTableState {
  paginator: ISimplePaginate;
  checked: Array<string>;
}
export class SimpleTable extends React.Component<ISimpleTableProps, ISimpleTableState> {
  state = {
    paginator: {
      page: 1,
      pageSize: 8,
    },
    checked: [],
  };

  navigateToPage(page) {
    this.setState(s => ({
      paginator: Object.assign({}, s.paginator, { page: page }),
      checked: s.paginator.page !== page ? [] : s.checked,
    }));
  }

  nextPage() {
    let pageSize = this.state.paginator.pageSize;
    let lastPage = Math.ceil(this.props.data.length / pageSize);
    let next = Math.min(this.state.paginator.page + 1, lastPage);
    this.navigateToPage(next);
  }

  prevPage() {
    let next = Math.max(this.state.paginator.page - 1, 1);
    this.navigateToPage(next);
  }

  onRowClick = (index, row) => e => {
    const { onRowClick } = this.props;
    onRowClick &&
      onRowClick({
        index,
        row,
      });
  };

  componentDidUpdate() {
    const { data } = this.props;
    const paginator = Object.assign({}, this.props.paginator || {}, this.state.paginator);
    let { pageSize, page } = paginator;
    let lastPage = Math.ceil(data.length / pageSize);
    const renderedData = data.slice(Math.max(page - 1, 0) * pageSize, Math.min(page, lastPage) * pageSize);
    if (renderedData.length == 0 && page > 1) {
      this.setState({
        paginator: {
          ...paginator,
          page: page - 1,
        },
      });
    }
  }

  handleCheckAll = e => {
    e.stopPropagation();
    const { checked } = this.state;
    const { onSelect, data } = this.props;
    const clone: Array<any> = checked.slice();
    const _paginator = Object.assign({}, this.props.paginator || {}, this.state.paginator);

    const { renderedData } = getPaginationState(data, _paginator);
    renderedData.forEach(item => {
      if (!this.isCheckedAll()) {
        if (!this.isChecked(item.id)) {
          clone.push(item.id);
        }
      } else {
        const itemIndex = clone.indexOf(item.id);
        if (itemIndex > -1) {
          clone.splice(itemIndex, 1);
        }
      }
    });
    this.setState({ checked: clone });
    onSelect && onSelect(clone);
  };

  isCheckedAll = (): boolean => {
    const { data } = this.props;
    const { checked } = this.state;

    const _paginator = Object.assign({}, this.props.paginator || {}, this.state.paginator);
    const { renderedData } = getPaginationState(data, _paginator);

    return renderedData.length > 0 && renderedData.every((data: any) => (checked as Array<any>).includes(data.id));
  };

  handleCheck = (e, value) => {
    e.stopPropagation();
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
    const { onSelect } = this.props;
    const { checked } = this.state;
    let clone: Array<string> = checked.slice();
    if (clone.includes(value)) {
      clone = clone.filter(c => c != value);
    } else {
      clone.push(value);
    }
    this.setState({ checked: clone });
    onSelect && onSelect(clone);
  };

  isChecked = (value: string): boolean => {
    const { checked } = this.state;
    return (checked as Array<string>).includes(value);
  };

  render() {
    const props = this.props;
    const { columns, data, classes } = props;
    const { paginator: paginatorState } = this.state;
    const onSortColumn = (e, col, dir) => {
      e.stopPropagation();
      e.preventDefault();
      const direction = dir ? dir : col.sort && col.sort.direction;
      props.onSortColumn && props.onSortColumn(col, direction);
    };
    const _paginator = Object.assign({}, this.props.paginator || {}, this.state.paginator);
    var { renderedData, paginator, pageOptions } = getPaginationState(data, _paginator);
    return (
      <>
        <Table className={classes.simpleTableStyle}>
          <TableHead>
            <TableRow className={classes.headRow}>
              {props.showIndex && <TableCell className={classes.theadCell}> # </TableCell>}
              {props.selectable && (
                <TableCell padding='checkbox' className={classes.theadCell} style={{ width: 30 }}>
                  <Checkbox checked={this.isCheckedAll()} onChange={e => this.handleCheckAll(e)} />
                </TableCell>
              )}
              {columns.map((column, i) => {
                let sortDirection = '';
                if (column.sort && column.sort.direction) {
                  sortDirection = column.sort.direction || '';
                }
                const nextSort = sortDirection === '' ? 'asc' : sortDirection === 'desc' ? '' : '';
                return (
                  <TableCell
                    className={classes.theadCell}
                    key={'__' + i}
                    onClick={e => onSortColumn(e, column, nextSort)}
                    style={{ width: column.width, ...column.headerStyle }}
                  >
                    {headerCell(column)}
                    {sortDirection === 'asc' && <ArrowUpward onClick={e => onSortColumn(e, column, 'desc')} />}
                    {sortDirection === 'desc' && <ArrowDownward onClick={e => onSortColumn(e, column, 'asc')} />}
                    {column.filterable && (
                      <div>
                        <TextField type='search' onChange={props.onColumnFiltered} />
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderedData.length ? (
              renderedData.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={row.id}
                    onClick={this.onRowClick(rowIndex, row)}
                    className={classes.bodyRow}
                    style={{
                      cursor: this.props.onRowClick ? 'pointer' : 'default',
                    }}
                  >
                    {props.showIndex && <TableCell key='0'> # </TableCell>}
                    {props.selectable && (
                      <TableCell padding='checkbox' key={rowIndex}>
                        <Checkbox
                          checked={this.isChecked(row.id)}
                          // onChange={e => this.handleCheck(e, row.id)}
                          onClick={e => {
                            e.stopPropagation();
                            this.handleCheck(e, row.id);
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.map((column, i) => {
                      const value = row[column.field];
                      let k = i + (props.showIndex ? 1 : 0) + (props.selectable ? 1 : 0);
                      return (
                        <TableCell
                          key={'__' + i}
                          onClick={e =>
                            props.onCellClicked &&
                            props.onCellClicked({
                              rowIndex: rowIndex,
                              cellIndex: k,
                              rowData: row,
                              e,
                            })
                          }
                        >
                          {column.cellRenderer ? column.cellRenderer(value, row, column) : '' + value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <>
                <TableRow>
                  {props.showIndex && <TableCell key='0'> # </TableCell>}
                  <TableCell colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
                    NO Data
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
        {paginator && paginator.isServerRendering}
        {(paginator && renderedData.length && (
          <div className={classes.simpleTableFooter}>
            <div className={classes.simpleTableFooterNavigators}>
              <OiIconButton
                disabled={paginatorState.page === 1}
                className={classes.paginationButton}
                aria-label='Back'
                onClick={e => this.prevPage()}
              >
                <ChevronLeft />
              </OiIconButton>
              {pageOptions.map((numb, i) => (
                <OiIconButton
                  key={i}
                  className={classNames(
                    {
                      [classes.activePaginationButton]: numb === this.state.paginator.page,
                    },
                    classes.paginationButton
                  )}
                  aria-label=''
                  onClick={e => this.navigateToPage(numb)}
                >
                  {numb}
                </OiIconButton>
              ))}
              <OiIconButton className={classes.paginationButton} aria-label='Next' onClick={e => this.nextPage()}>
                <ChevronRight />
              </OiIconButton>
            </div>
          </div>
        )) || <></>}
        {props.showLoading && (
          <CardContent className='text-center'>
            <CircularProgress color='primary' />
            <div>Loading ...</div>
          </CardContent>
        )}
      </>
    );
  }
}

export default Styled(SimpleTable);
