import { ConfirmConfigs } from './index';

export type IAppUtils = {
  confirm: (configs: ConfirmConfigs) => Promise<boolean>;
  sagaConfirm: (msg: string) => any;
};
const confirmUtils = {
  sagaConfirm: (msg: string) => async () => await confirmUtils.confirm({ msg } as ConfirmConfigs),
} as IAppUtils;

export default confirmUtils;
