import * as React from 'react';
import {
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
  TextField,
  Theme,
  StyleRulesCallback,
  withStyles,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PrimaryColumnSelect from 'src/components/PrimaryColumnSelect/PrimaryColumnSelect';

const OceanDataType = [
  { name: 'NUMBER', displayName: 'Number' },
  { name: 'STRING', displayName: 'String' },
  { name: 'DATE', displayName: 'Date' },
  { name: 'BOOLEAN', displayName: 'Boolean' },
  { name: 'OTHER', displayName: 'Other' },
  { name: 'JSON', displayName: 'Json' },
  { name: 'CATEGORY', displayName: 'Category' },
  { name: 'QUANTITY', displayName: 'Quantity' },
  { name: 'VALUE', displayName: 'Value' },
  { name: 'CREATION_TIMESTAMP', displayName: 'Creation timestamp' },
  { name: 'STATUS', displayName: 'status' },
  { name: 'BIRTHDAY', displayName: 'Birthday' },
  { name: 'REFERENCE', displayName: 'Referece' },
  { name: 'DRUG_NAME', displayName: 'Drug name' },
  { name: 'DRUG_CODE', displayName: 'Drug code' },
  { name: 'DIAGNOSIS_CODE', displayName: 'Diagnosis code' },
  { name: 'DIAGNOSIS_DESC', displayName: 'Diagnosis desc' },
];
const ColumnForm = ({ column, onChange, foreignList: foreignColumnsList, isForeign, isSpecial, isFilter, onChangeFlag, collapse }) => {
  isForeign = isForeign || column.fkSqlColumnId != null ? true : false;
  isSpecial = isSpecial || column.valueDisplayFormat != null ? true : false;
  isFilter = isFilter || column.filterComponentType != null ? true : false;

  return (
    <>
      <Grid container>
        <Grid style={{ paddingRight: '30px' }} item xs={12} md={collapse ? 6 : 12} lg={collapse ? 6 : 12}>
          <div>
            <TextField
              fullWidth
              id='name'
              label='Name'
              margin='normal'
              variant='outlined'
              value={column.dbName}
              disabled
              onChange={e => {
                column.name = e.target.value;
                onChange(column);
              }}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id='displayName'
              name='displayName'
              label='Display Name'
              variant='outlined'
              margin='normal'
              value={column.displayName}
              onChange={e => {
                column.displayName = e.target.value;
                onChange(column);
              }}
            />
          </div>
          <div>
            <TextField
              id='column-type'
              label='Column type '
              name='type'
              margin='normal'
              variant='outlined'
              fullWidth
              select
              value={column.type}
              onChange={e => {
                column.type = e.target.value;
                onChange(column);
              }}
              // helperText="select Column type "
            >
              <MenuItem value='DIMENSION' key={1}>
                Dimension
              </MenuItem>
              <MenuItem value='MEASURE' key={2}>
                Measure
              </MenuItem>
            </TextField>
          </div>
          <div>
            <TextField
              id='specialType-select'
              margin='normal'
              label='Special Type '
              variant='outlined'
              fullWidth
              select
              name='specialType'
              value={column.oceanDataType}
              onChange={e => {
                column.oceanDataType = e.target.value;
                onChange(column);
              }}
              // helperText="select Column type "
            >
              {OceanDataType.map((type, index) => (
                <MenuItem value={type.name} key={index}>
                  {type.displayName}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div>
            <TextField
              fullWidth
              disabled
              // InputProps={{
              //     readOnly: true,
              //   }}
              id='dbType'
              name='dbType'
              variant='outlined'
              label='DB Column type '
              margin='normal'
              value={column.dbType}
              onChange={e => {
                column.dbType = e.target.value;
                onChange(column);
              }}
            />
          </div>

          <div>
            <TextField
              fullWidth
              id='Description-static'
              name='description'
              label='Description'
              variant='outlined'
              placeholder='Add Description'
              multiline
              rows='4'
              value={column.description || ''}
              margin='normal'
              onChange={e => {
                column.description = e.target.value;
                onChange(column);
              }}
            />
          </div>
        </Grid>

        {/* Check boxes */}
        <Grid style={{ paddingRight: '30px' }} item xs={12} md={collapse ? 6 : 12} lg={collapse ? 6 : 12}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  value={!column.active ? 'checked' : ''}
                  checked={!column.active ? true : false}
                  onChange={e => {
                    column.active = !e.target.checked ? true : false;
                    onChangeFlag(column);
                  }}
                />
              }
              label='Hide'
            />
          </div>

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  disabled
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={column.pk ? true : false}
                  onChange={e => {
                    column.pk = e.target.checked ? true : false;
                    onChange(column);
                  }}
                />
              }
              label='Primary Key'
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={column.fkSqlColumnId !== null || isForeign ? true : false}
                  onChange={e => {
                    isForeign = e.target.checked ? true : false;
                    onChangeFlag({ name: 'isForeign', value: isForeign });
                    column.fkSqlColumnId = null;
                    onChange(column);
                  }}
                />
              }
              label='foreign Key'
            />

            {isForeign ? (
              <PrimaryColumnSelect
                columnsList={foreignColumnsList}
                onChange={colId => {
                  column.fkSqlColumnId = colId;
                  onChange(column);
                }}
                selectedColId={column.fkSqlColumnId}
              />
            ) : (
              ''
            )}
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  checked={isFilter}
                  onChange={e => {
                    isFilter = e.target.checked ? true : false;
                    onChangeFlag({ name: 'isFilter', value: isFilter });
                    column.filterComponentType = null;
                    if (!isFilter) onChange(column);
                  }}
                />
              }
              label='Filter Component'
            />
            {isFilter ? (
              <TextField
                id='filterComponentType-select'
                label='Filter component type'
                name='filterComponentType'
                variant='outlined'
                select
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={column.filterComponentType}
                onChange={e => {
                  column.filterComponentType = e.target.value;
                  onChange(column);
                }}
              >
                <MenuItem value='SEARCH_BOX' key={1}>
                  Search box
                </MenuItem>
                <MenuItem value='LIST_ALL_VALUES' key={2}>
                  List all values
                </MenuItem>
              </TextField>
            ) : (
              ''
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const style: StyleRulesCallback = (theme: Theme) => ({
  inputWidth: {
    width: '90%',
  },
});

export default withStyles(style)(ColumnForm);
