let defaultEchartsColors = [
  '#c23531',
  '#2f4554',
  '#61a0a8',
  '#d48265',
  '#91c7ae',
  '#749f83',
  '#ca8622',
  '#bda29a',
  '#6e7074',
  '#546570',
  '#c4ccd3',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
];
const colorsList = [
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#3B3EAC',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
];
const echartsSchemasLite = [
  '#37A2DA',
  '#32C5E9',
  '#67E0E3',
  '#9FE6B8',
  '#FFDB5C',
  '#ff9f7f',
  '#fb7293',
  '#E062AE',
  '#E690D1',
  '#e7bcf3',
  '#9d96f5',
  '#8378EA',
  '#96BFFF',
];
const echartsSchemas3 = [
  '#dd6b66',
  '#759aa0',
  '#e69d87',
  '#8dc1a9',
  '#ea7e53',
  '#eedd78',
  '#73a373',
  '#73b9bc',
  '#7289ab',
  'rgb(153, 102, 255)',
  'rgb(54, 162, 235)',
  '#91ca8c',
  '#f49f42',
];
const chartJsColors = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
  '#e7bcf3',
  '#9d96f5',
  '#96BFFF',
  '#fb7293',
  '#E062AE',
  '#E690D1',
];
const colorsSchema4 = [
  '#3FA7DC',
  '#7091C4',
  '#5170A2',
  '#E1CA74',
  'rgba(91, 192, 235, 1)',
  'rgba(253, 231, 76, 1)',
  'rgba(155, 197, 61, 1)',
  'rgba(229, 89, 52, 1)',
  'rgba(250, 121, 33, 1)',
  '#67E0E3',
  '#fb7293',
  '#E062AE',
  '#E690D1',
];
export const DefaultColorsPallette = chartJsColors;
export const ColorsSchemasList = [chartJsColors, defaultEchartsColors, echartsSchemasLite, echartsSchemas3, colorsSchema4];
