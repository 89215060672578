import { withStyles, createStyles, WithStyles } from '@material-ui/core';
import { Colors } from 'src/shared/theme';

const styles = () =>
  createStyles({
    card: {
      width: 280,
      height: 180,
      padding: 10,
      position: 'relative',
      '&:hover .fav-action-buttons': {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    thumbImage: {
      backgroundSize: 'contain',
      height: 110,
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    actionButtons: {
      display: 'none',
      position: 'absolute',
      zIndex: 5,
      top: 0,
      height: 180,
      width: 280,
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      left: 0,
      right: 0,
      margin: '0 auto',
      background: 'rgba(255, 255, 255, 0.9)',
      textAlign: 'center',
      '& svg': {
        fontSize: 20,
        color: Colors.brandDarkGrey,
      },
      '& > button': {
        height: 35,
        width: 35,
        border: '2px solid ' + Colors.brandDarkGrey,
        margin: '0 10px',
      },
    },
    closeButton: {
      position: 'absolute',
      top: 5,
      right: 0,
      height: 30,
      width: 30,
      '& svg': {
        fontSize: 20,
      },
    },
    cardContent: {
      padding: '10px',
    },
  });

export interface styleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);
export default Styled;
