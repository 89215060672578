import React from 'react';
import { Tooltip } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import OiIconButton from 'src/components/OiIconButton';
import OiIcon from 'src/assets/icons';
import TitleInput from '../TitleInput';
import { OiFunction } from 'src/shared/models/OiFunction';
import { ColumnType } from 'src/shared/models/SqlColumn';

const HeadingItem = props => (
  <div className={props.headingItem}>
    <OiIconButton onClick={props.toggleExpansion}> {props.expanded ? <ExpandLess /> : <ExpandMore />} </OiIconButton>

    <Tooltip title='Pin column'>
      <OiIconButton onClick={e => props.onPinChanged()}>
        {' '}
        <OiIcon viewBox='0 0 24 24' path={'Pin'} color={props.model.pin ? '#048edd' : '#a3a3a3'} />{' '}
      </OiIconButton>
    </Tooltip>
    {props.model.functionItem && props.model.functionItem.value == OiFunction.NONE && props.model.column.type == ColumnType.DIMENSION && (
      <Tooltip title='Group values'>
        <OiIconButton onClick={e => props.onToggleGroupByColumn()}>
          {' '}
          <OiIcon scale={16} path={'ColumnGroupBy'} color={props.model.grouped ? '#048edd' : '#a3a3a3'} />{' '}
        </OiIconButton>
      </Tooltip>
    )}
    <TitleInput onTitleChanged={v => props.handleLabelChanged(v || '')} value={props.displayName} />
    <Tooltip title='Delete column'>
      <OiIconButton onClick={props.onDelete}>
        {' '}
        <Delete />{' '}
      </OiIconButton>
    </Tooltip>
  </div>
);

export default HeadingItem;
