import * as React from 'react';

import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import ActionFooter from 'src/layout/ActionFooter';
import Form from './components/form';
import style from './CreatorStyle';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Permissions from './components/permissions';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { StateProps, DispatchProps } from './index';
import { RouteComponentProps } from 'react-router-dom';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import Divider from '@material-ui/core/Divider/Divider';
import { BasicUserFormData } from './components/form/models';

interface iProps extends StateProps, DispatchProps, RouteComponentProps {
  onSave: (e: any) => void;
  edit: boolean;
  classes: any;
}
interface IState {
  permissions: Array<string>;
  form: BasicUserFormData;
  formErrorMessage: string;
}

class Creator extends React.Component<iProps, IState> {
  state: IState = {
    permissions: [],
    form: {} as BasicUserFormData,
    formErrorMessage: '',
  };
  componentDidMount() {
    const { getAuthorities, getPermissions } = this.props;
    getAuthorities();
    getPermissions();
  }

  handleSubmit = e => {
    e.preventDefault();
    if (!this.checkInfo()) {
      this.setState({ formErrorMessage: 'Please fill all fields' });
      return;
    }

    const { onSave } = this.props;
    const { permissions, form } = this.state;
    const body = {
      info: form,
      permissions: permissions,
    };
    onSave(body);
  };
  checkInfo(): boolean {
    const { form } = this.state;
    return Object.keys(form).length >= 6;
  }
  handleFormChange = (data: BasicUserFormData) => {
    let formData = { ...data };
    formData.realmRoles = [formData.authorities];
    delete formData.authorities;
    this.setState({ form: formData });
  };
  handlePermissionsChange = permission => {
    this.setState({ permissions: permission });
  };
  render() {
    const { formErrorMessage, form: formData } = this.state;
    const { classes, loader, history, edit } = this.props;
    return (
      <React.Fragment>
        <Grid className={classes.container} container spacing={8}>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <CardHeader className={classes.cardHeader} title={'User information'} />
              <Divider />
              <CardContent className={classes.cardBody}>
                <Typography color={'error'} variant='subtitle1'>
                  {formErrorMessage}
                </Typography>
                <Form onChange={this.handleFormChange} isEdit={edit} />
                <div className={classes.loaderWrapper}>{loader && <CircularProgress size={40} color='secondary' />}</div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Card className={classes.card}>
              <CardHeader className={classes.cardHeader} title={'Permissions'} />
              <Divider />
              <CardContent className={classes.cardBody}>
                <Permissions onChange={this.handlePermissionsChange} isEdit={edit} userRoles={formData.realmRoles} />
                <div className={classes.loaderWrapper}>{loader && <CircularProgress size={40} color='secondary' />}</div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <ActionFooter
          isSaveDisabled={loader}
          handleSave={e => this.handleSubmit(e)}
          handleCancel={() => {
            history.push('/users');
          }}
        />
      </React.Fragment>
    );
  }
}

const Styled = withStyles(style)(Creator);
export default Styled;
