import * as React from 'react';
import { Drawer, Paper, Typography, Button } from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';
import OiIconButton from 'src/components/OiIconButton';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import { QueryFilterViewItem, QuestionStudioStoreState } from 'src/modules/questions/studio/models';
import { OceanDataType } from 'src/shared/models/SqlColumn';
import { isOperatorCurrentOrLast } from 'src/shared/models/OiFilter';
import moment from 'moment';
import TitledBorder from 'components/TitledBorder';
import QuestionFilteringItem from 'src/modules/questions/studio/IngredientsPanel/QuestionFilteringItem/QuestionFilteringItem';
import OiIcon from 'src/assets/icons';
import TooltipedIconButton from '../TooltipedIconButton';
import { APP_LOCAL_DATE_FORMAT } from 'src/constants';

type IProps = {
  questionDto?: OiQuestionDto;
  questionModel?: QuestionStudioStoreState;
  isLoadingPreview?: boolean;
  icon?: any;
  onExecuteFilters: (filters: QueryFilterViewItem[]) => any;
};
export default class DynamicFiltersPopup extends React.Component<IProps> {
  state = {
    fileName: '',
    anchorEl: undefined,
    questionFilters: [] as QueryFilterViewItem[],
    isOpen: false as boolean,
  };

  get filtersItems() {
    return ((this.props.questionModel && this.props.questionModel.filtersItems) || []).filter(c => c.dynamic);
  }

  componentDidMount() {
    this.setState({
      questionDynamicFilters: (this.props.questionModel && this.props.questionModel.filtersItems) || [],
    });
  }

  toggleDrawer = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  onChangeFilterItem = (itemObj, itemIndex) => {
    let filtersItems = this.filtersItems.slice();
    Object.assign(filtersItems[itemIndex], itemObj);
    this.setState({ questionFilters: filtersItems, mayNeedRefresh: true });
  };

  mapFilterToQuestionFilteringItem = (filter, i) => (
    <QuestionFilteringItem
      key={i}
      item={filter}
      itemIndex={i}
      source={'questionView'}
      onUpdated={this.onChangeFilterItem}
      columns={this.props.questionModel!.baseTable.columns}
    />
  );

  render() {
    const filterItemsListDom = (this.filtersItems.length && (
      <div className='filter-by-list'>
        <TitledBorder color={'#10ab5b'}>Filter By</TitledBorder>
        {this.filtersItems.length ? (
          this.filtersItems.map(this.mapFilterToQuestionFilteringItem)
        ) : (
          <Typography variant='body1'> No Dynamic Filters Yet! </Typography>
        )}
      </div>
    )) || <></>;
    return (
      <>
        <TooltipedIconButton
          tooltip='Edit dynamic filters'
          aria-label='type'
          aria-owns='type-menu'
          aria-haspopup='true'
          onClick={event => this.toggleDrawer()}
        >
          {this.props.icon || <OiIcon path='FilterFunnel' />}
        </TooltipedIconButton>

        <Drawer open={this.state.isOpen} onClose={e => this.toggleDrawer()} anchor='right'>
          <Paper
            style={{ width: 400, padding: 20, display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            onDrag={e => e.stopPropagation()}
          >
            <div style={{ flex: '1 1 auto', width: '100%' }}>{filterItemsListDom}</div>
            <Button
              className='btn-rounded'
              variant='contained'
              type='button'
              color='primary'
              disabled={this.props.isLoadingPreview}
              onClick={e => {
                this.props.onExecuteFilters(this.state.questionFilters);
              }}
            >
              {this.props.isLoadingPreview ? (
                <>
                  <RefreshIcon /> Loading
                </>
              ) : (
                ' Preview '
              )}
            </Button>
          </Paper>
        </Drawer>
      </>
    );
  }
}
