import { StyleRulesCallback, Theme, createStyles, withStyles } from '@material-ui/core';
import { Colors } from 'src/shared/theme';
export const WizardStyles = {
  H14px: {
    fontSize: 15,
  },
  slide: {
    width: 350,
    height: 400,
    overflow: 'hidden',
    zIndex: 100,
  },
  scrollBar: {
    width: 350,
    height: 350,
  },
  searchField: {
    margin: '10px 0px',
    marginBottom: 0,
  },
  listItem: {
    padding: 5,
  },
  tableIcon: {
    fontSize: 14,
    color: Colors.brandBlue,
  },
  tablesList: { margin: 0 },
  toolbar: {
    display: 'flex',
    padding: 2,
    justifyContent: 'flex-end',
    borderBottom: `1px solid ${Colors.brandGreyBorders}`,
  },
  tableNameWrap: {
    paddingLeft: '10px',
  },
  expandIcon: {
    margin: 'auto',
    marginRight: 0,
  },
};
export const styles: StyleRulesCallback = (theme: Theme) => createStyles(WizardStyles);
export default withStyles(styles);
