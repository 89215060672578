import * as React from 'react';
import { QuestionViewMode } from 'src/components/QuestionPreviewer/QuestionPreviewer';
import OiIconButton from 'src/components/OiIconButton';
import QuestionPreviewerByQuestionId from 'src/components/QuestionPreviewerByQuestionId';
import Cancel from '@material-ui/icons/Cancel';
import ResponsiveGrid from 'modules/dashboards/Studio/components/ResponsiveGrid';
import questionStudioServices from 'src/modules/questions/studio/questionStudioServices';

export class DashboardGrid extends React.Component<any> {
  constructor(props) {
    super(props);
    this.QuestionRefs = [];
  }
  QuestionRefs = [];
  myRef: any = [];

  handleRemoveItem = i => {
    const { onChangeTabs, tabs, selected } = this.props;
    let _tabs = tabs.slice();
    _tabs[selected].views = tabs[selected].views.filter(view => view.i !== i);
    onChangeTabs(_tabs);
  };

  handleChange(tab: any, tabs: any, tabIndex: any, onChangeTabs: any): any {
    return e => {
      let views = tab.views.map((v, i) => ({ ...v, ...e[i] }));
      let _tabs = tabs.slice(0);
      _tabs[tabIndex] = { ...tab, views };
      onChangeTabs(_tabs);
    };
  }

  componentDidUpdate(prefProps) {
    const { filters: filtersVm, selected } = this.props;
    if (filtersVm !== prefProps.filters && filtersVm.length > 0) {
      const filtersDto = filtersVm.map(questionStudioServices.mapQuestionFilterItemToOiQueryFilter);
      Object.keys(this.myRef).forEach(key => {
        const _myRef = this.myRef;
        if (key.split('-')[0] == selected) {
          _myRef[key].executeWithDynamicFilters(filtersDto, true);
        }
      });
    }
  }

  render() {
    const { isViewMode, tabs, resizeAgain, onChangeTabs, onChangeRefs, tab, tabIndex } = this.props;
    const views = tab.views || [];
    const isEditMode = !isViewMode;
    return (
      <ResponsiveGrid
        key={tabIndex}
        isViewMode={isViewMode}
        views={views}
        onWidthChange={e => this.QuestionRefs.map((r: any) => r.resize())}
        handleDragStop={this.handleChange(tab, tabs, tabIndex, onChangeTabs)}
        handleResizeStop={this.handleChange(tab, tabs, tabIndex, onChangeTabs)}
      >
        {views.map((el, vIndex) => (
          <div key={el.i} className='data-grid data-preview table-dimensions-ref chart-dimensions-ref'>
            <QuestionPreviewerByQuestionId
              key={tabIndex + '-' + vIndex}
              questionId={el.questionId}
              callResize={resizeAgain}
              ref={ref => {
                this.QuestionRefs[tabIndex + '-' + vIndex] = ref;
                onChangeRefs(this.QuestionRefs, ref);
              }}
              myRef={(r: any) => {
                this.myRef[tabIndex + '-' + vIndex] = r;
              }}
              initialViewOptions={el.viewCustomizationOptions || {}}
              toolBarStartButtons={
                (isEditMode && (
                  <OiIconButton
                    onClick={e => {
                      this.handleRemoveItem(el.i);
                    }}
                  >
                    <Cancel />
                  </OiIconButton>
                )) ||
                ''
              }
              mode={isViewMode ? QuestionViewMode.DASHBOARD_STUDIO_VIEW : QuestionViewMode.DASHBOARD_STUDIO_EDIT}
            />
          </div>
        ))}
      </ResponsiveGrid>
    );
  }
}

export default DashboardGrid;
