import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import React from 'react';
import { TextValueFilter } from './TextValueFilter';
export default function NumericValuesFilterEditor(props) {
  const { classes, values, operator, onBlur, onKeyDown, onChange } = props;
  if (operator === ComparisonOperator.BETWEEN) {
    return (
      <div className={classes.flexWrapper}>
        <TextValueFilter value={values[0]} label='Value 1' onBlur={onBlur} onKeyDown={onKeyDown} onChange={onChange(0)} />
        <div>and</div>
        <TextValueFilter value={values[1]} label='Value 2' onBlur={onBlur} onKeyDown={onKeyDown} onChange={onChange(1)} />
      </div>
    );
  } else {
    return <TextValueFilter value={values[0]} label='enter value' onBlur={onBlur} onKeyDown={onKeyDown} onChange={onChange(0)} />;
  }
}
