import React, { useState } from 'react';
import RestoreItemsForm from './RestoreItemsForm';
import oceancoreApis from 'src/shared/apis/oceancoreApis';
import toastr from 'src/components/toastr';

export default function RestoreItemsFormContainer(props) {
  const [uploadPrgoress, setUploadPrgoress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const handleSubmitFileRestore = selectedFile => {
    setIsUploading(true);
    oceancoreApis
      .importManyMetaQuestionsOrDashboards(selectedFile, ProgressEvent => {
        setIsUploading(false);
        setUploadPrgoress((ProgressEvent.loaded / ProgressEvent.total) * 100);
      })
      .then(s => {
        setIsUploading(false);
        setUploadPrgoress(0);
        toastr.success('imported successfully');
      });
  };
  return <RestoreItemsForm uploadPrgoress={uploadPrgoress} isUploading={isUploading} onSubmit={handleSubmitFileRestore} />;
}
