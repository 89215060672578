import * as React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

const BorderedTitle = ({ classes, color, className, ...props }: any) => (
  <h5 {...props} className={classNames(classes.h5, className)} style={{ color, ...props.style }}>
    {props.children}
    <span className={classes.border} style={{ borderBottomColor: color }} />
  </h5>
);
const TitledBorder = withStyles({
  h5: {
    display: 'flex',
    width: '100%',
    margin: 'unset',
  },
  border: {
    content: ' ',
    display: 'flex',
    borderBottom: '1px solid black',
    marginLeft: '0.5em',
    marginBottom: '0.5em',
    flex: '1 1 auto',
  },
})(BorderedTitle);

export default TitledBorder;
