import React from 'react';
import CreatableAutoCompleteSelect from 'react-select/lib/Creatable';
import ReactAutoCompleteSelect from 'react-select';
import { FormControl, FormLabel, CircularProgress } from '@material-ui/core';
// import { Props as SelectProps } from "react-select/src/Select"
const styles = {
  menu: s => ({
    ...s,
    zIndex: 100,
  }),
  menuList: s => ({
    ...s,
    zIndex: 100,
  }),
  menuPortal: s => ({
    ...s,
    zIndex: 100,
  }),
};
interface IProps {
  //extends SelectProps
  isLoadingOptions?: boolean;
  isCreatable: boolean;
  label: string;
  [key: string]: any;
}
export default function TitledAutoComplete(props: IProps) {
  const { isCreatable, label, isLoadingOptions } = props;
  return (
    <FormControl fullWidth style={{ marginBottom: 10 }}>
      <FormLabel
        style={{
          fontSize: 12,
          zIndex: 1,
          background: 'white',
          display: 'inline-block',
          width: 'max-content',
          padding: '0px 5px 2px',
          paddingBottom: 0,
          position: 'absolute',
          top: '-0.5em',
          left: 9,
        }}
      >
        {isLoadingOptions && <CircularProgress size={12} />} {label}
      </FormLabel>
      {isCreatable ? (
        <CreatableAutoCompleteSelect name={label} {...props} styles={styles} />
      ) : (
        <ReactAutoCompleteSelect name={label} {...props} styles={styles} />
      )}
    </FormControl>
  );
}
