import { Theme, StyleRules } from '@material-ui/core/styles';

export const measures = {
  drawerWidth: 240,
  headerHeight: 70,
  footerHeight: 55,
};

export type StyleRulesCallback<ClassKey extends string = string> = (theme: Theme) => StyleRules<ClassKey>;

export const styles: StyleRulesCallback = (theme: Theme) => ({
  contentLeft: {
    marginLeft: -measures.drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentShiftLeft: {
    marginLeft: 0,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  main: {
    minHeight: `calc(100% - ${measures.headerHeight}px)`,
    width: '100%',
    display: 'flex',
  },
  mainContainer: {
    padding: '0px',
    background: '#eaebef',
  },
  mainContent: {
    height: `calc(100% - ${measures.footerHeight}px)`,
    paddingBottom: 0,
    position: 'relative',
    width: '100%',
  },
});
