import SelectDatabase from './SelectDataset';
import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import { getAllDatabases } from 'src/store/databases/actions';
import questionStudioActions from 'src/store/questionStudio/questionStudioActions';
const mapStateToProps = ({ databases }: IRootState) => ({
  isLoadingList: databases.loading,
  databasesList: databases.databasesList,
});

const mapDispatchToProps = {
  getAllDatabases,
  onSelectDb: questionStudioActions.onSelectDb,
  onSelectTable: questionStudioActions.onSelectBaseTable,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect<any, any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(SelectDatabase);
