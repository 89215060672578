import { SQLDatabase } from 'src/shared/models/SqlDatabase';
import { SQLTable } from 'src/shared/models/SqlTable';

import { createAction } from 'store/util';
import { QueryViewItem, QueryGroupViewItem, QueryFilterViewItem } from 'src/modules/questions/studio/models';
import { SQLColumn } from 'src/shared/models/SqlColumn';
const prefix = 'Q_STUDIO_';
const TYPES = {
  RESET_STATE: prefix + 'RESET_STATE',
  LOAD_QUESTION_BY_ID: prefix + 'LOAD_QUESTION_BY_ID',
  RESET_INGREDIENTS_PANEL_DATA: prefix + 'RESET_INGREDIENTS_PANEL_DATA',
  ON_SELECT_DB: prefix + 'ON_SELECT_DB',
  ON_VIEW_REFRESHED: prefix + 'ON_VIEW_REFRESHED',

  ON_SELECT_BASE_TABLE: prefix + 'ON_SELECT_BASE_TABLE',
  TOGGLE_EXPAND_FORM: prefix + 'TOGGLE_EXPAND_FORM',

  BATCH_STATE: prefix + 'UPDATE_STATE',
  ON_FORM_MODEL_UPDATED: prefix + 'ON_FORM_MODEL_UPDATED',

  PICK_RAW_DATA: prefix + 'PICK_RAW_DATA',
  PICK_RAW_COLUMNS: prefix + 'PICK_RAW_COLUMNS',
  PICK_ROWS_COUNT: prefix + 'PICK_ROWS_COUNT',
  PICK_VIEW_ITEM: prefix + 'PICK_VIEW_ITEM',

  ADD_RAW_DATA: prefix + 'ADD_RAW_DATA',
  ADD_VIEW_ITEM: prefix + 'ADD_VIEW_ITEM',

  PICK_GROUP_ITEM: prefix + 'PICK_GROUP_ITEM',
  ADD_GROUP_ITEM: prefix + 'ADD_GROUP_ITEM',
  ON_ADD_CALCULATED_COLUMN: prefix + 'ON_ADD_CALCULATED_COLUMN',
  ON_DELETE_CALCULATED_COLUMN: prefix + 'ON_DELETE_CALCULATED_COLUMN',
  ON_CHANGE_CALCULATED_COLUMN: prefix + 'ON_CHANGE_CALCULATED_COLUMN',

  ADD_FILTER_ITEM: prefix + 'ADD_FILTER_ITEM',
  PICK_FILTER_ITEM: prefix + 'PICK_FILTER_ITEM',

  UPDATE_VIEW_ITEM: prefix + 'UPDATE_VIEW_ITEM',
  UPDATE_GROUP_ITEM: prefix + 'UPDATE_GROUP_ITEM',
  UPDATE_FILTER_ITEM: prefix + 'UPDATE_FILTER_ITEM',

  ON_ADD_ADVANCE_FILTER: prefix + 'ON_ADD_ADVANCE_FILTER',
  UPDATE_ADVANCE_FILTERS_ROOT: prefix + 'UPDATE_ADVANCE_FILTERS_ROOT',
  DELETE_ADVANCE_FILTERS_ROOT: prefix + 'DELETE_ADVANCE_FILTERS_ROOT',

  REMOVE_VIEW_ITEM: prefix + 'REMOVE_VIEW_ITEM',
  REMOVE_GROUP_ITEM: prefix + 'REMOVE_GROUP_ITEM',
  REMOVE_FILTER_ITEM: prefix + 'REMOVE_FILTER_ITEM',
  ON_SET_RESULT_LIMIT: prefix + 'ON_SET_RESULT_LIMIT',

  LOAD_DRILLED_DOWN_QUESTION: prefix + 'LOAD_DRILLED_DOWN_QUESTION',

  PREVIEW_QUESTION: prefix + 'PREVIEW_QUESTION',
};
export const ACTION_TYPES = TYPES;

type ILOAD_QUESTION_BY_IDPayload = {
  db: SQLDatabase;
  table: SQLTable;
  column: SQLColumn;
  columnValue: any;
  filterOut: boolean;
  filtersItems: QueryFilterViewItem[];
};
export const questionStudioActions = {
  //   onSelectDbById: ['dbId'], // may be needed
  // resetStudioState
  loadQuestionById: id => createAction(TYPES.LOAD_QUESTION_BY_ID, { id }),
  //loadDrilledDownQuestion payload : db, table, column, columnValue, filterOut, filtersItems
  loadDrilledDownQuestion: (payload: ILOAD_QUESTION_BY_IDPayload) => createAction(TYPES.LOAD_QUESTION_BY_ID, payload),

  resetState: () => createAction(TYPES.RESET_STATE),
  resetIngredientsPanelData: () => createAction(TYPES.RESET_INGREDIENTS_PANEL_DATA),
  batchState: batch => createAction(TYPES.BATCH_STATE, { batch }),

  onSelectDb: (db: SQLDatabase, tables?: SQLTable[]) => createAction(TYPES.ON_SELECT_DB, { db, tables }),
  onSelectBaseTable: (table: SQLTable) => createAction(TYPES.ON_SELECT_BASE_TABLE, { table }),

  onViewRefreshed: () => createAction(TYPES.ON_VIEW_REFRESHED),
  toggleExpandFormCard: () => createAction(TYPES.TOGGLE_EXPAND_FORM),
  onFormModelUpdated: () => createAction(TYPES.ON_FORM_MODEL_UPDATED),

  pickRawData: dbTableName => createAction(TYPES.PICK_RAW_DATA, { dbTableName }),
  pickRawColumns: tables => createAction(TYPES.PICK_RAW_COLUMNS, { tables }),
  pickRowsCount: () => createAction(TYPES.PICK_ROWS_COUNT),
  pickViewItem: (viewItem: QueryViewItem) => createAction(TYPES.PICK_VIEW_ITEM, { viewItem }),

  addRawData: (viewsItems: QueryViewItem[]) => createAction(TYPES.ADD_RAW_DATA, { viewsItems }),
  addViewItem: (viewItem: QueryViewItem) => createAction(TYPES.ADD_VIEW_ITEM, { viewItem }),

  pickGroupItem: (groupItem: QueryGroupViewItem) => createAction(TYPES.PICK_GROUP_ITEM, { groupItem }),
  addGroupItem: groupItem => createAction(TYPES.ADD_GROUP_ITEM, { groupItem }),

  pickFilterItem: filterItem => createAction(TYPES.PICK_FILTER_ITEM, { filterItem }),
  addFilterItem: filterItem => createAction(TYPES.ADD_FILTER_ITEM, { filterItem }),

  updateViewItem: (itemIndex, updatedItem) => createAction(TYPES.UPDATE_VIEW_ITEM, { itemIndex, updatedItem }),
  updateGroupItem: (itemIndex, groupItem) => createAction(TYPES.UPDATE_GROUP_ITEM, { itemIndex, groupItem }),
  updateFilterItem: (itemIndex, filterItem) => createAction(TYPES.UPDATE_FILTER_ITEM, { itemIndex, filterItem }),

  updateAdvanceFiltersRoot: groupBatch => createAction(TYPES.UPDATE_ADVANCE_FILTERS_ROOT, { groupBatch }),
  deleteAdvanceFiltersRoot: () => createAction(TYPES.DELETE_ADVANCE_FILTERS_ROOT, {}),

  removeViewItem: viewItem => createAction(TYPES.REMOVE_VIEW_ITEM, { item: viewItem }),
  removeGroupItem: groupItem => createAction(TYPES.REMOVE_GROUP_ITEM, { item: groupItem }),
  removeFilterItem: filterItem => createAction(TYPES.REMOVE_FILTER_ITEM, { item: filterItem }),

  onSetResultLimit: limit => createAction(TYPES.ON_SET_RESULT_LIMIT, limit),
  previewQuestion: (dynamicFilters?) => createAction(TYPES.PREVIEW_QUESTION, { dynamicFilters }),
  onAddAdvanceFilter: () => createAction(TYPES.ON_ADD_ADVANCE_FILTER, {}),
  onAddCalculatedColumn: () => createAction(TYPES.ON_ADD_CALCULATED_COLUMN, {}),
  onDeleteCalculatedColumn: (col, index) => createAction(TYPES.ON_DELETE_CALCULATED_COLUMN, { col, index }),
  onChangeCalculatedColumn: (index, batch) => createAction(TYPES.ON_CHANGE_CALCULATED_COLUMN, { batch, index }),
};

export default questionStudioActions;
