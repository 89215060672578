import * as React from 'react';
export const ExpressionChip = ({ label, bgColor, ...props }) => (
  <span
    style={{
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: bgColor,
      background: bgColor,
      color: 'white',
      margin: 2,
      padding: '5px 5px',
      ...props.style,
    }}
  >
    {label}
  </span>
);
