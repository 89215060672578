import { useState, useEffect } from 'react';
import { ShareableUser } from 'src/shared/models/ShareableUser';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';
import toastr from 'src/components/toastr';

export default function useShareableUsers() {
  const [sharebleUsers, setSharableUsers] = useState([] as Array<ShareableUser>);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    oceanAuthApis.getSharableUsers().then(
      resp => {
        setSharableUsers(resp.data || []);
        setIsLoading(false);
      },
      () => {
        toastr.error('Error loading users');
        setIsLoading(false);
      }
    );
  }, []);

  return [sharebleUsers, isLoading];
}
