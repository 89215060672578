import * as React from 'react';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormLabel, FormControl } from '@material-ui/core';
import CreatableAutoCompleteSelect from 'react-select/lib/Creatable';
import TitledAutoComplete from './TitledAutoComplete';

const TagsSeparator = ',';
interface IProps {
  onSave: any;
  open: boolean;
  onSaveAs: any;
  onCancel: any;
  showSaveAs?: boolean;

  title?: string;
  description?: string;
  tags?: string;

  EntityName?: string;
}
interface IState {
  name: string;
  description: string;
  tags: [];
}

export default class SaveModal extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      name: props.title,
      description: props.description,
      tags: [],
    };
  }

  componentWillUpdate(nextProps) {
    const watchProps = ['title', 'desc', 'tags'];
    if (this.props.tags != nextProps.tags) {
      const tags = nextProps.tags
        ? nextProps.tags
            .trim()
            .split(TagsSeparator)
            .map(v => ({ value: v, label: v }))
        : null;
      this.setState({ tags });
    }
    if (watchProps.find(p => this.props[p] != nextProps[p])) {
      this.setState({ name: nextProps.title, description: nextProps.description });
    }
  }

  handleSave = e => this.props.onSave(this.state);
  handleSaveAs = e => this.props.onSaveAs(this.state);
  handleClose = e => this.props.onCancel();

  isValid = () => {
    return this.state.name.trim();
  };

  render() {
    const { name, description, tags } = this.state;
    const { open, EntityName } = this.props;
    return (
      <Dialog open={open} onClose={this.handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title' color='primary'>
          Save {EntityName}{' '}
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 60 }}>
          <form>
            <div style={{ marginTop: 10, width: 500 }} />
            <TextField
              autoFocus
              variant={'outlined'}
              name='name'
              id='name'
              label={`${EntityName} name`}
              fullWidth
              required
              error={!name.trim()}
              value={name}
              onChange={e => this.setState({ name: e.currentTarget.value })}
            />
            <div style={{ marginTop: 10 }} />
            <TextField
              id='description'
              name='description'
              label='Description'
              fullWidth
              variant={'outlined'}
              multiline
              rows={4}
              rowsMax='4'
              value={description}
              onChange={e => this.setState({ description: e.currentTarget.value })}
            />
            <div style={{ marginTop: 10 }} />
            <TitledAutoComplete
              isCreatable={true}
              label={'Tags'}
              isClearable={false}
              isMulti={true}
              onChange={value => this.setState({ tags: value })}
              value={tags}
              name='Tags'
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color='primary'>
            Cancel
          </Button>
          {this.props.showSaveAs && (
            <Button onClick={this.handleSaveAs} disabled={!this.isValid()} color='secondary'>
              Save New
            </Button>
          )}
          <Button onClick={this.handleSave} disabled={!this.isValid()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
