import * as React from 'react';
import ViewTypeSelect from 'src/components/QuestionPreviewer/ViewTypeSelect';
import ViewOptionsMenu from 'src/components/Charts/ViewOptionsMenu';
import ColorSchemaSelect from 'src/components/QuestionPreviewer/ColorSchemaSelect';
import ShareQuestionPopup from 'src/components/QuestionPreviewer/ShareQuestionPopup';

import ExportQuestionPopup from 'src/components/QuestionPreviewer/ExportQuestionPopup';
import DynamicFiltersPopup from 'src/components/QuestionPreviewer/DynamicFiltersPopup';
import EmailIcon from '@material-ui/icons/Email';
import Colorize from '@material-ui/icons/Colorize';
import { ChartCustomizationOptions } from './models';
import { ViewTypeItem, viewTypesOptionsList } from 'src/components/QuestionPreviewer/questionViewTypes';
import { IPreviewResult } from 'src/shared/models/IPreviewResult';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import { QuestionStudioStoreState, QueryFilterViewItem } from 'src/modules/questions/studio/models';
import { withStyles, Typography } from '@material-ui/core';
import ShareUsersPopup from '../ShareUsersPopup';
import questionAccess from 'src/services/permissions/abilities/questions';
import CanDo from '../Permissions/Can';
import TooltipedIconButton from '../TooltipedIconButton';
import { QuestionViewMode } from './QuestionPreviewer';
export type ChangePreviewerColorEvent = { kpiColor?: string; colorSchemaIndex?: number };
interface IProps {
  className?: any;
  hasRawData: boolean;
  questionDto?: OiQuestionDto;
  viewTypeOption?: ViewTypeItem;
  previewResult?: IPreviewResult;
  chartOptions: ChartCustomizationOptions;
  questionModel?: QuestionStudioStoreState;
  classes?: any;
  toolBarEndButtons?: any;
  toolBarStartButtons?: any;
  onSelectExportExtension?: any; // (x?: { extension: string; [key: string]: any }) => any;
  isKpiView?: boolean;
  isTableView?: boolean;
  isChartView?: boolean;
  isLoadingPreview?: boolean;
  hidePickColorSchema?: boolean;
  onSelectViewTypeOption: (viewType: any) => any;
  onExecuteFilters: (result: QueryFilterViewItem[]) => any;
  onChartCustomizationOptionChanged: (changedItem: any) => any;
  onChangeComponentColor: (changedItem: ChangePreviewerColorEvent) => any;
  // onOpenDynamicFilters: (changedItem: any) => any;
  title: string;
  onShareWithUsers?: any;
  onShareQuestionToEmails?: any;
  questionViewMode?: QuestionViewMode;
}
export function QuestionToolbar({
  questionDto,
  questionModel,
  viewTypeOption,
  previewResult,
  hidePickColorSchema,
  isKpiView,
  isTableView,
  isChartView,
  chartOptions,
  isLoadingPreview,
  onExecuteFilters,
  title,
  classes,
  hasRawData,
  questionViewMode,
  ...props
}: IProps) {
  let showColorPicker = !hidePickColorSchema && (isChartView || isKpiView);
  const dynamicFiltersItems = ((questionModel && questionModel.filtersItems) || []).filter(s => s.dynamic);
  const isInDashboard = [QuestionViewMode.DASHBOARD_STUDIO_EDIT, QuestionViewMode.DASHBOARD_STUDIO_VIEW].includes(questionViewMode!);
  return (
    <div className={classes.previewerToolBar}>
      <span className={classes.btnsToTheLeft}>
        {props.toolBarStartButtons || <> </>}
        {/* <span className='previewResult-title'> */}

        <Typography className={` ${classes.title} previewResult-title`} noWrap component={'span'}>
          {title}
        </Typography>
        {/* </span> */}
      </span>

      <span className={classes.btnsToTheRight}>
        {(dynamicFiltersItems.length && (
          <DynamicFiltersPopup
            isLoadingPreview={isLoadingPreview}
            onExecuteFilters={filters => {
              onExecuteFilters(filters);
            }}
            questionDto={questionDto}
            questionModel={questionModel}
          />
        )) ||
          []}

        {(!hasRawData && (
          <ViewTypeSelect
            onSelect={props.onSelectViewTypeOption}
            value={viewTypeOption || viewTypesOptionsList[0]}
            meta={(previewResult && previewResult.metadata) || []}
          />
        )) ||
          []}

        <ViewOptionsMenu
          chartOptions={chartOptions}
          viewType={viewTypeOption && viewTypeOption!.type}
          onChange={props.onChartCustomizationOptionChanged}
        />

        {(showColorPicker && (
          <ColorSchemaSelect
            onChange={e => props.onChangeComponentColor(e)}
            singleColor={isKpiView}
            icon={
              <TooltipedIconButton tooltip='Change colors'>
                <Colorize />
              </TooltipedIconButton>
            }
          />
        )) ||
          []}

        <CanDo action={questionAccess.sendEmail}>
          <ShareQuestionPopup
            onDone={props.onShareQuestionToEmails}
            icon={
              <TooltipedIconButton tooltip={'Share by email'}>
                <EmailIcon />
              </TooltipedIconButton>
            }
          />
        </CanDo>

        {!isInDashboard && (
          <CanDo action={questionAccess.share}>
            <ShareUsersPopup onDone={props.onShareWithUsers} />
          </CanDo>
        )}

        <CanDo action={questionAccess.export}>
          <ExportQuestionPopup
            questionName={(questionModel && questionModel.name) || ''}
            onSelect={props.onSelectExportExtension}
            isKpiView={isKpiView!}
            isChartView={isChartView!}
            isTableView={isTableView!}
          />
        </CanDo>

        {props.toolBarEndButtons || <> </>}
      </span>
    </div>
  );
}

export default withStyles({
  previewerToolBar: {
    borderBottom: '1px solid #e9ebee',
    justifyContent: 'flex-end',
    display: 'flex',
    width: '100%',
    height: 49,
    paddingLeft: 10,
    paddingRight: 10,
    alignContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
  },
  btnsToTheLeft: {
    flex: 1,
    width: 150,
    margin: 'auto',
    alignContent: 'center',
    alignItems: 'center',
    marginLeft: 0,
    fontWeight: 600,
    display: 'inline-flex',
  },
  btnsToTheRight: {
    width: 'fit-content',
    margin: 'auto',
    textAlign: 'right',
    alignContent: 'right',
    marginRight: 0,
    whiteSpace: 'nowrap',
  },
})(QuestionToolbar);
