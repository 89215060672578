import React from 'react';
import { withStyles } from '@material-ui/core';
import { Colors } from 'src/shared/theme';

const TitleInput = ({ classes, value, onTitleChanged }) => (
  <input type='input' className={'flex ' + classes.titleInput} onChange={e => onTitleChanged(e.currentTarget.value || '')} value={value} />
);
const styled = withStyles({
  headingItem: {
    display: 'flex',
    padding: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleInput: {
    border: 'none',
    height: 'auto',
    color: Colors.brandDarkGrey,
    fontSize: 16,
    width: 20,
  },
})(TitleInput);

export default styled;
