import { REQUEST, SUCCESS, FAILURE } from 'src/store/util';

import { ACTION_TYPES } from 'src/store/account/actions';
import { IUserAccount } from 'src/shared/models/UserAccount';
const STORAGE_KEY = 'OI_ACCOUNT';

let account = {};
try {
  const storedAccount = localStorage.getItem(STORAGE_KEY);
  if (storedAccount) {
    account = JSON.parse(storedAccount);
  }
} catch (e) {}
const initialState = {
  loading: false,
  errorMessage: String,
  data: account as IUserAccount,
  passwordChangeResponse: [],
  changeLoader: false,
};
export type AccountState = Readonly<typeof initialState>;

// Reducer
export default (state = initialState, { type, payload, ...action }): any => {
  switch (type) {
    // GET_ACCOUNT
    case REQUEST(ACTION_TYPES.GET_ACCOUNT):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_ACCOUNT):
      return {
        ...initialState,
        errorMessage: payload.message,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.GET_ACCOUNT): {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(payload.data));
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    }

    // PASSWORD_CHANGE
    case REQUEST(ACTION_TYPES.PASSWORD_CHANGE):
      return {
        ...state,
        changeLoader: true,
      };
    case FAILURE(ACTION_TYPES.PASSWORD_CHANGE):
      return {
        ...initialState,
        errorMessage: payload.message,
        changeLoader: false,
      };
    case SUCCESS(ACTION_TYPES.PASSWORD_CHANGE): {
      return {
        ...state,
        changeLoader: false,
        passwordChangeResponse: payload.data,
      };
    }

    default:
      return state;
  }
};
