import { withStyles, StyleRulesCallback, createStyles, WithStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    cardHeaderStyle: {
      color: '#f44336',
    },
    // textFieldStyle: {
    //   width: '400px',
    // },
    errMsgStyle: {},
    submitBtn: {
      marginTop: '20px',
      width: '100px',
    },
    card: {
      width: 400,
    },
  });

export interface styleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);
export default Styled;
