import toastr from 'components/toastr';
import questionsServices, { getQueryPageLength, IQuestionsServices } from 'modules/questions/questions.services';
import questionStudioServices from 'modules/questions/studio/questionStudioServices';
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { QueryViewItem, QuestionStudioStoreState, QueryFilterViewItem } from 'src/modules/questions/studio/models';
import OiFunctionsList from 'src/shared/dataSources/OiFunctions';
import { SORT_DIRECTION } from 'src/shared/models';
import { isCurrentOperator, isOperatorCurrentOrLast } from 'src/shared/models/OiFilter';
import { OiFunction } from 'src/shared/models/OiFunction';
import { ColumnType, OceanDataType, SQLColumn } from 'src/shared/models/SqlColumn';
import confirmUtils from 'store/confirmationPopup/utils';
import questionStudioActions, { ACTION_TYPES as questionStudioActionTypes } from './questionStudioActions';
import { getQuestionTitle, QuestionsStudioState } from './questionStudioReducer';
import questionStudioSelectors, { questionStudioSelectors as qStudioSelectors } from './selectors';
import { APP_LOCAL_DATE_FORMAT } from 'src/constants';

export function* onSelectBaseTable(confirm, {}) {
  const hasAnyData = yield select(qStudioSelectors.hasAnyData);
  if (hasAnyData) {
    let confirmationMsg = 'Are you sure you want to remove all selected views ?';
    const confirmation = yield call(confirm(confirmationMsg));
    if (confirmation) {
      yield put(questionStudioActions.resetIngredientsPanelData() as any);
    }
  } else {
    yield put(questionStudioActions.resetIngredientsPanelData() as any);
  }
}

export function* loadQuestionById(questionsServices: IQuestionsServices, toastr, { payload }) {
  let { id } = payload;
  //previewQuestion
  yield put(questionStudioActions.batchState({ isLoadingQuestion: true }));
  try {
    const result = yield call(questionsServices.loadQuestionById, id);
    if (result.ok) {
      yield put(
        questionStudioActions.batchState({
          ...result.model,
          showPreviewPanelPlaceHolderMessage: false,
          expandPreviewResultCard: true,
        })
      );
      yield put(questionStudioActions.previewQuestion());
    } else {
      yield put(questionStudioActions.batchState({ isLoadingQuestion: false }));
      yield call(toastr.error, `Error Loading Question with id ${id}\n`);
    }
  } catch (e) {
    yield call(toastr.error, `Error Loading Question with id ${id}\n`);
  } finally {
    yield put(questionStudioActions.batchState({ isLoadingQuestion: false }));
  }
}

export function* onPickRawData(confirm, { payload }) {
  const { dbTableName } = payload;
  let studioState: QuestionStudioStoreState = yield select(qStudioSelectors.studioState);
  const selectedTable = studioState.dbTables.find(t => t.name == dbTableName);
  let baseTableColumns = selectedTable!.columns;
  let vid = 0;

  let viewItems = studioState.viewsItems;
  if (viewItems.length > 0) {
    let confirmationMsg = 'adding Raw data will replace the current View, please confirm ?';
    const confirmation = yield call(confirm(confirmationMsg));
    if (!confirmation) {
      return;
    }
  }
  let allViews = baseTableColumns.map((c, i) => {
    return {
      column: c,
      functionItem: { displayName: '', value: OiFunction.NONE },
      displayName: c.displayName,
      vid: vid++,
      orderIndex: i,
      orderDirection: SORT_DIRECTION.NONE,
    } as QueryViewItem;
  });
  yield put(questionStudioActions.resetIngredientsPanelData());
  yield put(questionStudioActions.addRawData(allViews));
  yield put(questionStudioActions.onFormModelUpdated());
}

export function* onPickRawColumns(confirm, { payload }) {
  const { tables } = payload;
  let studioState: QuestionStudioStoreState = yield select(qStudioSelectors.studioState);
  let hasRawData: QuestionStudioStoreState = yield select(qStudioSelectors.hasRawData);
  let vid = 0;

  let viewItems = studioState.viewsItems;
  if (viewItems.length > 0 && !hasRawData) {
    let confirmationMsg = 'adding Raw data will replace the current View, please confirm ?';
    const confirmation = yield call(confirm(confirmationMsg));
    if (!confirmation) {
      return;
    }
  }
  let columns = tables.reduce((agg, table) => {
    return agg.concat(table.columns.filter(c => c.isChecked));
  }, []);
  let allViews = columns.map((c, i) => {
    return {
      column: c,
      functionItem: { displayName: '', value: OiFunction.NONE },
      displayName: c.displayName,
      vid: vid++,
      orderIndex: i,
      orderDirection: SORT_DIRECTION.NONE,
    } as QueryViewItem;
  });
  if (hasRawData) {
    allViews = viewItems.concat(allViews);
  }
  yield put(questionStudioActions.resetIngredientsPanelData());
  yield put(questionStudioActions.addRawData(allViews));
  yield put(questionStudioActions.onFormModelUpdated());
}

export function* onPickRowsCount(confirm) {
  let studioState = yield select(qStudioSelectors.studioState);
  let displayName = studioState.baseTable.displayName + ' count';
  let viewItem: QueryViewItem = {
    column: {
      type: ColumnType.MEASURE,
      oceanDataType: OceanDataType.NUMBER,
      displayName,
      dbTableName: studioState.baseTable.name,
    } as SQLColumn,
    displayName,
    functionItem: OiFunctionsList.find(o => o.value == OiFunction.ROW_COUNT),
  };
  yield put(questionStudioActions.pickViewItem(viewItem));
}

export function* onPickViewItem(confirm, { payload }) {
  let { viewItem } = payload;
  let studioState = yield select(qStudioSelectors.studioState);
  let viewItems = studioState.viewsItems;
  viewItem = Object.assign(viewItem, {
    orderIndex: viewItems.length,
    orderDirection: SORT_DIRECTION.NONE,
    vid: studioState.vid,
  });
  let hasRawData = yield select(qStudioSelectors.hasRawData);
  if (hasRawData) {
    let confirmationMsg = `Can't add the selected View with Raw Data, do you want to reset ?`;
    const confirmation = yield call(confirm(confirmationMsg));
    if (!confirmation) {
      return;
    }
    yield put(questionStudioActions.resetIngredientsPanelData());
  }
  yield put(questionStudioActions.addViewItem(viewItem));
  yield put(questionStudioActions.onFormModelUpdated());
}

export function* onChangeViewItem(toastr, { payload }) {
  let { itemIndex, updatedItem } = payload;
  let studioState = yield select(qStudioSelectors.studioState);
  let viewItems = studioState.viewsItems;
  let groupItems = studioState.groupItems;
  let viewsItems = viewItems.slice();
  if (updatedItem.pin) {
    let pinnedViews = viewItems.filter(v => v.pin);
    let pinnedGroups = groupItems.filter(v => v.pin);
    let totalPinned = pinnedViews.length + pinnedGroups.length;
    if (totalPinned == 5) {
      yield call(toastr.warn, 'Maximum pinned items is 5');
      return;
    }
  }
  if (updatedItem.grouped) {
    let groupedViews = viewItems.filter(v => v.grouped);
    let groupedGroups = groupItems.filter(v => v.grouped);
    let totalGrouped = groupedViews.length + groupedGroups.length;
    if (totalGrouped == 3) {
      yield call(toastr.warn, 'Maximum grouped items is 3');
      return;
    }
  }
  Object.assign(viewsItems[itemIndex], updatedItem);
  yield put(questionStudioActions.batchState({ viewsItems }));
  yield put(questionStudioActions.onFormModelUpdated());
}

function* onPickGroupToAdd({ payload }) {
  let { groupItem } = payload;
  let studioState: QuestionsStudioState = yield select(qStudioSelectors.studioState);
  groupItem.vid = studioState.vid + 1;
  groupItem.orderDirection = SORT_DIRECTION.NONE;
  let allGroups = studioState.groupItems.slice(); //([groupItem]);

  let sameGroups = questionStudioSelectors.getSameGroupItems(groupItem, studioState.groupItems);
  if (sameGroups.length >= 1) {
    toastr.warn(' Group already exists');
    return;
  } else {
    allGroups.push(groupItem);
  }
  yield put(
    questionStudioActions.batchState({
      vid: groupItem.vid,
      groupItems: allGroups,
      showPreviewPanelPlaceHolderMessage: false,
      expandPreviewResultCard: true,
    })
  );
  yield put(questionStudioActions.onFormModelUpdated());
}

function* onChangeGroupItem(toastr, { payload }) {
  let { itemIndex, groupItem } = payload;

  let studioState = yield select(qStudioSelectors.studioState);
  let groupItems = studioState.groupItems.slice();
  let sameGroups = questionStudioSelectors.getSameGroupItems(groupItem, studioState.groupItems);

  if (sameGroups.length > 0) {
    yield call(toastr.warn, ' Group already exists');
    return;
  }
  if (groupItem.pin) {
    let pinnedViews = studioState.viewsItems.filter(v => v.pin);
    let pinnedGroups = studioState.groupItems.filter(v => v.pin);
    let totalPinned = pinnedViews.length + pinnedGroups.length;
    if (totalPinned == 5) {
      yield call(toastr.warn, 'Maximum pinned items is 5');
      return;
    }
  }
  if (groupItem.grouped) {
    let groupedViews = studioState.viewsItems.filter(v => v.grouped);
    let groupedGroups = studioState.groupItems.filter(v => v.grouped);
    let totalGrouped = groupedViews.length + groupedGroups.length;
    if (totalGrouped == 3) {
      yield call(toastr.warn, 'Maximum grouped items is 3');
      return;
    }
  }
  Object.assign(groupItems[itemIndex], groupItem);
  yield put(questionStudioActions.batchState({ groupItems, mayNeedRefresh: true }));
  yield put(questionStudioActions.onFormModelUpdated());
  // this.setState({ groupItems, mayNeedRefresh: true });
}

function* onPickFilterToAdd({ payload }) {
  let studioState = yield select(qStudioSelectors.studioState);

  const filterItem: QueryFilterViewItem = payload.filterItem; //{ ..._filterItem, dynamic: true };

  let isDateColumn = filterItem.column.oceanDataType === OceanDataType.DATE;
  let compareOperator = filterItem.filterOperator.operator;
  const columnFilterAlreadyExist = (studioState.filtersItems as QueryFilterViewItem[]).find(f => {
    const isSameColumn = f.column.id == filterItem.column.id;
    return !filterItem.zoomingFilter && isSameColumn;
  });
  if (columnFilterAlreadyExist) {
    toastr.warn(`A filter for ${filterItem.column.displayName} column already exists`);
    return;
  }
  if (isDateColumn && !isOperatorCurrentOrLast(compareOperator)) {
    filterItem.values = filterItem.values.map(v => moment(v).format(APP_LOCAL_DATE_FORMAT));
  }
  if (isCurrentOperator(compareOperator)) {
    filterItem.values = ['', filterItem.values[1]];
  }
  const allFilters = studioState.filtersItems.concat([{ ...filterItem, dynamic: true }]);

  yield put(questionStudioActions.batchState({ filtersItems: allFilters }));
  yield put(questionStudioActions.onFormModelUpdated());
}

export function* onFormModelUpdated() {
  let studioState = yield select(qStudioSelectors.studioState);
  let hasRawData = yield select(qStudioSelectors.hasRawData);
  let previewResult = studioState.previewResult;
  let views = studioState.viewsItems;
  let computedColumns = studioState.computedColumns;
  let groups = studioState.groupItems;
  let name = hasRawData ? `${studioState.baseTable.displayName} details` : getQuestionTitle(studioState);
  let shouldBeKpi = questionStudioServices.shouldBeKpi(groups, computedColumns, views, previewResult);

  let viewTypeOption = questionStudioServices.getNextViewTypeOptions(shouldBeKpi, studioState);
  yield put(
    questionStudioActions.batchState({
      viewTypeOption,
      name,
      previewResult: previewResult ? { ...previewResult, title: name } : previewResult,
      showPreviewPanelPlaceHolderMessage: false,
      mayNeedRefresh: true,
    })
  );
}

function* previewQuestion(questionsServices: IQuestionsServices, toastr, { payload }) {
  const { dynamicFilters } = payload;
  let studioState = yield select(qStudioSelectors.studioState);
  let views = studioState.viewsItems;
  let groups = studioState.groupItems;
  let filtersItems = studioState.filtersItems;
  let computedColumns = studioState.computedColumns;
  const questionModel = Object.assign({}, studioState);
  if (!questionModel) {
    yield call(toastr.error, " can't preview Question with no question model! please add some views/ groups ");
    return;
  }
  yield put(questionStudioActions.batchState({ lastQueryPage: 1, isLoadingPreview: true, previewResult: {} }));
  try {
    const pageLength = getQueryPageLength(questionModel.limit);
    const paginationResult = yield call(questionsServices.getMassiveQueryPagination, questionModel);
    const result = yield call(questionsServices.previewQuestion, questionModel, { pageNumber: 1, pageLength });
    let shouldBeKpi = questionStudioServices.shouldBeKpi(groups, computedColumns, views, result);
    let viewTypeOption = questionStudioServices.getNextViewTypeOptions(shouldBeKpi, studioState);
    yield put(
      questionStudioActions.batchState({
        viewTypeOption,
        previewResult: {
          ...result,
          ...paginationResult,
        },
        isLoadingPreview: false,
        mayNeedRefresh: false,
        showPreviewPanelPlaceHolderMessage: false,
        expandPreviewResultCard: true,
      })
    );
  } catch (e) {
    console.error('error previewing question from previewQuestion saga', e);
    yield call(toastr.warn, 'Failed to preview question');
    // yield call(toastr.warn,JSON.stringify(e.data));
    yield put(questionStudioActions.batchState({ isLoadingPreview: false }));
    yield put(questionStudioActions.onFormModelUpdated());
  }
}

export const questionStudioSagas = [
  takeLatest(questionStudioActionTypes.LOAD_QUESTION_BY_ID as any, loadQuestionById, questionsServices, toastr),
  takeLatest(questionStudioActionTypes.PREVIEW_QUESTION as any, previewQuestion, questionsServices, toastr),
  takeLatest(questionStudioActionTypes.ON_SELECT_BASE_TABLE as any, onSelectBaseTable, confirmUtils.sagaConfirm),
  takeLatest(questionStudioActionTypes.PICK_RAW_DATA as any, onPickRawData, confirmUtils.sagaConfirm),
  takeLatest(questionStudioActionTypes.PICK_RAW_COLUMNS as any, onPickRawColumns, confirmUtils.sagaConfirm),
  takeLatest(questionStudioActionTypes.PICK_ROWS_COUNT as any, onPickRowsCount),
  takeLatest(questionStudioActionTypes.PICK_VIEW_ITEM as any, onPickViewItem, confirmUtils.sagaConfirm),
  takeLatest(questionStudioActionTypes.ON_FORM_MODEL_UPDATED as any, onFormModelUpdated),
  takeLatest(questionStudioActionTypes.UPDATE_VIEW_ITEM as any, onChangeViewItem, toastr),
  takeLatest(questionStudioActionTypes.PICK_GROUP_ITEM as any, onPickGroupToAdd),
  takeLatest(questionStudioActionTypes.UPDATE_GROUP_ITEM as any, onChangeGroupItem, toastr),
  takeLatest(questionStudioActionTypes.PICK_FILTER_ITEM as any, onPickFilterToAdd),
];
