import * as React from 'react';
import { Popover, MenuItem, withStyles } from '@material-ui/core';
import { ColorsSchemasList } from 'src/components/QuestionPreviewer/ColorSchamas';

export class ColorSchemaSelect extends React.Component<any> {
  state = {
    anchorEl: undefined,
  };

  onSelect = value => e => {
    e.stopPropagation();
    this.props.onChange && this.props.onChange(this.props.singleColor ? { kpiColor: value } : { colorSchemaIndex: value });
  };

  closePopover = () => {
    this.setState({ anchorEl: undefined });
  };

  render() {
    const classes = this.props.classes;
    return (
      <>
        <span aria-label='type' aria-owns='type-menu' aria-haspopup='true' onClick={e => this.setState({ anchorEl: e.currentTarget })}>
          {this.props.icon || 'Select color schema'}
        </span>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={Boolean(this.state.anchorEl)}
          onBackdropClick={this.closePopover}
          anchorEl={this.state.anchorEl}
        >
          <>
            {this.props.singleColor ? (
              <div className={classes.FlexWrap}>
                {ColorsSchemasList.reduce((acc, schemaColors) => acc.concat(schemaColors), []).map((color, index) => (
                  <MenuItem key={index} onClick={this.onSelect(color)} style={{ padding: 0 }}>
                    <div className={classes.StyledDiv} style={{ backgroundColor: color }} />
                  </MenuItem>
                ))}
              </div>
            ) : (
              ColorsSchemasList.map((schema, index) => (
                <MenuItem key={index + ''} onClick={this.onSelect(index)}>
                  <div className={classes.FlexWrap}>
                    {schema.map(color => (
                      <div className={classes.StyledDiv} style={{ backgroundColor: color }} key={color} />
                    ))}
                  </div>
                </MenuItem>
              ))
            )}
          </>
        </Popover>
      </>
    );
  }
}
export default withStyles({
  StyledDiv: {
    width: 20,
    height: 20,
    margin: '0 1px',
  },
  FlexWrap: {
    width: '100%',
    display: 'flex',
    maxWidth: 300,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
} as any)(ColorSchemaSelect);
