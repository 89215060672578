import { withStyles, StyleRulesCallback, Theme, createStyles } from '@material-ui/core';

let color = '#d44b00';
export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    Wrapper: {
      border: `1px solid ${color}`,
      width: '100%',
      margin: '5px 0',
      borderRadius: 5,
    },
    title: {
      //.title
      flex: '1 1',
      maxWidth: 100,
      overflow: 'hidden',
      background: 'red',
      textOverflow: 'ellipsis',
    },
    WizardButtonWrap: {
      padding: 0,
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    HeadingItem: {
      display: 'flex',
      padding: 5,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    NormalizedLabel: {
      marginBottom: 0,
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 13,
    },
    EditorWrapper: {
      borderTop: `1px solid ${color}`,
      padding: '10px 15px',
      //.radio-group
    },
    radioGroup: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  });

export default withStyles(styles);
