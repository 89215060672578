import * as React from 'react';

const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'Qa', 'Qi'];

export function formatNumber(number, w = 3) {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(number) / w) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * w);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

type IProps = {
  value: number;
};
export const NumberFormatter = new Intl.NumberFormat();
export default class DynamicNumberFormatter extends React.Component<IProps> {
  timeOutRef: any = null;
  wrapRef: any = null;
  state = {};

  constructor(props) {
    super(props);
  }
  resizeEventListener = e => {
    if (this.timeOutRef) {
      clearTimeout(this.timeOutRef);
    }
    this.timeOutRef = setTimeout(() => {
      this.forceUpdate();
    }, 50);
  };

  render() {
    const { value } = this.props;
    let boudnigRect: any = null;
    let numDigits = Math.ceil(Math.log10(value));
    let cap = 10;
    if (this.wrapRef) {
      boudnigRect = this.wrapRef.getBoundingClientRect();
      cap = boudnigRect.width / 34; // - 3;
    }
    return (
      <div style={{ width: '100%' }} ref={e => (this.wrapRef = e)} className='DynamicNumberFormatter'>
        {cap > numDigits + 3 ? NumberFormatter.format(value) : formatNumber(value)}
      </div>
    );
  }
}
