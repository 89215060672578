import React from 'react';
import FavIcon from '@material-ui/icons/Star';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Toolbar, Tooltip } from '@material-ui/core';
import CanDo from 'src/components/Permissions/Can';
import questionAccess from 'src/services/permissions/abilities/questions';
import CustomSearch from 'src/components/CustomSearch';
import ShareWithUsersPopup from 'src/components/ShareUsersPopup';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
interface IProps {
  selectedQuestions: any[];
  showFavList: Boolean;
  favQuestions: any[];
  search: string;
  openAddQuestionForm: (e: any) => any;
  handleDeleteSelected: (e: any) => any;
  handleShare: (e: any) => any;
  handleSearch: (e: any) => any;
  toggleShowFav: (e: any) => any;
}
function QuestionListToolbar(props: IProps) {
  const { selectedQuestions, showFavList, favQuestions, search } = props;
  const { openAddQuestionForm, handleDeleteSelected, handleShare, handleSearch, toggleShowFav } = props;
  return (
    <Toolbar>
      <CanDo action={questionAccess.create}>
        <TooltipedIconButton tooltip={'Add new question'} rounded aria-label='Add' onClick={openAddQuestionForm}>
          <AddIcon />
        </TooltipedIconButton>
      </CanDo>
      <CanDo action={questionAccess.delete}>
        <TooltipedIconButton
          tooltip={'Delete selected questions'}
          disabled={!(selectedQuestions.length > 0)}
          rounded
          aria-label='remove'
          onClick={handleDeleteSelected}
        >
          <DeleteIcon />
        </TooltipedIconButton>
      </CanDo>
      <CanDo action={questionAccess.share}>
        <ShareWithUsersPopup isDisabled={!(selectedQuestions.length > 0)} onDone={handleShare} rounded />
      </CanDo>

      <div className='flex' />

      <CustomSearch onChange={handleSearch} value={search} placeholder='Search' />

      <div>
        <TooltipedIconButton
          rounded
          tooltip={'Toggle Fav list visibility'}
          aria-label='toggle fav list'
          disabled={favQuestions.length == 0}
          style={{ borderColor: showFavList ? '#ffeb3b' : favQuestions.length == 0 ? '#ccc' : '' }}
          onClick={toggleShowFav}
        >
          <FavIcon scale='2' style={{ color: showFavList ? '#ffeb3b' : '' }} />{' '}
        </TooltipedIconButton>
      </div>
    </Toolbar>
  );
}
export default QuestionListToolbar;
