import PERMISSIONS from '../enum';
const questionAccess = {
  create: [PERMISSIONS.QUESTION.WRITE],
  delete: [PERMISSIONS.QUESTION.DELETE],
  edit: [PERMISSIONS.QUESTION.WRITE],
  export: [PERMISSIONS.QUESTION.EXPORT],
  schedule: [PERMISSIONS.QUESTION.SCHEDULE],
  sendEmail: [PERMISSIONS.QUESTION.SEND_AS_EMAIL],
  share: [PERMISSIONS.QUESTION.SHARE],
  view: [PERMISSIONS.QUESTION.VIEW, PERMISSIONS.QUESTION.WRITE],
};

export default questionAccess;
