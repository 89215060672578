import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import { WidgetSettingsItem, WIDGET_SETTINGS_ITEM } from '../QuestionPreviewer/models';

export const chartTypesArr = [
  VIEW_TYPE.BAR_CHART,
  VIEW_TYPE.LINE_CHART,
  VIEW_TYPE.H_BAR_CHART,
  // VIEW_TYPE.ROSE_CHART,
  // VIEW_TYPE.AREA_CHART,
  // VIEW_TYPE.SMOOTH_LINE_CHART,
  VIEW_TYPE.DOUGHNUT_CHART,
  VIEW_TYPE.PIE_CHART,
];
export const chartSettingsOptions: WidgetSettingsItem[] = [
  {
    id: WIDGET_SETTINGS_ITEM.DATA_ZOOMING,
    fieldName: 'enableDataZooming',
    displayName: 'Drill Down',
    displayType: 'TOGGLE',
    appliesFor: [...chartTypesArr, VIEW_TYPE.TABLE],
    value: true,
  },
  {
    id: WIDGET_SETTINGS_ITEM.LEGEND,
    fieldName: 'legend',
    displayName: 'Legend',
    displayType: 'TOGGLE',
    appliesFor: chartTypesArr,
    value: true,
  },
  {
    id: WIDGET_SETTINGS_ITEM.SMOOTH,
    fieldName: 'smooth',
    displayName: 'Smooth',
    displayType: 'TOGGLE',
    appliesFor: [VIEW_TYPE.LINE_CHART],
    value: false,
  },
  {
    id: WIDGET_SETTINGS_ITEM.AREA,
    fieldName: 'area',
    displayName: 'Area',
    displayType: 'TOGGLE',
    appliesFor: [VIEW_TYPE.LINE_CHART],
    value: false,
  },
  {
    id: WIDGET_SETTINGS_ITEM.MAX_MARK_LINE,
    fieldName: 'maxLine',
    displayName: 'Max Mark Line',
    displayType: 'TOGGLE',
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.LINE_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.MIN_MARK_LINE,
    fieldName: 'minLine',
    displayName: 'Min Mark Line',
    displayType: 'TOGGLE',
    value: false,
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.LINE_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.AVG_MARK_LINE,
    fieldName: 'avgLine',
    displayName: 'Avg Mark Line',
    displayType: 'TOGGLE',
    value: true,
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.LINE_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.MAX_MARK_POINT,
    fieldName: 'maxPoint',
    displayName: 'Max Mark Point',
    displayType: 'TOGGLE',
    value: true,
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.LINE_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.MIN_MARK_POINT,
    fieldName: 'minPoint',
    displayName: 'Min Mark Point',
    displayType: 'TOGGLE',
    value: true,
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.LINE_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.AVG_MARK_POINT,
    fieldName: 'avgPoint',
    displayName: 'Avg Mark Point',
    displayType: 'TOGGLE',
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.LINE_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.STACK,
    fieldName: 'stack',
    displayName: 'Stack Series',
    displayType: 'TOGGLE',
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.H_BAR_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.BAR_LABEL,
    fieldName: 'barLabel',
    displayName: 'Bar Label',
    displayType: 'TOGGLE',
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.H_BAR_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.PERCENTAGE_BAR,
    fieldName: 'percentageBar',
    displayName: 'Percentage bar',
    displayType: 'TOGGLE',
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.H_BAR_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.ROSE,
    fieldName: 'rose',
    displayName: 'Rose',
    displayType: 'TOGGLE',
    value: false,
    appliesFor: [VIEW_TYPE.PIE_CHART, VIEW_TYPE.DOUGHNUT_CHART],
  },
  {
    id: WIDGET_SETTINGS_ITEM.AUTO_GROUP,
    fieldName: 'dimensionalGrouping',
    displayName: 'Dimensional Group',
    displayType: 'TOGGLE',
    value: false,
    appliesFor: [VIEW_TYPE.BAR_CHART, VIEW_TYPE.H_BAR_CHART],
  },
];
