import withStyles, { StyleRulesCallback } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';

const style = ((theme: Theme) => {
  return {
    titleWrapper: {
      marginBottom: 15,
      color: theme.palette.grey[600],
    },
    textField: {
      flexBasis: 200,
      '& fieldset ': {
        borderRadius: 40,
      },
    },
    loginAdornment: {
      color: theme.palette.grey[600],
    },
    marginLeft: {
      margin: '0px 10px',
    },
    flex: {
      display: 'flex',
      height: 35,
      marginTop: 10,
    },
    root: {
      lineHeight: '1em',
    },
    forgetPassword: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  };
}) as StyleRulesCallback;

const Styled = withStyles(style);
export default Styled;
