import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import { login } from 'src/store/authentication/actions';
import types from 'src/store/authentication/actionTypes';
import Login from 'src/modules/auth/login/Login';
import { getLoader, getError, getIsAuthenticated } from 'src/store/authentication/selectors';

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: getIsAuthenticated(state),
  isInProduction: state.applicationProfile.inProduction,
  loading: getLoader(state, types.LOGIN),
  errorMessage: getError(state, types.LOGIN),
});

const mapDispatchToProps = dispatch => {
  return { login: (request, rememberMe) => dispatch(login(request, rememberMe)) };
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
