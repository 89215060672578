import * as React from 'react';
import { ExpressionItem } from 'src/shared/models/OiQueryColumn';
import styles from './styles';
import SimpleSelect, { SimpleSelectItem } from 'src/components/SimpleSelectPopup';
import { SQLTable } from 'src/shared/models/SqlTable';
import { getSelectionPosition, setCursorPosition, getCursorPosition, setSelectionPosition } from 'src/shared/util/dom';
import {
  getSuggestionMenuConfigs,
  replaceCurrentWord,
  computeNextCursorPosition,
  expressionTreeToExpressionText,
  guessExpressionTokenType,
  ExpressionTokenType,
  getAggrigationTokenSelection,
  getColumnTokenSelection,
} from 'src/services/libs/computedColumns/libs';
import SuggestionsSelectList from './SuggestionsSelectList';
import { computedColumnExpressionParser as convertComputedColumnTextToExpressionTree } from 'src/services/libs/computedColumns/parser';
import { ColumnType, SQLColumn } from 'src/shared/models/SqlColumn';
import { getColumnTypeIconClass } from 'src/shared/util/SqlColumnUtils';
import { ExpressionTokensPresenter } from './ExpressionTokensPresenter';
import ReactDOM from 'react-dom';
import { Colors } from 'src/shared/theme';
import { debounce } from 'src/modules/questions/studio/utils';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { KEYCODES } from 'src/shared/util/keyboard';
import ExpressionTextInput from './ExpressionTextInput';

type IState = {
  showOpSelect: boolean;
  showAggSelect: boolean;
  showColumnSelect: boolean;
  selectAnchor?: any;
  selectCallBackFunc?: any;
  suggestionKeyword: string;
  highlightedSuggestionIndex: number;
  isEditorFocusable: boolean;
};
type IProps = {
  expression: ExpressionItem;
  operators: Array<SimpleSelectItem>;
  aggregators: Array<SimpleSelectItem>;
  tables: Array<SQLTable>;
  onExpressionUpdated: (exp: ExpressionItem, txt: string) => any;
};
export default class ComputedColumnExpressionBuilder extends React.Component<IProps, IState> {
  oldExpression = '';
  lastRenderedText = '';
  state: IState = {
    isEditorFocusable: false,
    showOpSelect: false,
    showAggSelect: false,
    showColumnSelect: false,
    selectAnchor: null,
    selectCallBackFunc: null,
    suggestionKeyword: '',
    highlightedSuggestionIndex: 0,
  };
  selectCallBack: any = null;

  get editorElement() {
    return ReactDOM.findDOMNode(this.refs.expressionTextInput)! as Element;
  }

  get aggregatorsDataSource() {
    let aggregators: any[] = this.props.aggregators;
    return aggregators;
  }

  get tablesDataSource() {
    let tables: any[] = this.props.tables;

    return tables.map(table => ({
      ...table,
      label: table.displayName,
      options: table.columns
        .filter((col: SQLColumn) => col.type == ColumnType.MEASURE)
        .map(c => ({ ...c, label: c.dbName, value: c.dbName, icon: getColumnTypeIconClass(c) })),
    }));
  }

  get activeDataSource() {
    const { showAggSelect, suggestionKeyword } = this.state;
    return showAggSelect ? this.aggregatorsDataSource : this.tablesDataSource;
  }

  get flatSuggestionsDataSource() {
    const { suggestionKeyword } = this.state;
    let ds = this.activeDataSource;
    let count = 0;
    return ds.reduce((acc, group) => {
      acc.push({ label: group.label, selectable: false, isGroupTitle: true });
      let groupOptions = group.options.filter(item => item.label.toLowerCase().indexOf(suggestionKeyword) > -1);
      return acc.concat(groupOptions.map(c => ({ ...c, colIndex: count++ })));
    }, []);
  }

  componentDidMount() {
    this.oldExpression = JSON.stringify(this.props.expression);
    let contentText = expressionTreeToExpressionText(this.props.expression);
    (this.editorElement as any).textContent = contentText;
    (this.editorElement as any).focus();
    this.lastRenderedText = contentText;
    // this.reRenderAsFancy(contentText);
    this.debouncedExpressionTextChanged();
  }

  selectHightlightedSuggestion = () => {
    const { highlightedSuggestionIndex, showAggSelect, showColumnSelect } = this.state;
    const suggestion = this.flatSuggestionsDataSource.find(c => c.colIndex == highlightedSuggestionIndex);
    if (suggestion) {
      const replacement = suggestion.label;
      if (showAggSelect) {
        const replacement = suggestion.label + (suggestion.label.trim() === '()' ? '' : '()');
        this.applyBatchToExpressionText(replacement, -1);
      } else if (showColumnSelect) {
        this.applyBatchToExpressionText(replacement, 1);
      }
    }
  };

  selectSuggestionByMouseClick = suggestionLabel => {
    const { showAggSelect, showColumnSelect } = this.state;
    if (suggestionLabel) {
      const replacement = suggestionLabel;
      if (showAggSelect) {
        const replacement = suggestionLabel + (suggestionLabel.trim() === '()' ? '' : '()');
        this.applyBatchToExpressionText(replacement, -1);
      } else if (showColumnSelect) {
        this.applyBatchToExpressionText(replacement, 1);
      }
    }
  };

  updateAutoCompleteStatus = (e?) => {
    const element = this.editorElement;
    let textContent = element.textContent || '';

    let [selectionStart, selectionEnd] = getSelectionPosition(element);
    let { suggestionKeyword, showAggSelect, showColumnSelect } = getSuggestionMenuConfigs(textContent, selectionStart);
    this.setState({
      suggestionKeyword,
      showAggSelect,
      showColumnSelect,
    });
  };

  handleBackspace = e => {
    const currentPosition = getCursorPosition(this.editorElement);

    let element = this.editorElement;
    let contentText = element.textContent || '';

    let currentToken = guessExpressionTokenType(contentText.trim(), currentPosition || 1);
    let [startSelection, endSelection] = getSelectionPosition(this.editorElement);
    if (currentToken == ExpressionTokenType.AGGREGATION_RPAREN || currentToken == ExpressionTokenType.AGGREGATION) {
      //avg(something)|
      e.preventDefault();
      e.stopPropagation();
      if (startSelection == endSelection) {
        let [tokenStartPosition, tokenEndPosition] = getAggrigationTokenSelection(contentText, startSelection - 1);
        setSelectionPosition(this.editorElement, [tokenStartPosition, tokenEndPosition]);
      } else {
        contentText = contentText.substring(0, startSelection) + contentText.substring(endSelection);
        this.editorElement.innerHTML = contentText;
        this.convertTextExpressionToExpresisonTree(contentText);
      }
    } else if (currentToken == ExpressionTokenType.COLUMN) {
      e.preventDefault();
      e.stopPropagation();
      if (startSelection == endSelection) {
        let [tokenStartPosition, tokenEndPosition] = getColumnTokenSelection(contentText, startSelection);
        setSelectionPosition(this.editorElement, [tokenStartPosition, tokenEndPosition]);
      } else {
        contentText = contentText.substring(0, startSelection - 1) + contentText.substring(endSelection);
        this.editorElement.innerHTML = contentText;
        this.convertTextExpressionToExpresisonTree(contentText);
      }
    }
  };

  handleForwardDelete = e => {
    const currentPosition = getCursorPosition(this.editorElement);
    let element = this.editorElement;
    let contentText = element.textContent || '';
    let currentToken = guessExpressionTokenType(contentText.trim(), currentPosition || 1);
    let [startSelection, endSelection] = getSelectionPosition(this.editorElement);
    if (currentToken == ExpressionTokenType.AGGREGATION_RPAREN || currentToken == ExpressionTokenType.AGGREGATION) {
      //avg(something)|
      e.preventDefault();
      e.stopPropagation();
      if (startSelection == endSelection) {
        let [tokenStartPosition, tokenEndPosition] = getAggrigationTokenSelection(contentText, startSelection - 1);
        setSelectionPosition(this.editorElement, [tokenStartPosition, tokenEndPosition]);
      } else {
        contentText = contentText.substring(0, startSelection - 1) + contentText.substring(endSelection);
        this.editorElement.innerHTML = contentText;
        this.convertTextExpressionToExpresisonTree(contentText);
      }
    } else if (currentToken == ExpressionTokenType.COLUMN) {
      e.preventDefault();
      e.stopPropagation();
      if (startSelection == endSelection) {
        let [tokenStartPosition, tokenEndPosition] = getColumnTokenSelection(contentText, startSelection);
        setSelectionPosition(this.editorElement, [tokenStartPosition, tokenEndPosition]);
      } else {
        contentText = contentText.substring(0, startSelection - 1) + contentText.substring(endSelection);
        this.editorElement.innerHTML = contentText;
        this.convertTextExpressionToExpresisonTree(contentText);
      }
    }
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    let suggestions = this.flatSuggestionsDataSource;
    let highlightedSuggestionIndex = this.state.highlightedSuggestionIndex;
    if (e.keyCode == KEYCODES.BACKSPACE) {
      this.handleBackspace(e);
    } else if (e.keyCode == KEYCODES.DEL) {
      this.handleForwardDelete(e);
    } else if (e.keyCode == KEYCODES.ENTER) {
      e.preventDefault();
      e.stopPropagation();
      this.selectHightlightedSuggestion();
    } else if ([KEYCODES.UP, KEYCODES.DOWN].includes(e.keyCode)) {
      let dir = KEYCODES.DOWN == e.keyCode ? 1 : -1;
      e.preventDefault();
      e.stopPropagation();
      let nextHighlightSuggestionIndex = (highlightedSuggestionIndex + suggestions.length + dir) % suggestions.length;
      this.setState({ highlightedSuggestionIndex: nextHighlightSuggestionIndex });
    }
  };

  onKeyUp = e => {
    const isNumber = /\d/g.test(e.key);
    const isSpace = /\s/g.test(e.key);
    const isShift = e.keyCode == 16 || e.key == 'Shift';
    const isOperator = '+-/*()'.indexOf(e.key) > -1;
    if (isOperator) {
      setTimeout(e => {
        const currentPosition = getCursorPosition(this.editorElement);
        this.state.isEditorFocusable && setCursorPosition(this.editorElement, currentPosition + 1);
      }, 30);
    }
    if (e.keyCode > 65) {
      this.updateAutoCompleteStatus(e);
    }
    if ('+-/*)'.indexOf(e.key) > -1) {
      this.debouncedExpressionTextChanged();
    }
  };

  onFocus = e => {
    this.setState({
      isEditorFocusable: true,
    });
    this.updateAutoCompleteStatus();
  };

  applyBatchToExpressionText = (batch, cursorShift) => {
    let element = this.editorElement;
    let textContent = element.textContent || '';
    let selectionStart = getCursorPosition(this.editorElement);

    let batchedContent = replaceCurrentWord(textContent, selectionStart, batch);
    let nextCursorPoisition = computeNextCursorPosition(textContent, selectionStart, batch) + cursorShift;
    this.editorElement.textContent = batchedContent;
    setCursorPosition(element, nextCursorPoisition);
    this.debouncedExpressionTextChanged();
    this.setState(
      {
        showColumnSelect: false,
        showAggSelect: false,
        highlightedSuggestionIndex: 0,
      },
      () => {
        this.updateAutoCompleteStatus();
      }
    );
  };

  convertTextExpressionToExpresisonTree = (text?: string) => {
    let element = this.editorElement;
    if (!element) return [];
    let contentText = text !== undefined ? text : element.textContent;
    contentText = (contentText || '').replace(/\s/g, '');
    const result = convertComputedColumnTextToExpressionTree(contentText, this.props.tables);
    return result;
  };

  onExpressionTextChanged = () => {
    const { onExpressionUpdated } = this.props;
    let parsed = this.convertTextExpressionToExpresisonTree();
    let element = this.editorElement;
    onExpressionUpdated({ t: 'group', value: parsed }, (element && element.textContent) || '');
    this.reRenderAsFancy();
  };

  debouncedExpressionTextChanged: any = debounce(this.onExpressionTextChanged.bind(this), 100, false);

  onBlured = e => {
    this.editorElement && this.debouncedExpressionTextChanged();
  };

  onInput = e => {
    this.editorElement && this.debouncedExpressionTextChanged();
  };

  reRenderAsFancy = (text?: string) => {
    try {
      let parsedExpression = this.convertTextExpressionToExpresisonTree(text);
      const currentPosition = getCursorPosition(this.editorElement);
      this.editorElement.innerHTML = '';
      const div = document.createElement('span');

      let res = ReactDOM.render(
        <React.Fragment>
          {parsedExpression.map((exp, index) => (
            <ExpressionTokensPresenter expression={exp} key={index} />
          ))}
        </React.Fragment>,
        div
      );
      this.editorElement.innerHTML = div.innerHTML;
      this.state.isEditorFocusable && setCursorPosition(this.editorElement, currentPosition);
    } catch (e) {}
  };

  handleEditorClickAway = e => {
    this.setState({
      isEditorFocusable: false,
    });
  };

  render() {
    const { showAggSelect, showColumnSelect, highlightedSuggestionIndex: focusedSuggestionIndex } = this.state;
    const suggestionsDataSource = this.flatSuggestionsDataSource;
    const hasAnySuggestoinItem = suggestionsDataSource.find(c => !c.isGroupTitle);
    const showSelect = showAggSelect || showColumnSelect;

    return (
      <React.Fragment>
        <div style={styles.editorWrapStyle as any} onBlur={this.onBlured}>
          =
          <ClickAwayListener onClickAway={this.handleEditorClickAway}>
            <ExpressionTextInput
              ref='expressionTextInput'
              contentEditable={true}
              onFocus={this.onFocus}
              onSelect={this.onFocus}
              onInput={this.onInput}
              onKeyDown={this.onKeyDown}
              onKeyUp={this.onKeyUp}
            />
          </ClickAwayListener>
          {showSelect && (
            <SimpleSelect handleClose={e => this.setState({ showAggSelect: false, showColumnSelect: false })} anchorEl={this.editorElement}>
              {hasAnySuggestoinItem ? (
                <SuggestionsSelectList
                  focusIndex={focusedSuggestionIndex}
                  onSelectOption={this.selectSuggestionByMouseClick}
                  suggestionsDataSource={suggestionsDataSource}
                />
              ) : (
                <div style={{ textAlign: 'center', color: Colors.brandDarkGrey }}> No Suggestions</div>
              )}
            </SimpleSelect>
          )}
        </div>
      </React.Fragment>
    );
  }
}
