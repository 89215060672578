import React from 'react';
import CreatableAutoCompleteSelect from 'react-select/lib/Creatable';
import { isInListOperator } from 'src/shared/models/OiFilter';
export function InListOperatorFilter({ onPickSelectValue, operator, values, columnValues, onMenuOpen, dropdownRef }) {
  const MENU_IS_OPEN_CLASS = 'suggestions-list-is-opened';
  let suggestionsCount = Math.min(6, columnValues.length);
  return (
    <CreatableAutoCompleteSelect
      isClearable={false}
      isMulti={isInListOperator(operator)}
      onChange={onPickSelectValue}
      value={values.map(v => ({
        value: v,
        label: v,
      }))}
      onMenuClose={onSuggestionMenuClosed(dropdownRef, MENU_IS_OPEN_CLASS, suggestionsCount)}
      onMenuOpen={onSuggestionMenuOpend(dropdownRef, MENU_IS_OPEN_CLASS, suggestionsCount, onMenuOpen)}
      name='color'
      options={columnValues.map(v => ({
        value: '' + v.value,
        label: '' + v.value,
      }))}
    />
  );
}
function onSuggestionMenuOpend(dropdownRef: any, MENU_IS_OPEN_CLASS: string, suggestionsCount: number, onMenuOpen: any) {
  return () => {
    if (dropdownRef && !dropdownRef.classList.contains(MENU_IS_OPEN_CLASS)) {
      let parentRec = dropdownRef.getBoundingClientRect();
      dropdownRef.style.minHeight = parentRec.height + (suggestionsCount * 38 + 30) + 'px';
      dropdownRef.classList.add(MENU_IS_OPEN_CLASS);
    }
    onMenuOpen && onMenuOpen();
    return;
  };
}

function onSuggestionMenuClosed(dropdownRef: any, MENU_IS_OPEN_CLASS: string, suggestionsCount: number) {
  return () => {
    if (dropdownRef && dropdownRef.classList.contains(MENU_IS_OPEN_CLASS)) {
      let parentRec = dropdownRef.getBoundingClientRect();
      dropdownRef.style.minHeight = parentRec.height - (suggestionsCount * 38 + 30) + 'px';
      dropdownRef.classList.remove(MENU_IS_OPEN_CLASS);
    }
  };
}
