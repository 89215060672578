import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import React from 'react';
import { hasModulePermission } from 'src/services/permissions/utils';
import { getPermissions, getRoles } from 'src/store/account/selectors';
import { Redirect } from 'react-router';

interface IProps extends StateProps {
  userPermissions: string[];
  moduleName?: string;
  canActivate?: any;
  userRoles: string[];
  renderNotPermitted?: () => any;
  children?: any;
  // [key: string]: any;
}
const CanActivate = (props: IProps) => {
  const { moduleName, userPermissions, userRoles, children, canActivate } = props;
  const readyToCheck = userPermissions && userRoles.length;
  if (
    !readyToCheck ||
    (canActivate && canActivate(userPermissions, userRoles)) ||
    hasModulePermission(userPermissions, userRoles, moduleName || '')
  ) {
    return children;
  } else {
    return <></>;
  }
};

// export default QuestionsList;
const mapStateToProps = (state: IRootState) => ({
  userPermissions: getPermissions(state),
  userRoles: getRoles(state),
});
type StateProps = ReturnType<typeof mapStateToProps>;

const CanActivateModule = connect(
  mapStateToProps,
  null
)(CanActivate);
export default CanActivateModule;
