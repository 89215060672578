import * as React from 'react';
import * as echarts from 'echarts';
import { debounce } from 'modules/questions/studio/utils';
import { ChartCustomizationOptions } from 'src/components/QuestionPreviewer/models';
import { DefaultColorsPallette, ColorsSchemasList } from 'src/components/QuestionPreviewer/ColorSchamas';
import { reduceMultiDimensionalToOne } from 'components/Charts/utils';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { PieChartClickEvent } from 'src/shared/models/EchartsClickEvents';
import { ColumnType } from 'src/shared/models/SqlColumn';
interface IProps {
  customize?: ChartCustomizationOptions;
  data: Array<any>;
  meta: Array<any>;
  currentGroup?: ColumnMetadatum;
  setChartRef: (e) => any;
  myRef?: (e: { resize: () => any }) => void;
}
export default class LineComponent extends React.Component<IProps> {
  myChart: echarts.ECharts;
  didMount = false;
  getDefaultOptions(props?) {
    const getToolTip = segment => {
      return `${segment.data[0]}:<br/> ${segment.data[1]}`;
    };
    props = props || this.props;
    return {
      legend: {
        show: props.customize ? props.customize.legend : false,
        type: 'scroll',
        orient: 'horizontal',
        x: 'center',
        y: 'top',
      },
      colors: DefaultColorsPallette,
      tooltip: {
        trigger: 'item',
        show: true,
        formatter: getToolTip,
      },
      series: [
        {
          type: 'line',
          label: {
            formatter: getToolTip,
            normal: {
              formatter: getToolTip,
              show: false,
              position: 'center',
            },
            emphasis: {
              show: false,
              textStyle: {
                fontSize: '16',
              },
            },
          },

          labelLine: {
            normal: {
              smooth: 0.4,
              length: 10,
              length2: 20,
            },
          },
        },
      ],
      xAxis: {
        type: 'category',
      },
      yAxis: {
        type: 'value',
      },
    };
  }

  getMarkingOptions(props) {
    const customize = (props || this.props).customize || {};
    return {
      markPoint: {
        data: [
          {
            name: 'Maximum',
            type: 'max',
            show: customize.maxPoint,
          },
          {
            name: 'Minimum',
            type: 'min',
            show: customize.minPoint,
          },
          {
            name: 'Average',
            type: 'average',
            show: customize.avgPoint,
          },
        ].filter(c => c.show),
      },
      markLine: {
        data: [
          {
            name: 'Maximum',
            type: 'max',
            show: customize.maxLine,
          },
          {
            name: 'Minimum',
            type: 'min',
            show: customize.minLine,
          },
          {
            name: 'Average',
            type: 'average',
            show: customize.avgLine,
          },
        ].filter(c => c.show),
      },
    };
  }

  componentWillUnmount() {
    this.didMount = false;
  }

  componentWillReceiveProps(nextProps) {
    // if (JSON.stringify(nextProps) == JSON.stringify(this.props) || _.isEqual(nextProps, this.props)) return;
    this.debouncedSetChart(nextProps);
  }

  componentDidMount() {
    this.didMount = true;
    let element = this.refs.lineChartDiv as HTMLDivElement;
    if (this.myChart) {
      this.myChart.dispose();
    }
    this.myChart = echarts.init(element);
    this.myChart.resize();
    this.props.myRef && this.props.myRef({ resize: this.myChart.resize });
    this.debouncedSetChart(this.props);
  }

  debouncedSetChart: any = debounce(this.refreshData.bind(this), 100, false);

  setChart(options) {
    if (this.myChart && !this.myChart.isDisposed()) {
      this.myChart.setOption(options);
      this.myChart.resize();

      this.props.setChartRef(this.myChart);
    }
  }

  refreshData(props) {
    if (!this.didMount) return;
    let options = Object.assign({}, this.getDefaultOptions(props) as any);
    let _data = (this.props.data || []).slice();
    let data = _data.slice();
    let { customize, meta } = this.props;

    let currentGroup = this.props.currentGroup || {
      ...meta[0],
    };
    data = reduceMultiDimensionalToOne(meta, currentGroup, data);
    let newHeaderRow = [currentGroup].concat(meta.filter(m => m.type !== ColumnType.DIMENSION)).map((c: ColumnMetadatum) => c.label);

    options.dataset = {
      source: [newHeaderRow, ...data],
    };
    let colorIndex = (customize && customize.colorSchemaIndex) || -1;
    options.color = colorIndex >= 0 ? ColorsSchemasList[colorIndex] || DefaultColorsPallette : DefaultColorsPallette;
    options.legend = {
      data: newHeaderRow,
    };
    options.tooltip = {
      trigger: 'axis',
    };
    options.series = Array(data[0].length - 1).fill({
      ...this.getDefaultOptions(props).series[0],
      ...this.getMarkingOptions(props),
      smooth: customize ? customize.smooth : false,
      areaStyle: Boolean(customize && customize.area) ? {} : undefined,
    });

    this.setChart(options);
  }

  render() {
    return (
      <>
        <div ref='lineChartDiv' style={{ height: '100%', width: '100%' }} />
      </>
    );
  }
}
