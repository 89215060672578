import { withStyles, StyleRulesCallback, Theme, createStyles } from '@material-ui/core';

const style = (theme: Theme) =>
  createStyles({
    bodyRow: {
      '&:nth-child(odd)': {
        background: '#f7f7f7',
      },
      '&:hover': {
        background: '#d9f4ff',
      },
    },
    headRow: {
      height: 'auto',
      minHeight: 30,
    },
    theadCell: {
      borderColor: `${theme.palette.primary.main} !important`,
      textTransform: 'capitalize',
      fontSize: 16,
    },
    simpleTableFooter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    simpleTableFooterNavigators: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      margin: '0 auto',
    },
    paginationButton: {
      borderRadius: '50%',
      border: `1px solid ${theme.palette.grey[700]}`,
      color: '#454c53',
      margin: '5px 5px',
      width: 35,
      height: 35,
      fontSize: 18,
      display: 'inline-block',
    },
    activePaginationButton: {
      background: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white !important',
      width: '35px !important',
      height: '35px !important',
      '&:hover': {
        background: theme.palette.primary.light,
        color: 'white !important',
      },
    },
  });

const Styled = withStyles(style);
export default Styled;
