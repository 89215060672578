import React from 'react';
import OiIconButton from 'src/components/OiIconButton';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import CanDo from 'src/components/Permissions/Can';
import dashboardAccess from 'src/services/permissions/abilities/dashboard';
import FavIcon from '@material-ui/icons/Star';
import TooltipedIconButton from 'src/components/TooltipedIconButton';

function ActionButtons(props) {
  const { onFavouriteClick, onEditClick, onDeleteClick, isFavourite } = props;
  return (
    <span onClick={e => e.stopPropagation()}>
      <TooltipedIconButton
        tooltip={(isFavourite ? 'Remove from' : 'Add to') + ' favourites'}
        onClick={e => {
          e.stopPropagation();
          onFavouriteClick();
        }}
      >
        <FavIcon scale='2' style={{ color: isFavourite ? '#ffeb3b' : '' }} />
      </TooltipedIconButton>

      <CanDo action={dashboardAccess.edit}>
        <TooltipedIconButton
          tooltip={'Edit dashboard'}
          onClick={e => {
            e.stopPropagation();
            onEditClick();
          }}
        >
          <Edit />
        </TooltipedIconButton>
      </CanDo>

      <CanDo action={dashboardAccess.delete}>
        <TooltipedIconButton
          tooltip={'Delete dashboard'}
          onClick={e => {
            e.stopPropagation();
            onDeleteClick();
          }}
        >
          <Delete />
        </TooltipedIconButton>
      </CanDo>
    </span>
  );
}
export default ActionButtons;
