// Generated by https://quicktype.io

export enum SORT_DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}

export const QUESTION_QUERY_PAGE_SIZE = 5000;
export interface IQuestionQueryPaginator {
  pageNumber: number;
  pageLength: number;
}

export type SelectableListItem = { isChecked?: boolean };

export interface SelectItem {
  label?: string;
  value?: string;
}
