import { REQUEST } from 'store/util';
import types from './actionTypes';

export const login = (request, rememberMe = false) => ({
  type: REQUEST(types.LOGIN),
  payload: { request, rememberMe },
});

export const logout = () => ({
  type: REQUEST(types.LOGOUT),
});

export const clearAuth = message => {
  return {
    type: REQUEST(types.CLEAR_AUTH),
    payload: { message },
  };
};

export const forgetPassword = username => ({
  type: REQUEST(types.FORGET_PASSWORD),
  payload: { username },
});

export const resetPassword = request => ({
  type: REQUEST(types.RESET_PASSWORD),
  payload: { request },
});

export const setCheckingAuth = isCheckingAuth => ({
  type: types.SET_CHECKING_AUTH,
  payload: { isCheckingAuth },
});

export const checkAuth = () => ({
  type: types.CHECK_AUTH,
  payload: {},
});

export const setToken = token => ({
  type: types.SET_TOKEN,
  payload: { token },
});
export const onLoggedInSuccessfully = token => ({
  type: types.ON_LOGGED_IN_SUCCESSFULLY,
  payload: { token },
});
