import { connect } from 'react-redux';
import { update, getUser, fetchAuthorities, fetchUserPermissions, fetchPermissions, updateWithPermission } from 'src/store/users/actions';
import { user, authorities, getLoader, getSuccess, shouldRouteSelector } from 'src/store/users/selectors';
import Edit from 'src/modules/users/edit/Edit';
import types from 'src/store/users/actionTypes';

const mapStateToProps = state => {
  return {
    user: user(state),
    loader: getLoader(state, types.UPDATE),
    successMessage: getSuccess(state, types.UPDATE),
    shouldRouteBack: shouldRouteSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: id => dispatch(getUser(id)),
    update: body => dispatch(update(body)),
    updateWithPermission: body => dispatch(updateWithPermission(body)),
    getAuthorities: () => dispatch(fetchAuthorities()),
    getPermissions: () => dispatch(fetchPermissions()),
    getUserPermission: username => dispatch(fetchUserPermissions(username)),
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit);

export default connected;
