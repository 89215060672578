import { connect } from 'react-redux';
import questionStudioActions from 'src/store/questionStudio/questionStudioActions';
import qStudioSelectors from 'src/store/questionStudio/selectors';
import { IRootState } from 'src/store/rootReducer';
import StyledQuestionStudionControlPanel from './SideToolbar';
import { openConfirmationPopup } from 'store/confirmationPopup';

const mapStateToProps = (state: IRootState) => {
  const { questionStudio } = state;
  return {
    questionId: questionStudio.id,
    selectedDatabase: questionStudio.database,
    columns: (questionStudio.baseTable && questionStudio.baseTable.columns) || [],
    groupItems: qStudioSelectors.groupItems(state),
    viewsItems: qStudioSelectors.viewsItems(state),
    computedColumns: qStudioSelectors.computedColumns(state),
    hasRawData: qStudioSelectors.hasRawData(state),
    relatedTables: qStudioSelectors.relatedTables(state),
    selectedTable: questionStudio.baseTable,
    limit: questionStudio.limit,
  };
};

const mapDispatchToProps = {
  addRawData: questionStudioActions.pickRawData,
  addRowsCount: questionStudioActions.pickRowsCount,
  addGroupItem: questionStudioActions.pickGroupItem,
  addViewItem: questionStudioActions.pickViewItem,
  onSelectDb: questionStudioActions.onSelectDb,
  onPickGroupToAdd: questionStudioActions.pickGroupItem,
  onAddAdvanceFilter: questionStudioActions.onAddAdvanceFilter,
  onPickCalculatedColumn: questionStudioActions.onAddCalculatedColumn,
  onReset: questionStudioActions.resetIngredientsPanelData,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledQuestionStudionControlPanel);
