import oceanAuthApis from 'src/shared/apis/oceanAuthApis';
import toastr from 'src/components/toastr';
export const ACTION_TYPES = {
  GET_ACCOUNT: 'GET_ACCOUNT',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
};

// Actions
export const getAccout = () => ({
  type: ACTION_TYPES.GET_ACCOUNT,
});

export const changePassword = (data: { newPassword: string }) => ({
  type: ACTION_TYPES.PASSWORD_CHANGE,
  payload: oceanAuthApis.changeCurrentUserPassowrd(data).then(
    data => {
      toastr.success('Password changed successfully');
      return data;
    },
    err => {
      toastr.error('Failed to change password');
      console.error('change password', err);
      return err;
    }
  ),
});
