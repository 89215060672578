import React, { useState, useEffect } from 'react';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import apis from 'src/shared/apis';
import oceancoreApis from 'src/shared/apis/oceancoreApis';
import toastr from 'src/components/toastr';
import BackupForm from './BackupForm';

const backupQuestions = qIds => {
  return oceancoreApis.exportMetaQuestions(qIds).then(
    res => {
      toastr.success('Questions Exported Successfully');
      return true;
    },
    err => {
      console.error('error exporting questions meta data /(question backup)');
      toastr.error('error exporting questions meta data /(question backup)');
      return false;
    }
  );
};
const backupDashboards = dIds => {
  return oceancoreApis.exportMetaDashboards(dIds).then(
    res => {
      toastr.success('Dashboards Exported Successfully');
      return true;
    },
    err => {
      console.error('error exporting Dashboards meta data /(Dashboards backup)');
      toastr.error('error exporting Dashboards meta data /(Dashboards backup)');
      return false;
    }
  );
};
interface IProps {
  onCancel: (d: any) => any;
  onSaveAndClose: (d: any) => any;
}
export default function BackupFormContainer(props: IProps) {
  const [questionsList, setQuestionsList] = useState([] as OiQuestionDto[]);
  const [dashboardList, setDashboardList] = useState([] as any[]);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [isLoadingDashboards, setIsLoadingDashboards] = useState(false);

  useEffect(() => {
    loadQuestions();
    loadDashboards();
  }, []);

  const onSave = formBody => {
    setIsSavingForm(true);
    const questionsIds = formBody.selectedQuestions.map(q => q.id);
    const dashboardsIds = formBody.selectedDashboards.map(q => q.id);
    let questionPromise = (questionsIds.length && backupQuestions(questionsIds)) || Promise.resolve(true);
    let dashboardPromise = (dashboardsIds.length && backupDashboards(dashboardsIds)) || Promise.resolve(true);
    Promise.all([questionPromise, dashboardPromise]).then(([questionsSaved, dashboardsSaved]) => {
      setIsSavingForm(false);
      if (questionsSaved && dashboardsSaved) {
        props.onSaveAndClose(null);
      }
    });
  };

  const loadQuestions = () => {
    setIsLoadingQuestions(true);

    oceancoreApis
      .getQuestions()
      .then(
        questions => {
          setQuestionsList(questions.map(q => ({ ...q, isChecked: false })));
        },
        err => {
          console.error('Error Loading Questions List for backup list', err);
          toastr.warn('Error Loading Questions List for backup list');
        }
      )
      .then(e => {
        setIsLoadingQuestions(false);
      });
  };

  const loadDashboards = () => {
    setIsLoadingDashboards(true);
    oceancoreApis
      .getDashboards()
      .then(
        dashboards => {
          setDashboardList(dashboards.map(ds => ({ ...ds, isChecked: false })));
        },
        err => {
          console.error('Error Loading Dashboards List for backup list', err);
          toastr.warn('Error Loading Dashboards List for backup list');
        }
      )
      .then(e => {
        setIsLoadingDashboards(false);
      });
  };

  return (
    <BackupForm
      isSavingForm={isSavingForm}
      dashboardsList={dashboardList}
      questionsList={questionsList}
      isLoadingDashboards={isLoadingDashboards}
      isLoadingQuestions={isLoadingQuestions}
      onSave={onSave}
      onCancel={props.onCancel}
    />
  );
}
