import { oiQueryFiltersSelectOptions } from 'src/shared/models/OiFilter';
import { IAdvanceFilterGroup } from './models';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
const defaultOp = (oiQueryFiltersSelectOptions || []).find(c => c.operator === ComparisonOperator.IS_NOT_NULL);
export const DefaultConditionItem = {
  groupItemType: 'condition',
  values: [],
  filterOperator: { ...defaultOp },
  column: {},
} as any;
export const DefaultAdvanceFilterGroupItem = {
  groupItemType: 'group',
  items: [{ ...DefaultConditionItem }],
  groupOperator: 'and',
} as IAdvanceFilterGroup;
