import { OceanDataType } from 'src/shared/models/SqlColumn';
export enum ColumnDesignType {
  VIEW = 'VIEW',
  GROUPBY = 'GROUPBY',
  COMPUTED = 'COMPUTED',
}
export type IQLColumnExpression = ColumnExpression | ExpressionOperator;
export interface OiQueryColumn {
  columnIndex: number;
  dashboardLinkID: number;
  dataPattern: string;
  dbTable: string;
  designType: ColumnDesignType;
  expression: Array<IQLColumnExpression[] | IQLColumnExpression>;
  foreignKey: boolean;
  formatJSON: string;
  grouped: boolean;
  hyperlink: boolean;
  label: string;
  oceanDataType: OceanDataType;
  orderDirection: string;
  orderIndex: number;
  pin: boolean;
  primaryKey: boolean;
  questionLinkID: number;
  type: string;
  vid: number;
}
export type ExpressionOperator = '+' | '-' | '*' | '/';

export interface ExpressionItem {
  t: 'op' | 'col' | 'value' | 'group' | 'err';
  value: ColumnExpression | ExpressionOperator | ExpressionItem | number | string | Array<any>;
  parentId?: number;
  errMsg?: string;
}
export interface ColumnExpression {
  dbColumn: string;
  function: string;
}
