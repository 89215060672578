import * as React from 'react';
// Router Props
import { RouteComponentProps } from 'react-router';
// Material-ui component
import Typography from '@material-ui/core/Typography/Typography';
import Styled, { styleProps } from './style';
import Form from './components/Form';
import { StateProps, DispatchProps } from './LoginContainer';
import AuthGrid from '../grid/AuthGrid';

export interface IAppProps extends styleProps, StateProps, DispatchProps, RouteComponentProps<{}> {}

class Login extends React.Component<IAppProps> {
  componentDidUpdate(nextProps) {
    const { isAuthenticated, history } = this.props;
    if (isAuthenticated !== nextProps.isAuthenticated && isAuthenticated == true) {
      history.push('/');
    }
  }
  handleSubmit = response => {
    const { login } = this.props;
    const request = {
      username: response.username,
      password: response.password,
    };
    login(request, false);
  };

  handleForgetPassword = () => {
    const { history } = this.props;
    history.push('/forget-password');
  };
  render() {
    const { classes, errorMessage, loading } = this.props;
    const hasError = errorMessage != '';
    return (
      <AuthGrid>
        <Form loading={loading} onSubmit={this.handleSubmit} onForgetPassword={this.handleForgetPassword} error={hasError} />

        {hasError && (
          <div className={classes.errorWrapper}>
            <Typography noWrap variant='subtitle1' color='error'>
              {errorMessage}
            </Typography>
          </div>
        )}
      </AuthGrid>
    );
  }
}
const styled = Styled(Login);
export default styled;
