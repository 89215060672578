import { REDUCER_NAME } from './constant';

export default {
  RESET: `${REDUCER_NAME}/RESET`,
  GET_FAV_DASHBOARDS: `${REDUCER_NAME}/GET_FAV`,
  CREATE_FAV_DASHBOARD: `${REDUCER_NAME}/CREATE_FAV`,
  DELETE_FAV_DASHBOARD: `${REDUCER_NAME}/DELETE_FAV`,
  GET_DASHBOARDS: `${REDUCER_NAME}/GET`,
  CREATE_DASHBOARD: `${REDUCER_NAME}/CREATE`,
  DELETE_DASHBOARD: `${REDUCER_NAME}/DELETE`,
  SHARE_DASHBOARD: `${REDUCER_NAME}/SHARE_DASHBOARD`,
  DASHBOARDS_TABS: `${REDUCER_NAME}/DASHBOARDS_TABS`,
  CHANGE_SELECTED_TAB: `${REDUCER_NAME}/CHANGE_SELECTED_TAB`,
  ADD_FILTER_DASHBOARD: `${REDUCER_NAME}/ADD_FILTER_DASHBOARD`,
  ADD_ALL_FILTERS_DASHBOARD: `${REDUCER_NAME}/ADD_ALL_FILTERS_DASHBOARD`,
  REMOVE_FILTER_DASHBOARD: `${REDUCER_NAME}/REMOVE_FILTER_DASHBOARD`,
};
