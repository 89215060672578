import { connect } from 'react-redux';
import { IRootState } from 'src/store/rootReducer';
import { closeConfirmationPopup } from 'store/confirmationPopup';
import ConfirmationPopup from './ConfirmationPopup';

export const mapStateToProps = ({ confirmationPopup }: IRootState) => ({
  isOpen: confirmationPopup.showConfirmationPopup,
  configs: confirmationPopup.confirmConfigs,
});

export const mapDispatchToProps = {
  closeConfirmationPopup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationPopup);
