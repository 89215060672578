import * as React from 'react';

const AddGroupObjectsIcon = props => (
  <svg viewBox='0 0 252 252' {...props}>
    <g
      fill='none'
      strokeMiterlimit={10}
      fontFamily='none'
      fontWeight='none'
      fontSize='none'
      textAnchor='none'
      style={{ mixBlendMode: 'normal' }}
    >
      <path d='M0 252V0h252v252z' />
      <path
        d='M55.44 15.12v15.12h141.12V15.12h40.32v40.32h-15.12v89.155a59.808 59.808 0 0 0-10.08-2.629V55.44h-15.12V40.32H55.44v15.12H40.32v141.12h15.12v15.12h86.526a59.809 59.809 0 0 0 2.63 10.08H55.44v15.12H15.12v-40.32h15.12V55.44H15.12V15.12zm85.68 50.4v75.6h-75.6v-75.6zm45.36 40.32v37.206c-21.173 5.501-37.933 22.26-43.434 43.434H105.84V151.2h45.36v-45.36z'
        fill='currentColor'
      />
      <g fill='currentColor'>
        <path d='M231.336 201.6c0 3.024-2.016 5.04-5.04 5.04H206.64v19.656c0 3.024-2.016 5.04-5.04 5.04s-5.04-2.016-5.04-5.04V206.64h-19.656c-3.024 0-5.04-2.016-5.04-5.04s2.016-5.04 5.04-5.04h19.656v-19.656c0-3.024 2.016-5.04 5.04-5.04s5.04 2.016 5.04 5.04v19.656h19.656c3.024 0 5.04 2.016 5.04 5.04zm20.664 0c0 27.72-22.68 50.4-50.4 50.4s-50.4-22.68-50.4-50.4 22.68-50.4 50.4-50.4 50.4 22.68 50.4 50.4zm-10.08 0c0-22.176-18.144-40.32-40.32-40.32-22.176 0-40.32 18.144-40.32 40.32 0 22.176 18.144 40.32 40.32 40.32 22.176 0 40.32-18.144 40.32-40.32z' />
      </g>
    </g>
  </svg>
);

export default AddGroupObjectsIcon;
