import { connect } from 'react-redux';
import DashboardStudio from './Studio';
import { saveDashboardTabs, changeSelectedTab, reset } from 'src/store/dashboards/actions';
import { dashboardFiltersByTab, dashboardAllFilters } from 'src/store/dashboards/selectors';

const mapStateToProps = state => ({
  selectedFilters: dashboardFiltersByTab(state),
  dashboardStoreFilters: dashboardAllFilters(state),
});

const mapDispatchToProps = dispatch => {
  return {
    saveDashboardTabs: tabs => dispatch(saveDashboardTabs(tabs)),
    changeSelectedTab: index => dispatch(changeSelectedTab(index)),
    resetStore: () => dispatch(reset()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardStudio);
