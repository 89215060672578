import { StyleRulesCallback, Theme, createStyles, withStyles } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    Wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'stretch',
      padding: '0 !important',
      height: '100%',
      marginRight: 10,

      '& .controls-card:first': {
        marginTop: '-4px',
      },
      '& .controls-card.controls-card': {
        marginBottom: 10,
      },
    },
    H14px: {
      fontSize: 14,
      margin: 0,
      fontWeight: 400,
    },
  });
export default withStyles(styles);
