import React, { useState } from 'react';
import { ListItem, List, Checkbox, ListItemText, Button, CircularProgress } from '@material-ui/core';
import CustomSearch from '../CustomSearch';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  list: {
    maxHeight: 380,
    overflow: 'auto',
    marginBottom: 10,
  },
  toolbar: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '5px 10px',
  },
  unshareBtn: {
    marin: 'auto',
    marginRight: 0,
    maxHeight: 40,
    fontSize: 14,
  },
  checkBox: {
    padding: 2,
  },
});

export interface SharedEntityDto {
  displayName?: string;
  name: string;
  id: number;
}
interface SharedEntitiesListProps {
  isLoading?: boolean;
  entities: SharedEntityDto[];
  onUnshareSelectedEntities: (idsList: number[]) => any;
}
const SharedEntitiesList: React.SFC<SharedEntitiesListProps> = ({ entities, onUnshareSelectedEntities, isLoading }) => {
  const [checkedIds, setCheckedIds] = useState([] as number[]);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsToRender, setItemsToRender] = useState(entities);
  const classes = useStyles();
  React.useEffect(
    () => {
      const term = (searchTerm || '').trim().toLowerCase();
      if (term.length > 0) {
        setItemsToRender(entities.filter(e => e.name.toLowerCase().includes(term)));
      } else {
        setItemsToRender(entities.slice());
      }
    },
    [entities, searchTerm]
  );

  const isChecked = entity => checkedIds.includes(entity.id);
  const handleEntityCheckBoxClick = (e, entity: SharedEntityDto) => {
    if (isChecked(entity)) {
      setCheckedIds(checkedIds.filter(id => id !== entity.id));
    } else {
      setCheckedIds(checkedIds.concat([entity.id]));
    }
  };
  return (
    <div>
      <div className={classes.toolbar}>
        <CustomSearch
          isFullWidth
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.value)}
          value={searchTerm}
          placeholder='type to filter'
        />
        <Button className={classes.unshareBtn} variant='contained' onClick={e => onUnshareSelectedEntities(checkedIds)}>
          Unshare
        </Button>
      </div>
      <hr />
      <List className={classes.list} dense>
        {isLoading && (
          <div>
            {' '}
            <CircularProgress size={14} /> Loading ....{' '}
          </div>
        )}
        {itemsToRender.map(entity => {
          return (
            <ListItem key={entity.id} onClick={e => handleEntityCheckBoxClick(e, entity)}>
              <Checkbox className={classes.checkBox} checked={isChecked(entity)} tabIndex={-1} disableRipple />
              <ListItemText primary={entity.displayName || entity.name} />
            </ListItem>
          );
        })}

        {!isLoading && itemsToRender.length == 0 ? (
          <ListItem>
            <ListItemText primary={'No shared entity yet'} />
          </ListItem>
        ) : null}
      </List>
    </div>
  );
};
export default SharedEntitiesList;
