import { TextField, InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import * as React from 'react';

export const TableSearchField = ({ search, handleSearch, ...props }) => (
  <TextField
    id='search'
    name='search'
    type='search'
    placeholder='Search'
    variant='standard'
    margin='normal'
    className='rounded-outlined-input'
    value={search}
    onChange={handleSearch}
    onBlur={e => {
      let _input = e.currentTarget.closest('.rounded-outlined-input');
      _input && _input.classList.remove('rounded-outlined-input-foucs');
    }}
    onFocus={e => {
      let _input = e.currentTarget.closest('.rounded-outlined-input');
      _input && _input.classList.add('rounded-outlined-input-foucs');
    }}
    InputProps={{
      disableUnderline: true,
      endAdornment: (
        <InputAdornment position='end'>
          <Search />
        </InputAdornment>
      ),
    }}
  />
);
