import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    list: {
      padding: '15px 10px',
      minWidth: 300,
    },
    root: {
      borderRadius: 25,
      border: `1px solid ${theme.palette.primary.main}`,
      margin: '5px 0px',
    },
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      '& span': {
        color: '#fff',
      },
    },
  });

export interface StyleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);
export default Styled;
