import * as React from 'react';
import { Typography, Paper, withStyles } from '@material-ui/core';
export interface IProps {
  icon: any;
  title: any;
  disabled?: boolean;
  subTitle: string;
  classes?: any;
  borderColor?: string;
  onClick?: (e: any) => any;
}
const style = (borderColor, disabled): any => ({
  borderColor: borderColor || 'transparent',
  color: disabled ? 'inherit' : borderColor,
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'no-drop' : 'pointer',
  background: disabled ? 'rgba(255,255,255,0.3)' : 'white',
});

const ControlButton = ({ icon, title, subTitle, classes, borderColor, disabled, onClick }: IProps) => {
  return (
    <Paper
      elevation={1}
      className={classes.controlBtnWrap}
      style={style(borderColor, disabled)}
      onClick={e => {
        if (Boolean(disabled)) {
          e.preventDefault();
          e.stopPropagation();
          return false;
        } else {
          return (onClick && onClick(e)) || true;
        }
      }}
    >
      <div className={classes.controlIconWrap}>{icon}</div>
      <Typography variant={'caption'}>{subTitle}</Typography>
      {title}
    </Paper>
  );
};

export default withStyles({
  controlBtnWrap: {
    textAlign: 'center',
    display: 'flex',
    color: '$darkGrey',
    flexDirection: 'column',
    justifyContent: 'stretch',
    padding: 20,
    flex: '1 1 auto',
    cursor: 'pointer',
    borderColor: 'transparent',
    width: '100px',
    height: '100px',
    paddingLeft: 0,
    margin: 'auto',
    paddingRight: 0,
    '& .controlWrap *': {
      fontSize: '30px !important',
    },
  },
  controlIconWrap: {
    "& [class*='MuiSvgIcon']": {
      fontSize: 28,
      '& svg > path': {
        fill: '$darkGrey',
      },
    },
  },
})(ControlButton);
