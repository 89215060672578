import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import OiIconButton from 'src/components/OiIconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import CanDo from 'src/components/Permissions/Can';
import classNames from 'classnames';
import Styled from './style';
import TooltipedIconButton from '../TooltipedIconButton';

function Favourite(props) {
  const { favourites, classes, access, thumbClass, onPlay, onEdit, onUnfavourite } = props;
  return (
    <Scrollbars autoHide className='scrollbars' style={{ height: '200px', width: '100%' }}>
      <Grid container wrap='nowrap' spacing={8} alignItems={'stretch'} direction={'row'} justify={'flex-start'}>
        {favourites.length ? (
          favourites.map((favourite, index) => {
            return (
              <Grid key={index} item>
                <Card className={classNames(classes.card)}>
                  <Typography variant='body1' align='center' gutterBottom noWrap>
                    {favourite.name}
                  </Typography>
                  <CardContent className={classes.cardContent}>
                    <div className={classNames(classes.thumbImage, thumbClass(favourite))} />
                    <div className={classNames(classes.actionButtons, 'fav-action-buttons')} onClick={e => e.preventDefault()}>
                      <TooltipedIconButton tooltip='Open' onClick={e => onPlay(favourite.id)}>
                        <PlayIcon />
                      </TooltipedIconButton>
                      <CanDo action={access.edit}>
                        <TooltipedIconButton tooltip='Edit' onClick={e => onEdit(favourite.id)}>
                          <EditIcon />
                        </TooltipedIconButton>
                      </CanDo>
                      <TooltipedIconButton tooltip='remove from fav' onClick={e => onUnfavourite(favourite)}>
                        <CloseIcon />
                      </TooltipedIconButton>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Card className={classNames(classes.card)}>
            <Typography variant='body1' align='center' gutterBottom noWrap>
              No Favourite's yet
            </Typography>
            <CardContent className={classes.cardContent}>No Favourite's yet</CardContent>
          </Card>
        )}
      </Grid>
    </Scrollbars>
  );
}
const styled = Styled(Favourite);
export default styled;
