import * as React from 'react';
import styles from './styles';
import { OiFunction } from 'src/shared/models/OiFunction';
import { ExpressionChip } from './ExpressionChip';
import { Colors } from 'src/shared/theme';

interface IColumnPresenterProps {
  onDelete: (e?) => any;
  onAggClick: (e?) => any;
  onColumnClick: (e?) => any;
  canDelete: boolean;
  dbColumn: any;
  aggregate: any;
}
export const ColumnPresenter = ({ ...props }: IColumnPresenterProps) => {
  const onDelete = e => {
    e.stopPropagation();
    props.onDelete(e);
  };
  const onAggClick = e => {
    e.stopPropagation();
    props.onAggClick(e);
  };
  const onColumnClick = e => {
    e.stopPropagation();
    props.onColumnClick(e);
  };
  const colComponent = <span onClick={onColumnClick}>{props.dbColumn}</span>;
  const columnWithAgg = (
    <div>
      {props.aggregate}({colComponent})
    </div>
  );
  return (
    <span style={styles.aggPresenter} onClick={onAggClick}>
      <ExpressionChip bgColor={Colors.brandOrange} label={props.aggregate === OiFunction.NONE ? colComponent : columnWithAgg} />
    </span>
  );
};
