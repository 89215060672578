import { DownloadableFile } from '../models/DownloadableFile';
import { ExportQuestionDto } from '../models/Exporter';
import axios from 'axios';
import { exporterUrl } from './baseUrls';

const getDownloadsList = () => {
  return axios.get<DownloadableFile[]>(exporterUrl + '/oi-files?size=9999').then(resp => resp.data);
};
const exportQuestion = (dto: ExportQuestionDto) => {
  return axios.post<{ fileId: number; status: string; cause: string }>(exporterUrl + '/oi-files', dto).then(resp => resp.data);
};
const deleteExportedFile = id => {
  return axios.delete(exporterUrl + '/oi-files/' + id).then(resp => resp.data);
};
const shareQuestionByEmail = data => {
  return axios.post(exporterUrl + '/oi-files/share', data).then(resp => resp.data);
};
// Job scheduler
const addScheduler = body => {
  return axios.post(exporterUrl + '/jobs', body);
};
export default {
  getDownloadsList,
  exportQuestion,
  deleteExportedFile,
  shareQuestionByEmail,
  addScheduler,
};
