import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import DasbhoardsComponent from 'src/modules/dashboards';
import ScheduledTasksListComponent from 'src/modules/scheduledTasks';
import QuestionsComponent from 'src/modules/questions';
import UsersComponent from 'src/modules/users';
import SettingsComponent from 'src/modules/settings';
import DownloadsModule from 'src/modules/downloads';
import DatabasesList from 'src/modules/databases';
import NotFound from 'src/layout/NotFoundPage';
import NotAuthorizedPage from 'src/layout/NotAuthorized';
import PrivateRoute from './PrivateRoute';

const routes = (
  <Switch>
    <PrivateRoute path='/dashboards' component={DasbhoardsComponent} />
    <PrivateRoute path='/questions' component={QuestionsComponent} />
    <PrivateRoute path='/scheduled-tasks' component={ScheduledTasksListComponent} />
    <PrivateRoute path='/downloads' component={DownloadsModule} />
    <PrivateRoute path='/databases' component={DatabasesList} />
    <PrivateRoute path='/users' component={UsersComponent} />
    <PrivateRoute path='/settings' component={SettingsComponent} />
    <Redirect path='/' exact to='/dashboards' />
    <Route path='/NotAuthorized' component={NotAuthorizedPage} />
    <Route component={NotFound} />
  </Switch>
);
export default routes;
