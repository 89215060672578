import ShareWithUsersPopup from './ShareUsersPopup';
import { connect } from 'react-redux';
import { fetchShareableUsers } from 'src/store/users/actions';
import { Loader } from 'src/store/util';
import usersActionTypes from 'src/store/users/actionTypes';

const mapStateToProps = state => ({
  usersList: state.users.shareableUsers || [],
  loading: state.users[Loader(usersActionTypes.GET_SHAREABLE_USERS)],
});

const mapDispatchToProps = {
  fetchSharableUsers: fetchShareableUsers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareWithUsersPopup);
