import { put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES } from './questions.reducer';
import apisService from 'src/shared/apis';

export function* onGetQuestionById({ payload }) {
  try {
    const result = yield apisService.getQuestion(payload);
    yield put({ type: ACTION_TYPES.GET_QUESTION_BY_ID_SUCCESS, payload: result });
  } catch (e) {
    console.error(e);
    yield put({ type: ACTION_TYPES.GET_QUESTION_BY_ID_SUCCESS, message: e.message });
  }
}

export default [takeEvery(ACTION_TYPES.GET_QUESTION_BY_ID as any, onGetQuestionById)];
