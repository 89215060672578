import React from 'react';
import Delete from '@material-ui/icons/Delete';
import QuestionFilteringItem from '../../IngredientsPanel/QuestionFilteringItem';
import { IAdvanceFilterGroup } from './models';
import { SQLTable } from 'src/shared/models/SqlTable';
import { QueryViewItem, QueryFilterViewItem } from '../../models';
import './style.scss';
import OiIconButton from 'src/components/OiIconButton';
import OiSimpleMenu from 'src/components/SimpleMenu';
import { DefaultConditionItem, DefaultAdvanceFilterGroupItem } from './utils';
import AddOiFilterWizard from '../../SideToolBar/AddOiFilterWizard/AddOiFilterWizard';
import { oiQueryFiltersSelectOptions } from 'src/shared/models/OiFilter';

interface IPorps {
  group: IAdvanceFilterGroup;
  tables?: SQLTable[];
  aggregateColumns?: QueryViewItem[];
  onGroupChanged: (g: IAdvanceFilterGroup) => any;
  onDelete: () => any;
}
const AdvanceFilterGroup = ({ group, tables = [], aggregateColumns = [], onGroupChanged, onDelete: onDeleteGroup, ...props }: IPorps) => {
  const onChangeItem = (itemBatch, index) => {
    const items = group.items.map((item, i) => {
      if (index == i) {
        return { ...itemBatch };
      } else return item;
    });
    onGroupChanged({
      ...group,
      items,
    });
  };
  const deleteItem = index => {
    const items = group.items.filter((e, i) => i !== index);
    onGroupChanged({
      ...group,
      items,
    });
  };
  const changeGroupOp = value => {
    onGroupChanged({
      ...group,
      groupOperator: value,
    });
  };
  const addGroup = () => {
    onGroupChanged({
      ...group,
      items: group.items.concat([{ ...DefaultAdvanceFilterGroupItem }]),
    });
  };
  const addCondition = (filterItem = DefaultConditionItem) => {
    onGroupChanged({
      ...group,
      items: group.items.concat([{ ...filterItem }]),
    });
  };
  return (
    <div className='group-panel'>
      <div className='group-bar'>
        <OiSimpleMenu options={['and', 'or']} onSelectOption={changeGroupOp} value={group.groupOperator}>
          <div className='btn glue-btn'>{group.groupOperator}</div>
        </OiSimpleMenu>

        <AddOiFilterWizard
          tables={tables}
          aggregateColumns={aggregateColumns}
          filteringOperators={oiQueryFiltersSelectOptions}
          onDone={addCondition}
        >
          <button className='btn add-condition'> + condition </button>
        </AddOiFilterWizard>

        <button className='btn add-group' onClick={addGroup}>
          + group
        </button>
        <OiIconButton onClick={e => onDeleteGroup()}>
          <Delete />
        </OiIconButton>
      </div>
      {(group.items || []).map((item, index) => {
        if (item.groupItemType == 'group') {
          return (
            <AdvanceFilterGroup
              key={index}
              group={item as IAdvanceFilterGroup}
              onGroupChanged={batch => onChangeItem(batch, index)}
              onDelete={() => deleteItem(index)}
              aggregateColumns={aggregateColumns}
              tables={tables}
            />
          );
        } else {
          return (
            <div className='condition-panel'>
              <QuestionFilteringItem
                itemIndex={index}
                key={index}
                item={item as QueryFilterViewItem}
                tables={tables}
                source='questionStudio'
                onUpdated={batch => onChangeItem(batch, index)}
                onDelete={e => deleteItem(index)}
              />
            </div>
          );
        }
      })}
    </div>
  );
};
export default AdvanceFilterGroup;
