import { StyleRulesCallback, Theme } from '@material-ui/core/styles';

const style: StyleRulesCallback = (theme: Theme) => ({
  loaderWrapper: {
    textAlign: 'center',
    height: '50px',
  },
  container: {
    height: 'calc(100vh - 200px)',
  },
  card: {
    overflow: 'hidden',
    // height: 'calc(100vh - 210px)',
    height: '100%',
  },
  cardHeader: {
    height: 50,
  },
  cardBody: {
    height: 'calc(100% - 50px)',
  },
});
export default style;
