import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import NestableMenu from './NestableMenu';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const styles = {
  subMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

class SubMenuItem extends React.Component<any, any> {
  state = {
    anchorElement: null,
    menuOpen: false,
  };
  setAnchorElement = node => {
    this.setState({ anchorElement: node });
  };

  handleItemClick = event => {
    if (!this.state.anchorElement) {
      this.setAnchorElement(event.currentTarget);
    }
    this.setState(s => ({ menuOpen: !s.menuOpen }));
  };

  handleSubMenuClose = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    const { label, menuItems, classes } = this.props;
    return (
      <React.Fragment>
        <MenuItem onClick={this.handleItemClick} className={classNames(classes.subMenuItem)}>
          {label}
          <ArrowRightIcon />
        </MenuItem>
        <NestableMenu
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={this.state.menuOpen}
          menuItems={menuItems}
          anchorElement={this.state.anchorElement}
          onClose={this.handleSubMenuClose}
        />
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(SubMenuItem);
