import { REQUEST, SUCCESS, FAILURE } from 'store/util';

import { SETTINGS_ACTION_TYPES } from './settings.action';

const initialState = {
  loading: false,
  data: [],
  errorMessage: String,
};

// Reducer
export default (state = initialState, { type, payload, ...action }): any => {
  switch (type) {
    case REQUEST(SETTINGS_ACTION_TYPES.FETCH):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(SETTINGS_ACTION_TYPES.FETCH): {
      return {
        ...initialState,
        loading: false,
        errorMessage: payload.message,
      };
    }
    case SUCCESS(SETTINGS_ACTION_TYPES.FETCH): {
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    }
    default:
      return state;
  }
};
