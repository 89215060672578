import React from 'react';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import { MenuItem, TextField } from '@material-ui/core';

export default function CurrentDatePeriodFilter({ value, onChange }) {
  return (
    <TextField
      id='period-select'
      label='Interval'
      placeholder=' Select a Interval'
      name='Interval'
      margin='dense'
      variant='outlined'
      fullWidth
      value={value}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
      select
    >
      <MenuItem value={ComparisonOperator.CURRENT_DAY}> day </MenuItem>
      <MenuItem value={ComparisonOperator.CURRENT_MONTH}> month </MenuItem>
      <MenuItem value={ComparisonOperator.CURRENT_YEAR}> year </MenuItem>
    </TextField>
  );
}
