export default {
  FETCH_USERS: 'FETCH_USERS',
  CREATE: 'CREATE_USER',
  CREATE_WITH_PERMISSION: 'CREATE_WITH_PERMISSION',
  UPDATE: 'UPDATE_USER',
  UPDATE_WITH_PERMISSION: 'UPDATE_WITH_PERMISSION',
  DELETE: 'USER_DELETE',
  GET_USER: 'GET_USER',
  GET_SHAREABLE_USERS: 'GET_SHAREABLE_USERS',
  FETCH_PERMISSIONS: 'FETCH_PERMISSIONS',
  GRANT_PERMISSIONS: 'GRANT_PERMISSIONS',
  UPDATE_GRANT_PERMISSIONS: 'UPDATE_GRANT_PERMISSIONS',
  USER_PERMISSIONS: 'USER_PERMISSIONS',
  FETCH_AUTHORITIES: 'FETCH_AUTHORITIES',
  ROUTE_TO_USERS_PAGE: 'ROUTE_TO_USERS_PAGE',
};
