import { Store } from 'redux';
import { IRootState } from 'src/store/rootReducer';
import { setCheckingAuth, setToken, onLoggedInSuccessfully, logout } from 'src/store/authentication/actions';
import { getToken } from 'src/store/authentication/selectors';
import setupAxiosAuthInterceptors from 'src/store/config/axios-interceptor';
import * as keycloakService from './KeycloakService';
import environment from 'src/envReader';
import { store } from 'src/constants';
import { fetchColumnsMap } from 'src/store/columnsMap/actions';

export function checkLogin() {
  const useKeycloakSSO = environment.useKeycloakSSO;
  store.dispatch(setCheckingAuth(true));
  const promise = new Promise((resolve, reject) => {
    if (useKeycloakSSO) {
      keycloakService.initKeycloakAuth().then(auth => {
        store.dispatch(onLoggedInSuccessfully(auth.authz.token));
        store.dispatch(setCheckingAuth(false));
        resolve();
      }, reject);
    } else {
      const token = getToken();
      if (token) {
        store.dispatch(setToken(token));
        resolve();
      } else {
        reject();
      }
    }
  });
  promise.then(
    () => {
      setupAxiosAuthInterceptors();
      store.dispatch(fetchColumnsMap());
      store.dispatch(setCheckingAuth(false));
    },
    () => {
      store.dispatch(logout());
      store.dispatch(setCheckingAuth(false));
    }
  );
  return promise;
}
