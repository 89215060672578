import { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';

interface IProps {
  options: any[];
  labelKey?: string;
  valueKey?: string;
  onSelectOption: (option?: any) => any;
  children: any;
  value: string;
}
export default function OiSimpleMenu(props: IProps) {
  const { options, labelKey, valueKey, value, onSelectOption } = props;
  const [anchorEl, setAnchorEl] = useState(undefined as any);

  return (
    <React.Fragment>
      <div onClick={e => setAnchorEl(e.target)}>{props.children || value}</div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={setAnchorEl.bind(undefined)}>
        {options.map((option, i) => (
          <MenuItem
            value={valueKey ? option[valueKey] : option}
            key={valueKey ? option[valueKey] : option}
            onClick={e => {
              setAnchorEl(undefined);
              onSelectOption(valueKey ? option[valueKey] : option);
            }}
          >
            {labelKey ? option[labelKey] : option}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
