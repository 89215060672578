import React from 'react';
import { withStyles } from '@material-ui/core';
import { OceanDataType, ColumnValue, ColumnAnalysis } from 'src/shared/models/SqlColumn';
import { isEqualityOperator, isEmptinessOperator, isInListOperator } from 'src/shared/models/OiFilter';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import { InListOperatorFilter } from './InListOperatorFilter';
import { TextValueFilter } from './TextValueFilter';
import { BooleanValueFilter } from './BooleanValueFilter';
import NumericValuesFilterEditor from './NumericValuesFilterEditor';
import { DateValuesFilterEditor } from './DateValuesFilterEditor';
import moment from 'moment';
import { KEYCODES } from 'src/shared/util/keyboard';
import { APP_LOCAL_DATE_FORMAT } from 'src/constants';

type IEditorProps = {
  values: any[];
  valueType: OceanDataType;
  operator: ComparisonOperator;
  autoOpenDatePicker?: boolean;
  columnAnalysis?: ColumnAnalysis;
  columnValues?: ColumnValue[];
  onMenuOpen?: () => any;
  classes?: any;
  onBlur?: any;
  onKeyDown?: any;
  onChange: any;
  onSave?: any;
};
const onValueChanged = (props: IEditorProps, i) => (e: any) => {
  let v = e.target.value;
  let values = props.values.slice();
  values[i] = v;
  props.onChange && props.onChange(values);
};
const onPickSelectValue = (props, i) => obj => {
  let values = props.values.slice();
  if (isInListOperator(props.operator)) {
    values = obj.map(o => o.value);
    return props.onChange && props.onChange(values);
  }
  values[i] = obj.value;
  props.onChange && props.onChange(values);
};

const onPickDay = (props, i) => date => {
  let v = moment(date).format(APP_LOCAL_DATE_FORMAT); //date;
  let values = props.values.slice();
  values[i] = v;
  props.onChange && props.onChange(values);
};
const _FilterValueEditor = (props: IEditorProps) => {
  const classes = props.classes;
  const onBlur = e => props.onBlur && props.onBlur(e.currentTarget.value);
  const onKeyDown = e => e.keyCode == KEYCODES.ENTER && props.onSave && props.onSave(e.currentTarget.value);
  const analysis = props.columnAnalysis || ({} as any);
  let selectDropdownWrapRef: HTMLElement = undefined as any; //React.createRef();
  const onChange = onValueChanged.bind({}, props);
  if (isEmptinessOperator(props.operator)) {
    return <div />;
  }
  switch (props.valueType as OceanDataType) {
    case OceanDataType.NUMBER: {
      return (
        <NumericValuesFilterEditor
          classes={classes}
          values={props.values}
          operator={props.operator}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
        />
      );
    }
    case OceanDataType.DATE: {
      return (
        <DateValuesFilterEditor
          analysis={analysis}
          classes={classes}
          values={props.values}
          operator={props.operator}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onPickDay={onPickDay.bind({}, props)}
          // {...props}
        />
      );
    }
    case OceanDataType.BOOLEAN: {
      if (isEqualityOperator(props.operator)) {
        return (
          <div className={classes.flexWrapper}>
            <BooleanValueFilter value={props.values[0]} onChange={onChange(0)} />
          </div>
        );
      }
    }
    case OceanDataType.STRING:
    default: {
      if ((isEqualityOperator(props.operator) && (props.columnValues || []).length) || isInListOperator(props.operator)) {
        return (
          <div ref={s => (selectDropdownWrapRef = s as HTMLElement)}>
            <InListOperatorFilter
              columnValues={props.columnValues || []}
              onPickSelectValue={onPickSelectValue(props, 0)}
              dropdownRef={selectDropdownWrapRef}
              operator={props.operator}
              values={props.values}
              onMenuOpen={props.onMenuOpen}
            />
          </div>
        );
      } else {
        return (
          <div className={classes.flexWrapper}>
            <TextValueFilter value={props.values[0]} label='enter value' onBlur={onBlur} onKeyDown={onKeyDown} onChange={onChange(0)} />
          </div>
        );
      }
    }
  }
};
export const FilterValueEditor = withStyles({
  flexWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    '& .basic-single': {
      position: 'relative',
      zIndex: 100000,
    },
  },
})(_FilterValueEditor);
