import Studio, { DashboardMode } from '../Studio/Studio';
import OIServicesDashboard from 'modules/dashboards/dashboard.service';
import toastr from 'src/components/toastr';

class EditDashboard extends Studio {
  dashboard_id: number = 0;

  componentWillMount() {
    this.setState({ mode: DashboardMode.studio });
    const { match, addFilter, saveDashboardTabs } = this.props;

    let id = parseInt(match.params.id);

    OIServicesDashboard.GetDashboardsById(id)
      .then((response: any) => {
        saveDashboardTabs(response.tabs);
        addFilter(response.filters || []);

        this.dashboard_id = response.id;

        let tabs = response.tabs.map(tab => {
          tab.views = tab.views.map(view => {
            let obj: any = JSON.parse(view.position);
            obj.id = view.id;
            return obj;
          });
          tab.views.sort((a, b) => a._i - b._i);
          return tab;
        });
        this.setState({
          tabs: tabs,
          name: response.name,
          description: response.description,
          tags: response.tag,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  handleSaveDashboard = result => {
    let body = this.mapStateToDto(result);
    (body as any).id = this.dashboard_id;
    const { dashboardStoreFilters, history } = this.props;

    body['filters'] = dashboardStoreFilters;

    OIServicesDashboard.UpdateDashboard(body)
      .then(response => {
        history.push('/dashboards');
        toastr.success('Saved');
      })
      .catch(err => {
        console.log(err.message);
        toastr.error(err.message);
      });
  };
}

export default EditDashboard;
