import * as React from 'react';
import { Button, Paper, Dialog, withStyles } from '@material-ui/core';
import AlertIcon from '@material-ui/icons/Warning';
import { DispatchProps, StateProps } from './models';
const StyledPaper = withStyles({
  root: {
    textAlign: 'center',
    padding: 20,
    '& svg': {
      width: '3em',
      height: '3em',
    },
  },
})(Paper);

// Paper;
interface IProps extends DispatchProps, StateProps {
  closeConfirmationPopup: any;
}
export default class ConfirmationPopupPlain extends React.Component<any> {
  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      let popup = document.getElementById('AppConfirmationPopup');
      popup && popup.focus();
    }
  }

  confirm = () => {
    let { configs } = this.props;
    this.props.closeConfirmationPopup();
    configs.ok && configs.ok(true);
  };

  reject = () => {
    let { configs } = this.props;
    this.props.closeConfirmationPopup();
    configs.cancel && configs.cancel(false);
  };

  render() {
    let { confirm, reject } = this;
    let { isOpen, configs } = this.props;
    return (
      <React.Fragment>
        <Dialog open={isOpen} onEscapeKeyDown={this.reject}>
          <StyledPaper id='AppConfirmationPopup'>
            <AlertIcon />
            <pre style={{ fontFamily: 'inherit' }}> {configs.msg} </pre>
            {configs.cancelText !== false ? (
              <Button className='btn-rounded' onClick={reject}>
                {configs.cancelText || 'Cancel'}
              </Button>
            ) : (
              ''
            )}
            {configs.okText !== false ? (
              <Button autoFocus className='btn-rounded' color='primary' variant='contained' style={configs.okStyle || {}} onClick={confirm}>
                {configs.okText || 'Ok'}
              </Button>
            ) : (
              ''
            )}
          </StyledPaper>
        </Dialog>
      </React.Fragment>
    );
  }
}
