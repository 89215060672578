import Studio, { DashboardMode } from '../Studio/Studio';
import OIServicesDashboard from '../dashboard.service';

class ViewDashboard extends Studio {
  componentWillMount() {
    const { addFilter, saveDashboardTabs } = this.props;
    let id = parseInt(this.props.match.params.id);
    OIServicesDashboard.GetDashboardsById(id).then((response: any) => {
      saveDashboardTabs(response.tabs);
      addFilter(response.filters || []);
      let tabs = response.tabs.map(tab => {
        tab.views = tab.views.map(view =>
          Object.assign({}, JSON.parse(view.position), {
            viewCustomizationOptions: view.viewOptionsJSON ? JSON.parse(view.viewOptionsJSON) : {},
          })
        );

        tab.views.sort((a, b) => a._i - b._i);
        tab.views.forEach((v, i) => {
          v.i = i.toString();
        });

        return tab;
      });

      this.setState({ name: response.name, tabs: tabs, mode: DashboardMode.view });
    });
  }
}

export default ViewDashboard;
