import React from 'react';
import { Paper, Modal } from '@material-ui/core';
import QuestionPreviewerByQuestionId from 'src/components/QuestionPreviewerByQuestionId';
import { ResizingElement } from 'components/oiDimension';
import { QuestionViewMode } from 'src/components/QuestionPreviewer/QuestionPreviewer';
import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';

export class PreviewQuestionModal extends React.Component<any> {
  constructor(props) {
    super(props);
  }
  render() {
    const { onCloseModal, viewType, showPreviewModal, questionForPreview } = this.props;
    return (
      <Modal onClose={onCloseModal} aria-labelledby='simple-dialog-title' open={Boolean(showPreviewModal)}>
        <Paper
          className='oi-dimensions-ref'
          style={{
            width: viewType == VIEW_TYPE.KPI ? '25vw' : viewType == VIEW_TYPE.TABLE ? '80vw' : '40vw',
            height: viewType == VIEW_TYPE.KPI ? '25vh' : viewType == VIEW_TYPE.TABLE ? '80vh' : '65vh',
            margin: '0 auto',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 0,
            right: 0,
          }}
        >
          <ResizingElement>
            <QuestionPreviewerByQuestionId questionId={questionForPreview && questionForPreview.id} mode={QuestionViewMode.VIEW} />
          </ResizingElement>
        </Paper>
      </Modal>
    );
  }
}
