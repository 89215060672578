import * as React from 'react';
import { Button, CircularProgress, withStyles, StyleRulesCallback, Theme } from '@material-ui/core';
import classNames from 'classnames';

import 'react-day-picker/lib/style.css';
import { OceanDataType, ColumnValue, ColumnAnalysis } from 'src/shared/models/SqlColumn';
import { isEqualityOperator, isEmptinessOperator, isLastOperator } from 'src/shared/models/OiFilter';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import moment from 'moment';
import apisService from 'src/shared/apis';
import { FilterValueEditor } from './FilterValueEditor';
import { APP_LOCAL_DATE_FORMAT } from 'src/constants';

interface iFilterPresenterProps {
  filterOperator: ComparisonOperator;
  values: any;
  valueType: any;
  inEditMode?: boolean;
  showDone?: boolean;
  classes?: any;
  columnId?: number;
  autoOpenDatePicker?: boolean;
  onValueUpdated: (values: any[]) => any;
  pinDoneButtonDown?: boolean;
  onSave: any;
}
interface IState {
  editMode: boolean;
  isLoadingValues?: boolean;
  isLoadingAnalysis?: boolean;
  columnId?: number;
  columnAnalysis?: ColumnAnalysis;
  columnValues?: ColumnValue[];
}
class FilterValuePresenter extends React.Component<iFilterPresenterProps, IState> {
  didMount = false;
  state: IState = {
    editMode: false,
    isLoadingValues: false,
    isLoadingAnalysis: false,
    columnValues: [],
  };

  componentDidMount() {
    this.didMount = true;
    this.setState({ editMode: Boolean(this.props.inEditMode) });
    const values = this.props.values;
    if (!values || !values[0]) {
      this.setState({ editMode: true });
    }
    let colId = this.props.columnId;
    if (colId) {
      this.getAnalysis(colId);
      this.getColumnValues(colId);
      this.setState({ columnId: colId });
    }
  }

  componentWillReceiveProps(nextProps: iFilterPresenterProps) {
    if (!this.didMount) return;
    if (nextProps.columnId && this.state.columnId != nextProps.columnId) {
      this.setState({ columnId: nextProps.columnId });
      this.getAnalysis(nextProps.columnId);
      this.getColumnValues(nextProps.columnId);
    }
  }

  doSave = () => {
    const { onSave, values } = this.props;
    onSave && onSave(values);
    this.setState({ editMode: false });
  };

  getAnalysis(colId) {
    this.setState({ isLoadingAnalysis: true });
    apisService.getColumnAnalysis(colId).then(
      analysis => {
        this.setState({ columnAnalysis: analysis, isLoadingAnalysis: false });
      },
      () => {
        this.setState({ isLoadingAnalysis: false });
      }
    );
  }

  getColumnValues(colId) {
    this.setState({ isLoadingValues: true });
    apisService.getColumnValues(colId).then(
      values => {
        this.setState({ columnValues: values, isLoadingValues: false });
      },
      () => {
        this.setState({ isLoadingValues: false });
      }
    );
  }

  enableEditMode = e => {
    this.setState({ editMode: true });
    this.getAnalysis(this.state.columnId);
    this.getColumnValues(this.state.columnId);
  };

  render() {
    const { filterOperator, values, valueType, classes } = this.props;
    const { isLoadingAnalysis, isLoadingValues, columnAnalysis, columnValues } = this.state;
    const _values = (values || []).slice();
    const isDateType = this.props.valueType == OceanDataType.DATE;
    const showLoadingSpinner = !isEmptinessOperator(filterOperator) && (isLoadingAnalysis || isLoadingValues);

    if (isDateType && !isEqualityOperator(filterOperator) && Boolean(columnAnalysis)) {
      let minDate: any = undefined;
      if (columnAnalysis && columnAnalysis.minDate) {
        minDate = moment(columnAnalysis.minDate)
          .add('d', 1)
          .format(APP_LOCAL_DATE_FORMAT);
      }
      if (!isLastOperator(filterOperator)) {
        values[0] = values[0] || minDate;
      }
    }

    if (isEqualityOperator(filterOperator) && this.props.valueType == OceanDataType.BOOLEAN) {
      _values[0] = _values[0] === '1' || _values[0] === true ? 'True' : 'False';
    }

    return (
      <React.Fragment>
        {showLoadingSpinner ? (
          <React.Fragment>
            <CircularProgress /> Loading...
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        <FilterValueEditor
          values={values}
          columnAnalysis={columnAnalysis}
          columnValues={columnValues}
          operator={filterOperator}
          autoOpenDatePicker={this.props.autoOpenDatePicker}
          valueType={valueType}
          onChange={this.props.onValueUpdated}
          onSave={this.doSave}
        />
        <p />

        {this.props.showDone && (
          <Button
            color='secondary'
            variant='outlined'
            onClick={this.doSave}
            className={classNames({ [classes.pinDoneButtonDown]: this.props.pinDoneButtonDown }, classes.doneButton)}
            disabled={!isEmptinessOperator(filterOperator) && values == ''}
          >
            Done
          </Button>
        )}
      </React.Fragment>
    );
  }
}

const styles: StyleRulesCallback = (theme: Theme) => ({
  intervalSelect: {
    width: '50%',
  },
  doneButton: {
    margin: '0 auto',
    marginRight: 0,
    float: 'right',
  },
  pinDoneButtonDown: {
    position: 'absolute',
    top: 220,
    right: 0,
  },
});
const styledFilterValuePresenter = withStyles(styles)(FilterValuePresenter);
export default styledFilterValuePresenter;
