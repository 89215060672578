import { createMuiTheme } from '@material-ui/core/styles';

export const Colors = {
  brandDarkGrey: '#454c53',
  brandMedGrey: '#666b6f',
  brandLightGrey: '#e9ebee',
  brandGreyBorders: '#cdcdcd',
  brandLightBlue: '#e3f5ff',
  brandBlue: '#048edd',
  brandGreen: '#10ab5b',
  brandOrange: '#ffa465',
  brandYellow: '#ffa700',
  brandRed: '#d44b00',
  groupingRed: 'rgb(212, 75, 0)',
  white: '#FFF',
};
export const $primaryShadow = '1px 1px 6.86px 0.14px rgba(0, 0, 0, 0.16)';

export const $btnShadow = '0.5px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.16)';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#048edd',
    },
    secondary: {
      main: '#10ab5b',
    },
  },
  typography: {
    fontSize: 14,
    useNextVariants: true,
  },
});

export default theme;
