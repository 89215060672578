import { IRootState } from '../rootReducer';
import { isSystemAdmin } from 'src/services/permissions/utils';
import { REDUCER_NAME } from './constant';

export const getAccountState = (state: IRootState) => state[REDUCER_NAME];
export const getAccount = state => getAccountState(state);
export const getPermissions = state => getAccountState(state).data.permissions || [];
export const getRoles = state => getAccountState(state).data.roles || [];
export const hasPermissions = state => p => getAccountState(state).data.permissions.indexOf(p) > -1;

export default { getAccountState, getAccount, getPermissions, getRoles, hasPermissions };
