import * as React from 'react';

import ExpandMore from '@material-ui/icons/ExpandMore';
import { FormControlLabel, Radio, RadioGroup, FormGroup, Checkbox } from '@material-ui/core';
import { QueryGroupViewItem } from 'src/modules/questions/studio/models';
import { SORT_DIRECTION } from 'src/shared/models';

import AddOiGroupWizard from 'src/modules/questions/studio/SideToolBar/AddOiGroupWizard/AddOiGroupWizard';
import { SQLColumn } from 'src/shared/models/SqlColumn';
import OiIconButton from 'src/components/OiIconButton';
import toastr from 'components/toastr';
import { getGroupingOperatorName } from 'src/shared/util/OiFunctionUtils';
import Styled from './Styled';
import { IProps } from './models';
import HeadingItem from './HeadingItem';
import { OiFunction } from 'src/shared/models/OiFunction';

export class QuestionGroupingItem extends React.Component<IProps, any> {
  state = {
    expanded: false,
  };

  componentDidMount() {}

  handleLabelChanged = value => {
    let model = Object.assign({}, this.props.item);
    model.displayName = value;
    this.triggerChange(model);
  };

  onChangeColumnDone = groupItem => {
    this.triggerChange({ ...groupItem });
  };

  handleSortRadioChanged = e => {
    this.triggerChange({ orderDirection: e.target.value } as QueryGroupViewItem);
  };

  onPinChanged = () => {
    if (this.props.item.grouped) {
      toastr.warn("Can't pin grouped column.");
    } else {
      this.triggerChange({ pin: !this.props.item.pin } as QueryGroupViewItem);
    }
  };

  triggerChange = (changes: QueryGroupViewItem) => {
    const { onUpdate } = this.props;
    const result = Object.assign({}, this.props.item, changes);
    onUpdate && onUpdate(result as QueryGroupViewItem);
  };

  toggleExpansion = e => {
    this.setState({ expanded: !this.state.expanded });
  };

  onToggleGroupByColumn = () => {
    if (this.props.item.pin) {
      toastr.warn("Can't group pinned column.");
    } else {
      this.triggerChange({ grouped: !this.props.item.grouped } as QueryGroupViewItem);
    }
  };

  handleCheckboxChange = name => event => {
    const { pin, grouped } = this.props.item;
    if (event.target.checked) {
      if (pin && name == 'grouped') {
        toastr.warn("Can't pin grouped column.");
        return false;
      } else if (grouped && name == 'pin') {
        toastr.warn("Can't group pinned column.");
        return false;
      }
    }
    this.triggerChange({ [name]: event.target.checked } as QueryGroupViewItem);
  };

  render() {
    const { expanded } = this.state;
    const { item, onDelete, tables, classes } = this.props;
    const model = item;
    let groupingOpName = getGroupingOperatorName(model.groupingOperator);
    groupingOpName = groupingOpName ? ` (${groupingOpName})` : '';
    const displayName = model.column.displayName + groupingOpName;

    return (
      <div className={classes.Wrapper}>
        <HeadingItem
          toggleExpansion={this.toggleExpansion}
          onPinChanged={this.onPinChanged}
          onToggleGroupByColumn={this.onToggleGroupByColumn}
          handleLabelChanged={this.handleLabelChanged}
          expanded={expanded}
          onDelete={onDelete}
          HeadingItem={classes.HeadingItem}
          model={model}
        />
        {expanded && (
          <div className={classes.EditorWrapper}>
            <div>
              <div className={classes.NormalizedLabel}> Group By </div>
              <AddOiGroupWizard
                tables={tables || []}
                onDone={this.onChangeColumnDone}
                buttonComponent={
                  <div className={classes.WizardButtonWrap}>
                    {displayName || 'select Field'}
                    <OiIconButton>
                      <ExpandMore />
                    </OiIconButton>
                  </div>
                }
              />
            </div>
            <RadioGroup
              aria-label='sort'
              name='sort'
              className={classes.radioGroup}
              value={model.orderDirection}
              onChange={this.handleSortRadioChanged}
            >
              <FormControlLabel value={SORT_DIRECTION.NONE} control={<Radio color={'primary'} />} label='No sort' />
              <FormControlLabel value={SORT_DIRECTION.ASC} control={<Radio color={'primary'} />} label='Asc' />
              <FormControlLabel value={SORT_DIRECTION.DESC} control={<Radio color={'primary'} />} label='Desc' />
            </RadioGroup>
          </div>
        )}
      </div>
    );
  }
}
export default Styled(QuestionGroupingItem);
