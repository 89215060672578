import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import OiIconButton from 'src/components/OiIconButton';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
// import { firstRowOnPage, lastRowOnPage } from '../../../../core';
import { calculateStartPage } from '../../../../core';
import { Tooltip, CircularProgress } from '@material-ui/core';
import OiIcon from 'src/assets/icons';

const styles = theme => ({
  pagination: {
    float: 'right',
    margin: 0,
    flex: '1 0 auto',
    textAlign: 'center',
  },
  rowsLabel: {
    ...theme.typography.caption,
    paddingRight: theme.spacing.unit * 5,
    lineHeight: `${theme.spacing.unit * 5}px`,
  },
  button: {
    minWidth: theme.spacing.unit * 2,
    height: theme.spacing.unit * 5,
  },
  activeButton: {
    fontWeight: 'bold',
    cursor: 'default',
  },
  arrowButton: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4,
    display: 'inline-block',
    verticalAlign: 'middle',
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : null,
  },
  prev: {
    marginRight: 0,
  },
  next: {
    marginLeft: 0,
  },
  '@media(max-width: 768px)': {
    button: {
      display: 'none',
    },
    prev: {
      marginRight: theme.spacing.unit,
    },
    next: {
      marginLeft: theme.spacing.unit,
    },
    rowsLabel: {
      paddingRight: theme.spacing.unit * 2,
    },
  },
  doubleRightArrowStyle: {
    width: 16,
    height: 16,
    lineHeight: 'normal',
    textIndent: 0,
    textAlign: 'start',
    textDecorationLine: 'none',
    textDecorationStyle: 'solid',
    textDecorationColor: '#000',
    textTransform: 'none',
    fontWeight: 400,
    color: 'inherit',
    fill: 'currentColor',
    blockProgression: 'tb',
    fontFamily: 'sans-serif',
    whiteSpace: 'normal',
    isolation: 'auto',
    mixBlendMode: 'normal',
    overflow: 'visible',
  },
});

const PageButton = ({ text, isActive, isDisabled, classes, onClick }) => {
  const buttonClasses = classNames({
    [classes.button]: true,
    [classes.activeButton]: isActive,
  });

  return (
    <Button className={buttonClasses} disabled={isDisabled} onClick={onClick} disableRipple={isActive}>
      {text}
    </Button>
  );
};
const NextPagesChunkButton = ({ loadNextQueryChunk, classes, isLoadingQueryNextChunk }) => {
  return isLoadingQueryNextChunk ? (
    <CircularProgress color='primary' size={16} />
  ) : (
    <Tooltip title='Load Next Chunk'>
      <OiIconButton
        className={classNames(classes.arrowButton, classes.next)}
        onClick={loadNextQueryChunk}
        disabled={isLoadingQueryNextChunk}
      >
        <OiIcon path={'DoubleRight'} className={classes.doubleRightArrowStyle} viewBox='0 0 30 30' scale={16} />
      </OiIconButton>
    </Tooltip>
  );
};

const RemotePagesInfo = ({ remoteTotalRowsCount, remotePagesCount }) => {
  return remoteTotalRowsCount && remotePagesCount ? (
    <span style={{ marginRight: 0, fontSize: 12, float: 'left', textAlign: 'left' }}>
      <span>
        <b>{remoteTotalRowsCount}</b>
        &nbsp;Rows
      </span>
      <span>
        <br />
        <b>{remotePagesCount}</b>
        &nbsp;Pages
      </span>
    </span>
  ) : null;
};
PageButton.propTypes = {
  text: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

PageButton.defaultProps = {
  onClick: () => {},
  isDisabled: false,
  isActive: false,
};

const ellipsisSymbol = '\u2026';

const renderPageButtons = (currentPage, totalPageCount, classes, onCurrentPageChange) => {
  const pageButtons = [];
  const maxButtonCount = 3;
  let startPage = 1;
  let endPage = totalPageCount || 1;

  if (maxButtonCount < totalPageCount) {
    startPage = calculateStartPage(currentPage + 1, maxButtonCount, totalPageCount);
    endPage = startPage + maxButtonCount - 1;
  }
  if (startPage > 1) {
    pageButtons.push(<PageButton key={1} text={String(1)} classes={classes} onClick={() => onCurrentPageChange(0)} />);

    if (startPage > 2) {
      pageButtons.push(<PageButton key='ellipsisStart' text={ellipsisSymbol} classes={classes} isDisabled />);
    }
  }

  for (let page = startPage; page <= endPage; page += 1) {
    pageButtons.push(
      <PageButton
        key={page}
        text={String(page)}
        isActive={page === currentPage + 1}
        classes={classes}
        onClick={() => onCurrentPageChange(page - 1)}
        isDisabled={startPage === endPage}
      />
    );
  }

  if (endPage < totalPageCount) {
    if (endPage < totalPageCount - 1) {
      pageButtons.push(<PageButton key='ellipsisEnd' text={ellipsisSymbol} classes={classes} isDisabled />);
    }

    pageButtons.push(
      <PageButton
        key={totalPageCount}
        text={String(totalPageCount)}
        classes={classes}
        onClick={() => onCurrentPageChange(totalPageCount - 1)}
      />
    );
  }

  return pageButtons;
};

const PaginationBase = ({
  totalPages,
  totalCount,
  pageSize,
  currentPage,
  onCurrentPageChange,
  getMessage,
  remoteTotalRowsCount,
  isLoadingQueryNextChunk,
  loadNextQueryChunk,
  classes,
}) => {
  // const from = firstRowOnPage(currentPage, pageSize, totalCount);
  // const to = lastRowOnPage(currentPage, pageSize, totalCount);
  const remotePagesCount = Math.ceil(remoteTotalRowsCount / pageSize);
  const showFetchNextChunkButton = totalCount < remoteTotalRowsCount;

  return (
    <div className={classes.pagination}>
      <RemotePagesInfo remoteTotalRowsCount={remoteTotalRowsCount} remotePagesCount={remotePagesCount} />

      <OiIconButton
        className={classNames(classes.arrowButton, classes.prev)}
        disabled={currentPage === 0}
        onClick={() => currentPage > 0 && onCurrentPageChange(currentPage - 1)}
      >
        <ChevronLeft />
      </OiIconButton>
      {renderPageButtons(currentPage, totalPages, classes, onCurrentPageChange)}
      <OiIconButton
        className={classNames(classes.arrowButton, classes.next)}
        disabled={currentPage === totalPages - 1 || totalCount === 0}
        onClick={() => currentPage < totalPages - 1 && onCurrentPageChange(currentPage + 1)}
      >
        <ChevronRight />
      </OiIconButton>

      {showFetchNextChunkButton && (
        <NextPagesChunkButton isLoadingQueryNextChunk={isLoadingQueryNextChunk} loadNextQueryChunk={loadNextQueryChunk} classes={classes} />
      )}
    </div>
  );
};

PaginationBase.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCurrentPageChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  getMessage: PropTypes.func.isRequired,
};

export const Pagination = withStyles(styles)(PaginationBase);
