import { connect } from 'react-redux';
import { saveDashboardTabs, changeSelectedTab, addAllFilter } from 'src/store/dashboards/actions';
import EditDashboard from './EditDashboard';

import { dashboardFiltersByTab, dashboardAllFilters } from 'src/store/dashboards/selectors';

const mapStateToProps = state => ({
  selectedFilters: dashboardFiltersByTab(state),
  dashboardStoreFilters: dashboardAllFilters(state),
});

const mapDispatchToProps = dispatch => {
  return {
    saveDashboardTabs: tabs => dispatch(saveDashboardTabs(tabs)),
    changeSelectedTab: index => dispatch(changeSelectedTab(index)),
    addFilter: filters => dispatch(addAllFilter(filters)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDashboard);
