import { OiFunction } from 'src/shared/models/OiFunction';
import { QuestionStudioStoreState } from './models';

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}
export const simpleFuzzySearchFilter = (keyWord: string = '', field?) => {
  if (!keyWord) return () => true;
  let myRegExp = new RegExp(keyWord.split('').join('.*'), 'ig');
  if (field) {
    return column => column && column[field] && myRegExp.test(column[field]);
  } else {
    return column => column && myRegExp.test(column);
  }
};

export function debounce(func: any, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
