import { IRootState } from '../rootReducer';
import { REDUCER_NAME, AUTH_TOKEN_KEY } from './constant';
import { ErrorMessage, SuccessMessage, Loader } from '../util';

export function getToken() {
  return localStorage.getItem(AUTH_TOKEN_KEY) || sessionStorage.getItem(AUTH_TOKEN_KEY);
}

const AuthenticationState = (state: IRootState) => state[REDUCER_NAME];

export const getIsCheckingAuth = state => AuthenticationState(state).isCheckingAuth;
export const getIsAuthenticated = state => AuthenticationState(state).isAuthenticated;
export const getAuthentication = state => AuthenticationState(state).authentication;

export const getError = (state: IRootState, name: string) => AuthenticationState(state)[ErrorMessage(name)] || '';
export const getSuccess = (state: IRootState, name: string) => AuthenticationState(state)[SuccessMessage(name)] || '';
export const getLoader = (state: IRootState, name: string) => AuthenticationState(state)[Loader(name)] || false;
