import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import { ColumnType } from 'src/shared/models/SqlColumn';
import toastr from '../toastr';
import { QUESTION_DRILL_DOWN_OPERATION } from './models';
import { objToQParams } from 'src/shared/util';

export function hasSingleMeasure(previewResult) {
  return previewResult && previewResult.metadata.length === 1 && previewResult.metadata[0].type == ColumnType.MEASURE;
}
export function IsKpiView(viewTypeOption, previewResult) {
  return viewTypeOption && viewTypeOption.type === VIEW_TYPE.KPI; // || hasSingleMeasure(previewResult));
}
export function IsTableView(viewTypeOption, previewResult) {
  return viewTypeOption && viewTypeOption.type === VIEW_TYPE.TABLE && !IsKpiView(viewTypeOption, previewResult);
}
export function IsChartView(viewTypeOption, previewResult) {
  return !IsKpiView(viewTypeOption, previewResult) && !IsTableView(viewTypeOption, previewResult);
}

export function openColumnDistributionByDatePage(sqlColumn, questionEditorModel) {
  if (!sqlColumn || sqlColumn.id === undefined) {
    return toastr.warn(`Can't get distribution by date function on calculated columns`);
  }
  let dbId = questionEditorModel.database.id;
  let tableId = questionEditorModel.baseTable.id;
  let params = {
    operation: QUESTION_DRILL_DOWN_OPERATION.DISTRIBUTION,
    dbId,
    tableId,
    columnId: sqlColumn.id,
  };
  let url = location.origin + `/questions/new?${objToQParams(params)}`;
  window.open(url, '' + Date());
}

export function openColumnDistribution(sqlColumn, questionEditorModel) {
  if (!sqlColumn || sqlColumn.id === undefined) {
    return toastr.warn(`Can't apply distribution function on calculated columns`);
  }
  let dbId = questionEditorModel.database.id;
  let tableId = questionEditorModel.baseTable.id;
  let params = {
    operation: QUESTION_DRILL_DOWN_OPERATION.DISTRIBUTION,
    dbId,
    tableId,
    columnId: sqlColumn.id,
  };
  let url = location.origin + `/questions/new?${objToQParams(params)}`;
  window.open(url, '' + Date());
}

export function applyUniqueFunctionOnColumn(sqlColumn, questionEditorModel) {
  if (!sqlColumn || sqlColumn.id === undefined) {
    return toastr.warn(`Can't apply unique function on calculated columns`);
  }
  let dbId = questionEditorModel.database.id;
  let tableId = questionEditorModel.baseTable.id;
  let params = {
    operation: QUESTION_DRILL_DOWN_OPERATION.UNIQUE_VALUES,
    dbId,
    tableId,
    columnId: sqlColumn.id,
  };
  let url = location.origin + `/questions/new?${objToQParams(params)}`;
  window.open(url, '' + Date());
}
