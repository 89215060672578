import * as React from 'react';

const AddViewColumnFilledIcon = props => (
  <svg viewBox='0 0 252 252' {...props}>
    <g
      fill='none'
      strokeMiterlimit={10}
      fontFamily='none'
      fontWeight='none'
      fontSize='none'
      textAnchor='none'
      style={{ mixBlendMode: 'normal' }}
    >
      <path d='M0 252V0h252v252z' />
      <path
        d='M80.64 30.24v191.52H20.16c-2.776 0-5.04-2.382-5.04-5.316V35.556c0-2.934 2.264-5.316 5.04-5.316zm80.64 0v126.377c-12.357 11.094-20.16 27.171-20.16 44.983a59.95 59.95 0 0 0 3.475 20.16H90.72V30.24zm70.56 0c2.776 0 5.04 2.382 5.04 5.316v116.997c-9.947-7.187-22.142-11.433-35.28-11.433a59.97 59.97 0 0 0-30.24 8.157V30.24z'
        fill='currentColor'
      />
      <g fill='currentColor'>
        <path d='M231.336 201.6c0 3.024-2.016 5.04-5.04 5.04H206.64v19.656c0 3.024-2.016 5.04-5.04 5.04s-5.04-2.016-5.04-5.04V206.64h-19.656c-3.024 0-5.04-2.016-5.04-5.04s2.016-5.04 5.04-5.04h19.656v-19.656c0-3.024 2.016-5.04 5.04-5.04s5.04 2.016 5.04 5.04v19.656h19.656c3.024 0 5.04 2.016 5.04 5.04zm20.664 0c0 27.72-22.68 50.4-50.4 50.4s-50.4-22.68-50.4-50.4 22.68-50.4 50.4-50.4 50.4 22.68 50.4 50.4zm-10.08 0c0-22.176-18.144-40.32-40.32-40.32-22.176 0-40.32 18.144-40.32 40.32 0 22.176 18.144 40.32 40.32 40.32 22.176 0 40.32-18.144 40.32-40.32z' />
      </g>
    </g>
  </svg>
);

export default AddViewColumnFilledIcon;
