import React from 'react';
import CanDo from 'src/components/Permissions/Can';
import abilities from 'src/services/permissions/abilities/users';
import OiIconButton from 'src/components/OiIconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye';
import OiIcon from 'src/assets/icons';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import EntitiesSharedWithUserControllerModal from 'src/components/EntitiesSharedWithUser/EntitiesSharedWithUserControllerModal';

function UsersGridActionButtons({ onOpenManageUserShareables, onEditClick, onActivationClick, onDeleteClick, userEnabled }) {
  return (
    <React.Fragment>
      <TooltipedIconButton
        tooltip={'Edit user'}
        aria-label='Edit'
        onClick={e => {
          e.stopPropagation();
          onEditClick();
        }}
      >
        <EditIcon />
      </TooltipedIconButton>
      <CanDo action={abilities.delete}>
        <TooltipedIconButton
          tooltip='Delete user'
          aria-label='Delete'
          onClick={e => {
            e.stopPropagation();
            onDeleteClick();
          }}
        >
          <DeleteIcon />
        </TooltipedIconButton>
      </CanDo>
      <CanDo action={abilities.view}>
        <TooltipedIconButton
          tooltip={'Toggle user activatation'}
          aria-label='active'
          onClick={e => {
            e.stopPropagation();
            onActivationClick();
          }}
        >
          <OiIcon path={userEnabled ? 'CheckedUser' : 'UserDenied'} />
        </TooltipedIconButton>
      </CanDo>
      <CanDo action={abilities.edit}>
        <TooltipedIconButton
          tooltip={'Manage User Shared Entities'}
          aria-label='active'
          onClick={e => {
            e.stopPropagation();
            onOpenManageUserShareables();
          }}
        >
          <PanoramaFishEyeIcon />
        </TooltipedIconButton>
      </CanDo>
    </React.Fragment>
  );
}

export default UsersGridActionButtons;
