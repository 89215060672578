import * as React from 'react';
import moment from 'moment';
import {
  TableBody as MuiTbody,
  TableCell as MuiTd,
  Table as MuiTable,
  TableRow as MuiTRow,
  TableHead as MuiThead,
} from '@material-ui/core';

import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { standardDeviation } from 'src/shared/util';
interface IProps {
  columnMeta: ColumnMetadatum;
  columnData: any[];
}
const ColumnSummary = ({ columnMeta, columnData }: IProps) => {
  const colType = columnMeta.oceanDataType;
  let max: any = undefined;
  let min: any = undefined;
  let sum: any = undefined;
  let avg: any = undefined;
  let count: any = columnData.length;
  let uniqueCount = columnData.reduce((uniq, d) => {
    uniq.indexOf(d) === -1 && uniq.push(d);
    return uniq;
  }, []).length;
  let nullCount = columnData.filter(c => c === null).length;
  let stdDeviation: any = undefined;
  const isDefined = s => s !== undefined;
  if (colType == 'NUMBER') {
    let numericalData = columnData.filter(c => !isNaN(c)).map(c => parseFloat(c));
    max = Math.max(...numericalData).toFixed(2);
    min = Math.min(...numericalData).toFixed(2);
    sum = numericalData.reduce((sum, d) => sum + d, 0);
    avg = (sum / count).toFixed(2);
    sum = sum.toFixed(2);
    stdDeviation = standardDeviation(numericalData);
  }
  if (colType == 'DATE') {
    const dateCol = columnData.map(d =>
      moment(d)
        .toDate()
        .getTime()
    );
    max = Math.max(...dateCol);
    max = moment(max).format('MM/DD/YYYY HH:mm a');
    min = Math.min(...dateCol);
    min = moment(min).format('MM/DD/YYYY HH:mm a');
  }
  //// all types
  return (
    <>
      <MuiTable>
        <MuiThead>
          <MuiTRow>
            <MuiTd> Aggregate </MuiTd>
            <MuiTd> Value </MuiTd>
          </MuiTRow>
        </MuiThead>
        <MuiTbody>
          {isDefined(max) && (
            <MuiTRow>
              {' '}
              <MuiTd> Max </MuiTd> <MuiTd> {max} </MuiTd>{' '}
            </MuiTRow>
          )}
          {isDefined(avg) && (
            <MuiTRow>
              {' '}
              <MuiTd> Avg </MuiTd> <MuiTd> {avg} </MuiTd>{' '}
            </MuiTRow>
          )}
          {isDefined(min) && (
            <MuiTRow>
              {' '}
              <MuiTd> Min </MuiTd> <MuiTd> {min} </MuiTd>{' '}
            </MuiTRow>
          )}
          {isDefined(sum) && (
            <MuiTRow>
              {' '}
              <MuiTd> Sum </MuiTd> <MuiTd> {sum} </MuiTd>{' '}
            </MuiTRow>
          )}
          {isDefined(uniqueCount) && (
            <MuiTRow>
              {' '}
              <MuiTd> Unique Count </MuiTd> <MuiTd> {uniqueCount} </MuiTd>{' '}
            </MuiTRow>
          )}
          {isDefined(nullCount) && (
            <MuiTRow>
              {' '}
              <MuiTd> Null Count </MuiTd> <MuiTd> {nullCount} </MuiTd>{' '}
            </MuiTRow>
          )}
          {stdDeviation && isDefined(stdDeviation.deviation) && (
            <MuiTRow>
              {' '}
              <MuiTd> Std deviation </MuiTd> <MuiTd> {stdDeviation.deviation.toFixed(2)} </MuiTd>{' '}
            </MuiTRow>
          )}
          {stdDeviation && isDefined(stdDeviation.variance) && (
            <MuiTRow>
              {' '}
              <MuiTd> Variance </MuiTd> <MuiTd> {stdDeviation.variance.toFixed(2)} </MuiTd>{' '}
            </MuiTRow>
          )}
          {stdDeviation && isDefined(stdDeviation.mean) && (
            <MuiTRow>
              {' '}
              <MuiTd> Mean </MuiTd> <MuiTd> {stdDeviation.mean.toFixed(2)} </MuiTd>{' '}
            </MuiTRow>
          )}
        </MuiTbody>
      </MuiTable>
    </>
  );
};

export default ColumnSummary;
