import axios from 'axios';
import { ACTION_TYPES } from 'store/questions/questions.reducer';
import apisService from 'src/shared/apis';
import { discoveryUrl } from 'src/shared/apis/baseUrls';

// Actions
export const getFavoriteQuestions = () => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_FAV_QUESTIONS,
    payload: apisService.getFavQuestions(),
  });
  return result;
};

export const addQuestionToFavList = () => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_QUESTIONS,
    payload: apisService.getFavQuestions(),
  });
  return result;
};

export const unFavoriteQuestion = () => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_QUESTIONS,
    payload: apisService.getFavQuestions(),
  });
  return result;
};

export const getQuestion = id => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_QUESTIONS,
    payload: apisService.getQuestion(id),
  });
  return result;
};
export const getAllQuestions = () => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.GET_QUESTIONS,
    payload: apisService.getQuestions(),
  });
  return result;
};
export const deleteQuestion = id => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_QUESTION,
    payload: apisService.deleteQuestion(id),
  });
  //    getAllQuestions();
  return result;
};

// Actions
export const createQuestion = body => async (dispatch, getState) => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_QUESTION,
    payload: axios.post(discoveryUrl + '/questions', body),
  });
  return result;
};
export const getQuestionById = id => ({
  type: ACTION_TYPES.GET_QUESTION_BY_ID,
  payload: id,
});
