import * as React from 'react';

const AddFilter = props => (
  <svg viewBox='0 0 252 252' {...props}>
    <g
      fill='none'
      strokeMiterlimit={10}
      fontFamily='none'
      fontWeight='none'
      fontSize='none'
      textAnchor='none'
      style={{ mixBlendMode: 'normal' }}
    >
      <path d='M0 252V0h252v252z' />
      <path
        d='M231.84 10.08c3.032 0 5.04 2.008 5.04 5.04v15.12c0 1.516-.57 2.46-1.575 3.465l-80.64 85.68c-1.004 1.004-1.95 1.575-3.465 1.575h-50.4c-1.004 0-2.46-.57-3.465-1.575l-80.64-85.68c-1.004-1.004-1.575-1.95-1.575-3.465V15.12c0-3.032 2.008-5.04 5.04-5.04zm-75.6 120.96v17.735c-18.525 10.367-31.12 30.197-31.12 52.825 0 11.23 3.102 21.77 8.49 30.806l-35.33-21.199c-1.516-1.004-2.52-2.559-2.52-4.567v-75.6z'
        fill='currentColor'
      />
      <g fill='currentColor'>
        <path d='M215.336 201.6c0 3.024-2.016 5.04-5.04 5.04H190.64v19.656c0 3.024-2.016 5.04-5.04 5.04s-5.04-2.016-5.04-5.04V206.64h-19.656c-3.024 0-5.04-2.016-5.04-5.04s2.016-5.04 5.04-5.04h19.656v-19.656c0-3.024 2.016-5.04 5.04-5.04s5.04 2.016 5.04 5.04v19.656h19.656c3.024 0 5.04 2.016 5.04 5.04zm20.664 0c0 27.72-22.68 50.4-50.4 50.4s-50.4-22.68-50.4-50.4 22.68-50.4 50.4-50.4 50.4 22.68 50.4 50.4zm-10.08 0c0-22.176-18.144-40.32-40.32-40.32-22.176 0-40.32 18.144-40.32 40.32 0 22.176 18.144 40.32 40.32 40.32 22.176 0 40.32-18.144 40.32-40.32z' />
      </g>
    </g>
  </svg>
);

export default AddFilter;
