import { IRootState } from '../rootReducer';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import { OiFilterDto } from 'src/shared/models/OiFilterDto';

export const dashboardState = (state: IRootState) => state.dashboards;
export const getTabs = (state: IRootState) => dashboardState(state).tabs;
export const getSelectedTab = (state: IRootState) => dashboardState(state).selectedTab;

export const sharedFilter = state => {
  let tabs = getTabs(state);
  let active = getSelectedTab(state);
  let questions = questionState(state);

  let tabsSharedFilters: Array<Array<OiFilterDto>> = (tabs || []).reduce((tabsSharedFilters, tab) => {
    const questionDtos = tab.views.map(v => questions.questionDtos[v.questionId]);
    const result = questionDtos.reduce((acc, dto) => {
      if (typeof dto === 'object') {
        let filters = dto.query.filters.filter(f => typeof f === 'object' && f.dynamic);
        acc[dto.id] = filters;
      }
      return acc;
    }, {});

    let [first, ...others] = Object.values(result);
    let shared = [] as Array<any>;

    if (others.length === 0) {
      shared = (first || []).reduce((shared, filter) => {
        if (filter && !shared.find(sh => sh.dbColumn === filter.dbColumn && sh.dbTable === filter.dbTable)) {
          shared.push(filter);
        }
        return shared;
      }, []);
    } else {
      others.forEach(filters => {
        filters.forEach(el => {
          first.forEach(element => {
            if (element.dbColumn === el.dbColumn && element.dbTable === el.dbTable) {
              if (!shared.find(sh => sh.dbColumn === element.dbColumn && sh.dbTable === element.dbTable)) {
                shared.push(element);
              }
            }
          });
        });
      });
    }
    tabsSharedFilters.push(shared);
    return tabsSharedFilters;
  }, []);
  if (tabsSharedFilters && tabsSharedFilters.length) {
    return tabsSharedFilters[active] || [];
  }
  return [];
};

export const questionState = (state: IRootState) => state.questions;
export const questionDTOs = (state: IRootState): Array<OiQuestionDto> => Object.values(questionState(state).questionDtos);

export const dashboardAllFilters = state => dashboardState(state).filters;

export const dashboardFiltersByTab = state => {
  const active = getSelectedTab(state);
  const filtersByTab = dashboardState(state).filters;
  const filters = filtersByTab.find(f => f.tab === active) && filtersByTab.find(f => f.tab === active)!.filters;

  const shared = sharedFilter(state);

  const checkedFilters = filters && filters.filter(f => shared.findIndex(s => s.dbColumn === f.dbColumn && s.dbTable === f.dbTable) > -1);

  return checkedFilters || [];
};

export default {};
