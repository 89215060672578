import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import React, { useState, useEffect } from 'react';
import { MultiSelectItemsList } from './BackupItemsList';
import toastr from 'src/components/toastr';
import { makeStyles } from '@material-ui/styles';
import { DialogActions, Button, CircularProgress } from '@material-ui/core';
import { SelectableListItem } from 'src/shared/models';

interface IProps {
  isLoadingQuestions: boolean;
  isLoadingDashboards: boolean;
  isSavingForm: boolean;

  questionsList: Array<OiQuestionDto & SelectableListItem>;
  dashboardsList: Array<any & SelectableListItem>;
  onSave: (options: any) => any;
  onCancel: (options: any) => any;
}
export default function BackupForm(props: IProps) {
  const classes = useStyle();
  const [questionsList, setQuestionsList] = useState(props.questionsList || []);
  const [dashboardsList, setDashboardsList] = useState(props.dashboardsList || []);
  const { isSavingForm, isLoadingDashboards, isLoadingQuestions } = props;
  const { onCancel, onSave } = props;
  const isValid = () => [...questionsList, ...dashboardsList].some(q => q.isChecked);
  useEffect(
    () => {
      setQuestionsList(props.questionsList);
    },
    [props.questionsList.length]
  );
  useEffect(
    () => {
      setDashboardsList(props.dashboardsList);
    },
    [props.dashboardsList.length]
  );

  const saveForm = () => {
    const selectedQuestions = questionsList.filter(q => q.isChecked);
    const selectedDashboards = dashboardsList.filter(d => d.isChecked);
    onSave({
      selectedQuestions,
      selectedDashboards,
    });
  };

  const handleSelectAll = (list, update) => {
    let batchList = list.map(d => ({ ...d, isChecked: true }));
    update(batchList);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <Button color='primary' onClick={e => handleSelectAll(dashboardsList, setDashboardsList)}>
            {' '}
            select all
          </Button>
          <MultiSelectItemsList
            title={'Dashboards List'}
            datasource={dashboardsList}
            isLoading={isLoadingDashboards}
            onSelectionUpdate={(dsPatch, item, i) => setDashboardsList(dsPatch)}
            labelOptions={e => e.name}
          />
        </div>
        <div className={classes.block}>
          <Button color='primary' onClick={e => handleSelectAll(questionsList, setQuestionsList)}>
            {' '}
            select all
          </Button>
          <MultiSelectItemsList
            title={'Questions List'}
            datasource={questionsList}
            isLoading={isLoadingQuestions}
            onSelectionUpdate={(dsPatch, item, i) => setQuestionsList(dsPatch)}
            labelOptions={e => e.name}
          />
        </div>
      </div>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={saveForm} color='primary' disabled={isSavingForm || !isValid()}>
          {isSavingForm ? <CircularProgress size={14} /> : null} Save
        </Button>
      </DialogActions>
    </>
  );
}

const useStyle = makeStyles({
  wrapper: {
    display: 'flex',
    width: '100%',
    height: 400,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  block: {
    flex: '1 1 auto',
    overflow: 'auto',
    maxWidth: 450,
    height: 400,
    margin: '0 10px',
    border: '1px solid #e3e3e3',
  },
});
