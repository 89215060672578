import dashboardBgImage from 'src/assets/img/dashboard.png';

import { withStyles, createStyles, WithStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    thumbClass: {
      backgroundImage: `url(${dashboardBgImage})`,
    },
  });

export interface styleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);

export default Styled;
