import React from 'react';
import { TextField } from '@material-ui/core';

export function TextValueFilter({ value, label, onBlur, onKeyDown, onChange }) {
  return (
    <TextField
      fullWidth
      variant='outlined'
      margin='dense'
      value={value}
      placeholder={label}
      type='text'
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={onChange}
      InputLabelProps={{ shrink: true }}
    />
  );
}
