import * as React from 'react';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { $primaryShadow } from 'src/shared/theme';

interface IProps {
  path?: any;
  [k: string]: any;
}
const BreadCrumb = ({ path, pathTitles, classes, ...props }: IProps) => {
  return (
    <Card className={classes.rootCard}>
      <div className={classes.path}>{path || props.children}</div>
      <div className='flex' />
    </Card>
  );
};

export default withStyles({
  rootCard: {
    display: 'flex',
    width: '100%',
    height: 60,
    alignContent: 'center',
    alignItems: 'center',
    padding: '0 20px',
    borderRadius: '0px !important',
    boxShadow: $primaryShadow,
  },
  path: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& .pull-end': {
      alignSelf: 'flex-end',
    },
  },
})(BreadCrumb);
