import { ShareDatabaseFormRowItem } from './models';
import { PostDatabaseAccessList, DatabaseAccessListDto } from 'src/shared/models/DatabaseAccessListDto';

export const createEmptyRow = (vid, row?): ShareDatabaseFormRowItem => {
  return {
    vid,
    tables: [],
    user: undefined,
    ...row,
  };
};

export const mapShareDbFormRowsToPostDbAccessListDto = (rows: ShareDatabaseFormRowItem[]) => {
  const tablesAcc = rows.reduce(
    (acc, row, i) => {
      (row.tables || []).forEach(table => {
        acc[table.id] = acc[table.id] || [];
        acc[table.id].push(row.user!.username);
      });
      return acc;
    },
    {} as { [tableId: number]: string[] }
  );
  const aclList: PostDatabaseAccessList[] = Object.keys(tablesAcc).map(tableId => {
    return {
      tableId: Number(tableId),
      usernames: tablesAcc[tableId],
    } as PostDatabaseAccessList;
  });

  return aclList;
};

export const mapDbAccessListDtoToShareDbFormRows = (aclList: DatabaseAccessListDto, getUserByUsername, getTableByTableId) => {
  const rows: ShareDatabaseFormRowItem[] = [];
  const usersNamesAgg: { [key: string]: number[] } = {};
  aclList.tables.forEach(table => {
    table.usernames.forEach(un => {
      if (usersNamesAgg[un] == undefined) {
        usersNamesAgg[un] = [];
      }
      if (!usersNamesAgg[un].includes(table.tableId)) {
        usersNamesAgg[un].push(table.tableId);
      }
    });
  });
  let vid = 0;
  Object.keys(usersNamesAgg).map(username => {
    rows.push({
      user: getUserByUsername(username),
      tables: usersNamesAgg[username].map(tableId => getTableByTableId(tableId)),
      vid: vid++,
    });
  });
  return rows;
};

export const getArrItemByFieldName = (datasource: any[], fieldName, value) => {
  return (datasource || []).find(item => item[fieldName] == value);
};
