import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';

export function LastDatePeriodFilter(props) {
  const { onBlur, onKeyDown, onChangeValue, onChangePeriod } = props;
  return (
    <div>
      <TextField
        fullWidth
        variant='outlined'
        margin='dense'
        value={props.values[0]}
        placeholder='enter value'
        label='Value'
        type='text'
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        InputLabelProps={{ shrink: true }}
        onChange={onChangeValue}
      />
      <TextField
        fullWidth
        id='period-select'
        label='Period '
        placeholder=' Select a Period'
        name='Period'
        margin='dense'
        variant='outlined'
        value={props.values[1]}
        InputLabelProps={{ shrink: true }}
        onChange={onChangePeriod}
        select
      >
        <MenuItem value={ComparisonOperator.LAST_HOURS}> hours </MenuItem>
        <MenuItem value={ComparisonOperator.LAST_DAYS}> days </MenuItem>
        <MenuItem value={ComparisonOperator.LAST_MONTHS}> months </MenuItem>
        <MenuItem value={ComparisonOperator.LAST_YEARS}> years </MenuItem>
      </TextField>
    </div>
  );
}
