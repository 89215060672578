import React, { useEffect } from 'react';
import { ShareableUser } from 'src/shared/models/ShareableUser';
import { SQLTable } from 'src/shared/models/SqlTable';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import TitledAutoComplete from '../TitledAutoComplete';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import FileCopy from '@material-ui/icons/FileCopy';
import { TableDropdownItem, UserDropdownItem, ShareDatabaseFormRowItem } from './models';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles(theme => ({
  row: {
    display: 'flex',
    width: '100%',
    padding: '5px 20px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  usersCell: {
    flex: '1 1 33%',
    paddingRight: 10,
  },
  tablesCell: {
    flex: '1 1 47%',
    paddingRight: 10,
  },
  actionsCell: {
    flex: '0 0 90px',
  },
  addNewRow: {
    textAlign: 'right',
    margin: 'auto',
    marginRight: 45,
    marginBottom: 20,
  },
}));
interface ShareDatabaseFormProps {
  isLoadingTables: boolean;
  isLoadingUsers: boolean;
  tables: Array<TableDropdownItem>;
  users: Array<UserDropdownItem>;
  rows: Array<ShareDatabaseFormRowItem>;
  // handlers
  onRowTablesChanged: (rowIndex: number, tables: SQLTable[]) => any;
  onRowUserChanged: (rowIndex: number, user: ShareableUser) => any;
  onDeleteRow: (rowIndex: number) => any;
  onDuplicate: (rowIndex: number) => any;
  onAddRow: () => any;
}
const ShareDatabaseForm = (props: ShareDatabaseFormProps) => {
  const { rows = [], users = [], tables = [] } = props;
  const { isLoadingTables, isLoadingUsers } = props;
  const { onRowTablesChanged, onRowUserChanged, onDeleteRow, onAddRow, onDuplicate } = props;
  const showDelete = rows.length > 1;
  const classes = useStyle();
  useEffect(() => {
    if (rows.length == 0) {
      onAddRow();
    }
  }, []);
  return (
    <>
      {rows.map((row, index) => (
        <div key={row.vid} className={classes.row}>
          <div className={classes.usersCell}>
            <TitledAutoComplete
              isLoadingOptions={isLoadingUsers}
              isCreatable={false}
              label={'User'}
              onChange={onRowUserChanged.bind({}, index)}
              value={row.user}
              options={users}
              name='user'
            />
          </div>
          <div className={classes.tablesCell}>
            <TitledAutoComplete
              isLoadingOptions={isLoadingTables}
              isCreatable={false}
              label={'Tables'}
              isMulti={true}
              onChange={onRowTablesChanged.bind({}, index)}
              value={row.tables}
              options={tables}
              name='tables'
            />
          </div>
          <div className={classes.actionsCell}>
            <TooltipedIconButton
              tooltip={'Duplicate'}
              onClick={e => {
                e.stopPropagation();
                onDuplicate(index);
              }}
            >
              <FileCopy />
            </TooltipedIconButton>

            {showDelete && (
              <TooltipedIconButton
                disabled={!showDelete}
                tooltip={'Delete row'}
                onClick={e => {
                  e.stopPropagation();
                  onDeleteRow(index);
                }}
              >
                <Delete />
              </TooltipedIconButton>
            )}
          </div>
        </div>
      ))}
      <div className={classes.addNewRow}>
        {
          <Typography
            variant='body2'
            style={{ cursor: 'pointer', margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            onClick={onAddRow}
          >
            <TooltipedIconButton tooltip={'Add row'}>
              <Add />
            </TooltipedIconButton>
            Add New
          </Typography>
        }
      </div>
    </>
  );
};
export default ShareDatabaseForm;
