import types from './actionTypes';
import { OiColumnsCacheMap } from './helpers';

const initialState = {
  isLoaded: false,
  isLoading: false,
  byNames: {} as OiColumnsCacheMap,
};
export type ColumnsMapState = Readonly<typeof initialState>;

// Reducer
export default (state = initialState, { type, payload, ...action }): any => {
  switch (type) {
    case types.SET_COLUMNS_MAP: {
      return {
        isLoaded: true,
        byNames: payload,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
