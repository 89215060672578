import * as React from 'react';
import { MenuItem, Tooltip, Menu } from '@material-ui/core';

import MoreVert from '@material-ui/icons/MoreVert';
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { standardDeviation, getAnchorElementFromEvent } from 'src/shared/util';
import OiIconButton from 'src/components/OiIconButton';
interface IProps {
  onSelectOption: (e: string) => any;
  mode: string;
  tableHasCreationDateColumn: boolean;
  classes?: any;
}
interface IState {
  anchorEl: any;
  distributionMenuAnchorEl: any;
}

export default class ColumnActionsMenu extends React.Component<IProps, IState> {
  //// all types
  state = {} as IState;
  openMenu = event => {
    this.setState({ anchorEl: getAnchorElementFromEvent(event) });
  };
  closeMenu = e => {
    this.setState({ anchorEl: undefined });
  };

  openDistributionByDateMenu = event => {
    this.setState({ anchorEl: getAnchorElementFromEvent(event) });
  };
  closeDistributionMenu = e => {
    this.setState({ anchorEl: undefined });
  };
  onSelectOption = (option: string) => {
    this.setState(
      {
        anchorEl: undefined,
        distributionMenuAnchorEl: undefined,
      },
      () => {
        this.props.onSelectOption(option);
      }
    );
  };

  render() {
    const { anchorEl, distributionMenuAnchorEl } = this.state;
    const { mode, tableHasCreationDateColumn, classes } = this.props;
    const onSelectOption = this.onSelectOption;
    // openDateDistibutionMenu
    return (
      <>
        <OiIconButton
          // className="oi-table-function"
          className={classNames(classes.tableIconBtn, ' table-content-iconBtn')}
          onClick={this.openMenu}
        >
          <MoreVert />
        </OiIconButton>
        <Menu
          classes={{ paper: 'column-actions-menu' }}
          anchorEl={distributionMenuAnchorEl}
          open={Boolean(distributionMenuAnchorEl)}
          onClose={this.closeDistributionMenu}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          elevation={6}
        >
          <MenuItem onClick={e => onSelectOption('distribution')}> By Value</MenuItem>
          {tableHasCreationDateColumn ? <MenuItem onClick={e => onSelectOption('distribution_by_date')}>By Date</MenuItem> : <></>}
        </Menu>
        <Menu classes={{ paper: 'column-actions-menu' }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.closeMenu}>
          {mode.toLowerCase() !== 'view' ? (
            <MenuItem onClick={e => onSelectOption('statistics')}>
              DB Statistics
              <Tooltip title='opens a modal that shows Table statistics among all data in the database'>
                <OiIconButton>
                  <HelpIcon />
                </OiIconButton>
              </Tooltip>
            </MenuItem>
          ) : (
            <></>
          )}
          <MenuItem onClick={e => onSelectOption('unique')}>
            Unique Values
            <Tooltip title='Opens a new questions -in new tab- which you can execute to get only unique values of this table \n all data not just result set'>
              <OiIconButton>
                <HelpIcon />
              </OiIconButton>
            </Tooltip>
          </MenuItem>
          <MenuItem onClick={e => (tableHasCreationDateColumn ? this.openDistributionByDateMenu(e) : onSelectOption('distribution'))}>
            Distribution
            <Tooltip title='Show distribution of unique values'>
              <OiIconButton>
                <HelpIcon />
              </OiIconButton>
            </Tooltip>
          </MenuItem>
        </Menu>
      </>
    );
  }
}
