import confirmUtils from 'store/confirmationPopup/utils';
import { ConfirmConfigs } from 'store/confirmationPopup';
/**
 * Appends REQUEST asyc action type
 */

export const REQUEST = (actionType: String) => `${actionType}_PENDING`;

/**
 * Appends SUCCESS asyc action type
 */

export const SUCCESS = (actionType: String) => `${actionType}_FULFILLED`;

/**
 * Appends FAILURE asyc action type
 */

export const FAILURE = (actionType: String) => `${actionType}_REJECTED`;

export const ErrorMessage = (name: string) => `${name}_Error`;
export const SuccessMessage = (name: string) => `${name}_Success`;
export const Loader = (name: string) => `${name}_Loader`;

export const createReducer = (initialState: any, reducerObject) => (state = initialState, { type, payload }): any => {
  if (reducerObject[type]) {
    return reducerObject[type](state, payload);
  } else {
    return state;
  }
};

export const createAction = (type, payload?) => ({ type, payload });
export const createDispatchapleAction = (type, payload?) => d => d(createAction({ type, payload }));
export const confirm = (msg: string) => async () => await confirmUtils.confirm({ msg } as ConfirmConfigs);
