import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import AddOiFilterWizard from './AddOiFilterWizard';
import questionStudioSelectors from 'src/store/questionStudio/selectors';
export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;
const mapStateToProps = (state: IRootState) => ({
  aggregateColumns: questionStudioSelectors.aggregateColumns(state),
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOiFilterWizard);
