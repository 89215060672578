import React, { Fragment } from 'react';
import UserHas from './UserHas';

interface IProps {
  action: string[];
  no?: () => any;
  children?: any;
  // [key: string]: any;
}
const CanDo = (props: IProps) => {
  const { action: requiredPermissions, children, no: renderNotPermitted } = props;
  return (
    <UserHas permissions={requiredPermissions} renderNotPermitted={renderNotPermitted}>
      {children}
    </UserHas>
  );
};
export default CanDo;
