import * as React from 'react';
// router
import { NavLink as Link } from 'react-router-dom';
// Styles
import { styles } from '../drawer/drawer.style';
import { withStyles } from '@material-ui/core';
// material components
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import OiIconButton from 'src/components/OiIconButton';
import MenuIcon from '@material-ui/icons/Menu';

export interface DrawerLayoutProps {
  items: Array<any>;
  isOpen: boolean;
}
class DrawerComponent extends React.Component<any> {
  state = {
    isOpen: false,
  };
  handleOpen = () => {
    this.setState({
      isOpen: true,
    });
  };
  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const { classes, items } = this.props;
    const { isOpen } = this.state;
    return (
      <React.Fragment>
        <OiIconButton
          style={{ color: 'white', margin: '0 10px !important' }}
          aria-label='open drawer'
          className={classes.menuIcon}
          onClick={this.handleOpen}
        >
          <MenuIcon style={{ color: 'white' }} />
        </OiIconButton>
        <Drawer
          className='drawer-bar'
          variant='temporary'
          open={isOpen}
          PaperProps={{ elevation: 1, style: { height: '100%' } }}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={this.handleClose}
        >
          <List onClick={this.handleClose} onKeyDown={this.handleClose}>
            {items.map((item, i) => (
              <ListItem
                key={i}
                button
                color='primary'
                component={p => (
                  <Link
                    activeClassName='active'
                    isActive={(e, loc) => loc.pathname.indexOf(item.linkTo) === 0}
                    to={item.linkTo}
                    {...p as any}
                  />
                )}
              >
                {(item.icon && <ListItemIcon>{item.icon}</ListItemIcon>) || <></>}
                <ListItemText primary={item.title} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(DrawerComponent);
