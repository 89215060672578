import axios from 'axios';
import { OiQuestionDto } from '../models/OiQuestionDto';
import { ExecuterResponseDto } from '../models/ExecuterResultResponseDto';
import { OiQuestionQuery } from '../models/OiQuestionQuery';
import { IQuestionQueryPaginator, QUESTION_QUERY_PAGE_SIZE } from '../models';
import { CanDeleteQuestionDto } from '../models/CanDeleteQuestionDto';
import { coreUrl } from './baseUrls';
import { GDAFilter } from '../models/GdaFilterDto';
import { downloadFileResponse } from './oceanAuthApis';
import { GetQuestionListResponseDto } from '../models/GetQuestionListResponseDto';
import { number } from 'prop-types';
import { SharedEntityDto } from 'src/components/EntitiesSharedWithUser/SharedEntitiesList';
import { fixFiltersDtoMissingGlue } from 'src/modules/questions/questions.services';

const defaultDynamicFilter = {
  // viewType: 'TABLE',
  pageNumber: 0,
  pageLength: 50,
  filters: [
    {
      dynamic: false,
    },
  ],
};
const shareAQuestionWithUsers = (questionId, data) => {
  const url = coreUrl + `/questions/${questionId}/share`;
  return axios.post(url, data).then(resp => resp.data);
};

/* Dashboards */
const getDashboards = () => {
  const url = coreUrl + `/dashboards?size=9999`;
  return axios.get(url).then(resp => resp.data);
};
const saveDashboard = body => {
  const url = coreUrl + '/dashboards';
  return axios.post(url, body).then(resp => resp.data);
};
const getDashboard = id => {
  const url = coreUrl + `/dashboards/${id}`;
  return axios.get(url).then(resp => resp.data);
};
const updateDashboard = (id, body) => {
  const url = coreUrl + `/dashboards/${id}`;
  return axios.post(url, body).then(resp => resp.data);
};
const deleteDashboard = id => {
  const url = coreUrl + `/dashboards/${id}`;
  return axios.delete(url).then(resp => ({ id }));
};

const getFavDashboards = () => {
  const url = coreUrl + '/user-dashboard?size=9999';
  return axios.get(url).then(resp => resp.data);
};
const addDashboardToFav = id => {
  const url = coreUrl + '/user-dashboard';
  return axios.post<any>(url, { dashboardId: id, username: 'admin' }).then(resp => resp.data);
};
const removeDashboardFromFav = id => {
  const url = coreUrl + '/user-dashboard/dashboard/' + id;
  return axios.delete(url).then(resp => resp.data);
};
const getQuestions = () => {
  const url = coreUrl + '/questions?size=9999';
  return axios.get(url).then(resp => resp.data);
};
const createQuestion = (model: OiQuestionDto) => {
  const url = coreUrl + '/questions';
  return axios.post<OiQuestionDto>(url, model).then(resp => resp.data);
};
const executeQuestion = (id: number, { dynamicFilters, ...other }: any) => {
  const url = coreUrl + `/executor/${id}/execute`;
  return axios
    .post<ExecuterResponseDto>(url, {
      ...defaultDynamicFilter,
      ...other,
      filters: fixFiltersDtoMissingGlue(dynamicFilters),
    })
    .then(resp => resp.data);
};
const previewQuestion = (dto: OiQuestionQuery, { pageLength, pageNumber }: IQuestionQueryPaginator) => {
  const url = coreUrl + `/executor/preview?pageNumber=${pageNumber || 1}&pageSize=${Math.min(QUESTION_QUERY_PAGE_SIZE, pageLength)}`;
  return axios.post<ExecuterResponseDto>(url, dto).then(resp => {
    const results = resp.data;
    let columnTitles = {};
    results.metadata.forEach(item => {
      const titleField = 'label';
      const title = item[titleField];
      const sameLabelsCount = columnTitles[title] || 0;
      const headerLabel = sameLabelsCount > 0 ? title + ' ' + Number(sameLabelsCount + 1) : title;
      item[titleField] = headerLabel;
      columnTitles[title] = sameLabelsCount + 1;
    });
    return results;
  });
};
const countQueryResult = (dto: OiQuestionQuery) => {
  const url = coreUrl + '/executor/countQueryResult';
  return axios.post<number>(url, dto).then(resp => resp.data);
};
const getQuestion = id => {
  const url = coreUrl + '/questions/' + id;
  return axios.get<OiQuestionDto>(url).then(resp => resp.data);
};
const updateQuestion = (model: OiQuestionDto) => {
  const url = coreUrl + '/questions';
  return axios.put(url, model).then(resp => resp.data);
};
const deleteQuestion = id => {
  const url = coreUrl + '/questions/' + id;
  return axios.delete(url).then(resp => ({ id }));
};
const canDeleteQuestion = id => {
  const url = coreUrl + '/questions/delete/' + id;
  return axios.get<CanDeleteQuestionDto>(url).then(resp => resp.data);
};
const getFavQuestions = () => {
  const url = coreUrl + '/user-question?size=9999';
  return axios.get(url).then(resp => resp.data);
};
const addQuestionToFav = id => {
  const url = coreUrl + '/user-question';
  return axios.post<any>(url, { questionId: id, username: 'admin' }).then(resp => resp.data);
};
const removeQuestionFromFav = id => {
  const url = coreUrl + '/user-question/question/' + id;
  return axios.delete(url).then(resp => resp.data);
};

const shareQuestionsWithUsers = ({ questions, users }) => {
  const url = coreUrl + `/questions/share`;
  return axios.post(url, { questions, users }).then(resp => resp.data);
};

const getGdaFilters = () => {
  const url = coreUrl + `/user-gdas`;
  return axios.get(url).then(resp => resp.data);
};

const addGdaFilter = (dto: GDAFilter) => {
  const url = coreUrl + `/user-gdas`;
  return axios.post(url, dto).then(resp => resp.data);
};
const editGdaFilter = (dto: GDAFilter) => {
  const url = coreUrl + `/user-gdas`;
  return axios.put(url, dto).then(resp => resp.data);
};
const deleteGdaFilter = (id: number) => {
  const url = coreUrl + `/user-gdas/` + id;
  return axios.delete(url).then(resp => resp.data);
};

const exportMetaQuestions = (ids: Array<number>) => {
  const url = coreUrl + `/export-question`;
  return axios.post(url, { questions: ids }, { responseType: 'blob' }).then(resp => {
    downloadFileResponse(resp.data, 'exportedQuestions.zip');
  });
};

const exportMetaDashboards = (ids: Array<number>) => {
  const url = coreUrl + `/export-dashboard`;
  return axios.post(url, { dashboards: ids }, { responseType: 'blob' }).then(resp => {
    resp.data;
    downloadFileResponse(resp.data, 'exportedDashboards.zip');
  });
};

const uploadFileToUrl = (url, file, onProgress) => {
  const data = new FormData();
  data.append('exportedFile', file);

  return axios
    .post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: onProgress,
    })
    .then(resp => resp.data);
};

const importManyMetaQuestionsOrDashboards = (file, onProgress) => {
  const url = coreUrl + `/importmany`;
  return uploadFileToUrl(url, file, onProgress);
};

const updateSystemSettings = settings => {
  const url = coreUrl + `/system-settings `;
  return axios.put(url, settings).then(r => r.data);
};
const getSystemSettings = () => {
  const url = coreUrl + `/system-settings `;
  return axios.get(url).then(r => r.data);
};

const getUserSharedQuestions = userName => {
  const url = coreUrl + `/questions/shared_questions_for_username/` + userName;
  return axios.get<SharedEntityDto[]>(url).then(r => r.data);
};

const getUserSharedDashboards = userName => {
  const url = coreUrl + `/dashboards/shared_dashboards_for_username/` + userName;
  return axios.get<SharedEntityDto[]>(url).then(r => r.data);
};

export const bulkUnshareQuestions = (args: { questionIds: number[]; userNames: string[] }) => {
  const { questionIds: questions, userNames: users } = args;
  const url = coreUrl + `/questions/unshare`;
  return axios.post(url, { questions, users }).then(r => r.data);
};

export const bulkUnshareDashboards = (args: { dashboardsIds: number[]; userNames: string[] }) => {
  const { dashboardsIds: dashboards, userNames: users } = args;
  const url = coreUrl + `/dashboards/unshare`;
  return axios.post(url, { dashboards, users }).then(r => r.data);
};
export default {
  getGdaFilters,
  addGdaFilter,
  editGdaFilter,
  deleteGdaFilter,
  shareAQuestionWithUsers,
  shareQuestionsWithUsers,
  getDashboards,
  saveDashboard,
  getDashboard,
  updateDashboard,
  deleteDashboard,
  getFavDashboards,
  addDashboardToFav,
  removeDashboardFromFav,
  getQuestions,
  createQuestion,
  executeQuestion,
  previewQuestion,
  countQueryResult,
  getQuestion,
  updateQuestion,
  deleteQuestion,
  canDeleteQuestion,
  getFavQuestions,
  addQuestionToFav,
  removeQuestionFromFav,
  exportMetaQuestions,
  exportMetaDashboards,
  importManyMetaQuestionsOrDashboards,
  updateSystemSettings,
  getSystemSettings,
  getUserSharedQuestions,
  getUserSharedDashboards,
  bulkUnshareQuestions,
  bulkUnshareDashboards,
};
