import { StyleRulesCallback, Theme } from '@material-ui/core/styles';

const style: StyleRulesCallback = (theme: Theme) => ({
  container: {
    width: '100%',
    padding: '0px',
    paddingTop: 5,
    marginTop: '-20px',
    // height: '100%',
    height: 'calc(100vh - 330px)',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0',
  },
  itemWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  item: {
    flex: '0 0 33%',
  },

  root: {
    '&$disabled': {
      color: theme.palette.primary.light,
    },
  },
  disabled: {
    // important to override disabled checkbox "classes={{ root: classes.root, disabled: classes.disabled }}"
    color: 'white',
  },
});

export default style;
