import { connect } from 'react-redux';

import { IRootState } from 'store/rootReducer';
import { getFavoriteDashboards, getAllDashboards, deleteDashboard } from 'store/dashboards/actions';
import { getPermissions, getRoles } from 'store/account/selectors';
import { openConfirmationPopup } from 'store/confirmationPopup';
import { shareDashboard } from 'store/dashboards/actions';

import DashboardsTable from './DashboardsTable';

const mapState = (state: IRootState) => {
  const { dashboards } = state;
  return {
    favoriteDashboardsList: dashboards.favoriteDashboards,
    isLoadingFav: dashboards.loadingFav,
    dashboards: dashboards.dashboardsList,
    loading: dashboards.loading,
    userPermissions: getPermissions(state),
    userRoles: getRoles(state),
  };
};

const mapDispatch = dispatch => {
  return {
    getFavoriteDashboards: () => dispatch(getFavoriteDashboards()),
    getAllDashboards: () => dispatch(getAllDashboards()),
    confirm: config => {
      openConfirmationPopup(config)(dispatch);
    },
    deleteDashboard: id => dispatch(deleteDashboard(id)),
    shareDashboard: ({ dashboards, users }) => dispatch(shareDashboard({ dashboards, users })),
  };
};
export type StateProps = ReturnType<typeof mapState>;
export type DispatchProps = ReturnType<typeof mapDispatch>;

const connected = connect(
  mapState,
  mapDispatch
)(DashboardsTable);

const DashboardsTableContainer = connected;

export default DashboardsTableContainer;
