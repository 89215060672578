import { connect } from 'react-redux';
import { IRootState } from 'src/store/rootReducer';
import { getAllDatabases, shareDBTable } from 'src/store/databases/actions';
import { BrowserRouterProps } from 'react-router-dom';
import DataModelStudio from './Data-Model-Studio';

const mapState = ({ databases }: IRootState) => ({
  isLoadingList: databases.loading,
  databasesList: databases.databasesList,
});

const mapDispatch = dispatch => {
  return {
    getAllDatabases: () => dispatch(getAllDatabases()),
    share: ({ usernames, tableIds }) => dispatch(shareDBTable({ usernames, tableIds })),
  };
};

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;

export interface DetailsProps extends BrowserRouterProps, StateProps, DispatchProps {}

export default connect(
  mapState,
  mapDispatch
)(DataModelStudio);
