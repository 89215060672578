import * as React from 'react';

import { withStyles } from '@material-ui/core';
import { ChartCustomizationOptions } from 'src/components/QuestionPreviewer/models';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { PieChartClickEvent } from 'src/shared/models/EchartsClickEvents';
import OiIcon from 'src/assets/icons';
import { getAnchorElementFromEvent } from 'src/shared/util';
import { ChartsComponent } from './ChartsComponent';
import ChartMenu from './ChartMenu';
import SwitchChartGroup from './SwitchChartGroup';

interface IChartsComponentContainerProps {
  type: string;
  data: any;
  meta: any;
  customization: ChartCustomizationOptions;
  setChartRef: (e) => any;
  classes?: any;
  myRef?: (e: { resize: () => any }) => void;
  currentGroup?: ColumnMetadatum;
  onDoSegmentZoom: (direction: 'in' | 'out', e: PieChartClickEvent) => any;
  onDoSegmentDrillDown: (e: IClickedSegment) => any;
  onDoSegmentFilterOut: (e: IClickedSegment) => any;
  onChartGroupChanged: (group: ColumnMetadatum) => any;
}
export type IClickedSegment = { currentGroup?: ColumnMetadatum } & PieChartClickEvent;
interface IState {
  chartContextMenuAnchorEl: any;
  clickedSegment?: IClickedSegment;
}
class ChartsComponentContainer extends React.Component<IChartsComponentContainerProps, IState> {
  state: IState = {
    chartContextMenuAnchorEl: null,
    clickedSegment: undefined,
  };

  onSegmentClicked = (e: PieChartClickEvent) => {
    if (this.props.customization.enableDataZooming) {
      this.setState({
        chartContextMenuAnchorEl: getAnchorElementFromEvent(e.event.event),
        clickedSegment: { currentGroup: this.props.currentGroup, ...e },
      });
    }
  };

  onDoSegmentDrillDown = () => {
    let clickedSegment = { ...this.state.clickedSegment };
    this.setState({ chartContextMenuAnchorEl: undefined, clickedSegment: undefined });
    this.props.onDoSegmentDrillDown(clickedSegment as any);
  };

  onDoSegmentFilterOut = () => {
    let clickedSegment = { ...this.state.clickedSegment };
    this.setState({ chartContextMenuAnchorEl: undefined, clickedSegment: undefined });
    this.props.onDoSegmentFilterOut(clickedSegment as any);
  };

  onDoSegmentZoom = direction => () => {
    let clickedSegment = { ...this.state.clickedSegment };
    this.setState({ chartContextMenuAnchorEl: undefined, clickedSegment: undefined });
    this.props.onDoSegmentZoom(direction, clickedSegment as any);
  };

  render() {
    const clickedSegment = this.state.clickedSegment;
    const customize = this.props.customization || {};
    return (
      <>
        <ChartsComponent {...this.props} onSegmentClicked={this.onSegmentClicked} />
        {!customize.dimensionalGrouping && (
          <SwitchChartGroup
            meta={this.props.meta}
            onChanged={this.props.onChartGroupChanged}
            currentGroup={this.props.currentGroup as any}
          />
        )}
        <ChartMenu
          onClose={_ => this.setState({ chartContextMenuAnchorEl: undefined })}
          open={Boolean(this.state.chartContextMenuAnchorEl)}
          clickedSegment={clickedSegment}
          anchorElement={this.state.chartContextMenuAnchorEl}
          onDoSegmentZoom={this.onDoSegmentZoom}
          onDoSegmentDrillDown={this.onDoSegmentDrillDown}
          onDoSegmentFilterOut={this.onDoSegmentFilterOut}
        />
      </>
    );
  }
}
export default withStyles({
  '.menu': {
    ul: {
      minWidth: '100px !important',
    },
  },

  '.submenu': {
    marginLeft: '90px !important',
    ul: {
      minWidth: '100px !important',
    },
  },
  '.chartWrapper': {
    width: '100%',
    height: '100%',
    background: 'white',
  },
})(ChartsComponentContainer);
