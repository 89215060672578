import * as React from 'react';

interface IProps {
  nobreadcrumb?: boolean;
  [k: string]: any;
}
const ContentPage = ({ nobreadcrumb, ...props }: IProps) => (
  <div
    style={{
      padding: 10,
      display: 'block',
      height: nobreadcrumb ? 'calc(100vh - 125px)' : 'calc(100vh - 185px)',
      width: '100%',
      overflow: 'auto',
    }}
    {...props}
  >
    {props.children}
  </div>
);

export default ContentPage;
