import { OiFunction } from 'src/shared/models/OiFunction';

export interface SQLColumn {
  active: boolean;
  analyses: ColumnAnalysis[];
  analysisSyncSchedule: string;
  createdBy: string;
  dbTableName: string;
  createdDate: string;
  functionType?: OiFunction;
  dbName: string;
  dbType: string;
  deleted: boolean;
  description: string;
  displayName: string;
  filterComponentType: string;
  fkSqlColumnId: number;
  id: number;
  lastAnalysisDate: string;
  lastAnalysisStatus: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  pk: boolean;
  publicField: boolean;
  specialType: SpecialType;
  sqlTableId: number;
  type: ColumnType;
  oceanDataType: OceanDataType;
  valueDisplayFormat: string;
  values: ColumnValue[];
}

export interface ColumnAnalysis {
  active: boolean;
  analysisStatus: string;
  avgTextLength: number;
  avgValue: number;
  createdBy: string;
  createdDate: string;
  distinctCount: number;
  id: number;
  lastModifiedBy: string;
  lastModifiedDate: string;
  maxDate: string;
  maxTextLength: number;
  maxValue: number;
  minDate: string;
  minValue: number;
  nullCount: number;
  sqlColumnId: number;
}

export interface ColumnValue {
  id: number;
  sqlColumnId: number;
  value: string;
}

export enum SpecialType {
  CATEGORY = 'CATEGORY',
  NUMBER = 'NUMBER',
  QUANTITY = 'QUANTITY',
  VALUE = 'VALUE',
  CREATION_TIMESTAMP = 'CREATION_TIMESTAMP',
  STATUS = 'STATUS',
  BIRTHDATE = 'BIRTHDATE',
  REFRENCE = 'REFRENCE',
  DRUG_NAME = 'DRUG_NAME',
  DRUG_CODE = 'DRUG_CODE',
  DIAGNOSIS_CODE = 'DIAGNOSIS_CODE',
  DIAGNOSIS_DESC = 'DIAGNOSIS_DESC',
}

export enum OceanDataType {
  NUMBER = 'NUMBER',
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
}

export enum ColumnType {
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
  COMPUTED = 'COMPUTED',
  LOOKUP = 'LOOKUP',
}
