import * as React from 'react';
import Styled from './FormStyle';
import classNames from 'classnames';

import Button from '@material-ui/core/Button/Button';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import UsernameIcon from '@material-ui/icons/Person';
import PasswordIcon from '@material-ui/icons/Lock';

function Form(props) {
  const { classes, loading, onForgetPassword, onSubmit } = props;

  const [showPassword, setShowPassword] = React.useState(false);
  const [form, setForm] = React.useState({ username: '', password: '' });
  const { password, username } = form;

  const handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    const clone = { ...form, [name]: value };
    setForm(clone);
  };
  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({ username, password });
  };
  return (
    <form className='loginForm' onSubmit={handleSubmit}>
      <div className={classes.titleWrapper}>
        <Typography color={'inherit'} variant='h4'>
          Login to Ocean Insights
        </Typography>
      </div>

      <TextField
        placeholder={'Username'}
        variant={'outlined'}
        fullWidth
        id='username'
        name='username'
        margin='normal'
        value={username}
        onChange={handleChange}
        className={classNames(classes.margin, classes.textField)}
        InputProps={{
          className: classNames(classes.root),
          startAdornment: (
            <InputAdornment position='start'>
              <UsernameIcon className={classes.loginAdornment} />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        id='password'
        placeholder='Password'
        name='password'
        margin='normal'
        fullWidth
        type={showPassword ? 'text' : 'password'}
        variant={'outlined'}
        value={password}
        onChange={handleChange}
        className={classNames(classes.textField)}
        InputProps={{
          className: classNames(classes.root),
          startAdornment: (
            <InputAdornment position='start'>
              <PasswordIcon className={classes.loginAdornment} />
            </InputAdornment>
          ),
        }}
      />

      <Typography align={'left'} onClick={onForgetPassword} className={classes.forgetPassword}>
        Forgot Password
      </Typography>

      <div>
        <Button type='submit' variant='contained' color='primary' size='medium' fullWidth disabled={loading}>
          SIGN IN
        </Button>

        {loading && (
          <div className={classes.marginLeft}>
            <CircularProgress size={35} color='secondary' />
          </div>
        )}
      </div>
    </form>
  );
}
export default Styled(Form);
