import types from './actionTypes';
import apisService from 'src/shared/apis';
import { REQUEST } from 'store/util';

export const getFavoriteDashboards = () => ({
  type: types.GET_FAV_DASHBOARDS,
  payload: apisService.getFavDashboards(),
});

export const addDashboardToFavList = () => async (dispatch, getState) => {
  const result = await dispatch({
    type: types.CREATE_FAV_DASHBOARD,
    payload: apisService.getFavDashboards(),
  });
  return result;
};

export const unFavoriteDashboard = () => async (dispatch, getState) => {
  const result = await dispatch({
    type: types.DELETE_FAV_DASHBOARD,
    payload: apisService.getFavDashboards(),
  });
  return result;
};

export const getAllDashboards = () => ({
  type: types.GET_DASHBOARDS,
  payload: apisService.getDashboards(),
});

export const getDashboard = id => async (dispatch, getState) => {
  const result = await dispatch({
    type: types.GET_DASHBOARDS,
    payload: apisService.getDashboard(id),
  });
  return result;
};

export const deleteDashboard = id => ({
  type: types.DELETE_DASHBOARD,
  payload: apisService.deleteDashboard(id),
});

export const shareDashboard = ({ dashboards, users }) => ({
  type: REQUEST(types.SHARE_DASHBOARD),
  payload: { dashboards, users },
});

export const saveDashboardTabs = tabs => ({
  type: types.DASHBOARDS_TABS,
  payload: { tabs },
});

export const changeSelectedTab = index => ({
  type: types.CHANGE_SELECTED_TAB,
  payload: { index },
});

export const addAllFilter = filters => ({
  type: types.ADD_ALL_FILTERS_DASHBOARD,
  payload: { filters },
});
export const addFilterToDashboard = filter => ({
  type: types.ADD_FILTER_DASHBOARD,
  payload: { filter },
});
export const removeFilterFromDashboard = filter => ({
  type: types.REMOVE_FILTER_DASHBOARD,
  payload: { filter },
});
export const reset = () => ({
  type: types.RESET,
});
