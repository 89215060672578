import * as React from 'react';

import { Toolbar, Card, withStyles } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SimpleTable from 'components/simple-table';
import BreadCrumb from 'layout/Breadcrumb/BreadCrumb';
import ContentPage from 'layout/Breadcrumb/ContentPage';
import { Theme } from 'react-select/lib/types';
import { updateJobActivation, deleteTask } from './services';
import { columnsBuilder } from './columnsBuilder';
import { TableSearchField } from 'src/components/TableSearchField';

export function TasksListComponent(props) {
  const { activation, confirm, loading } = props;
  const [search, setSearch] = React.useState('');
  const onToggleActivation = updateJobActivation.bind({}, activation, confirm);
  const onDeleteTask = deleteTask.bind({}, confirm, props.deleteTask);
  const [columns] = React.useState(() => {
    return columnsBuilder(onToggleActivation, onDeleteTask);
  });
  let allTasks = props.tasks;
  React.useEffect(
    () => {
      props.getTasks({ jobService: 'EXPORTER' });
    },
    [props.activationData, props.deleteResponse]
  );
  if (search) {
    let keyword = search.trim().toLowerCase();
    allTasks = props.tasks.filter(value => (value.displayName || '').toLowerCase().includes(keyword));
  }
  return (
    <>
      <BreadCrumb>
        Home <KeyboardArrowRight /> Tasks
      </BreadCrumb>
      <ContentPage>
        <Card>
          <Toolbar>
            <div className='flex' />
            <TableSearchField search={search} handleSearch={e => setSearch(e.target.value)} />
          </Toolbar>
          <SimpleTable columns={columns} data={allTasks} showLoading={loading} />
        </Card>
      </ContentPage>
    </>
  );
}
const style: any = (theme: Theme) => ({});

export default withStyles(style)(TasksListComponent);
