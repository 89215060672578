import * as React from 'react';
import TextField from '@material-ui/core/TextField/TextField';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Search from '@material-ui/icons/Search';

interface iProps {
  onChange: (e: any) => void;
  value: string;
  placeholder?: string;
  isFullWidth?: boolean;
}
function CustomSearch(props: iProps) {
  const { onChange, value, placeholder, isFullWidth } = props;
  return (
    <TextField
      id='search'
      name='search'
      type='search'
      placeholder={placeholder}
      variant='standard'
      fullWidth={isFullWidth}
      margin='normal'
      className='rounded-outlined-input'
      value={value}
      onChange={e => onChange(e)}
      onBlur={e => {
        const clone = e.currentTarget.closest('.rounded-outlined-input');
        clone && clone.classList.remove('rounded-outlined-input-foucs');
      }}
      onFocus={e => {
        const clone = e.currentTarget.closest('.rounded-outlined-input');
        clone && clone.classList.add('rounded-outlined-input-foucs');
      }}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position='end'>
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
export default CustomSearch;
