import * as React from 'react';
import { withStyles, Theme } from '@material-ui/core';
import ControlButton from 'components/ControlButton/ControlButtonComponent';
import { getTablesByDbId } from 'modules/databases/data-models/data-model.service';

import StorageIcon from '@material-ui/icons/Storage';
import GridTable from '@material-ui/icons/GridOn';

class SideControlButtons extends React.Component<any, any> {
  state = {
    databases: [] as any,
    db: {} as any,
    tables: [],
    table: {} as any,
    isLoadingDatabases: true,
    isLoadingTables: true,
  };

  componentWillUpdate(nextProps) {
    if (this.props !== nextProps && nextProps.databases.length > 0) {
      var database = nextProps.databases.find(el => {
        if (el.id === Number(nextProps.id)) return el;
      });
      this.setState({ databases: nextProps.databases });
      this.selectDatabase(database);
    }
  }

  selectDatabase(db) {
    if (this.state.db && db.id == this.state.db.id) return;

    this.setState({
      db: db,
      isLoadingDB: false,
      isLoadingDatabases: true,
    });
    getTablesByDbId(db.id).then(tables => {
      this.setState({
        tables,
        isLoadingDatabases: false,
        isLoadingTables: false,
      });
      this.selectTable(tables[0]);
    });
  }

  selectTable(table) {
    if (!table) {
      this.props.handleSelectTable({
        table: {},
        foreign: [],
        columns: [],
      });
    } else {
      let foreign: Array<any> = [];

      let _tables = this.state.tables.slice();

      foreign = _tables.map((el: any) => {
        if (el.id !== table.id) {
          const pkColumn = el.columns.filter(column => {
            if (column.pk) return column;
          });
          return { table: el.displayName, columns: pkColumn };
        } else {
          return { table: el.displayName, columns: [] };
        }
      });
      this.setState({
        table: table,
        isLoadingTables: false,
      });

      this.props.handleSelectTable({
        table: table,
        foreign: foreign,
        columns: table.columns,
      });
    }
  }

  render() {
    let { databases, db, tables, table, isLoadingDatabases, isLoadingTables } = this.state;

    return (
      <>
        <div className='side-control-btn'>
          {!isLoadingTables ? (
            <ControlButton
              title='Dataset'
              subTitle={!isLoadingDatabases ? db.displayName : 'Loading...'}
              icon={<StorageIcon />}
              data={!isLoadingDatabases ? databases : []}
              disabled={true}
              HandleOnClick={element => {
                this.selectDatabase(element);
              }}
            />
          ) : (
            <ControlButton title='Dataset' subTitle={'Loading...'} icon={<StorageIcon />} data={[]} />
          )}
        </div>
        <div className='side-control-btn'>
          {!isLoadingTables ? (
            <ControlButton
              title='Table'
              subTitle={table.displayName}
              icon={<GridTable />}
              data={tables}
              HandleOnClick={element => {
                this.selectTable(element);
              }}
            />
          ) : (
            <ControlButton title='Table' subTitle={'Loading...'} icon={<GridTable />} data={[]} />
          )}
        </div>
      </>
    );
  }
}

const style = (theme: Theme) => ({});

export default withStyles(style)(SideControlButtons);
