import * as React from 'react';

import apisService from 'src/shared/apis';
import dashboardAccess from 'src/services/permissions/abilities/dashboard';
import FavouriteBar from 'src/components/favouriteBar';
import Styled, { styleProps } from './styles';
import { DispatchProps, StateProps } from './Container';

interface IProps extends styleProps, StateProps, DispatchProps {
  onPlay: (id: any) => any;
  onDelete: (id: any) => any;
  onEdit: (id: any) => any;
  onUnfavourite: (question: any) => any;
}
interface IState {
  isLoadingFavDashboards: boolean;
}
class DashboardsFavouriteBar extends React.Component<IProps, IState> {
  state: IState = {
    isLoadingFavDashboards: true,
  };

  handlePlay = id => {
    this.props.onPlay(id);
  };
  handleEdit = id => {
    this.props.onEdit(id);
  };
  handleUnfavourite = question => {
    this.props.onUnfavourite(question);
  };

  getThumbClass = favourite => {
    const classes = this.props.classes;
    return classes.thumbClass;
  };

  render() {
    const favDashboards = this.props.favDashboards || [];
    return (
      <FavouriteBar
        favourites={favDashboards}
        access={dashboardAccess}
        thumbClass={this.getThumbClass}
        onPlay={this.handlePlay}
        onEdit={this.handleEdit}
        onUnfavourite={this.handleUnfavourite}
      />
    );
  }
}
const styled = Styled(DashboardsFavouriteBar);
export default styled;
