export const KEYCODES = {
  UP: 38,
  RIGHT: 39,
  LEFT: 37,
  DOWN: 40,
  ENTER: 13,
  DEL: 46,
  ESC: 27,
  BACKSPACE: 8,
  SPACE: 32,
  FORWARD_DELETE: 46,
};
