import axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import types from './actionTypes';
import { REQUEST, SUCCESS, FAILURE } from 'store/util';
import toastr from 'src/components/toastr';
import discoveryApis from 'src/shared/apis/discoveryApis';

export function* doShareDatabase(action) {
  const type = types.SHARE_DB;
  const payload = action.payload;
  try {
    const { result, error } = yield call(discoveryApis.shareDatabase, payload);
    if (result) {
      yield put({ type: SUCCESS(type), payload: result.data });
      toastr.success(`Shared successfully`);
    } else {
      const message = error && error.response && error.response.data.message;
      toastr.error(message);
      yield put({ type: FAILURE(type), payload: { message: message || `${type} error` } });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: FAILURE(type), payload: { message: `${type} error` } });
  } finally {
  }
}

const watcher = [takeEvery(REQUEST(types.SHARE_DB) as any, doShareDatabase)];
export default watcher;
