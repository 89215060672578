import React from 'react';

import moment from 'moment';

import Card from '@material-ui/core/Card';
import Toolbar from '@material-ui/core/Toolbar';
import FavIcon from '@material-ui/icons/Star';
import AddIcon from '@material-ui/icons/Add';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Delete from '@material-ui/icons/Delete';

import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';

import apisService from 'src/shared/apis';
import SimpleTable from 'src/components/simple-table';
import CanDo from 'src/components/Permissions/Can';
import dashboardAccess from 'src/services/permissions/abilities/dashboard';
import { ToastMessages } from 'src/constants';
import toastr from 'src/components/toastr';
import { canDo } from 'src/services/permissions/utils';
import CustomSearch from 'src/components/CustomSearch';

import ShareWithUsersPopup from 'src/components/ShareUsersPopup';
import { DispatchProps, StateProps } from 'src/modules/dashboards/home/table/Container';

import DashboardsFavouriteBarContainer from '../favouriteBar';
import ActionButtons from './ActionButtons';
import TooltipedIconButton from 'src/components/TooltipedIconButton';

interface IProps extends DispatchProps, StateProps {}
interface IState {
  searchField: string;
  showFavList: boolean;
  search: string;
  data: Array<any>;
  selected: Array<any>;
}
class DashboardsTable extends React.Component<IProps, IState> {
  state = {
    showFavList: false,
    searchField: '',
    search: '',
    data: [],
    selected: [],
  } as IState;

  columns = [
    { field: 'name', title: 'Name' },
    {
      field: 'createdDate',
      title: 'Creation Date',
      cellRenderer: (v, row, column) => moment(v, moment.defaultFormat).format('MMM DD, YYYY'),
    },

    { field: 'description', title: 'Description' },
    {
      field: 'tag',
      title: 'Tags',
      cellRenderer: v => v || '-',
    },
    {
      field: 'id',
      title: 'actions',
      cellRenderer: (id, row, column) => {
        return (
          <ActionButtons
            onFavouriteClick={() => this.toggleDashboardFav(row)}
            onEditClick={() => this.openEditDashboard(id)}
            onDeleteClick={() => this.openConfirmDeleteDashboard(id)}
            isFavourite={this.isFavoriteDashboard(row.id)}
          />
        );
      },
    },
  ];

  componentDidCatch(error, info) {
    console.log('error', error, 'info', info);
  }

  openDashboard = id => {
    if (!canDo(this.props.userPermissions, this.props.userRoles, dashboardAccess.view)) {
      toastr.warn(ToastMessages.unAuthorized);
      return;
    }
    (this.props as any).history.push('/dashboards/' + id);
  };

  openConfirmDeleteDashboard = id => {
    const confirm = this.props.confirm;
    confirm({
      msg: `Are you sure you want to delete this dashboard ?`,
      ok: _ => {
        this.props.deleteDashboard(id);
      },
    });
  };

  openEditDashboard = dashboardId => {
    (this.props as any).history.push('/dashboards/edit/' + dashboardId);
  };

  isFavoriteDashboard = id => {
    return !!(this.props.favoriteDashboardsList || []).find(q => q.id == id);
  };

  toggleDashboardFav = dashboard => {
    if (this.isFavoriteDashboard(dashboard.id)) {
      apisService.removeDashboardFromFav(dashboard.id).then(q => this.props.getFavoriteDashboards());
    } else {
      apisService.addDashboardToFav(dashboard.id).then(q => this.props.getFavoriteDashboards());
    }
  };

  componentDidMount() {
    this.onSearchFiledChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.favoriteDashboardsList || !prevProps.favoriteDashboardsList.length) &&
      (this.props.favoriteDashboardsList && this.props.favoriteDashboardsList.length) &&
      !this.state.showFavList
    ) {
      this.setState({ showFavList: true });
    } else if (
      prevProps.favoriteDashboardsList &&
      prevProps.favoriteDashboardsList.length &&
      (!this.props.favoriteDashboardsList || this.props.favoriteDashboardsList.length == 0) &&
      this.state.showFavList
    ) {
      this.setState({ showFavList: false });
    }

    if (this.props && this.props.dashboards !== prevProps.dashboards) {
      this.setState({ data: this.props.dashboards });
      this.updateQuestionsOrdering();
    }
  }

  componentWillMount() {
    this.props.getAllDashboards();
    this.props.getFavoriteDashboards();
  }

  private updateQuestionsOrdering() {
    let dashboardsList = this.props.dashboards.reduce(
      (acc, d) => {
        let dashboard = {
          ...d,
          isFav: this.isFavoriteDashboard(d.id),
        };
        if (dashboard.isFav) {
          acc.fav.push(dashboard);
        } else {
          acc.notFav.push(dashboard);
        }
        return acc;
      },
      { fav: [], notFav: [] }
    );
    this.setState({
      data: [...dashboardsList.fav, ...dashboardsList.notFav],
    });
  }

  onSearchFiledChanged = e => {
    const searchField = e.target.value;
    this.setState({ searchField });
  };

  handleSearch = e => {
    const keyWord = (e.target.value || '').toLowerCase();
    let _data = keyWord
      ? this.props.dashboards.filter(q => (q.name + '**' + q.description + '**' + q.tag).toLowerCase().includes(keyWord))
      : this.props.dashboards.slice();
    this.setState({ search: e.target.value || '', data: _data });
  };

  handleSelection = selected => {
    this.setState({ selected });
  };

  handleDeleteSelected = () => {
    const { selected, data } = this.state;
    const confirm = this.props.confirm;
    const dashboardsNames = selected.map(id => data.find(d => d.id == id).name).join('\n');
    confirm({
      msg: `Are you sure you want to delete these dashboards ?\n\n` + dashboardsNames,
      ok: _ => {
        selected.forEach(element => {
          this.props.deleteDashboard(parseInt(element));
        });
      },
    });
  };

  handleShare = users => {
    const { selected: selectedDashboards } = this.state;
    const { shareDashboard } = this.props;

    const result = selectedDashboards.map(element => parseInt(element));
    shareDashboard({ dashboards: result, users });
  };

  render() {
    let { searchField, data: dashboards, selected } = this.state;
    let { loading } = this.props;
    searchField = searchField.toLowerCase();
    dashboards = !searchField.length ? dashboards : dashboards.filter((d: any) => d.name.toLowerCase().indexOf(searchField) > -1);
    return (
      <>
        <BreadCrumb
          path={
            <>
              Home <KeyboardArrowRight /> Dashboards
              <div className='flex' />{' '}
            </>
          }
        />
        <ContentPage>
          {this.state.showFavList && (
            <DashboardsFavouriteBarContainer
              onPlay={id => this.openDashboard(id)}
              onDelete={id => this.openConfirmDeleteDashboard(id)}
              onEdit={id => this.openEditDashboard(id)}
              onUnfavourite={this.toggleDashboardFav}
            />
          )}
          <Card>
            <Toolbar>
              <CanDo action={dashboardAccess.create}>
                <TooltipedIconButton
                  tooltip={'Add new dashboard'}
                  rounded
                  aria-label='Add'
                  onClick={e => (this.props as any).history.push('/dashboards/new')}
                >
                  <AddIcon />
                </TooltipedIconButton>
              </CanDo>
              <CanDo action={dashboardAccess.delete}>
                <TooltipedIconButton
                  tooltip={'Delete selected dashboards'}
                  disabled={!(selected.length > 0)}
                  rounded
                  aria-label='remove'
                  onClick={this.handleDeleteSelected}
                >
                  <Delete />
                </TooltipedIconButton>
              </CanDo>
              <CanDo action={dashboardAccess.share}>
                <ShareWithUsersPopup isDisabled={!(selected.length > 0)} onDone={this.handleShare} rounded />
              </CanDo>

              <div className='flex' />
              <CustomSearch onChange={this.handleSearch} value={this.state.search} placeholder='Search' />
              <div>
                <TooltipedIconButton
                  tooltip='Toggle Fav list visibility'
                  rounded
                  aria-label='add'
                  disabled={this.props.favoriteDashboardsList.length == 0}
                  style={{
                    margin: 'auto',
                    marginRight: 10,
                    borderColor: this.state.showFavList ? '#ffeb3b' : this.props.favoriteDashboardsList.length == 0 ? '#ccc' : '',
                  }}
                  className='pull-end'
                  onClick={e => this.setState({ showFavList: !this.state.showFavList })}
                >
                  <FavIcon scale='2' style={{ color: this.state.showFavList ? '#ffeb3b' : '' }} />{' '}
                </TooltipedIconButton>
              </div>
            </Toolbar>
            <SimpleTable
              columns={this.columns}
              data={dashboards}
              showLoading={loading}
              onRowClick={data => {
                this.openDashboard(data.row.id);
              }}
              selectable
              onSelect={this.handleSelection}
            />
          </Card>
        </ContentPage>
      </>
    );
  }
}

export default DashboardsTable;
