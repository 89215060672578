import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Pager } from './templates/paging-panel/pager';
import { pageCount, getMessagesFormatter, Template, TemplatePlaceholder, Plugin, TemplateConnector } from '../../core';

const pluginDependencies = [{ name: 'PagingState' }];

const defaultMessages = {
  showAll: 'All',
  rowsPerPage: 'Rows per page:',
  info: ({ from, to, count }) => `${from}${from < to ? `-${to}` : ''} of ${count}`,
};

export class PagingPanelBase extends React.PureComponent {
  render() {
    const {
      containerComponent: Pager,
      pageSizes,
      messages,
      isLoadingQueryNextChunk,
      loadNextQueryChunk,
      remoteTotalRowsCount,
    } = this.props;
    const getMessage = getMessagesFormatter(messages);

    return (
      <Plugin name='PagingPanel' dependencies={pluginDependencies}>
        <Template name='footer'>
          <TemplatePlaceholder />
          <TemplateConnector>
            {({ currentPage, pageSize, totalCount: loadedRowsTotalCount }, { setCurrentPage, setPageSize }) => (
              <Pager
                currentPage={currentPage}
                pageSize={pageSize}
                totalCount={loadedRowsTotalCount}
                totalPages={pageCount(loadedRowsTotalCount, pageSize)}
                pageSizes={pageSizes}
                getMessage={getMessage}
                onCurrentPageChange={setCurrentPage}
                onPageSizeChange={setPageSize}
                remoteTotalRowsCount={remoteTotalRowsCount}
                isLoadingQueryNextChunk={isLoadingQueryNextChunk}
                loadNextQueryChunk={loadNextQueryChunk}
              />
            )}
          </TemplateConnector>
        </Template>
      </Plugin>
    );
  }
}

export class PagingPanel extends React.PureComponent {
  render() {
    const { messages, ...restProps } = this.props;

    return <PagingPanelBase containerComponent={Pager} messages={{ ...defaultMessages, ...messages }} {...restProps} />;
  }
}
PagingPanelBase.propTypes = {
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  containerComponent: PropTypes.func.isRequired,
  messages: PropTypes.object,
};

PagingPanelBase.defaultProps = {
  pageSizes: [],
  messages: {},
};

PagingPanel.Container = Pager;

PagingPanel.propTypes = {
  messages: PropTypes.shape({
    showAll: PropTypes.string,
    rowsPerPage: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }),
};

PagingPanel.defaultProps = {
  messages: {},
};
