import { StyleRulesCallback, Theme, createStyles, withStyles } from '@material-ui/core';

let color = '#048edd';
export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    wrapper: {
      border: `1px solid ${color}`,
      width: '100%',
      margin: '5px 0',
      borderRadius: 5,
      '& .title': {
        flex: '1 1',
        maxWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    headingItem: {
      display: 'flex',
      padding: 5,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    editorWrapper: {
      borderTop: `1px solid ${color}`,
      padding: '10px 15px',
    },
    radioGroup: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    normalizedLabel: {
      marginBottom: 0,
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 13,
    },
    wizardButtonWrap: {
      padding: 0,
      display: 'flex',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    caretDown: {
      margin: 'auto',
      marginRight: 0,
    },
  });
export default withStyles(styles);
