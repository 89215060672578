import * as React from 'react';
// router
import { NavLink as Link } from 'react-router-dom';
// Styles
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import Menu from '@material-ui/core/Menu/Menu';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import { styles } from './header.style';
import classNames from 'classnames';
// material components
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button';
// Icons
import AccountBox from '@material-ui/icons/AccountBox';
import ExitToApp from '@material-ui/icons/ExitToApp';

import LoadingBar from 'react-redux-loading-bar';
import ChangePasswordDialog from './ChangePasswordDialog';
import TemporaryDrawer from './TemporaryDrawer';
import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import toastr from 'src/components/toastr';

export interface HeaderLayoutProps {
  items: Array<any>;
  handleClick: any;
  changePassowordLoader: any;
  handleLogout: any;
  classes?: any;
}
class HeaderComponent extends React.Component<any, any> {
  state = {
    userDropdownMenuAnchor: null,
    isOpenPasswordDialog: false,
    changePassowordLoader: false,
    changePasswordFromReset: false,
    changePasswordError: '',
    // width: 0,
  };
  componentWillUpdate(nextProps) {
    if (this.props.changePassowordLoader !== nextProps.changePassowordLoader) {
      this.setState({ changePassowordLoader: nextProps.changePassowordLoader });
    }
  }
  componentWillMount() {
    this.props.getAccout();
  }
  componentDidMount() {
    // window.addEventListener('resize', this.resizeFn);
  }
  componentWillUnmount() {
    // window.removeEventListener('resize', this.resizeFn);
  }
  resizeFn = (ev: UIEvent) => {
    this.setState({ width: window.innerWidth });
  };
  handleResponsiveMenu = () => {
    this.setState({ drawer: true });
  };
  handlePasswordSave = newPassword => {
    this.props
      .changePassword({ newPassword })
      .then(response => {
        this.setState({ isOpenPasswordDialog: false, changePasswordFromReset: true, changePasswordError: '' });
      })
      .catch(err => {
        this.setState({
          changePasswordError: err.response.data.title,
        });
      });
  };
  render() {
    const {
      isOpenPasswordDialog,
      changePassowordLoader,
      changePasswordFromReset,
      changePasswordError,
      userDropdownMenuAnchor,
    } = this.state;

    const { classes, account, items, width } = this.props;
    let username = (account && account.preferred_username) || '';
    username = username.charAt(0).toUpperCase() + username.substr(1).toLowerCase();
    return (
      <AppBar className={classNames(classes.appBar, 'main-app-bar')}>
        <ChangePasswordDialog
          isOpen={isOpenPasswordDialog}
          resetForm={changePasswordFromReset}
          handleDialogClose={e => {
            this.setState({ isOpenPasswordDialog: false });
          }}
          handlePasswordSave={this.handlePasswordSave}
          errorMsg={changePasswordError}
          loader={changePassowordLoader}
        />

        <Toolbar className='fullHeight'>
          {/* <OiIconButton style={{ color: 'white' }} aria-label="open drawer" className={classes.menuIcon} onClick={this.props.handleClick}>
            <MenuIcon style={{ color: 'white' }} />
          </OiIconButton> */}

          {isWidthDown('sm', width) && <TemporaryDrawer items={items} />}

          <Typography variant='h6' color='inherit' noWrap className={classNames(classes.oicTypography, classes.oiBgLogo)} />

          {isWidthUp('md', width) &&
            items.map((item, i) => (
              <Button
                key={i}
                style={{ margin: '0 2px' }}
                component={props => (
                  <Link
                    to={item.linkTo}
                    {...props as any}
                    isActive={(match, location) => {
                      return location.pathname.indexOf(item.linkTo) === 0;
                    }}
                    activeClassName='active'
                  />
                )}
                variant='text'
                color='inherit'
              >
                {item.icon}
                &nbsp;&nbsp;
                {item.title}
              </Button>
            ))}
          <div className={classes.flex} />
          <React.Fragment>
            <Button color='inherit' variant='text' onClick={e => this.setState({ userDropdownMenuAnchor: e.target })}>
              <AccountBox /> &nbsp;&nbsp; {username}
            </Button>

            <Menu
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onBackdropClick={() => this.setState({ userDropdownMenuAnchor: undefined })}
              open={Boolean(userDropdownMenuAnchor)}
              anchorEl={userDropdownMenuAnchor}
            >
              <MenuItem
                onClick={e => {
                  this.setState({ isOpenPasswordDialog: true });
                }}
              >
                {/* <OiIcon path={'Password'} />&nbsp;&nbsp; */}
                Change Password
              </MenuItem>
              <MenuItem onClick={this.props.handleLogout}>
                <ExitToApp />
                &nbsp;&nbsp;Logout
              </MenuItem>
            </Menu>
          </React.Fragment>
        </Toolbar>
        {/* Main react redux loader */}
        <LoadingBar />
      </AppBar>
    );
  }
}
const styled = withStyles(styles)(HeaderComponent);
export default withWidth()(styled);
