import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import { resetPassword } from 'src/store/authentication/actions';
import PasswordReset from './PasswordReset';
import types from 'src/store/authentication/actionTypes';
import { getLoader, getSuccess } from 'src/store/authentication/selectors';

const mapStateToProps = (state: IRootState) => ({
  loading: getLoader(state, types.RESET_PASSWORD),
  success: getSuccess(state, types.RESET_PASSWORD),
});

const mapDispatchToProps = dispatch => {
  return { resetPassword: body => dispatch(resetPassword(body)) };
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordReset);
