import {
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListSubheader,
  CircularProgress,
} from '@material-ui/core';
import React from 'react';
import { labelExtracter } from 'src/components/TitledAutoComplete/utils';
import { SelectableListItem } from 'src/shared/models';

interface IProps<T> {
  title: string;
  datasource: Array<T & SelectableListItem>;
  isLoading: boolean;
  onSelectionUpdate: (updatedDatasource: any[], item: any, index: number) => any;
  labelOptions: string | string[] | ((item: any) => string);
}
export function MultiSelectItemsList<T>(props: IProps<T>) {
  const { datasource, labelOptions, isLoading } = props;
  const toggleIsSelected = (q, i) => {
    const ds = datasource.slice();
    ds[i].isChecked = !ds[i].isChecked;
    props.onSelectionUpdate(ds, q, i);
  };
  return (
    <List
      subheader={
        (props.title && (
          <ListSubheader component='div' id='nested-list-subheader' style={{ background: 'white', padding: '0px 15px' }}>
            {props.title}
          </ListSubheader>
        )) || <></>
      }
    >
      {isLoading && (
        <ListItem>
          <CircularProgress size={14} />
          <ListItemText primary={`Loading`} />
        </ListItem>
      )}
      {datasource.map((item, i) => {
        const label = labelExtracter(labelOptions, item, i);
        return (
          <ListItem key={i + '_' + label} role={undefined} button onClick={e => toggleIsSelected(item, i)} style={{ padding: '0px 15px' }}>
            <Checkbox checked={Boolean(item.isChecked)} tabIndex={-1} disableRipple style={{ padding: 5 }} />
            <ListItemText primary={label} />
          </ListItem>
        );
      })}
      {datasource.length == 0 && (
        <ListItem role={undefined} dense button>
          <ListItemText primary={`No Items`} />
        </ListItem>
      )}
    </List>
  );
}
