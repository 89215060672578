import { getPermissions, getRoles } from 'src/store/account/selectors';
import { store as appStore } from 'src/constants';
import { USER_ROLES } from 'src/shared/models/USER_ROLES';

export const hasSinglePermission = (userPermissions, reuiredPermission) => {
  return !!(userPermissions && userPermissions.length && userPermissions.indexOf(reuiredPermission) > -1);
};
export const hasPermissions = (userPermissions, requiredPermissions: string[]) => {
  let hasPermission = hasSinglePermission.bind({}, userPermissions);
  return !!(requiredPermissions || []).find(p => hasPermission(p));
};

export const _hasModulePermissions = (userPermissions, moduleName) => {
  return !!userPermissions.find(p => p.indexOf(moduleName) > -1);
};

export const hasModulePermission = (userPermissions, userRoles, moduleName: string) => {
  return shouldOverridePermissions(userRoles) || _hasModulePermissions(userPermissions, moduleName);
};

export const canDo = (userPermissions, userRoles, action) => {
  return shouldOverridePermissions(userRoles) || hasPermissions(userPermissions, action);
};
export const connectedCanDo = action => {
  const state = appStore.getState();
  const permissions = getPermissions(state);
  const roles = getRoles(state);
  return canDo(permissions, roles, action);
};

export const hasRole = (roles, role) => {
  role = role.toLowerCase();
  return !!(roles || []).find(r => r.toLowerCase() == role);
};

export const isAdmin = (roles: string[]) => {
  return hasRole(roles, USER_ROLES.ADMIN);
};
export const isSystemAdmin = (roles: string[]) => {
  return hasRole(roles, USER_ROLES.SYSTEM_ADMIN);
};

export const isSupportAdmin = (roles: string[]) => {
  return hasRole(roles, USER_ROLES.SYSTEM_SUPPORT);
};

export const shouldOverridePermissions = (roles: string[] = []) => {
  let shouldOverride = isSystemAdmin(roles) || isAdmin(roles) || isSupportAdmin(roles);
  return shouldOverride;
};
