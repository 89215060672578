import oceanAuthApis from 'src/shared/apis/oceanAuthApis';

export const SETTINGS_ACTION_TYPES = {
  FETCH: 'FETCH_SETTINGS',
};

export const fetchSettings = () => {
  return {
    type: SETTINGS_ACTION_TYPES.FETCH,
    payload: oceanAuthApis.getSystemSettings(),
  };
};
