import * as React from 'react';

import { TableLayout } from '../../base/table-layout';
import { VirtualTableLayout as VirtualTableLayoutCore } from '../../base/table-layout/virtual-table-layout';

const MINIMAL_COLUMN_WIDTH = 120;

export const VirtualTableLayout = props => (
  <TableLayout layoutComponent={VirtualTableLayoutCore} minColumnWidth={MINIMAL_COLUMN_WIDTH} {...props} />
);
