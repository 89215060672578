import * as React from 'react';
import { debounce } from 'modules/questions/studio/utils';

export class ResizingElement extends React.Component<any, any> {
  elementRef = React.createRef();

  componentDidUpdate() {
    this.debouncedResize();
  }
  componentDidMount() {
    this.debouncedResize();
  }
  debouncedResize: any = debounce(this.resize.bind(this), 50, true);

  resize() {
    let elementRef = this.elementRef.current as HTMLElement;
    if (elementRef) {
      let dimensionsRef = (elementRef as HTMLElement).closest('.oi-dimensions-ref');
      if (dimensionsRef) {
        let dimens = dimensionsRef.getBoundingClientRect();
        elementRef.style.width = '100%';
        elementRef.style.height = dimens.height + (this.props.heightAdjust || 0) + 'px';
        elementRef.style.overflow = 'auto';
      }
    }
  }

  render() {
    return <div ref={this.elementRef as any}>{this.props.children}</div>;
  }
}

export const myHoc = PassedComponent => props => {
  return (
    <ResizingElement>
      <PassedComponent {...props} />
    </ResizingElement>
  );
};
