import React, { useEffect, useState } from 'react';
import discoveryApis from 'src/shared/apis/discoveryApis';
import oceancoreApis from 'src/shared/apis/oceancoreApis';
import { ShareableUser } from 'src/shared/models/ShareableUser';
import toastr from '../toastr';
import EntitiesSharedWithUser, { EntitiesSharedWithUserLoaders } from './EntitiesSharedWithUser';
import { SharedEntityDto } from './SharedEntitiesList';
interface EntitiesSharedWithUserProps {
  user: ShareableUser;
}
const EntitiesSharedWithUserController: React.SFC<EntitiesSharedWithUserProps> = ({ user }) => {
  const [loaders, setLoaders] = useState({} as EntitiesSharedWithUserLoaders);
  const [sharedQuestions, setSharedQuestions] = useState([] as SharedEntityDto[]);
  const [sharedDashboards, setSharedDashboards] = useState([] as SharedEntityDto[]);
  const [sharedTables, setSharedTables] = useState([] as SharedEntityDto[]);

  const setLoader = (field: keyof EntitiesSharedWithUserLoaders, status: boolean) => {
    setLoaders({
      ...loaders,
      [field]: status,
    });
  };

  useEffect(
    () => {
      loadSharedTables();
      loadSharedDashboards();
      loadSharedQuestions();
    },
    [user]
  );

  const onUnshareTables = ids => {
    const closeLoader = toastr.loading('Revoking selected user Tables ');
    discoveryApis
      .unshareDatabase({
        tableIds: ids,
        usernames: [user.username],
      })
      .then(
        () => {
          closeLoader();
          toastr.success('Selected Tables Revoked successfully');
        },
        err => {
          closeLoader();
          toastr.error('Error while trying to unshare selected Tables');
          console.error('Error while trying to unshare selected Tables', err);
        }
      );
  };

  const onUnshareDashboards = ids => {
    const closeLoader = toastr.loading('Revoking user Dashboards ');
    oceancoreApis
      .bulkUnshareDashboards({
        dashboardsIds: ids,
        userNames: [user.username],
      })
      .then(
        () => {
          closeLoader();
          toastr.success(' Dashboards Revoked successfully');
        },
        err => {
          closeLoader();
          toastr.error('Error while trying to unshare Dashboards');
          console.error('Error while trying to unshare Dashboards', err);
        }
      );
  };

  const onUnshareQuestions = ids => {
    const closeLoader = toastr.loading('Revoking  user shared questions ');
    oceancoreApis
      .bulkUnshareQuestions({
        questionIds: ids,
        userNames: [user.username],
      })
      .then(
        () => {
          closeLoader();
          toastr.success(' Questions Revoked successfully');
        },
        err => {
          closeLoader();
          toastr.error('Error while trying to unshare questions');
          console.error('Error while trying to unshare questions', err);
        }
      );
  };

  const loadSharedTables = () => {
    const loaderName = 'tables';
    setLoader(loaderName, true);
    discoveryApis.getUserSharedTables(user.username).then(
      lookupItems => {
        setLoader(loaderName, false);
        setSharedTables(lookupItems);
      },
      err => {
        setLoader(loaderName, false);
        toastr.error('Error loading user shared tables ');
        console.error('Error loading user shared tables ', err);
      }
    );
  };

  const loadSharedDashboards = () => {
    const loaderName = 'dashboards';
    setLoader(loaderName, true);
    oceancoreApis.getUserSharedDashboards(user.username).then(
      lookupItems => {
        setLoader(loaderName, false);
        setSharedDashboards(lookupItems);
      },
      err => {
        setLoader(loaderName, false);
        toastr.error('Error loading user shared dashboards ');
        console.error('Error loading user shared dashboards ', err);
      }
    );
  };

  const loadSharedQuestions = () => {
    const loaderName = 'questions';
    setLoader(loaderName, true);
    oceancoreApis.getUserSharedQuestions(user.username).then(
      lookupItems => {
        setLoader(loaderName, false);
        setSharedQuestions(lookupItems);
      },
      err => {
        setLoader(loaderName, false);
        toastr.error('Error loading user shared questions ');
        console.error('Error loading user shared questions ', err);
      }
    );
  };

  return (
    <EntitiesSharedWithUser
      loaders={loaders}
      dashboards={sharedDashboards}
      questions={sharedQuestions}
      tables={sharedTables}
      onUnshareDashboards={onUnshareDashboards}
      onUnshareQuestions={onUnshareQuestions}
      onUnshareTables={onUnshareTables}
    />
  );
};

export default EntitiesSharedWithUserController;
