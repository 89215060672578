import * as React from 'react';
import moment from 'moment';
import { IntegratedSummary } from './grid/plugins/integrated-summary';
import { DataTypeProvider } from './grid/plugins/data-type-provider';

export const SortingDate = (a, b) => {
  const DATE_FORMAT = 'DD-MMM-Y HH:mm:ss';
  return moment(a, DATE_FORMAT).isBefore(moment(b, DATE_FORMAT)) ? -1 : 1;
};

export const NumericSorting = (a, b) => {
  const priorityA = parseFloat(a);
  const priorityB = parseFloat(b);

  if (isNaN(priorityA)) {
    return 1;
  } else if (isNaN(priorityB)) {
    return -1;
  } else {
    if (priorityA === priorityB) {
      return 0;
    }

    return priorityA < priorityB ? -1 : 1;
  }
};
export const NumberFormatter = ({ value }) => {
  if (isNaN(parseFloat(value))) return '-';
  else {
    let v = parseFloat(value).toLocaleString('en', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${v}`;
  }
};
const DateFormatter = ({ value }) => {
  if (isNaN(parseFloat(value)) || value === '') return '-';
  else {
    // moment(value).format('yyyy-mm-dd HH24:MM:SS');
    return `${moment(value).format('YYYY-MMM-DD HH:mm:ss')}`;
  }
};
const DateDiffFormatter = ({ value }) => {
  if (isNaN(parseFloat(value)) || value === '') return '-';
  else {
    return `${value} seconds`;
  }
};
const SpecialTypeFormatter = ({ data }) => {
  if (data.column.type === 'DATE') {
    moment(data.value).format(data.column.dataPattern);
  } else if (data.column.type === 'NUMBER') {
    if (isNaN(parseFloat(data.value))) return '-';
    else {
      let fractionDigits = 2;
      let noComma = false;
      if (data.column.dataPattern === '000000') {
        return `${data.value}`;
      } else if (data.column.dataPattern === '000000.00') {
        noComma = true;
      } else if (data.column.dataPattern === '00,000') {
        fractionDigits = 0;
      } else if (data.column.dataPattern === '00,000.00') {
      }

      let v = parseFloat(data.value).toLocaleString('en', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      });
      if (noComma) {
        v = `${v}`.replace(/,/g, '');
      }
      return `${v}`;
    }
  } else {
  }
};
export const NumberTypeProvider = props => <DataTypeProvider formatterComponent={NumberFormatter} {...props} />;
export const DateTypeProvider = props => <DataTypeProvider formatterComponent={DateFormatter} {...props} />;
export const DateDiffTypeProvider = props => <DataTypeProvider formatterComponent={DateDiffFormatter} {...props} />;
export const SpecialTypeProvider = props => (
  <DataTypeProvider
    formatterComponent={data => {
      return SpecialTypeFormatter({ data });
    }}
    {...props}
  />
);

export const summaryCalculator = (type, rows, getValue) => {
  if (type === 'null') {
    if (!rows.length) {
      return null;
    }
    return rows.filter(row => getValue(row) === undefined || getValue(row) === null || getValue(row) === '').length;
  } else if (type === 'unique') {
    if (!rows.length) return null;
    return rows.map(row => getValue(row)).filter((value, index, self) => self.indexOf(value) === index).length;
  } else if (type === 'deviation') {
    if (!rows.length) return null;
    const avg = rows.reduce((acc, row) => acc + parseFloat(getValue(row)) || acc, 0) / rows.length;
    const diffs = rows.map(row => (getValue(row) ? parseFloat(getValue(row)) - avg : 0));
    const squareDiffs = diffs.map(diff => diff * diff);
    const avgSquareDiff = squareDiffs.reduce((acc, diff) => acc + diff, 0) / rows.length;

    return Math.sqrt(avgSquareDiff);
  }
  return IntegratedSummary.defaultCalculator(type, rows, getValue);
};
export const messages = {
  null: 'Nulls',
  unique: 'Unique',
  deviation: 'Standard Deviation',
};
