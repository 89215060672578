import { StyleRulesCallback, Theme, createStyles, withStyles } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    popover: {
      height: '350px',
      width: '350px',
      overflow: 'hidden',
    },
    scrollBar: {
      height: '350px !important',
      width: '350px !important',
    },
  });

export default withStyles(styles);
