import { Theme } from '@material-ui/core/styles';
import { measures, StyleRulesCallback } from 'src/layout/main.style';
import { $btnShadow } from 'src/shared/theme';

export const styles: StyleRulesCallback = (theme: Theme) => ({
  actionFooter: {
    textAlign: 'end',
    width: '100%',
    display: 'flex',
  },
  width100: {
    width: '100px !important',
  },
  btnShadow: {
    boxShadow: `${$btnShadow} !important`,
  },
  footer: {
    width: '100%',
    background: '#FFF',
    height: `${measures.footerHeight}px`,
    minHeight: `${measures.footerHeight}px`,
    float: 'left',
    borderTop: '1px solid rgb(219, 219, 219)',
  },
});
