import { connect } from 'react-redux';
import { create, createWithPermission } from 'src/store/users/actions';
import Create from './Create';
import { getSuccess, shouldRouteSelector } from 'src/store/users/selectors';
import types from 'src/store/users/actionTypes';

const mapStateToProps = state => {
  return {
    successMessage: getSuccess(state, types.CREATE),
    shouldRouteBack: shouldRouteSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    create: username => dispatch(create(username)),
    createWithPermission: body => dispatch(createWithPermission(body)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create);
