import React from 'react';
import Button from '@material-ui/core/Button';
import Dropdown from '@material-ui/icons/ArrowDropDown';
import FiltersList from './filtersList';

const AddFiltersButton = props => {
  const [anchorEl, setAnchorEl] = React.useState(undefined);

  const handleOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <React.Fragment>
      <Button
        style={{ padding: '5px 15px', paddingRight: 5, margin: 'auto 10px' }}
        color='secondary'
        className='add-question-btn btn-rounded'
        variant={'contained'}
        onClick={handleOpen}
      >
        Add Filters <Dropdown />
      </Button>
      <FiltersList el={anchorEl} onClose={e => setAnchorEl(undefined)} />
    </React.Fragment>
  );
};

export default AddFiltersButton;
