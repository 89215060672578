import * as React from 'react';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from 'src/shared/theme';
import { IconButton } from '@material-ui/core';
import { IconButtonProps } from '@material-ui/core/IconButton';

const styles = {
  root: {
    margin: '0 2px',
    padding: '4px',
    '&:hover': {
      // color: Colors.white,
      backgroundColor: `${Colors.brandLightGrey}`,
      '& > span': {
        // color: Colors.white
      },
    },
    '&:disabled': {
      border: `2px solid ${Colors.brandLightGrey}`,
    },
  },
  rounded: {
    border: `2px solid ${Colors.brandMedGrey}`,
    color: Colors.brandMedGrey,
  },
};
type IPROPS = { rounded?: boolean; classes: any } & IconButtonProps;
function OiIconButton(props: IPROPS) {
  const { classes, children, className, rounded, ...other } = props;

  return (
    <IconButton className={classNames(classes.root, { [classes.rounded]: rounded }, className)} {...other}>
      {children || 'class names'}
    </IconButton>
  );
}

export default withStyles(styles)(OiIconButton);
