import React from 'react';
import Search from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField/TextField';

const SearchTextField = props => (
  <TextField
    fullWidth
    id='search-field'
    type='search'
    label='Search'
    color='primary'
    margin='dense'
    value={props.search}
    onChange={props.filter}
    InputProps={{
      endAdornment: (
        <InputAdornment position='start'>
          <Search />
        </InputAdornment>
      ),
    }}
  />
);
export default SearchTextField;
