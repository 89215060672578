import * as React from 'react';
import { List, ListItem, Popover } from '@material-ui/core';
import { ILimitSelectProps, ILimitSelectState, numberLike } from './models';
import LimitTextInput from './LimitTextInput';
import { QUESTION_MAX_LIMIT } from 'src/modules/questions/questions.services';

const LimitSelect = (props: ILimitSelectProps) => {
  const [limit, setLimit] = React.useState(props.value);
  const [anchorEl, setAnchorEl] = React.useState(undefined);
  const [isOpen, setIsOpen] = React.useState(false);
  const setLimitValue = (limit: numberLike) => {
    if (limit) {
      limit = Math.max(0, Number(limit));
      limit = Math.min(limit, QUESTION_MAX_LIMIT);
    }
    setLimit(limit);
    props.onValueChanged && props.onValueChanged(limit);
    setIsOpen(false);
  };
  const openPopup = e => {
    setIsOpen(true);
    setAnchorEl(e.target);
  };
  let values: numberLike[] = [50, 100, 1000];
  const onOk = v => {
    setIsOpen(false);
    setLimitValue(v);
    props.onOk && props.onOk(v);
  };
  return (
    <>
      <div onClick={openPopup}>{props.buttonComponent || <>set limit</>}</div>
      <Popover
        open={Boolean(isOpen)}
        anchorEl={anchorEl}
        onBackdropClick={e => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div>
          <div style={{ display: 'flex', padding: '5px 15px' }}>
            <LimitTextInput limit={limit} onChange={setLimit} onOk={onOk} />
          </div>
          <List component='nav'>
            {values.map(v => (
              <ListItem button key={v} onClick={e => onOk(v)}>
                {v}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </>
  );
};
export default LimitSelect;
