import React from 'react';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import CanDo from 'src/components/Permissions/Can';
import questionAccess from 'src/services/permissions/abilities/questions';
import FavIcon from '@material-ui/icons/Star';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

interface IProps {
  question: any;
  id: number | string;
  column?: any;

  onEdit: (...args: any) => any;
  onToggleFavStatus: (...args: any) => any;
  onDelete: (...args: any) => any;
}
export function RowActionsCell(props: IProps) {
  const { question, id, column } = props;
  const { onEdit, onToggleFavStatus, onDelete } = props;
  return (
    <span onClick={e => e.stopPropagation()}>
      <TooltipedIconButton tooltip={(question.isFav ? 'Remove from' : 'Add to') + ' favourite question'} onClick={onToggleFavStatus}>
        <FavIcon scale='2' style={{ color: question.isFav ? '#ffeb3b' : '' }} />{' '}
      </TooltipedIconButton>
      <CanDo action={questionAccess.edit}>
        <TooltipedIconButton tooltip={'Edit question'} onClick={onEdit}>
          <EditIcon />{' '}
        </TooltipedIconButton>
      </CanDo>
      <CanDo action={questionAccess.delete}>
        <TooltipedIconButton tooltip={'Delete question'} onClick={onDelete}>
          <DeleteIcon />
        </TooltipedIconButton>
      </CanDo>
    </span>
  );
}
