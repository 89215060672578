import axios from 'axios';

import { put, takeEvery, call } from 'redux-saga/effects';
import { ACTION_TYPES as types } from './actions';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';
import { SUCCESS, FAILURE } from '../util';

function* onGetAccount(action) {
  const type = types.GET_ACCOUNT;
  try {
    const result = yield oceanAuthApis.getCurrentUserAccount();
    yield put({ type: SUCCESS(type), payload: result });
  } catch (e) {
    yield put({ type: FAILURE(type) });
    console.error('Error in get account saga', e);
  }
}

const sagas = [takeEvery(types.GET_ACCOUNT, onGetAccount)];

export default sagas;
