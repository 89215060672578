import { QueryFilterViewItem } from 'src/modules/questions/studio/models';
import {
  oiQueryFiltersSelectOptions,
  isOperatorCurrentOrLast,
  isLastOperator,
  isCurrentOperator,
  isInListOperator,
} from 'src/shared/models/OiFilter';
import { OiFilterDto } from 'src/shared/models/OiFilterDto';
import { OiColumnsCacheMap, getColumnCacheName } from 'src/store/columnsMap/helpers';
import { OiFunction } from 'src/shared/models/OiFunction';
import { createAggregateFilterColumn, FilterValuesJoinPattern } from 'src/modules/questions/studio/questionStudioServices';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';

export const mapFilters = (filters: OiFilterDto[], columnsMap: OiColumnsCacheMap) => {
  return (filters || []).map(filter => {
    let isAggregate = filter.functionType !== OiFunction.NONE;
    const table = { displayName: filter.dbTable, name: filter.dbTable } as any;

    const getOp = () => {
      if (isOperatorCurrentOrLast(filter.operator)) {
        // last operator
        if (isLastOperator(filter.operator)) return oiQueryFiltersSelectOptions.find(d => d.operator == ComparisonOperator.LAST);
        // current operator
        else if (isCurrentOperator(filter.operator)) return oiQueryFiltersSelectOptions.find(d => d.operator == ComparisonOperator.CURRENT);
      } else {
        return oiQueryFiltersSelectOptions.find(d => d.operator == filter.operator);
      }
    };

    const getValues = () => {
      if (isInListOperator(filter.operator)) {
        return [filter.values];
      } else if (filter.operator == ComparisonOperator.BETWEEN) {
        return filter.values.split(FilterValuesJoinPattern);
      } else if (isOperatorCurrentOrLast(filter.operator)) {
        return [filter.values, filter.operator];
      } else {
        return [filter.values];
      }
    };

    return {
      filterOperator: getOp(),
      values: getValues(),
      dynamic: filter.dynamic,
      zoomingFilter: filter.zoomingFilter,
      functionType: filter.functionType,
      column: isAggregate ? createAggregateFilterColumn(table, filter) : columnsMap[getColumnCacheName(filter.dbTable, filter.dbColumn)],
    } as QueryFilterViewItem;
  });
};
