import { REQUEST, SUCCESS, FAILURE } from 'store/util';

import { TASKS_ACTION_TYPES } from './tasks.action';

const initialState = {
  loading: false,
  data: [],
  errorMessage: String,
};

// Reducer
export default (state = initialState, { type, payload, ...action }): any => {
  switch (type) {
    case REQUEST(TASKS_ACTION_TYPES.GET):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(TASKS_ACTION_TYPES.GET): {
      return {
        ...initialState,
        loading: false,
        errorMessage: payload.message,
      };
    }
    case SUCCESS(TASKS_ACTION_TYPES.GET): {
      return {
        ...state,
        loading: false,
        data: payload.data,
      };
    }

    case REQUEST(TASKS_ACTION_TYPES.DELETE):
      return {
        ...state,
        deleteLoading: true,
      };
    case FAILURE(TASKS_ACTION_TYPES.DELETE): {
      return {
        ...initialState,
        deleteLoading: false,
        deleteErrorMessage: payload.message,
      };
    }
    case SUCCESS(TASKS_ACTION_TYPES.DELETE): {
      return {
        ...state,
        deleteLoading: false,
        deleteResponse: payload.data,
      };
    }

    case REQUEST(TASKS_ACTION_TYPES.ACTIVATION):
      return {
        ...state,
        activationLoading: true,
      };
    case FAILURE(TASKS_ACTION_TYPES.ACTIVATION): {
      return {
        ...initialState,
        activationLoading: false,
        activationErrorMessage: payload.message,
      };
    }
    case SUCCESS(TASKS_ACTION_TYPES.ACTIVATION): {
      return {
        ...state,
        activationLoading: false,
        activationData: payload.data,
      };
    }
    default:
      return state;
  }
};
