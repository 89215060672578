import { StyleRulesCallback, Theme } from '@material-ui/core';
import { lighten, fade, darken } from '@material-ui/core/styles/colorManipulator';

export const getBorder = theme =>
  `1px solid ${
    theme.palette.type === 'light' ? lighten(fade(theme.palette.divider, 1), 0.88) : darken(fade(theme.palette.divider, 1), 0.8)
  }`;

export const styles: StyleRulesCallback = (theme: Theme) => ({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  alignCenter: {
    justifyContent: 'center',
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  cell: {
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    '&:first-child': {
      paddingLeft: theme.spacing.unit * 3,
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  footer: {
    borderBottom: getBorder(theme),
  },
  cellRightAlign: {
    textAlign: 'right',
  },
  cellCenterAlign: {
    textAlign: 'center',
  },
  cellNoWrap: {
    whiteSpace: 'nowrap',
  },
  tableWrapper: {
    '& tfoot': {
      background: '#eee !important',
    },
    '& .table-content-custom': {
      '& .table-content-iconBtn': {
        display: 'none !important',
      },
      '&:hover': {
        '& .table-content-iconBtn': {
          display: 'flex !important',
        },
      },
    },

    '.column-actions-menu': {
      '& ul li': {
        width: 200,
      },
    },

    'table>thead>tr>th': {
      borderBottom: '1px solid #048edd !important',
    },
  },
  headerTitleWrap: {
    display: 'flex',
    flex: '1 1 auto',
    textAlign: 'left',
    maxWidth: 'calc(100% - 60px)', // consedaring icons might be visible
  },

  tableIconBtn: {
    height: '30px',
    width: '30px',
  },
});
