import { DefaultColorsPallette, ColorsSchemasList } from 'src/components/QuestionPreviewer/ColorSchamas';
import { reduceMultiDimensionalToOne } from '../utils';

export const getTooltip = segment => {
  return `${segment.name}:<br/> ${segment.value} ${segment.percent}%`;
};

export const getSeriesLabelOptions = () => {
  return {
    formatter: getTooltip,
    normal: {
      formatter: getTooltip,
      show: false,
      position: 'center',
    },
    emphasis: {
      show: true,
      formatter: segment => {
        return `${segment.name}:\n ${segment.value} ${segment.percent}%`;
      },
      textStyle: {
        fontSize: '16',
      },
    },
  };
};

export const getSeriesOptions = (data, meta, customize, currentGroup) => {
  data = data || [];
  let plainRowsData = (data = data.slice());
  let radius = 70;
  const mm = currentGroup || {
    ...meta[0],
  };

  plainRowsData = reduceMultiDimensionalToOne(meta, mm, plainRowsData);

  return Array(plainRowsData[0].length - 1)
    .fill({})
    .map((s, i) => {
      let space = i * 4;
      let maxRadius = `${radius - space}%`;
      radius = radius - 20 - space;
      let minRadius = `${radius}%`;
      return {
        type: 'pie',
        roseType: Boolean(customize && customize.rose),
        radius: customize && customize.doughnut ? [minRadius, maxRadius] : '50%',
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        label: getSeriesLabelOptions(),
        labelLine: {
          normal: {
            smooth: 0.4,
            length: 10,
            length2: 20,
          },
        },
        animationType: 'scale',
        animationEasing: 'elasticOut',
        animationDelay: function(idx) {
          return Math.random() * 200;
        },
        data:
          customize && customize.doughnut
            ? plainRowsData.map(r => ({ name: r[0], value: r[i + 1] }))
            : plainRowsData.map(r => ({ name: r[0], value: r[1] })),
        name: plainRowsData[0][0] + '-' + meta[i + 1].label,
      };
    });
};

export const getChartOptions = props => {
  if (!props) return;
  const { customize, data, meta } = props;
  let colorIndex = (customize && customize.colorSchemaIndex) || -1;
  return {
    color: colorIndex >= 0 ? ColorsSchemasList[colorIndex] : DefaultColorsPallette,
    legend: {
      show: props.customize ? props.customize.legend : false,
      type: 'scroll',
      orient: 'horizontal',
      x: 'center',
      y: 'top',
    },
    tooltip: {
      trigger: 'item',
      formatter: getTooltip,
    },
    series: getSeriesOptions(data, meta, customize, props.currentGroup),
  };
};
