import * as React from 'react';
// Styles
import { withStyles, Button, Grid } from '@material-ui/core';
import { styles } from './style';
// material components
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import { EventEmitter } from 'events';

export interface footerActionState {
  style: any;
}

export interface footerActionProps {
  style: any;
  resetBtn?: Boolean;
  hideCancel?: Boolean;
  handleSave?: EventEmitter;
  handleReset?: EventEmitter;
  handleCancel?: EventEmitter;
  isSaveDisabled?: Boolean;
}

class ActionFooterComponent extends React.Component<any, footerActionState> {
  render() {
    const { classes, handleCancel, handleSave, handleReset, hideCancel, resetBtn, isSaveDisabled } = this.props;

    return (
      <Toolbar
        style={{
          position: 'fixed',
          bottom: 0,
          zIndex: 1,
          left: 0,
          borderTop: '1px solid rgb(219, 219, 219)',
        }}
        className={classes.footer}
      >
        <Grid container className={classes.actionFooter} spacing={8} justify='flex-end'>
          {!hideCancel && (
            <Grid item className={classes.width100}>
              <Button className='btn-rounded' variant='text' type='submit' fullWidth onClick={handleCancel}>
                Cancel
              </Button>
            </Grid>
          )}

          {resetBtn && (
            <Grid item className={classes.width100}>
              <Button className='btn-rounded' variant='contained' fullWidth color='secondary' onClick={handleReset}>
                Reset
              </Button>
            </Grid>
          )}
          <Grid item className={classes.width100}>
            <Button
              disabled={isSaveDisabled}
              className='btn-rounded'
              variant='contained'
              type='submit'
              fullWidth
              color='primary'
              onClick={handleSave}
            >
              Save
            </Button>
          </Grid>
          <Grid item style={{ display: 'flex' }}>
            {this.props.children}
          </Grid>
        </Grid>
      </Toolbar>
    );
  }
}
export default withStyles(styles)(ActionFooterComponent);
