import * as React from 'react';
import { Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import CronEditor from 'components/CronEditor';

const ColumnStatistics = ({ column, onChange, isRefreshSchedule, onCheckSchedule }) => {
  isRefreshSchedule = isRefreshSchedule || column.analysisSyncSchedule != null ? true : false;

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant='subtitle1' gutterBottom>
            Last Update
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='subtitle1' gutterBottom>
            {new Date(column.lastAnalysisDate).toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle1' gutterBottom>
            Analysis Status
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant='subtitle1'
            gutterBottom
            color={column.lastAnalysisStatus && column.lastAnalysisStatus.toLowerCase() === 'SUCCESS'.toLowerCase() ? 'secondary' : 'error'}
          >
            {column.lastAnalysisStatus}
          </Typography>
        </Grid>
        {(column.analyses && column.analyses[0] && (
          <>
            <Grid item xs={4}>
              <Typography variant='subtitle1' gutterBottom>
                Unique Values
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle1' gutterBottom>
                {column.analyses.length === 0 ? '' : column.analyses[0].distinctCount}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle1' gutterBottom>
                Null Counts
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant='subtitle1' gutterBottom>
                {column.analyses.length === 0 ? '' : column.analyses[0].nullCount}
              </Typography>
            </Grid>
            {column.oceanDataType === 'NUMBER' || column.dbType === 'INT' || column.dbType === 'FLOAT' || column.dbType === 'BIGINT' ? (
              <>
                <Grid item xs={4}>
                  <Typography variant='subtitle1' gutterBottom>
                    Avaliable Values
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='subtitle1' gutterBottom>
                    Between {column.analyses.length === 0 ? '' : column.analyses[0].minValue} and{' '}
                    {column.analyses.length === 0 ? '' : column.analyses[0].maxValue}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='subtitle1' gutterBottom>
                    Average Value is
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='subtitle1' gutterBottom>
                    {column.analyses.length === 0 ? '' : column.analyses[0].avgValue}
                  </Typography>
                </Grid>
              </>
            ) : column.oceanDataType === 'STRING' || column.dbType === 'VARCHAR' ? (
              <>
                <Grid item xs={4}>
                  <Typography variant='subtitle1' gutterBottom>
                    Max Lenght for values
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='subtitle1' gutterBottom>
                    {column.analyses.length === 0 ? '' : column.analyses[0].maxTextLength}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='subtitle1' gutterBottom>
                    Avg Length
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='subtitle1' gutterBottom>
                    {column.analyses.length === 0 ? '' : column.analyses[0].avgTextLength}
                  </Typography>
                </Grid>
              </>
            ) : column.dbType === 'DATETIME' ? (
              <>
                <Grid item xs={4}>
                  <Typography variant='subtitle1' gutterBottom>
                    Max Date
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='subtitle1' gutterBottom>
                    {column.analyses.length === 0 ? '' : new Date(column.analyses[0].maxDate).toLocaleString()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant='subtitle1' gutterBottom>
                    Min Date
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant='subtitle1' gutterBottom>
                    {column.analyses.length === 0 ? '' : new Date(column.analyses[0].minDate).toLocaleString()}
                  </Typography>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </>
        )) || <></>}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                checked={isRefreshSchedule}
                onChange={e => {
                  onCheckSchedule(e.target.checked ? true : false);
                  column.analysisSyncSchedule = null;
                  if (!isRefreshSchedule) onChange(column);
                }}
              />
            }
            label='Refresh Scedule'
          />
        </Grid>
        <Grid item xs={12}>
          {isRefreshSchedule ? (
            <CronEditor
              key={1}
              model={column.analysisSyncSchedule}
              handleChange={e => {
                column.analysisSyncSchedule = e;
                onChange(column);
              }}
            />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ColumnStatistics;
