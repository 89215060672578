import axios from 'axios';

import apisService from 'src/shared/apis';
import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import { coreUrl } from 'src/shared/apis/baseUrls';

const url = coreUrl + '/dashboards';

export default class OIServicesDashboard {
  static GetQuestions() {
    return apisService.getQuestions();
  }
  static SaveDashboard(body) {
    return axios.post(url, body).then(response => response.data);
  }
  static UpdateDashboard(body) {
    return axios.put(url, body).then(response => response.data);
  }
  static GetDashboards() {
    return axios.get(url).then(response => response.data);
  }
  static GetDashboardsById(id) {
    return axios.get(`${url}/${id}`).then(response => response.data);
  }
  static DeleteDashboard(id) {
    return axios.delete(`${url}/${id}`).then(response => response.data);
  }
  static ShareDashboard(id, body) {
    return axios.post(`${url}/${id}/share`, body).then(response => response.data);
  }
}

export const getViewTypeLayoutDimensions = (viewType?: VIEW_TYPE) => {
  switch (viewType) {
    case VIEW_TYPE.KPI:
      return {
        w: 3,
        h: 1,
        minW: 2,
        minH: 1,
      };
    case VIEW_TYPE.TABLE:
      return {
        w: 4,
        h: 3,
        minW: 4,
        minH: 3,
      };
    case VIEW_TYPE.BAR_CHART:
    case VIEW_TYPE.PIE_CHART:
    case VIEW_TYPE.LINE_CHART:
    case VIEW_TYPE.DOUGHNUT_CHART:
    default:
      return {
        w: 4,
        h: 3,
        minW: 4,
        minH: 3,
      };
  }
};
