import apisService from 'src/shared/apis';
import { StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import { DbConnectionCreate } from 'modules/databases/connection-create/DbConnectionCreate';
import toastr from 'src/components/toastr';

export class DbConnectionEdit extends DbConnectionCreate {
  componentWillMount() {
    this.setState({ cardTitle: 'Edit database connection' });
    this.loadConnectionsTemplates();

    let id = parseInt(this.props.match.params.id);

    apisService.getDatabaseById(id).then(
      db => {
        let template: any = this.state.connectionTemplates.filter((connection: any) => connection.dbType === db.dbType)[0];

        this.setState({
          form: Object.assign({}, this.state.form, {
            displayName: db.displayName,
            dbType: db.dbType,
            dbName: db.dbName,
            description: db.description,
            displayType: template.displayType,
            hostServer: db.hostServer,
            port: db.port,
            schema: db.schemaName,
            username: db.username,
            id: id,
          }),
          template,
        });
      },
      err => {
        console.log(err);
        this.setState({ isLoadingConnectionTemplates: false });
      }
    );
  }
  handleSubmit = event => {
    event.preventDefault();

    let data: any = this.state.form;

    if (Object.values(data).some(d => d === '')) {
      this.setState({ errorMsg: 'Please fill all fields' });
      return;
    }

    let template = this.state.template as any;
    data.templateId = template.id;
    let closeLoadingToastr = toastr.loading('Updating database connection');

    apisService
      .updateDatabaseConnection(data)
      .then(response => {
        toastr.success('Connection updated successfully');
        closeLoadingToastr();
        this.props.history.push('/databases');
      })
      .catch(err => {
        toastr.error('Failed to update database connection');
        closeLoadingToastr();
        this.setState({ errorMsg: err.response.statusText });
      });
  };
}

const style: StyleRulesCallback = (theme: Theme) => ({
  textField: {
    width: '300px',
    margin: '15px 15px 15px 0px',
  },
});

export default withStyles(style)(DbConnectionEdit);
