import * as React from 'react';
import { Popover, withStyles } from '@material-ui/core';
import { Colors, $primaryShadow } from 'src/shared/theme';

export interface SimpleSelectItem {
  label: string;
  value: string;
}

const SimpleSelect = ({ handleClose, anchorEl, classes, children, ...props }) => (
  <Popover
    id='simple-popper'
    open={true}
    disableAutoFocus={true}
    disableEnforceFocus={true}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    classes={{
      paper: classes.weirdPaper,
    }}
  >
    <div className={classes.contentWrapper}>{children}</div>
  </Popover>
);

export default withStyles({
  weirdPaper: {
    background: 'transparent !important',
    border: 'none !important',
    outline: 'unset !important',
    padding: '1px',
    boxShadow: $primaryShadow,
  },
  contentWrapper: {
    width: 300,
    borderRadius: 5,
    overflow: 'auto',
    minHeight: 300,
    background: 'white',
  },
})(SimpleSelect);
