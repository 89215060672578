import { connect } from 'react-redux';
import { IRootState } from 'src/store/rootReducer';
import questionStudioActions from 'src/store/questionStudio/questionStudioActions';
import questionStudioSelectors from 'src/store/questionStudio/selectors';
import QuestoinStudioIngredientsPanel from './IngredientsPanel';

const mapStateToProps = (state: IRootState) => {
  const { databases, questionStudio } = state;
  return {
    baseTable: questionStudio.baseTable,
    relatedTables: questionStudioSelectors.relatedTables(state),
    hasRawData: questionStudioSelectors.hasRawData(state),
    availableColumns: questionStudioSelectors.allColumns(state),
    aggregateColumns: questionStudioSelectors.aggregateColumns(state),
    viewsItems: questionStudio.viewsItems,
    advanceFiltersRoot: questionStudio.advanceFilter,
    groupItems: questionStudio.groupItems,
    computedColumns: questionStudio.computedColumns,
    filtersItems: questionStudio.filtersItems,
  };
};

const mapDispatchToProps = {
  onFormModelUpdated: questionStudioActions.onFormModelUpdated,
  updateViewItem: questionStudioActions.updateViewItem,
  updateGroupItem: questionStudioActions.updateGroupItem,
  batchQuestionStudioState: questionStudioActions.batchState,
  resetIngredientsPanelData: questionStudioActions.resetIngredientsPanelData,
  onDeleteCalculatedColumn: questionStudioActions.onDeleteCalculatedColumn,
  onChangeCalculatedColumn: questionStudioActions.onChangeCalculatedColumn,
  updateAdvanceFiltersRoot: questionStudioActions.updateAdvanceFiltersRoot,
  deleteAdvanceFiltersRoot: questionStudioActions.deleteAdvanceFiltersRoot,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

const QuestionFormItemsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestoinStudioIngredientsPanel);
export default QuestionFormItemsContainer;
