import React from 'react';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import OiIcon from 'src/assets/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import taskAccess from 'src/services/permissions/abilities/tasks';
import CanDo from 'src/components/Permissions/Can';

export default function ActionsColumn({ row, id, updateJobActivation, deleteTask }) {
  return (
    <>
      <CanDo action={taskAccess.write}>
        <TooltipedIconButton
          tooltip='Tooltip task'
          disabled={row.active}
          onClick={e => {
            e.stopPropagation();
            updateJobActivation(row);
          }}
        >
          <OiIcon path='Play' />
        </TooltipedIconButton>
      </CanDo>

      <CanDo action={taskAccess.write}>
        <TooltipedIconButton
          tooltip={'Pause task'}
          disabled={!row.active}
          onClick={e => {
            e.stopPropagation();
            updateJobActivation(row);
          }}
        >
          <OiIcon path='Pause' />
        </TooltipedIconButton>
      </CanDo>

      <CanDo action={taskAccess.delete}>
        <TooltipedIconButton tooltip={'Delete task'}>
          <DeleteIcon
            onClick={e => {
              e && e.stopPropagation();
              deleteTask(id);
            }}
          />
        </TooltipedIconButton>
      </CanDo>
    </>
  );
}
