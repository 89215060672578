import React from 'react';
import { Tooltip } from '@material-ui/core';
import OiIconButton from './OiIconButton';
export default ({ tooltip, children, ...props }) => {
  return (
    <Tooltip title={tooltip}>
      <OiIconButton aria-label={props['aria-label'] || tooltip} {...props}>
        {children}
      </OiIconButton>
    </Tooltip>
  );
};
