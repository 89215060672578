import * as React from 'react';

import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Card from '@material-ui/core/Card/Card';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import SimpleTable from 'src/components/simple-table';
import { ISimpleTableColumn } from 'src/components/simple-table/models';
import BreadcrumbComponent from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import CustomSearch from 'src/components/CustomSearch';
import UsersGridActionButtons from './ActionButtons';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import { ShareableUser } from 'src/shared/models/ShareableUser';
import EntitiesSharedWithUserControllerModal from 'src/components/EntitiesSharedWithUser/EntitiesSharedWithUserControllerModal';

interface userObject {
  id: number;
  activated: boolean;
  firstName: string;
  lastName: string;
  email: string;
  login: string;
  authorities: Array<string>;
  imageUrl: string;
  langKey: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}
interface IUserTypes {
  data: Array<userObject>;
  userUnderProcess?: any;
  openManageShareablesModal?: boolean;
  search: string;
}

class UsersTable extends React.Component<any, IUserTypes> {
  state = {
    userUnderProcess: undefined,
    data: this.props.users,
    openManageShareablesModal: false,
    search: '',
  };
  columns: ISimpleTableColumn[] = [
    { field: 'username', title: 'Username' },
    { field: 'firstName', title: 'First Name' },
    { field: 'lastName', title: 'Last Name' },
    { field: 'email', title: 'Email' },
    {
      field: 'id',
      title: 'actions',
      cellRenderer: (id, userItem) => {
        return (
          <UsersGridActionButtons
            onOpenManageUserShareables={() => this.onOpenManageUserShareables(userItem)}
            onEditClick={this.handleEditClick.bind({}, userItem.id)}
            onDeleteClick={this.handleDelete.bind({}, userItem, userItem.createdBy)}
            onActivationClick={this.handleActive.bind({}, userItem)}
            userEnabled={userItem.enabled}
          />
        );
      },
    },
  ];
  componentWillMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }
  componentWillUpdate(nextProps) {
    const { users } = this.props;
    if (users !== nextProps.users) {
      this.setState({ data: nextProps.users });
    }
  }
  handleEditClick = id => {
    const { history } = this.props;
    history.push('/users/edit/' + id);
  };
  handleSearch = e => {
    const { users } = this.props;
    const value = e.target.value;
    const keyWord = (value || '').toLowerCase();
    let clone = keyWord ? users.filter(value => (value.username || '').toLowerCase().includes(keyWord)) : users.slice();
    this.setState({ search: value, data: clone });
  };

  onOpenManageUserShareables = (userItem: ShareableUser) => {
    this.setState({ userUnderProcess: userItem, openManageShareablesModal: true });
  };

  handleDelete = (user, createdBy) => {
    const { confirm, remove } = this.props;
    const { username, id } = user;

    if (createdBy === 'system') {
      confirm({
        msg: `The ${username} user created by ${createdBy}, you cannot delete this user account`,
        okText: 'Ok',
        cancelText: false,
      });
    } else {
      confirm({
        msg: 'Are you sure you want to delete this users ?',
        okText: 'Ok',
        ok: () => {
          remove(id);
        },
      });
    }
  };

  handleCloseManageShareablesModal = () => {
    this.setState({ openManageShareablesModal: false, userUnderProcess: undefined });
  };

  handleActive = row => {
    const { update } = this.props;
    const requestBody = {
      id: row.id,
      enabled: !row.enabled,
    };
    update(requestBody);
  };
  goToCreator = () => {
    const { history } = this.props;
    history.push('/users/new');
  };
  render() {
    const { data, search, openManageShareablesModal, userUnderProcess } = this.state;
    const { loading } = this.props;
    return (
      <React.Fragment>
        <BreadcrumbComponent>
          Home <KeyboardArrowRight /> Users
        </BreadcrumbComponent>
        <ContentPage>
          <Card>
            <Toolbar>
              <TooltipedIconButton tooltip={'Create new user'} rounded aria-label='Add' onClick={this.goToCreator}>
                <AddIcon />
              </TooltipedIconButton>
              <div className='flex' />
              <CustomSearch onChange={this.handleSearch} value={search} placeholder={'Search by username'} />
            </Toolbar>
            <SimpleTable columns={this.columns} data={data} showLoading={loading} />
            <EntitiesSharedWithUserControllerModal
              onClose={this.handleCloseManageShareablesModal}
              userItem={userUnderProcess}
              open={Boolean(openManageShareablesModal)}
            />
          </Card>
        </ContentPage>
      </React.Fragment>
    );
  }
}

export default UsersTable;
