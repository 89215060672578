import React from 'react';
import OiIconButton from 'src/components/OiIconButton';
import OiIcon from 'src/assets/icons';
import FilterDrawer from './FilterDrawer';

const SelectedFiltersPreviewerBtn = props => {
  const { onFilterPreview } = props;

  const [open, setOpen] = React.useState(false);

  const handleDrawer = e => {
    setOpen(!open);
  };
  const handleFilter = filters => {
    onFilterPreview(filters);
  };
  return (
    <React.Fragment>
      <OiIconButton rounded aria-label='open-drawer' className='add-tab-btn' color='primary' onClick={handleDrawer}>
        <OiIcon path='FilterFunnel' />
      </OiIconButton>

      <FilterDrawer isOpen={open} onclose={handleDrawer} onFilter={handleFilter} />
    </React.Fragment>
  );
};

export default SelectedFiltersPreviewerBtn;
