import * as React from 'react';
import { Select, MenuItem, withStyles } from '@material-ui/core';
import { CronPickers } from './CronPickers';

const EVERY_ARR = ['Hour', 'Day', 'Week', 'Month', 'Year'];

interface iProps {
  model?: '';
  handleChange: Function;
}
interface iState {
  expression: string;
  every: string;
  values: {
    minute: number;
    hour: number;
    dayOfWeek: number;
    dayOfMonth: number;
    month: number;
  };
}
export class CronEditorComponent extends React.Component<iProps, iState> {
  state = {
    expression: '',
    every: 'Hour',
    values: {
      minute: 0,
      hour: 0,
      dayOfWeek: 0,
      dayOfMonth: 0,
      month: 0,
    },
  };
  componentDidMount() {
    if (this.props.model !== undefined) {
      this.handleEditFromModel(this.props.model);
    }
  }

  handleEditFromModel(model) {
    let myValue = {
      minute: 0,
      hour: 0,
      dayOfWeek: 0,
      dayOfMonth: 0,
      month: 0,
    };
    let mo = model && model.split(' ');

    mo &&
      mo.forEach((element, index) => {
        if (element !== '*') {
          switch (index) {
            case 0: {
              myValue.minute = element;
              this.setState({ values: myValue, every: 'Minute' });
              break;
            }
            case 1: {
              myValue.minute = element;
              this.setState({ values: myValue, every: 'Hour' });
              break;
            }
            case 2: {
              myValue.hour = element;
              this.setState({ values: myValue, every: 'Day' });
              break;
            }
            case 3: {
              myValue.dayOfMonth = Number(element);
              this.setState({ values: myValue, every: 'Month' });
              break;
            }
            case 4: {
              myValue.month = Number(element);
              this.setState({ values: myValue, every: 'Year' });
              break;
            }
            case 5: {
              myValue.dayOfWeek = Number(element);
              this.setState({ values: myValue, every: 'Week' });
              break;
            }
          }
        }
      });
  }
  onChangePicker = data => {
    const { every } = this.state;
    let expression = '';

    switch (every.toLowerCase()) {
      case 'minute': {
        expression = '0 * * * * *';
        this.setState({ values: data, expression });
        this.props.handleChange(expression);

        break;
      }
      case 'hour': {
        expression = `0 ${data.minute || '0'} * * * *`;
        this.setState({ values: data, expression });
        this.props.handleChange(expression);

        break;
      }
      case 'day': {
        expression = `0 ${data.minute || '0'} ${data.hour || '1'} * * *`;
        this.setState({ values: data, expression });
        this.props.handleChange(expression);

        break;
      }
      case 'week': {
        expression = `0 ${data.minute || '0'} ${data.hour || '1'} * * ${data.dayOfWeek || '1'}`;
        this.setState({ values: data, expression });
        this.props.handleChange(expression);

        break;
      }
      case 'month': {
        expression = `0 ${data.minute || '0'} ${data.hour || '1'} ${data.dayOfMonth || '1'} * *`;
        this.setState({ values: data, expression });
        this.props.handleChange(expression);

        break;
      }
      case 'year': {
        expression = `0 ${data.minute || '0'} ${data.hour || '1'} ${data.dayOfMonth || '1'} ${data.month || '1'} *`;
        this.setState({ values: data, expression });
        this.props.handleChange(expression);

        break;
      }
    }
  };

  render() {
    const { every, values } = this.state;
    return (
      <>
        {'Every '}
        <Select
          value={every}
          margin='dense'
          onChange={e => {
            this.setState(
              {
                every: e.target.value,
              },
              () => {
                this.onChangePicker(values);
              }
            );
          }}
        >
          {EVERY_ARR.map((period, index) => (
            <MenuItem value={period} key={index}>
              {period}
            </MenuItem>
          ))}
        </Select>

        {CronPickers(every, values, this.onChangePicker)}
      </>
    );
  }
}

export default withStyles({
  minutePicker: {
    width: 40,
    textAlign: 'center',
  },
  hourPicker: {
    width: 40,
    textAlign: 'center',
  },
})(CronEditorComponent);
