import { withStyles, StyleRulesCallback, createStyles, WithStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    titleWrapper: {
      marginBottom: 15,
      color: theme.palette.grey[600],
    },
    textField: {
      flexBasis: 200,
    },
    loginAdornment: {
      color: theme.palette.grey[600],
    },
    marginLeft: {
      margin: '0px 10px',
    },
    flex: {
      display: 'flex',
      height: 35,
      marginTop: 10,
    },
    card: {
      width: 400,
    },
    root: {
      '& fieldset ': {
        borderRadius: 40,
      },
    },
  });

export interface styleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);
export default Styled;
