import { connect } from 'react-redux';
import { saveDashboardTabs, changeSelectedTab, addAllFilter } from 'src/store/dashboards/actions';
import ViewDashboard from './ViewDashboard';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    saveDashboardTabs: tabs => dispatch(saveDashboardTabs(tabs)),
    changeSelectedTab: index => dispatch(changeSelectedTab(index)),
    addFilter: filters => dispatch(addAllFilter(filters)),
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDashboard);

export default connected;
