import React from 'react';
import { Route } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
export function NoQuestions() {
  return (
    <div className='no-questions'>
      <Typography variant='subtitle1' gutterBottom noWrap>
        No Questions
      </Typography>
      <Route
        render={({ history }) => (
          <Button
            onClick={() => {
              history.push('/questions/new');
            }}
          >
            Try add new question
          </Button>
        )}
      />
    </div>
  );
}
