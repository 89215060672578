import * as React from 'react';
import './App.scss';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import MainComponent from './layout/main.component';

import { MuiThemeProvider } from '@material-ui/core';
import theme from './shared/theme';
import MomentUtils from '@date-io/moment';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';

import PasswordReset from './modules/auth/passwordReset';
import ForgetPassword from './modules/auth/forgetPassword';
import LoginContainer from './modules/auth/login';

class App extends React.Component<any> {
  public render() {
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Switch>
              <Route path='/password-reset' component={PasswordReset} />,
              <Route path='/forget-password' component={ForgetPassword} />,
              <Route path='/login' component={LoginContainer} />,
              <Route component={MainComponent} />
            </Switch>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default App;
