import { getQuestionById } from 'store/questions/questions.actions';
import { IRootState } from 'store/rootReducer';
import { connect } from 'react-redux';
import QuestionPreviewerByQuestionId from './QuestionPreviewerByQuestionId';
const mapStateToProps = ({ questions }: IRootState) => ({
  favoriteQuestionsList: questions.favoriteQuestions,
  isLoadingFav: questions.loadingFav,
  questions: questions.questionsList,
  loading: questions.loading,
});
const mapDispatchToProps = {
  dispatchGetQuestionById: getQuestionById,
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionPreviewerByQuestionId);
