import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FilterWrapper from './FilterWrapper';
import { DispatchProps, StateProps } from './FiltersListContainer';
import Styled, { StyleProps } from './filtersListStyle';

const isItemChecked = filter => f =>
  f.dbColumn == filter.dbColumn &&
  f.dbTable == filter.dbTable &&
  f.functionType == filter.functionType &&
  f.operator == filter.operator &&
  f.valueType == filter.valueType;

interface IProps extends StateProps, DispatchProps, StyleProps {
  el: any;
  onClose: any;
}
const FiltersList = (props: IProps) => {
  const { el, onClose, sharedFilter = [], addFilter, removeFilter, classes } = props;
  const filters = props.filters || [];

  const handleClick = filter => {
    const isFilterIn = filters.findIndex(isItemChecked(filter)) >= 0;
    if (filters.length == 0 || !isFilterIn) {
      addFilter(filter);
    } else {
      removeFilter(filter);
    }
  };

  return (
    <FilterWrapper onClose={onClose} el={el}>
      <List className={classes.list}>
        {sharedFilter && sharedFilter.length === 0 && (
          <ListItem dense button>
            <ListItemText primary={`There is no filters you can add!`} />
          </ListItem>
        )}
        {sharedFilter &&
          sharedFilter.map((filter, index) => {
            const isFilterSelected = filters.some(isItemChecked(filter));

            return (
              <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                key={index}
                dense
                button
                onClick={e => handleClick(filter)}
                selected={isFilterSelected}
              >
                <ListItemText primary={`${filter.dbColumn}`} />
              </ListItem>
            );
          })}
      </List>
    </FilterWrapper>
  );
};

const styled = Styled(FiltersList);
export default styled;
