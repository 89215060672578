import { REQUEST } from 'store/util';
import types from './actionTypes';

export const fetchUsers = () => ({
  type: REQUEST(types.FETCH_USERS),
});
export const create = body => ({
  type: REQUEST(types.CREATE),
  payload: { body },
});
export const createWithPermission = body => ({
  type: REQUEST(types.CREATE_WITH_PERMISSION),
  payload: { body },
});
export const setRouteToUsersPage = shouldRoute => ({
  type: REQUEST(types.ROUTE_TO_USERS_PAGE),
  payload: shouldRoute,
});
export const update = body => ({
  type: REQUEST(types.UPDATE),
  payload: { body },
});
export const updateWithPermission = body => ({
  type: REQUEST(types.UPDATE_WITH_PERMISSION),
  payload: { body },
});
export const remove = id => ({
  type: REQUEST(types.DELETE),
  payload: { id },
});
export const getUser = id => ({
  type: REQUEST(types.GET_USER),
  payload: { id },
});
export const fetchAuthorities = () => ({
  type: REQUEST(types.FETCH_AUTHORITIES),
});
export const fetchShareableUsers = () => ({
  type: REQUEST(types.GET_SHAREABLE_USERS),
});
export const fetchPermissions = () => ({
  type: REQUEST(types.FETCH_PERMISSIONS),
});
export const grantPermissions = (username, body) => ({
  type: REQUEST(types.GRANT_PERMISSIONS),
  payload: { username, body },
});
export const updateGrantPermissions = (username, body) => ({
  type: REQUEST(types.GRANT_PERMISSIONS),
  payload: { username, body },
});
export const fetchUserPermissions = username => ({
  type: REQUEST(types.USER_PERMISSIONS),
  payload: { username },
});
