import * as React from 'react';
import { Popover, Tooltip, withStyles } from '@material-ui/core';

import { ViewTypeItem, viewTypesOptionsList, VIEW_TYPE } from './questionViewTypes';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { ColumnType } from 'src/shared/models/SqlColumn';
import OiIconButton from 'src/components/OiIconButton';
import TooltipedIconButton from '../TooltipedIconButton';

type IProps = {
  onSelect: (type: ViewTypeItem) => any;
  meta: ColumnMetadatum[];
  classes?: any;
  value?: ViewTypeItem;
};
export class ViewTypeSelect extends React.Component<IProps> {
  state = {
    anchorEl: undefined,
    isOpen: false,
  };

  onSelect = (type: ViewTypeItem) => () => {
    this.props.onSelect(type);
    this.setState({ anchorEl: undefined });
  };

  getSelectedIcon() {
    return (this.props.value && this.props.value.icon) || viewTypesOptionsList[0].icon;
  }

  render() {
    const { meta, classes } = this.props;
    const measures = (meta || []).filter(m => m.type == ColumnType.MEASURE);
    const dimensions = (meta || []).filter(m => m.type == ColumnType.DIMENSION);
    let SelectedIcon = this.getSelectedIcon() as any;
    let viewTypes = viewTypesOptionsList.filter(o => {
      if (o.chartType === 'pie') {
        return measures.length == 1;
      }
      if (o.type == VIEW_TYPE.KPI) {
        return measures.length == 1 && dimensions.length == 0;
      }
      return o;
    });
    if (measures.length == 1 && dimensions.length == 0) {
      viewTypes = viewTypesOptionsList.filter(o => {
        return o.type == VIEW_TYPE.KPI || o.type == VIEW_TYPE.TABLE;
      });
    }
    return (
      <>
        <TooltipedIconButton
          aria-label='type'
          aria-haspopup='true'
          aria-owns='type-menu'
          className={classes.icon}
          tooltip='Change view type'
          onClick={event => this.setState({ anchorEl: event.currentTarget })}
        >
          <SelectedIcon scale={24} />
        </TooltipedIconButton>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onBackdropClick={() => this.setState({ anchorEl: undefined })}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
        >
          {' '}
          <div className={classes.iconsWrapper}>
            {viewTypes.map(option => {
              let Icon = option.icon as any;
              return (
                <Tooltip key={option.displayName} title={option.displayName} placement='top'>
                  <OiIconButton onClick={this.onSelect(option)} key={option.displayName} className={classes.icon}>
                    <Icon scale={24} />
                  </OiIconButton>
                </Tooltip>
              );
            })}
          </div>
        </Popover>
      </>
    );
  }
}

export default withStyles({
  iconsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 20,
    textAlign: 'center',
  },
  icon: {
    padding: 5,
    color: 'rgba(0, 0, 0, 0.54)',
  },
})(ViewTypeSelect);
