import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import QuestionsList from './questions-list';
import QuestionStudio from './studio';
import CanActivateModule from 'src/components/Permissions/CanActivateModule';
import { AclModules } from 'src/services/permissions/enum';
import { connectedCanDo } from 'src/services/permissions/utils';
import questionAccess from 'src/services/permissions/abilities/questions';
import NotAuthorizedPage from 'src/layout/NotAuthorized';
const Routes = ({ match }) => {
  return (
    <CanActivateModule moduleName={AclModules.questions}>
      <Switch>
        <Route exact path={`${match.url}/new`} component={connectedCanDo(questionAccess.create) ? QuestionStudio : NotAuthorizedPage} />
        <Route exact path={`${match.url}/edit/:id`} component={connectedCanDo(questionAccess.edit) ? QuestionStudio : NotAuthorizedPage} />
        <Route path={match.url} component={QuestionsList} />
      </Switch>
    </CanActivateModule>
  );
};
export default Routes;
