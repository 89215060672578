import React from 'react';
import { Tooltip } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockedIcon from '@material-ui/icons/Lock';
import classNames from 'classnames';
import OiIconButton from 'src/components/OiIconButton';
import OiIcon from 'src/assets/icons';
import IconsSvgBodies from 'assets/icons/IconsSvgBodies';
const ItemHeading = ({
  model,
  expanded,
  displayName,
  titleValues,
  toggleExpanded,
  isInQuestionStudio,
  headingClass,
  onDelete,
  onDynamicChanged,
}) => {
  return (
    <div className={classNames('heading', headingClass)}>
      <Tooltip title='Expand Row'>
        <OiIconButton onClick={toggleExpanded}> {expanded ? <ExpandLess /> : <ExpandMore />} </OiIconButton>
      </Tooltip>
      {isInQuestionStudio && (
        <Tooltip title='Toggle is dynamic filter'>
          <OiIconButton onClick={onDynamicChanged}> {model.dynamic ? <LockOpenIcon /> : <LockedIcon />} </OiIconButton>
        </Tooltip>
      )}
      <div className='flex flex-direction-column'>
        {displayName} {model.filterOperator.displayName} <br />
        <small>{titleValues}</small>
      </div>
      {isInQuestionStudio && (
        <OiIconButton onClick={onDelete}>
          <Delete />
        </OiIconButton>
      )}
    </div>
  );
};
export default ItemHeading;
