import * as React from 'react';
import * as echarts from 'echarts';
import { debounce } from 'modules/questions/studio/utils';
import { ChartCustomizationOptions } from 'src/components/QuestionPreviewer/models';
import { DefaultColorsPallette, ColorsSchemasList } from 'src/components/QuestionPreviewer/ColorSchamas';
import { ColumnMetadatum } from 'shared/models/ColumnMetadatum';
import { reduceMultiDimensionalToOne, convertBarDataToPercentageBar, createHeadersDataRow } from 'components/Charts/utils';
import { PieChartClickEvent } from 'shared/models/EchartsClickEvents';
import { deepClone } from 'src/shared/util';
import { OceanDataType, ColumnType } from 'src/shared/models/SqlColumn';
interface IProps {
  customize: ChartCustomizationOptions;
  data: Array<any>;
  meta: Array<any>;
  currentGroup?: ColumnMetadatum;
  onSegmentClicked: (e: PieChartClickEvent) => any;
  myRef?: (e: { resize: () => any }) => void;
  setChartRef: (e) => any;
}
export default class BarComponent extends React.Component<IProps> {
  myChart: echarts.ECharts;
  debouncedSetChart: any;
  oldCustomizations = '';
  constructor(props) {
    super(props);
    this.debouncedSetChart = debounce(this.updateChartOptions, 100, false);
  }

  componentDidUpdate() {
    this.debouncedSetChart();
  }

  componentDidMount() {
    this.initChart();
    this.props.myRef && this.props.myRef({ resize: this.myChart.resize });
    this.debouncedSetChart();
  }

  componentWillUnmount() {
    if (this.myChart) {
      this.myChart.off('click');
      this.myChart.dispose();
    }
  }

  initChart() {
    let element = this.refs.barChartMainDiv as HTMLDivElement;
    this.myChart = echarts.init(element);
    this.myChart.resize();
    this.myChart.on('click', this.onChartClicked);
  }

  getCurrentGroup = () => {
    const { meta, currentGroup } = this.props;
    return (
      currentGroup || {
        ...meta[0],
      }
    );
  };

  getMetaCounts = () => {
    const { meta = [] } = this.props;
    const dimensionsCount = meta.filter(d => d.type == ColumnType.DIMENSION).length;
    const mesaursCount = meta.length - dimensionsCount;
    return [dimensionsCount, mesaursCount];
  };

  getDefaultOptions = () => {
    const customize = this.props.customize || {};
    let colorIndex = customize.colorSchemaIndex || -1;
    const [dimensionsCount, measuresCount] = this.getMetaCounts();
    const shouldDodimensionalGrouping = customize.dimensionalGrouping && dimensionsCount == 2 && measuresCount == 1;

    return {
      legend: {
        show: customize.legend || false,
        type: 'scroll',
        orient: 'horizontal',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      colors: DefaultColorsPallette,
      color: colorIndex >= 0 ? ColorsSchemasList[colorIndex] : DefaultColorsPallette,
      series: [
        {
          type: 'bar',
          ...this.getMarkingOptions(),
          label: this.getBarLabelOptions(),
          stack: customize.stack ? 1 : undefined,
          barGap: customize.stack ? 0 : undefined,
        },
      ],
      xAxis: {
        type: 'category',
        data: !shouldDodimensionalGrouping ? undefined : this.getXAxisHeaders(), // ['2012', '2013', '2014', '2015', '2016']
      },
      yAxis: {
        type: 'value',
      },
    };
  };

  getXAxisHeaders = () => {
    return (this.props.data || []).reduce((a, v, i) => {
      if (i > 0 && a.indexOf(v[0]) == -1) {
        a.push(v[0]);
      }
      return a;
    }, []);
  };

  getSeriesNames = () => {
    return (this.props.data || []).reduce((a, v, i) => {
      if (i > 0 && a.indexOf(v[1]) == -1) {
        a.push(v[1]);
      }
      return a;
    }, []);
  };

  getBarLabelOptions = () => {
    return {
      normal: {
        show: (this.props.customize || {}).barLabel,
        position: 'insideTop',
        distance: 20,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: '{a}',
        fontSize: 12,
      },
    };
  };

  getMarkingOptions = () => {
    const customize = this.props.customize || {};
    return {
      markPoint: {
        data: [
          {
            name: 'Maximum',
            type: 'max',
            show: customize.maxPoint,
          },
          {
            name: 'Minimum',
            type: 'min',
            show: customize.minPoint,
          },
          {
            name: 'Average',
            type: 'average',
            show: customize.avgPoint,
          },
        ].filter(c => c.show),
      },
      markLine: {
        data: [
          {
            name: 'Maximum',
            type: 'max',
            show: customize.maxLine,
          },
          {
            name: 'Minimum',
            type: 'min',
            show: customize.minLine,
          },
          {
            name: 'Average',
            type: 'average',
            show: customize.avgLine,
          },
        ].filter(c => c.show),
      },
    };
  };

  getSeriesDefaultOptions = () => {
    return { ...this.getDefaultOptions().series[0] };
  };

  updateChartOptions = () => {
    let { meta, customize } = this.props;
    let plainRowsData = (this.props.data || []).slice();
    customize = customize || {};
    let newHeadersRow = createHeadersDataRow(this.getCurrentGroup(), meta);
    const [dimensionsCount, measuresCount] = this.getMetaCounts();
    const shouldDodimensionalGrouping = customize.dimensionalGrouping && dimensionsCount == 2 && measuresCount == 1;
    let xAxisheadersObj = {};
    if (!shouldDodimensionalGrouping && customize.percentageBar) {
      plainRowsData = convertBarDataToPercentageBar(plainRowsData);
      xAxisheadersObj = this.getXAxisHeaders().reduce((acc, v) => {
        acc[v] = '';
        return acc;
      }, {});
    }
    if (!shouldDodimensionalGrouping) {
      plainRowsData = reduceMultiDimensionalToOne(meta, this.getCurrentGroup(), plainRowsData);
    }
    const seriesNames = this.getSeriesNames();
    const seriesCount = shouldDodimensionalGrouping ? seriesNames.length : Math.max(plainRowsData[0].length - 1, 0);
    const defaultOptions = this.getDefaultOptions();
    let options = {
      ...defaultOptions,
      dataset: !shouldDodimensionalGrouping
        ? {
            source: [newHeadersRow, ...plainRowsData],
          }
        : undefined,
      legend: {
        ...defaultOptions.legend,
        data: shouldDodimensionalGrouping ? seriesNames : newHeadersRow,
      },
      series: Array(seriesCount)
        .fill(this.getSeriesDefaultOptions())
        .map((s, sI) => {
          let obj = deepClone(s);
          if (shouldDodimensionalGrouping) {
            obj.name = seriesNames[sI];
            obj.data = plainRowsData.reduce((acc, row) => {
              if (row[1] == obj.name) {
                acc[row[0]] = row[2];
              }
              return acc;
            }, xAxisheadersObj);
            obj.data = this.getXAxisHeaders().map(h => obj.data[h] || 0);
          }
          if (obj.label && obj.label.normal) {
            obj.label.normal.formatter = `{@[${sI + 1}]} ${(customize.percentageBar && '%') || ''}`;
          }
          return obj;
        }),
    };
    if (shouldDodimensionalGrouping && customize.percentageBar) {
      this.getXAxisHeaders().map((h, headerIndx) => {
        const sum = options.series.reduce((acc, s) => acc + parseFloat(s.data[headerIndx]), 0);
        options.series.forEach((s, sI) => {
          s.data[headerIndx] = ((parseFloat(s.data[headerIndx]) / sum) * 100).toFixed(1);
        });
        return;
      });
    }
    if (this.myChart && !this.myChart.isDisposed()) {
      const serializedCustomizations = JSON.stringify(customize);
      if (this.oldCustomizations !== serializedCustomizations) {
        this.oldCustomizations = serializedCustomizations;
        this.myChart.clear();
      }
      this.myChart.setOption(options);
      this.myChart.resize();
      this.props.setChartRef(this.myChart);
    }
  };

  onChartClicked = (e: any) => {
    this.props.onSegmentClicked && this.props.onSegmentClicked(e);
  };

  render() {
    return (
      <>
        <div ref='barChartMainDiv' style={{ height: '100%', width: '100%' }} />
      </>
    );
  }
}
