import { QuestionStudioStoreState, QueryCalculatedColumnItem } from 'src/modules/questions/studio/models';
import { viewTypesOptionsList } from 'src/components/QuestionPreviewer/questionViewTypes';
import { createReducer } from 'store/util';
import { ACTION_TYPES } from './questionStudioActions';
import { SQLDatabase } from 'src/shared/models/SqlDatabase';
import { SQLTable } from 'src/shared/models/SqlTable';
import { chartSettingsOptions } from 'src/components/Charts/chartSettingsOptions';
import { SORT_DIRECTION } from 'src/shared/models';

/* ------------- Initial State ------------- */
const DEFAULTS = {
  resultsLimit: 50,
};
declare type IState = QuestionStudioStoreState;
export const initialState: IState = {
  dbTables: [],
  saveConfigs: undefined as any,
  id: undefined,
  name: '',
  vid: 1,
  tags: '',
  baseTable: undefined,
  database: undefined,
  lastQueryPage: 1,
  limit: '' + DEFAULTS.resultsLimit,
  viewsItems: [],
  groupItems: [],
  filtersItems: [],
  computedColumns: [],
  chartOptions: chartSettingsOptions.reduce((options, o) => Object.assign(options, { [o.fieldName]: o.value }), {}) as any,
  description: '',
  isLoadingPreview: false,
  advanceFilter: undefined,
  isLoadingQuestion: false,
  expandPreviewResultCard: false,
  saveModalIsOpened: false,
  previewResult: {} as any,
  viewTypeOption: viewTypesOptionsList[0],
  mayNeedRefresh: false,
  showPreviewPanelPlaceHolderMessage: true,
} as any;
export type QuestionsStudioState = Readonly<typeof initialState>;

/* ------------- Selectors ------------- */

/* ------------- Reducers ------------- */

// request the avatar for a user
export const resetState = (state: IState): IState => ({ ...initialState });
export const loadQuestionById = (state: IState, { id }): IState => ({ ...state, id, isLoadingQuestion: true });
export const resetIngredientsPanelData = (state: IState) => ({
  ...initialState,
  database: state.database,
  dbTables: state.dbTables,
  baseTable: state.baseTable,
});
export const toggleExpandForm = (state: IState): IState => ({
  ...state,
  expandPreviewResultCard: !state.expandPreviewResultCard,
});
export const onViewRefreshed = (state: IState): IState => ({ ...state, mayNeedRefresh: false });
export const selectDb = (state: IState, { db, tables }: { db: SQLDatabase; tables?: SQLTable[] }): IState => {
  return { ...state, database: db, dbTables: tables || [] };
};
export const selectBaseTable = (state: IState, { table }): IState => ({ ...state, baseTable: table });
export const selectForm = (state: IState, { db }): IState => ({ ...state, database: db });
export const batchState = (state: IState, { batch }): IState => ({ ...state, ...batch });
export const addViewItem = (state: IState, { viewItem }): IState => {
  viewItem = viewItem;
  return {
    ...state,
    viewsItems: state.viewsItems.concat([viewItem]),
    vid: state.vid + 1,
    mayNeedRefresh: true,
    expandPreviewResultCard: true,
  };
};
export const addGroupItem = (state: IState, { groupItem }): IState => {
  groupItem = groupItem;
  return {
    ...state,
    groupItems: state.groupItems.concat([groupItem]),
    vid: state.vid + 1,
    mayNeedRefresh: true,
    expandPreviewResultCard: true,
  };
};

export const addFilterItem = (state: IState, { filterItem }): IState => {
  return {
    ...state,
    filtersItems: state.filtersItems.concat([filterItem]),
    vid: state.vid + 1, //؟؟
    mayNeedRefresh: true,
    expandPreviewResultCard: true,
  };
};
export const addRawData = (state: IState, { viewsItems }): IState => ({
  ...state,
  viewsItems: viewsItems,
  vid: viewsItems.length,
  mayNeedRefresh: true,
  expandPreviewResultCard: true,
});

export const onAddCalculatedColumn = (state: IState, payload): IState => {
  const quesitonName = getQuestionTitle(state);
  let computedColumns = state.computedColumns.slice();
  computedColumns.push({
    displayName: 'New Calculated Column' + (state.computedColumns.length + 1),
    vid: state.vid + 1,
    expression: {
      parentId: null,
      t: 'group',
      value: [],
    } as any,
    grouped: false,
    orderDirection: SORT_DIRECTION.NONE,
    pin: false,
    orderIndex: state.computedColumns.length,
  });
  return {
    ...state,
    name: quesitonName,
    mayNeedRefresh: true,
    showPreviewPanelPlaceHolderMessage: false,
    expandPreviewResultCard: true,
    vid: state.vid + 1,
    computedColumns: computedColumns,
  };
};

export const getQuestionTitle = state => {
  const { viewsItems, groupItems, computedColumns = [] } = state;
  const cols = viewsItems.map(c => c.displayName).concat(computedColumns.map(c => c.expressionText));
  return cols.join(' , ') + (groupItems.length ? ' By ' + groupItems.map(g => g.displayName).join(' , ') : '');
};
export const onDeleteCalculatedColumn = (state: IState, { col, index }) => {
  const questionName = getQuestionTitle(state);
  return {
    ...state,
    name: questionName,
    computedColumns: state.computedColumns.filter((c, i) => i !== index),
  };
};
export const onChangeCalculatedColumn = (state: IState, payload) => {
  const { batch, index } = payload;
  const questionName = getQuestionTitle(state);

  return {
    ...state,
    mayNeedRefresh: true,
    name: questionName,
    computedColumns: state.computedColumns.map((c, i) => {
      if (i === index) {
        return batch;
      }
      return c;
    }),
  };
};

export const onAddAdvanceFilter = (state: IState, payload): IState => {
  if (state.advanceFilter) return state;
  return {
    ...state,
    mayNeedRefresh: true,
    showPreviewPanelPlaceHolderMessage: false,
    expandPreviewResultCard: true,
    vid: state.vid + 1,
    advanceFilter: {
      items: [],
      groupOperator: 'and',
      groupItemType: 'group',
    },
  };
};

export const updateAdvanceFiltersRoot = (state: IState, payload) => {
  const { groupBatch } = payload;
  return {
    ...state,
    mayNeedRefresh: true,
    advanceFilter: groupBatch,
  };
};

export const deleteAdvanceFiltersRoot = (state: IState, payload) => {
  return {
    ...state,
    mayNeedRefresh: true,
    advanceFilter: undefined,
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export default createReducer(initialState, {
  [ACTION_TYPES.RESET_STATE]: resetState,
  [ACTION_TYPES.RESET_INGREDIENTS_PANEL_DATA]: resetIngredientsPanelData,
  [ACTION_TYPES.LOAD_QUESTION_BY_ID]: loadQuestionById,

  [ACTION_TYPES.ON_SELECT_DB]: selectDb,
  [ACTION_TYPES.ON_SELECT_BASE_TABLE]: selectBaseTable,

  [ACTION_TYPES.ON_VIEW_REFRESHED]: onViewRefreshed,
  [ACTION_TYPES.TOGGLE_EXPAND_FORM]: toggleExpandForm,

  [ACTION_TYPES.ADD_VIEW_ITEM]: addViewItem,
  [ACTION_TYPES.ADD_RAW_DATA]: addRawData,

  [ACTION_TYPES.BATCH_STATE]: batchState,
  [ACTION_TYPES.ON_ADD_CALCULATED_COLUMN]: onAddCalculatedColumn,
  [ACTION_TYPES.ON_DELETE_CALCULATED_COLUMN]: onDeleteCalculatedColumn,
  [ACTION_TYPES.ON_CHANGE_CALCULATED_COLUMN]: onChangeCalculatedColumn,

  [ACTION_TYPES.ON_ADD_ADVANCE_FILTER]: onAddAdvanceFilter,
  [ACTION_TYPES.UPDATE_ADVANCE_FILTERS_ROOT]: updateAdvanceFiltersRoot,
  [ACTION_TYPES.DELETE_ADVANCE_FILTERS_ROOT]: deleteAdvanceFiltersRoot,

  [ACTION_TYPES.ADD_GROUP_ITEM]: addGroupItem,
  //   [ACTION_TYPES.ADD_FILTER_ITEM]: null,

  //   [ACTION_TYPES.UPDATE_VIEW_ITEM]: null,
  //   [ACTION_TYPES.UPDATE_GROUP_ITEM]: null,
  //   [ACTION_TYPES.UPDATE_FILTER_ITEM]: null,

  //   [ACTION_TYPES.REMOVE_VIEW_ITEM]: null,
  //   [ACTION_TYPES.REMOVE_GROUP_ITEM]: null,
  //   [ACTION_TYPES.REMOVE_FILTER_ITEM]: null,
  //   [ACTION_TYPES.ON_SET_RESULT_LIMIT]: null,
});
