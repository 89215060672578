import { ColumnType, OceanDataType } from 'src/shared/models/SqlColumn';
import { getMetaColumnNameInDb } from 'src/shared/models/ExecuterResultResponseDto';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';

export function reduceMultiDimensionalToOne(meta: ColumnMetadatum[], currentGroup, data) {
  let includedIndecies = meta.reduce(
    (acc, c, i) => {
      let isSingleColumn = c => c.expression.length == 1;
      if (getMetaColumnNameInDb(c) == getMetaColumnNameInDb(currentGroup) || c.type !== ColumnType.DIMENSION) {
        acc.push(i);
      }
      return acc;
    },
    [] as Array<any>
  );
  let dataRows = data.slice().map(r => r.filter((c, i) => includedIndecies.indexOf(i) > -1));
  let accumulated = dataRows.reduce((acc, r, i) => {
    let key = r[0];
    // acc[0] = [/*0,*/ 1,2,3]
    acc[key] = acc[key] || [];
    acc[key] = r.map((d, i) => {
      if (i == 0) return key;
      return acc[key][i] ? Number(acc[key][i]) + Number(d) : Number(d || 0);
    });

    return acc;
  }, {});
  return Object.keys(accumulated).map(key => accumulated[key]);
}

export function convertBarDataToPercentageBar(data) {
  return data.map(row => {
    let [label, ...rowData] = row;
    let sum = rowData.reduce((a, c) => a + parseFloat(c), 0);
    return [label, ...rowData.map((d, i) => ((parseFloat(d) / sum) * 100).toFixed(1))];
  });
}
export function createHeadersDataRow(currentGroup: ColumnMetadatum | undefined, meta: any[]) {
  return [currentGroup].concat(meta.filter(m => m.type !== ColumnType.DIMENSION)).map((c: ColumnMetadatum) => c.label);
}
