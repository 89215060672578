import React from 'react';

// material-ui components
import Dashboard from '@material-ui/icons/Dashboard';
import AccountBox from '@material-ui/icons/AccountBox';
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import HelpOutline from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Storage';
import Storage from '@material-ui/icons/Storage';
import TimerIcon from '@material-ui/icons/Timer';
import { hasModulePermission, connectedCanDo, isAdmin, isSystemAdmin } from 'src/services/permissions/utils';
import questionAccess from 'src/services/permissions/abilities/questions';
import downloadAccess from 'src/services/permissions/abilities/downloads';
import taskAccess from 'src/services/permissions/abilities/tasks';
import { AclModules } from 'src/services/permissions/enum';

const AppMenuItems = [
  {
    linkTo: '/dashboards',
    title: 'Dashboards',
    icon: <Dashboard />,
    module: 'dashboard',
    isVisible: (permissions, roles) => hasModulePermission(permissions, roles, AclModules.dashboards),
  },
  {
    linkTo: '/questions',
    title: 'Questions',
    icon: <HelpOutline />,
    module: 'question',
    isVisible: (permissions, roles) => hasModulePermission(permissions, roles, AclModules.questions),
  },
  {
    linkTo: '/databases',
    title: 'Databases',
    icon: <Storage />,
    module: 'database',
    isVisible: (permissions, roles) => hasModulePermission(permissions, roles, AclModules.databases),
  },
  {
    linkTo: '/downloads',
    title: 'Downloads',
    icon: <FileDownloadIcon />,
    module: 'download',
    isVisible: (permissions, roles) =>
      connectedCanDo(downloadAccess.donwnload) || hasModulePermission(permissions, roles, AclModules.downloads),
  },
  {
    linkTo: '/scheduled-tasks',
    title: 'Tasks',
    icon: <TimerIcon />,
    module: 'task',
    isVisible: (permissions, roles) => connectedCanDo(taskAccess.view) || hasModulePermission(permissions, roles, AclModules.tasks),
  },
  {
    linkTo: '/users',
    title: 'Users',
    icon: <AccountBox />,
    module: 'user',
    isVisible: (permissions, roles) => hasModulePermission(permissions, roles, AclModules.users),
  },
  {
    linkTo: '/settings',
    title: 'Settings',
    icon: <SettingsIcon />,
    isVisible: (permissions, roles) => isAdmin(roles) || isSystemAdmin(roles),
  },
];
export default AppMenuItems;
