import { connect } from 'react-redux';
import { fetchUsers, remove, update } from 'src/store/users/actions';
import UsersTable from 'modules/users/usersList/UsersTable';
import { openConfirmationPopup } from 'src/store/confirmationPopup';
import { allUsers, loader, getSuccess } from 'src/store/users/selectors';

const mapStateToProps = state => {
  return {
    users: allUsers(state),
    loading: loader(state),
    activationSuccess: getSuccess(state, update(undefined).type),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchUsers: () => {
      dispatch(fetchUsers());
    },
    remove: id => {
      dispatch(remove(id));
    },
    confirm: config => {
      openConfirmationPopup(config)(dispatch);
    },
    update: body => {
      dispatch(update(body));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTable);
