import React from 'react';
import moment from 'moment';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';

export function FilterDatePicker({ value, label, analysis, onPickDay }) {
  let maxDate = moment().toDate();
  if (analysis && analysis.maxDate) {
    if (moment(analysis.maxDate).isAfter(moment())) {
      maxDate = moment(analysis.maxDate || undefined).toDate();
    } else {
      maxDate = moment().toDate();
    }
  } else {
    maxDate = moment().toDate();
  }
  return (
    <InlineDatePicker
      keyboard
      fullWidth
      variant='outlined'
      margin='dense'
      label={label}
      // handleAccept={() => alert('accepted!')}
      value={moment(value).toDate()}
      maxDate={maxDate}
      minDate={analysis ? moment(analysis.minDate || undefined).toDate() : undefined}
      onChange={onPickDay}
      format='DD/MM/YYYY'
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  );
}
