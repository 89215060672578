import PieChartImg from 'assets/img/pie.png';
import barLineImg from 'assets/img/barLine.png';
import BarChartImg from 'assets/img/bar.png';
import doughnutChartImg from 'assets/img/doughnut.png';
import kpiImg from 'assets/img/kpi.png';
import tableImg from 'assets/img/table.png';
import lineChartImg from 'assets/img/linechart.png';

import { withStyles, createStyles, WithStyles } from '@material-ui/core';

const styles = () =>
  createStyles({
    PieChartImg: {
      backgroundImage: `url(${PieChartImg})`,
    },

    Image2: {
      backgroundImage: `url(${barLineImg})`,
    },

    BarChart: {
      backgroundImage: `url(${BarChartImg})`,
    },
    Kpi: {
      backgroundImage: `url(${kpiImg})`,
    },
    Table: {
      backgroundImage: `url(${tableImg})`,
    },
    lineChartImg: {
      backgroundImage: `url(${lineChartImg})`,
    },

    doughnutChart: {
      backgroundImage: `url(${doughnutChartImg})`,
    },
  });

export interface styleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);

export default Styled;
