import axios from 'axios';
import { ExportQuestionDto } from '../models/Exporter';
import { DownloadableFile } from '../models/DownloadableFile';
import { authUrl } from './baseUrls';
import { GetAuthoritiesDto } from '../models/GetAuthoritiesDto';
import { GetPermissionsDto } from '../models/GetPermissionsDto';

export function loginApi(info): any {
  return axios
    .post(authUrl + '/authenticate', info)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}
export const downloadFileResponse = (data, filename, isDataUrl?: boolean) => {
  const url = isDataUrl ? data : window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    link.remove();
  }, 300);
};

const downloadExportedFile = (file: ExportQuestionDto | DownloadableFile) => {
  const url = authUrl + '/exported-files/' + file.id;
  return axios.get(url, { responseType: 'blob' }).then(r => {
    const fileName = `${(file.displayName || 'exported-file').replace(/\s/g, '_')}.${file.extention.toLowerCase()}`;
    downloadFileResponse(r.data, fileName);
  });
};
const finishResetPassword = body => {
  const url = authUrl + '/account/reset-password/finish';
  return axios.post(url, body);
};
const removeUser = login => {
  const url = authUrl + `/users/${login}`;
  return axios.delete(url);
};
const getSharableUsers = () => {
  const url = authUrl + `/sharableusers?size=9999`;
  return axios.get(url);
};
const getAuthorities = () => {
  const url = authUrl + `/users/authorities`;
  return axios.get<GetAuthoritiesDto[]>(url);
};
const getUserById = id => {
  const url = authUrl + `/users/${id}`;
  return axios.get(url);
};
const getUserPermissionsByUserName = username => {
  const url = authUrl + `/users/${username}/permissions`;
  return axios.get(url);
};
const getUsersPermissions = () => {
  const url = authUrl + '/user-permissions';
  return axios.get<GetPermissionsDto[]>(url);
};
const getCurrentUserAccount = () => {
  const url = authUrl + '/account';
  return axios.get(url);
};
const grantUserPermissionByName = (username, body) => {
  const url = authUrl + `/users/${username}/permissions/grant`;
  return axios.post(url, body);
};
const updateUserGrantedPermissionByName = (username, body) => {
  const url = authUrl + `/users/${username}/permissions/set`;
  return axios.post(url, body);
};

const changeCurrentUserPassowrd = data => {
  const url = authUrl + '/account/change-password';
  return axios.post(url, data);
};
const getSystemSettings = () => {
  const url = authUrl + '/systemsettings';
  return axios.get(url);
};
const getUsers = () => {
  const url = authUrl + '/users?size=9999';
  return axios.get(url);
};
const logout = refreshToken => {
  const url = authUrl + '/logout ';
  return axios.post(url, { refreshToken });
};
export default {
  /* Users */
  logout,
  loginApi,
  removeUser,
  getUserById,
  getUsers,
  getAuthorities,
  getSharableUsers,
  getSystemSettings,
  getUsersPermissions,
  finishResetPassword,
  downloadExportedFile,
  getCurrentUserAccount,
  grantUserPermissionByName,
  changeCurrentUserPassowrd,
  getUserPermissionsByUserName,
  updateUserGrantedPermissionByName,
};
