import * as React from 'react';
import Card from '@material-ui/core/Card/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';

import AuthGrid from '../grid/AuthGrid';
import PasswordResetForm from './Form';
import Styled from './style';

class PasswordReset extends React.Component<any> {
  componentDidUpdate(nextProps) {
    const { success, history } = this.props;
    if (nextProps.success !== success) {
      history.push('/login');
    }
  }
  handleSubmit = event => {
    const { location, resetPassword } = this.props;
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    resetPassword({ token, password: event.password1 });
  };

  render() {
    const { classes, loading } = this.props;
    return (
      <AuthGrid>
        <Card elevation={5} className={classes.card}>
          <CardHeader title='Reset password' titleTypographyProps={{ className: classes.cardHeaderStyle }} />
          <CardContent>
            <PasswordResetForm loading={loading} onSubmit={this.handleSubmit} />
          </CardContent>
        </Card>
      </AuthGrid>
    );
  }
}
const styled = Styled(PasswordReset);
export default styled;
