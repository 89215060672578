import React, { useState, useEffect } from 'react';
import toastr from 'src/components/toastr';
import discoveryApis from 'src/shared/apis/discoveryApis';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';
import oceancoreApis from 'src/shared/apis/oceancoreApis';
import { GDAFilter } from 'src/shared/models/GdaFilterDto';
import { SQLDatabase } from 'src/shared/models/SqlDatabase';
import { SQLTable } from 'src/shared/models/SqlTable';
import GDAFilterForm from './GDAFilterForm';
import { convertListToSelectOptions } from 'src/components/TitledAutoComplete/utils';
import { ISqlColumnWithTableNames } from 'src/components/PrimaryColumnSelect/PrimaryColumnSelect';

interface IProps {
  model?: GDAFilter;
  onCancel: (d: any) => any;
  onSaveAndClose: (d: any) => any;
}
export default function GDAFilterFormContainer(props: IProps) {
  const { onCancel, onSaveAndClose, model } = props;
  const [users, setUsers] = useState([] as any[]);
  const [databases, setDatabases]: [SQLDatabase[], any] = useState([] as SQLDatabase[]);
  const [primaryColumnsList, setPrimaryColumnsList]: [ISqlColumnWithTableNames[], any] = useState([] as ISqlColumnWithTableNames[]);
  ///////////////////////////////////
  const [selectedUser, setSelectedUser]: [any, any] = useState(undefined);
  const [selectedDatabase, setSelectedDatabase]: [SQLDatabase?, any?] = useState(undefined);
  const [selectedPrimaryColumnId, setPrimaryColumnId]: [number?, any?] = useState(undefined);
  ///////////////////////////////////
  const [isLoadingUsers, setIsLoadingUsers]: [boolean, any] = useState(false);
  const [isLoadingDatabases, setIsLoadingDatabases]: [boolean, any] = useState(false);
  const [isLoadingTables, setIsLoadingTables]: [boolean, any] = useState(false);
  const [isSavingForm, setIsSavingForm]: [boolean, any] = useState(false);

  React.useEffect(() => {
    loadUsers();
    loadDatabases();
  }, []);

  React.useEffect(
    () => {
      if (selectedDatabase) {
        loadTablesList(selectedDatabase!.id);
      }
    },
    [selectedDatabase]
  );

  const loadTablesList = dbId => {
    setIsLoadingTables(true);
    selectedDatabase &&
      discoveryApis
        .getDatabaseRawTables(dbId)
        .then(
          tables => {
            let columnsDatasource: ISqlColumnWithTableNames[] = tables.reduce(
              (agg, table) => {
                let columns = (table.columns || [])
                  .filter(c => c.pk)
                  .map(t => ({
                    ...t,
                    dbTableName: table.name,
                    tableDisplayName: table.displayName,
                  }));
                return agg.concat(columns);
              },
              [] as ISqlColumnWithTableNames[]
            );
            setPrimaryColumnsList(columnsDatasource);
          },
          err => {
            toastr.error('Error loading database tables');
            console.error('Error loading database tables', err);
          }
        )
        .finally(() => setIsLoadingTables(false));
  };

  const loadUsers = () => {
    setIsLoadingUsers(true);
    oceanAuthApis
      .getUsers()
      .then(r => r.data.filter(u => u))
      .then(
        users => {
          const usersDatasource = convertListToSelectOptions<any>(users, {
            label: u => `${u.firstName} ${u.lastName}`,
            value: (u, i) => i,
          });
          setUsers(usersDatasource);
          if (model && model.username) {
            setSelectedUser(usersDatasource.find(u => u.username == model.username));
          }
        },
        err => {
          toastr.error('Failed to load GDA users');
          console.error('Failed to load GDA users', err);
        }
      )
      .finally(() => setIsLoadingUsers(false));
  };

  const loadDatabases = () => {
    setIsLoadingDatabases(true);
    discoveryApis
      .getDatabases()
      .then(
        dbs => {
          let databasesDatasource = convertListToSelectOptions<SQLDatabase>(dbs, {
            label: u => u.displayName,
            value: (u, i) => i,
          });
          setDatabases(databasesDatasource);
          if (model && model.databaseName) {
            setSelectedDatabase(databasesDatasource.find(db => db.dbName == model.databaseName));
          }
        },
        err => {
          toastr.error('Failed to load GDA databases');
          console.error('Failed to load GDA databases', err);
        }
      )
      .finally(() => setIsLoadingDatabases(false));
  };

  const onSave = (gdaFilter: GDAFilter) => {
    setIsSavingForm(true);
    let save = gdaFilter.id ? oceancoreApis.editGdaFilter(gdaFilter) : oceancoreApis.addGdaFilter(gdaFilter);
    save
      .then(
        rest => {
          toastr.success('GDA filter saved successfully');
          onSaveAndClose(gdaFilter);
        },
        rest => {
          toastr.error('Failed to save GDA filter');
          console.error('Failed to save GDA filter', rest);
        }
      )
      .finally(() => setIsSavingForm(false));
  };

  return (
    <GDAFilterForm
      id={model && model.id}
      databasesOptions={{
        isLoading: isLoadingDatabases,
        datasource: databases,
        onChanged: setSelectedDatabase,
        value: selectedDatabase,
      }}
      usersOptions={{
        isLoading: isLoadingUsers,
        datasource: users,
        onChanged: setSelectedUser,
        value: selectedUser,
      }}
      columnsOptions={{
        isLoading: isLoadingTables,
        datasource: primaryColumnsList,
        onChanged: setPrimaryColumnId,
        value: selectedPrimaryColumnId,
      }}
      columnInitalValue={model ? model.columnValue : ''}
      isSavingForm={isSavingForm}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
}
