import * as React from 'react';
import { Select, MenuItem, TextField } from '@material-ui/core';
import moment from 'moment';

const DAYS = moment.weekdays();
const MONTHES = moment.months();
const DAYS_OF_MONTH = Array.of(...Array(30)).map((value, i) => moment(`${1 + i}`, 'DD').format('Do'));

const isItNumber = value => {
  return /^\d+$/.test(value);
};

const getValidValue = (value: string, max: number, min: number): string => {
  if (!isItNumber(value)) {
    return min + '';
  } else if (parseInt(value) < min) {
    return min + '';
  } else if (parseInt(value) > max) {
    return max + '';
  } else {
    return value;
  }
};
export const CronPickers = (every, model, handleChange) => {
  let minuteField = (
    <TextField
      type='number'
      margin='none'
      inputProps={{
        max: 59,
        min: 0,
      }}
      value={model.minute}
      onChange={e => {
        let v = getValidValue(e.target.value, 59, 0);

        let m = Object.assign({}, model, { minute: v });
        handleChange(m);
      }}
    />
  );
  let hourFields = (
    <React.Fragment>
      <TextField
        type='number'
        margin='none'
        inputProps={{
          max: 23,
          min: 0,
        }}
        value={model.hour}
        onChange={e => {
          let v = getValidValue(e.target.value, 23, 0);

          let m = Object.assign({}, model, { hour: v });
          handleChange(m);
        }}
      />
      : {minuteField}
    </React.Fragment>
  );
  let daysOfMonthsField = (
    <React.Fragment>
      <Select
        value={model.dayOfMonth}
        margin='dense'
        onChange={e => {
          let m = Object.assign({}, model, { dayOfMonth: e.target.value });
          handleChange(m);
        }}
      >
        {DAYS_OF_MONTH.map((day, i) => (
          <MenuItem value={i} key={i}>
            {day}
          </MenuItem>
        ))}
      </Select>
    </React.Fragment>
  );

  switch (every.toLowerCase()) {
    case 'minute':
      return <></>;
    case 'hour':
      return (
        <React.Fragment>
          {' on '}
          {minuteField}
        </React.Fragment>
      );
    case 'day':
      return (
        <React.Fragment>
          {' at '}
          {hourFields}
        </React.Fragment>
      );
    case 'week':
      return (
        <React.Fragment>
          {' on '}
          <Select
            value={model.dayOfWeek}
            margin='dense'
            onChange={e => {
              let m = Object.assign({}, model, { dayOfWeek: e.target.value });
              handleChange(m);
            }}
          >
            {DAYS.map((day, i) => (
              <MenuItem value={i} key={i}>
                {day}
              </MenuItem>
            ))}
          </Select>
          {' at '}
          {hourFields}
        </React.Fragment>
      );
    case 'month':
      return (
        <React.Fragment>
          {' on the '}
          {daysOfMonthsField}
          {' at '}
          {hourFields}
        </React.Fragment>
      );
    case 'year':
      return (
        <React.Fragment>
          {' on the '}
          {daysOfMonthsField}
          {' of '}
          <Select
            value={model.month}
            margin='dense'
            onChange={e => {
              let m = Object.assign({}, model, { month: e.target.value });
              handleChange(m);
            }}
          >
            {MONTHES.map((month, i) => (
              <MenuItem value={i} key={i}>
                {month}
              </MenuItem>
            ))}
          </Select>
          {' at '}
          {hourFields}
        </React.Fragment>
      );
    default:
      return <></>;
  }
};
