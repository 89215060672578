import PERMISSIONS from '../enum';
const databaseAccess = {
  create: [PERMISSIONS.DATABASE.WRITE],
  delete: [PERMISSIONS.DATABASE.DELETE],
  share: [PERMISSIONS.DATABASE.SHARE],
  edit: [PERMISSIONS.DATABASE.WRITE],
  view: [PERMISSIONS.DATABASE.VIEW, PERMISSIONS.DATABASE.WRITE],
};

export default databaseAccess;
