import { Theme } from '@material-ui/core/styles';
import { measures, StyleRulesCallback } from '../main.style';
import { Colors } from 'src/shared/theme';

import oiLogoBgImgWhite from 'src/assets/img/ocean_logo_white2.png';
export const styles: StyleRulesCallback = (theme: Theme) => ({
  appBar: {
    height: measures.headerHeight,
    position: 'relative',
  },
  menuIcon: {
    marginRight: '10px',
    marginLeft: '-15px',
    color: 'white !important',
  },
  oicTypography: {
    marginRight: '10px',
  },
  flex: {
    flex: 1,
  },
  exitIconBtn: {
    padding: '4px',
    marginLeft: '5px',
    color: Colors.brandDarkGrey,
  },
  oiBgLogo: {
    backgroundSize: 'contain',
    height: 40,
    width: 130,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${oiLogoBgImgWhite})`,
  },
});
