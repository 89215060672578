import * as React from 'react';
import { FormControl, MenuItem, withStyles, Theme, WithStyles, StyleRulesCallback, TextField } from '@material-ui/core';
import {
  isEmptinessOperator,
  oiQueryFiltersSelectOptions,
  ColumnDefaultComparisonOperator,
  getFilterDefaultValue,
} from 'src/shared/models/OiFilter';
import { OiFilterDropdownItem } from 'src/shared/models/OiFilterDropdownItem';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import classNames from 'classnames';
import { SQLColumn, OceanDataType } from 'src/shared/models/SqlColumn';
import { QueryFilterViewItem } from 'src/modules/questions/studio/models';
import FilterValuePresenter from 'src/modules/questions/studio/components/QuestionFilter/filterValuePresenter';

interface IProps extends WithStyles<typeof styles> {
  onDone?: any;
  onSelectFilterOperator?: any;
  selectedColumn?: SQLColumn;
}
export class AddFilterSelectOperatorValueStep extends React.Component<IProps, any> {
  state = {
    anchorEl: undefined,
    isOpen: false,
    selectedFilter: '',
    values: [],
  };

  componentDidUpdate(prevProps: IProps) {
    const prevCol = prevProps.selectedColumn;
    const selectedColumn = this.props.selectedColumn;
    if (prevCol && selectedColumn && prevCol.id != selectedColumn.id) {
      this.onColumnChanged(selectedColumn);
    }
  }

  onColumnChanged(selectedColumn, prevCol?) {
    const defaultOperator = ColumnDefaultComparisonOperator(selectedColumn);
    const operatorItem = defaultOperator ? oiQueryFiltersSelectOptions.find(t => t.operator == defaultOperator!.operator) : null;
    if (operatorItem) {
      this.setState({
        selectedFilter: operatorItem.operator,
        values: operatorItem.defaultValue,
      });
    }
  }

  onSelectOperator = e => {
    this.setState({ values: [] });
    const selectedColumn = this.props.selectedColumn as SQLColumn;
    const operatorItem = oiQueryFiltersSelectOptions.find(t => t.operator == e.target.value);
    if (isEmptinessOperator(operatorItem && operatorItem.operator)) {
      // this.onDone(); ?!
    }
    if (operatorItem) {
      let defaultValue = operatorItem.defaultValue;
      if (selectedColumn && selectedColumn.oceanDataType == OceanDataType.DATE) {
        defaultValue = getFilterDefaultValue(selectedColumn, operatorItem);
      }

      this.setState({
        selectedFilter: operatorItem.operator,
        values: defaultValue,
      });
    }
    this.props.onSelectFilterOperator && this.props.onSelectFilterOperator(operatorItem);
  };

  onDone = () => {
    const { selectedFilter, values } = this.state;
    const { selectedColumn } = this.props;
    const operator = oiQueryFiltersSelectOptions.find(t => t.operator == selectedFilter);
    let model: QueryFilterViewItem = {
      column: selectedColumn!,
      filterOperator: operator as OiFilterDropdownItem,
      values: values,
    };
    this.props.onDone && this.props.onDone(model);
  };

  onValueUpdated = values => {
    if (values && Array.isArray(values)) {
      this.setState({ values });
    }
  };

  render() {
    const { selectedColumn, classes } = this.props;
    const { selectedFilter, values } = this.state;
    const filteringOperators = oiQueryFiltersSelectOptions.filter(
      item => item.isGeneric || (selectedColumn && item.appliesFor.indexOf(selectedColumn.oceanDataType) > -1)
    );
    return (
      <>
        <TextField
          id='operator-select'
          label='Operator '
          placeholder=' Select an Operator'
          name='operator'
          margin='dense'
          variant='outlined'
          value={selectedFilter}
          fullWidth
          select
          onChange={this.onSelectOperator}
          className={classNames(classes.selectOperatorRoot, {})}
        >
          {filteringOperators.map((item, key) => (
            <MenuItem button key={key} value={item.operator} selected={item.operator == selectedFilter}>
              {item.displayName}
            </MenuItem>
          ))}
        </TextField>

        <FormControl fullWidth>
          <FilterValuePresenter
            columnId={selectedColumn && selectedColumn.id}
            autoOpenDatePicker={true}
            inEditMode={true}
            showDone={true}
            filterOperator={selectedFilter as ComparisonOperator}
            onValueUpdated={this.onValueUpdated}
            onSave={this.onDone}
            pinDoneButtonDown={true}
            valueType={selectedColumn && selectedColumn.oceanDataType}
            values={values}
          />
        </FormControl>
      </>
    );
  }
}

const styles: StyleRulesCallback = (theme: Theme) => ({
  selectOperatorRoot: {
    width: '100%',
    marginBottom: 15,
  },
});

export default withStyles(styles)(AddFilterSelectOperatorValueStep);
