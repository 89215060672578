import { OiFunction } from '../models/OiFunction';
export function isDateGroupingOperator(op: OiFunction) {
  return [OiFunction.DATE_BY_DAY, OiFunction.DATE_BY_MONTH, OiFunction.DATE_BY_YEAR, OiFunction.DATE_BY_QUARTER].includes(op);
}
export function getGroupingOperatorName(op?: OiFunction) {
  switch (op) {
    case OiFunction.DATE_BY_DAY:
      return 'By Day';
    case OiFunction.DATE_BY_MONTH:
      return 'By Month';
    case OiFunction.DATE_BY_QUARTER:
      return 'By Quarter';
    case OiFunction.DATE_BY_YEAR:
      return 'By Year';
    default:
      '';
  }
}
