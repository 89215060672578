import { connect } from 'react-redux';

import { IRootState } from 'store/rootReducer';
import { getFavoriteDashboards, getAllDashboards } from 'store/dashboards/actions';

import DashboardsFavouriteBar from './Favourite';

const mapStateToProps = ({ dashboards }: IRootState) => ({
  favDashboards: dashboards.favoriteDashboards,
  dashboardsList: dashboards.dashboardsList,
  isLoading: dashboards.loadingFav,
});

const mapDispatchToProps = () => {
  return {
    getFavoriteDashboards,
    getAllDashboards,
  };
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardsFavouriteBar);

export default Connected;
