import types from './actionTypes';
import { REQUEST, SUCCESS, FAILURE, ErrorMessage, Loader, SuccessMessage } from 'store/util';
import { getToken } from './selectors';

const initialState = {
  isCheckingAuth: true,
  isAuthenticated: false,
  authentication: {} as any,
};

export type AuthenticationState = Readonly<typeof initialState>;
export default (state: AuthenticationState = initialState, { type, payload, ...action }): AuthenticationState => {
  switch (type) {
    case REQUEST(types.LOGIN):
      return {
        ...state,
        [Loader(types.LOGIN)]: true,
      };
    case FAILURE(types.LOGIN): {
      let response = payload.response;
      return {
        ...initialState,
        [SuccessMessage(types.LOGIN)]: '',
        [ErrorMessage(types.LOGIN)]: (response && response.data.detail) || payload.message,
        [Loader(types.LOGIN)]: false,
        isAuthenticated: false,
      };
    }
    case SUCCESS(types.LOGIN): {
      return {
        ...state,
        [ErrorMessage(types.LOGIN)]: '',
        [SuccessMessage(types.LOGIN)]: 'success',
        [Loader(types.LOGIN)]: false,
        isAuthenticated: true,
        isCheckingAuth: false,
        authentication: payload.data,
      };
    }
    case SUCCESS(types.LOGOUT): {
      return {
        ...initialState,
        [ErrorMessage(types.LOGOUT)]: '',
        [SuccessMessage(types.LOGOUT)]: 'Bye bye',
        [Loader(types.LOGIN)]: false,
        isAuthenticated: false,
        authentication: {},
      };
    }
    case SUCCESS(types.CLEAR_AUTH): {
      return {
        ...state,
        [ErrorMessage(types.LOGIN)]: '',
        [SuccessMessage(types.LOGIN)]: 'Bye bye',
        [Loader(types.LOGIN)]: false,
        isAuthenticated: false,
        authentication: {},
      };
    }
    case REQUEST(types.FORGET_PASSWORD):
      return {
        ...state,
        [SuccessMessage(types.FORGET_PASSWORD)]: '',
        [Loader(types.FORGET_PASSWORD)]: true,
      };
    case FAILURE(types.FORGET_PASSWORD):
      return {
        ...state,
        [SuccessMessage(types.FORGET_PASSWORD)]: '',
        [Loader(types.FORGET_PASSWORD)]: false,
      };

    case SUCCESS(types.FORGET_PASSWORD):
      return {
        ...state,
        [SuccessMessage(types.FORGET_PASSWORD)]: 'Check email',
        [Loader(types.FORGET_PASSWORD)]: false,
      };

    case REQUEST(types.RESET_PASSWORD):
      return {
        ...state,
        [SuccessMessage(types.RESET_PASSWORD)]: '',
        [Loader(types.RESET_PASSWORD)]: true,
      };
    case FAILURE(types.RESET_PASSWORD):
      return {
        ...state,
        [SuccessMessage(types.RESET_PASSWORD)]: '',
        [Loader(types.RESET_PASSWORD)]: false,
      };

    case SUCCESS(types.RESET_PASSWORD):
      return {
        ...state,
        [SuccessMessage(types.RESET_PASSWORD)]: 'Password changed',
        [Loader(types.RESET_PASSWORD)]: false,
      };
    case types.SET_TOKEN:
      return {
        ...state,
        isAuthenticated: Boolean(payload.token),
      };
    case types.SET_CHECKING_AUTH:
      return {
        ...state,
        isCheckingAuth: payload.isCheckingAuth,
      };

    default:
      return state;
  }
};
