import { QueryFilterViewItem, QuestionStudioStoreState } from 'src/modules/questions/studio/models';
import { ComparisonOperatorIntMap, oiQueryFiltersSelectOptions } from 'src/shared/models/OiFilter';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import questionStudioServices, {
  FilterValuesJoinPattern,
  SerializedFilter,
  mapOiQueryDtoToQuestionFormViewModel,
  getColumnDateByYearFilterConfigs,
  getColumnDateByQuarterFilterConfigs,
  getColumnDateByMonthFilterConfigs,
  getColumnDateByDayFilterConfigs,
} from 'modules/questions/studio/questionStudioServices';
import { SQLColumn, OceanDataType, ColumnType } from 'src/shared/models/SqlColumn';
import apisService from 'src/shared/apis';
import { ExecuterResponseDto } from 'src/shared/models/ExecuterResultResponseDto';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { IPreviewResult } from 'src/shared/models/IPreviewResult';
import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import { IQuestionQueryPaginator, QUESTION_QUERY_PAGE_SIZE } from 'src/shared/models';
import { OiFunction } from 'src/shared/models/OiFunction';
import OiFunctionsList from 'src/shared/dataSources/OiFunctions';
import { OiQuestionQueryDtoFiltersType } from 'src/shared/models/OiQuestionQuery';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import { joinToArray } from 'src/shared/util';
export const QUESTION_MAX_LIMIT = 2147483647;

export const serializesFiltersToUrlParams = (filtersItems: QueryFilterViewItem[]) => {
  let filters = filtersItems.map(f => {
    return {
      opId: ComparisonOperatorIntMap.findIndex(o => o == f.filterOperator.operator),
      colId: f.column.id,
      values: f.values.join(FilterValuesJoinPattern),
      d: f.dynamic,
    } as SerializedFilter;
  });
  return encodeURIComponent(JSON.stringify(filters));
};

export const deSerializesFiltersToUrlParams = (serializedFilters: string, columns: SQLColumn[]) => {
  if (!serializedFilters) return [];
  const decodedSerializedFilters = decodeURIComponent(serializedFilters);
  const filters = JSON.parse(decodedSerializedFilters) || [];
  let filtersItems = filters.map(
    (filter: SerializedFilter): QueryFilterViewItem => {
      let opEnum = ComparisonOperatorIntMap[filter.opId];
      if (filter.values == '' && opEnum == ComparisonOperator.EQUAL) {
        opEnum = ComparisonOperator.IS_NULL_OR_EMPTY;
      }
      return {
        column: columns.find(c => c.id == filter.colId) as any,
        dynamic: filter.d,
        filterOperator: oiQueryFiltersSelectOptions.find(f => f.operator == opEnum) as any,
        values: filter.values.split(FilterValuesJoinPattern),
      };
    }
  );
  return filtersItems;
};

interface IDrilldownParams {
  clickedSegment: { name: string };
  // previewResult: IPreviewResult;
  column: ColumnMetadatum;
  // chartGroup: ColumnMetadatum;
  filtersItems: QueryFilterViewItem[];
  dbId: number;
  tableId: number;
}

const loadQuestionById = id => {
  return apisService.getQuestion(id).then(
    async dto => {
      const dbId = dto.query.datasourceID;
      const database = await apisService.getDatabaseById(dbId);
      const tables = await apisService.getDatabasePublicTables(dbId);

      const model = mapOiQueryDtoToQuestionFormViewModel(dto, tables, database);
      return {
        ok: true,
        database,
        tables,
        model,
      };
    },
    err => {
      // toastr.error(`Error Loading Question with id ${id}\n` + JSON.stringify(err.data));
      return Promise.reject({ ...err, ok: false });
    }
  );
};
function getNextGroupOPerator(func, dir) {
  if (dir == 'in' || dir == 'out') {
    switch (func) {
      case OiFunction.DATE_BY_YEAR: {
        return dir == 'in' ? OiFunction.DATE_BY_QUARTER : OiFunction.DATE_BY_YEAR;
      }
      case OiFunction.DATE_BY_QUARTER: {
        return dir == 'in' ? OiFunction.DATE_BY_MONTH : OiFunction.DATE_BY_YEAR; //[yearMoment.format(APP_LOCAL_DATE_FORMAT), yearMoment.add(1, 'y').format(APP_LOCAL_DATE_FORMAT)];
      }
      case OiFunction.DATE_BY_MONTH:
        return dir == 'in' ? OiFunction.DATE_BY_DAY : OiFunction.DATE_BY_QUARTER;
      case OiFunction.DATE_BY_DAY:
        return dir == 'in' ? OiFunction.DATE_BY_DAY : OiFunction.DATE_BY_MONTH;
    }
  } else {
    switch (dir.toLowerCase()) {
      case 'to_year':
        return OiFunction.DATE_BY_YEAR;
      case 'to_quarter':
        return OiFunction.DATE_BY_QUARTER;
      case 'to_month':
        return OiFunction.DATE_BY_MONTH;
      case 'to_day':
        return OiFunction.DATE_BY_DAY;
    }
  }
}
function mapGroupingFunctionToFilterValues(func, value) {
  switch (func) {
    case OiFunction.DATE_BY_YEAR: {
      return getColumnDateByYearFilterConfigs(value);
    }
    case OiFunction.DATE_BY_QUARTER: {
      return getColumnDateByQuarterFilterConfigs(value); //[yearMoment.format(APP_LOCAL_DATE_FORMAT), yearMoment.add(1, 'y').format(APP_LOCAL_DATE_FORMAT)];
    }
    case OiFunction.DATE_BY_MONTH:
      return getColumnDateByMonthFilterConfigs(value);
    case OiFunction.DATE_BY_DAY:
      return getColumnDateByDayFilterConfigs(value);
  }
}

function getZoomInFilters(column, columnFunction, value) {
  const filterItem: any = mapGroupingFunctionToFilterValues(columnFunction, value);
  return [
    {
      column: column,
      dynamic: true,
      zoomingFilter: true,
      filterOperator: oiQueryFiltersSelectOptions.find(c => c.operator == ComparisonOperator.GREATER_THAN_EQUAL),
      values: filterItem.values.slice(0),
    },
    {
      column: column,
      dynamic: true,
      zoomingFilter: true,
      filterOperator: oiQueryFiltersSelectOptions.find(c => c.operator == ComparisonOperator.LESS_THAN),
      values: filterItem.values.slice(1),
    },
  ];
}

function getZoomOutFilters(column, columnFunction, value, groupingOperator) {
  let filterItem: any = mapGroupingFunctionToFilterValues(columnFunction, value);

  switch (groupingOperator) {
    case OiFunction.DATE_BY_YEAR: {
      return [];
    }
    case OiFunction.DATE_BY_QUARTER: {
      filterItem = getColumnDateByYearFilterConfigs(value);
      break;
    }
    case OiFunction.DATE_BY_MONTH: {
      filterItem = getColumnDateByQuarterFilterConfigs(value);
      break;
    }
    case OiFunction.DATE_BY_DAY: {
      filterItem = getColumnDateByMonthFilterConfigs(value);
      break;
    }
    default:
      return [];
  }
  const filters = [
    {
      column: column,
      dynamic: true,
      zoomingFilter: true,
      filterOperator: oiQueryFiltersSelectOptions.find(c => c.operator == ComparisonOperator.GREATER_THAN_EQUAL),
      values: filterItem.values.slice(0),
    },
    {
      column: column,
      dynamic: true,
      zoomingFilter: true,
      filterOperator: oiQueryFiltersSelectOptions.find(c => c.operator == ComparisonOperator.LESS_THAN),
      values: filterItem.values.slice(1),
    },
  ];
  return filters;
}

export function getQuestionZoomingBatch(clickedSegment: any, columnMeta: any, direction: any, column: SQLColumn, questionModel) {
  let value = clickedSegment.name;
  let columnFunction = (columnMeta as any).expression[0].function;
  const filters = questionModel.filtersItems.filter(f => !f.zoomingFilter);
  const groupItems = questionModel.groupItems.slice();
  const groupItem = groupItems.find(c => c.vid == columnMeta.vid)!;
  groupItem.groupingOperator = getNextGroupOPerator(columnFunction, direction);
  const filterItem: any = mapGroupingFunctionToFilterValues(columnFunction, value);

  if (direction == 'in') {
    filters.push(...getZoomInFilters(column, columnFunction, value));
  } else {
    filters.push(...(getZoomOutFilters(column, columnFunction, value, groupItem.groupingOperator) as any));
    // out
    // no need to add filters !
  }
  return { filters, groupItems };
}

export const fixFiltersDtoMissingGlue = (filters: OiQuestionQueryDtoFiltersType = []) => {
  const hasLogicalGlue = filters.some(f => f === 'and' || f === 'or');
  if (filters.length > 1 && !hasLogicalGlue) {
    return joinToArray(filters, 'and');
  }
  return filters;
};
export const fixQueryFiltersNoLogicalGlue = questionQuery => {
  return (questionQuery = {
    ...questionQuery,
    filters: fixFiltersDtoMissingGlue(questionQuery.filters),
  });
};
const previewQuestion = (model: QuestionStudioStoreState, pagination: IQuestionQueryPaginator) => {
  const toBeSubmittedModel = questionStudioServices.convertQuestionVMToDto(model);
  let body: any = fixQueryFiltersNoLogicalGlue(toBeSubmittedModel.query);
  return apisService.previewQuestion(body, pagination).then(previewResult => {
    previewResult.viewType = (model.viewTypeOption && model.viewTypeOption.type) || VIEW_TYPE.TABLE;
    let table: any = parseExecuterResultDataToTableData(previewResult);
    let chart: any = { data: parseExecuterResultDataToChartData(previewResult) };
    return {
      ...previewResult,
      title: model.name,
      table,
      chart,
    };
  });
};

export const questionModelHasRawData = model => model && model.viewsItems.some(c => c.functionItem!.value == OiFunction.NONE);

export const questionModelHasGrouping = (model: QuestionStudioStoreState) => model && model.groupItems.length > 0;

export const getQueryPageLength = limit => (limit ? Math.min(Number(limit), QUESTION_QUERY_PAGE_SIZE) : QUESTION_QUERY_PAGE_SIZE);

const countQueryResult = (questionQuery: OiQuestionDto['query']) => {
  questionQuery = fixQueryFiltersNoLogicalGlue(questionQuery);
  return apisService.countQueryResult(questionQuery).then(data => Math.min(questionQuery.resultLimit, Number((data as any).resultCount)));
};

const shouldCallQueryRowsCountApi = (questionModel: QuestionStudioStoreState) => {
  return questionModelHasRawData(questionModel) || questionModelHasGrouping(questionModel);
};

const convertRowsCountToPaging = (totalRowsCount: number | string): IPreviewResult => {
  return {
    totalCount: totalRowsCount,
    totalPages: Math.ceil(parseInt('' + totalRowsCount) / QUESTION_QUERY_PAGE_SIZE),
  } as IPreviewResult;
};

const getMassiveQueryPagination = (questionModel: QuestionStudioStoreState, filters?: OiQuestionQueryDtoFiltersType) => {
  if (shouldCallQueryRowsCountApi(questionModel)) {
    const questionQuery: OiQuestionDto['query'] = questionStudioServices.convertQuestionVMToDto(questionModel).query;
    questionQuery.filters = filters ? filters : questionQuery.filters;
    return countQueryResult(questionQuery).then(convertRowsCountToPaging);
  } else {
    return Promise.resolve(convertRowsCountToPaging(questionModel.limit));
  }
};
//=============================================================//

export function parseExecuterResultDataToTableData(executerResults: ExecuterResponseDto) {
  let table: any = {};
  table.columns = (executerResults.metadata || []).map((col, i) => ({
    ...col,
    id: col.title,
    label: col.label,
    title: col.label,
    field: col.title,
    index: i,
  }));

  table.data = executerResults.resultset.map(dataRow =>
    executerResults.metadata.reduce((agg, col, index) => {
      agg[col.label] = dataRow[index];
      return agg;
    }, {})
  );
  return table;
}

export function parseExecuterResultDataToChartData(executerResults: ExecuterResponseDto) {
  let columns = (executerResults.metadata || []).map(col => col.label);
  return [columns, ...executerResults.resultset];
}

export function getQueryChunkPreviewResultBatch(
  newPreviewResult: IPreviewResult,
  previousPreviewResult: IPreviewResult,
  questionModel: QuestionStudioStoreState
) {
  const queryLimit = Number(questionModel.limit);
  let table: any = parseExecuterResultDataToTableData(newPreviewResult);
  let chart: any = { data: ([] as any[]).concat(previousPreviewResult.chart.data, parseExecuterResultDataToChartData(newPreviewResult)) };
  table = {
    columns: [].concat(previousPreviewResult.table.columns, table.columns),
    data: [].concat(previousPreviewResult.table.data, table.data),
  };
  table.data = table.data.slice(0, queryLimit);
  chart.data = chart.data.slice(0, queryLimit);
  const resultset = previousPreviewResult.resultset.concat(newPreviewResult.resultset).slice(0, queryLimit);
  return {
    table,
    chart,
    resultset,
    totalCount: previousPreviewResult.totalCount,
    totalPages: previousPreviewResult.totalPages,
  };
}

const questionsServices = {
  getQueryPageLength,
  countQueryResult,
  loadQuestionById,
  previewQuestion,
  getQueryChunkPreviewResultBatch,
  getMassiveQueryPagination,
  serializesFiltersToUrlParams,
  deSerializesFiltersToUrlParams,
};

export type IQuestionsServices = typeof questionsServices;
export default questionsServices;
