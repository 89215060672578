import types from './actionTypes';

export const fetchColumnsMap = () => ({
  type: types.FETCH_COLUMNS_MAP,
  payload: {},
});

export const setColumnsMap = columnsMap => ({
  type: types.SET_COLUMNS_MAP,
  payload: columnsMap,
});
