import axios from 'axios';
import * as types from './actionTypes';
import { REQUEST } from '../util';
import { discoveryUrl } from 'src/shared/apis/baseUrls';
// Actions
export const getDatabase = id => async dispatch => {
  return await dispatch({
    type: types.GET_DB,
    payload: axios.get(discoveryUrl + `/databases/${id}`),
  });
};
export const getAllDatabases = () => async dispatch => {
  return await dispatch({
    type: types.GET_DB,
    payload: axios.get(discoveryUrl + `/databases`),
  });
};
export const deleteDatabase = id => async dispatch => {
  return await dispatch({
    type: types.DELETE_DB,
    payload: axios.delete(discoveryUrl + `/databases/${id}`),
  });
};
export const createDb = body => async dispatch => {
  return await dispatch({
    type: types.CREATE_DB,
    payload: axios.post(discoveryUrl + '/databases', body),
  });
};

export const shareDatabase = ({ usernames, dbIds }) => ({
  type: REQUEST(types.SHARE_DB),
  payload: { usernames, dbIds },
});

export const shareDBTable = ({ usernames, tableIds }) => ({
  type: REQUEST(types.SHARE_DB),
  payload: { usernames, tableIds },
});
