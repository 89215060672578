import React from 'react';
import Button from '@material-ui/core/Button';
import Dropdown from '@material-ui/icons/ArrowDropDown';
export const PopupTriggerButton = ({ onClick }) => (
  <Button
    style={{ padding: '5px 15px', paddingRight: 5 }}
    color='secondary'
    className='add-question-btn btn-rounded'
    variant={'contained'}
    onClick={onClick}
  >
    Add Questions <Dropdown />
  </Button>
);
