import { StyleRulesCallback, Theme, createStyles, withStyles } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    StepWrapper: {
      maxHeight: 400,
      width: 350,
      overflow: 'hidden',
    },
    StepHeader: {
      borderBottom: '1px solid #e3e3e3',
      padding: 10,
      display: 'flex',
      alignContent: 'center',
      color: '#048edd',
      '& svg': {
        color: '#666b6f',
        cursor: 'pointer',
      },
    },
    StepHeaderSvg: {
      color: '#666b6f',
      cursor: 'pointer',
    },
  });

export default withStyles(styles);
