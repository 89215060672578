import { OiFunction } from '../models/OiFunction';
import { OiViewFunctionOption } from '../models/OiViewFunctionOption';
import { OceanDataType } from '../models/SqlColumn';

export const dateGroupingFunctions: OiViewFunctionOption[] = [
  {
    value: OiFunction.DATE_BY_DAY,
    displayName: 'By Day',
  },
  {
    value: OiFunction.DATE_BY_MONTH,
    displayName: 'By Month',
  },
  {
    value: OiFunction.DATE_BY_QUARTER,
    displayName: 'By Quarter',
  },
  {
    value: OiFunction.DATE_BY_YEAR,
    displayName: 'By Year',
  },
];
export const OiAggOperators: OiViewFunctionOption[] = [
  {
    displayName: 'Min',
    value: OiFunction.MIN,
    measuresOnly: true,
  },
  {
    displayName: 'Max',
    value: OiFunction.MAX,
    measuresOnly: true,
  },
  {
    displayName: 'Avg',
    value: OiFunction.AVG,
    measuresOnly: true,
  },
  {
    displayName: 'Sum',
    value: OiFunction.SUM,
    measuresOnly: true,
  },
];
const OiFunctionsList: OiViewFunctionOption[] = [
  {
    displayName: 'Rows Count',
    value: OiFunction.ROW_COUNT,
    isSingleStep: true,
  },
  {
    displayName: 'Unique Values',
    value: OiFunction.UNIQUE_VALUES,
  },
  {
    displayName: 'Unique Count',
    value: OiFunction.UNIQUE_COUNT,
  },
  ...OiAggOperators,
  {
    displayName: 'Standard Deviation',
    value: OiFunction.STD_DEV,
    measuresOnly: true,
  },
  {
    displayName: 'Date Diff',
    value: OiFunction.DATE_DIFF,
    columnsOceanDataTypes: [OceanDataType.DATE],
    measuresOnly: false,
    mustPickTwoColumns: true,
  },
];

export const allGroupingOperatorsWithDate = dateGroupingFunctions.concat(OiFunctionsList);
export const oiViewOperatorsWithNone = OiFunctionsList.concat({
  displayName: 'NONE',
  value: OiFunction.NONE,
});
export default OiFunctionsList;
