export const getFilteredQuestions = (questions, search) => {
  const _search = (search || '').toLowerCase().trim();
  return !_search
    ? questions
    : questions.filter((q: any) => {
        const name = q.displayName ? q.displayName.toLowerCase() : q.name.toLowerCase();
        return name.includes(_search) || q.tag.toLowerCase().includes(_search);
      });
};

export const isFavoriteQuestion = (favQuestionsList, q) => (favQuestionsList || []).find(fq => fq.id === q.id);
