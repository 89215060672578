import QuestionsList from './QuestionsList';
import { connect } from 'react-redux';
import { IRootState } from 'src/store/rootReducer';
import { getFavoriteQuestions, getAllQuestions, deleteQuestion } from 'src/store/questions/questions.actions';
import { getPermissions, getRoles } from 'src/store/account/selectors';
import { openConfirmationPopup } from 'store/confirmationPopup';

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;
// export default QuestionsList;
const mapStateToProps = (state: IRootState) => {
  const { questions }: IRootState = state;
  return {
    favQuestions: questions.favoriteQuestions,
    questionsList: questions.questionsList,
    userPermissions: getPermissions(state),
    userRoles: getRoles(state),
    isLoading: questions.loading,
  };
};

const mapDispatchToProps = {
  confirm: openConfirmationPopup,
  getFavoriteQuestions,
  deleteQuestion,
  getAllQuestions,
};

const QuestionsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionsList as any);
export default QuestionsListContainer;
