import * as React from 'react';
import * as PropTypes from 'prop-types';

import { GridBase } from './base/grid-base';
import { Root } from './components/templates/layout';

export const OiTableGrid = ({ children, ...props }) => (
  <GridBase rootComponent={Root} {...props}>
    {children}
  </GridBase>
);

OiTableGrid.Root = Root;

OiTableGrid.propTypes = {
  children: PropTypes.node.isRequired,
};
