/* globals window:true */
import { EventEmitter } from '../misc/event-emitter';

let eventEmitter = null;
export const getSharedEventEmitter = () => {
  if (!eventEmitter) {
    eventEmitter = new EventEmitter();

    ['mousemove', 'mouseup', 'touchmove', 'touchend', 'touchcancel'].forEach(name =>
      window.addEventListener(name, e => eventEmitter.emit([name, e]), { passive: false })
    );
  }
  return eventEmitter;
};
