import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import { forgetPassword } from 'src/store/authentication/actions';
import ForgetPassword from './ForgetPassword';
import types from 'src/store/authentication/actionTypes';
import { getLoader, getSuccess } from 'src/store/authentication/selectors';

const mapStateToProps = (state: IRootState) => ({
  loading: getLoader(state, types.FORGET_PASSWORD),
  success: getSuccess(state, types.FORGET_PASSWORD),
});

const mapDispatchToProps = dispatch => {
  return { forgetPassword: username => dispatch(forgetPassword(username)) };
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPassword);
