import * as React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Radio, RadioGroup, FormControlLabel, withStyles, Tooltip, FormGroup, Checkbox } from '@material-ui/core';
import { SORT_DIRECTION } from 'src/shared/models';
import { QueryViewItem } from 'src/modules/questions/studio/models';
import OiIconButton from 'src/components/OiIconButton';
import toastr from 'components/toastr';
import { debounce } from '../../utils';
import AddOiViewWizard from '../../SideToolBar/AddOiViewWizard/AddOiViewWizard';
import { QuestionViewProps, IState } from './models';
import Styled from './Styled';
import HeadingItem from './HeadingItem';
import { OiFunction } from 'src/shared/models/OiFunction';

class QuestionViewItem extends React.Component<QuestionViewProps, IState> {
  state: IState = {
    expanded: false,
    model: {
      pin: false,
      grouped: false,
    } as QueryViewItem,
  };
  lastItem: any = null;
  isWatingDebounce = false;
  componentWillMount() {
    this.setState({ model: Object.assign({ pin: false, grouped: false }, this.props.item) });
    const stringy = JSON.stringify(this.props.item);
    this.lastItem = '' + stringy;
  }
  componentDidUpdate() {
    const stringy = JSON.stringify(this.props.item);
    if (this.lastItem && this.lastItem !== stringy) {
      this.lastItem = '' + stringy;
      this.setState({ model: this.props.item });
    }
  }
  handleSortRadioChanged = e => {
    let model = Object.assign({}, this.state.model, { orderDirection: e.target.value });
    this.triggerChange({ orderDirection: e.target.value } as QueryViewItem);
  };

  handleLabelChanged = e => {
    this.triggerChange({ displayName: e } as QueryViewItem);
  };

  onPinChanged = (e?) => {
    let nextValue = e ? e.target.checked : !this.state.model.pin;
    if (this.state.model.grouped && nextValue) {
      toastr.warn("Can't pin grouped column.");
      this.triggerChange({ pin: false });
    } else {
      this.triggerChange({ pin: nextValue });
    }
    e && e.preventDefault();
    e && e.stopPropagation();
    return false;
  };

  onToggleGroupByColumn = (e?) => {
    let nextValue = e ? e.target.checked : !this.state.model.grouped;
    if (this.state.model.pin && nextValue) {
      toastr.warn("Can't group pinned column.");
      this.triggerChange({ grouped: false });
    } else {
      this.triggerChange({ grouped: nextValue });
    }
    e && e.preventDefault();
    e && e.stopPropagation();
    return false;
  };

  triggerChange = (changes: any) => {
    const result = Object.assign({}, this.state.model, changes);
    this.isWatingDebounce = true;
    this.setState({ model: result }, () => {
      this.debouncedOnUpdate(result);
    });
  };

  debouncedOnUpdate: any = debounce(
    result => {
      this.isWatingDebounce = false;
      const { onUpdate } = this.props;
      onUpdate && onUpdate(this.state.model as QueryViewItem);
    },
    250,
    false
  );

  toggleExpansion = e => this.setState({ expanded: !this.state.expanded });

  render() {
    const { expanded } = this.state;
    const model = this.state.model;
    const classes = this.props.classes;
    let displayName = model.displayName;
    if (!this.isWatingDebounce) {
      const isNoneFucntion = model.functionItem && model.functionItem.value == OiFunction.NONE;
      let functionName = isNoneFucntion ? '' : model.functionItem && model.functionItem.displayName;
      let colName = model.column && model.column.displayName;
      // if (!displayName) {
      //   displayName = functionName ? `${functionName} (${colName})` : colName;
      // }
    }

    return (
      <div className={classes.wrapper}>
        <HeadingItem
          toggleExpansion={this.toggleExpansion}
          onPinChanged={this.onPinChanged}
          onToggleGroupByColumn={this.onToggleGroupByColumn}
          handleLabelChanged={this.handleLabelChanged}
          onDelete={this.props.onDelete}
          expanded={expanded}
          model={model}
          headingItem={classes.headingItem}
          displayName={displayName}
        />
        {expanded && (
          <div className={classes.editorWrapper}>
            <div>
              <div className={classes.normalizedLabel}>Show</div>
              <AddOiViewWizard
                tables={this.props.tables || []}
                oiFunctionsList={this.props.functionsList}
                onDone={this.triggerChange}
                buttonComponent={
                  <div className={classes.wizardButtonWrap}>
                    {((model.functionItem && model.functionItem.displayName) || '').toLowerCase()}{' '}
                    {((model.column && model.column.displayName) || '').toLowerCase()}
                    <OiIconButton className={classes.caretDown}>
                      <ExpandMore />
                    </OiIconButton>
                  </div>
                }
              />
            </div>
            <RadioGroup
              aria-label='sort'
              name='sort'
              className={classes.radioGroup}
              value={model.orderDirection}
              onChange={this.handleSortRadioChanged}
            >
              <FormControlLabel value={SORT_DIRECTION.NONE} control={<Radio color={'primary'} />} label='No sort' />
              <FormControlLabel value={SORT_DIRECTION.ASC} control={<Radio color={'primary'} />} label='Asc' />
              <FormControlLabel value={SORT_DIRECTION.DESC} control={<Radio color={'primary'} />} label='Desc' />
            </RadioGroup>
          </div>
        )}
      </div>
    );
  }
}
export default Styled(QuestionViewItem);
