import * as React from 'react';

import { Popover, MenuItem, Checkbox } from '@material-ui/core';
import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import Settings from '@material-ui/icons/Settings';

import { WIDGET_SETTINGS_ITEM, ChartCustomizationOptions } from '../QuestionPreviewer/models';
import { chartSettingsOptions } from './chartSettingsOptions';
import TooltipedIconButton from '../TooltipedIconButton';

type IProps = {
  onChange?: (v: { [key: string]: any }) => any;
  viewType?: VIEW_TYPE;
  chartOptions: ChartCustomizationOptions;
  // widgetSettingsOptions?: WidgetSettingsItem[];
};
export default class ViewOptionsMenu extends React.Component<IProps> {
  state = {
    anchorEl: undefined,
    isOpen: false,
  };

  onChange = (v: { [key: string]: any }) => {
    this.props.onChange && this.props.onChange(v);
  };

  onToggleOption = option => () => {
    if (option.id == WIDGET_SETTINGS_ITEM.PERCENTAGE_BAR) {
      this.onChange({
        [option.fieldName]: !option.value,
        stack: true,
        barLabel: true,
        maxPoint: false,
        minPoint: false,
        avgPoint: false,
      });
    } else if (option.id == WIDGET_SETTINGS_ITEM.STACK) {
      this.onChange({ [option.fieldName]: !option.value, maxPoint: false, minPoint: false, avgPoint: false });
    } else {
      this.onChange({ [option.fieldName]: !option.value });
    }
  };

  openPopover = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closePopover = () => {
    this.setState({ anchorEl: undefined });
  };

  render() {
    const { chartOptions, viewType } = this.props;
    const listItems = (chartSettingsOptions || [])
      .filter(t => !t.appliesFor || t.appliesFor.includes(viewType))
      .map(o => Object.assign({}, o, { value: chartOptions[o.fieldName] }));
    return (
      <>
        <TooltipedIconButton
          tooltip='Change view settings'
          aria-label='type'
          aria-owns='type-menu'
          aria-haspopup='true'
          onClick={this.openPopover}
        >
          <Settings />
        </TooltipedIconButton>
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={Boolean(this.state.anchorEl)}
          onBackdropClick={this.closePopover}
          anchorEl={this.state.anchorEl}
        >
          <>
            {!listItems.length ? (
              <p onClick={this.closePopover}> Sorry! no settings for this view type </p>
            ) : (
              listItems.map(option =>
                option.displayType == 'TOGGLE' ? (
                  <MenuItem key={option.id} onClick={this.onToggleOption(option)}>
                    <Checkbox color='primary' disabled={true} checked={Boolean(chartOptions[option.fieldName])} />
                    {option.displayName}
                  </MenuItem>
                ) : (
                  <MenuItem key={option.id}>
                    <option.Render onChange={this.onChange} optionItem={option} style={{ width: '100%', height: '100%' }} />
                  </MenuItem>
                )
              )
            )}
          </>
        </Popover>
      </>
    );
  }
}
