import initStore from './store/store';

export const store = initStore();

export const APP_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-M-DTh:mm';

export const ToastMessages = {
  unAuthorized: ' Sorry, You Are not Authorized to do this action.',
};
export default {
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
};
