import * as React from 'react';
import {
  mapOiQueryDtoToQuestionFormViewModel,
  mapQuestionFilterItemToOiQueryFilter,
  mapViewFiltersToDtoFilters,
} from 'modules/questions/studio/questionStudioServices';
import apisService from 'src/shared/apis';
import { getMetaColumnNameInDb } from 'src/shared/models/ExecuterResultResponseDto';
import QuestionPreviewer, { QuestionViewMode } from 'src/components/QuestionPreviewer/QuestionPreviewer';
import { ExportQuestionDto } from 'src/shared/models/Exporter';
import { QUESTION_DRILL_DOWN_OPERATION } from 'src/components/QuestionPreviewer/models';
import { objToQParams } from 'src/shared/util';
import toastr from 'components/toastr';
import { OiFunction } from 'src/shared/models/OiFunction';
import DeletedQuestionCard from '../QuestionPreviewer/DeletedQuestionCard';
import questionsServices, {
  getQuestionZoomingBatch,
  getQueryPageLength,
  parseExecuterResultDataToTableData,
  serializesFiltersToUrlParams,
  parseExecuterResultDataToChartData,
  fixFiltersDtoMissingGlue,
} from 'modules/questions/questions.services';
import { viewTypesOptionsList, VIEW_TYPE } from '../QuestionPreviewer/questionViewTypes';
import { QUESTION_QUERY_PAGE_SIZE } from 'src/shared/models';
import { IProps, IState } from './models';
import { OiFilterDto } from 'src/shared/models/OiFilterDto';
export default class QuestionPreviewerByQuestionId extends React.Component<IProps, IState> {
  state = {
    toBeAppliedFiltersDtos: [],
    lastQueryPage: 1,
  } as any;
  databaseId: any = null;
  tableId: any = null;
  get filtersDto() {
    return (this.state.questionModel!.filtersItems || []).slice().map(mapQuestionFilterItemToOiQueryFilter);
  }

  componentWillMount() {
    this.setState({ lastQueryPage: 1 });
    if (this.props.questionId) {
      this.onExecuteQuestion();
    } else {
      toastr.warn('Question has no id, question previewer');
      this.setState({ questionDeleted: true });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResizedddd);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResizedddd);
  }

  onWindowResizedddd = e => {
    this.setState({});
    this.props.callResize && this.props.callResize();
  };

  componentDidUpdate(prevProps) {
    const { initialViewOptions } = this.props;
    if (initialViewOptions && initialViewOptions !== prevProps.initialViewOptions) {
      this.setState(state => ({
        viewTypeOption: { ...state.viewTypeOption, ...initialViewOptions },
        chartOptions: { ...state.chartOptions, ...initialViewOptions },
      }));
    }
  }
  onTablePaginationChanged = () => {};

  onExecuteQuestion = async (filters: any = []) => {
    this.setState({ isLoadingPreview: true });

    let mayBeQuestionDto = await apisService.getQuestion(this.props.questionId).catch(e => {
      this.setState({ questionDeleted: true });
      return null;
    });
    this.props.dispatchGetQuestionById && this.props.dispatchGetQuestionById(this.props.questionId);

    if (!mayBeQuestionDto || mayBeQuestionDto.deleted) {
      this.setState({ questionDeleted: true });
      return;
    }
    let questionDto = mayBeQuestionDto!;

    let dbId = questionDto.query.datasourceID;
    this.databaseId = dbId;
    const db = {
      id: questionDto.query.datasourceID,
      dbName: questionDto.query.datasource,
      displayName: questionDto.query.datasource,
    } as any;
    let tables = await apisService.getDatabasePublicTables(dbId);
    let baseTable = tables.find(t => t.name == questionDto.query.dbTable);
    let questionModel = mapOiQueryDtoToQuestionFormViewModel(questionDto, tables, db);
    this.tableId = baseTable && baseTable.id;
    questionModel.baseTable = baseTable!;
    const explicitFilters: any = (questionModel.filtersItems || []).filter(f => !f.dynamic);
    const didUserOverrideTheFilter = f => filters && filters.find(df => df.dbColumn === f.dbColumn);
    const otherDynamicFilters: any = (questionModel.filtersItems || []).filter(f => f.dynamic && !didUserOverrideTheFilter(f));

    let allFilters = [...explicitFilters, ...otherDynamicFilters, ...filters].map(mapQuestionFilterItemToOiQueryFilter);
    allFilters = fixFiltersDtoMissingGlue(allFilters);
    this.setState({ toBeAppliedFiltersDtos: allFilters });
    let executeOptions = {
      pageLength: Math.min(QUESTION_QUERY_PAGE_SIZE, Number(questionModel.limit) || Number.MAX_SAFE_INTEGER),
      pageNumber: 1,
      dynamicFilters: allFilters,
    };

    let promises: any[] = [];
    let paginationPromise = questionsServices.getMassiveQueryPagination(questionModel, allFilters);
    promises.push(paginationPromise);
    const executePromise = apisService.executeQuestion(this.props.questionId, executeOptions).then(
      previewResult => {
        let table: any = parseExecuterResultDataToTableData(previewResult);
        let chart: any = { data: parseExecuterResultDataToChartData(previewResult) };

        let chartOptions = questionDto.viewTypeOptions ? JSON.parse(questionDto.viewTypeOptions) : {};
        chartOptions = Object.assign(chartOptions, this.props.initialViewOptions || {});
        let viewType = chartOptions.viewType || previewResult.viewType || VIEW_TYPE.TABLE;
        let viewTypeItem = viewTypesOptionsList.find(o => o.type == viewType);
        const _previewResult = { ...previewResult, table, chart, viewType: chartOptions.viewType };
        this.setState({
          previewResult: _previewResult,
          viewTypeOption: viewTypeItem!,
          isLoadingPreview: false,
          chartOptions: chartOptions,
          questionDto,
          questionModel: questionModel,
        });
        return _previewResult;
      },
      err => {
        // todo toaster
        // this.setState({ previewResult: err, isLoadingPreview: false });
      }
    );

    promises.push(executePromise);
    Promise.all(promises).then(([pagingPreviewResult, previewResult]) => {
      this.setState({
        previewResult: Object.assign({}, previewResult, pagingPreviewResult),
      });
    });
  };

  onUpdateQuestionPreview() {
    const questionModel = this.state.questionModel;
    this.setState({ isLoadingPreview: true, lastQueryPage: 1 });
    questionsServices
      .previewQuestion(questionModel, { pageNumber: 1, pageLength: questionsServices.getQueryPageLength(questionModel.lmit) })
      .then(
        previewResult => {
          let table: any = parseExecuterResultDataToTableData(previewResult);
          let chart: any = { data: parseExecuterResultDataToChartData(previewResult) };

          this.setState({
            previewResult: { ...previewResult, table, chart },
            isLoadingPreview: false,
          });
        },
        error => {
          this.setState({ isLoadingPreview: false });
        }
      );
  }

  executeWithDynamicFilters = (globalsDynamicFilters: OiFilterDto[], isDashboard = false) => {
    this.setState({ isLoadingPreview: true, lastQueryPage: 1 });
    const stateFilters = this.filtersDto;
    let toBeAppliedFiltersDtos: OiFilterDto[] = stateFilters;

    const getFilterOverride = filter => {
      return globalsDynamicFilters.find(f => {
        const isSameColumn = f.dbColumn === filter.dbColumn;
        const isSameDbTable = f.dbTable === filter.dbTable;
        const isSameFunctionType = f.functionType === filter.functionType;
        return !filter.zoomingFilter && filter.dynamic && isSameColumn && isSameDbTable && isSameFunctionType;
      });
    };
    if (isDashboard) {
      //1. override all dynamics with global dynamics if exists
      toBeAppliedFiltersDtos = toBeAppliedFiltersDtos.map(f => getFilterOverride(f) || f);
    } else {
      toBeAppliedFiltersDtos = stateFilters;
    }
    toBeAppliedFiltersDtos = fixFiltersDtoMissingGlue(toBeAppliedFiltersDtos);
    this.setState({ toBeAppliedFiltersDtos: toBeAppliedFiltersDtos });

    let promises: any[] = [];
    if (Number(this.state.questionModel.limit) > QUESTION_QUERY_PAGE_SIZE) {
      let paginationPromise = questionsServices.getMassiveQueryPagination(this.state.questionModel, toBeAppliedFiltersDtos);
      promises.push(paginationPromise);
    }
    let executeOptions = {
      pageNumber: 1,
      pageLength: Math.min(QUESTION_QUERY_PAGE_SIZE, Number(this.state.questionModel.limit) || Number.MAX_SAFE_INTEGER),
      dynamicFilters: toBeAppliedFiltersDtos,
    };
    const executePromise = apisService.executeQuestion(this.props.questionId, executeOptions).then(
      previewResult => {
        let table: any = parseExecuterResultDataToTableData(previewResult);
        let chart: any = { data: parseExecuterResultDataToChartData(previewResult) };
        const _previewResult = { ...previewResult, table, chart };

        this.setState({
          previewResult: _previewResult,
          isLoadingPreview: false,
        });
        return _previewResult;
      },
      err => {
        this.setState({ previewResult: err, isLoadingPreview: false });
      }
    );

    promises.push(executePromise);
    Promise.all(promises).then(([pagingPreviewResult, previewResult]) => {
      this.setState({
        previewResult: { ...previewResult, ...pagingPreviewResult },
      });
    });
  };

  loadNextQueryChunk = () => {
    const nextPage = this.state.lastQueryPage + 1;
    this.setState({
      lastQueryPage: nextPage,
      isLoadingQueryNextChunk: true,
    });

    let filtersDto = this.state.toBeAppliedFiltersDtos;
    const queryLimit = Number(this.state.questionModel.limit);
    let executeOptions = {
      pageNumber: nextPage,
      pageLength: getQueryPageLength(queryLimit),
      dynamicFilters: filtersDto,
    };

    apisService.executeQuestion(this.props.questionId, executeOptions).then(
      newPreviewResult => {
        const statePreviewResult = this.state.previewResult;
        let _previewResultBatch = questionsServices.getQueryChunkPreviewResultBatch(
          newPreviewResult,
          statePreviewResult,
          this.state.questionModel
        );
        this.setState({
          isLoadingQueryNextChunk: false,
          previewResult: {
            ...newPreviewResult,
            ..._previewResultBatch,
          },
        });
      },
      err => {
        this.setState({ previewResult: err, isLoadingPreview: false });
      }
    );
  };

  onDoChartDrillDown = ({ clickedSegment, previewResult, chartGroup }) => {
    let dbId = this.databaseId;
    let tableId = this.tableId;
    let column = previewResult.metadata[0];
    if (chartGroup && chartGroup.name) {
      column = previewResult.metadata.find(c => getMetaColumnNameInDb(c) == chartGroup.name);
    }
    let value = clickedSegment.name;
    let questionModel = this.state.questionModel!;
    let params = {
      operation: QUESTION_DRILL_DOWN_OPERATION.DRILL_DOWN,
      dbId,
      tableId,
      columnName: getMetaColumnNameInDb(column),
      columnValue: value,
      columnId: column.id,
      columnFunction: column.functionType,
      filterOut: false,
      filters: serializesFiltersToUrlParams(questionModel.filtersItems),
    };
    let url = location.origin + `/questions/new?${objToQParams(params)}`;
    window.open(url, '' + Date());
  };

  onDoSegmentFilterOut = ({ clickedSegment, previewResult, chartGroup }) => {
    let dbId = this.databaseId;
    let tableId = this.tableId;
    let column = previewResult.metadata[0];
    if (chartGroup && chartGroup.name) {
      column = previewResult.metadata.find(c => getMetaColumnNameInDb(c) == chartGroup.name);
    }
    let value = clickedSegment.name;
    let questionModel = this.state.questionModel!;
    let params = {
      dbId,
      tableId,
      columnName: getMetaColumnNameInDb(column),
      columnValue: value,
      columnId: column.id,
      columnFunction: column.functionType,
      filterOut: true,
      filters: serializesFiltersToUrlParams(questionModel.filtersItems),
    };
    let url = location.origin + `/questions/new?${objToQParams(params)}`;
    window.open(url, '' + Date());
  };

  onDoSegmentZoom = ({ direction, clickedSegment, previewResult, chartGroup }) => {
    let columnMeta = clickedSegment.currentGroup || previewResult.metadata[clickedSegment.seriesIndex];
    const column = this.state.questionModel.baseTable.columns.find(c => getMetaColumnNameInDb(columnMeta) == c.dbName);
    const { filters, groupItems } = getQuestionZoomingBatch(clickedSegment, columnMeta, direction, column!, this.state.questionModel);
    this.setState(
      s => ({
        questionModel: {
          ...s.questionModel,
          filtersItems: filters,
          groupItems: groupItems,
          mayNeedRefresh: true,
        } as any,
      }),
      () => {
        this.onUpdateQuestionPreview();
      }
    );
  };

  onExportQuestion = options => {
    let questionId = this.props.questionId;
    const questionModel = this.state.questionModel;
    let queryFilters = this.state.toBeAppliedFiltersDtos;
    let dto = {
      displayName: (options.name || `question-${questionId}-export`).replace(/[\W,\s\n\t]/g, '-'),
      extention: options.extension,
      filters: queryFilters,
      lifeTimeHours: 0,
      pageNumber: 0,
      pageSize: questionModel ? questionModel.limit : 1000,
      questionId: questionId,
    } as ExportQuestionDto;
    apisService.exportQuestion(dto).then(
      d => {
        if (d.fileId) {
          dto.id = d.fileId;
          apisService.downloadExportedFile(dto);
        } else {
          if (d.status !== 'PASS') {
            toastr.error(d.cause);
          } else {
            toastr.error('Failed to export question');
          }
        }
      },
      e => {
        toastr.error('Failed to export question');
      }
    );
  };

  onShareWithUsers = option => {
    apisService.shareAQuestionWithUsers(this.props.questionId, option.users).then(
      success => {
        toastr.success('Question Shared Successfully');
      },
      error => {
        toastr.error('Failed to share question');
        console.error(error);
      }
    );
  };

  onShareQuestionToEmails = options => {
    let questionId = this.props.questionId;
    const questionModel = this.state.questionModel;
    let filters = this.state.toBeAppliedFiltersDtos;

    let data = {
      emails: options.extension.email,
      question: {
        displayName: options.extension.title,
        extention: options.extension.format.toUpperCase() || 'CSV',
        filters: filters,
        pageNumber: 0,
        pageSize: questionModel ? questionModel.limit : 1000,
        questionId: questionId,
      },
    };
    let closeLoadingToastr = toastr.loading('Sending Emails');

    if (options.extension.cron !== undefined) {
      let jobBody = {
        displayName: `Send email schedule ${options.extension.title} question`,
        cronExp: options.extension.cron,
        exportFileJob: {
          emails: data.emails,
          questions: [data.question],
        },
        jobType: 'EXPORT_FILES',
      };

      apisService
        .addScheduler(jobBody)
        .then(
          success => {
            closeLoadingToastr();
            toastr.success('Question Shared Successfully');
          },
          error => {
            closeLoadingToastr();
            toastr.error('Failed to share question');
            console.error(error);
          }
        )
        .catch(err => {
          closeLoadingToastr();
          console.error('Scheduler job err: ', err);
          toastr.error('Failed to share question');
        });
    } else {
      // send immediately email
      apisService.shareQuestionByEmail(data).then(
        resp => {
          if (resp.status !== 'PASS') {
            toastr.error('Error, unable to share question by email!');
            return Promise.reject(resp.cause);
          }
          closeLoadingToastr();
          toastr.success('Question Shared by email Successfully');
          return Promise.resolve(resp);
        },
        errorResponse => {
          closeLoadingToastr();
          console.error(errorResponse);
          toastr.error('Error, unable to share question by email!');
        }
      );
    }
  };

  onChartCustomizationChangedHandler = newChartOptions => {
    const { onChartCustomizationChanged } = this.props;
    onChartCustomizationChanged && onChartCustomizationChanged(newChartOptions);
    this.setState({ chartOptions: Object.assign({}, this.state.chartOptions, newChartOptions) });
  };

  onQuestionViewUpdated = updatedViewOptions => {
    this.setState({ viewTypeOption: updatedViewOptions });
  };

  render() {
    const { previewResult, isLoadingPreview, questionDeleted } = this.state;
    const { mode, myRef } = this.props;
    if (questionDeleted) {
      return <DeletedQuestionCard toolBarStartButtons={this.props.toolBarStartButtons} />;
    }
    const questionModel = this.state.questionModel;
    const hasRawData = questionModel && (questionModel.viewItems || []).find(c => c.functionItem!.value == OiFunction.NONE);
    return (
      <QuestionPreviewer
        ref={r => {
          this.state.previewrRef = r;
          if (myRef && r) {
            myRef({
              //  previewerState: r.state,
              reloadView: this.onExecuteQuestion,
              executeWithDynamicFilters: this.executeWithDynamicFilters,
            });
          }
        }}
        hasRawData={hasRawData}
        toolBarStartButtons={this.props.toolBarStartButtons}
        questionDto={this.state.questionDto}
        questionModel={this.state.questionModel}
        previewResult={previewResult}
        onViewTypeOptionUpdated={this.onQuestionViewUpdated}
        viewTypeOption={this.state.viewTypeOption}
        onExecuteFilters={filters => this.executeWithDynamicFilters(filters)}
        isLoadingPreview={isLoadingPreview}
        chartOptions={this.state.chartOptions}
        showPreviewButton={false}
        onDoSegmentFilterOut={this.onDoSegmentFilterOut}
        onChartCustomizationChanged={this.onChartCustomizationChangedHandler}
        hidePickColorSchema={mode == QuestionViewMode.VIEW}
        onDoChartDrillDown={this.onDoChartDrillDown}
        onDoSegmentZoom={this.onDoSegmentZoom}
        onExportQuestion={this.onExportQuestion}
        mode={mode}
        onShareQuestionToEmails={this.onShareQuestionToEmails}
        onShareWithUsers={this.onShareWithUsers}
        isLoadingQueryNextChunk={this.state.isLoadingQueryNextChunk}
        onTablePaginationChanged={this.onTablePaginationChanged}
        loadNextQueryChunk={this.loadNextQueryChunk}
        // {...otherProps}
      />
    );
  }
}
