import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import TitledBorder from 'src/components/TitledBorder';
import { Colors } from 'src/shared/theme';
import OiIconButton from 'src/components/OiIconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Scrollbars } from 'react-custom-scrollbars';
import CalculatedColumnListItem from 'src/modules/questions/studio/IngredientsPanel/ComputedColumnListItem';

import { OceanDataType } from 'src/shared/models/SqlColumn';
import moment from 'moment';
import { isOperatorCurrentOrLast } from 'src/shared/models/OiFilter';
import QuestionViewItem from './QuestionViewItem';
import QuestionGroupingItem from './QuestionGroupingItem/QuestionGroupingItem';
import QuestionFilteringItem from './QuestionFilteringItem/QuestionFilteringItem';
import OiFunctionsList from 'src/shared/dataSources/OiFunctions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IProps, IState } from './models';
import Styled from './Styled';
import AdvanceFilterGroup from '../components/AdvanceFilters/AdvanceFilterGroup';
import { IAdvanceFilterGroup } from '../components/AdvanceFilters/models';
import confirmUtils from 'src/store/confirmationPopup/utils';
import { APP_LOCAL_DATE_FORMAT } from 'src/constants';
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class QuestoinStudioIngredientsPanel extends React.PureComponent<IProps, IState> {
  onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items = reorder(this.props[source.droppableId].slice(), source.index, destination.index);
      this.props.batchQuestionStudioState({ [source.droppableId]: items });
      this.props.onFormModelUpdated();
    }
    return false;
  };

  scrollPanel1To = ref => {
    const scrollBars: any = this.refs.panel1Ref;
    const elementDom: any = ReactDOM.findDOMNode(this.refs[ref]);
    scrollBars.scrollTop(elementDom.getBoundingClientRect().y);
  };

  onChangeFilterItem = (itemObj, itemIndex) => {
    let filtersItems = this.props.filtersItems.slice();

    const currentItem = filtersItems[itemIndex];
    const columnChanged = currentItem.column.dbName !== itemObj.column.dbName;
    const operatorChanged = currentItem.filterOperator.operator !== itemObj.filterOperator.operator;
    if (columnChanged) {
      itemObj.values = currentItem.filterOperator.defaultValue.slice();
    } else if (operatorChanged) {
      itemObj.values = itemObj.filterOperator.defaultValue.slice();
    }
    if (itemObj.column.oceanDataType === OceanDataType.DATE && !isOperatorCurrentOrLast(itemObj.filterOperator.operator)) {
      itemObj.values = itemObj.values.map(v => moment(v).format(APP_LOCAL_DATE_FORMAT));
    }
    Object.assign(filtersItems[itemIndex], itemObj);
    this.props.batchQuestionStudioState({ filtersItems });
    this.props.onFormModelUpdated();
  };

  onDeleteViewItem = i => {
    let viewsItems = this.props.viewsItems.filter((g, _i) => i !== _i);
    this.props.batchQuestionStudioState({ viewsItems });
    this.props.onFormModelUpdated();
  };

  onDeleteGroupItem = i => {
    let groupItems = this.props.groupItems.filter((g, _i) => i !== _i);
    this.props.batchQuestionStudioState({ groupItems, mayNeedRefresh: true });
    this.props.onFormModelUpdated();
  };

  onDeleteQueryFilterItem = i => {
    let filtersItems = this.props.filtersItems.filter((g, _i) => i !== _i);
    this.props.batchQuestionStudioState({ filtersItems });
    this.props.onFormModelUpdated();
  };

  _mapViewToQuestionViewItem = (view, i) => (
    <Draggable key={view.vid} draggableId={'viewItems-' + view.vid} index={i}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <QuestionViewItem
            item={view}
            functionsList={this.props.hasRawData ? [] : OiFunctionsList.slice(2)}
            tables={[this.props.baseTable, ...this.props.relatedTables]}
            onDelete={this.onDeleteViewItem.bind(this, i)}
            onUpdate={this.props.updateViewItem.bind(this, i)}
          />
        </div>
      )}
    </Draggable>
  );

  onResetPanelData = () => {
    confirmUtils.confirm({
      msg: 'Please confirm you want to reset all data',
      ok: () => {
        this.props.resetIngredientsPanelData();
      },
    });
  };

  _mapGroupToQuestionGroupingItem = (groupItem, i) => (
    <Draggable key={groupItem.vid} draggableId={'groupItems-' + i} index={i}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <QuestionGroupingItem
            tables={[this.props.baseTable, ...this.props.relatedTables]}
            item={groupItem}
            onDelete={this.onDeleteGroupItem.bind(this, i)}
            onUpdate={this.props.updateGroupItem.bind(null, i)}
          />
        </div>
      )}
    </Draggable>
  );

  _mapFilterToQuestionFilteringItem = (filterItem, i) => (
    <Draggable key={filterItem.vid} draggableId={'filterItems-' + i} index={i}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <QuestionFilteringItem
            itemIndex={i}
            key={i}
            item={filterItem}
            tables={[this.props.baseTable, ...this.props.relatedTables]}
            aggregateColumns={this.props.aggregateColumns || []}
            source='questionStudio'
            onUpdated={this.onChangeFilterItem}
            onDelete={this.onDeleteQueryFilterItem.bind(this, i)}
          />{' '}
        </div>
      )}
    </Draggable>
  );

  reorderableListOfItems = (className, droppableId, listItemsDom) => {
    return (
      <div className={className}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} className={className}>
                {listItemsDom}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  handleDeleteRootFilters = () => {
    confirmUtils.confirm({
      msg: 'Please confirm you want to delete all groups',
      ok: () => {
        this.props.deleteAdvanceFiltersRoot();
      },
    });
  };

  render() {
    const { classes, viewsItems, groupItems, filtersItems } = this.props;
    const { advanceFiltersRoot, deleteAdvanceFiltersRoot, updateAdvanceFiltersRoot } = this.props;
    const viewItemsList = (viewsItems.length && (
      <>
        <TitledBorder className={classes.ingredientsSectionTitle} color={Colors.brandBlue}>
          View
        </TitledBorder>
        {this.reorderableListOfItems('view-list', 'viewsItems', viewsItems.map(this._mapViewToQuestionViewItem))}
      </>
    )) || <></>;

    const groupingItemsList = (groupItems.length && (
      <>
        <TitledBorder className={classes.ingredientsSectionTitle} color={Colors.brandRed}>
          Group By
        </TitledBorder>
        {this.reorderableListOfItems('group-by-list', 'groupItems', groupItems.map(this._mapGroupToQuestionGroupingItem))}
      </>
    )) || <></>;

    const filterItemsList = (filtersItems.length && (
      <>
        <TitledBorder className={classes.ingredientsSectionTitle} color={Colors.brandGreen}>
          Filter By
        </TitledBorder>
        {this.reorderableListOfItems('filter-by-list', 'filtersItems', filtersItems.map(this._mapFilterToQuestionFilteringItem))}
      </>
    )) || <></>;

    const allTables = ([] as any[]).concat([this.props.baseTable], this.props.relatedTables);

    const computedColumnsList = (this.props.computedColumns.length && (
      <div className='computed-columns-list'>
        <TitledBorder className={classes.ingredientsSectionTitle} color={Colors.brandYellow}>
          Calculated Columns
        </TitledBorder>
        {this.props.computedColumns.map((c, i) => {
          return (
            <CalculatedColumnListItem
              key={c.vid}
              item={c}
              tables={allTables}
              onChanged={this.props.onChangeCalculatedColumn.bind({}, i)}
              onDelete={this.props.onDeleteCalculatedColumn.bind({}, c, i)}
            />
          );
        })}
      </div>
    )) || <></>;
    return (
      <Paper elevation={1} className={classes.questionPanel1}>
        <div className={classes.panelHeader}>
          <div className={classes.titlesWrapper}>
            <span className={classNames(classes.titleItem, classes.viewsTitleItem)} onClick={() => this.scrollPanel1To('viewItems-list')}>
              Views ({this.props.viewsItems.length})
            </span>
            <span
              className={classNames(classes.titleItem, classes.groupsTitleItem)}
              onClick={() => this.scrollPanel1To('groupingItems-list')}
            >
              Groups ({this.props.groupItems.length})
            </span>
            <span
              className={classNames(classes.titleItem, classes.filtersTitleItem)}
              onClick={() => this.scrollPanel1To('filterItems-list')}
            >
              Filters ({this.props.filtersItems.length})
            </span>
          </div>
          <OiIconButton className={classes.resetBtnPanel1} onClick={this.onResetPanelData}>
            <RefreshIcon />
          </OiIconButton>
        </div>
        <div className={classes.panel1ScrollableArea}>
          <Scrollbars ref='panel1Ref' className={classes.scrollbars}>
            <Paper elevation={0} style={{ padding: '0px 10px 0px 5px', paddingBottom: 250 }}>
              {viewItemsList && <div ref='viewItems-list'> {viewItemsList} </div>}
              {computedColumnsList && <div ref='computed-columns-list'>{computedColumnsList}</div>}
              {groupingItemsList && <div ref='groupingItems-list'> {groupingItemsList} </div>}
              {filterItemsList && <div ref='filterItems-list'> {filterItemsList} </div>}
              {advanceFiltersRoot && (
                <div ref='advance-columns-list'>
                  <AdvanceFilterGroup
                    group={advanceFiltersRoot as IAdvanceFilterGroup}
                    onGroupChanged={batch => updateAdvanceFiltersRoot(batch)}
                    onDelete={this.handleDeleteRootFilters}
                    tables={[this.props.baseTable, ...this.props.relatedTables]}
                    aggregateColumns={this.props.aggregateColumns}
                  />
                </div>
              )}
            </Paper>
          </Scrollbars>
        </div>
      </Paper>
    );
  }
}

export default Styled(QuestoinStudioIngredientsPanel);
