import * as React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';
// @material-ui
import List from '@material-ui/core/List';
import { NoQuestions } from './NoQuestions';
import AddQuestionListItem from './QuestionListItem';
import SearchTextField from './SearchTextField';
import { IQuestionPoupProps } from '.';
import { LoadingWidget } from './LoadingWidget';
import { Popup } from './Popup';
import { PopupTriggerButton } from './PopupTriggerButton';
import { getFilteredQuestions, isFavoriteQuestion } from './utils';
import { List as VirtualizedList, AutoSizer } from 'react-virtualized';

export function AddQuestionsPopup({
  questions: questionsList,
  favoriteQuestionsList: favList,
  loading,
  isLoadingFav,
  AddQuestion,
  getAllQuestions,
  getFavoriteQuestions,
}: IQuestionPoupProps) {
  const [search, setSearch] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fetchTrails, setFetchTrails] = React.useState(0);
  const questions = getFilteredQuestions(questionsList, search);
  const isLoading = loading || isLoadingFav;
  const handleClose = () => {
    setSearch('');
    setAnchorEl(null);
  };

  React.useEffect(
    () => {
      if (!loading && !questions.length && fetchTrails < 3) {
        getAllQuestions();
        getFavoriteQuestions();
        setFetchTrails(fetchTrails + 1);
      }
    },
    [questions, loading, isLoadingFav]
  );

  const renderQuestionItem = ({ index, isScrolling, key, style }) => {
    const question = questions[index % questions.length];
    return (
      <div key={key} style={style}>
        <AddQuestionListItem
          key={question.id}
          style={style}
          AddQuestion={AddQuestion}
          isFavoriteQuestions={isFavoriteQuestion.bind({}, favList)}
          question={question}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <PopupTriggerButton onClick={e => setAnchorEl(e.target)} />
      <Popup anchorEl={anchorEl} handleClose={handleClose}>
        <div style={{ padding: '0px 10px' }}>{<SearchTextField search={search} filter={e => setSearch(e.target.value)} />}</div>

        <div
          className='questions-list'
          style={{
            height: 470,
            width: 470,
          }}
        >
          <AutoSizer disableHeight>
            {({ width }) => (
              <VirtualizedList
                className={'virtualized-list'}
                ref='List'
                height={450}
                noRowsRenderer={NoQuestions}
                rowCount={questions.length}
                rowHeight={30}
                rowRenderer={renderQuestionItem}
                width={width}
              />
            )}
          </AutoSizer>
        </div>
      </Popup>
    </React.Fragment>
  );
}
