import * as React from 'react';
import './style.scss';

import OIServicesDashboard from '../dashboard.service';
import Grid from '@material-ui/core/Grid/Grid';

import SwipeableViews from 'react-swipeable-views';
import { Scrollbars } from 'react-custom-scrollbars';

import ActionFooter from 'src/layout/ActionFooter';
import DashboardTabs from 'modules/dashboards/Studio/components/tabs';
import GridContainer from 'modules/dashboards/Studio/components/GridContainer';
import toastr from 'src/components/toastr';
import SaveModal from 'src/components/SaveModal';

interface ItProps {}
interface ItState {
  selected: number;
  tabs: Array<any>;
  questionsList: Array<any>;
  anchorEl: any;
  dialog: boolean;
  name: string;
  tags: string;
  description: string;
  resizeAgain: boolean;
  mode: DashboardMode;
  filters: Array<any>;
  users: Array<any>;
}
export enum DashboardMode {
  view,
  studio,
}
export default class DashboardStudio extends React.Component<any, ItState> {
  myRefs: Array<any>;
  constructor(props) {
    super(props);
    this.myRefs = [];
  }
  componentDidMount() {
    const { changeSelectedTab } = this.props;
    changeSelectedTab(0);

    let footer = document.getElementById('main-footer');
    if (footer) footer.style.display = 'none';
    let wrapper = document.getElementById('dashboard-create-wrapper');
    if (wrapper) wrapper.style.height = 'calc(100vh - 125px)';
  }
  componentWillUnmount() {
    const { resetStore } = this.props;
    resetStore && resetStore();

    let footer = document.getElementById('main-footer');
    if (footer) footer.style.display = 'flex';
    let wrapper = document.getElementById('dashboard-create-wrapper');
    if (wrapper) wrapper.style.height = 'calc(100vh - 180px)';
  }

  lastViewIndex: number = 0;
  state = {
    selected: 0,
    tabs: [
      {
        index: 0,
        name: 'New Tab',
        editable: false,
        views: [] as Array<any>,
      },
    ],
    name: 'My Dashboard',
    tags: '',
    description: '',
    questionsList: [],
    anchorEl: undefined,
    dialog: false,
    resizeAgain: false,
    mode: DashboardMode.studio,
    filters: [],
    users: [],
  };

  // Dialog
  handleDialogOpen = () => {
    this.setState({ dialog: true });
  };

  handleDialogClose = () => {
    this.setState({ dialog: false });
  };

  // Main functions cancel and save
  handleCancel = () => {
    this.props.history.push('/dashboards');
  };
  mapStateToDto = result => {
    let myRefs = this.myRefs;
    const { tabs } = this.state;
    let cloned = JSON.parse(JSON.stringify(tabs));
    let _tabs = cloned.map((tab, i) => {
      tab['index'] = i;
      const views = tab.views || [];
      const _views = views.map((view, vIndex) => {
        let ref = myRefs[i + '-' + vIndex];
        const title = (ref && (ref.state && ref.state.previewResult && ref.state.previewResult.title)) || '';
        const viewType = ref && ref.state && ref.state.previewResult && ref.state.viewTypeOption.type;
        const viewOptionsJSON = (ref && JSON.stringify(ref.state && ref.state.chartOptions)) || '';
        let obj: any = {
          position: JSON.stringify(view),
          description: '',
          title: title,
          viewType: viewType,
          viewOptionsJSON: viewOptionsJSON,
          questionId: view.questionId,
        };
        view.id && (obj.id = view.id);
        return obj;
      });
      tab.views = _views;
      return tab;
    });

    return {
      name: result.name,
      description: result.description,
      tabs: _tabs,
      tag: (result.tags || []).map((v: any) => v.value).join(','),
    };
  };
  handleSaveDashboard = result => {
    let body = this.mapStateToDto(result);
    const { dashboardStoreFilters, history } = this.props;
    const { users } = this.state;

    body['filters'] = dashboardStoreFilters;

    OIServicesDashboard.SaveDashboard(body)
      .then((response: any) => {
        toastr.success('Saved');
        if (users.length > 0) {
          this.shareIt(response.id);
          this.setState({ users: [], dialog: false });
        } else {
          history.push('/dashboards');
        }
      })
      .catch(err => {
        toastr.error(err.message);
        console.log(err.message);
      });
  };
  // Tabs
  handleChangeIndex = index => {
    this.setState({ selected: index });
  };
  handleChangeTabs = tabs => {
    this.setState({ tabs: tabs });

    const { saveDashboardTabs } = this.props;
    saveDashboardTabs(tabs);
  };
  handleChangeSelected = index => {
    this.setState({ selected: index });

    const { changeSelectedTab } = this.props;
    changeSelectedTab(index);
  };
  handleChangeRefs = QuestionRefs => {
    this.myRefs = QuestionRefs;
  };
  handleChangeWidth(): any {
    return e => this.forceUpdate();
  }
  handleFilterPreview = filters => {
    this.setState({ filters });
  };
  handleShare = async users => {
    await this.setState({ users: users });
    const { match } = this.props;
    let id = parseInt(match.params.id);
    if (id) {
      this.shareIt(id);
    } else {
      this.setState({ dialog: true });
    }
  };
  shareIt = id => {
    const { users } = this.state;
    OIServicesDashboard.ShareDashboard(id, users)
      .then(response => {
        return response;
      })
      .catch(err => {});
  };

  render() {
    this.myRefs = [];
    const { selected, tabs, dialog, resizeAgain, mode, name, description, filters } = this.state;
    const editable = mode == DashboardMode.studio ? true : false;
    const hasTabs = editable || (!editable && tabs.length > 1);

    return (
      <React.Fragment>
        <DashboardTabs
          isViewMode={!editable}
          selected={selected}
          tabs={tabs}
          onChangeSelected={this.handleChangeSelected}
          onChangeTabs={this.handleChangeTabs}
          onFilterPreview={this.handleFilterPreview}
          onShare={this.handleShare}
        />
        <div id='dashboard-create-wrapper' className='dashbaord-create-wrapper'>
          <Scrollbars autoHide>
            <Grid container className='grid'>
              <div className='card-wrapper'>
                <SwipeableViews
                  containerStyle={{ height: '100%', marginBottom: 50 }}
                  ignoreNativeScroll
                  className='card-content-swipeable'
                  index={selected}
                  onChangeIndex={this.handleChangeIndex}
                >
                  {tabs.map((tab, tabIndex) => (
                    <React.Fragment key={tabIndex}>
                      <GridContainer
                        tabIndex={tabIndex}
                        tab={tab}
                        isViewMode={!editable}
                        selected={selected}
                        tabs={tabs}
                        resizeAgain={resizeAgain}
                        myRefs={this.myRefs}
                        onChangeTabs={this.handleChangeTabs}
                        onChangeRefs={this.handleChangeRefs}
                        onWidthChange={this.handleChangeWidth()}
                        filters={filters}
                      />
                    </React.Fragment>
                  ))}
                </SwipeableViews>
              </div>
            </Grid>
          </Scrollbars>
        </div>
        {editable && (
          <React.Fragment>
            <ActionFooter handleSave={this.handleDialogOpen} handleCancel={this.handleCancel} />

            <SaveModal
              EntityName='Dashboard'
              tags={this.state.tags}
              title={name}
              open={Boolean(dialog)}
              description={description}
              showSaveAs={Boolean(false)}
              onSave={this.handleSaveDashboard}
              onSaveAs={this.handleSaveDashboard}
              onCancel={this.handleDialogClose}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
