import cogoToast from 'cogo-toast';

const baseConfigs = {
  position: 'bottom-right',
  hideAfter: 4.5,
};

const success = (msg, configs?, ...params) => {
  return cogoToast.success(msg, { ...baseConfigs, configs }, ...params);
};
const error = (msg, configs?, ...params) => {
  const closeIt = cogoToast.error(
    msg,
    {
      ...baseConfigs,
      hideAfter: 5,
      // onClick: function() {
      //   closeIt();
      // },
      heading: 'Error',
      configs,
    },
    ...params
  );
  return closeIt;
};

const info = (msg, configs?, ...params) => {
  return cogoToast.info(msg, { ...baseConfigs, configs }, ...params);
};
const warn = (msg, configs?, ...params) => {
  return cogoToast.warn(msg, { ...baseConfigs, configs }, ...params);
};
const loading = (msg, configs?, ...params) => {
  return cogoToast.loading(msg, { ...baseConfigs, hideAfter: 0, configs }, ...params);
};
export const alert = warn;
export default {
  success,
  error,
  info,
  warn,
  loading,
};
