import { IRootState } from 'store/rootReducer';
import { SQLTable } from 'src/shared/models/SqlTable';
import { OiFunction } from 'src/shared/models/OiFunction';
import { isDateColumn } from 'src/shared/util/SqlColumnUtils';
import { QueryGroupViewItem } from 'src/modules/questions/studio/models';

const aggregateColumns = (s: IRootState) => {
  return s.questionStudio.viewsItems.filter(v => v.functionItem && v.functionItem.value !== OiFunction.NONE);
};
const studioState = (s: IRootState) => s.questionStudio;
const baseTable = (s: IRootState) => s.questionStudio.baseTable;
const dbTables = (s: IRootState) => s.questionStudio.dbTables || [];
const hasRawData = (s: IRootState) => Boolean(viewsItems(s).find(c => c.functionItem!.value == OiFunction.NONE));
const viewsItems = (s: IRootState) => s.questionStudio.viewsItems || [];
const computedColumns = (s: IRootState) => s.questionStudio.computedColumns || [];
const groupItems = (s: IRootState) => s.questionStudio.groupItems || [];
const filtersItems = (s: IRootState) => s.questionStudio.filtersItems || [];
const hasAnyData = (s: IRootState) => {
  return Boolean(viewsItems(s).length || filtersItems(s).length || computedColumns(s).length || groupItems(s).length);
};
const relatedTablesNames = (s: IRootState) => {
  return baseTable(s) ? baseTable(s).relations.map(item => item.refTableName) : [];
};
const relatedTables = (s: IRootState) => {
  let baseTable$ = baseTable(s);
  return baseTable$ ? dbTables(s).filter((t: SQLTable) => relatedTablesNames(s).indexOf(t.name) > -1) : [];
};

const unSelectedRawTable = (s: IRootState) => {
  let _viewsItems = viewsItems(s);
  let baseTable$ = baseTable(s);

  return dbTables(s)
    .filter((t: SQLTable) => (baseTable$ && baseTable$.name == t.name) || relatedTablesNames(s).indexOf(t.name) > -1)
    .reduce(
      (agg, table) => {
        let columns = table.columns.filter(c => !_viewsItems.find(v => v.column.id == c.id));
        if (columns.length) {
          agg.push({ ...table, columns: columns });
        }
        return agg;
      },
      [] as SQLTable[]
    );
};

const allColumns = (s: IRootState) => (baseTable(s) ? baseTable(s).columns.concat(...relatedTables(s).map(t => t.columns)) : []);

export function getSameGroupItems(groupItem: any, groupItems: Readonly<Array<QueryGroupViewItem>>) {
  let sameGroups = groupItems.filter((g: QueryGroupViewItem) => {
    let isSameColumn = g.column.dbName === groupItem.column.dbName;
    let sameGroupingOp = g.groupingOperator == groupItem.groupingOperator;
    return isSameColumn && sameGroupingOp && g.vid !== groupItem.vid;
  });
  return sameGroups;
}

export const questionStudioSelectors = {
  studioState,
  aggregateColumns,
  baseTable,
  dbTables,
  getSameGroupItems,
  hasRawData,
  viewsItems,
  computedColumns,
  groupItems,
  filtersItems,
  hasAnyData,
  relatedTablesNames,
  relatedTables,
  allColumns,
  unSelectedRawTable,
  //   relatedTables: state=> state.baseTable? [state.baseTable]:[]
};

export default questionStudioSelectors;
