import { connect } from 'react-redux';
import { authorities, user, getLoader } from 'src/store/users/selectors';
import Form from './Form';
import types from 'src/store/users/actionTypes';

const mapStateToProps = state => {
  return {
    authLoader: getLoader(state, types.FETCH_AUTHORITIES),
    authorities: authorities(state),
    user: user(state),
    userLoader: getLoader(state, types.GET_USER),
  };
};

const conected = connect(
  mapStateToProps,
  null
)(Form);
const UserComponent = conected;
export default UserComponent;
