import * as React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

export class ResponsiveGrid extends React.Component<any> {
  state = {
    isCompact: window.innerWidth > 996 ? false : true,
  };

  get GridLayout() {
    const { views } = this.props;
    return views.reduce(
      (acc, v, index) => {
        acc.lg.push({ ...v, w: v.w });
        acc.md.push({ ...v, w: Math.min(v.w, 5) });
        acc.sm.push({ ...v, x: index % 2 ? 0 : 3, w: 3 });
        acc.xs.push({ ...v, w: 4 });
        acc.xxs.push({ ...v, w: 4 });
        return acc;
      },
      { lg: [], md: [], sm: [], xs: [], xxs: [] }
    );
  }

  render() {
    const { isViewMode, handleDragStop, handleResizeStop, onWidthChange } = this.props;

    return (
      <ResponsiveGridLayout
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 0, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
        isDraggable={!isViewMode}
        isRearrangeable={!isViewMode}
        isResizable={!isViewMode}
        rowHeight={150}
        layouts={this.GridLayout}
        containerPadding={[15, 15]}
        onDrag={e => {}}
        onDragStop={e => {
          handleDragStop(e);
        }}
        onResizeStop={e => {
          handleResizeStop(e);
        }}
        onWidthChange={containerWidth => {
          this.forceUpdate();
          onWidthChange(containerWidth);
        }}
      >
        {this.props.children}
      </ResponsiveGridLayout>
    );
  }
}
export default ResponsiveGrid;
