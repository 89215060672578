import React from 'react';
import { connect } from 'react-redux';
import { Paper, CircularProgress, withStyles } from '@material-ui/core';
import { IRootState } from 'src/store/rootReducer';

const EmptyPanel = ({ classes, isLoadingQuestion }) => (
  <Paper className={classes.mainCard} style={{ width: '100%', height: '100%' }}>
    <div className={classes.emptyFormPreviewAreaPlaceHolderWrap}>
      {isLoadingQuestion ? (
        <>
          <CircularProgress color='primary' /> Loading Your question, please wait ...
        </>
      ) : (
        <h3>Start exploring your data, select the views you want :)</h3>
      )}
    </div>
  </Paper>
);

const mapStateToProps = (state: IRootState) => {
  const { questionStudio } = state;
  return {
    isLoadingQuestion: questionStudio.isLoadingQuestion,
  };
};

const EmptyResultPanel = connect(mapStateToProps)(EmptyPanel);
const styler = withStyles({
  emptyFormPreviewAreaPlaceHolderWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainCard: {
    padding: 20,
    position: 'relative',
    height: '100%',
    overflow: 'auto',
  },
});

export default styler(EmptyResultPanel);
