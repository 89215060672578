import * as React from 'react';

import moment from 'moment';
import ActionsColumn from './columnActions';
export const columnsBuilder = (onToggleActivation, onDeleteTask) => {
  return [
    { field: 'displayName', title: 'Display Name' },
    {
      field: 'lastRunStatus',
      title: 'status',
      cellRenderer: v => (v === null ? 'N/A' : v),
    },
    {
      field: 'lastRunDate',
      title: 'Last Run',
      cellRenderer: v => (v === null ? 'N/A' : moment(v).format('MM/DD/YYYY hh:mm a')),
    },
    {
      field: 'id',
      title: 'actions',
      cellRenderer: (id, row, column) => (
        <ActionsColumn row={row} id={id} updateJobActivation={onToggleActivation} deleteTask={onDeleteTask} />
      ),
    },
  ];
};
