const styles = {
  absolutUl: {
    position: 'absolute',
    top: 5,
    left: 0,
    width: 150,
    minHeight: 100,
    background: 'white',
    listStyle: 'none',
    padding: 0,
    paddingLeft: 10,
  },
  wrapperDiv: {
    position: 'relative',
    border: '1px solid red',
    height: 20,
  },
  colusrePresenterStyle: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    float: 'left',
    padding: '2px',
    cursor: 'pointer',
    overflow: 'auto',
    flexWrap: 'wrap',
    maxWidth: '100%',
  } as any,
  aggPresenter: {
    display: 'flex',
    flex: ' 0 0 auto',
    alignItems: 'center',
  } as any,
  editorWrapStyle: {
    display: 'flex',
    flex: '  auto',
    alignItems: 'center',
    padding: '5',
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
    alignContent: 'center',
    overflow: 'wrap',
  },
  operatorChip: {
    background: '#666',
    color: 'white',
    fontSize: '20px',
  },
  braces: {
    fontSize: '20px',
  },
};
export default styles;
