import * as React from 'react';

import apisService from 'src/shared/apis';
import { FavoriteQuestion } from 'src/shared/models/OiQuestionDto';
import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import questionAccess from 'src/services/permissions/abilities/questions';
import FavouriteBar from 'src/components/favouriteBar';
import { StateProps, DispatchProps } from './Container';
import Styled, { styleProps } from './style';

interface IProps extends styleProps, StateProps, DispatchProps {
  onPlay: (id: any) => any;
  onDelete: (id: any) => any;
  onEdit: (id: any) => any;
  onUnfavourite: (id: any) => any;
}
interface IState {
  isLoadingFavQuestions: boolean;
  favQuestions: Array<FavoriteQuestion>;
}
export class QuestionsFavouriteBar extends React.Component<IProps, IState> {
  state: IState = {
    isLoadingFavQuestions: true,
    favQuestions: [],
  };

  componentWillMount() {
    this.props.getFavoriteQuestions();
  }

  handleUnfavourite = question => {
    this.props.onUnfavourite(question);
    // apisService.removeQuestionFromFav(question.id).then(c => {
    //   this.props.getFavoriteQuestions();
    // });
  };
  handlePlay = id => {
    this.props.onPlay(id);
  };
  handleEdit = id => {
    this.props.onEdit(id);
  };
  getThumbClass = favourite => {
    const { classes } = this.props;

    switch (favourite.defaultView as VIEW_TYPE) {
      case VIEW_TYPE.LINE_CHART: {
        return classes.lineChartImg;
      }
      case VIEW_TYPE.BAR_CHART: {
        return classes.BarChart;
      }
      case VIEW_TYPE.PIE_CHART: {
        return classes.PieChartImg;
      }
      case VIEW_TYPE.DOUGHNUT_CHART: {
        return classes.doughnutChart;
      }
      case VIEW_TYPE.KPI: {
        return classes.Kpi;
      }
      case VIEW_TYPE.TABLE: {
        return classes.Table;
      }
      default:
        return classes.Table;
    }
  };
  render() {
    const favQuestions = this.props.favQuestions || [];
    return (
      <FavouriteBar
        favourites={favQuestions}
        access={questionAccess}
        thumbClass={this.getThumbClass}
        onPlay={this.handlePlay}
        onEdit={this.handleEdit}
        onUnfavourite={this.handleUnfavourite}
      />
    );
  }
}
const styled = Styled(QuestionsFavouriteBar);
export default styled;
