import axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import types from './actionTypes';
import { REQUEST, SUCCESS, FAILURE } from 'store/util';
import toastr from 'src/components/toastr';
import { coreUrl } from 'src/shared/apis/baseUrls';

function shareDashboardApi(info): any {
  return axios
    .post(coreUrl + `/dashboards/share`, info)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}
export function* doShareDashboard(action) {
  const type = types.SHARE_DASHBOARD;
  try {
    const payload = action.payload;
    const { result, error } = yield call(shareDashboardApi, payload);

    if (result) {
      yield put({ type: SUCCESS(type), payload: result.data });
      toastr.success(`Dashboard shared successfully`);
    } else {
      const message = error && error.response && error.response.data.message;
      toastr.error(message);
      yield put({ type: FAILURE(type), payload: { message: message || `${type} error` } });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: FAILURE(type), payload: { message: `Unexpeted ${type} error` } });
  }
}

const watcher = [takeEvery(REQUEST(types.SHARE_DASHBOARD) as any, doShareDashboard)];
export default watcher;
