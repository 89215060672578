import * as React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  MenuItem,
  StyleRulesCallback,
  Theme,
  withStyles,
  Typography,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import apisService from 'src/shared/apis';
import ActionFooter from 'src/layout/ActionFooter';
import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import toastr from 'src/components/toastr';

export class DbConnectionCreate extends React.Component<any, any> {
  state = {
    cardTitle: 'Create database connection',
    connectionTemplates: [],
    isLoadingConnectionTemplates: true,
    isSaving: false,
    template: {},
    form: {
      displayName: '',
      templateId: 0,
      dbName: '',
      dbType: '',
      description: null,
      displayType: '',
      hostServer: '',
      password: '',
      port: 0,
      schema: null,
      username: '',
    },
    errorMsg: '',
  };

  componentDidMount() {
    this.loadConnectionsTemplates();
  }
  loadConnectionsTemplates() {
    apisService.getConnectionTemplates().then(
      connection => {
        this.setState({ connectionTemplates: connection, isLoadingConnectionTemplates: false });
      },
      () => {
        this.setState({ isLoadingConnectionTemplates: false });
      }
    );
  }

  handleDBChange = e => {
    let template: any = this.state.connectionTemplates.filter((connection: any) => connection.dbType === e.target.value)[0];
    this.setState({
      form: Object.assign({}, this.state.form, {
        dbType: e.target.value,
        dbName: this.state.form.dbName === '' ? template.dbName : this.state.form.dbName,
        description: this.state.form.dbName === '' ? template.description : this.state.form.dbName,
        displayType: this.state.form.displayType === '' ? template.displayType : this.state.form.displayType,
        hostServer: this.state.form.hostServer === '' ? template.hostServer : this.state.form.hostServer,
        port: template.port,
        schema: template.schema,
      }),
      template,
    });
  };
  handleChange = e => {
    let form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  };
  handleSubmit = event => {
    event.preventDefault();

    const { history } = this.props;

    let data: any = this.state.form;
    if (Object.values(data).some(d => d === '')) {
      this.setState({ errorMsg: 'Please fill all fields' });
      return;
    }

    let template = this.state.template as any;
    data.templateId = template.id;
    this.setState({
      errorMsg: '',
      isSaving: true,
    });
    let closeLoadingToastr = toastr.loading('Adding database');
    apisService
      .saveDatabaseConnection(data)
      .then(response => {
        history.push('/databases');
        toastr.success('Database Added Successfully');
        closeLoadingToastr();
        this.setState({
          isSaving: false,
        });
      })
      .catch(err => {
        const errorMsg = err.response.data.title || err.response.statusText;
        toastr.error('Failed to Add database, ' + errorMsg);
        closeLoadingToastr();
        this.setState({ errorMsg: errorMsg, isSaving: false });
      });
  };
  render() {
    const { classes } = this.props;
    const { cardTitle, connectionTemplates, errorMsg, isLoadingConnectionTemplates, template, form, isSaving } = this.state;

    return (
      <>
        <BreadCrumb>
          Home <KeyboardArrowRight /> Databases <KeyboardArrowRight /> Connection form
        </BreadCrumb>

        <ContentPage>
          <Card>
            <CardHeader
              title={
                isLoadingConnectionTemplates ? (
                  <>
                    <CircularProgress size={40} color='secondary' />
                    {cardTitle}
                  </>
                ) : (
                  cardTitle
                )
              }
            />
            <Divider />
            <CardContent>
              <form>
                <TextField
                  autoFocus
                  id='database-type'
                  select
                  label='Database type'
                  name='dbType'
                  value={form.dbType}
                  onChange={this.handleDBChange}
                  className={classes.textField}
                  SelectProps={{
                    MenuProps: {
                      className: classes.textField,
                    },
                  }}
                  helperText='Please select your Database type'
                  margin='normal'
                  variant='outlined'
                >
                  {connectionTemplates.map((connection: any) => (
                    <MenuItem key={connection.id} value={connection.dbType}>
                      {connection.displayType}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  disabled={form.dbType === '' ? true : false}
                  id='display-name'
                  label='Display Name'
                  className={classes.textField}
                  name={'displayName'}
                  value={form.displayName}
                  error={form.displayName === '' && errorMsg.trim() !== ''}
                  onChange={this.handleChange}
                  margin='normal'
                  variant='outlined'
                />
                <div>
                  <TextField
                    disabled={form.dbType === '' ? true : false}
                    id='host'
                    label='Host Server'
                    className={classes.textField}
                    name='hostServer'
                    value={form.hostServer}
                    error={form.hostServer === '' && errorMsg.trim() !== ''}
                    onChange={this.handleChange}
                    margin='normal'
                    variant='outlined'
                  />
                  <TextField
                    disabled={form.dbType === '' ? true : false}
                    id='port'
                    label='Port'
                    className={classes.textField}
                    name='port'
                    value={form.port}
                    error={form.port.toString() === '' && errorMsg.trim() !== ''}
                    onChange={this.handleChange}
                    margin='normal'
                    variant='outlined'
                  />
                </div>
                <div>
                  {(template as any).schema && (
                    <TextField
                      disabled={form.dbType === '' ? true : false}
                      id='schema'
                      label='Schema'
                      className={classes.textField}
                      name='schema'
                      value={form.schema || ''}
                      error={form.schema === '' && errorMsg.trim() !== ''}
                      onChange={this.handleChange}
                      margin='normal'
                      variant='outlined'
                    />
                  )}
                  <TextField
                    disabled={form.dbType === '' ? true : false}
                    id='dbName'
                    label='Database Name'
                    className={classes.textField}
                    name='dbName'
                    value={form.dbName}
                    error={form.dbName === '' && errorMsg.trim() !== ''}
                    onChange={this.handleChange}
                    margin='normal'
                    variant='outlined'
                  />
                </div>
                <div>
                  <TextField
                    disabled={form.dbType === '' ? true : false}
                    id='username'
                    label='Username'
                    className={classes.textField}
                    name='username'
                    value={form.username}
                    error={form.username === '' && errorMsg.trim() !== ''}
                    onChange={this.handleChange}
                    margin='normal'
                    variant='outlined'
                  />
                  <TextField
                    disabled={form.dbType === '' ? true : false}
                    id='password'
                    label='Password'
                    className={classes.textField}
                    name='password'
                    type='password'
                    autoComplete='new-password'
                    value={form.password}
                    error={form.password === '' && errorMsg.trim() !== ''}
                    onChange={this.handleChange}
                    margin='normal'
                    variant='outlined'
                  />
                </div>
                {errorMsg.trim() !== '' && (
                  <>
                    <Divider className={classes.dividerErrorColor} color='error' />

                    <div className={classes.errorDiv}>
                      <Typography color='error' variant='subtitle1' gutterBottom>
                        {errorMsg}
                      </Typography>
                    </div>
                  </>
                )}
                <ActionFooter
                  isSaveDisabled={form.dbType === '' ? true : false || isSaving}
                  handleSave={e => this.handleSubmit(e)}
                  handleCancel={e => {
                    this.props.history.push('/databases');
                  }}
                >
                  {isSaving && <CircularProgress size={40} color='secondary' />}
                </ActionFooter>
              </form>
            </CardContent>
          </Card>
        </ContentPage>
      </>
    );
  }
}

const style: StyleRulesCallback = (theme: Theme) => ({
  textField: {
    width: '500px',
    margin: '15px 15px 15px 0px',
  },
  errorDiv: {
    marginTop: '15px',
  },
  dividerErrorColor: {
    backgroundColor: `${theme.palette.error.main} `,
  },
});

export default withStyles(style)(DbConnectionCreate);
