import * as React from 'react';
import BreadcrumbComponent from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Creator from '../Creator';

class Editor extends React.Component<any> {
  componentWillMount() {
    const {
      getUser,
      getAuthorities,
      match: {
        params: { id },
      },
    } = this.props;
    getUser(id);
    getAuthorities();
  }
  componentWillUpdate(nextProps) {
    const { user, getUserPermission, shouldRouteBack, history, successMessage } = this.props;

    if (user != nextProps.user) {
      getUserPermission(nextProps.user.username);
    }

    if (nextProps.shouldRouteBack && nextProps.shouldRouteBack != shouldRouteBack) {
      history.push('/users');
    }
  }

  handleSave = body => {
    const { updateWithPermission, history } = this.props;
    updateWithPermission(body);
  };

  render() {
    return (
      <React.Fragment>
        <BreadcrumbComponent>
          Home <KeyboardArrowRight /> Users <KeyboardArrowRight /> Edit
        </BreadcrumbComponent>
        <ContentPage>
          <Creator onSave={this.handleSave} edit />
        </ContentPage>
      </React.Fragment>
    );
  }
}

export default Editor;
