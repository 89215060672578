import * as React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import Styled from './style';
import LoginFooter from './AuthFooter';
import './auth.scss';

function AuthGrid(props) {
  const { classes, children } = props;
  return (
    <React.Fragment>
      <Grid container className={` registration-main-contaier`} direction='row' justify='center' alignItems='center'>
        <div className='assista-header' />
        <div className='circle-1' />
        <div className='circle-2' />
        <div className='circle-3' />
        <div className='circle-4' />
        <div className='registration-container'>
          <div className='registration-panel-container'>
            <div className='registration-form-cover'>
              <div className='cover-text'>
                <div className='registration-logo' />
                <div className='text'>A remotely accessible Smart Cloud-based healthcare informatics platform for eHealth</div>
              </div>
            </div>
            <div className='registration-form-panel'>
              <div className='registration-inputs-panel'>{children}</div>
            </div>
          </div>
          <div className='powered-by' />
        </div>
      </Grid>
      <LoginFooter />
    </React.Fragment>
  );
}

export default Styled(AuthGrid);
