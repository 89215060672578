import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserDataGrid from 'src/modules/users/usersList';
import CreateComponent from './create';
import EditComponent from './edit';
import CanActivateModule from 'src/components/Permissions/CanActivateModule';
import { canDo, connectedCanDo } from 'src/services/permissions/utils';
import NotAuthorized from 'src/layout/NotAuthorized/component';
import userAccess from 'src/services/permissions/abilities/users';

const Routes = ({ permissions, roles, match }) => {
  return (
    <CanActivateModule moduleName={'user'}>
      <Switch>
        <Route path={`${match.url}/new`} component={connectedCanDo(userAccess.create) ? CreateComponent : NotAuthorized} />
        <Route path={`${match.url}/edit/:id`} component={connectedCanDo(userAccess.edit) ? EditComponent : NotAuthorized} />
        <Route path={`${match.url}`} component={connectedCanDo(userAccess.view) ? UserDataGrid : NotAuthorized} />
      </Switch>
    </CanActivateModule>
  );
};

export default Routes;
