import * as React from 'react';
import { ColumnPresenter } from './ColumnPresenter';
import styles from './styles';
import { ExpressionItem } from 'src/shared/models/OiQueryColumn';
import { Tooltip } from '@material-ui/core';
import { Colors } from 'src/shared/theme';
import { ExpressionChip } from './ExpressionChip';

interface IProps {
  expression: ExpressionItem;
}
export class ExpressionTokensPresenter extends React.Component<IProps, {}> {
  state = {};

  lastAnchor: any = null;

  lastV: any = null;
  lastOp: any = null;
  lastAgg: any = null;

  render() {
    const { expression, ...props } = this.props;
    const exp: any = expression;
    switch (exp.t) {
      case 'op': {
        return <ExpressionChip bgColor={Colors.brandBlue} label={exp.value} />;
      }
      case 'col': {
        return (
          <ColumnPresenter
            dbColumn={exp.value.dbColumn}
            aggregate={exp.value.function}
            canDelete={false}
            onDelete={e => e}
            onAggClick={e => e}
            onColumnClick={e => e}
          />
        );
      }
      case 'value': {
        return <ExpressionChip bgColor={Colors.brandMedGrey} label={exp.value} variant='outlined' />;
      }
      case 'err': {
        return (
          <Tooltip title={'' + exp.errMsg}>
            <ExpressionChip bgColor={Colors.brandRed} label={exp.value} variant='outlined' title={exp.errMsg} />
          </Tooltip>
        );
      }
      case 'group': {
        let content = (exp.value || []).map((e, i) => {
          return <ExpressionTokensPresenter key={i} expression={e} />;
        });
        return (
          <span style={styles.colusrePresenterStyle}>
            <span style={styles.braces}>(</span>
            {content}
            <span style={styles.braces}>)</span>
          </span>
        );
      }
      default:
        return <span>DEFAULT</span>;
    }
  }
}
