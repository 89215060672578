import { withStyles, StyleRulesCallback } from '@material-ui/core';
import IqviaLogo from 'src/assets/registration/iqvia-logo-color.svg';

const styles: StyleRulesCallback = () => ({
  // IqviaLogo: {
  //   backgroundImage: `url(${IqviaLogo})`,
  //   backgroundRepeat: 'no-repeat',
  //   width: '150px',
  //   height: '60px',
  // },
  // loginFooterWrapper: {
  //   display: 'flex',
  //   width: '100%',
  //   height: '40px',
  //   backgroundColor: 'white',
  //   position: 'absolute',
  //   bottom: 0,
  //   alignItems: 'center',
  //   alignContent: 'center',
  // },
  // loginFooterTxt: {
  //   flex: '1 1 auto',
  //   textAlign: 'center',
  // },
});
const Styled = withStyles(styles);
export default Styled;
