import * as React from 'react';
import { List, ListItem, Popover, Paper } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { Scrollbars } from 'react-custom-scrollbars';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { SORT_DIRECTION } from 'src/shared/models';
import { QueryViewItem } from 'src/modules/questions/studio/models';

import { SQLColumn, ColumnType, OceanDataType } from 'src/shared/models/SqlColumn';
import ColumnSelectList from 'src/modules/questions/studio/components/ColumnSelectList';
import { OiFunction } from 'src/shared/models/OiFunction';
import { OiViewFunctionOption } from 'src/shared/models/OiViewFunctionOption';
import { IAddOiQueryColumnProps, IAddOiQueryColumnState } from './models';
import Styled from './Styled';
class AddOiViewWizard extends React.Component<IAddOiQueryColumnProps, IAddOiQueryColumnState> {
  state: IAddOiQueryColumnState = {
    currentStepIndex: 0,
    limit: undefined,
  };

  onSelectOperator = (operator: OiViewFunctionOption, e) => {
    e && e.stopPropagation();
    this.setState({ selectedOiFunction: operator, currentStepIndex: 1 });
    if (operator.isSingleStep) {
      this.onDone({
        functionItem: operator,
      } as QueryViewItem);
    }
    this.props.onSelectOiFunction && this.props.onSelectOiFunction(operator, this.close);
  };

  onSelectColumn = col => {
    const { selectedOiFunction } = this.state;
    if (selectedOiFunction!.mustPickTwoColumns) {
      this.setState({ currentStepIndex: 2, selectedColumn: col });
    } else {
      this.setState({ currentStepIndex: 0 });
      this.onDone({
        pin: false,
        column: col,
        functionItem: selectedOiFunction,
        orderDirection: SORT_DIRECTION.NONE,
        orderIndex: 99999,
        vid: -1,
        displayName: `${(selectedOiFunction && selectedOiFunction.displayName) || ''}  (${col.displayName})`,
      });
    }
  };

  onSelectSecondColumn = secondCol => {
    const { selectedOiFunction, selectedColumn: firstColumn } = this.state;

    this.setState({ currentStepIndex: 0 });
    const displayName = `Date Diff (${firstColumn.displayName} - ${secondCol.displayName})`;
    this.onDone({
      pin: false,
      column: {
        pk: false,
        oceanDataType: OceanDataType.STRING,
        dbName: firstColumn.dbName + '-_-' + secondCol.dbName,
        dbTableName: secondCol.dbTableName,
        displayName: displayName,
      } as SQLColumn,
      functionItem: selectedOiFunction,
      orderDirection: SORT_DIRECTION.NONE,
      orderIndex: 99999,
      vid: -1,
      displayName: displayName,
    });
  };

  onDone = (obj: QueryViewItem) => {
    this.setState({ currentStepIndex: 0 });
    const { addRowsCount, addGroupItem, addViewItem } = this.props;
    if (this.props.onDone) {
      this.props.onDone(obj);
    } else if (obj.functionItem!.value == OiFunction.ROW_COUNT) {
      addRowsCount && addRowsCount();
    } else if (obj.functionItem!.value == OiFunction.UNIQUE_VALUES) {
      addGroupItem &&
        addGroupItem({
          column: obj.column,
          functionItem: obj.functionItem,
          groupingOperator: OiFunction.UNIQUE_VALUES,
          displayName: obj.displayName,
        });
    } else if (obj.functionItem!.value == OiFunction.UNIQUE_COUNT) {
      addViewItem &&
        addViewItem({
          ...obj,
          column: { ...obj.column, oceanDataType: OceanDataType.NUMBER },
        });
    } else {
      addViewItem && addViewItem(obj);
    }
    this.close();
  };

  close = () => {
    this.setState({ selectedOiFunction: undefined, selectedColumn: undefined, isOpen: false, currentStepIndex: 0 });
  };

  render() {
    let oiFunctions = this.props.oiFunctionsList || [];
    let columns = (this.props.columns || []).slice();
    let tables = this.props.tables;

    const { classes, onClose } = this.props;
    // columns = columns

    const { selectedOiFunction, isOpen, anchorEl, currentStepIndex } = this.state;
    if (selectedOiFunction) {
      if (selectedOiFunction.measuresOnly) {
        columns = columns.filter(col => col.type == ColumnType.MEASURE);
      }
      if (selectedOiFunction.value == OiFunction.STD_DEV) {
        columns = columns.filter(c => c.oceanDataType == OceanDataType.NUMBER);
      }
      if (selectedOiFunction.value == OiFunction.DATE_DIFF) {
        columns = columns.filter(c => c.oceanDataType == OceanDataType.DATE);
      }
    }
    let opendTable = this.state.opendTable || this.props.selectedTable || (tables && tables[0]);

    return (
      <>
        <div ref='clickRef' onClick={e => this.setState({ isOpen: true, anchorEl: this.refs.clickRef, currentStepIndex: 0 })}>
          {this.props.buttonComponent || this.props.children}
        </div>
        <Popover
          open={!!isOpen}
          anchorEl={anchorEl}
          onClose={onClose}
          onBackdropClick={this.close}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        >
          <div className={classes.popover}>
            <SwipeableViews index={currentStepIndex} enableMouseEvents={false}>
              <Paper elevation={1} className={classes.popover}>
                <div className={classes.stepHeader}> Select Operator </div>
                {!selectedOiFunction && (
                  <Scrollbars className={classes.scrollBar}>
                    <List component='nav'>
                      {oiFunctions.map(op => (
                        <ListItem button key={op.displayName} onClick={this.onSelectOperator.bind(this, op)}>
                          {op.displayName}
                        </ListItem>
                      ))}
                      {oiFunctions.length == 0 ? (
                        <ListItem> No functions, if you added raw data, you will need reset the view. </ListItem>
                      ) : (
                        <></>
                      )}
                    </List>
                  </Scrollbars>
                )}
              </Paper>
              <Paper elevation={1} className={classes.popover}>
                <div
                  className={classes.stepHeader}
                  onClick={e => {
                    this.setState({ selectedOiFunction: undefined, currentStepIndex: 0 });
                  }}
                >
                  <ArrowBack /> &nbsp; {selectedOiFunction && selectedOiFunction.displayName}
                </div>
                {selectedOiFunction && (
                  <Scrollbars className={classes.scrollBar}>
                    <ColumnSelectList
                      tables={tables}
                      columnsOceanDataTypes={selectedOiFunction.columnsOceanDataTypes}
                      columnTypes={selectedOiFunction.measuresOnly ? [ColumnType.MEASURE] : undefined}
                      opendTable={opendTable}
                      onSelectColumn={this.onSelectColumn}
                    />
                  </Scrollbars>
                )}
              </Paper>
              <Paper elevation={1} className={classes.popover}>
                <div
                  className={classes.stepHeader}
                  onClick={e => {
                    this.setState({ currentStepIndex: 1 });
                  }}
                >
                  <ArrowBack /> &nbsp; {selectedOiFunction && selectedOiFunction.displayName}
                </div>
                {selectedOiFunction && (
                  <Scrollbars className={classes.scrollBar}>
                    <ColumnSelectList
                      tables={tables}
                      columnsOceanDataTypes={selectedOiFunction.columnsOceanDataTypes}
                      columnTypes={selectedOiFunction.measuresOnly ? [ColumnType.MEASURE] : undefined}
                      opendTable={opendTable}
                      onSelectColumn={this.onSelectSecondColumn}
                    />
                  </Scrollbars>
                )}
              </Paper>
            </SwipeableViews>
          </div>
        </Popover>
      </>
    );
  }
}
export default Styled(AddOiViewWizard);
