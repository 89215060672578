import * as React from 'react';
// main style
import { styles } from './main.style';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// redux
import { connect } from 'react-redux';
import { logout } from 'src/store/authentication/actions';
// Main App Router
import AppRoutes from '../routes/AppRoutes';
// Layout components
import HeaderComponent from './header';
import DrawerComponent from './drawer';
import FooterComponent from './footer';
import './style.scss';
import { openConfirmationPopup, ConfirmConfigs } from 'store/confirmationPopup';
import ConnectedConfirmationPopup from 'src/components/ConfirmationPopup';
import confirmUtils from 'store/confirmationPopup/utils';

import { getPermissions, getRoles } from 'src/store/account/selectors';
import AppMenuItems from './menuItems';
import { getIsCheckingAuth } from 'src/store/authentication/selectors';
class MainLayoutComponent extends React.Component<any> {
  state = {
    open: false,
  };

  handleDrawerVisibility = () => {
    this.setState({ open: !this.state.open });
  };
  handleLogout = e => {
    const { logout, history } = this.props;
    logout();
  };

  componentDidMount() {
    confirmUtils.confirm = this.confirmPromise.bind(this);
  }

  confirmPromise = (_configs: ConfirmConfigs) => {
    return new Promise<boolean>((rs, rj) => {
      const configs: ConfirmConfigs = {
        ..._configs,
        ok: (...p) => {
          _configs.ok && _configs.ok(...p);
          rs(true);
        },
        cancel: c => {
          _configs.cancel && _configs.cancel();
          rs(false);
        },
      };
      this.props.openConfirmationPopup(configs);
    });
  };

  render() {
    const { classes, menuItems, isCheckingAuth } = this.props;
    const { open } = this.state;
    return (
      <div className='fullHeight'>
        {!isCheckingAuth && (
          <HeaderComponent handleClick={this.handleDrawerVisibility} items={menuItems} handleLogout={this.handleLogout} />
        )}
        <div className={classes.main}>
          <DrawerComponent items={menuItems} isOpen={open && !isCheckingAuth} />
          <div
            className={classNames(classes.mainContainer, classes.content, classes.contentLeft, {
              [(classes.contentShift, classes.contentShiftLeft)]: open,
            })}
          >
            <main className={classes.mainContent}>
              <ConnectedConfirmationPopup />
              {AppRoutes}
            </main>
            <FooterComponent style={{ borderTop: '1px solid rgb(219, 219, 219)' }} />
          </div>
        </div>
      </div>
    );
  }
}

const menuItemsSelector = state => AppMenuItems.filter(menu => menu.isVisible(getPermissions(state), getRoles(state)));

const mapStateToProps = state => {
  return {
    isCheckingAuth: getIsCheckingAuth(state),
    menuItems: menuItemsSelector(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    openConfirmationPopup: config => {
      openConfirmationPopup(config)(dispatch);
    },
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
const Styled = withStyles(styles)(MainLayoutComponent);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Styled);
