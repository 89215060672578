import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button/Button';
import Drawer from '@material-ui/core/Drawer/Drawer';
import Paper from '@material-ui/core/Paper/Paper';
import RefreshIcon from '@material-ui/icons/Refresh';

import { connect } from 'react-redux';
import { dashboardFiltersByTab } from 'src/store/dashboards/selectors';
import { Typography } from '@material-ui/core';
import TitledBorder from 'src/components/TitledBorder';
import QuestionFilteringItem from 'src/modules/questions/studio/IngredientsPanel/QuestionFilteringItem';
import { mapFilters } from '../utils';
import { IRootState } from 'src/store/rootReducer';
import { OceanDataType } from 'src/shared/models/SqlColumn';
import { isOperatorCurrentOrLast } from 'src/shared/models/OiFilter';
import { APP_LOCAL_DATE_FORMAT } from 'src/constants';
import moment from 'moment';

const FilterDrawer = props => {
  const { loading, isOpen, onclose, filters, columnsMap, onFilter } = props;
  const [filtersMapped, setFiltersMapped] = useState([] as any);

  useEffect(
    () => {
      const _f = mapFilters(filters, columnsMap);
      setFiltersMapped(_f);
    },
    [filters]
  );

  const toggleDrawer = e => {
    onclose();
  };
  const handlePreviewFilters = e => {
    filtersMapped;
    onFilter(filtersMapped);
  };
  const handleChangeFilterItem = (v, index) => {
    const _filter = filtersMapped.slice(0);
    _filter[index] = v;
    setFiltersMapped(_filter);
  };

  return (
    <Drawer open={isOpen} onClose={toggleDrawer} anchor='right'>
      <Paper style={{ width: 400, padding: 20, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ flex: '1 1 auto', width: '100%' }}>
          {filters && (
            <div className='filter-by-list'>
              <TitledBorder color={'#10ab5b'}>Filter By</TitledBorder>
              {filters.length > 0 ? (
                filtersMapped.map((filter, i) => {
                  return (
                    <QuestionFilteringItem key={i} item={filter} itemIndex={i} source={'questionView'} onUpdated={handleChangeFilterItem} />
                  );
                })
              ) : (
                <Typography variant='body1'> No Filters Yet! </Typography>
              )}
            </div>
          )}
        </div>
        <Button className='btn-rounded' variant='contained' type='button' color='primary' disabled={loading} onClick={handlePreviewFilters}>
          {loading ? (
            <React.Fragment>
              <RefreshIcon /> Loading
            </React.Fragment>
          ) : (
            ' Preview '
          )}
        </Button>
      </Paper>
    </Drawer>
  );
};

const mapState = (state: IRootState) => ({
  filters: dashboardFiltersByTab(state),
  columnsMap: state.columnsMap.byNames,
});
const mapDispatch = dispatch => {
  return {};
};

export type StateProps = ReturnType<typeof mapState>;
export type DispatchProps = ReturnType<typeof mapDispatch>;

const connected = connect(
  mapState,
  mapDispatch
)(FilterDrawer);

export default connected;
