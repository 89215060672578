import toastr from 'src/components/toastr';
export const updateJobActivation = (activation, confirm, taskItem) => {
  let msg = 'Are you sure you want to play this task ?';
  if (taskItem.active) {
    msg = 'Are you sure you want to pause this task ?';
  }
  confirm({
    msg: msg,
    okText: 'ok',
    ok: () => {
      let body = {
        active: !taskItem.active,
        id: taskItem.id,
        cronExp: taskItem.cronExp,
        displayName: taskItem.displayName,
        jobService: 'EXPORTER',
        payload: taskItem.payload,
      };
      activation(body)
        .then(response => {
          toastr.success('Task Status updated successfully');
        })
        .catch(err => {
          toastr.error('Error updating Task Status');
          console.log(err);
        });
    },
  });
};
export const deleteTask = (confirm, deleteTask, id) => {
  confirm({
    msg: `Are you sure you want to delete this task ?`,
    ok() {
      deleteTask(id)
        .then(() => {
          toastr.success('Task deleted successfully updated');
        })
        .catch(err => {
          toastr.error('Error deleting Task');
        });
    },
  });
};
