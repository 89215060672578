import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  List,
  ListItem,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import * as React from 'react';
import toastr from 'src/components/toastr';
import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import oceancoreApis from 'src/shared/apis/oceancoreApis';
import BackupFormModal from './components/BackupModal/BackupModal';
import GDAFiltersList from './components/GDAFiltersList';
import RestoreItemsFormContainer from './components/RestoreItemsForm/RestoreItemsFormContainer';
import { IProps, IState } from './models';

class SettingsComponent extends React.Component<IProps, IState> {
  state = {
    expandGdaPanel: false,
    expandBackupResotre: false,
    settings: undefined,
  } as IState;

  componentWillMount() {
    oceancoreApis.getSystemSettings().then(settings => {
      console.log('Meizzooo', settings);
      this.setState({ settings });
    });
  }

  toggleGDA = () => {
    const { settings } = this.state;
    if (!settings) return;
    settings.enabledGDA = !settings.enabledGDA;
    this.setState({
      settings,
      expandGdaPanel: settings.enabledGDA,
    });
    return oceancoreApis.updateSystemSettings(settings).then(
      s => {
        toastr.success(`GDA Filters ${settings.enabledGDA ? 'enabled' : 'disabled'} successfully`);
      },
      err => {
        settings.enabledGDA = !settings.enabledGDA;
        this.setState({
          settings,
        });
        console.error('Failed to update GDA Settings.', err);
        toastr.error('Failed to update GDA Settings.');
      }
    );
  };

  render() {
    const { settings = {} as any } = this.state;
    const enableGDA = Boolean(settings.enabledGDA);
    return (
      <div>
        <BreadCrumb
          path={
            <>
              Home <KeyboardArrowRight /> Settings <div className='flex' />
            </>
          }
        />
        <ContentPage>
          <ExpansionPanel expanded={this.state.expandBackupResotre}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={e => this.setState(s => ({ expandBackupResotre: !s.expandBackupResotre }))}
            >
              <Typography>Backup/Restore </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List>
                <ListItem>
                  <Tooltip title='Click to add Backup'>
                    <BackupFormModal
                      onSave={e => null}
                      renderOpener={open => (
                        <Button>
                          <FileDownloadIcon color='primary' style={{ marginRight: 10 }} /> Backup
                        </Button>
                      )}
                    />
                  </Tooltip>
                </ListItem>

                <ListItem>
                  <RestoreItemsFormContainer />
                </ListItem>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel expanded={enableGDA && this.state.expandGdaPanel}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={e => this.setState(s => ({ expandGdaPanel: !s.expandGdaPanel }))}
            >
              GDA Settings
              <Tooltip title={enableGDA ? 'disable' : 'enable' + ' GDA'} style={{ padding: 0 }} onClick={e => e.stopPropagation()}>
                <Switch value='checkedC' checked={enableGDA} onChange={this.toggleGDA} style={{ height: 20 }} />
              </Tooltip>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: 0 }}>
              <div style={{ width: '100%' }}>
                <GDAFiltersList />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </ContentPage>
      </div>
    );
  }
}
export default SettingsComponent;
