import { openConfirmationPopup } from 'store/confirmationPopup';
import { connect } from 'react-redux';
import { getTasks, deleteTask, activation } from 'src/store/tasks/tasks.action';
import TasksList from './TasksList';
const mapStateToProps = state => ({
  tasks: state.tasks.data,
  deleteResponse: state.tasks.deleteResponse,
  activationData: state.tasks.activationData,

  loading: state.tasks.loading,
  activationLoader: state.tasks.activationLoading,
  deleteLoader: state.tasks.deleteLoading,
});

const mapDispatchToProps = {
  confirm: openConfirmationPopup,
  getTasks,
  deleteTask,
  activation,
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TasksList);
