import { connect } from 'react-redux';
import { dashboardFiltersByTab } from 'src/store/dashboards/selectors';
import { IRootState } from 'src/store/rootReducer';
import StudioTabs from './StudioTabs';

const mapState = (state: IRootState) => ({
  filters: dashboardFiltersByTab(state),
});

export type StateProps = ReturnType<typeof mapState>;
const connected = connect(mapState)(StudioTabs);
export default connected;
