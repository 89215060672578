import { OceanDataType, SQLColumn } from 'src/shared/models/SqlColumn';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT } from 'src/constants';
import { ComparisonOperator } from './ComparisonOperator';
import { OiFilterDropdownItem } from './OiFilterDropdownItem';

export enum FilterValueType {
  SINGLE_VALUE = 'SINGLE_VALUE',
  RANGE = 'RANGE',
}

export const ComparisonOperatorIntMap = [
  ComparisonOperator.EQUAL,
  ComparisonOperator.NOT_EQUAL,
  ComparisonOperator.GREATER_THAN,
  ComparisonOperator.GREATER_THAN_EQUAL,
  ComparisonOperator.LESS_THAN,
  ComparisonOperator.LESS_THAN_EQUAL,
  ComparisonOperator.BETWEEN,
  ComparisonOperator.CONTAINS,
  ComparisonOperator.IN,
  ComparisonOperator.NOT_IN,
  ComparisonOperator.IS_NULL,
  ComparisonOperator.IS_NOT_NULL,
  ComparisonOperator.START_WITH,
  ComparisonOperator.END_WITH,

  ComparisonOperator.LAST_HOURS,
  ComparisonOperator.LAST_DAYS,
  ComparisonOperator.LAST_MONTHS,
  ComparisonOperator.LAST_QUARTERS,
  ComparisonOperator.LAST_YEARS,
  ComparisonOperator.LAST,

  ComparisonOperator.CURRENT_DAY,
  ComparisonOperator.CURRENT,
  ComparisonOperator.CURRENT_MONTH,
  ComparisonOperator.CURRENT_QUARTER,
  ComparisonOperator.CURRENT_YEAR,
];
export function isCurrentOperator(op) {
  const currentOps = [
    ComparisonOperator.CURRENT_DAY,
    ComparisonOperator.CURRENT,
    ComparisonOperator.CURRENT_MONTH,
    ComparisonOperator.CURRENT_QUARTER,
    ComparisonOperator.CURRENT_YEAR,
  ];
  return op == ComparisonOperator.CURRENT || ([] as any).concat(currentOps).indexOf(op) > -1 || /CURRENT_/g.test('' + op);
}
export function isLastOperator(op) {
  const lastOps = [
    ComparisonOperator.LAST_HOURS,
    ComparisonOperator.LAST_DAYS,
    ComparisonOperator.LAST_MONTHS,
    ComparisonOperator.LAST_QUARTERS,
    ComparisonOperator.LAST_YEARS,
    ComparisonOperator.LAST,
  ];
  return op == ComparisonOperator.LAST || ([] as any).concat(lastOps).indexOf(op) > -1 || /LAST_/g.test('' + op);
}

export function isOperatorCurrentOrLast(op) {
  return isLastOperator(op) || isCurrentOperator(op);
}

export const oiQueryFiltersSelectOptions: OiFilterDropdownItem[] = [
  {
    displayName: 'between',
    operator: ComparisonOperator.BETWEEN,
    appliesFor: [OceanDataType.DATE, OceanDataType.NUMBER],
    valueType: FilterValueType.RANGE,
    defaultValue: [undefined, undefined],
  },
  {
    displayName: 'greater than',
    operator: ComparisonOperator.GREATER_THAN,
    appliesFor: [OceanDataType.DATE, OceanDataType.NUMBER],
    defaultValue: [0],
  },
  {
    displayName: 'greater than or equals',
    operator: ComparisonOperator.GREATER_THAN_EQUAL,
    appliesFor: [OceanDataType.DATE, OceanDataType.NUMBER],
    defaultValue: [0],
  },
  {
    displayName: 'less than',
    operator: ComparisonOperator.LESS_THAN,
    appliesFor: [OceanDataType.DATE, OceanDataType.NUMBER],
    defaultValue: [undefined, undefined],
  },
  {
    displayName: 'less than or equals',
    operator: ComparisonOperator.LESS_THAN_EQUAL,
    appliesFor: [OceanDataType.DATE, OceanDataType.NUMBER],
    defaultValue: [undefined, undefined],
  },
  {
    displayName: 'current',
    operator: ComparisonOperator.CURRENT,
    appliesFor: [OceanDataType.DATE],
    defaultValue: ['', ComparisonOperator.CURRENT_DAY],
  },
  {
    displayName: 'last',
    operator: ComparisonOperator.LAST,
    appliesFor: [OceanDataType.DATE],
    defaultValue: ['7', ComparisonOperator.LAST_DAYS],
  },
  {
    displayName: 'is empty',
    operator: ComparisonOperator.IS_NULL,
    isGeneric: true,
    appliesFor: [],
    defaultValue: [],
  },
  {
    displayName: 'is not empty',
    operator: ComparisonOperator.IS_NOT_NULL,
    isGeneric: true,
    appliesFor: [],
    defaultValue: [],
  },

  {
    displayName: 'equals',
    operator: ComparisonOperator.EQUAL,
    isGeneric: true,
    appliesFor: [],
    defaultValue: [],
  },
  {
    displayName: 'is not equal',
    operator: ComparisonOperator.NOT_EQUAL,
    isGeneric: true,
    appliesFor: [],
    defaultValue: [],
  },

  {
    displayName: 'contains',
    operator: ComparisonOperator.CONTAINS,
    appliesFor: [OceanDataType.STRING],
    defaultValue: [],
  },
  {
    displayName: 'is in',
    operator: ComparisonOperator.IN,
    appliesFor: [OceanDataType.STRING],
    defaultValue: [],
  },
  {
    displayName: 'is not in',
    operator: ComparisonOperator.NOT_IN,
    appliesFor: [OceanDataType.STRING],
    defaultValue: [],
  },
  {
    displayName: 'starts with',
    operator: ComparisonOperator.START_WITH,
    appliesFor: [OceanDataType.STRING],
    defaultValue: [],
  },
  {
    displayName: 'ends with',
    operator: ComparisonOperator.END_WITH,
    appliesFor: [OceanDataType.STRING],
    defaultValue: [],
  },
  {
    displayName: 'Is null or empty',
    operator: ComparisonOperator.IS_NULL_OR_EMPTY,
    appliesFor: [],
    defaultValue: [],
  },
];

oiQueryFiltersSelectOptions.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

export const isInListOperator = op => op === ComparisonOperator.IN || op === ComparisonOperator.NOT_IN;
export const isEqualityOperator = op => op === ComparisonOperator.EQUAL || op === ComparisonOperator.NOT_EQUAL;
export const isEmptinessOperator = op => op === ComparisonOperator.IS_NULL || op === ComparisonOperator.IS_NOT_NULL;

export const ColumnDefaultComparisonOperator = (column: SQLColumn) => {
  switch (column.oceanDataType) {
    case OceanDataType.NUMBER: {
      return oiQueryFiltersSelectOptions.find(item => item.operator == ComparisonOperator.IS_NOT_NULL);
    }
    case OceanDataType.STRING: {
      return oiQueryFiltersSelectOptions.find(item => item.operator == ComparisonOperator.IS_NOT_NULL);
    }
    case OceanDataType.DATE: {
      return oiQueryFiltersSelectOptions.find(item => item.operator == ComparisonOperator.LAST);
    }
    default:
      return oiQueryFiltersSelectOptions.find(item => item.operator == ComparisonOperator.EQUAL);
  }
};

export const getFilterDefaultValue = (column, operatorItem) => {
  switch (column.oceanDataType) {
    case OceanDataType.NUMBER: {
      return getNumberColumnDefaultValue(operatorItem.operator);
    }
    case OceanDataType.STRING: {
      return getStringColumnDefaultValue(operatorItem.operator);
    }
    case OceanDataType.DATE: {
      return getDateColumnDefaultValue(operatorItem.operator);
    }
    default:
      return getStringColumnDefaultValue(operatorItem.operator);
  }
};

export const getNumberColumnDefaultValue = operator => {
  switch (operator) {
    case ComparisonOperator.BETWEEN: {
      return [0, 100];
    }
    default:
      return [0];
  }
};
export const getStringColumnDefaultValue = operator => {
  return [];
};
export const getDateColumnDefaultValue = operator => {
  switch (operator) {
    case ComparisonOperator.LAST: {
      return [7, ComparisonOperator.LAST_DAYS];
    }
    case ComparisonOperator.CURRENT: {
      return ['', ComparisonOperator.CURRENT_DAY];
    }
    case ComparisonOperator.BETWEEN: {
      return [moment().format(APP_LOCAL_DATE_FORMAT), moment().format(APP_LOCAL_DATE_FORMAT)];
    }
    default:
      return [moment().format(APP_LOCAL_DATE_FORMAT)];
  }
};
