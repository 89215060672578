import discoveryApis from 'src/shared/apis/discoveryApis';
import { SQLTable } from 'src/shared/models/SqlTable';
import { SQLColumn } from 'src/shared/models/SqlColumn';
export interface OiColumnsCacheMap {
  [key: string]: SQLColumn;
}
const MAPPED_COLUM_LS_KEY = 'OIC_MAPPED_COLS';
const hashSeparator = ':@:';
let columnsMap: OiColumnsCacheMap | undefined = undefined;

export function getColumnCacheName(tableName: string, columnName: string) {
  return tableName + hashSeparator + columnName;
}

export function loadAndCacheTables() {
  const stored = localStorage.getItem(MAPPED_COLUM_LS_KEY);
  if (columnsMap) {
    return Promise.resolve(columnsMap);
  }
  if (stored && stored !== 'undefined') {
    try {
      const columnsByName = JSON.parse(stored);
      columnsMap = { ...columnsByName };
      return Promise.resolve(columnsMap);
    } catch (e) {
      console.error("Can't load mapped columns from localstorage");
    }
  }

  return fetchColumnsMapFromApi();
}

function fetchColumnsMapFromApi() {
  return discoveryApis.getDatabases().then(databases => {
    const tablesPromisses = databases.map(db => {
      return discoveryApis.getDatabasePublicTables(db.id);
    });
    return Promise.all(tablesPromisses).then((dbTablesResolved: any[]) => {
      const tables = [].concat(...dbTablesResolved);
      const columnsByTableColName = mapTablesToColumnsMap(tables);
      localStorage.setItem(MAPPED_COLUM_LS_KEY, JSON.stringify(columnsByTableColName));
      columnsMap = { ...columnsByTableColName };
      return columnsMap;
    });
  });
}

function mapTablesToColumnsMap(tables) {
  const columnsByTableColNameJSON = {};
  tables.forEach((table: SQLTable) => {
    table.columns.forEach(column => {
      columnsByTableColNameJSON[getColumnCacheName(table.name, column.dbName)] = column;
    });
  });
  return columnsByTableColNameJSON;
}
