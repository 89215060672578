import * as React from 'react';
import { List, ListItem, ListItemText, Collapse, ListItemIcon, InputAdornment, TextField } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import SearchIcon from '@material-ui/icons/Search';
import { getColumnTypeIconClass } from 'src/shared/util/SqlColumnUtils';
import { SQLTable } from 'src/shared/models/SqlTable';
import { simpleFuzzySearchFilter } from 'modules/questions/studio/utils';
import OiIcon from 'src/assets/icons';
import Styled from './Styled';
import { QueryViewItem } from '../../models';
import { IProps, IState } from './models';
class ColumnSelectList extends React.Component<IProps, IState> {
  state = {
    columnFilter: '',
    openedTable: 0,
    tables: [],
  };

  updateFilterValue = e => {
    this.setState({ columnFilter: e.target.value });
  };

  componentDidUpdate(prevProps) {
    const { tables, opendTable: selectedTable } = this.props;
    try {
      if (prevProps.selectedTable && selectedTable && prevProps.selectedTable.id != selectedTable.id) {
        this.setState({ tables, openedTable: selectedTable.id });
      }
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { onSelectColumn, onSelectAggregateColumn, classes, columnTypes, columnsOceanDataTypes, ...props } = this.props;
    let aggregateColumns: QueryViewItem[] = props.aggregateColumns || [];
    let tables: SQLTable[] = this.props.tables;
    const openedTable = this.state.openedTable;

    if (this.state.columnFilter) {
      aggregateColumns = aggregateColumns.filter(simpleFuzzySearchFilter(this.state.columnFilter, 'displayName'));
    }

    const colRenderer = col => (
      <ListItem
        button
        key={col.id || col.vid}
        classes={{
          root: classes.listItem,
        }}
        onClick={e => onSelectColumn && onSelectColumn(col, e)}
        style={{ paddingTop: 5, paddingBottom: 5 }}
      >
        <ListItemIcon>
          <OiIcon path={getColumnTypeIconClass(col)} scale={'24px'} />
        </ListItemIcon>
        {col.displayName}
      </ListItem>
    );

    const search = (
      <>
        <TextField
          type='search'
          value={this.state.columnFilter}
          onChange={this.updateFilterValue}
          className={classes.search}
          placeholder='Filter Columns'
          id='search'
          autoFocus
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </>
    );
    const aggregateKey = tables.length;
    return (
      <List component='nav' style={{ maxHeight: 350, padding: 0 }}>
        {search}
        {(!tables && aggregateColumns.map(colRenderer)) || <></>}
        {tables &&
          tables.map((table, i) => {
            let columns = columnTypes && columnTypes.length ? table.columns.filter(c => columnTypes.includes(c.type)) : table.columns;
            columns = columnsOceanDataTypes ? columns.filter(c => columnsOceanDataTypes.includes(c.oceanDataType)) : columns;
            columns = this.state.columnFilter ? columns.filter(simpleFuzzySearchFilter(this.state.columnFilter, 'displayName')) : columns;
            return (
              <React.Fragment key={i}>
                <ListItem className={classes.tableNameWrap} onClick={e => this.setState({ openedTable: openedTable !== i ? i : null })}>
                  <OiIcon className={classes.tableIcon} path={i == 0 ? 'BulletedList' : 'ColumnGroupBy'} /> &nbsp;
                  {'   ' + table.displayName}
                  <ListItemText inset />
                  {openedTable == i ? <ExpandLess /> : <ExpandMore />}
                </ListItem>{' '}
                <Collapse in={openedTable == i} timeout='auto' unmountOnExit className={classes.innerList}>
                  <List component='nav' disablePadding>
                    {columns.map(colRenderer)}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}
        {/* Aggregate Columns (Query View Items)./  */}
        {aggregateColumns.length ? (
          <>
            <ListItem
              className={classes.tableNameWrap}
              onClick={e => this.setState({ openedTable: openedTable !== aggregateKey ? aggregateKey : null })}
            >
              <OiIcon className={classes.tableIcon} path={'BulletedList'} /> &nbsp;
              {'   Aggregate Columns'}
              <ListItemText inset />
              {openedTable == aggregateKey ? <ExpandLess /> : <ExpandMore />}
            </ListItem>{' '}
            <Collapse in={openedTable == aggregateKey} timeout='auto' unmountOnExit className={classes.innerList}>
              <List component='nav' disablePadding>
                {aggregateColumns.map(viewItem => (
                  <ListItem
                    button
                    key={viewItem.vid}
                    classes={{
                      root: classes.listItem,
                    }}
                    onClick={e => onSelectAggregateColumn && onSelectAggregateColumn(viewItem, e)}
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  >
                    <ListItemIcon>
                      <OiIcon path={'HashTagBold'} scale={'24px'} />
                    </ListItemIcon>
                    {viewItem.displayName}
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </>
        ) : null}
      </List>
    );
  }
}

export default Styled(ColumnSelectList);
