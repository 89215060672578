import discoveryApis from './discoveryApis';
import oceanAuthApis from './oceanAuthApis';
import oceancoreApis from './oceancoreApis';
import oceanExporterApis from './oceanExporterApis';

export default {
  ...discoveryApis,
  ...oceanAuthApis,
  ...oceancoreApis,
  ...oceanExporterApis,
};
