import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboards from 'src/modules/dashboards/home';
import StudioDashboard from './Studio';
import EditDashboard from './Edit';
import ViewDashboard from './View';
import CanActivateModule from 'src/components/Permissions/CanActivateModule';
import { AclModules } from 'src/services/permissions/enum';
import { canDo, connectedCanDo } from 'src/services/permissions/utils';
import dashboardAccess from 'src/services/permissions/abilities/dashboard';
import NotAuthorizedPage from 'src/layout/NotAuthorized';

const Routes = ({ match }) => {
  return (
    <CanActivateModule moduleName={AclModules.dashboards}>
      <Switch>
        <Route path={`${match.url}/new`} component={connectedCanDo(dashboardAccess.create) ? StudioDashboard : NotAuthorizedPage} />
        <Route exact path={`${match.url}/edit/:id`} component={connectedCanDo(dashboardAccess.edit) ? EditDashboard : NotAuthorizedPage} />
        <Route exact path={`${match.url}/:id`} component={connectedCanDo(dashboardAccess.view) ? ViewDashboard : NotAuthorizedPage} />
        <Route path={`${match.url}`} component={Dashboards} />
      </Switch>
    </CanActivateModule>
  );
};

export default Routes;
