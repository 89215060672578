import { ConnectionTemplate } from '../models/connectionTemplate';
import { SQLDatabase } from '../models/SqlDatabase';
import { SQLTable } from '../models/SqlTable';
import axios from 'axios';
import { ColumnAnalysis, ColumnValue } from '../models/SqlColumn';
import { DiscoveryScheduledTask } from '../models/ScheduledTask';
import { discoveryUrl } from './baseUrls';
import { DatabaseAccessListDto, PostDatabaseAccessListDto } from '../models/DatabaseAccessListDto';
import { SharedEntityDto } from 'src/components/EntitiesSharedWithUser/SharedEntitiesList';

const getConnectionTemplates = () => {
  const url = discoveryUrl + '/connection-templates';
  return axios.get<ConnectionTemplate[]>(url).then(resp => resp.data);
};
const saveDatabaseConnection = data => {
  const url = discoveryUrl + '/databases';
  return axios.post(url, data).then(resp => resp.data);
};
const updateDatabaseConnection = data => {
  const url = discoveryUrl + '/databases';
  return axios.put(url, data).then(resp => resp.data);
};
const getDatabases = () => {
  const url = discoveryUrl + '/databases?size=9999';
  return axios.get(url).then(resp => resp.data);
};
const getDatabaseById = id => {
  const url = discoveryUrl + `/databases/${id}`;
  return axios.get<SQLDatabase>(url).then(resp => resp.data);
};
const getDatabaseRawTables = dbId => {
  const url = discoveryUrl + `/${dbId}/tables?size=9999`;
  return axios.get<SQLTable[]>(url).then(resp => resp.data);
};
const getDatabasePublicTables = dbId => {
  const url = discoveryUrl + `/${dbId}/tables/public?size=9999`;
  return axios.get<SQLTable[]>(url).then(resp => {
    const tables = resp.data;
    tables.forEach(table => {
      table.columns.forEach(column => {
        column.dbTableName = column.dbTableName || table.name;
      });
    });
    return tables;
  });
};
export interface ShareDatabaseRequestDto {
  usernames: string[];
  tableIds: number[];
}
function shareDatabase(payload: ShareDatabaseRequestDto): any {
  return axios
    .post(discoveryUrl + `/databases/share`, payload)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}

function unshareDatabase(payload: ShareDatabaseRequestDto): any {
  return axios
    .post(discoveryUrl + `/databases/unshare`, payload)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}

const saveTable = (body: SQLTable) => {
  const url = discoveryUrl + '/tables';
  return axios.put(url, body).then(resp => resp.data);
};
const refreshColumnAnalysis = id => {
  const url = discoveryUrl + `/column/${id}/analyse`;
  return axios.post<ColumnAnalysis[]>(url).then(resp => resp.data);
};
const getColumnAnalysis = id => {
  const url = discoveryUrl + `/column/${id}/analysis`;
  return axios.get<ColumnAnalysis[]>(url).then(resp => (resp.data || [])[0]);
};
const getColumnValues = id => {
  const url = discoveryUrl + `/column/${id}/values?size=9999`;
  return axios.get<ColumnValue[]>(url).then(resp => resp.data);
};
const getScheduledTasksList = () => {
  const url = discoveryUrl + `/jobs?size=9999`;
  return axios.get<DiscoveryScheduledTask[]>(url).then(resp => resp.data || []);
};
export function refreshDatabaseApi(id): Promise<any> {
  return axios.post(discoveryUrl + `/databases/${id}/analyse`);
}

const getDatabaseAccessList = dbId => {
  const url = discoveryUrl + `/databases/${dbId}/user_access_information`;
  return axios.get<DatabaseAccessListDto>(url).then(resp => resp.data || []);
};

const updateDatabaseTablesAccessList = (dbId, payload: PostDatabaseAccessListDto) => {
  const url = discoveryUrl + `/databases/${dbId}/user_access_information`;
  return axios.post<DatabaseAccessListDto>(url, payload).then(resp => resp.data || []);
};

const getUserSharedDatabases = userName => {
  const url = discoveryUrl + `/databases/shared_databases_for_username/` + userName;
  return axios.get<SharedEntityDto[]>(url).then(r => r.data);
};

const getUserSharedTables = userName => {
  const url = discoveryUrl + `/tables/shared_tables_for_username/` + userName;
  return axios.get<SharedEntityDto[]>(url).then(r => r.data);
};

export default {
  /* databases */
  saveTable,
  getDatabases,
  getColumnValues,
  getDatabaseById,
  shareDatabase,
  unshareDatabase,
  getColumnAnalysis,
  getDatabaseRawTables,
  getDatabaseAccessList,
  getScheduledTasksList,
  refreshColumnAnalysis,
  getConnectionTemplates,
  saveDatabaseConnection,
  getDatabasePublicTables,
  updateDatabaseConnection,
  refreshDatabaseApi,
  updateDatabaseTablesAccessList,
  getUserSharedDatabases,
  getUserSharedTables,
};
