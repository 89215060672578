import { ColumnExpression } from './OiQueryColumn';
import { ColumnMetadatum } from './ColumnMetadatum';

export interface ExecuterResponseDto {
  resultset: Array<string[]>;
  metadata: ColumnMetadatum[];
  options?: any;
  rowOffset: number;
  runtime: number;
  error: null;
  queryName: string;
  initialQueryName: null;
  title: string;
  data: any[];
  viewType: string;
  pageNum: number;
  pageLength: number;
  actualPageLength: number;
  totalCount: number;
  totalPages: number;
  remainingCount: number;
  remainingPages: number;
  chartLibraryName: string;
  rownum: number;
}

export function getMetaColumnNameInDb(c: any) {
  return c.expression && c.expression.length == 1 ? (c.expression[0] as ColumnExpression).dbColumn : '';
}
