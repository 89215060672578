import { withStyles, createStyles, StyleRulesCallback, Theme } from '@material-ui/core';
import { Colors } from 'src/shared/theme';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    '& .full-height': {
      height: '82vh !important',
      padding: '0 !important',
    },
    mainCard: {
      padding: 20,
      position: 'relative',
      height: '100%',
      overflow: 'auto',
    },

    resetBtnPanel1: {
      margin: 'auto',
      marginRight: 10,
    },
    questionPanel1: {
      position: 'relative',
      padding: 0,
      minHeight: 'calc(100vh - 225px)',
      height: '100%',
      width: '25%',
      flex: '0 0 auto',
    },
    ingredientsSectionTitle: {
      margin: '15px 15px 15px 0px',
    },
    previewAreaContentWrap: {
      width: 'calc(100% - 110px)',
      height: '100%',
      display: 'flex',
    },
    panelHeader: {
      height: 49,
      background: 'white',
      overflow: 'hidden',
      display: 'flex',
      width: '100%',
      flex: '1 1 auto',
      borderBottom: `1px solid ${Colors.brandGreyBorders}`,
    },
    titlesWrapper: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
    },
    titleItem: {
      padding: '10px 15px',
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 600,
    },
    viewsTitleItem: {
      color: Colors.brandBlue,
    },
    groupsTitleItem: {
      color: Colors.brandRed,
    },
    filtersTitleItem: {
      color: Colors.brandGreen,
    },
    panel1ScrollableArea: {
      height: 'calc(100% - 49px)',
      paddingRight: 0,
      paddingLeft: 10,
      scrollbars: {
        width: '100%',
        height: '100%',
      },
    },
    scrollbars: {
      width: '100%',
      height: '100%',
    },
    panel2: {
      flex: '1 1 auto',
      padding: 0,
      width: '70vw',
      marginLeft: 10,
    },
    emptyFormPreviewAreaPlaceHolderWrap: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    },
    myFlex: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    questionDeleted: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      fontSize: '20px',
    },
  });
export default withStyles(styles);
