import * as React from 'react';
import { Typography } from '@material-ui/core';
import { ReferenceObject } from 'popper.js';
export const isEmpty = obj => Object.keys(obj).length === 0;
export const getErrorMessage = errorData => {
  let errors = errorData;
  if (errorData instanceof Array) {
    errors = (errorData || []).reduce((acc, item) => {
      Object.keys(item).map(key => {
        if (item[key] instanceof Array) {
          item[key] = item[key].join(' & ');
        } else {
          item[key] = item[key];
        }
      });
      return { ...item, ...acc };
    }, {});
    return Object.keys(errors).map(key => {
      return (
        <p>
          <Typography variant='body1' color='error' gutterBottom>
            <strong>{key}: </strong> {errors[key]}
          </Typography>
        </p>
      );
    });
  } else if (typeof errorData == 'string') {
    return (
      <p>
        <Typography variant='body1' color='error' gutterBottom>
          <strong>Error </strong> {errors}
        </Typography>
      </p>
    );
  }
};

export const objToQParams = o =>
  Object.keys(o)
    .map(k => `${k}=${o[k]}`)
    .join('&');

export const getAnchorElementFromEvent = e => {
  let x: ClientRect = {
    height: 20,
    width: 20,
    left: e.clientX || e.nativeEvent.clientX + 20,
    top: e.clientY || e.nativeEvent.clientY,
    bottom: 0,
    right: 0,
  };
  let el: ReferenceObject = {
    clientHeight: 10,
    clientWidth: 10,
    getBoundingClientRect() {
      return x;
    },
  };
  return el;
};

export const standardDeviation = arr => {
  var n = arr.length;
  var sum = 0;

  arr.map(function(data) {
    sum += data;
  });

  var mean = sum / n;

  var variance = 0.0;
  var v1 = 0.0;
  var v2 = 0.0;
  var stddev;

  if (n != 1) {
    for (var i = 0; i < n; i++) {
      v1 = v1 + (arr[i] - mean) * (arr[i] - mean);
      v2 = v2 + (arr[i] - mean);
    }

    v2 = (v2 * v2) / n;
    variance = (v1 - v2) / (n - 1);
    if (variance < 0) {
      variance = 0;
    }
    stddev = Math.sqrt(variance);
  }

  return {
    mean: Math.round(mean * 100) / 100,
    variance: variance,
    deviation: Math.round(stddev * 100) / 100,
  };
};

export const deepClone = (...objects) => {
  const merged = Object.assign({}, ...objects);
  return JSON.parse(JSON.stringify(merged));
};

export const joinToArray = (array: Array<any>, glue: any) => {
  return array.reduce((acc, item, index, org) => {
    acc.push(item);
    if (index < org.length - 1) {
      acc.push(glue);
    }
    return acc;
  }, []);
};
