// those variables are read from environments in index.html
//    <script src="%PUBLIC_URL%/environment.js"></script>
let communityName = window['communityName'];
let gatewayUrl = window['gatewayUrl'];
let keycloakServerUrl = window['keycloakServerUrl'];
let keycloakResource = window['keycloakResource'];
let keycloakClientSecret = window['keycloakClientSecret'];
let useKeycloakSSO = window['useKeycloakSSO'];
const ENV = {
  useKeycloakSSO: Boolean(useKeycloakSSO),
  communityName: communityName || 'local',
  gatewayUrl: gatewayUrl,
  KeycloakServerUrl: keycloakServerUrl,
  keycloakOptions: {
    'ssl-required': 'none',
    resource: keycloakResource,
    clientSecret: keycloakClientSecret,
    'public-client': false,
    'use-resource-role-mappings': true,
    'confidential-port': 0,
  },
};
const hostname = window.location.hostname;
let baseUrl = hostname == 'localhost' ? 'http://10.162.176.34:8080' : `http://${hostname}:8080`;
if (ENV.gatewayUrl) {
  baseUrl = ENV.gatewayUrl;
}

export const BaseURL = baseUrl; //window['gatewayUrl'] || (hostname == 'localhost' ? 'http://10.162.176.34:8080' : `http://${hostname}:8080`);
export const CommunityURlPrefix = ENV.communityName ? '/' + ENV.communityName + '-' : '/';
export default ENV;
