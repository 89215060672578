import { viewTypesOptionsList, VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import moment from 'moment';
import { APP_LOCAL_DATE_FORMAT, store } from 'src/constants';
import {
  QueryCalculatedColumnItem,
  QueryFilterViewItem,
  QueryGroupViewItem,
  QueryViewItem,
  QuestionStudioStoreState,
} from 'src/modules/questions/studio/models';

import { ColumnType, OceanDataType, SQLColumn } from 'src/shared/models/SqlColumn';
import { getColumnValueType } from 'src/shared/util/SqlColumnUtils';
import apisService from 'src/shared/apis';
import { oiViewOperatorsWithNone } from 'src/shared/dataSources/OiFunctions';
import { SORT_DIRECTION } from 'src/shared/models';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { isInListOperator, isOperatorCurrentOrLast, oiQueryFiltersSelectOptions } from 'src/shared/models/OiFilter';
import { OiFilterDropdownItem } from 'src/shared/models/OiFilterDropdownItem';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import { OiFunction } from 'src/shared/models/OiFunction';
import { ColumnDesignType, ColumnExpression, ExpressionItem, OiQueryColumn, IQLColumnExpression } from 'src/shared/models/OiQueryColumn';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import { OiFilterDto } from 'src/shared/models/OiFilterDto';
import { SQLDatabase } from 'src/shared/models/SqlDatabase';
import { SQLTable } from 'src/shared/models/SqlTable';
import questionStudioSelectors from 'src/store/questionStudio/selectors';
import { IQLFilterExpression } from 'src/shared/models/OiQuestionQuery';
import { IAdvanceFilterGroup } from './components/AdvanceFilters/models';
import { DefaultAdvanceFilterGroupItem } from './components/AdvanceFilters/utils';
import { fixFiltersDtoMissingGlue } from '../questions.services';

export const FilterValuesJoinPattern = '-_-';
export type SerializedFilter = {
  d: boolean;
  opId: number;
  colId: number;
  values: string;
};

export const columnsTablesMapBuilder = tables => {
  const columnsMap = {};
  tables.forEach(t => {
    t.columns.forEach(c => {
      columnsMap[c.id] = {
        tableName: t.name,
      };
    });
  });
  return columnsMap;
};

const mapQueryViewItemToOiQueryColumn = (viewItem): OiQueryColumn => {
  let { column, colExpression } = viewItem;

  return {
    designType: ColumnDesignType.VIEW,
    expression: viewItem.colExpression || [
      {
        dbColumn: column.dbName,
        function: (viewItem.functionItem && viewItem.functionItem.value) || undefined,
      },
    ],
    pin: Boolean(viewItem.pin),
    grouped: Boolean(viewItem.grouped),
    label: viewItem.displayName,
    oceanDataType: colExpression ? OceanDataType.NUMBER : getColumnValueType(column),
    type: 'MEASURE',
    vid: viewItem.vid,
    // dbColumn: column.dbName,
    // functionType: (viewItem.functionItem && viewItem.functionItem.value) || undefined,
    orderIndex: viewItem.orderIndex || undefined,
    dbTable: column.dbTableName,
    // foreignKey: column.fkTargetColumnId,
    primaryKey: column.pk || undefined,
    orderDirection: viewItem.orderDirection || SORT_DIRECTION.NONE,
  } as OiQueryColumn;
};

const mapQuestionGroupItemToOiQueryColumn = (viewItem: QueryGroupViewItem): OiQueryColumn => {
  const { column } = viewItem;
  return {
    designType: ColumnDesignType.GROUPBY,
    expression: [
      {
        dbColumn: column.dbName,
        function: (viewItem.groupingOperator && viewItem.groupingOperator) || undefined,
      },
    ],
    pin: Boolean(viewItem.pin),
    label: viewItem.displayName,
    oceanDataType: getColumnValueType(column),
    type: 'DIMENSION',
    grouped: Boolean(viewItem.grouped),
    vid: viewItem.vid,
    orderIndex: viewItem.orderIndex || undefined,
    dbTable: column.dbTableName,
    // foreignKey: column.fkTargetColumnId,
    primaryKey: column.pk || undefined,
    orderDirection: viewItem.orderDirection || SORT_DIRECTION.NONE,
  } as OiQueryColumn;
};

export const mapQuestionFilterItemToOiQueryFilter = (filterItem: QueryFilterViewItem): OiFilterDto => {
  let values = filterItem.values[0];
  let operator = filterItem.filterOperator.operator;
  let valueType = getColumnValueType(filterItem.column);
  if (isOperatorCurrentOrLast(operator)) {
    operator = filterItem.values[1] as any;
    values = filterItem.values[0];
    valueType = OceanDataType.NUMBER;
  }
  if (operator == ComparisonOperator.EQUAL && valueType == OceanDataType.DATE) {
    let date = moment(filterItem.values[0]);
    filterItem.values = [date.format(APP_LOCAL_DATE_FORMAT), date.add(1, 'd').format(APP_LOCAL_DATE_FORMAT)] as any;
    operator = ComparisonOperator.BETWEEN;
    values = filterItem.values as any;
  }
  if (operator == ComparisonOperator.BETWEEN) {
    values = filterItem.values
      .slice()
      .sort()
      .join(FilterValuesJoinPattern);
  }

  if (isInListOperator(operator)) {
    values = filterItem.values.filter(v => v !== undefined && v.length > 0).join(FilterValuesJoinPattern);
  }

  return {
    dbColumn: filterItem.column.dbName,
    dbTable: filterItem.column.dbTableName,
    values: values,
    operator: operator,
    dynamic: filterItem.dynamic || false,
    functionType: filterItem.functionType || OiFunction.NONE,
    valueType: valueType,
    zoomingFilter: filterItem.zoomingFilter,
  } as OiFilterDto;
};

const colExpressionMap = (e: ExpressionItem) => {
  switch (e.t) {
    case 'op': {
      return e.value;
    }
    case 'col': {
      let col = e.value as ColumnExpression;
      return { dbColumn: col.dbColumn, function: col.function.toUpperCase() };
    }
    case 'value': {
      return { dbColumn: e.value, function: 'STATIC_VALUE' };
    }
    case 'group': {
      return ((e.value || []) as any[]).map(colExpressionMap);
    }
    default:
      return 'DEFAULT';
  }
};
export const mapVMComputedColumnToDto = (uiModel: QuestionStudioStoreState) => (colItem: QueryCalculatedColumnItem): OiQueryColumn => {
  let baseTableColumns = uiModel.baseTable.columns;
  const expression = colExpressionMap(colItem.expression);
  let oceanDataType = expression.length > 1 ? OceanDataType.NUMBER : OceanDataType.STRING;
  if (expression.length === 1) {
    const colExp = expression[0] as ColumnExpression;
    let col = baseTableColumns.find(c => c.dbName == colExp.dbColumn);
    if (colExp.function == OiFunction.NONE) {
      oceanDataType = col ? col.oceanDataType : oceanDataType;
    } else {
      oceanDataType = OceanDataType.NUMBER;
    }
  }
  return {
    designType: ColumnDesignType.COMPUTED,
    expression,
    pin: Boolean(colItem.pin),
    grouped: Boolean(colItem.grouped),
    label: colItem.displayName,
    oceanDataType: oceanDataType,
    type: 'MEASURE',
    vid: colItem.vid,
    orderIndex: colItem.orderIndex || undefined,
    dbTable: uiModel.baseTable.name,
    primaryKey: false,
    orderDirection: colItem.orderDirection || SORT_DIRECTION.NONE,
  } as OiQueryColumn;
};

const expressoinUsesAggregation = (exp: any) => {
  let useAggregation = false;
  switch (exp.t) {
    case 'op':
    case 'value': {
      useAggregation = false;
      break;
    }
    case 'col': {
      useAggregation = (exp as any).function !== OiFunction.NONE;
      break;
    }
    case 'group': {
      useAggregation = (exp as any).value.find(c => expressoinUsesAggregation(c));
      break;
    }
    default: {
      useAggregation = false;
      break;
    }
  }

  return useAggregation;
};

export const mapViewFiltersToDtoFilters = (filtersItems: QueryFilterViewItem[], op?) => {
  let queryFilters = (filtersItems || []).map(mapQuestionFilterItemToOiQueryFilter);
  return queryFilters.reduce(
    (acc, item, i) => {
      acc.push(item);
      if (i !== queryFilters.length - 1) {
        acc.push(op || 'and');
      }
      return acc;
    },
    [] as Array<IQLFilterExpression[] | IQLFilterExpression>
  );
};

export function convertQuestionVMToDto(uiModel: QuestionStudioStoreState): OiQuestionDto {
  let viewColumns = uiModel.viewsItems.map(mapQueryViewItemToOiQueryColumn);
  let computedColumns = (uiModel.computedColumns || []).map(mapVMComputedColumnToDto(uiModel));
  let groups = uiModel.groupItems.map(mapQuestionGroupItemToOiQueryColumn);
  let filterItems = mapViewFiltersToDtoFilters(uiModel.filtersItems);
  const dateDiffViewItems = viewColumns.filter(
    c => (c.expression[0] && (c.expression[0] as ColumnExpression)).function === OiFunction.DATE_DIFF
  );
  if (uiModel.advanceFilter) {
    const advanceFilter = convertFilterTreeVMToIQLFilterDTO(uiModel.advanceFilter);
    if (advanceFilter.length) {
      filterItems.length && filterItems.push('and');
      filterItems.push(advanceFilter as IQLFilterExpression[]);
    }
  }

  if (dateDiffViewItems && groups.length) {
    dateDiffViewItems.forEach(c => {
      let exp = c.expression[0] as ColumnExpression;
      exp.function = OiFunction.AVG_DATE_DIFF;
    });
  }

  const columns = [...groups, ...viewColumns, ...computedColumns];
  let relatedTables = uiModel.baseTable.relations;
  let usedRelatedTables = {};

  columns.forEach((c, i) => {
    c.columnIndex = i;
    c.orderIndex = i;
  });
  ([] as Array<any>)
    .concat(columns, filterItems, groups)
    .filter(e => e.dbTable && e.dbTable !== uiModel.baseTable.name)
    .forEach(e => {
      usedRelatedTables[e.dbTable] = relatedTables.find(r => r.refTableName == e.dbTable);
    });
  const usesAggregation = ([] as any[])
    .concat(uiModel.viewsItems, uiModel.groupItems)
    .find(c => c.functionItem && c.functionItem.value !== OiFunction.NONE);
  const computedColumnsUsesAgg = (uiModel.computedColumns || []).find(c => expressoinUsesAggregation(c.expression));

  let dto: OiQuestionDto = {
    id: uiModel.id,
    name: uiModel.name,
    deleted: false,
    active: true,
    defaultView: uiModel.viewTypeOption && uiModel.viewTypeOption.type,
    description: uiModel.description,
    version: 1,
    tag: uiModel.tags || '',
    query: {
      datasourceID: uiModel.datasourceID || (uiModel.database && uiModel.database.id),
      datasource: uiModel.database && uiModel.database.dbName,
      datasourceName: uiModel.database && uiModel.database.displayName,
      dbTable: uiModel.baseTable && uiModel.baseTable.name,
      resultLimit: Number(uiModel.limit),
      filters: fixFiltersDtoMissingGlue(filterItems), //
      columns: columns,
      usesAggregation: Boolean(computedColumnsUsesAgg || usesAggregation),
      relations: Object.values(usedRelatedTables),
    },
  } as OiQuestionDto;

  return dto;
}

//=============================================================//

const mapOiQueryFilterToQuestionFilterItem = (tables: SQLTable[] = []) => (filterItem: OiFilterDto): QueryFilterViewItem => {
  let values = (filterItem.values || '').split(FilterValuesJoinPattern);
  let columns = filterItem.dbTable ? tables.find(t => t.name == filterItem.dbTable)!.columns : [];
  const baseTable = tables.find(t => t.name == filterItem.dbTable);
  if (isOperatorCurrentOrLast(filterItem.operator)) {
    values = [values[0], filterItem.operator, filterItem.operator];
    filterItem.operator = filterItem.operator.replace(/_\w*$/g, '');
  }
  let column = columns.find(c => c.dbName == filterItem.dbColumn);
  if (filterItem.functionType !== OiFunction.NONE) {
    column = createAggregateFilterColumn(baseTable, filterItem);
  }

  return {
    filterOperator: oiQueryFiltersSelectOptions.find(f => f.operator == filterItem.operator),
    values: values,
    dynamic: filterItem.dynamic || false,
    zoomingFilter: filterItem.zoomingFilter || false,
    functionType: filterItem.functionType || OiFunction.NONE,
    column: column,
  } as QueryFilterViewItem;
};

const mapOiQueryColumnToQuestionGroupItem = (tables: SQLTable[] = []) => (item: OiQueryColumn): QueryGroupViewItem => {
  const columns = item.dbTable ? tables.find(t => t.name == item.dbTable)!.columns : [];

  let columnExpression = item.expression[0] as ColumnExpression;
  if (item.expression.length > 1) {
    throw 'Column expression is > 1 , please handle this !,mapOiQueryColumnToQuestionGroupItem ';
    // columnExpression
  }
  return {
    column: columns.find(c => c.dbName == columnExpression.dbColumn),
    displayName: item.label,
    pin: item.pin,
    orderDirection: item.orderDirection,
    grouped: Boolean(item.grouped),
    orderIndex: item.orderIndex || 0,
    functionItem: oiViewOperatorsWithNone.find(o => o.value == columnExpression.function),
    groupingOperator: columnExpression.function,
    //allGroupingOperatorsWithDate.find(o => o.value == item.functionType),
    vid: item.vid,
  } as QueryGroupViewItem;
};

const dtoComputedExpressionToVmExpressionTree = (exp: IQLColumnExpression[] | IQLColumnExpression): ExpressionItem => {
  if (Array.isArray(exp)) {
    return {
      t: 'group',
      value: exp.map(dtoComputedExpressionToVmExpressionTree),
    } as ExpressionItem;
  } else {
    if (typeof exp === 'string') {
      if ('+-/*'.split('').includes('' + exp)) {
        return {
          t: 'op',
          value: exp,
        };
      } else {
        return {
          t: 'err',
          errMsg: 'unexpected operator value',
          value: exp,
        };
      }
    } else {
      if (exp.function === 'STATIC_VALUE') {
        return {
          t: 'value',
          value: exp,
        };
      } else if (exp.function) {
        return {
          t: 'col',
          value: { dbColumn: exp.dbColumn, function: exp.function },
        };
      }
    }
  }
  return {
    t: 'err',
    errMsg: ' Unexpcted value',
    value: 'Error',
  };
};

export const mapDTOComputedColumnToVm = (colItem: OiQueryColumn): QueryCalculatedColumnItem => {
  return {
    displayName: colItem.label,
    pin: colItem.pin,
    grouped: colItem.grouped,
    orderDirection: colItem.orderDirection,
    orderIndex: colItem.orderIndex,
    vid: colItem.vid,
    expression: {
      t: 'group',
      value: colItem.expression.map(dtoComputedExpressionToVmExpressionTree),
    },
  } as QueryCalculatedColumnItem;
};

const mapOiQueryColumnToQueryViewItem = (tables: SQLTable[]) => (item: OiQueryColumn): QueryViewItem => {
  const columns = item.dbTable ? tables.find(t => t.name == item.dbTable)!.columns : [];

  let columnExpression = item.expression[0] as ColumnExpression;
  if (item.expression.length > 1) {
    throw 'Column expression is > 1 , please handle this !,mapOiQueryColumnToQuestionGroupItem ';
    // columnExpression
  }
  let column = columns.find(c => c.dbName == columnExpression.dbColumn)!;
  // column.functionType = "";
  // column.pr
  return {
    column: column || { dbTableName: item.dbTable },
    displayName: item.label,
    pin: item.pin,
    grouped: Boolean(item.grouped),
    orderDirection: item.orderDirection,
    orderIndex: item.orderIndex,
    functionItem: oiViewOperatorsWithNone.find(o => o.value == columnExpression.function),
    vid: item.vid,
  } as QueryViewItem;
};

export function createAggregateFilterColumn(table: SQLTable | undefined, filterItem: OiFilterDto): SQLColumn | undefined {
  return {
    type: ColumnType.MEASURE,
    oceanDataType: OceanDataType.NUMBER,
    displayName: (table ? table.displayName : filterItem.dbTable) + ' ' + filterItem.functionType,
    dbTableName: filterItem.dbTable,
  } as any;
}

export function mapOiQueryDtoToQuestionFormViewModel(
  questionDto: OiQuestionDto,
  tables: SQLTable[],
  db: SQLDatabase
): QuestionStudioStoreState {
  const table = tables.find(t => t.name == questionDto.query.dbTable)!;

  const viewTypeOption =
    viewTypesOptionsList.find(o => o.type.toString() == questionDto.defaultView) ||
    viewTypesOptionsList.find(o => o.type == VIEW_TYPE.TABLE);

  const viewsItems = questionDto.query.columns
    .filter(c => c.designType === ColumnDesignType.VIEW)
    .map(mapOiQueryColumnToQueryViewItem(tables));

  viewsItems.sort((c1, c2) => c1.orderIndex! - c2.orderIndex!);

  const groupItems = questionDto.query.columns
    .filter(c => c.designType === ColumnDesignType.GROUPBY)
    .map(mapOiQueryColumnToQuestionGroupItem(tables));

  groupItems.sort((c1, c2) => c1.orderIndex! - c2.orderIndex!);

  const computedColumnItems = questionDto.query.columns
    .filter(c => c.designType === ColumnDesignType.COMPUTED)
    .map(mapDTOComputedColumnToVm);

  computedColumnItems.sort((c1, c2) => c1.orderIndex! - c2.orderIndex!);

  let hasAdvanceFilters = IQLFilterExpressionHasAdvanceFilters(questionDto.query.filters);
  const advanceFilterGroup = convertIQLFilterDToToFilterTreeVM(questionDto.query.filters, tables);
  const filters = advanceFilterGroup.items.filter(f => !f.groupItemType);
  const result: QuestionStudioStoreState = {
    id: questionDto.id,
    isLoadingPreview: false,
    datasourceID: questionDto.query.datasourceID,
    datasourceName: (db && db.displayName) || undefined,
    database: db,
    baseTable: table,
    tags: questionDto.tag || '',
    computedColumns: computedColumnItems,
    dbTables: tables,
    description: questionDto.description || '',
    limit: '' + questionDto.query.resultLimit,
    name: questionDto.name,
    viewTypeOption: viewTypeOption,
    advanceFilter: hasAdvanceFilters ? advanceFilterGroup.items.filter(f => f.groupItemType == 'group')[0] : undefined,
    filtersItems: filters,
    chartOptions: questionDto.viewTypeOptions ? JSON.parse(questionDto.viewTypeOptions) : null,
    groupItems: groupItems,
    viewsItems: viewsItems,
  } as any;
  return result;
}

export function getColumnDateByDayFilterConfigs(orgValue) {
  let values = ['' + orgValue];
  let [year, month, day] = orgValue.split('-').map(c => parseInt(c));
  let start = moment(orgValue, 'Y-MMM-D');
  let end = moment(start).add(1, 'd');
  values = [start.format(APP_LOCAL_DATE_FORMAT), end.format(APP_LOCAL_DATE_FORMAT)];
  return {
    filter: oiQueryFiltersSelectOptions.find(o => o.operator == ComparisonOperator.BETWEEN) as OiFilterDropdownItem,
    values,
  };
}

export function getColumnDateByMonthFilterConfigs(orgValue) {
  let values = ['' + orgValue];
  let [year, month] = orgValue.split('-').map(c => parseInt(c));
  let orgFormat = /\d{4}-\w{3}/.test(orgValue) ? 'YYYY-MMM' : 'YYYY-MM';
  let start = moment(orgValue, orgFormat);
  let end = moment(start).add(1, 'M');
  values = [start.format(APP_LOCAL_DATE_FORMAT), end.format(APP_LOCAL_DATE_FORMAT)];
  return {
    filter: oiQueryFiltersSelectOptions.find(o => o.operator == ComparisonOperator.BETWEEN) as OiFilterDropdownItem,
    values,
  };
}

export function getColumnDateByQuarterFilterConfigs(orgValue) {
  let values = ['' + orgValue];
  let [year = '', q = ''] = orgValue.split('-');
  let start = moment(year, 'YYYY');
  let range = ['', ''];
  if (!['q1', 'q2', 'q3', 'q4'].includes(q.toLowerCase())) {
    q =
      'q' +
      moment(start)
        .month(q)
        .day(1)
        .quarter();
  }
  //
  switch (q.toLowerCase()) {
    case 'q1':
      range = [
        start.format(APP_LOCAL_DATE_FORMAT),
        moment(start)
          .month(3)
          .format(APP_LOCAL_DATE_FORMAT),
      ];
      break;
    case 'q2':
      range = [
        moment(start)
          .month(3)
          .format(APP_LOCAL_DATE_FORMAT),
        moment(start)
          .month(6)
          .format(APP_LOCAL_DATE_FORMAT),
      ];
      break;
    case 'q3':
      range = [
        moment(start)
          .month(6)
          .format(APP_LOCAL_DATE_FORMAT),
        moment(start)
          .month(9)
          .format(APP_LOCAL_DATE_FORMAT),
      ];
      break;
    case 'q4':
      range = [
        moment(start)
          .month(9)
          .format(APP_LOCAL_DATE_FORMAT),
        moment(start)
          .add(1, 'y')
          .format(APP_LOCAL_DATE_FORMAT),
      ];
      break;
    default:
      range = [
        start.format(APP_LOCAL_DATE_FORMAT),
        moment(start)
          .add(4, 'm')
          .format(APP_LOCAL_DATE_FORMAT),
      ];
      break;
  }
  values = range;
  return {
    filter: oiQueryFiltersSelectOptions.find(o => o.operator == ComparisonOperator.BETWEEN) as OiFilterDropdownItem,
    values,
  };
}

export function getColumnDateByYearFilterConfigs(orgValue) {
  let values = ['' + orgValue];
  let year = '' + orgValue;
  let start = moment(orgValue, 'YYYY');
  let end = moment(start).add(1, 'y');
  values = [start.format(APP_LOCAL_DATE_FORMAT), end.format(APP_LOCAL_DATE_FORMAT)];
  return {
    filter: oiQueryFiltersSelectOptions.find(o => o.operator == ComparisonOperator.BETWEEN) as OiFilterDropdownItem,
    values,
  };
}

export function getDrillingFilterItem(column, columnValue) {
  let orgValue = '' + columnValue;
  columnValue = column.oceanDataType == OceanDataType.DATE ? moment(columnValue).format(APP_LOCAL_DATE_FORMAT) : columnValue;
  let values = [columnValue];
  let filter =
    columnValue === ''
      ? (oiQueryFiltersSelectOptions.find(o => o.operator == ComparisonOperator.IS_NULL_OR_EMPTY) as OiFilterDropdownItem)
      : (oiQueryFiltersSelectOptions.find(o => o.operator == ComparisonOperator.EQUAL) as OiFilterDropdownItem);
  if (column && column.functionType) {
    switch (column.functionType as OiFunction) {
      case OiFunction.DATE_BY_DAY: {
        let results = getColumnDateByDayFilterConfigs(orgValue);
        values = results.values;
        filter = results.filter;
        break;
      }
      case OiFunction.DATE_BY_MONTH: {
        let results = getColumnDateByMonthFilterConfigs(orgValue);
        values = results.values;
        filter = results.filter;
        break;
      }
      case OiFunction.DATE_BY_QUARTER: {
        let results = getColumnDateByQuarterFilterConfigs(orgValue);
        values = results.values;
        filter = results.filter;
        break;
      }
      case OiFunction.DATE_BY_YEAR: {
        let results = getColumnDateByYearFilterConfigs(orgValue);
        values = results.values;
        filter = results.filter;
        break;
      }

      default: {
        //no cahnges needed! for now;
      }
    }
  }
  return {
    column: column!,
    values: values,
    filterOperator: filter,
  };
}

function shouldBeKpi(groups: any, computedColumns: any, views: any, previewResult: any) {
  let hasNoGroups = groups.length == 0;
  let hasSingleMesureColumn =
    (computedColumns.length == 1 && views.length == 0) || (views.length == 1 && views[0].column.type == ColumnType.MEASURE);
  let shouldBeKpi = !previewResult.metadata && hasNoGroups && hasSingleMesureColumn;
  let previewMeta = (previewResult && previewResult.metadata) || [];
  let previewResults = (previewResult && previewResult.resultset) || [];
  const hasOneValue = previewResults.length == 1;
  shouldBeKpi = previewMeta.length == 1 ? (previewMeta[0] as ColumnMetadatum).oceanDataType == OceanDataType.NUMBER : shouldBeKpi;
  shouldBeKpi = shouldBeKpi && hasOneValue;
  return shouldBeKpi;
}

function getNextViewTypeOptions(shouldBeKpi: boolean, studioState: any) {
  return shouldBeKpi
    ? viewTypesOptionsList.find(c => c.type == VIEW_TYPE.KPI)
    : studioState.viewTypeOption && studioState.viewTypeOption.type !== VIEW_TYPE.KPI
    ? studioState.viewTypeOption
    : viewTypesOptionsList.find(c => c.type == VIEW_TYPE.TABLE);
}

const operatorsMenuOptions = [
  {
    label: 'Operators',
    options: ['*', '/', '-', '+'].map(c => ({ label: c, value: c })),
  },
];
const aggregatorsMenuOptions = [
  {
    label: 'Aggregators',
    options: [
      {
        label: 'Min',
        value: OiFunction.MIN,
        measuresOnly: true,
      },
      {
        label: 'Max',
        value: OiFunction.MAX,
        measuresOnly: true,
      },
      {
        label: 'Avg',
        value: OiFunction.AVG,
        measuresOnly: true,
      },
      {
        label: 'Sum',
        value: OiFunction.SUM,
        measuresOnly: true,
      },
    ],
  },
  {
    label: 'Other',
    options: [
      {
        label: '()',
        value: '()',
        measuresOnly: true,
      },
    ],
  },
];

function validateViewItem(item: QueryViewItem) {
  if (!item.functionItem) {
    return {};
  }
}
function validateComputedColumn(item: QueryCalculatedColumnItem) {
  let errors: any = [];
  let value: any = item.expression.value;
  if (item.displayName === '') {
    errors.push({
      item: 'Computed Column',
      field: 'displayName',
      msg: 'missing display name',
    });
  }
  if (!value || !value.length) {
    errors.push({
      item: 'Computed Column',
      field: 'displayName',
      msg: 'cannot be empty expression',
    });
  }
  return errors;
}

export function saveQuestion(batch?) {
  let AppSate = store.getState();
  let studioState = questionStudioSelectors.studioState(AppSate);

  const dtoModel = convertQuestionVMToDto({ ...studioState, ...batch });
  const chartOptions = studioState.chartOptions;
  dtoModel.viewTypeOptions = JSON.stringify(chartOptions);
  if (batch.saveAs) {
    dtoModel.id = undefined;
  }
  let promise: any = null;
  if (!dtoModel.id) {
    promise = apisService.createQuestion(dtoModel);
  } else {
    promise = apisService.updateQuestion(dtoModel);
  }
  return promise;
}

export function validateQuestion(batch?) {
  let AppSate = store.getState();
  let errors = [];
  let studioState = questionStudioSelectors.studioState(AppSate);
  studioState.viewsItems.forEach(item => validateViewItem(item));
  studioState.computedColumns.forEach(item => validateComputedColumn(item));
  return { isValid: !errors.length, errors };
}

export function convertFilterTreeVMToIQLFilterDTO(
  advanceFilterGroup: IAdvanceFilterGroup
): Array<IQLFilterExpression[] | IQLFilterExpression> {
  return advanceFilterGroup.items.reduce(
    (acc, item, index) => {
      let group = item as IAdvanceFilterGroup;

      if (group.groupItemType === 'group') {
        const result = convertFilterTreeVMToIQLFilterDTO(group) as IQLFilterExpression[];
        acc.push(result);
      } else {
        let condition = item as QueryFilterViewItem;
        acc.push(...(mapViewFiltersToDtoFilters([condition], advanceFilterGroup.groupOperator) as any));
        const hasGroupingOp = ['and', 'or'].indexOf('' + acc[index]) > -1;
        if (!hasGroupingOp && index < advanceFilterGroup.items.length - 1) {
          acc.push(advanceFilterGroup.groupOperator);
        }
      }

      return acc;
    },
    [] as Array<IQLFilterExpression[] | IQLFilterExpression>
  );
}

export function convertIQLFilterDToToFilterTreeVM(
  filterExpression: Array<IQLFilterExpression[] | IQLFilterExpression>,
  tables: SQLTable[] = []
): IAdvanceFilterGroup {
  const advanceFilter = { ...DefaultAdvanceFilterGroupItem };
  const groupItems: any[] = [];
  filterExpression.forEach(expression => {
    if (Array.isArray(expression) && expression.length) {
      const group = convertIQLFilterDToToFilterTreeVM(expression, tables);
      groupItems.push(group as IAdvanceFilterGroup);
    } else if (expression == 'and' || expression == 'or') {
      advanceFilter.groupOperator = expression;
    } else {
      // OiFilterDto
      const condition = mapOiQueryFilterToQuestionFilterItem(tables)(expression as OiFilterDto);
      groupItems.push(condition);
    }
  });
  advanceFilter.items = groupItems;
  return advanceFilter;
}

export function IQLFilterExpressionHasAdvanceFilters(filterExpression: Array<IQLFilterExpression[] | IQLFilterExpression>) {
  return filterExpression.some(expression => Array.isArray(expression));
}

export default {
  shouldBeKpi,
  saveQuestion,
  operatorsMenuOptions,
  aggregatorsMenuOptions,
  getNextViewTypeOptions,
  mapQuestionFilterItemToOiQueryFilter,
  convertQuestionVMToDto,
  mapOiQueryDtoToQuestionFormViewModel,
  getDrillingFilterItem,
};
