import * as React from 'react';

import OiIconButton from '../OiIconButton';
import OiIcon from 'src/assets/icons';
import NestableMenu from '../NestableMenu';
import { OceanDataType } from 'src/shared/models/SqlColumn';
export default ({ anchorElement, open, clickedSegment, onDoSegmentZoom, onClose, onDoSegmentDrillDown, onDoSegmentFilterOut }) => {
  const zoomInOut = (
    <li key={'zooom'}>
      <span style={{ display: 'flex', justifyContent: 'space-evenly', marginRight: 5, marginLeft: 5 }}>
        <OiIconButton style={{ height: '30px' }} onClick={onDoSegmentZoom('in')}>
          <OiIcon scale={20} path={'ZoomIn'} />
        </OiIconButton>
        <OiIconButton style={{ height: '30px' }} onClick={onDoSegmentZoom('out')}>
          <OiIcon scale={20} path={'ZoomOut'} />
        </OiIconButton>
      </span>
    </li>
  );
  const menuItems: Array<any> = [
    {
      key: '1',
      label: (
        <>
          {' '}
          <OiIcon scale={20} path={'CollapseFilled'} /> Show Details{' '}
        </>
      ),
      onClick: e => {
        onDoSegmentDrillDown();
      },
    },
    {
      key: '3',
      label: (
        <>
          {' '}
          <OiIcon scale={20} path={'ExpandFilled'} /> Filter Out{' '}
        </>
      ),
      onClick: e => {
        onDoSegmentFilterOut();
      },
    },
  ];
  const showDateZoomingOperators =
    clickedSegment && clickedSegment.currentGroup && clickedSegment.currentGroup.oceanDataType == OceanDataType.DATE;
  if (showDateZoomingOperators) {
    menuItems.unshift(
      {
        key: '0',
        itemRendered: zoomInOut,
        onClick: e => {},
      },
      {
        key: '11',
        label: 'Zoom To',
        subMenuItems: ['Year', 'Quarter', 'Month', 'Day'].map(period => {
          return {
            key: period,
            label: period,
            onClick: onDoSegmentZoom('to_' + period.toLowerCase()),
          };
        }),
      }
    );
  }
  return <NestableMenu open={Boolean(open)} onClose={onClose} anchorElement={anchorElement} menuItems={menuItems} />;
};
