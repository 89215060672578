import * as React from 'react';
import * as PropTypes from 'prop-types';

import { PluginHost } from '../../core';
import { Plugin, Getter, Template, TemplatePlaceholder } from '../../core';
import { rowIdGetter, cellValueGetter } from '../../core/plugins/grid-core/computeds';

export class GridCore extends React.PureComponent {
  render() {
    const { rows, columns, getRowId, getCellValue, rootComponent: Root, ...restProps } = this.props;

    return (
      <Plugin>
        <Getter name='rows' value={rows} />
        <Getter name='getRowId' value={rowIdGetter(getRowId, rows)} />
        <Getter name='columns' value={columns} />
        <Getter name='getCellValue' value={cellValueGetter(getCellValue, columns)} />
        <Template name='root'>
          <Root {...restProps}>
            <TemplatePlaceholder name='header' />
            <TemplatePlaceholder name='body' />
            <TemplatePlaceholder name='footer' />
          </Root>
        </Template>
      </Plugin>
    );
  }
}

GridCore.propTypes = {
  rows: PropTypes.array.isRequired,
  getRowId: PropTypes.func,
  getCellValue: PropTypes.func,
  columns: PropTypes.array.isRequired,
  rootComponent: PropTypes.func.isRequired,
};

GridCore.defaultProps = {
  getRowId: undefined,
  getCellValue: undefined,
};

export const GridBase = ({ rows, columns, getRowId, getCellValue, rootComponent, children, ...restProps }) => (
  <PluginHost>
    <GridCore rows={rows} columns={columns} getRowId={getRowId} getCellValue={getCellValue} rootComponent={rootComponent} {...restProps} />
    {children}
  </PluginHost>
);

GridBase.propTypes = {
  rows: PropTypes.array.isRequired,
  getRowId: PropTypes.func,
  getCellValue: PropTypes.func,
  columns: PropTypes.array.isRequired,
  rootComponent: PropTypes.func.isRequired,
  children: PropTypes.node,
};

GridBase.defaultProps = {
  getRowId: undefined,
  getCellValue: undefined,
  children: undefined,
};
