import { connect } from 'react-redux';
import { login, checkAuth } from 'src/store/authentication/actions';
import { IRootState } from 'src/store/rootReducer';
import { getRoles, getPermissions } from 'src/store/account/selectors';
import { getIsAuthenticated, getLoader, getError, getIsCheckingAuth } from 'src/store/authentication/selectors';
import types from 'src/store/authentication/actionTypes';
import PrivateRoute from './PrivateRoute';

const mapStateToProps = (state: IRootState) => {
  const { applicationProfile } = state;
  return {
    isCheckingAuth: getIsCheckingAuth(state),
    isAuthenticated: getIsAuthenticated(state),
    isInProduction: applicationProfile.inProduction,
    loading: getLoader(state, types.LOGIN),
    errorMessage: getError(state, types.LOGIN),
    loginSuccess: getIsAuthenticated(state),
    permissions: getPermissions(state),
    roles: getRoles(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (request, rememberMe) => dispatch(login(request, rememberMe)),
    checkLogin: () => dispatch(checkAuth()),
  };
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const PrivateRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);

export default PrivateRouteContainer;
