import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

// import authentication, { AuthenticationState } from 'src/store/authentication/authentication';

import authentication, { AuthenticationState } from 'src/store/authentication/reducers';

import confirmationPopup, { ConfirmationPopupState } from 'store/confirmationPopup';
import databases, { DatabasesState } from 'src/store/databases/reducers';
import questions, { QuestionsState } from 'src/store/questions/questions.reducer';
import dashboards, { DashboardsState } from 'src/store/dashboards/reducers';
import applicationProfile, { ApplicationProfileState } from 'src/shared/util/application-profile';
import users from 'src/store/users/reducers';
import settings from 'src/store/settings/settings.reducer';
import account, { AccountState } from 'src/store/account/reducers';
import tasks from 'src/store/tasks/tasks.reducer';
import questionStudio, { QuestionsStudioState } from 'src/store/questionStudio/questionStudioReducer';
import columnsMap, { ColumnsMapState } from 'src/store/columnsMap/reducers';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly loadingBar: any;
  readonly applicationProfile: ApplicationProfileState;
  readonly databases: DatabasesState;
  readonly dashboards: DashboardsState;
  readonly questions: QuestionsState;
  readonly questionStudio: QuestionsStudioState;
  readonly confirmationPopup: ConfirmationPopupState;
  readonly users: any;
  readonly tasks: any;
  readonly account: AccountState;
  readonly settings: any;
  readonly columnsMap: ColumnsMapState;
}

export const rootReducer = combineReducers<any>({
  account,
  applicationProfile,
  authentication,
  confirmationPopup,
  dashboards,
  databases,
  loadingBar,
  questions,
  questionStudio,
  settings,
  tasks,
  users,
  columnsMap,
});

export default rootReducer;
