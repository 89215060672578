import * as React from 'react';
import { Popover, Paper } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import ColumnSelectList from '../ColumnSelectList';
import { IColumnSelectPopupProps, IColumnSelectPopupState } from './model';

export class ColumnSelectPopup extends React.Component<IColumnSelectPopupProps, IColumnSelectPopupState> {
  state: IColumnSelectPopupState = {};

  onSelectColumn = col => {
    this.props.onSelectColumn && this.props.onSelectColumn(col);
    this.setState({ isOpen: false });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { classes, columnTypes, tables, onClose } = this.props;
    const { isOpen, anchorEl } = this.state;
    let selectedTable = this.props.selectedTable || (tables && tables[0]);
    const popOverProps = {
      open: Boolean(isOpen),
      anchorEl: anchorEl,
      onClose: onClose,
      onBackdropClick: e => this.setState({ isOpen: false }),
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'center',
        horizontal: 'left',
      },
    };

    return (
      <>
        <div ref='clickRef' onClick={e => this.setState({ isOpen: true, anchorEl: this.refs.clickRef })}>
          {this.props.children}
        </div>
        <Popover {...popOverProps as any}>
          <Paper elevation={1} className={classes.popover}>
            <Scrollbars className={classes.scrollBar}>
              <ColumnSelectList
                tables={tables || []}
                columnTypes={columnTypes}
                opendTable={selectedTable}
                aggregateColumns={this.props.aggregateColumns}
                onSelectAggregateColumn={this.props.onSelectAggregateColumn}
                onSelectColumn={this.onSelectColumn}
              />
            </Scrollbars>
          </Paper>
        </Popover>
      </>
    );
  }
}
