import axios from 'axios';

import { put, takeEvery, call } from 'redux-saga/effects';
import types from './actionTypes';
import { REQUEST, SUCCESS, FAILURE } from 'store/util';
import toastr from 'src/components/toastr';
import { AUTH_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY } from './constant';
import { authUrl } from 'src/shared/apis/baseUrls';
import * as keycloakService from 'src/shared/auth/KeycloakService';
import { setCheckingAuth, onLoggedInSuccessfully, setToken } from './actions';
import { checkLogin } from 'src/shared/auth/checkLogin';
import { fetchColumnsMap } from '../columnsMap/actions';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';

function* doLogin(action) {
  const type = types.LOGIN;
  const body = action.payload.request;
  const rememberMe = action.payload.rememberMe;
  try {
    const { result, error } = yield call(oceanAuthApis.loginApi, body);
    if (result) {
      const token = result.data.id_token;
      if (!rememberMe) {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
      } else {
        sessionStorage.setItem(AUTH_TOKEN_KEY, token);
      }
      localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, result.data.refreshToken);
      yield put({ type: SUCCESS(type), payload: result.data });
      yield put(onLoggedInSuccessfully(token));
    } else {
      const message = error && error.response && (error.response.data.error || error.response.data.title);
      toastr.error(message);
      yield put({ type: FAILURE(type), payload: { message: message || `${type} error` } });
    }
  } catch (e) {
    console.error('Erorr in Login saga', e);
  } finally {
  }
}

function* doLogout(action) {
  const type = types.LOGOUT;
  const refreshToken = localStorage.getItem(AUTH_REFRESH_TOKEN_KEY);
  try {
    if (keycloakService.auth && keycloakService.auth.authz) {
      keycloakService.logout();
    } else {
      const redirectToLoginPage = () => (window.location.href = '/login');
      yield call(() =>
        oceanAuthApis.logout(refreshToken).then(redirectToLoginPage, err => {
          toastr.error('Error calling logout api');
          console.error(err);
          redirectToLoginPage();
        })
      );
    }
    yield put({ type: SUCCESS(type) });
  } catch (e) {
    console.error('error in do logout saga', e);
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  } finally {
    clearAuthToken();
    localStorage.clear();
    sessionStorage.clear();
  }
}

export const clearAuthToken = () => {
  if (localStorage.getItem(AUTH_TOKEN_KEY)) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
};

function* doClearAuth(action) {
  clearAuthToken();
  const type = types.CLEAR_AUTH;
  const message = action.payload.message;
  localStorage.clear();
  yield put({ type: SUCCESS(type), payload: { message } });
}

function forgetPasswordApi(info): any {
  return axios
    .post(authUrl + `/account/forget-password/${info.username}`)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}

function* doForgetPassword(action) {
  const type = types.FORGET_PASSWORD;
  try {
    const username = action.payload.username;
    const { result, error } = yield call(forgetPasswordApi, username);

    if (result) {
      toastr.info('Please follow the instructions in your email');
      yield put({ type: SUCCESS(type) });
    } else {
      const message = error && error.response && error.response.data.title;
      toastr.error(message || "Please enter your username and we'll send you instructions on how to reset your password");
      yield put({ type: FAILURE(type) });
    }
  } catch (e) {
    console.error('Error in doForgetPassword saga', e);
    yield put({ type: FAILURE(type) });
  } finally {
  }
}

function resetPasswordApi(info): any {
  return axios
    .post(authUrl + `/account/change-password/token/${info.token}`, info.password, { headers: { 'Content-Type': 'text/plain' } })
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}

function* doResetPassword(action) {
  const type = types.RESET_PASSWORD;
  try {
    const request = action.payload.request;
    const { result, error } = yield call(resetPasswordApi, request);
    if (result) {
      toastr.info('Password changed');
      yield put({ type: SUCCESS(type) });
    } else {
      const message = error && error.response && error.response.data.title;
      toastr.error(message);
      yield put({ type: FAILURE(type) });
    }
  } catch (e) {
    console.error('Error in doResetPassword saga', e);
  } finally {
    yield put({ type: FAILURE(type) });
  }
}

function* checkLoginSaga(action) {
  try {
    yield put(setCheckingAuth(true));
    checkLogin();
  } catch (e) {
    console.error('Error in checkLoginSaga saga', e);
  }
}

function* onLoggedInSuccessfullySaga(action) {
  const { payload } = action;
  const { token } = payload;
  yield put(setToken(token));
  yield put(fetchColumnsMap());
}

const sagas = [
  takeEvery(REQUEST(types.LOGOUT), doLogout),
  takeEvery(REQUEST(types.LOGIN), doLogin),
  takeEvery(REQUEST(types.CLEAR_AUTH) as any, doClearAuth),
  takeEvery(REQUEST(types.FORGET_PASSWORD) as any, doForgetPassword),
  takeEvery(REQUEST(types.RESET_PASSWORD) as any, doResetPassword),
  takeEvery(types.CHECK_AUTH, checkLoginSaga),
  takeEvery(types.ON_LOGGED_IN_SUCCESSFULLY, onLoggedInSuccessfullySaga),
];

export default sagas;
