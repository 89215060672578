import React from 'react';
import OiIconButton from 'src/components/OiIconButton';
import { Tooltip } from '@material-ui/core';
import OiIcon from 'src/assets/icons';
import TitleInput from '../TitleInput';
import Delete from '@material-ui/icons/Delete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

const HeadingItem = props => (
  <div className={props.HeadingItem}>
    <OiIconButton onClick={props.toggleExpansion}> {props.expanded ? <ExpandLess /> : <ExpandMore />} </OiIconButton>
    <Tooltip title='Pin column'>
      <OiIconButton onClick={props.onPinChanged}>
        {' '}
        <OiIcon viewBox='0 0 24 24' path={'Pin'} color={props.model.pin ? '#048edd' : '#a3a3a3'} />{' '}
      </OiIconButton>
    </Tooltip>
    <Tooltip title='Group values'>
      <OiIconButton onClick={props.onToggleGroupByColumn}>
        {' '}
        <OiIcon scale={16} path={'ColumnGroupBy'} color={props.model.grouped ? '#048edd' : '#a3a3a3'} />{' '}
      </OiIconButton>
    </Tooltip>
    <TitleInput onTitleChanged={v => props.handleLabelChanged(v || '')} value={props.model && props.model.displayName} />
    <Tooltip title='Delete column'>
      <OiIconButton onClick={props.onDelete}>
        {' '}
        <Delete />{' '}
      </OiIconButton>
    </Tooltip>
  </div>
);
export default HeadingItem;
