export default () => next => action => {
  // If not a promise, continue on
  if (action !== null && typeof action === 'object') {
    if (!(action && typeof action.then === 'function')) return next(action);
    // return action && typeof action.then === 'function';
  }

  /**
   *
   * The notification middleware serves to dispatch the initial pending promise to
   * the promise middleware, but adds a `then` and `catch.
   */
  return next(action)
    .then(response => {
      if (action.meta && action.meta.successMessage) {
        // toast(action.meta.successMessage, { type: toast.TYPE.SUCCESS });
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      if (action.meta && action.meta.errorMessage) {
        // toast(action.meta.errorMessage, { type: toast.TYPE.ERROR });
      }
      return Promise.reject(error);
    });
};
