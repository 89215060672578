import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    indicator: {
      display: 'none',
    },
    tab: {
      padding: 2,
    },
    selectedStyle: {
      borderRadius: 25,
      background: theme.palette.primary.main,
      // ".tabs-title-content": {
      //   "p": {
      //     color: "#fff"
      //   }
      // }
    },
  });

export interface styleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);
export default Styled;
