import * as React from 'react';
import { Popover, MenuItem } from '@material-ui/core';
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import OiIconButton from 'src/components/OiIconButton';
import OiIcon from 'src/assets/icons';
import { Colors } from 'src/shared/theme';
import TooltipedIconButton from '../TooltipedIconButton';
type IProps = {
  questionName: string;
  onSelect: (result: any) => any;
  isKpiView: boolean;
  isChartView: boolean;
  isTableView: boolean;
  question?: {};
};
export default class ExportQuestionPopup extends React.Component<IProps> {
  state = {
    fileName: '',
    anchorEl: undefined,
    isOpen: false,
  };

  onSelect = (extension: any) => () => {
    this.props.onSelect(extension);
    this.setState({
      anchorEl: undefined,
      fileName: '',
    });
  };
  componentDidMount() {
    this.setState({ fileName: this.props.questionName });
  }

  render() {
    const options = [
      {
        label: 'csv',
        extension: 'CSV',
        icon: 'Csv',
      },
      {
        label: 'Excel',
        extension: 'XLSX',
        icon: 'Excel',
      },
    ];
    if (this.props.isChartView) {
      options.push({
        label: 'image',
        extension: 'image',
        icon: 'Image',
      });
    }
    return (
      <>
        <TooltipedIconButton
          tooltip='Export question results'
          aria-label='type'
          aria-owns='type-menu'
          aria-haspopup='true'
          onClick={event => this.setState({ anchorEl: event.currentTarget })}
        >
          <FileDownloadIcon />
        </TooltipedIconButton>
        {/* <TextField placeholder="fileName" onChange={e => this.setState({ fileName: e.target.value })} /> */}
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onBackdropClick={() => this.setState({ anchorEl: undefined })}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
        >
          {options.map(o => (
            <MenuItem key={o.extension} value={o.extension} onClick={this.onSelect(o.extension)}>
              <OiIcon path={o.icon as any} fill={Colors.brandMedGrey} style={{ color: Colors.brandMedGrey }} /> {o.label}
            </MenuItem>
          ))}
        </Popover>
      </>
    );
  }
}
