import { getAllDatabases, deleteDatabase } from 'src/store/databases/actions';
import { IRootState } from 'src/store/rootReducer';
import { openConfirmationPopup } from 'store/confirmationPopup';
import { connect } from 'react-redux';
import DatabasesList from './DatabasesTable';
import { withRouter } from 'react-router';
import { getPermissions, getRoles } from 'src/store/account/selectors';
import { shareDatabase } from 'src/store/databases/actions';

const mapState = (state: IRootState) => {
  const { databases } = state;
  return {
    shareableUsers: state.users.shareableUsers || [],
    isLoadingList: databases.loading,
    databasesList: databases.databasesList,
    roles: getRoles(state),
    permissions: getPermissions(state),
  };
};

const mapDispatch = dispatch => {
  return {
    getAllDatabases: () => dispatch(getAllDatabases()),
    confirm: config => {
      openConfirmationPopup(config)(dispatch);
    },
    deleteDatabase: id => dispatch(deleteDatabase(id)),
    shareDB: ({ usernames, dbIds }) => dispatch(shareDatabase({ usernames, dbIds })),
  };
};

export type StateProps = ReturnType<typeof mapState>;
export type DispatchProps = ReturnType<typeof mapDispatch>;

const connected = connect(
  mapState,
  mapDispatch
)(DatabasesList);

const routed = withRouter<any, any>(connected);

export default routed;
