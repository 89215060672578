import { REQUEST, SUCCESS, FAILURE } from 'store/util';
import * as types from './actionTypes';

const initialState = {
  databasesList: [] as Array<any>,
  loading: false,
  loadSuccess: false,
  loadError: false,

  createSubmitting: false,
  createSuccess: false,
  createError: false,

  deleteSubmitting: false,
  deleteSuccess: false,
  deleteError: false,
  errorMessage: '' as string,
};

export type DatabasesState = Readonly<typeof initialState>;

// Reducer
export default (state: DatabasesState = initialState, { type, payload }): DatabasesState => {
  switch (type) {
    case REQUEST(types.GET_DB):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(types.GET_DB): {
      let response = payload.response;
      return {
        ...initialState,
        errorMessage:
          (payload.data && payload.data.fieldErrors) ||
          (response && response.data && (response.data.fieldErrors || JSON.stringify(response.data.messages))) ||
          payload.message,
        loadError: true,
        loadSuccess: false,
        loading: false,
      };
    }
    case SUCCESS(types.GET_DB): {
      return {
        ...state,
        loading: false,
        loadError: false,
        loadSuccess: true,
        databasesList: payload.data,
      };
    }

    case REQUEST(types.CREATE_DB):
      return {
        ...state,
        createSubmitting: true,
      };
    case FAILURE(types.CREATE_DB): {
      let response = payload.response;

      return {
        ...initialState,
        createError: true,
        createSuccess: false,
        errorMessage: (response && response.data.title) || (response && response.data.messages) || payload.message,
        createSubmitting: false,
      };
    }
    case SUCCESS(types.CREATE_DB): {
      return {
        ...state,
        createSubmitting: false,
        createError: false,
        createSuccess: true,
      };
    }

    case REQUEST(types.DELETE_DB):
      return {
        ...state,
        deleteSubmitting: true,
      };
    case FAILURE(types.DELETE_DB): {
      let response = payload.response;

      return {
        ...initialState,
        deleteError: true,
        deleteSuccess: false,
        errorMessage: (response && response.data.messages) || payload.message,
        deleteSubmitting: false,
      };
    }
    case SUCCESS(types.DELETE_DB): {
      return {
        ...state,
        deleteSubmitting: false,
        deleteError: false,
        deleteSuccess: true,
      };
    }
    case SUCCESS(types.SHARE_DB): {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
