import React from 'react';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import CurrentDatePeriodFilter from './CurrentDatePeriodFilter';
import { LastDatePeriodFilter } from './LastDatePeriodFilter';
import { FilterDatePicker } from './FilterDatePicker';
export function DateValuesFilterEditor(props) {
  const { analysis, values, operator, onChange, onBlur, onPickDay, onKeyDown } = props;
  if (operator === ComparisonOperator.CURRENT) {
    return <CurrentDatePeriodFilter value={values[1]} onChange={onChange(1)} />;
  } else if (operator === ComparisonOperator.LAST) {
    return (
      <LastDatePeriodFilter
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        values={values}
        onChangeValue={onChange(0)}
        onChangePeriod={onChange(1)}
      />
    );
  } else if (operator === ComparisonOperator.BETWEEN) {
    return (
      <div>
        <FilterDatePicker value={values[0]} label={'Value' + 1} analysis={analysis} onPickDay={onPickDay(0)} />
        <div> and </div>
        <FilterDatePicker value={values[1]} label={'Value' + 2} analysis={analysis} onPickDay={onPickDay(1)} />
      </div>
    );
  } else {
    return (
      <div>
        <FilterDatePicker value={values[0]} label={'Value'} analysis={analysis} onPickDay={onPickDay(0)} />
      </div>
    );
  }
}
