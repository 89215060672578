import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';

import registerServiceWorker from './registerServiceWorker';
import moment from 'moment';
import { store } from './constants';
import App from './App';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import appRun from './shared/appRun';

appRun(store);

ReactDOM.render(
  <AppContainer>
    <MuiPickersUtilsProvider utils={moment}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiPickersUtilsProvider>
  </AppContainer>,
  document.getElementById('root') as HTMLElement
);
registerServiceWorker();
