import axios from 'axios';
import { put, takeEvery, call } from 'redux-saga/effects';
import types from './actionTypes';
import { REQUEST, SUCCESS, FAILURE } from 'store/util';
import toastr from 'src/components/toastr';
import oceanAuthApis from 'src/shared/apis/oceanAuthApis';
import { authUrl } from 'src/shared/apis/baseUrls';
import { setRouteToUsersPage } from './actions';
function delay(time) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), time);
  });
}
function* routeToUserPage() {
  yield put(setRouteToUsersPage(true));
  yield call(delay, 200);
  yield put(setRouteToUsersPage(false));
}
export function* onFetchUsers() {
  const type = types.FETCH_USERS;
  try {
    const result = yield oceanAuthApis.getUsers();
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    console.error('error in onFetchUsers saga', e);
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}

function createApi(body): any {
  return axios
    .post(authUrl + `/users`, body)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}

export function* onCreateUser(action) {
  const type = types.CREATE;
  const body = action.payload.body;
  try {
    let closeLoadingToastr = toastr.loading('Adding user ');
    const { result, error } = yield call(createApi, body);
    closeLoadingToastr();
    if (result) {
      toastr.success('User created successfully');
      yield put({ type: SUCCESS(type), payload: result.data });
      yield routeToUserPage();
    } else {
      const message = error && error.response && error.response.data.title;
      toastr.error(message);
      yield put({ type: FAILURE(type), payload: { message: message || `${type} error` } });
    }
  } catch (e) {
    console.error('error in onCreateUser saga', e);
    yield put({ type: FAILURE(type), payload: { message: `Unexpected ${type} error` } });
  }
}

export function* onCreateWithPermission(action) {
  const type = types.CREATE;
  try {
    const info = action.payload.body.info;
    const permissions = action.payload.body.permissions;
    let closeLoadingToastr = toastr.loading('Adding user ');
    const { result, error } = yield call(createApi, info);
    closeLoadingToastr();
    if (result) {
      toastr.success('User created successfully');
      const username = result.data.username;
      const request = { body: permissions, username: username };
      if (username && permissions.length > 0) {
        yield put({ type: REQUEST(types.GRANT_PERMISSIONS), payload: request });
      }
      yield put({ type: SUCCESS(type), payload: result.data });
      yield routeToUserPage();
    } else {
      const message = error && error.response && error.response.data.title;
      toastr.error(message);
      yield put({ type: FAILURE(type), payload: { message: message || `${type} error` } });
    }
  } catch (e) {
    console.error('error in onCreateUser saga', e);
    yield put({ type: FAILURE(type), payload: { message: `Unexpected ${type} error` } });
  }
}

function updateApi(info): any {
  return axios
    .put(authUrl + `/users/${info.id}`, info)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}
export function* onUpdateUser(action) {
  const type = types.UPDATE;
  const body = action.payload.body;
  try {
    let closeLoadingToastr = toastr.loading('updating user ');
    const { result, error } = yield call(updateApi, body);
    closeLoadingToastr();
    if (result) {
      toastr.success('user updated successfully');
      yield put({ type: SUCCESS(type), payload: result.data });
      yield routeToUserPage();
    } else {
      const message = error && error.response && error.response.data.title;
      toastr.error(message);
      yield put({ type: FAILURE(type), payload: { message: message || `${type} error` } });
    }
  } catch (e) {
    console.error('error in onCreateUser saga', e);
    yield put({ type: FAILURE(type), payload: { message: `Unexpected ${type} error` } });
  }
}
function* onUpdateWithPermissions(action) {
  const type = types.UPDATE;
  try {
    const info = action.payload.body.info;
    const permissions = action.payload.body.permissions;
    if (!info.password.length) {
      delete info.password;
    }
    let closeLoadingToastr = toastr.loading('updating user ');
    const { result, error } = yield call(updateApi, info);
    closeLoadingToastr();
    if (result) {
      toastr.success('user updated successfully');
      const username = result.data.username;
      const request = { body: permissions, username: username };
      if (permissions.length > 0) {
        yield put({ type: REQUEST(types.UPDATE_GRANT_PERMISSIONS), payload: request });
      }

      yield put({ type: SUCCESS(type), payload: result.data });
      yield put({ type: REQUEST(types.FETCH_USERS) });
      yield routeToUserPage();
    } else {
      toastr.error(error && error.response && error.response.data.title);
      yield put({ type: FAILURE(type), payload: { message: (error && error.response && error.response.data.title) || `${type} error` } });
    }
  } catch (e) {
    console.error('error in onCreateUser saga', e);
    yield put({ type: FAILURE(type), payload: { message: `Unexpected ${type} error` } });
  }
}

function deleteApi(id): any {
  return axios
    .delete(authUrl + `/users/${id}`)
    .then(response => ({ result: response }))
    .catch(error => ({ error }));
}
export function* onDeleteUser(action) {
  const type = types.DELETE;
  try {
    const id = action.payload.id;
    let closeLoadingToastr = toastr.loading('Deleting user ');

    const { result, error } = yield call(deleteApi, id);
    closeLoadingToastr();
    if (result) {
      toastr.success('user deleted successfully');
      yield put({ type: SUCCESS(type), payload: { data: result.data, id: id } });
      // yield put({ type: REQUEST(types.FETCH_USERS) });
    } else {
      toastr.error(error && error.response && error.response.data.title);
      yield put({ type: FAILURE(type), payload: { message: (error && error.message) || `${type} error` } });
    }
  } catch (e) {
    console.error('error in onCreateUser saga', e);
    yield put({ type: FAILURE(type), payload: { message: `Unexpected ${type} error` } });
  }
}

export function* onGetUser(action) {
  const type = types.GET_USER;
  const id = action.payload.id;
  try {
    const result = yield oceanAuthApis.getUserById(id);
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}
export function* onFetchAuthorities() {
  const type = types.FETCH_AUTHORITIES;
  try {
    const result = yield oceanAuthApis.getAuthorities();
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}

export function* onGetShareableUsers() {
  const type = types.GET_SHAREABLE_USERS;
  try {
    const result = yield oceanAuthApis.getSharableUsers();
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}

export function* onFetchPermissions() {
  const type = types.FETCH_PERMISSIONS;
  try {
    const result = yield oceanAuthApis.getUsersPermissions();
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}
export function* onGrantPermissions({ payload: { username, body } }) {
  const type = types.GRANT_PERMISSIONS;
  try {
    const result = yield oceanAuthApis.grantUserPermissionByName(username, body);
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}
export function* onUpdateGrantPermissions({ payload: { username, body } }) {
  const type = types.UPDATE_GRANT_PERMISSIONS;
  try {
    const result = yield oceanAuthApis.updateUserGrantedPermissionByName(username, body);
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}

export function* onFetchUserPermissions({ payload: { username } }) {
  const type = types.USER_PERMISSIONS;
  try {
    const result = yield oceanAuthApis.getUserPermissionsByUserName(username);
    yield put({ type: SUCCESS(type), payload: result.data });
  } catch (e) {
    console.error(e);
    yield put({ type: FAILURE(type), payload: { message: (e && e.message) || `${type} error` } });
  }
}

export default [
  takeEvery(REQUEST(types.FETCH_USERS) as any, onFetchUsers),
  //
  takeEvery(REQUEST(types.CREATE) as any, onCreateUser),
  takeEvery(REQUEST(types.CREATE_WITH_PERMISSION) as any, onCreateWithPermission),
  takeEvery(REQUEST(types.UPDATE) as any, onUpdateUser),
  takeEvery(REQUEST(types.UPDATE_WITH_PERMISSION) as any, onUpdateWithPermissions),
  takeEvery(REQUEST(types.DELETE) as any, onDeleteUser),
  takeEvery(REQUEST(types.GET_USER) as any, onGetUser),
  takeEvery(REQUEST(types.GET_SHAREABLE_USERS) as any, onGetShareableUsers),
  takeEvery(REQUEST(types.FETCH_AUTHORITIES) as any, onFetchAuthorities),
  //
  takeEvery(REQUEST(types.FETCH_PERMISSIONS) as any, onFetchPermissions),
  takeEvery(REQUEST(types.GRANT_PERMISSIONS) as any, onGrantPermissions),
  takeEvery(REQUEST(types.UPDATE_GRANT_PERMISSIONS) as any, onUpdateGrantPermissions),
  takeEvery(REQUEST(types.USER_PERMISSIONS) as any, onFetchUserPermissions),
];
