import React, { useState, useEffect } from 'react';
import { Modal, Paper, Toolbar } from '@material-ui/core';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import CloseIcon from '@material-ui/icons/Close';
import ShareDatabaseFormContainer from 'src/components/ShareDatabaseForm/ShareDatabaseFormContainer';
import { makeStyles } from '@material-ui/styles';
import { Colors } from 'src/shared/theme';

const useStyle = makeStyles({
  toolbar: {
    backgroundColor: Colors.brandBlue,
    fontSize: 22,
    color: 'white',
  },
  modalPaper: {
    width: '60vw',
    minHeight: '400px',
    maxHeight: '65vh',
    margin: '0 auto',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    right: 0,
  },
  modalContent: {
    paddingTop: 20,
  },
});

interface IProps {
  databaseItem: { id: number; databaseName: string; displayName: string; [key: string]: any };
  open: boolean;
  shareableUsers: any[];
  onClose: (e?: any) => any;
}
export default function ShareDataBaseFormModal(props: IProps) {
  const { open, onClose } = props;
  const classes = useStyle();
  return (
    <Modal open={Boolean(open && props.databaseItem && props.databaseItem.id)} onClose={onClose} disableBackdropClick={true}>
      <Paper className={classes.modalPaper}>
        <Toolbar className={classes.toolbar}>
          <>Share Database</>
          <div style={{ flexGrow: 1 }} />
          <TooltipedIconButton tooltip='Close' onClick={onClose}>
            <CloseIcon nativeColor='white' />
          </TooltipedIconButton>
        </Toolbar>
        <div className={classes.modalContent}>
          {open && <ShareDatabaseFormContainer dbId={props.databaseItem.id} onClose={onClose} showCancel={true} onSave={onClose} />}
        </div>
      </Paper>
    </Modal>
  );
}
