import PERMISSIONS from '../enum';
const dashboardAccess = {
  create: [PERMISSIONS.DASHBOARD.WRITE],
  delete: [PERMISSIONS.DASHBOARD.DELETE],
  edit: [PERMISSIONS.DASHBOARD.WRITE],
  export: [PERMISSIONS.DASHBOARD.EXPORT],
  share: [PERMISSIONS.DASHBOARD.SHARE],
  view: [PERMISSIONS.DASHBOARD.VIEW],
};

export default dashboardAccess;
