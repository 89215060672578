import { connect } from 'react-redux';
import QuestionStudio from './QuestionStudio';

import { IRootState } from 'src/store/rootReducer';
import questionStudioActions from 'src/store/questionStudio/questionStudioActions';
import questionStudioSelectors from 'src/store/questionStudio/selectors';

const mapStateToProps = (state: IRootState) => {
  const { databases, questionStudio } = state;
  return {
    id: questionStudio.id,
    name: questionStudio.name,
    tags: questionStudio.tags,
    description: questionStudio.description,
    lastQueryPage: questionStudio.lastQueryPage,
    previewResults: questionStudio.previewResult,
    database: questionStudio.database,
    datasourceID: questionStudio.database ? questionStudio.database.id : null,
    baseTable: questionStudio.baseTable,
    dbTables: questionStudio.dbTables,
    isLoadingPreview: questionStudio.isLoadingPreview,
    viewTypeOption: questionStudio.viewTypeOption,
    limit: questionStudio.limit,
    chartOptions: questionStudio.chartOptions,
    allColumns: questionStudioSelectors.allColumns(state),
    hasRawData: questionStudioSelectors.hasRawData(state),
    hasAnyData: questionStudioSelectors.hasAnyData(state),
    availableColumns: questionStudioSelectors.allColumns(state),
    relatedTables: questionStudioSelectors.relatedTables(state),
    viewsItems: questionStudio.viewsItems,
    groupItems: questionStudio.groupItems,
    filtersItems: questionStudio.filtersItems,
    isLoadingList: databases.loading,
    mayNeedRefresh: questionStudio.mayNeedRefresh,
    expandPreviewResultCard: questionStudio.expandPreviewResultCard,
    showPreviewPanelPlaceHolderMessage: questionStudio.showPreviewPanelPlaceHolderMessage,
  };
};

const mapDispatchToProps = {
  resetStudioState: questionStudioActions.resetState,
  loadQuestionById: questionStudioActions.loadQuestionById,
  onSelectDb: questionStudioActions.onSelectDb,
  onViewRefreshed: questionStudioActions.onViewRefreshed,
  onFormModelUpdated: questionStudioActions.onFormModelUpdated,
  pickRawData: questionStudioActions.pickRawData,
  pickRowsCount: questionStudioActions.pickRowsCount,
  pickViewItem: questionStudioActions.pickViewItem,
  pickGroupItem: questionStudioActions.pickGroupItem,
  pickFilterItemToAdd: questionStudioActions.pickFilterItem,
  onSelectTable: questionStudioActions.onSelectBaseTable,
  batchQuestionStudioState: questionStudioActions.batchState,
  toggleExpandFormCard: questionStudioActions.toggleExpandFormCard,
  resetPanel1Data: questionStudioActions.resetIngredientsPanelData,
  doPreviewResult: questionStudioActions.previewQuestion,
};
export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;

const QuestionFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionStudio);
export default QuestionFormContainer;
