import React from 'react';
import { getMetaColumnNameInDb } from 'src/shared/models/ExecuterResultResponseDto';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { ColumnType } from 'src/shared/models/SqlColumn';
import ReactAutoCompleteSelect from 'react-select';
import { withStyles } from '@material-ui/core';

function SwitchChartGroup({ meta = [] as Array<ColumnMetadatum>, classes, onChanged, currentGroup }) {
  const dimensions = meta
    .filter(c => c.type == ColumnType.DIMENSION && c.expression.length == 1)
    .map(c => ({
      value: getMetaColumnNameInDb(c),
      name: getMetaColumnNameInDb(c),
      label: c.label,
    }));
  return dimensions.length > 1 ? (
    <div className={classes.multiDimensionSelectWrap}>
      <ReactAutoCompleteSelect
        isClearable={false}
        isMulti={false}
        onChange={onChanged}
        value={currentGroup as any}
        name='color'
        options={dimensions}
      />
    </div>
  ) : (
    <></>
  );
}

export default withStyles({
  multiDimensionSelectWrap: {
    position: 'absolute',
    right: 20,
    top: 10,
    width: '150px',
    zIndex: 20,
  },
})(SwitchChartGroup);
