import { Button, CircularProgress, DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import useLoadDbTables from 'src/hooks/useLoadDbTables';
import useLoadDbTablesAcl from 'src/hooks/useLoadDbTablesAcl';
import useMapArrayToSelectOptions from 'src/hooks/useMapArrayToSelectOptions';
import useShareableUsers from 'src/hooks/useSharableUsers';
import ActionFooter from 'src/layout/ActionFooter';
import discoveryApis from 'src/shared/apis/discoveryApis';
import { DatabaseAccessListDto as DbAccessListDto } from 'src/shared/models/DatabaseAccessListDto';
import { ShareableUser } from 'src/shared/models/ShareableUser';
import { SQLTable } from 'src/shared/models/SqlTable';
import confirmUtils from 'src/store/confirmationPopup/utils';
import toastr from '../toastr';
import { ShareDatabaseFormRowItem } from './models';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  createEmptyRow,
  getArrItemByFieldName,
  mapDbAccessListDtoToShareDbFormRows,
  mapShareDbFormRowsToPostDbAccessListDto,
} from './utils';
import ShareDatabaseForm from './ShareDatabaseForm';

const useStyle = makeStyles({
  actionsRow: {
    padding: '10px 20px',
    position: 'absolute',
    bottom: 0,
    right: 20,
  },
});

interface ShareDatabaseFormContainerProps {
  dbId: number;
  showCancel?: boolean;
  onSave: (rows) => any;
  onClose?: () => any;
}
const ShareDatabaseFormContainer = (props: ShareDatabaseFormContainerProps) => {
  const { onSave, onClose, showCancel, dbId } = props;
  const classes = useStyle();
  const [users, isLoadingUsers] = useShareableUsers();
  const [tables, isLoadingTables] = useLoadDbTables(props.dbId);
  const [tablesAcl, isLoadingTablesAcl] = useLoadDbTablesAcl(props.dbId);
  const [vid, setVid] = useState(0);
  const userDropdownOptions = {
    label: u => `${u.firstName} ${u.lastName} (${u.username})`,
    value: u => u.username,
  };
  const tableDropdownOptions = {
    label: id => `${id.displayName}`,
    value: id => id.id,
  };

  const mappedUsers = useMapArrayToSelectOptions(users as ShareableUser[], userDropdownOptions);
  const mappedTables = useMapArrayToSelectOptions(tables as SQLTable[], tableDropdownOptions);

  const [rows, setRows] = useState([] as Array<ShareDatabaseFormRowItem>);
  const [saveLoading, setSaveLoading] = useState(false);
  const loadingAnyData = isLoadingUsers || isLoadingTables || isLoadingTablesAcl;
  useEffect(
    () => {
      if (loadingAnyData) return;
      const userItemByUsername = getArrItemByFieldName.bind({}, mappedUsers, 'username');
      const tableItemById = getArrItemByFieldName.bind({}, mappedTables, 'id');
      let rows = mapDbAccessListDtoToShareDbFormRows(tablesAcl as DbAccessListDto, userItemByUsername, tableItemById);
      let _vid = vid + 1;
      rows = rows.map(row => {
        const _v = _vid++;
        setVid(_v);
        return createEmptyRow(_v, row);
      });
      setRows(rows);
    },
    [tablesAcl, mappedUsers, mappedTables, loadingAnyData]
  );

  const onAddRow = (row?) => {
    setVid(vid + 1);
    setRows(rows.concat(createEmptyRow(vid, row)));
  };

  const onRowTablesChanged = (index, tables) => {
    setRows(rows.map((r, i) => (i !== index ? r : { ...r, tables })));
  };

  const onDeleteRow = index => {
    setRows(rows.filter((r, i) => i !== index));
  };

  const onRowUserChanged = (index, user) => {
    setRows(rows.map((r, i) => (i !== index ? r : { ...r, user })));
  };

  const onDuplicate = index => {
    onAddRow({ ...rows[index] });
  };

  const localOnCancel = () => {
    if (rows.some(r => !!r.user)) {
      confirmUtils.confirm({
        msg: 'Are you sure you want to cancel sharing',
        ok: () => {
          onClose && onClose();
        },
      });
    } else {
      onClose && onClose();
    }
  };

  const validateRows = () => {
    const invalidRows = rows.some(r => !r.user || !r.tables || !r.tables.length);
    return !invalidRows;
  };

  const handleSave = () => {
    if (!validateRows()) {
      toastr.error('please validate all rows.');
      return;
    }
    setSaveLoading(true);
    const tables = mapShareDbFormRowsToPostDbAccessListDto(rows);
    discoveryApis.updateDatabaseTablesAccessList(props.dbId, { tables }).then(
      r => {
        toastr.success('Tables Access list updated successfully');
        setSaveLoading(false);
        onSave(rows);
      },
      err => {
        setSaveLoading(false);
        console.error('Error updating Tables Access list,', err);
        toastr.error('Error updating Tables Access list,', err);
      }
    );
  };

  return (
    <div>
      {loadingAnyData ? (
        <DialogContent>
          <CircularProgress /> Loading...
        </DialogContent>
      ) : (
        <Scrollbars autoHide style={{ zIndex: 1, height: '260px', width: '100%' }}>
          <ShareDatabaseForm
            rows={rows}
            tables={mappedTables}
            users={mappedUsers}
            isLoadingTables={Boolean(isLoadingTables)}
            isLoadingUsers={Boolean(isLoadingUsers)}
            onAddRow={onAddRow}
            onDeleteRow={onDeleteRow}
            onDuplicate={onDuplicate}
            onRowUserChanged={onRowUserChanged}
            onRowTablesChanged={onRowTablesChanged}
          />
        </Scrollbars>
      )}
      <DialogActions className={classes.actionsRow}>
        {showCancel && (
          <Button onClick={localOnCancel} color='default' variant='outlined'>
            <>cancel</>
          </Button>
        )}
        <Button disabled={saveLoading || !validateRows()} onClick={handleSave.bind({})} color='primary' variant='outlined'>
          {saveLoading && <CircularProgress size={14} />} Save
        </Button>
      </DialogActions>
      <ActionFooter resetBtn={false} handleSave={handleSave} handleCancel={localOnCancel} />
    </div>
  );
};
export default ShareDatabaseFormContainer;
