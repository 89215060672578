import { Theme } from '@material-ui/core/styles';
import { measures, StyleRulesCallback } from '../main.style';

export const styles: StyleRulesCallback = (theme: Theme) => ({
  footer: {
    width: '100%',
    background: '#FFF',
    height: `${measures.footerHeight}px`,
    minHeight: `${measures.footerHeight}px`,
  },
});
