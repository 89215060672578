import React from 'react';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SubMenuItem from './SubMenuItem';

export default class NestableMenu extends React.Component<any, any> {
  renderMenuItems = () => {
    const { menuItems } = this.props;
    return menuItems.map(menuItem => {
      if (menuItem.hasOwnProperty('subMenuItems')) {
        return <SubMenuItem key={menuItem.key} label={menuItem.label} menuItems={menuItem.subMenuItems} />;
      }

      if (menuItem.hasOwnProperty('itemRendered')) {
        return menuItem.itemRendered;
      }

      return (
        <MenuItem key={menuItem.key} onClick={menuItem.onClick}>
          {menuItem.label}
        </MenuItem>
      );
    });
  };

  render() {
    const { anchorElement, open, onClose } = this.props;
    return (
      <MuiMenu
        anchorEl={anchorElement}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {this.renderMenuItems()}
      </MuiMenu>
    );
  }
}
