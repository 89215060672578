import React from 'react';
import { TextField, MenuItem, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { SQLColumn } from 'src/shared/models/SqlColumn';
export type ISqlColumnWithTableNames = SQLColumn & { tableDisplayName: string };
interface IProps {
  columnsList: Array<ISqlColumnWithTableNames>;
  selectedColId?: number;
  onChange: (colId: number) => any;
}
export default function PrimaryColumnSelect(props: IProps) {
  const { selectedColId, columnsList = [] } = props;
  const { onChange } = props;

  const renderColumnItem = (column: ISqlColumnWithTableNames) => (
    <MenuItem component='span' value={column.id} key={column.id}>
      <>
        <Typography className='meun-item-txt' component='span' color='default' variant='body1'>
          {column.tableDisplayName}
        </Typography>
        <ArrowForward className='meun-item-icon-fontsize' />
        <Typography className='meun-item-txt' component='span' color='primary' variant='subtitle1'>
          {column.displayName}
        </Typography>
      </>
    </MenuItem>
  );

  const handleOnChange = e => {
    const id = parseInt(e.target.value);
    onChange(id);
  };
  return (
    <TextField
      id='foreign-key'
      label='foreign Key '
      name='fkColumn'
      variant='outlined'
      fullWidth
      select
      InputLabelProps={{ shrink: true }}
      value={selectedColId || ''}
      onChange={handleOnChange}
    >
      <MenuItem value="''" key={0}>
        {' '}
        None{' '}
      </MenuItem>
      {columnsList.map(renderColumnItem)}
    </TextField>
  );
}
