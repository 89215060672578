import * as React from 'react';

import Tabs from '@material-ui/core/Tabs/Tabs';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Add from '@material-ui/icons/Add';

import StudioTab from './StudioTab';
import Styled from './StudioTabStyle';
import AddFiltersButton, { Filters } from '../filters';

import OiIconButton from 'components/OiIconButton';
import CanDo from 'components/Permissions/Can';
import ShareWithUsersPopup from 'src/components/ShareUsersPopup';
import AddQuestionsPopup from 'src/modules/dashboards/Studio/components/AddQuestionsPopup';
import { getViewTypeLayoutDimensions } from 'src/modules/dashboards/dashboard.service';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import dashboardAccess from 'src/services/permissions/abilities/dashboard';

function* generator(i) {
  while (true) yield i++;
}

class StudioTabs extends React.Component<any, any> {
  gen = generator(1);

  // Tabs
  handleChangeTab = (event, value) => {
    let _tabs = this.props.tabs.slice();
    let _selected = this.props.selected;
    if (_selected === value) {
      return;
    } else {
      _tabs[_selected].editable = false;

      if (_tabs[_selected].name == '') {
        _tabs[_selected].name = 'New tab';
      }
      this.props.onChangeSelected(value);
    }
  };

  handleAddTabs = () => {
    let tabs = this.props.tabs.slice();
    tabs.push({ index: this.gen.next().value, name: `New Tab`, editable: false, views: [] });

    this.props.onChangeTabs(tabs);
  };

  handleDeleteTab = index => {
    const { tabs, selected } = this.props;

    if (tabs.length <= 1) {
      return;
    }

    let _tabs = tabs.slice(0);
    _tabs.splice(index, 1);

    let nextSelect = 0;
    if (_tabs.length === 1) {
      nextSelect = 0;
    }
    if (index < selected || selected === index) {
      nextSelect = selected - 1;
    }
    if (nextSelect < 0) {
      nextSelect = 0;
    }

    this.props.onChangeTabs(_tabs);
    this.props.onChangeSelected(nextSelect);
  };

  handleEditable = index => {
    this.handleChangeTab(null, index);

    let tabs = this.props.tabs.slice();
    tabs[index].editable = !tabs[index].editable;

    if (!tabs[index].editable) {
      if (tabs[index].name === '') {
        tabs[index].name = 'New tab';
      }
    }
    this.props.onChangeTabs(tabs);
    this.props.onChangeSelected(index);
  };

  handleChangeTabName = (event, index) => {
    event.stopPropagation();
    event.preventDefault();
    let tabs = this.props.tabs.slice();
    tabs[index].name = event.target.value;
    tabs[index].editable = true;

    this.props.onChangeTabs(tabs);
  };
  // React Grid
  AddQuestion = (question: OiQuestionDto) => {
    let selected = this.props.selected;
    let tabs = this.props.tabs.slice(0);

    let nextIndx = new Date().getTime();

    const viewType = question.defaultView;
    const view = {
      questionId: question.id,
      _i: nextIndx,
      i: nextIndx.toString(),
      x: 0, //,
      y: Number.MAX_SAFE_INTEGER,
      ...getViewTypeLayoutDimensions(viewType),
    };
    tabs[selected].views.push(view);

    this.props.onChangeTabs(tabs);
  };
  handleFilterPreview = filters => {
    const { onFilterPreview } = this.props;
    onFilterPreview(filters);
  };
  handleShare = users => {
    const { onShare } = this.props;
    onShare(users);
  };
  render() {
    const { isViewMode, tabs, selected, classes, filters } = this.props;
    const isEditMode = !isViewMode;
    return (
      <AppBar className='dashboard-tabs-appbar' position='static' color='inherit'>
        <Tabs className='tabs' value={selected} TabIndicatorProps={{ className: classes.indicator }}>
          {tabs.map((tab, index) => {
            return (
              <div
                className={`${classes.tab} tab ${selected === index && 'selected'}`}
                key={index}
                onClick={e => this.handleChangeTab(e, index)}
              >
                <StudioTab
                  tab={tab}
                  isDeleteDisabled={tabs.length <= 1}
                  isViewMode={isViewMode}
                  onChangeTabName={event => this.handleChangeTabName(event, index)}
                  onEditable={e => this.handleEditable(index)}
                  onDeleteTab={e => this.handleDeleteTab(index)}
                />
              </div>
            );
          })}
          {isEditMode && (
            <OiIconButton
              rounded
              disabled={tabs.length >= 4}
              aria-label='add'
              className='add-tab-btn'
              color='primary'
              onClick={this.handleAddTabs}
            >
              <Add />
            </OiIconButton>
          )}

          <div style={{ flex: 1 }} />
          <CanDo action={dashboardAccess.share}>
            <ShareWithUsersPopup className={'add-tab-btn'} onDone={this.handleShare} rounded />
          </CanDo>

          {filters.length > 0 && <Filters onFilterPreview={this.handleFilterPreview} />}
          {isEditMode && <AddFiltersButton />}
          {isEditMode && <AddQuestionsPopup AddQuestion={q => this.AddQuestion(q)} />}
        </Tabs>
      </AppBar>
    );
  }
}

const styled = Styled(StudioTabs);
export default styled;
