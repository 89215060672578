import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TasksListComponent from './tasks-list';
import CanActivateModule from 'src/components/Permissions/CanActivateModule';
import { connectedCanDo } from 'src/services/permissions/utils';
import questionAccess from 'src/services/permissions/abilities/questions';
import NotAuthorizedPage from 'src/layout/NotAuthorized';
import taskAccess from 'src/services/permissions/abilities/tasks';

const Routes = ({ match }) => (
  <Switch>
    <Route path={match.url} component={connectedCanDo(taskAccess.view) ? TasksListComponent : NotAuthorizedPage} />
  </Switch>
);

export default Routes;
