import * as React from 'react';
import { Button, CircularProgress, LinearProgress } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { QUESTION_DRILL_DOWN_OPERATION, ChartCustomizationOptions } from 'src/components/QuestionPreviewer/models';
import {
  IsKpiView,
  IsTableView,
  IsChartView,
  openColumnDistributionByDatePage,
  openColumnDistribution,
  applyUniqueFunctionOnColumn,
} from 'src/components/QuestionPreviewer/helpers';
import ChartsComponentContainer from 'components/Charts';
import Colorize from '@material-ui/icons/Colorize';
import { getMetaColumnNameInDb } from 'src/shared/models/ExecuterResultResponseDto';
import { IPreviewResult } from 'src/shared/models/IPreviewResult';
import { VIEW_TYPE, viewTypesOptionsList, ViewTypeItem } from './questionViewTypes';
import { PieChartClickEvent } from 'src/shared/models/EchartsClickEvents';
import { PreviewQuestionDto } from 'src/shared/models/questionPreviewDto';
import NumberView from 'src/components/QuestionPreviewer/NumberView';
import QuestionToolbar, { ChangePreviewerColorEvent } from 'src/components/QuestionPreviewer/QuestionToolbar';
import CustomEnhancedTableComponent from 'src/components/CustomEnhancedTable/CustomEnhancedTableComponent';
import apisService from 'src/shared/apis';
import { objToQParams } from 'src/shared/util';
import OiIcon from 'src/assets/icons';
import classNames from 'classnames';
import { OiQuestionDto } from 'src/shared/models/OiQuestionDto';
import { QuestionStudioStoreState, QueryFilterViewItem } from 'src/modules/questions/studio/models';
import DynamicFiltersPopup from './DynamicFiltersPopup';
import ColorSchemaSelect from './ColorSchemaSelect';
import OiIconButton from 'src/components/OiIconButton';
import { ColumnAnalysisModal } from './ColumnAnalysisModal';
import QuestionPreviewerStyler from './QuestionPreviewerStyler';
import EmptyResultSetView from './EmptyResultSetView';
import { downloadFileResponse } from 'src/shared/apis/oceanAuthApis';
import toastr from '../toastr';
import { store } from 'src/constants';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { ColumnExpression } from 'src/shared/models/OiQueryColumn';
import { IRootState } from 'src/store/rootReducer';
import { getColumnCacheName } from 'src/store/columnsMap/helpers';

export type MultiDimensionGroup = {
  name?: string;
  value?: string;
  label?: string;
};
export enum QuestionViewMode {
  VIEW = 'VIEW',
  DASHBOARD_STUDIO_EDIT = 'DASHBOARD_STUDIO_EDIT',
  DASHBOARD_STUDIO_VIEW = 'DASHBOARD_STUDIO_VIEW',
  QUESTION_STUDIO = 'QUESTION_STUDIO',
}

export interface IQuestionPreviewerProps {
  classes?: any;
  toolBarStartButtons?: any;
  toolBarEndButtons?: any;
  showPreviewButton?: boolean;
  hasRawData: boolean;
  isLoadingPreview?: boolean;
  previewResult?: IPreviewResult;
  mode?: QuestionViewMode;
  isLoadingQueryNextChunk?: boolean;
  hidePickColorSchema?: boolean;
  questionDto?: OiQuestionDto;
  questionModel?: QuestionStudioStoreState;

  viewTypeOption: ViewTypeItem;
  chartOptions?: ChartCustomizationOptions;
  onTablePaginationChanged?: (page: number) => any;
  loadNextQueryChunk: () => any;
  onViewTypeOptionUpdated: (viewTypeOption: ViewTypeItem) => any;

  onChangeComponentColor?: (e: ChartCustomizationOptions) => any;
  onChartCustomizationChanged?: (e: ChartCustomizationOptions) => any;

  onPreviewResult?: () => any;
  onExecuteFilters?: (filters: QueryFilterViewItem[]) => any;

  onDoSegmentZoom?: (e: { direction: any; clickedSegment: PieChartClickEvent; previewResult: PreviewQuestionDto; chartGroup?: any }) => any;
  onDoChartDrillDown?: (e: { clickedSegment: PieChartClickEvent; previewResult: PreviewQuestionDto; chartGroup?: any }) => any;
  onDoSegmentFilterOut?: (e: { clickedSegment: PieChartClickEvent; previewResult: PreviewQuestionDto; chartGroup?: any }) => any;

  onExportQuestion?: (x?: { extension: string; [key: string]: any }) => any;
  onShareQuestionToEmails?: (x?: { extension: string; [key: string]: any }) => any;
  onShareWithUsers?: (x?: { users: Array<string>; [key: string]: any }) => any;
  // [key: string]: any;
}
export interface IQuestionPreviewerState {
  // viewTypeOption?: ViewTypeItem;
  // chartOptions: ChartCustomizationOptions;
  isLoadingQueryNextChunk?: boolean;
  chartGroup?: MultiDimensionGroup;
  openAnalysisModal: boolean;
  analysisModal: any;
  column: any;
}

export class QuestionPreviewer extends React.Component<IQuestionPreviewerProps, IQuestionPreviewerState> {
  state: IQuestionPreviewerState = {
    chartGroup: undefined,
    openAnalysisModal: false,
    analysisModal: {},
    column: {},
  } as IQuestionPreviewerState;
  chartRef: any = null;

  get isKpiView() {
    return IsKpiView(this.viewTypeOption, this.props.previewResult);
  }

  get isTableView() {
    return IsTableView(this.viewTypeOption, this.props.previewResult);
  }

  get isChartView() {
    return IsChartView(this.viewTypeOption, this.props.previewResult);
  }

  get chartOptions() {
    const { viewTypeOption } = this;

    return Object.assign({}, this.props.chartOptions, (viewTypeOption && viewTypeOption!.customize) || {});
  }

  get viewTypeOption() {
    return this.props.viewTypeOption;
  }

  get currentGroup() {
    const chartGroup = this.state.chartGroup;
    const previewResult = this.props.previewResult;
    return (
      (previewResult &&
        ((chartGroup && previewResult.metadata.find(c => getMetaColumnNameInDb(c) == chartGroup.value)) || previewResult.metadata[0])) ||
      undefined
    );
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps: IQuestionPreviewerProps) {
    let props = this.props;
    if (props.previewResult && prevProps.previewResult !== props.previewResult) {
      let viewType = props.previewResult!.viewType || VIEW_TYPE.TABLE;
      const viewTypeOption = viewTypesOptionsList.find(o => o.type.toString() == viewType);
    }
  }

  onSelectExportExtension = extension => {
    const { onExportQuestion, questionModel } = this.props;
    if (extension == 'image') {
      const dataUrl = this.chartRef.getConnectedDataURL({ type: 'png', backgroundColor: 'white' });
      const fileName = questionModel ? questionModel.name : 'export';
      const isDataUrl = true;
      downloadFileResponse(dataUrl, fileName, isDataUrl);
    } else {
      onExportQuestion &&
        onExportQuestion({
          extension: extension,
          name: questionModel ? questionModel.name : '',
        });
    }
  };

  onShareWithUsers = users => {
    this.props.onShareWithUsers &&
      this.props.onShareWithUsers({
        users: users,
      });
  };

  onShareQuestionToEmails = extension => {
    this.props.onShareQuestionToEmails &&
      this.props.onShareQuestionToEmails({
        extension: extension,
      });
  };

  onViewSegmentDetail = clickedSegment => {
    this.props.onDoChartDrillDown &&
      this.props.onDoChartDrillDown({
        clickedSegment: clickedSegment as any,
        previewResult: this.props.previewResult as any,
        chartGroup: this.state.chartGroup,
      });
  };

  onDoSegmentZoom = (direction, clickedSegment) => {
    this.props.onDoSegmentZoom &&
      this.props.onDoSegmentZoom({
        direction,
        clickedSegment: clickedSegment as any,
        previewResult: this.props.previewResult as any,
        chartGroup: this.state.chartGroup,
      });
  };

  onDoSegmentFilterOut = clickedSegment => {
    this.props.onDoSegmentFilterOut &&
      this.props.onDoSegmentFilterOut({
        clickedSegment: clickedSegment,
        previewResult: this.props.previewResult as any,
        chartGroup: this.state.chartGroup,
      });
  };

  onChangeComponentColor = (changedOption: ChangePreviewerColorEvent) => {
    const { onChartCustomizationChanged } = this.props;
    let newChartOptions = {
      ...this.props.chartOptions,
      ...changedOption,
    };
    onChartCustomizationChanged && onChartCustomizationChanged(newChartOptions as any);
    // this.setState({ chartOptions: newChartOptions } as any);
  };

  onChartCustomizationOptionChanged = changedOption => {
    const { onChartCustomizationChanged } = this.props;
    let newChartOptions = {
      ...this.props.chartOptions,
      ...changedOption,
    };
    onChartCustomizationChanged && onChartCustomizationChanged(newChartOptions);
    // this.setState({ chartOptions: newChartOptions } as any);
  };

  onTableColumnFunctionClicked = (column, operation) => {
    const questionEditorModel = this.props.questionModel;
    const meta = column.meta as ColumnMetadatum;
    const expression = meta.expression[0] as ColumnExpression;
    const columnFunction = expression.function;
    const dbColName = expression.dbColumn;
    const table_ColName = getColumnCacheName(meta.tableName, dbColName);
    let sqlCol = (store.getState() as IRootState).columnsMap.byNames[table_ColName];
    if (operation == 'statistics') {
      if (!sqlCol || sqlCol.id === undefined) {
        return toastr.warn(`Can't get db statistics for calculated columns`);
      }
      apisService.getColumnAnalysis(sqlCol.id).then(analysis => {
        this.setState({
          openAnalysisModal: true,
          analysisModal: analysis,
          column,
        });
      });
    } else if (operation == 'unique') {
      if (questionEditorModel) {
        applyUniqueFunctionOnColumn(sqlCol, questionEditorModel);
      }
    } else if (operation == 'distribution') {
      if (questionEditorModel) {
        openColumnDistribution(sqlCol, questionEditorModel);
      }
    } else if (operation == 'distribution_by_date') {
      if (questionEditorModel) {
        openColumnDistributionByDatePage(sqlCol, questionEditorModel);
      }
    }
  };

  _renderPreviewResultComponent = () => {
    const { previewResult, isLoadingPreview, classes } = this.props;
    const viewTypeOption = this.viewTypeOption || viewTypesOptionsList[0];
    if (isLoadingPreview) {
      return (
        <div className={classes.LoadingWrap}>
          <CircularProgress /> Loading ...{' '}
        </div>
      );
    }
    if (previewResult && previewResult.resultset) {
      let resultSet = previewResult.resultset;
      const noData = resultSet.length == 0 && previewResult.metadata.length == 0 && previewResult.data.length == 0;
      if (noData) {
        return <EmptyResultSetView />;
      }
      if (this.isChartView && previewResult.chart) {
        return (
          <ChartsComponentContainer
            data={previewResult.resultset.slice()}
            meta={previewResult.metadata.slice()}
            type={'' + viewTypeOption!.chartType}
            customization={this.chartOptions}
            setChartRef={chartRef => (this.chartRef = chartRef)}
            currentGroup={this.currentGroup}
            onDoSegmentZoom={this.onDoSegmentZoom}
            onDoSegmentDrillDown={this.onViewSegmentDetail}
            onDoSegmentFilterOut={this.onDoSegmentFilterOut}
            onChartGroupChanged={g =>
              this.setState({
                chartGroup: g,
              })
            }
          />
        );
      } else if (this.isKpiView) {
        const data: any = previewResult.data && previewResult.data[0];
        const value = data ? data.value : (resultSet && resultSet[0] && resultSet[0][0]) || '';
        const label =
          previewResult.title ||
          (data ? data.name : (previewResult.metadata && previewResult.metadata[0] && previewResult.metadata[0].label) || '');
        return <NumberView value={value} displayName={label} previewResult={previewResult} color={this.chartOptions.kpiColor} />;
      } else if (previewResult.table) {
        // return <EnhancedTable meta={previewResult.table.columns} data={previewResult.table.data} />;
        return (
          <CustomEnhancedTableComponent
            isLoadingQueryNextChunk={this.props.isLoadingQueryNextChunk}
            totalCount={previewResult.totalCount || previewResult.pageLength}
            totalPages={previewResult.totalPages || previewResult.pageNum}
            meta={previewResult.table.columns}
            data={previewResult.table.data}
            viewOptions={this.chartOptions}
            onOpenStatics={(column, op) => {
              this.onTableColumnFunctionClicked(column, op);
              this.setState({ column });
            }}
            mode={this.props.mode}
            onPaginationChanged={this.props.onTablePaginationChanged}
            loadNextQueryChunk={this.props.loadNextQueryChunk}
          />
        );
      }
      return <></>;
    }
  };

  _renderQuestionToolbar = () => {
    const { previewResult, mode, isLoadingPreview, classes, onExecuteFilters, questionDto, questionModel } = this.props;
    const viewTypeOption = this.viewTypeOption;
    const isViewMode = mode == QuestionViewMode.VIEW;
    const isDashboardStudio = mode == QuestionViewMode.DASHBOARD_STUDIO_EDIT;
    const isQuestionStudio = mode == QuestionViewMode.QUESTION_STUDIO;
    const hideToolBar = isViewMode && isLoadingPreview; // isViewMode && (isLoadingPreview || this.isKpiView);
    const dynamicFiltersItems = ((questionModel && questionModel.filtersItems) || []).filter(s => s.dynamic);
    let title = questionModel ? questionModel.name : (previewResult && previewResult.title) || '';
    let defaultToolbar = (
      <QuestionToolbar
        hasRawData={this.props.hasRawData}
        questionDto={questionDto}
        questionModel={questionModel}
        chartOptions={this.chartOptions}
        previewResult={previewResult}
        isLoadingPreview={isLoadingPreview}
        onExecuteFilters={filters => {
          onExecuteFilters && onExecuteFilters(filters);
        }}
        title={title}
        isKpiView={this.isKpiView}
        isChartView={this.isChartView}
        isTableView={this.isTableView}
        viewTypeOption={viewTypeOption}
        hidePickColorSchema={this.props.hidePickColorSchema}
        onChartCustomizationOptionChanged={this.onChartCustomizationOptionChanged}
        onChangeComponentColor={this.onChangeComponentColor}
        toolBarStartButtons={this.props.toolBarStartButtons}
        toolBarEndButtons={this.props.toolBarEndButtons}
        onSelectExportExtension={this.onSelectExportExtension}
        onSelectViewTypeOption={this.props.onViewTypeOptionUpdated}
        onShareQuestionToEmails={this.onShareQuestionToEmails}
        onShareWithUsers={this.onShareWithUsers}
        questionViewMode={mode}
      />
    );
    if (hideToolBar) return <></>;

    if (mode == QuestionViewMode.QUESTION_STUDIO) {
      return <div className='question-toolbar'>{defaultToolbar}</div>;
    } else if (this.isKpiView) {
      return (
        <div className={classNames(classes.kpiToolbar, 'question-toolbar kpi-toolbar')}>
          <div className={classes.leftButtons}>{this.props.toolBarStartButtons}</div>

          <div className={classes.rightButtons}>
            {(dynamicFiltersItems.length && (
              <DynamicFiltersPopup
                isLoadingPreview={isLoadingPreview}
                onExecuteFilters={filters => {
                  onExecuteFilters && onExecuteFilters(filters);
                }}
                questionDto={questionDto}
                questionModel={questionModel}
                icon={<OiIcon path='FilterFunnel' className='toolbar-icon' style={{ color: 'white' }} />}
              />
            )) || <></>}
            {isDashboardStudio &&
              ((
                <ColorSchemaSelect
                  onChange={this.onChartCustomizationOptionChanged}
                  singleColor={true}
                  icon={
                    <OiIconButton>
                      <Colorize />
                    </OiIconButton>
                  }
                />
              ) || <></>)}
            {this.props.toolBarEndButtons}
          </div>
        </div>
      );
    } else {
      return <div className='question-toolbar'>{defaultToolbar}</div>;
    }
  };

  _renderPreviewButton = () => {
    const { isLoadingPreview, classes } = this.props;
    return (
      <Button
        className={classNames('btn-rounded', classes.previewButton)}
        variant='contained'
        type='button'
        color='primary'
        disabled={isLoadingPreview}
        onClick={this.props.onPreviewResult}
      >
        {isLoadingPreview ? (
          <>
            {' '}
            <RefreshIcon /> Loading{' '}
          </>
        ) : (
          ' Preview '
        )}
      </Button>
    );
  };

  render() {
    const { analysisModal, openAnalysisModal, column } = this.state;
    const { isLoadingPreview, classes, showPreviewButton } = this.props;
    const isViewMode = this.props.mode == QuestionViewMode.VIEW;
    const hideToolBar = isViewMode && isLoadingPreview; // isViewMode && (isLoadingPreview || this.isKpiView);
    // {isLoadingPreview && <LinearProgress />}
    return (
      <>
        <div className={classNames(classes.Wrapper)}>
          {this._renderQuestionToolbar()}

          <div
            className={classNames(classes.PreviewWrap, { [classes.showPreviewButton]: showPreviewButton })}
            style={(this.isKpiView ? { height: '100%' } : hideToolBar && { height: '100%' }) || {}}
          >
            <div className='flex'>{(showPreviewButton && this._renderPreviewButton()) || <></>}</div>
            {this._renderPreviewResultComponent()}
          </div>
        </div>

        <ColumnAnalysisModal
          isOpen={openAnalysisModal}
          onClose={e => {
            this.setState({ openAnalysisModal: false });
          }}
          column={column}
          analysisModal={analysisModal}
        />
      </>
    );
  }
}

// export default QuestionPreviewer; //QuestionPreviewerStyler(QuestionPreviewer);
export default QuestionPreviewerStyler(QuestionPreviewer);
