import * as React from 'react';
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, TextField, CircularProgress, Typography } from '@material-ui/core';
import { KEYCODES } from 'src/shared/util/keyboard';
interface IProps {
  isOpen: boolean;
  loader: boolean;
  resetForm: boolean;
  errorMsg: string;
  handleDialogClose: Function;
  handlePasswordSave: Function;
}

interface IState {
  newPassword: string;
}

export default class ChangePasswordDialog extends React.Component<IProps, IState> {
  state = {
    newPassword: '',
  };
  componentWillUpdate(nextProps) {
    if (this.props.resetForm !== nextProps.resetForm) {
      if (nextProps.resetForm === true)
        this.setState({
          newPassword: '',
        });
    }
  }
  render() {
    const { isOpen, loader, handleDialogClose, handlePasswordSave, errorMsg } = this.props;
    const { newPassword } = this.state;
    return (
      <Dialog
        open={isOpen}
        onClose={e => {
          handleDialogClose();
        }}
        aria-labelledby='change-password-dialog'
      >
        <DialogTitle id='change-password-dialog-title' style={{ paddingTop: 10, paddingBottom: 10 }}>
          Change Password
        </DialogTitle>
        <DialogContent style={{ paddingTop: 10 }}>
          <TextField
            variant={'outlined'}
            margin='normal'
            id='newPassword'
            label='New password'
            type='password'
            value={newPassword}
            fullWidth
            onChange={e => {
              this.setState({ newPassword: e.target.value });
            }}
            onKeyDown={e => {
              if (e.keyCode == KEYCODES.ENTER) {
                handlePasswordSave(newPassword);
              }
            }}
          />
          {errorMsg !== '' ? (
            <Typography variant='subtitle1' color='error'>
              {' '}
              {errorMsg}{' '}
            </Typography>
          ) : (
            ''
          )}
        </DialogContent>

        <DialogActions>
          <Button disabled={loader} onClick={e => handleDialogClose()} variant={'text'} color='default'>
            Cancel
          </Button>
          <Button disabled={loader} onClick={e => handlePasswordSave(newPassword)} variant={'contained'} color='primary'>
            {loader ? <CircularProgress size={14} color='secondary' /> : ''} Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
