export enum ComparisonOperator {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL',
  BETWEEN = 'BETWEEN',
  CONTAINS = 'CONTAINS',
  IN = 'IN',
  NOT_IN = 'NOT_IN',
  IS_NULL = 'IS_NULL',
  IS_NOT_NULL = 'IS_NOT_NULL',
  IS_NULL_OR_EMPTY = 'IS_NULL_OR_EMPTY',
  START_WITH = 'START_WITH',
  END_WITH = 'END_WITH',
  LAST_HOURS = 'LAST_HOURS',
  LAST_DAYS = 'LAST_DAYS',
  LAST_MONTHS = 'LAST_MONTHS',
  LAST_QUARTERS = 'LAST_QUARTERS',
  LAST_YEARS = 'LAST_YEARS',
  LAST = 'LAST',
  CURRENT_DAY = 'CURRENT_DAY',
  CURRENT = 'CURRENT',
  CURRENT_MONTH = 'CURRENT_MONTH',
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  CURRENT_YEAR = 'CURRENT_YEAR',
}
