import { StyleRulesCallback, Theme, createStyles, withStyles } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    slide: {
      width: 350,
      height: 400,
      overflow: 'hidden',
      zIndex: 100,
    },
    scrollBar: {
      width: 350,
      height: '315px !important',
    },
    slidingWrap: {
      width: 350,
      height: 400,
    },
    slideContentWrap: {
      height: 'calc( 100% - 45px)',
      overflow: 'hidden',
      paddingBottom: '30px!important',
      '.loading': {
        display: 'flex',
        alignContent: 'center',
        width: '100%',
      },
    },
    stepHeader: {
      borderBottom: '1px solid #e3e3e3',
      padding: 10,
      display: 'flex',
      alignContent: 'center',
      color: '#048edd',
    },
  });
export default withStyles(styles);
