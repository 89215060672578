export const ACTION_TYPES = {
  OPEN_CONFIRMATION_POPUP: 'CONFIRMATION_POPUP/OPEN_CONFIRMATION_POPUP',
  CLOSE_CONFIRMATION_POPUP: 'CONFIRMATION_POPUP/CLOSE_CONFIRMATION_POPUP',
  CONFIRM: 'CONFIRMATION_POPUP/CONFIRM',
};

export interface ConfirmConfigs {
  msg?: string | any;
  okText?: string | false;
  cancelText?: string | false;
  okStyle?: any;
  ok: (ok?: boolean) => any;
  cancel?: (ok?: boolean) => any;
}
const defaultConfirmConfigs: ConfirmConfigs = {
  msg: 'Please confirm action?',
  okText: 'Confirm',
  cancelText: 'cancel',
  ok: _ => null,
};
const initialState = {
  showConfirmationPopup: false,
  confirmConfigs: defaultConfirmConfigs,
};

export type ConfirmationPopupState = Readonly<typeof initialState>;

// Reducer
export default (state: ConfirmationPopupState = initialState, { type, configs, ...action }): ConfirmationPopupState => {
  switch (type) {
    case ACTION_TYPES.OPEN_CONFIRMATION_POPUP:
      return {
        ...state,
        confirmConfigs: { ...defaultConfirmConfigs, ...configs },
        showConfirmationPopup: true,
      };
    case ACTION_TYPES.CLOSE_CONFIRMATION_POPUP:
      return {
        ...state,
        // confirmConfigs: defaultConfirmConfigs,
        showConfirmationPopup: false,
      };
    default:
      return state;
  }
};

export const openConfirmationPopup = (configs: ConfirmConfigs) => dispatch => {
  dispatch({
    type: ACTION_TYPES.OPEN_CONFIRMATION_POPUP,
    configs,
  });
};
export const closeConfirmationPopup = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.CLOSE_CONFIRMATION_POPUP,
  });
};
