import * as React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { saveTableService } from 'modules/databases/data-models/data-model.service';

export interface IProps {
  handleOpen?: any;
  handleClose?: any;
  handleSave?: any;
  data: any;
}
export default class EditTableDialog extends React.Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      dataState: this.props.data,
      loading: false,
      errorMsg: '',
    };
  }
  componentWillUpdate(nextProps) {
    if (this.props.data !== nextProps.data)
      this.setState({
        dataState: nextProps.data,
      });
  }
  handleClose = () => {
    this.props.handleClose();
  };
  handleSave = () => {
    let props = this.props;
    saveTableService(this.state.dataState)
      .then(response => {
        this.setState({ loading: false });
        props.handleClose();
      })
      .catch(err => {
        this.setState({ errorMsg: err });
        console.log(err);
      });
    return;
  };
  render() {
    let table = this.state.dataState;
    return (
      <>
        <Dialog open={this.props.handleOpen}>
          <DialogTitle id='form-dialog-title'>{table.displayName} </DialogTitle>
          <DialogContent style={{ width: '400px' }}>
            <div>
              <TextField
                id='name'
                label='Name'
                margin='normal'
                variant='outlined'
                disabled
                fullWidth
                value={table.name || ''}
                onChange={e => {
                  table.name = e.target.value;
                  this.setState({ dataState: table });
                }}
              />
            </div>
            <div>
              <TextField
                id='displayName'
                name='displayName'
                label='Display Name'
                margin='normal'
                variant='outlined'
                fullWidth
                value={table.displayName || ''}
                onChange={e => {
                  table.displayName = e.target.value;
                  this.setState({ dataState: table });
                }}
              />
            </div>

            <div>
              <TextField
                id='Description-static'
                name='description'
                label='Description'
                placeholder='Add Description'
                multiline
                rows='4'
                variant='outlined'
                fullWidth
                value={table.description || ''}
                margin='normal'
                onChange={e => {
                  table.description = e.target.value;
                  this.setState({ dataState: table });
                }}
              />
            </div>

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    checked={!table.active ? true : false}
                    onChange={e => {
                      table.active = !e.target.checked ? true : false;
                      this.setState({ dataState: table });
                    }}
                  />
                }
                label='Hide'
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    checked={table.monitored ? true : false}
                    onChange={e => {
                      table.monitored = e.target.checked ? true : false;
                      this.setState({ dataState: table });
                    }}
                  />
                }
                label='Monitor'
              />
            </div>
            <Typography color='error'>{this.state.errorMsg}</Typography>
          </DialogContent>

          <DialogActions>
            <Button className='btn-rounded width-100' variant='contained' color='default' onClick={() => this.handleClose()}>
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              className='btn-rounded width-100'
              onClick={e => {
                this.setState({ loading: true });
                this.handleSave();
              }}
            >
              Save
            </Button>
            {this.state.loading ? (
              <span>
                <CircularProgress color='primary' />
              </span>
            ) : (
              <></>
            )}
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
