import React, { useState, useEffect } from 'react';
import Styled, { WizardStyles } from './Styled';
import {
  List,
  ListItem,
  TextField,
  CircularProgress,
  InputAdornment,
  Popover,
  Collapse,
  ListItemIcon,
  Checkbox,
  Button,
  Tooltip,
} from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import SearchIcon from '@material-ui/icons/Search';
import ControlButton from 'src/components/ControlButton';
import OiIcon from 'src/assets/icons';
import { makeStyles } from '@material-ui/styles';
import { SQLTable } from 'src/shared/models/SqlTable';

import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import { simpleFuzzySearchFilter } from '../../utils';
import { MultiSelectItemsList } from 'src/modules/settings/components/BackupModal/BackupItemsList';

const useStyles = makeStyles(WizardStyles);

interface IProps {
  tables: any[];
  isDisabled;
  onDone: (tables) => any;
  onCancel?: any;
}
function RawDataWizard(props: IProps) {
  const { tables: storeTables, isDisabled } = props;
  const { onDone, onCancel } = props;

  const classes = useStyles();

  const [searchTxt, setSearchTxt] = useState('');
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [tables, setTables] = useState(storeTables || []);
  const [openedTable, setOpenedTable] = useState(undefined as any);
  // const

  useEffect(
    () => {
      let tables = storeTables || [];
      setTables(tables);
    },
    [storeTables]
  );

  useEffect(
    () => {
      setTables(storeTables);
      setSearchTxt('');
      setOpenedTable(undefined);
    },
    [anchorEl]
  );

  const handleSearch = e => {
    const txt = e.target.value;
    setSearchTxt(txt);
    let search = txt.trim().toLowerCase();
    let tables = storeTables || [];
    const enableSearchTable = openedTable == undefined;
    tables =
      enableSearchTable && !search
        ? tables.slice()
        : tables.filter(
            table => table.displayName.toLowerCase().includes(search) || table.columns.some(simpleFuzzySearchFilter(search, 'displayName'))
          );

    setTables(tables);
  };

  const handleClose = () => setAnchorEl(undefined);
  const handleCancel = () => {
    handleClose();
    onCancel && onCancel();
  };
  const handleOpenPopover = e => setAnchorEl(e.currentTarget);
  const isOpen = Boolean(anchorEl);

  const normalizedSearchText = searchTxt.trim().toLowerCase();
  const isTableChecked = table => Boolean(!table.columns.some(c => !c.isChecked));

  const updateTable = (table, i) => {
    let _tables = tables.map((t, _i) => {
      if (i == _i) {
        return table;
      }
      return t;
    });
    setTables(_tables);
    return _tables;
  };

  return (
    <div>
      <ControlButton
        disabled={isDisabled}
        title={<div className={classes.H14px}> Raw Data </div>}
        subTitle={''}
        icon={<OiIcon path={'Table'} />}
        borderColor='#107dbb'
        onClick={handleOpenPopover}
      />
      {isOpen && (
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className={classes.slide}>
            <div className={classes.toolbar}>
              <Button onClick={handleCancel}> Cancel</Button>
              <Button
                onClick={e => {
                  onDone(tables);
                  handleClose();
                }}
              >
                Done
              </Button>
            </div>
            <TextField
              className={classes.searchField}
              type='search'
              fullWidth
              value={searchTxt}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Scrollbars className={classes.scrollBar}>
              <List component='nav' className={classes.tablesList}>
                {tables.map((table, i) => {
                  const columns = normalizedSearchText
                    ? table.columns.filter(simpleFuzzySearchFilter(normalizedSearchText, 'displayName'))
                    : table.columns;

                  return (
                    <React.Fragment key={table.id}>
                      <ListItem
                        key={table.id}
                        button
                        className={classes.tableNameWrap}
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          openedTable == i ? setOpenedTable(undefined) : setOpenedTable(i);
                        }}
                      >
                        <Checkbox
                          checked={isTableChecked(table)}
                          tabIndex={-1}
                          disableRipple
                          style={{ padding: 5 }}
                          onClick={e => {
                            e.stopPropagation();
                            let updateStatus = isTableChecked(table) ? false : true;
                            updateTable({ ...table, columns: columns.map(c => ({ ...c, isChecked: updateStatus })) }, i);
                          }}
                        />
                        <OiIcon className={classes.tableIcon} path={'BulletedList'} /> &nbsp;
                        {table.displayName}
                        <Tooltip className={classes.expandIcon} title={openedTable == i ? 'CollabseTable' : 'Expand Table'}>
                          {openedTable == i ? <ExpandLess /> : <ExpandMore />}
                        </Tooltip>
                      </ListItem>

                      <Collapse in={openedTable == i} timeout='auto' unmountOnExit>
                        <div>
                          <MultiSelectItemsList
                            isLoading={false}
                            datasource={columns}
                            title={''}
                            labelOptions={'displayName'}
                            onSelectionUpdate={columns => {
                              updateTable({ ...table, columns }, i);
                            }}
                          />
                        </div>
                      </Collapse>
                    </React.Fragment>
                  );
                })}
                {!tables.length && <ListItem button> No Items </ListItem>}
              </List>
            </Scrollbars>
          </div>
        </Popover>
      )}
    </div>
  );
}

export default RawDataWizard;
