import header from './header.layout';
import { connect } from 'react-redux';
import { getAccout, changePassword } from 'src/store/account/actions';
import { withRouter } from 'react-router';

const mapStateToProps = state => {
  return {
    account: state.account.data,
    passwordChange: state.account.passwordChangeResponse,
    changePassowordLoader: state.account.changeLoader,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAccout: () => dispatch(getAccout()),
    changePassword: data => dispatch(changePassword(data)),
  };
};
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(header);

export default withRouter<any, any>(connected);
