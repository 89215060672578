import axios from 'axios';
import { schedulerUrl } from 'src/shared/apis/baseUrls';

export const TASKS_ACTION_TYPES = {
  GET: 'GET_TASKS',
  DELETE: 'DELETE_TASKS',
  ACTIVATION: 'ACTIVATION_TASKS',
};

export const getTasks = jobServices => {
  return {
    type: TASKS_ACTION_TYPES.GET,
    payload: axios.post(schedulerUrl + '/oi-jobs/service', jobServices),
  };
};

export const activation = data => {
  return {
    type: TASKS_ACTION_TYPES.ACTIVATION,
    payload: axios.put(schedulerUrl + '/oi-jobs', data),
  };
};

export const deleteTask = id => {
  return {
    type: TASKS_ACTION_TYPES.DELETE,
    payload: axios.delete(schedulerUrl + `/oi-jobs/${id}`),
  };
};
