import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// Ocean insight reducers
import reducer from './rootReducer';
// Middlewares
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'src/store/config/logger-middleware';
import errorMiddleware from 'src/store/config/error-middleware';
import notificationMiddleware from 'src/store/config/notification-middleware';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'store/rootSaga';

const sagaMiddleware = createSagaMiddleware();

const defaultMiddlewares = [
  thunkMiddleware,
  errorMiddleware,
  notificationMiddleware,
  loggerMiddleware,
  sagaMiddleware,
  promiseMiddleware(),
  loadingBarMiddleware(),
];

const composedMiddlewares = middlewares =>
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares))
    : compose(applyMiddleware(...defaultMiddlewares, ...middlewares));

const initialize = (initialState = {}, middlewares = []) => {
  const store = createStore(reducer, initialState, composedMiddlewares(middlewares));

  sagaMiddleware.run(rootSaga);
  // Enable Webpack hot module replacement for reducers
  if ((module as any).hot) {
    // TODO : see if reducers can be moved to feature modules and still get HMR working
    (module as any).hot.accept('./rootReducer', () => {
      const nextReducer = require('./rootReducer').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};

export default initialize;
