import { REDUCER_NAME } from './constant';

const ACTION_TYPES = {
  LOGIN: `${REDUCER_NAME}/LOGIN`,
  LOGOUT: `${REDUCER_NAME}/LOGOUT`,
  CLEAR_AUTH: `${REDUCER_NAME}/CLEAR_AUTH`,
  ERROR_MESSAGE: `${REDUCER_NAME}/ERROR_MESSAGE`,
  FORGET_PASSWORD: `${REDUCER_NAME}/FORGET_PASSWORD`,
  RESET_PASSWORD: `${REDUCER_NAME}/RESET_PASSWORD`,
  SET_CHECKING_AUTH: `${REDUCER_NAME}/SET_CHECKING_AUTH`,
  CHECK_AUTH: `${REDUCER_NAME}/CHECK_AUTH`,
  ON_LOGGED_IN_SUCCESSFULLY: `${REDUCER_NAME}/ON_LOGGED_IN_SUCCESSFULLY`,
  SET_TOKEN: `${REDUCER_NAME}/SET_TOKEN`,
};

export default ACTION_TYPES;
