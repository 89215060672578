import * as React from 'react';
import { DialogTitle, Dialog, DialogContent, Typography } from '@material-ui/core';
import { SQLColumn } from 'src/shared/models/SqlColumn';

interface IProps {
  analysisModal: any;
  column: SQLColumn;
  isOpen: boolean;
  onClose: (e?: any) => any;
}

const renderAnalysisModalContent = analysisModal => {
  if (!analysisModal) return <></>;
  return (
    <>
      <Typography variant='subtitle1' gutterBottom>
        Last Update: {new Date(analysisModal.lastModifiedDate).toDateString()}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        Analysis Status: {analysisModal.analysisStatus}
      </Typography>

      <Typography variant='subtitle1' gutterBottom>
        Unique Values: {analysisModal.distinctCount}
      </Typography>
      <Typography variant='subtitle1' gutterBottom>
        Null Counts: {analysisModal.nullCount}
      </Typography>

      {analysisModal.oceanDataType === 'NUMBER' ||
      analysisModal.dbType === 'INT' ||
      analysisModal.dbType === 'FLOAT' ||
      analysisModal.dbType === 'BIGINT' ? (
        <>
          <Typography variant='subtitle1' gutterBottom />
          <Typography variant='subtitle1' gutterBottom>
            Available Values: Between {analysisModal.minValue} and {analysisModal.maxValue}
          </Typography>
          <Typography variant='subtitle1' gutterBottom />
          <Typography variant='subtitle1' gutterBottom>
            Average Value: {analysisModal.avgValue}
          </Typography>
        </>
      ) : analysisModal.oceanDataType === 'STRING' || analysisModal.dbType === 'VARCHAR' ? (
        <>
          <Typography variant='subtitle1' gutterBottom>
            Max Length for values: {analysisModal.maxTextLength}
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            Avg Length: {analysisModal.avgTextLength}
          </Typography>
        </>
      ) : analysisModal.dbType === 'DATETIME' ? (
        <>
          <Typography variant='subtitle1' gutterBottom>
            Max Date: {new Date(analysisModal.maxDate).toDateString()}
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            Min Date: {new Date(analysisModal.minDate).toDateString()}
          </Typography>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const ColumnAnalysisModal = ({ analysisModal, column, isOpen, ...props }: IProps) => (
  <Dialog open={isOpen} {...props}>
    <DialogTitle>{column.displayName} </DialogTitle>

    <DialogContent>{analysisModal ? renderAnalysisModalContent(analysisModal) : <p> No analysis for you </p>}</DialogContent>
  </Dialog>
);
