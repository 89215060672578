import { Button, Popover, Typography, withStyles, CircularProgress } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import * as React from 'react';
import ReactAutoCompleteSelect from 'react-select';
import TooltipedIconButton from '../TooltipedIconButton';
import style from './styles';
type IProps = {
  icon?: any;
  onDone: (model: { email: string }) => any;
};
class ShareWithUsersPopup extends React.Component<any> {
  state = {
    anchorEl: undefined,
    isOpen: false,
    listOfUsers: [],
    selectedUsers: [],
  };

  componentDidUpdate(prevProps) {
    const { usersList } = this.props;
    if (usersList !== prevProps.usersList) {
      this.setState({ listOfUsers: usersList });
    }
  }

  componentDidMount() {
    this.setState({ listOfUsers: this.props.usersList });
  }

  close = () => {
    this.setState({ anchorEl: undefined });
  };

  done = () => {
    let selected = this.state.selectedUsers;
    const result = selected.map((user: any) => user.value);
    this.props.onDone(result);
    this.close();
  };

  openPopup = event => {
    this.setState({ anchorEl: event.currentTarget });
    this.props.fetchSharableUsers();
  };

  handleChange = value => {
    this.setState({
      selectedUsers: value,
    });
  };

  handleSelectAll = () => {
    const { usersList } = this.props;
    const users = usersList.map((user: any) => ({ label: user.username, value: user.username }));
    this.setState({ selectedUsers: users });
  };

  render() {
    const { classes, loading, isDisabled = false, rounded = false, className } = this.props;
    const { listOfUsers, selectedUsers } = this.state;
    let el = document.body;
    return (
      <>
        <TooltipedIconButton
          aria-label='type'
          aria-owns='type-menu'
          aria-haspopup='true'
          tooltip={'Share with users'}
          disabled={isDisabled}
          rounded={rounded}
          className={className}
          onClick={this.openPopup}
        >
          {this.props.icon || <ShareIcon />}
        </TooltipedIconButton>

        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onBackdropClick={this.close}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
        >
          <div className={classes.PopoverWrapper}>
            <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
              {<CircularProgress size={14} style={{ marginRight: 5 }} />}
              <Typography variant={'title'}>Share With Users:</Typography>
            </div>
            <div>
              <ReactAutoCompleteSelect
                value={selectedUsers}
                isMulti
                name='users-multi-select'
                className={classes.FieldsStyle}
                classNamePrefix='user-select'
                autoFocus
                maxMenuHeight={200}
                closeMenuOnSelect={false}
                onChange={this.handleChange}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={listOfUsers.map((user: any) => ({ label: user.username, value: user.username }))}
              />
            </div>

            <div className={classes.BtnsWrapper}>
              <Button className={classes.btn} type='button' onClick={this.handleSelectAll}>
                All users
              </Button>

              <Button className={classes.btn} type='button' onClick={this.close}>
                Cancel
              </Button>
              <Button
                className={classes.btn}
                disabled={!selectedUsers.length}
                variant='contained'
                type='button'
                color='primary'
                onClick={this.done}
              >
                Done
              </Button>
            </div>
          </div>
        </Popover>
      </>
    );
  }
}

export default withStyles(style)(ShareWithUsersPopup);
