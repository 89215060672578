import { REQUEST, SUCCESS, FAILURE, ErrorMessage, Loader, SuccessMessage } from 'store/util';
import types from './actionTypes';
import { GetAuthoritiesDto } from 'src/shared/models/GetAuthoritiesDto';
import { GetPermissionsDto } from 'src/shared/models/GetPermissionsDto';

const initialState = {
  loading: false,
  users: [],
  user: {},
  shareableUsers: [],
  authorities: [] as GetAuthoritiesDto[],
  permissions: [] as GetPermissionsDto[],
  userPermissions: [],
  routeToUserPage: false,
};

export declare type UsersState = typeof initialState;
// Reducer
export default (state = initialState, { type, payload, ...action }): any => {
  switch (type) {
    // USER LIST
    case REQUEST(types.ROUTE_TO_USERS_PAGE):
      return {
        ...state,
        routeToUserPage: payload,
      };
    case REQUEST(types.FETCH_USERS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(types.FETCH_USERS):
      return {
        ...state,
        [ErrorMessage(types.FETCH_USERS)]: payload.message,
        loading: false,
      };
    case SUCCESS(types.FETCH_USERS): {
      return {
        ...state,
        loading: false,
        users: payload,
      };
    }
    // CREATE USER
    case REQUEST(types.CREATE):
    case REQUEST(types.CREATE_WITH_PERMISSION):
      return {
        ...state,
        [Loader(types.CREATE)]: true,
      };
    case FAILURE(types.CREATE):
    case FAILURE(types.CREATE_WITH_PERMISSION):
      return {
        ...state,
        [ErrorMessage(types.CREATE)]: payload.message,
        [Loader(types.CREATE)]: false,
      };
    case SUCCESS(types.CREATE):
    case SUCCESS(types.CREATE_WITH_PERMISSION): {
      const users: Array<any> = state.users.slice();
      users.push(payload);
      return {
        ...state,
        users,
        [SuccessMessage(types.CREATE)]: 'success',
        [Loader(types.CREATE)]: false,
      };
    }
    // UPDATE USER
    case REQUEST(types.UPDATE):
    case REQUEST(types.UPDATE_WITH_PERMISSION):
      return {
        ...state,
        [Loader(types.UPDATE)]: true,
      };
    case FAILURE(types.UPDATE):
    case FAILURE(types.UPDATE_WITH_PERMISSION):
      return {
        ...state,
        [Loader(types.UPDATE)]: false,
        [ErrorMessage(types.UPDATE)]: payload.message,
      };
    case SUCCESS(types.UPDATE):
    case REQUEST(types.UPDATE_WITH_PERMISSION): {
      const users = state.users.map((user: any) => (user.id == payload.id ? payload : user));
      return {
        ...state,
        [Loader(types.UPDATE)]: false,
        [SuccessMessage(types.UPDATE)]: 'success',
        users: users,
      };
    }
    // DELETE USER
    case REQUEST(types.DELETE):
      return {
        ...state,
        [Loader(types.DELETE)]: true,
      };
    case FAILURE(types.DELETE):
      return {
        ...state,
        [Loader(types.DELETE)]: false,
        [ErrorMessage(types.DELETE)]: payload.message,
      };
    case SUCCESS(types.DELETE): {
      const id = payload.id;
      const users = state.users.filter((user: any) => user.id !== id);
      return {
        ...state,
        users: users,
        [Loader(types.DELETE)]: false,
        [SuccessMessage(types.DELETE)]: 'Deleted',
      };
    }
    // GET USER BY ID
    case REQUEST(types.GET_USER):
      return {
        ...state,
        [Loader(types.GET_USER)]: true,
      };
    case FAILURE(types.GET_USER):
      return {
        ...state,
        [ErrorMessage(types.GET_USER)]: payload.message,
        [Loader(types.GET_USER)]: false,
      };
    case SUCCESS(types.GET_USER): {
      return {
        ...state,
        user: payload,
        [Loader(types.GET_USER)]: false,
      };
    }

    // FETCH AUTHORITIES
    case REQUEST(types.FETCH_AUTHORITIES):
      return {
        ...state,
        [Loader(types.FETCH_AUTHORITIES)]: true,
      };
    case FAILURE(types.FETCH_AUTHORITIES):
      return {
        ...state,
        [ErrorMessage(types.FETCH_AUTHORITIES)]: payload.message,
        [Loader(types.FETCH_AUTHORITIES)]: false,
      };
    case SUCCESS(types.FETCH_AUTHORITIES): {
      return {
        ...state,
        [Loader(types.FETCH_AUTHORITIES)]: false,
        authorities: payload,
      };
    }

    // SHAREABLE USERS
    case REQUEST(types.GET_SHAREABLE_USERS):
      return {
        ...state,
        [Loader(types.GET_SHAREABLE_USERS)]: true,
      };
    case FAILURE(types.GET_SHAREABLE_USERS):
      return {
        ...state,
        [ErrorMessage(types.GET_SHAREABLE_USERS)]: payload.message,
        [Loader(types.GET_SHAREABLE_USERS)]: false,
      };
    case SUCCESS(types.GET_SHAREABLE_USERS): {
      return {
        ...state,
        [Loader(types.GET_SHAREABLE_USERS)]: false,
        shareableUsers: payload,
      };
    }

    // GET PERMISSIONS
    case REQUEST(types.FETCH_PERMISSIONS):
      return {
        ...state,
        [Loader(types.FETCH_PERMISSIONS)]: true,
      };
    case FAILURE(types.FETCH_PERMISSIONS):
      return {
        ...state,
        [ErrorMessage(types.FETCH_PERMISSIONS)]: payload.message,
        [Loader(types.FETCH_PERMISSIONS)]: false,
      };
    case SUCCESS(types.FETCH_PERMISSIONS): {
      return {
        ...state,
        permissions: payload,
        [Loader(types.FETCH_PERMISSIONS)]: false,
      };
    }
    // GRANT PERMISSIONS
    case REQUEST(types.GRANT_PERMISSIONS):
      return {
        ...state,
        [Loader(types.GRANT_PERMISSIONS)]: true,
      };
    case FAILURE(types.GRANT_PERMISSIONS):
      return {
        ...state,
        [ErrorMessage(types.GRANT_PERMISSIONS)]: payload.message,
        [Loader(types.GRANT_PERMISSIONS)]: false,
      };
    case SUCCESS(types.GRANT_PERMISSIONS): {
      return {
        ...state,
        grant: payload,
        [Loader(types.GRANT_PERMISSIONS)]: false,
      };
    } // GRANT PERMISSIONS
    case REQUEST(types.UPDATE_GRANT_PERMISSIONS):
      return {
        ...state,
        [Loader(types.UPDATE_GRANT_PERMISSIONS)]: true,
      };
    case FAILURE(types.UPDATE_GRANT_PERMISSIONS):
      return {
        ...state,
        [ErrorMessage(types.UPDATE_GRANT_PERMISSIONS)]: payload.message,
        [Loader(types.UPDATE_GRANT_PERMISSIONS)]: false,
      };
    case SUCCESS(types.UPDATE_GRANT_PERMISSIONS): {
      return {
        ...state,
        grant: payload,
        [Loader(types.UPDATE_GRANT_PERMISSIONS)]: false,
      };
    }
    // USER PERMISSIONS
    case REQUEST(types.USER_PERMISSIONS):
      return {
        ...state,
        [Loader(types.USER_PERMISSIONS)]: true,
      };
    case FAILURE(types.USER_PERMISSIONS):
      return {
        ...state,
        [ErrorMessage(types.USER_PERMISSIONS)]: payload.message,
        [Loader(types.USER_PERMISSIONS)]: false,
      };
    case SUCCESS(types.USER_PERMISSIONS): {
      return {
        ...state,
        userPermissions: payload,
        [Loader(types.USER_PERMISSIONS)]: false,
      };
    }

    default:
      return state;
  }
};
