import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import TextField from '@material-ui/core/TextField/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import style from './style';
import { IProps, BasicUserFormData } from './models';
import { InputAdornment } from '@material-ui/core';
import { EMAIL_REGEX } from 'src/store/authentication/constant';

const checkFormValidation = () => {};

const UserForm = ({ onChange, authorities, authLoader, userLoader, classes, isEdit = false, user }: IProps) => {
  const [showPasswordField] = React.useState(!isEdit);
  const [form, setForm] = React.useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    authorities: '',
  } as BasicUserFormData);

  const [formError, setFormError] = React.useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    authorities: '',
  });

  React.useEffect(
    () => {
      if (isEdit) {
        const cloned: BasicUserFormData = {
          id: user.id || '',
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email || '',
          password: user.password || '',
          username: user.username || '',
          authorities: (user && user.realmRoles && user.realmRoles[0] && user.realmRoles[0].name) || '',
        };
        onChange(cloned);
        setForm(cloned);
      }
    },
    [user]
  );

  const passwordHint = 'If password field blank the password will not change';

  const checkFormValidation = (field, value): Boolean => {
    if (field == 'email') {
      if (!EMAIL_REGEX.test(value)) {
        setFormError({ ...formError, [field]: 'Please enter valid email' });
      } else {
        setFormError({ ...formError, [field]: '' });
      }
    }
    return true;
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    checkFormValidation(name, value);

    const clone = { ...form, [name]: value };
    setForm(clone);
    onChange(clone);
  }
  return (
    <React.Fragment>
      <form>
        <TextField
          label='First Name'
          name='firstName'
          onChange={handleChange}
          value={form.firstName}
          className={classes.textField}
          margin='normal'
          variant='outlined'
          InputProps={{
            endAdornment: userLoader && (
              <InputAdornment position='end'>
                <CircularProgress size={20} color='primary' />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label='Last Name'
          name='lastName'
          value={form.lastName}
          className={classes.textField}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
          InputProps={{
            endAdornment: userLoader && (
              <InputAdornment position='end'>
                <CircularProgress size={20} color='primary' />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          name='username'
          label='Username'
          value={form.username}
          className={classes.textField}
          disabled={isEdit}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
          InputProps={{
            endAdornment: userLoader && (
              <InputAdornment position='end'>
                <CircularProgress size={20} color='primary' />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label='Email'
          name='email'
          type='email'
          value={form.email}
          className={classes.textField}
          onChange={handleChange}
          margin='normal'
          variant='outlined'
          InputProps={{
            endAdornment: userLoader && (
              <InputAdornment position='end'>
                <CircularProgress size={20} color='primary' />
              </InputAdornment>
            ),
          }}
          helperText={formError.email}
          error={formError.email !== ''}
        />

        {
          <TextField
            id='password'
            label='Password'
            name='password'
            type='password'
            autoComplete='new-password'
            helperText={isEdit ? passwordHint : ''}
            value={form.password}
            className={classes.textField}
            onChange={handleChange}
            margin='normal'
            variant='outlined'
          />
        }
        <TextField
          id='authorities'
          label='User Roles'
          name='authorities'
          select
          // fullWidth
          value={form.authorities}
          className={classes.textField}
          onChange={handleChange}
          disabled={authLoader}
          helperText={authLoader ? 'loading... ' : ''}
          margin='normal'
          variant='outlined'
          InputProps={{
            endAdornment: authLoader && (
              <InputAdornment position='end'>
                <CircularProgress size={20} color='primary' />
              </InputAdornment>
            ),
          }}
        >
          {authorities.map((auth: any) => (
            <MenuItem key={auth.id} value={auth.name}>
              {auth.name}
            </MenuItem>
          ))}
        </TextField>
      </form>
    </React.Fragment>
  );
};

const styled = withStyles(style)(UserForm);
export default styled;
