import React from 'react';
import { Popover } from '@material-ui/core';

export const Popup = ({ anchorEl, handleClose, children }) => {
  return (
    <Popover
      className='add-questions-popover'
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </Popover>
  );
};
