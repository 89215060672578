import { withStyles, StyleRulesCallback, Theme, createStyles } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flex: '1 1 auto',
    },
    row: {
      marginBottom: 10,
      marginTop: 10,
    },
    wrapper: {
      border: '1px solid #10ab5b',
      width: '100%',
      margin: '5px 0',
      borderRadius: '5px',
    },
    heading: {
      display: 'flex',
      padding: 5,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    editorWrapping: {
      borderTop: '1px solid  #10ab5b',
      padding: '10px 15px',
    },
    wizardButtonWrap: {
      padding: 0,
      display: 'flex',
      flex: '1 1 100%',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    expandButton: {
      margin: 'auto',
      marginRight: 0,
    },
  });

export default withStyles(styles);
