import React from 'react';
import AuthGrid from '../grid/AuthGrid';
import { Card, CardContent } from '@material-ui/core';
import Form from './Form';
import Styled from './style';

class ForgetPassword extends React.Component<any> {
  componentDidUpdate(nextProps) {
    const { success, history } = this.props;
    if (nextProps.success !== success) {
      history.push('/login');
    }
  }
  handleSubmit = username => {
    const { forgetPassword } = this.props;
    forgetPassword(username);
  };
  render() {
    const { classes, loading } = this.props;
    return (
      <AuthGrid>
        <Form loading={loading} onSubmit={this.handleSubmit} />
        {/* <Card elevation={5} className={classes.card}>
          <CardContent>
          </CardContent>
        </Card> */}
      </AuthGrid>
    );
  }
}
const styled = Styled(ForgetPassword);
export default styled;
