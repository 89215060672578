import { IRootState } from 'src/store/rootReducer';
import { connect } from 'react-redux';
import AddOiViewWizard from './AddOiViewWizard';
export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = typeof mapDispatchToProps;
const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOiViewWizard);
