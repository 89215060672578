import * as React from 'react';
import { Card } from '@material-ui/core';
import moment from 'moment';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SimpleTable from 'components/simple-table';
import { ISimpleTableColumn } from 'components/simple-table/models';
import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import { VIEW_TYPE } from 'src/components/QuestionPreviewer/questionViewTypes';
import questionAccess from 'src/services/permissions/abilities/questions';
import { canDo } from 'src/services/permissions/utils';
import toastr from 'src/components/toastr';
import { ToastMessages } from 'src/constants';
import apisService from 'src/shared/apis';
import QuestionsFavouriteBarContainer from './favouriteBar';
import { IProps, IQuestionListState } from './models';
import { RowActionsCell } from './RowActionsCell';
import QuestionListToolbar from './QuestionListToolbar';
import { PreviewQuestionModal } from './PreviewQuestionModal';

class QuestionsList extends React.Component<IProps, IQuestionListState> {
  questionToOpenAfterLoad = null;
  state: IQuestionListState = {
    // isLoadingQuestions: true,
    showFavList: false,
    showPreviewModal: false,
    questionForPreview: undefined,
    search: '',
    questionsList: [],
    selected: [],
    // favQuestions
  };
  columns: ISimpleTableColumn[] = [
    { field: 'name', title: 'Name' },
    {
      field: 'createdDate',
      title: 'Creation Date',
      cellRenderer: (v, row, column) => moment(v, moment.defaultFormat).format('MMM DD, YYYY'),
    },
    {
      field: 'lastModifiedDate',
      title: 'Modified Date',
      cellRenderer: (v, row, column) => moment(v, moment.defaultFormat).format('MMM DD, YYYY'),
    },
    { field: 'datasource', title: 'Database' },
    {
      field: 'tag',
      title: 'Tags',
      cellRenderer: v => v || '',
    },
    { field: 'description', title: 'Description' },
    {
      field: 'id',
      title: 'actions',
      width: 160,
      cellRenderer: (id, question, column) => (
        <RowActionsCell
          column={column}
          question={question}
          id={id}
          onDelete={this.deleteQuestion(id)}
          onEdit={this.editQuestion(id)}
          onToggleFavStatus={e => {
            e.stopPropagation();
            this.toggleQuestionFav(question);
            this.setState({});
          }}
        />
      ),
    },
  ];

  isFavoriteQuestions = id => {
    return !!(this.props.favQuestions || []).find(q => q.id == id);
  };

  componentWillMount() {
    let params: any = location.search
      .substring(1)
      .split('&')
      .reduce((res, s) => {
        let sp = s.split('=');
        return { ...res, [sp[0]]: decodeURIComponent(sp[1]) };
      }, {});
    if (params.openQuestion) {
      this.questionToOpenAfterLoad = params.openQuestion;
    }
    this.props.getAllQuestions();
    this.props.getFavoriteQuestions();
    this.setState({
      questionsList: this.props.questionsList,
    });
  }

  componentDidUpdate(prevProps) {
    const {} = this.props;
    if (prevProps.isLoading && !this.props.isLoading && this.questionToOpenAfterLoad) {
      let qId = this.questionToOpenAfterLoad;
      this.openQuestionPreview(qId);
      this.questionToOpenAfterLoad = null;
    }
    if (
      (!prevProps.favQuestions || !prevProps.favQuestions.length) &&
      (this.props.favQuestions && this.props.favQuestions.length) &&
      !this.state.showFavList
    ) {
      this.setState({ showFavList: true });
    } else if (
      prevProps.favQuestions &&
      prevProps.favQuestions.length &&
      (!this.props.favQuestions || this.props.favQuestions.length == 0) &&
      this.state.showFavList
    ) {
      this.setState({ showFavList: false });
    }
    if (
      this.props.questionsList.length !== this.state.questionsList.length ||
      prevProps.favQuestions.length !== this.props.favQuestions.length
    ) {
      this.updateQuestionsOrdering();
    }
  }

  editQuestion = id => e => {
    e && e.stopPropagation();
    (this.props as any).history.push('/questions/edit/' + id);
    return false;
  };

  deleteQuestion = id => async e => {
    e && e.stopPropagation();
    const confirm = this.props.confirm;
    const deleteAbleResponse = await apisService.canDeleteQuestion(id);
    let confirmMessage = `do you want to delete this question ?`;
    confirmMessage = deleteAbleResponse.deletable ? confirmMessage : deleteAbleResponse.cause + ', ' + confirmMessage;
    confirm({
      msg: confirmMessage,
      ok: _ => {
        this.props.deleteQuestion(id);
        this.props.getFavoriteQuestions();
      },
    });
    return false;
  };

  toggleQuestionFav = question => {
    if (this.isFavoriteQuestions(question.id)) {
      apisService.removeQuestionFromFav(question.id).then(q => this.props.getFavoriteQuestions());
    } else {
      apisService.addQuestionToFav(question.id).then(q => this.props.getFavoriteQuestions());
    }
    this.setState({});
  };

  openQuestionPreview = qId => {
    if (!canDo(this.props.userPermissions, this.props.userRoles, questionAccess.view)) {
      toastr.warn(ToastMessages.unAuthorized);
      return;
    }
    const question = this.props.questionsList.find(q => q.id == qId);
    this.setState({ questionForPreview: question, showPreviewModal: true });
  };

  openAddQuestionForm = e => {
    (this.props as any).history.push('/questions/new');
  };

  handleSearch = e => {
    const keyWord = e.target.value || '';
    // let _data = this.props.questionsList.slice().filter(value => (value.name || '').toLowerCase().includes(keyWord));
    this.setState({ search: e.target.value });
  };

  private updateQuestionsOrdering() {
    let questionsList = this.props.questionsList.reduce(
      (acc, q) => {
        let question = {
          ...q,
          isFav: this.isFavoriteQuestions(q.id),
        };
        if (question.isFav) {
          acc.fav.push(question);
        } else {
          acc.notFav.push(question);
        }
        return acc;
      },
      { fav: [], notFav: [] }
    );
    this.setState({
      questionsList: [...questionsList.fav, ...questionsList.notFav],
    });
  }

  handleSelection = selected => {
    this.setState({ selected });
  };

  handleDeleteSelected = () => {
    const { selected, questionsList } = this.state;
    const confirm = this.props.confirm;
    const dashboardsNames = selected.map(id => questionsList.find(d => d.id == id).name).join('\n');
    confirm({
      msg: `Are you sure you want to delete these questions ?\n\n` + dashboardsNames,
      ok: _ => {
        selected.forEach(element => {
          this.props.deleteQuestion(parseInt(element));
        });
      },
    });
  };

  handleShare = users => {
    const { selected } = this.state;
    selected.map(element => parseInt(element));
    apisService.shareQuestionsWithUsers({ questions: selected, users }).then(
      success => {
        toastr.success('Question Shared Successfully');
      },
      error => {
        toastr.error('Failed to share question');
        console.error(error);
      }
    );
  };

  render() {
    const { selected } = this.state;
    let showFavList = this.state.showFavList;
    const questionForPreview = this.state.questionForPreview || {};
    const viewType: VIEW_TYPE = questionForPreview.defaultView;
    const keyWord = this.state.search.toLowerCase();
    let _data = keyWord
      ? this.state.questionsList.filter(q => (q.name + '**' + q.description + '**' + q.tag).toLowerCase().includes(keyWord))
      : this.state.questionsList;
    return (
      <>
        <BreadCrumb
          path={
            <>
              Home <KeyboardArrowRight /> Questions <div className='flex' />{' '}
            </>
          }
        />
        <ContentPage>
          {showFavList && (
            <QuestionsFavouriteBarContainer
              onPlay={this.openQuestionPreview}
              onDelete={id => this.deleteQuestion(id)(null)}
              onEdit={id => this.editQuestion(id)(null)}
              onUnfavourite={this.toggleQuestionFav}
            />
          )}
          <Card>
            <QuestionListToolbar
              favQuestions={this.props.favQuestions}
              showFavList={Boolean(showFavList)}
              search={this.state.search}
              handleSearch={this.handleSearch}
              handleDeleteSelected={this.handleDeleteSelected}
              openAddQuestionForm={this.openAddQuestionForm}
              selectedQuestions={selected}
              toggleShowFav={e => this.setState({ showFavList: !showFavList })}
              handleShare={this.handleShare}
            />
            <SimpleTable
              columns={this.columns}
              data={_data}
              showLoading={this.props.isLoading}
              onRowClick={e => this.openQuestionPreview(e.row.id)}
              selectable
              onSelect={this.handleSelection}
            />
            <PreviewQuestionModal
              onCloseModal={e =>
                this.setState({
                  showPreviewModal: false,
                })
              }
              showPreviewModal={this.state.showPreviewModal}
              questionForPreview={questionForPreview}
              viewType={viewType}
            />
          </Card>
        </ContentPage>
      </>
    );
  }
}
export default QuestionsList;
