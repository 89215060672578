import { connect } from 'react-redux';
import { permissionsGroup, userPermissions, getLoader } from 'src/store/users/selectors';
import Permissions from './Permissions';
import types from 'src/store/users/actionTypes';

const mapStateToProps = state => {
  return {
    permissions: permissionsGroup(state),
    userPermissions: userPermissions(state),
    permissionsLoader: getLoader(state, types.FETCH_PERMISSIONS),
    userPermissionsLoader: getLoader(state, types.USER_PERMISSIONS),
  };
};

const connected = connect(
  mapStateToProps,
  null
)(Permissions);
export default connected;
