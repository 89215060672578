import React from 'react';
import Styled from './AuthFooterStyle';

function LoginFooter(props) {
  const { classes } = props;
  return (
    <div className={classes.loginFooterWrapper}>
      <div className={classes.loginFooterTxt}>{`Copyright © 2018 IQVIA. All Rights Reserved. Terms & Conditions`}</div>
      <div className={classes.IqviaLogo} />
    </div>
  );
}

export default Styled(LoginFooter);
