import { connect } from 'react-redux';
import { IRootState } from 'src/store/rootReducer';
import QuestionsFavouriteBar from './Favourite';
import { getFavoriteQuestions, getAllQuestions } from 'src/store/questions/questions.actions';

// export default QuestionsList;
const mapStateToProps = ({ questions }: IRootState) => ({
  favQuestions: questions.favoriteQuestions,
  questionsList: questions.questionsList,
  isLoading: questions.loadingFav,
});

const mapDispatchToProps = () => {
  return {
    getFavoriteQuestions,
    getAllQuestions,
  };
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionsFavouriteBar);

export default connected;
