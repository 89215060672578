import * as React from 'react';

import { Typography } from '@material-ui/core';
import Line from 'components/Charts/Line';
import Bar from 'components/Charts/Bar';
import { ChartCustomizationOptions } from 'src/components/QuestionPreviewer/models';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import { PieChartClickEvent } from 'src/shared/models/EchartsClickEvents';
import HorizontalBar from './HorizontalBar';
import PieChart from './PieChart';

interface IProps {
  type: string;
  data: any;
  meta: any;
  currentGroup?: ColumnMetadatum;
  setChartRef: (e) => any;
  myRef?: (e: { resize: () => any }) => void;
  customization: ChartCustomizationOptions;
  onSegmentClicked: (e: PieChartClickEvent) => any;
}
export const ChartsComponent: React.StatelessComponent<IProps> = props => {
  const { type, data, customization, setChartRef, onSegmentClicked, currentGroup, myRef, meta } = props;
  switch (type) {
    case 'line':
    case 'area':
      return <Line setChartRef={setChartRef} data={data} customize={customization} currentGroup={currentGroup} myRef={myRef} meta={meta} />;
    case 'bar':
      return (
        <Bar
          setChartRef={setChartRef}
          data={data}
          customize={customization}
          myRef={myRef}
          meta={meta}
          currentGroup={currentGroup}
          onSegmentClicked={onSegmentClicked}
        />
      );
    case 'hbar':
      return (
        <HorizontalBar
          setChartRef={setChartRef}
          data={data}
          customize={customization}
          myRef={myRef}
          meta={meta}
          currentGroup={currentGroup}
          onSegmentClicked={onSegmentClicked}
        />
      );
    case 'pie':
      return (
        <PieChart
          setChartRef={setChartRef}
          data={data}
          customize={customization}
          myRef={myRef}
          meta={meta}
          currentGroup={currentGroup}
          onSegmentClicked={onSegmentClicked}
        />
      );
    default:
      return <Typography>Type error</Typography>;
  }
};
