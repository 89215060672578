import * as React from 'react';
import { FormControl, Input, Typography } from '@material-ui/core';
import OiIconButton from 'src/components/OiIconButton';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { KEYCODES } from 'src/shared/util/keyboard';

const StudioTab = props => {
  const { tab, isDeleteDisabled, isViewMode, onChangeTabName, onEditable, onDeleteTab } = props;
  return (
    <div className='tabs-title-content'>
      {tab.editable ? (
        <FormControl className='title-input'>
          <Input
            id='tab-input'
            className={!tab.editable ? 'tabs-input ' : ' editable-input'}
            value={tab.name}
            disableUnderline
            disabled={!tab.editable}
            onKeyDown={e => e.keyCode == KEYCODES.ENTER && onEditable()}
            onChange={e => onChangeTabName(e)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        </FormControl>
      ) : (
        <Typography variant={'body1'}> {tab.name} </Typography>
      )}
      {!isViewMode && (
        <span className='title-btns'>
          <OiIconButton
            aria-label='edit'
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              onEditable();
            }}
          >
            <Edit />
          </OiIconButton>
          <OiIconButton
            aria-label='delete'
            disabled={isDeleteDisabled}
            className={isDeleteDisabled ? 'btn-disabled' : ''}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              onDeleteTab();
            }}
          >
            <Delete />
          </OiIconButton>
        </span>
      )}
    </div>
  );
};
export default StudioTab;
