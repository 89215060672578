import { TextField, InputAdornment } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React from 'react';
import OiIconButton from 'src/components/OiIconButton';
import { QUESTION_MAX_LIMIT } from 'src/modules/questions/questions.services';
import { KEYCODES } from 'src/shared/util/keyboard';

const LimitTextInput = ({ onChange, limit, onOk }) => (
  <TextField
    type='number'
    label='Limit:'
    value={limit}
    onChange={e => {
      let v = parseInt(e.target.value);
      if (v > 1000001) {
        return false;
      }

      onChange(v);
    }}
    onClick={e => e.stopPropagation()}
    onKeyDown={e => {
      if (e.keyCode == KEYCODES.ENTER) {
        onOk(limit);
      }
    }}
    inputProps={{
      type: 'number',
      min: 0,
      max: 1000000,
      maxLength: 7,
    }}
    InputProps={{
      'aria-valuemax': 1000000,
      'aria-valuemin': 0,
      endAdornment: (
        <InputAdornment position='end'>
          <OiIconButton
            onClick={e => {
              onOk(limit);
            }}
          >
            <SaveIcon />
          </OiIconButton>
        </InputAdornment>
      ),
    }}
  />
);
export default LimitTextInput;
