import React, { useState } from 'react';
import CanDo from 'src/components/Permissions/Can';
import databaseAccess from 'src/services/permissions/abilities/database';
import OiIcon from 'src/assets/icons';
import Edit from '@material-ui/icons/Edit';
import Refresh from '@material-ui/icons/Refresh';
import RefreshIcon from '@material-ui/icons/Refresh';
import Delete from '@material-ui/icons/Delete';
import ShareIcon from '@material-ui/icons/Share';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import discoveryApis from 'src/shared/apis/discoveryApis';
import toastr from 'src/components/toastr';
import CircularProgress from '@material-ui/core/CircularProgress';

function ActionButtons(props) {
  const { onEdit, onDelete, onDataModel, onShareDatabase, id } = props;
  const [isRefreshingDatabase, setIsRefreshingDatabase] = useState(false);

  function handleRefresh(e) {
    e && e.stopPropagation();
    setIsRefreshingDatabase(true);
    discoveryApis
      .refreshDatabaseApi(id)
      .then(response => {
        toastr.success('Refresh database successful');
        setIsRefreshingDatabase(false);
      })
      .catch(err => {
        toastr.error(`Can't refresh database, ${err}`);
        console.error(err);
        setIsRefreshingDatabase(false);
      });
  }

  return (
    <span onClick={e => e.stopPropagation()}>
      <CanDo action={databaseAccess.edit}>
        <TooltipedIconButton
          tooltip={'Refresh database analysis'}
          aria-label='Refresh'
          onClick={handleRefresh}
          disabled={isRefreshingDatabase}
        >
          {isRefreshingDatabase ? <CircularProgress size={20} color='secondary' /> : <RefreshIcon />}
        </TooltipedIconButton>
      </CanDo>

      <CanDo action={databaseAccess.share}>
        <TooltipedIconButton
          tooltip={'Open share database modal'}
          aria-label='data-model'
          onClick={e => {
            e && e.stopPropagation();
            onShareDatabase();
          }}
        >
          <ShareIcon scale={18} />
        </TooltipedIconButton>
      </CanDo>

      <CanDo action={databaseAccess.view}>
        <TooltipedIconButton
          tooltip={'Open Database Details'}
          aria-label='data-model'
          onClick={e => {
            e && e.stopPropagation();
            onDataModel();
          }}
        >
          <OiIcon scale={18} path={'DataModel'} />
        </TooltipedIconButton>
      </CanDo>

      <CanDo action={databaseAccess.edit}>
        <TooltipedIconButton
          tooltip={'Edit database connection'}
          aria-label='Edit'
          onClick={e => {
            e && e.stopPropagation();
            onEdit();
          }}
        >
          <Edit />
        </TooltipedIconButton>
      </CanDo>

      <CanDo action={databaseAccess.delete}>
        <TooltipedIconButton
          tooltip={'Delete database'}
          aria-label='Delete'
          onClick={e => {
            e && e.stopPropagation();
            onDelete();
          }}
        >
          <Delete />
        </TooltipedIconButton>
      </CanDo>
    </span>
  );
}
export default ActionButtons;
