import * as React from 'react';
import { IPreviewResult } from 'src/shared/models/IPreviewResult';
import { withStyles, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import DynamicNumberFormatter, { NumberFormatter } from 'src/components/QuestionPreviewer/DynamicNumberFormatter';

const defaultBackgroundColor = '#048edd';
interface INumberViewProps {
  previewResult?: IPreviewResult;
  value: number | string;
  displayName: string;
  color: string;
  classes?: any;
}

const NumberView: React.StatelessComponent<INumberViewProps> = ({ previewResult, classes, displayName, value, color, ...props }) => {
  return (
    <div
      className={classNames(classes.root, 'kpi-chart-bg')}
      style={{
        backgroundColor: (Array.isArray(color) ? color[0] : color) || defaultBackgroundColor,
      }}
    >
      {/* <Card> */}
      <h4 className={classes.h4}> {displayName}</h4>
      <Tooltip title={NumberFormatter.format(value as number)} style={{ fontSize: 18 }} classes={{ tooltip: classes.toolTip }}>
        <h1 className={classes.h1} style={{ width: '100%' }}>
          <DynamicNumberFormatter value={value as any} />
        </h1>
      </Tooltip>
      {/* </Card> */}
    </div>
  );
};

export default withStyles({
  root: {
    height: '100%',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center',
    alignSelf: 'center',
    alignContent: 'center',
  },
  h1: {
    margin: 0,
    fontSize: 50,
    fontWeight: 500,
    color: 'white',
    flex: '0 0 auto',
    fontFamily: `'Montserrat', sans-serif`,
  },
  h4: {
    flex: '0 0 auto',
    fontWeight: 500,
    fontSize: 16,
    margin: 10,
    color: 'white',
  },
  toolTip: {
    fontSize: 18,
  },
})(NumberView);
