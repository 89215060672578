import React from 'react';

import Popover from '@material-ui/core/Popover/Popover';

interface IProps {
  el: any;
  onClose: any;
  children: any;
}
const FilterPopover = (props: IProps) => {
  const { el, onClose, children } = props;
  return (
    <Popover
      className=''
      open={Boolean(el)}
      anchorEl={el}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {children}
    </Popover>
  );
};

export default FilterPopover;
