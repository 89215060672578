import { Button, CircularProgress, DialogActions, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import PrimaryColumnSelect from 'src/components/PrimaryColumnSelect/PrimaryColumnSelect';
import TitledAutoComplete from 'src/components/TitledAutoComplete';
import { GDAFilter } from 'src/shared/models/GdaFilterDto';
import { SQLDatabase } from 'src/shared/models/SqlDatabase';

interface IOptions<T> {
  isLoading: boolean;
  datasource: Array<T | any>;
  onChanged: (v) => any;
  value: T | undefined;
}
interface IProps {
  onCancel: (d: any) => any;
  onSave: (d: any) => any;
  usersOptions: IOptions<any>;
  databasesOptions: IOptions<SQLDatabase>;
  columnsOptions: IOptions<any>;
  columnInitalValue: string;
  isSavingForm: boolean;
  id?: number;
}
export default function GDAFilterForm(props: IProps) {
  const { onCancel, isSavingForm, columnInitalValue, id } = props;
  const { usersOptions, databasesOptions, columnsOptions } = props;
  const [columnValue, setColumValue]: [any, any] = useState(columnInitalValue);
  const selectedTable = columnsOptions.value;

  const onSave = () => {
    const column = columnsOptions.datasource.find(c => c.id == columnsOptions.value);
    const gdaFilter: GDAFilter = {
      id: id,
      columnName: column ? column.dbName : '',
      tableName: column ? column.dbTableName : '',
      databaseName: databasesOptions.value!.dbName,
      columnValue,
      username: usersOptions.value.username,
    };
    props.onSave(gdaFilter);
  };

  const isValid = () => {
    return selectedTable && databasesOptions.value && usersOptions.value;
  };

  return (
    <div>
      <TitledAutoComplete
        isLoadingOptions={usersOptions.isLoading}
        isCreatable={false}
        label={'User'}
        onChange={usersOptions.onChanged}
        value={usersOptions.value}
        options={usersOptions.datasource}
        name='User'
      />
      <TitledAutoComplete
        isLoadingOptions={databasesOptions.isLoading}
        isCreatable={false}
        label={'Databases'}
        onChange={databasesOptions.onChanged}
        value={databasesOptions.value}
        options={databasesOptions.datasource}
        name='Databases'
      />
      <div style={{ marginBottom: 10 }}>
        <PrimaryColumnSelect
          onChange={columnsOptions.onChanged}
          selectedColId={columnsOptions.value}
          columnsList={columnsOptions.datasource}
        />
      </div>

      <TextField
        autoFocus
        variant={'outlined'}
        name='Value'
        label={`Value`}
        fullWidth
        value={columnValue}
        onChange={e => setColumValue(e.target.value)}
      />
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={onSave} color='primary' disabled={isSavingForm || !isValid()}>
          {isSavingForm ? <CircularProgress size={14} /> : null} Save
        </Button>
      </DialogActions>
    </div>
  );
}
