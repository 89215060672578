import { takeLatest, all } from 'redux-saga/effects';
import { questionStudioSagas } from 'store/questionStudio/questionStudioSagas';
import questionsSaga from './questions/questions.saga';
import usersSaga from './users/saga';
import authenticationSagas from './authentication/sagas';
import dashboardsSagas from './dashboards/sagas';
import databaseSagas from './databases/sagas';
import columnsMapSagas from './columnsMap/sagas';
import accountSaga from './account/sagas';

// import API from '../Services/Api'
// import FixtureAPI from '../Services/FixtureApi'
// import DebugConfig from '../Config/DebugConfig'

/* ------------- Connect Types To Sagas ------------- */

export default function* rootSaga() {
  yield all([
    ...questionStudioSagas,
    ...questionsSaga,
    ...usersSaga,
    ...authenticationSagas,
    ...dashboardsSagas,
    ...databaseSagas,
    ...columnsMapSagas,
    ...accountSaga,
    /*// some sagas only receive an action

    // some sagas receive extra parameters in addition to an action
    takeLatest(GithubTypes.USER_REQUEST, getUserAvatar, api)*/
  ]);
}
