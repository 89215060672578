export const AclModules = {
  databases: 'database',
  questions: 'question',
  dashboards: 'dashboard',
  users: 'user',
  tasks: 'task',
  downloads: 'download',
};
export default {
  DATABASE: {
    WRITE: 'database.write_access',
    DELETE: 'database.delete',
    VIEW: 'database.view',
    SHARE: 'database.share',
  },
  USER: {
    ACTIVATE: 'user.activate',
    WRITE: 'user.write_access',
    DELETE: 'user.delete',
    VIEW: 'user.view',
  },
  QUESTION: {
    WRITE: 'question.write_access',
    DELETE: 'question.delete',
    EXPORT: 'question.export_data',
    SCHEDULE: 'question.schedule',
    SEND_AS_EMAIL: 'question.send_as_email',
    SHARE: 'question.share',
    VIEW: 'question.view',
  },
  DASHBOARD: {
    WRITE: 'dashboard.write_access',
    DELETE: 'dashboard.delete',
    EXPORT: 'dashboard.export_data',
    SHARE: 'dashboard.share',
    VIEW: 'dashboard.view',
  },
  TASK: {
    WRITE: 'task.write_access',
    DELETE: 'task.delete',
    VIEW: 'task.view',
  },
  DOWNLOAD: {
    DELETE: 'download.delete',
    VIEW: 'download.view',
  },
};
