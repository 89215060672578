import * as React from 'react';
import { Route, Redirect } from 'react-router';
import { IProps } from './models';
import CheckingAuthLoader from 'src/modules/auth/CheckingAuthLoader';
const PrivateRoute = (props: IProps) => {
  const { component: Component, isCheckingAuth, isAuthenticated, checkLogin, ...rest } = props;
  React.useEffect(() => {
    checkLogin();
  }, []);
  if (isCheckingAuth) {
    return <Route {...rest} render={props => <CheckingAuthLoader {...props} />} />;
  } else if (isAuthenticated) {
    return <Route {...rest} {...props} render={props => Component && <Component {...props} {...rest} />} />;
  } else {
    return <Route {...rest} {...props} render={props => <Redirect to='/login' />} />;
  }
};
export default PrivateRoute;
