import { Lexer, extendToken } from 'chevrotain';

import {
  allTokens,
  LParen,
  RParen,
  AdditiveOperator,
  MultiplicativeOperator,
  Aggregation,
  NullaryAggregation,
  UnaryAggregation,
  StringLiteral,
  NumberLiteral,
  Minus,
  Identifier,
  ColumnName,
  AggregationWithColumnName,
} from './tokens';
import { columnNamePattern, aggWithColPattern, aggsPattern, lParPattern, rParPattern } from './tokens';
import { SQLTable } from 'src/shared/models/SqlTable';
import { ExpressionItem } from 'src/shared/models/OiQueryColumn';
export function findClosingBracketMatchIndex(str, pos) {
  if (str[pos] != '(') {
    throw new Error("No '(' at index " + pos);
  }
  let depth = 1;
  for (let i = pos + 1; i < str.length; i++) {
    switch (str[i]) {
      case '(':
        depth++;
        break;
      case ')':
        if (--depth == 0) {
          return i;
        }
        break;
    }
  }
  return -1; // No matching closing parenthesis
}
export function findClosingBracketTokenMatchIndex(tokens, openingBracketPosition) {
  if (!(tokens[openingBracketPosition] instanceof LParen)) {
    throw new Error("No '(' at index " + openingBracketPosition);
  }
  let depth = 1;
  for (let i = openingBracketPosition + 1; i < tokens.length; i++) {
    let token = tokens[i];
    if (token instanceof LParen) {
      depth++;
    } else if (token instanceof RParen) {
      if (--depth == 0) {
        return i;
      }
    }
  }
  return -1; // No matching closing parenthesis
}

const expressionReducer = (acc: Array<ExpressionItem>, token, tokenIndex, tokens) => {
  let value = token.image;
  if (token instanceof AggregationWithColumnName) {
    let matches = value.match(new RegExp(aggWithColPattern, 'i'))!;
    matches.groups;
    let agg = matches[1].toLowerCase();
    let col = matches[2];
    acc.push({ t: 'col', value: { dbColumn: col, function: agg } });
  } else if (token instanceof ColumnName) {
    acc.push({ t: 'col', value: { dbColumn: value, function: 'NONE' } as any });
  } else if (token instanceof AdditiveOperator || token instanceof MultiplicativeOperator) {
    acc.push({ t: 'op', value: value as any });
  } else if (token instanceof NumberLiteral) {
    acc.push({ t: 'value', value: value as any });
  } else if (token instanceof LParen) {
    let closingParenIndex = findClosingBracketTokenMatchIndex(tokens, tokenIndex);
    if (closingParenIndex > -1) {
      let nestedExpressionTokens = tokens.splice(tokenIndex + 1, closingParenIndex - tokenIndex);
      nestedExpressionTokens.pop(); // pop the ) closing Paren
      let expArr = nestedExpressionTokens.reduce(expressionReducer, []);

      acc.push({ t: 'group', value: expArr });
    } else {
      acc.push({ t: 'err', value: value, errMsg: 'missing closing Paren ")"!' });
      // throw ''expecting ) closing Paren!' ;
    }
  } else if (token instanceof RParen) {
    acc.push({ t: 'err', value: value, errMsg: 'unexpected ) closing Paren!' });
    // throw 'unexpected ) closing Paren!';
  } else {
    acc.push({ t: 'err', value: value, errMsg: 'unrecognized token' });
  }
  return acc;
};

export function computedColumnExpressionParser(source, tables: SQLTable[]) {
  const ComputedColumnLexer = new Lexer(allTokens);
  let lexed = ComputedColumnLexer.tokenize(source);
  let sourceTokens = lexed.tokens;
  const finalResult = sourceTokens.reduce(expressionReducer, []);
  return finalResult;
}
