import { withStyles, StyleRulesCallback, Theme } from '@material-ui/core';
import LoginBackgroundImage from 'src/assets/registration/background.png';

const styles: StyleRulesCallback = (theme: Theme) => ({
  loginContainer: {
    height: '100%',
    width: '100%',
    '& input:-webkit-autofill,& textarea:-webkit-autofill,& select:-webkit-autofill ': {
      backgroundColor: 'transparent !important',
      boxShadow: '0 0 0px 1000px white inset',
      WebkitBoxShadow: '0 0 0px 1000px white inset',
    },
    backgroundImage: `url(${LoginBackgroundImage})`,
    backgroundColor: theme.palette.primary.main,
    'background-position-x': 'right',
    'background-position-y': 'bottom',
    'background-repeat': 'no-repeat',
  },
});
const Styled = withStyles(styles);
export default Styled;
