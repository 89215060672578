import Creator from './Creator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAuthorities, fetchPermissions } from 'src/store/users/actions';
import { authorities, getLoader } from 'src/store/users/selectors';
import types from 'src/store/users/actionTypes';

const mapStateToProps = state => {
  return {
    authorities: authorities(state),
    loader: getLoader(state, types.CREATE) || getLoader(state, types.UPDATE),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPermissions: () => dispatch(fetchPermissions()),
    getAuthorities: () => dispatch(fetchAuthorities()),
  };
};

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Creator);

const routed = withRouter<any, any>(connected);
export default routed;
