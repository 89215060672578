import { REDUCER_NAME } from './constant';
import { IRootState } from '../rootReducer';
import { Loader, ErrorMessage, SuccessMessage } from '../util';
import { UsersState } from './reducers';

const UserSelector = (state: IRootState) => state[REDUCER_NAME] as UsersState;

export const allUsers = (state: IRootState) => UserSelector(state).users;
export const loader = (state: IRootState) => UserSelector(state).loading;
export const user = (state: IRootState) => UserSelector(state).user;
export const shareableUsers = (state: IRootState) => UserSelector(state).shareableUsers;
export const authorities = (state: IRootState) => UserSelector(state).authorities;
export const allPermissions = (state: IRootState) => UserSelector(state).permissions;
export const userPermissions = (state: IRootState) => UserSelector(state).userPermissions;
export const permissionsGroup = (state: IRootState) => {
  const groups = allPermissions(state).reduce((acc, v) => {
    acc[v.category] = acc[v.category] || [];
    acc[v.category].push(v);
    return acc;
  }, {});

  const permissionsByGroup = Object.keys(groups).map(groupName => ({
    groupName: groupName,
    items: groups[groupName],
  }));
  return permissionsByGroup;
};

export const getError = (state: IRootState, name: string) => UserSelector(state)[ErrorMessage(name)] || '';
export const getSuccess = (state: IRootState, name: string) => UserSelector(state)[SuccessMessage(name)] || '';
export const getLoader = (state: IRootState, name: string) => UserSelector(state)[Loader(name)] || false;
export const shouldRouteSelector = (state: IRootState) => UserSelector(state).routeToUserPage;
