import * as React from 'react';
import { Typography, SvgIcon, Menu, MenuItem, TextField, Button, Divider, withStyles } from '@material-ui/core';

const style = (borderColor): any => ({
  textAlign: 'center',
  justifyContent: 'stretch',
  width: '100%',
  height: '100%',
  flex: '1 1 auto',
  padding: '15px',
  border: `2px solid ${borderColor}`,
  color: `${borderColor}`,
  backgroundColor: 'white',
  cursor: 'pointer',
  boxShadow: '1px 1px 6.86px 0.14px rgba(0, 0, 0, 0.16)',
});
export interface IProps {
  icon: any;
  title: String;
  subTitle: string;
  data: Array<any>;
  classes?: any;
  borderColor?: string;
  HandleOnClick?: any;
  isEditable?: Boolean;
  disabled?: Boolean;
  HandleEditClick?: any;
}

export class ControlButtonComponent extends React.Component<IProps, any> {
  anchorEl: any = null;
  state = {
    anchorEl: undefined,
    dataState: this.props.data,
  };
  handleClickButton = e => {
    this.setState({
      anchorEl: e.currentTarget,
      dataState: this.props.data,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: undefined,
    });
  };
  filter = e => {
    this.setState({
      dataState: this.props.data.filter(el => {
        const name = el.displayName ? el.displayName.toLowerCase() : el.name.toLowerCase();
        return name.toLowerCase().includes(e.target.value.toLowerCase());
      }),
    });
  };
  render() {
    const { icon, title, subTitle, borderColor, HandleOnClick, isEditable, HandleEditClick, disabled, classes } = this.props;
    const { anchorEl, dataState } = this.state;
    return (
      <>
        <div
          style={{ ...style(borderColor), cursor: this.props.disabled ? undefined : 'pointer' }}
          onClick={(!disabled && this.handleClickButton) || undefined}
        >
          <SvgIcon className={classes.controlWrap} style={{ fontSize: 30 }}>
            {icon}
          </SvgIcon>
          <Typography variant='subtitle1' component='h2' color='inherit' noWrap>
            {title}
          </Typography>
          <Typography variant='body2' component='h3' className={classes.fontWeight} color='inherit' noWrap>
            ({subTitle})
          </Typography>
        </div>
        {isEditable ? (
          <>
            <Divider />
            <div className={classes.cbtnEdit}>
              <Button size='small' variant='text' color='default' fullWidth onClick={HandleEditClick}>
                Edit {title.toLowerCase()}
              </Button>
            </div>
            <Divider />
          </>
        ) : (
          <></>
        )}

        <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem>
            <TextField
              placeholder='Search'
              type='search'
              onChange={this.filter}
              inputProps={{
                onReset: e => alert('reseted'),
              }}
              onReset={e => {
                alert('Result');
                this.setState({
                  dataState: this.props.data.slice(),
                });
              }}
            />
          </MenuItem>
          {dataState.map((el, i) => (
            <MenuItem
              value={el.id || el.name}
              key={i}
              onClick={e => {
                this.handleClose();
                HandleOnClick(el);
              }}
            >
              {el.displayName || el.name}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default withStyles({
  root: {
    '& .controlWrap *': {
      fontSize: '30px !important',
    },
  },
  fontWeight: {
    fontWeight: 700,
  },
  cbtnEdit: {
    textAlign: 'center',
  },
})(ControlButtonComponent);
