import * as React from 'react';
import Styled from './style';
import classNames from 'classnames';
import Button from '@material-ui/core/Button/Button';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import UsernameIcon from '@material-ui/icons/Person';

function Form(props) {
  const { classes, loading, onSubmit } = props;

  const [form, setForm] = React.useState({ username: '' });
  const { username } = form;

  const handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    const clone = { ...form, [name]: value };
    setForm(clone);
  };
  const handleSubmit = event => {
    event.preventDefault();
    onSubmit({ username });
  };
  return (
    <form className='loginForm' onSubmit={handleSubmit}>
      <div className={classes.titleWrapper}>
        <Typography color={'inherit'} variant='h4'>
          Forgot Password
        </Typography>
      </div>
      <Typography color={'textPrimary'} variant='body2'>
        Please enter your username and we'll send you instructions on how to reset your password
      </Typography>

      <TextField
        autoFocus
        // label={'Username'}

        placeholder='Username'
        variant={'outlined'}
        fullWidth
        id='username'
        name='username'
        margin='normal'
        value={username}
        onChange={handleChange}
        className={classNames(classes.margin, classes.textField, classes.root)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <UsernameIcon className={classes.loginAdornment} />
            </InputAdornment>
          ),
        }}
      />

      <div className={classes.flex}>
        <Button type='submit' variant='contained' color='primary' size='medium' fullWidth disabled={loading}>
          Ok
        </Button>

        {loading && (
          <div className={classes.marginLeft}>
            <CircularProgress size={35} color='secondary' />
          </div>
        )}
      </div>
    </form>
  );
}
export default Styled(Form);
