import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import { CircularProgress } from '@material-ui/core';

interface IProps {
  id: number | string;
  column?: any;
  rowItem?: any;
  ///////
  onEdit: (...args: any) => any;
  onDelete: (...args: any) => any;
}
export function RowActionsCell(props: IProps) {
  const { id, column, rowItem } = props;
  const { onEdit, onDelete } = props;
  return (
    <span onClick={e => e.stopPropagation()}>
      {/* <CanDo action={questionAccess.edit}> */}
      {(rowItem.isLoading && <CircularProgress size={14} />) || null}

      <TooltipedIconButton tooltip={'Edit filter'} onClick={onEdit}>
        <EditIcon />
      </TooltipedIconButton>
      {/* </CanDo>
            <CanDo action={questionAccess.delete}> */}
      <TooltipedIconButton tooltip={'Delete Filter'} onClick={onDelete}>
        <DeleteIcon />
      </TooltipedIconButton>
      {/* </CanDo> */}
    </span>
  );
}
