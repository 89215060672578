import * as React from 'react';

import { Paper } from '@material-ui/core';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';

import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import ActionFooter from 'src/layout/ActionFooter';

import { OiFunction } from 'src/shared/models/OiFunction';
import OiFunctionsList, { dateGroupingFunctions } from 'src/shared/dataSources/OiFunctions';
import { oiQueryFiltersSelectOptions } from 'src/shared/models/OiFilter';
import { OiFilterDropdownItem } from 'src/shared/models/OiFilterDropdownItem';
import { ComparisonOperator } from 'src/shared/models/ComparisonOperator';
import apisService from 'src/shared/apis';
import { ExportQuestionDto } from 'src/shared/models/Exporter';
import { objToQParams } from 'src/shared/util';
import { QUESTION_DRILL_DOWN_OPERATION } from 'src/components/QuestionPreviewer/models';
import QuestionPreviewer, { QuestionViewMode } from 'src/components/QuestionPreviewer/QuestionPreviewer';
import { ResizingElement } from 'components/oiDimension';
import { VIEW_TYPE, viewTypesOptionsList } from 'src/components/QuestionPreviewer/questionViewTypes';
import OiIconButton from 'src/components/OiIconButton';
import toastr from 'components/toastr';

import { QueryFilterViewItem, QueryGroupViewItem } from 'src/modules/questions/studio/models';
import Styled from 'modules/questions/studio/styles';
import { SQLColumn } from 'src/shared/models/SqlColumn';
import QuestionStudioSideToolbar from 'src/modules/questions/studio/SideToolBar';

import questionStudioServices, {
  getDrillingFilterItem,
  mapViewFiltersToDtoFilters,
  mapQuestionFilterItemToOiQueryFilter,
} from 'modules/questions/studio/questionStudioServices';
import questionsServices, {
  deSerializesFiltersToUrlParams,
  serializesFiltersToUrlParams,
  getQuestionZoomingBatch,
  getQueryPageLength,
  fixFiltersDtoMissingGlue,
} from 'modules/questions/questions.services';
import { getMetaColumnNameInDb } from 'src/shared/models/ExecuterResultResponseDto';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import QuestoinStudioIngredientsPanel from './IngredientsPanel';
import EmptyResultPanel from './components/EmptyResultPanel';
import { QUESTION_QUERY_PAGE_SIZE } from 'src/shared/models';
import { IProps, IQuestionFormContainerState } from './models';
import SaveModal from 'src/components/SaveModal';
import { SQLTable } from 'src/shared/models/SqlTable';
class QuestionStudio extends React.Component<IProps, IQuestionFormContainerState> {
  temp = {} as any;
  doSaveAs = false;
  openSelctDb = false;
  get oiViewItemFunctions() {
    return OiFunctionsList.slice();
  }

  get filtersItemsDto() {
    const filters = (this.props.filtersItems || []).slice().map(mapQuestionFilterItemToOiQueryFilter);
    return fixFiltersDtoMissingGlue(filters);
  }

  state: IQuestionFormContainerState = {
    saveModalIsOpened: false,
  } as IQuestionFormContainerState;

  componentWillMount() {
    let id = (this.props as any).match.params.id;

    this.props.resetStudioState();
    if (id) {
      this.props.loadQuestionById(id);
    } else {
      this.openSelctDb = true;
      let params: any = location.search
        .substring(1)
        .split('&')
        .reduce((res, s) => {
          let sp = s.split('=');
          return { ...res, [sp[0]]: decodeURIComponent(sp[1]) };
        }, {});
      if (params.dbId) {
        this.openSelctDb = false;
        this.loadModelFromParams(params);
      }
    }
  }

  async loadModelFromParams(params) {
    let { operation, dbId, tableId, columnName, columnValue, columnFunction, filterOut, columnId, groupByOperator, filters } = params;
    this.props.batchQuestionStudioState({ isLoadingQuestion: true, isLoadingPreview: false });
    let db = await apisService.getDatabaseById(dbId);
    let tables = await apisService.getDatabasePublicTables(dbId);
    this.props.onSelectDb(db, tables);
    let allColumns = tables.reduce((cAcc: Array<SQLColumn>, t) => cAcc.concat(t.columns), []);
    let table = tables.find(t => t.id == tableId);
    this.props.onSelectTable(table!);
    //////////////////
    columnId = columnId === 'undefined' ? undefined : columnId;
    let filterItems = deSerializesFiltersToUrlParams(filters, allColumns);
    let column = (table && table.columns.find(c => (columnId ? c.id == columnId : c.dbName == columnName))) || undefined;
    column = column || allColumns.find(c => (columnId ? c.id == columnId : c.dbName == columnName));
    column!.functionType = columnFunction;
    column!.dbTableName = column!.dbTableName || table!.name;
    switch (operation) {
      case QUESTION_DRILL_DOWN_OPERATION.UNIQUE_VALUES: {
        this.loadGroupByUniqueValues(table!.displayName, column, groupByOperator, filterItems);
        break;
      }
      case QUESTION_DRILL_DOWN_OPERATION.DISTRIBUTION: {
        this.loadRowsCountDistributionByColumn(table!.displayName, column, filterItems);
        break;
      }
      case QUESTION_DRILL_DOWN_OPERATION.DRILL_DOWN:
      default: {
        this.loadDrilledDownQuestion(table!, column, columnValue, filterOut, filterItems);
        break;
      }
    }
  }

  loadGroupByUniqueValues = (tableName, column, groupByOperator, filtersItems) => {
    let questionTitle = 'Unique values Of ' + column!.displayName + '( ' + tableName + ' )';
    groupByOperator = groupByOperator || OiFunction.UNIQUE_VALUES;
    let groupingFunctionObject = this.oiViewItemFunctions.find(o => o.value == groupByOperator);
    groupingFunctionObject = groupingFunctionObject || dateGroupingFunctions.find(o => o.value == groupByOperator);
    let groupingItem: QueryGroupViewItem = {
      groupingOperator: OiFunction.UNIQUE_VALUES,
      column: column!,
      displayName: (column ? column.displayName + ' ' : '') + groupingFunctionObject!.displayName,
      functionItem: groupingFunctionObject,
    };
    this.props.batchQuestionStudioState({ name: questionTitle, filtersItems });
    this.props.pickGroupItem(groupingItem);
    this.props.onFormModelUpdated();
    this.props.doPreviewResult();
  };

  loadDrilledDownQuestion = (table: SQLTable, column, columnValue, filterOut, filtersItems) => {
    let questionTitle = table.displayName + ' Where ' + column!.displayName + ' equals ' + columnValue;

    this.props.batchQuestionStudioState({ name: questionTitle, filtersItems });

    this.props.pickRawData(table.name);
    let filterItem = getDrillingFilterItem(column, columnValue);
    if (filterOut === 'true') {
      filterItem.filterOperator = oiQueryFiltersSelectOptions.find(o => o.operator == ComparisonOperator.NOT_EQUAL) as OiFilterDropdownItem;
    }
    this.onPickFilterItemToAdd(filterItem);
    this.props.onFormModelUpdated();
    this.props.doPreviewResult();
  };

  loadRowsCountDistributionByColumn = (tableName, column, filtersItems) => {
    let questionTitle = 'Unique values Of ' + column!.displayName + '( ' + tableName + ' )';

    let groupingItem: QueryGroupViewItem = {
      groupingOperator: OiFunction.NONE,
      column: column!,
      displayName: column ? column.displayName + ' ' : '',
      vid: 0,
    };
    this.props.batchQuestionStudioState({ name: questionTitle, filtersItems });
    this.props.pickGroupItem(groupingItem);
    this.props.pickRowsCount();

    ///changeView option to BAR_CHART
    this.props.batchQuestionStudioState({
      viewTypeOption: viewTypesOptionsList.find(o => o.type == VIEW_TYPE.BAR_CHART)!,
    });
    this.props.doPreviewResult();
  };

  requestPaginationConfigs() {
    questionsServices.countQueryResult(this.props as any).then(resultCount => {
      const totalRowsCount = resultCount;
    });
  }

  onLimitChanged = (limit: any) => {
    if (limit > QUESTION_QUERY_PAGE_SIZE) {
      this.requestPaginationConfigs();
    }
    this.props.batchQuestionStudioState({ limit, mayNeedRefresh: true });
    this.props.onFormModelUpdated();
  };

  onPickFilterItemToAdd = (_filterItem: QueryFilterViewItem) => {
    this.props.pickFilterItemToAdd(_filterItem);
  };

  handleSave = (model?: any) => {
    let batch: any = {};
    if (model) {
      batch = {
        name: model.name,
        saveAs: this.doSaveAs,
        description: model.description,
        tags: model.tags.map(v => v.value).join(','),
      };
      this.props.batchQuestionStudioState(batch);
    }
    let closeLoadingToastr = toastr.loading('Saving your question');

    return questionStudioServices.saveQuestion(batch).then(
      data => {
        !this.props.id && this.props.batchQuestionStudioState({ id: data.id });
        toastr.success('Question Saved Successfully');
        closeLoadingToastr();
        this.closeSaveModal();
        return data;
      },
      err => {
        closeLoadingToastr();
        toastr.error('Error Saving question \n' + JSON.stringify(err));
      }
    );
  };

  saveAndClose = model => {
    const { saveConfigs } = this.state;
    if (saveConfigs && saveConfigs.saveCallBack) {
      saveConfigs.saveCallBack(model);
    } else {
      this.handleSave(model).then(() => {
        this.doSaveAs = false;
        (this.props as any).history.goBack();
      });
    }
  };

  saveAsAndClose = model => {
    this.doSaveAs = true;
    this.saveAndClose(model);
  };

  onCancelAllForm = () => {
    (this.props as any).history.goBack();
  };

  openSaveModal = (saveAs = false) => {
    this.doSaveAs = saveAs;
    this.setState(state => ({
      saveModalIsOpened: true,
    }));
  };

  closeSaveModal = () => {
    this.setState(state => ({
      saveModalIsOpened: false,
    }));
  };

  openSaveQuestionModal = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        saveModalIsOpened: true,
        saveConfigs: {
          saveCallBack: saveModel => {
            this.setState({
              saveModalIsOpened: false,
              ...saveModel,
            });
            this.handleSave(saveModel).then(result => {
              this.doSaveAs = false;
              resolve(result);
              return result;
            });
          },
          cancelCallBack: () => {
            reject();
          },
        },
      });
    });
  };

  onDoChartDrillDown = ({ clickedSegment, previewResult }) => {
    let dbId = this.props.datasourceID;
    let tableId = this.props.baseTable && this.props.baseTable.id;

    let columnMeta = clickedSegment.currentGroup || previewResult.metadata[clickedSegment.seriesIndex];
    let value = clickedSegment.name;
    const column = this.props.allColumns.find(c => getMetaColumnNameInDb(columnMeta) == c.dbName);
    let columnFunciton = columnMeta.expression[0].function;
    let params = {
      operation: QUESTION_DRILL_DOWN_OPERATION.DRILL_DOWN,
      dbId,
      tableId,
      columnId: column ? column.id : '',
      columnName: columnMeta.expression[0].dbName,
      columnValue: value,
      filterOut: false,
      columnFunction: columnFunciton,
      filters: serializesFiltersToUrlParams(this.props.filtersItems),
    };
    let url = location.origin + `/questions/new?${objToQParams(params)}`;
    window.open(url, '' + Date());
  };

  onDoSegmentZoom = ({ direction, clickedSegment, previewResult }) => {
    let columnMeta: ColumnMetadatum = clickedSegment.currentGroup || previewResult.metadata[clickedSegment.seriesIndex];
    const column = this.props.allColumns.find(c => getMetaColumnNameInDb(columnMeta) == c.dbName);
    const { filters, groupItems } = getQuestionZoomingBatch(clickedSegment, columnMeta, direction, column!, this.props);
    this.props.batchQuestionStudioState({
      filtersItems: filters,
      groupItems: groupItems,
      mayNeedRefresh: true,
    });
    this.props.doPreviewResult();
  };

  onDoSegmentFilterOut = ({ clickedSegment, previewResult }) => {
    let dbId = this.props.datasourceID;
    let tableId = this.props.baseTable && this.props.baseTable.id;
    let columnMeta = clickedSegment.currentGroup || previewResult.metadata[clickedSegment.seriesIndex];
    let value = clickedSegment.name;
    let column = this.props.allColumns.find(c => getMetaColumnNameInDb(columnMeta) == c.dbName);
    let params = {
      operation: QUESTION_DRILL_DOWN_OPERATION.DRILL_DOWN,
      dbId,
      tableId,
      columnId: column ? column.id : '',
      columnName: columnMeta.expression[0].dbName,
      columnValue: value,
      filterOut: true,
      columnFunction: columnMeta.expression[0].function,
      filters: serializesFiltersToUrlParams(this.props.filtersItems),
    };
    let url = location.origin + `/questions/new?${objToQParams(params)}`;
    window.open(url, '' + Date());
  };

  onQuestionViewUpdated = updatedViewOptions => {
    const chartOptions = { ...this.props.chartOptions, ...updatedViewOptions.customize };
    this.props.batchQuestionStudioState({
      viewTypeOption: updatedViewOptions,
      chartOptions,
    });
  };

  onChartCustomizationChanged = chartOptions => {
    this.props.batchQuestionStudioState({ chartOptions: Object.assign({}, this.props.chartOptions, chartOptions) });
  };

  onExportQuestion = async options => {
    let questionId = this.props.id;
    let questionName = this.props.name || `question-${questionId}-export`;
    try {
      await this.openSaveQuestionModal();
    } catch (e) {
      console.error(e);
      return;
    }

    let dto = {
      displayName: questionName,
      extention: options.extension,
      filters: mapViewFiltersToDtoFilters(this.props.filtersItems),
      lifeTimeHours: 0,
      pageNumber: 0,

      pageSize: this.props.limit || 1000,
      questionId: this.props.id,
    } as ExportQuestionDto;
    let closeLoadingToastr = toastr.loading('Exporting your question');
    this.props.batchQuestionStudioState({ isLoadingQuestion: true });
    apisService.exportQuestion(dto).then(
      d => {
        closeLoadingToastr();
        if (d.fileId) {
          dto.id = d.fileId;
          apisService.downloadExportedFile(dto);
        } else {
          if (d.status !== 'PASS' || d.cause) {
            toastr.error(d.cause);
          } else {
            toastr.error('Failed to export question');
          }
        }
      },
      e => {
        closeLoadingToastr();
        toastr.error('Failed to export question');
        this.props.batchQuestionStudioState({ isLoadingQuestion: false });
      }
    );
  };

  onShareWithUsers = async options => {
    try {
      await this.openSaveQuestionModal();
    } catch (e) {
      console.error(e);
      return;
    }

    const closeLoadingToastr = toastr.loading('Sharing question');
    this.props.batchQuestionStudioState({ isLoadingQuestion: true });
    apisService.shareAQuestionWithUsers(this.props.id, options.users).then(
      () => {
        this.props.batchQuestionStudioState({ isLoadingQuestion: false });
        closeLoadingToastr();
        toastr.success('Question Shared Successfully');
      },
      err => {
        closeLoadingToastr();
        this.props.batchQuestionStudioState({ isLoadingQuestion: false });
        console.error('Scheduler job: ', err);
        toastr.error('Error, un able to share question!');
      }
    );
  };

  onShareQuestionToEmails = async options => {
    try {
      await this.openSaveQuestionModal();
    } catch (e) {
      console.error(e);
      return;
    }
    const filters = this.filtersItemsDto;
    let dto = {
      displayName: `${this.props.name ? this.props.name : options.extension.title}`,
      emails: options.extension.email,
      question: {
        displayName: this.props.name ? this.props.name : options.extension.title,
        extention: options.extension.format.toUpperCase() || 'CSV',
        filters: filters,
        pageNumber: 0,
        pageSize: this.props.limit || 1000,
        questionId: this.props.id,
      },
    };

    this.props.batchQuestionStudioState({ isLoadingQuestion: true });
    let closeLoadingToastr = toastr.loading('Sending emails');
    apisService.shareQuestionByEmail(dto).then(
      r => {
        if (r.status !== 'PASS') {
          toastr.error('Error, unable to share question by email!');
          return Promise.reject(r.cause);
        }
        this.props.batchQuestionStudioState({ isLoadingQuestion: false });
        closeLoadingToastr();
        toastr.success('Question Shared by email Successfully');
        return Promise.resolve(r);
      },
      err => {
        this.props.batchQuestionStudioState({ isLoadingQuestion: false });
        closeLoadingToastr();
        console.error('Scheduler job: ', err);
        toastr.error('Error, unable to share question by email!');
      }
    );

    if (options.extension.cron !== undefined) {
      let jobBody = {
        displayName: `Send email schedule ${dto.displayName} question`,
        cronExp: options.extension.cron,
        exportFileJob: {
          emails: options.extension.email,
          questions: [dto.question],
        },
        jobType: 'EXPORT_FILES',
      };

      apisService
        .addScheduler(jobBody)
        .then(
          () => {
            toastr.info('Question Share has been scheduled Successfully');
          },
          err => {
            console.error('Scheduler job: ', err);
            toastr.error(' Cant create job for scheduling email!');
          }
        )
        .catch(err => console.error('Scheduler job: ', err));
    }
  };

  loadNextQueryChunk = () => {
    const nextPage = this.props.lastQueryPage + 1;
    this.props.batchQuestionStudioState({ lastQueryPage: nextPage });

    this.setState({ isLoadingQueryNextChunk: true });
    const questionModel = this.props as any;
    // let filtersDto = this.filtersDto;
    const pageLength = getQueryPageLength(questionModel.limit);
    questionsServices.previewQuestion(questionModel, { pageNumber: nextPage, pageLength }).then(
      newPreviewResult => {
        const statePreviewResult = this.props.previewResults;
        let _previewResultBatch = questionsServices.getQueryChunkPreviewResultBatch(newPreviewResult, statePreviewResult, questionModel);
        this.setState({ isLoadingQueryNextChunk: false });
        this.props.batchQuestionStudioState({
          previewResult: {
            ...newPreviewResult,
            ..._previewResultBatch,
          },
        });
      },
      err => {
        this.setState({ isLoadingQueryNextChunk: false });
      }
    );
  };

  render() {
    const { expandPreviewResultCard, classes } = this.props;
    const filterOperatorsList = oiQueryFiltersSelectOptions.slice();
    const { saveModalIsOpened } = this.state;
    const showPanel1 = this.props.hasAnyData && expandPreviewResultCard;
    const Panel2 = (
      <Paper className={classNames(classes.mainCard, classes.panel2, 'oi-dimensions-ref')} elevation={1}>
        <ResizingElement>
          <QuestionPreviewer
            hasRawData={this.props.hasRawData}
            chartOptions={this.props.chartOptions}
            isLoadingPreview={this.props.isLoadingPreview}
            previewResult={this.props.previewResults}
            showPreviewButton={this.props.mayNeedRefresh}
            mode={QuestionViewMode.QUESTION_STUDIO}
            questionModel={{ ...this.props } as any}
            viewTypeOption={this.props.viewTypeOption || viewTypesOptionsList[0]}
            toolBarStartButtons={
              <OiIconButton onClick={this.props.toggleExpandFormCard} className='toggleExpandFormCard'>
                {showPanel1 ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </OiIconButton>
            }
            onPreviewResult={this.props.doPreviewResult}
            onViewTypeOptionUpdated={this.onQuestionViewUpdated}
            onChartCustomizationChanged={this.onChartCustomizationChanged}
            onDoChartDrillDown={this.onDoChartDrillDown}
            onDoSegmentZoom={this.onDoSegmentZoom}
            onDoSegmentFilterOut={this.onDoSegmentFilterOut}
            onExportQuestion={this.onExportQuestion}
            onShareQuestionToEmails={this.onShareQuestionToEmails}
            onShareWithUsers={this.onShareWithUsers}
            onChangeComponentColor={this.onChartCustomizationChanged}
            onExecuteFilters={this.props.doPreviewResult}
            isLoadingQueryNextChunk={this.state.isLoadingQueryNextChunk}
            loadNextQueryChunk={this.loadNextQueryChunk}
          />
        </ResizingElement>
      </Paper>
    );

    const { showPreviewPanelPlaceHolderMessage, hasAnyData } = this.props;

    return (
      <>
        <BreadCrumb>
          {' '}
          Home <KeyboardArrowRight /> Questions <KeyboardArrowRight /> Studio{' '}
        </BreadCrumb>
        <ContentPage>
          <div className={classNames(classes.myFlex, 'create-question-wrap')}>
            <QuestionStudioSideToolbar
              autoOpenDb={this.openSelctDb}
              onLimitChanged={this.onLimitChanged}
              onPickFilterItemToAdd={this.onPickFilterItemToAdd}
              functionsList={this.oiViewItemFunctions}
              filtersList={filterOperatorsList}
            />

            <div className={classes.previewAreaContentWrap}>
              {showPreviewPanelPlaceHolderMessage || !hasAnyData ? (
                <EmptyResultPanel />
              ) : (
                <>
                  {(showPanel1 && <QuestoinStudioIngredientsPanel classes={classes} />) || <></>}
                  {Panel2}
                </>
              )}
            </div>
          </div>
        </ContentPage>
        <ActionFooter
          resetBtn={true}
          handleReset={this.props.resetPanel1Data}
          handleSave={this.openSaveModal.bind(this, false)}
          handleCancel={this.onCancelAllForm}
        />
        <SaveModal
          EntityName='Question'
          tags={this.props.tags}
          title={this.props.name}
          open={Boolean(saveModalIsOpened)}
          description={this.props.description}
          showSaveAs={Boolean(this.props.id)}
          onSave={this.saveAndClose}
          onSaveAs={this.saveAsAndClose}
          onCancel={this.closeSaveModal}
        />
      </>
    );
  }
}

export default Styled(QuestionStudio);
