import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import SettingsPage from './SettingsPage';

const Routes = ({ match }) => (
  <>
    <Switch>
      <Route path={`${match.url}`} component={SettingsPage} />
    </Switch>
  </>
);

export default Routes;
