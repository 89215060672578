import * as React from 'react';
import Styled from './style';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography/Typography';
import TextField from '@material-ui/core/TextField/TextField';

function Form(props) {
  const { classes, onSubmit } = props;

  const [form, setForm] = React.useState({ password1: '', password2: '' });
  const [focus, setFocus] = React.useState({ password1: false, password2: false });

  const [isMatch, setMatching] = React.useState(true);

  const { password1, password2 } = form;

  const handleFocus = event => {
    const name = event.target.name;
    if (!focus[name]) {
      const fox = { ...focus, [name]: true };
      setFocus(fox);
    }
  };

  const handleChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    const fo = { ...form, [name]: value };
    setForm(fo);
    checkMatching(fo);
  };

  const checkMatching = fo => {
    if (focus.password1 && focus.password2) {
      fo.password1 == fo.password2 ? setMatching(true) : setMatching(false);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    onSubmit(form);
  };

  const canSubmit = isMatch && password1.length > 2 && password2.length > 2;
  return (
    <form>
      <div>
        <TextField
          id='new-password'
          label='New Password'
          type='password'
          fullWidth
          autoComplete='new-password'
          margin='normal'
          variant='outlined'
          name='password1'
          value={password1}
          onChange={handleChange}
          onFocus={handleFocus}
          autoFocus
        />
      </div>
      <div>
        <TextField
          id='new-password-confirm'
          label='Confirm Password'
          type='password'
          fullWidth
          autoComplete='new-password'
          margin='normal'
          variant='outlined'
          name='password2'
          value={password2}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </div>

      {!isMatch && (
        <Typography className={classes.errMsgStyle} variant='subtitle1' color='error'>
          The password not matching
        </Typography>
      )}
      <Button disabled={!canSubmit} className={classes.submitBtn} variant='contained' color={'primary'} onClick={handleSubmit}>
        Submit
      </Button>
    </form>
  );
}
export default Styled(Form);
