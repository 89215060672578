import { StyleRulesCallback, Theme, createStyles, withStyles } from '@material-ui/core';

export const styles: StyleRulesCallback = (theme: Theme) =>
  createStyles({
    slideWrap: {
      overflow: 'hidden',
    },
    stepHeader: {
      borderBottom: '1px solid #e3e3e3',
      padding: 10,
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      color: '#048edd',
      '& svg': {
        color: '#666b6f',
        cursor: 'pointer',
      },
      '& button': {
        margin: 'auto',
        marginRight: 0,
      },
    },

    stepHeaderSvg: {
      color: '#666b6f',
      cursor: 'pointer',
    },
    slideContent: {
      padding: 10,
    },
    slide: {
      width: 350,
      height: 400,
    },
    dotsWrapper: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      alignContent: 'center',
      width: '100%',
      flex: '1 1 auto',
      justifyContent: 'center',
    },
    dot: {
      width: 15,
      height: 15,
      borderRadius: '50%',
      border: '1px solid ',
      margin: '5px 2px',
      borderColor: theme.palette.grey['300'],
      background: theme.palette.grey['300'],
    },
    dotActive: {
      borderColor: theme.palette.secondary.light,
      background: theme.palette.secondary.light,
    },
    listItem: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  });

export default withStyles(styles);
