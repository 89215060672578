import React from 'react';
import { Paper, List, ListItem, withStyles, Collapse } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import { Colors } from 'src/shared/theme';
import TitledBorder from 'src/components/TitledBorder';
import OiIcon from 'src/assets/icons';

interface IProps {
  classes?: any; //{[key:string]:IComponentStyle};
  suggestionsDataSource: any[];
  focusIndex?: number;
  onSelectOption: (col?: string) => any;
}

function SuggestionsSelectList({ classes, suggestionsDataSource, onSelectOption, focusIndex }: IProps) {
  return (
    <Paper elevation={1} className={classes.popover}>
      <Scrollbars className={classes.scrollBar}>
        {
          <List style={{ listStyle: 'none', padding: 0, width: '100%' }}>
            {suggestionsDataSource.map((suggestion, i) => {
              return suggestion.isGroupTitle ? (
                <ListItem key={`${i}_${suggestion.label}`} className={classes.tableNameWrap}>
                  <TitledBorder color={Colors.brandGreyBorders}>
                    <span style={{ color: Colors.brandDarkGrey }}> {suggestion.label} </span>
                  </TitledBorder>
                </ListItem>
              ) : (
                <ListItem
                  button
                  key={suggestion.colIndex}
                  selected={focusIndex == suggestion.colIndex}
                  classes={{
                    root: classes.listItem,
                  }}
                  style={{
                    color: focusIndex == suggestion.colIndex ? Colors.brandBlue : 'black',
                  }}
                  onClickCapture={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelectOption(suggestion.label);
                  }}
                >
                  {suggestion.icon && <OiIcon path={suggestion.icon} scale={'24px'} />}
                  {suggestion.label}
                </ListItem>
              );
            })}
          </List>
        }
      </Scrollbars>
    </Paper>
  );
}

const componentStyle = {
  selectedItem: {
    display: 'flex',
    alignContent: 'center',
    color: '#048edd',
  },
  innerList: {},
  listItem: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 20,
  },
  popover: {
    height: '300px',
    width: '300px',
    overflow: 'hidden',
  },
  scrollBar: {
    height: '300px !important',
    width: '300px !important',
  },
};
export default withStyles(componentStyle)(SuggestionsSelectList);
