import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import DownloadsList from 'modules/downloads/DownloadsList';
import CanActivateModule from 'src/components/Permissions/CanActivateModule';
import { canDo, connectedCanDo } from 'src/services/permissions/utils';
import questionAccess from 'src/services/permissions/abilities/questions';
import NotAuthorizedPage from 'src/layout/NotAuthorized';
import downloadAccess from 'src/services/permissions/abilities/downloads';

const Routes = ({ match }) => (
  <CanActivateModule canActivate={(userStorePermissions, userRoles) => canDo(userStorePermissions, userRoles, questionAccess.export)}>
    <Switch>
      <Route path={match.url} component={connectedCanDo(downloadAccess.view) ? DownloadsList : NotAuthorizedPage} />
    </Switch>
  </CanActivateModule>
);

export default Routes;
