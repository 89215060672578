import React from 'react';
import { CircularProgress } from '@material-ui/core';

const CheckingAuthLoader = (props: any) => {
  return (
    <div>
      <CircularProgress /> Please wait...{' '}
    </div>
  );
};

export default CheckingAuthLoader;
