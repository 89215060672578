import axios from 'axios';
import { getToken } from 'src/store/authentication/selectors';
import { put } from 'redux-saga/effects';

import { store } from 'src/constants';
import { bindActionCreators } from 'redux';
import { clearAuth, logout } from 'store/authentication/actions';
import { BaseURL } from 'src/envReader';
import toastr from 'src/components/toastr';

const TIMEOUT = 1000000; // 10000

const setupAxiosAuthInterceptors = () => {
  const onRequestSuccess = config => {
    const token = getToken();
    put({ type: 'AXIOS_REQUEST_SENT', payload: config });
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.timeout = TIMEOUT;
    config.url = config.url.replace(BaseURL, ''); // to avoid adding the world twice !
    config.url = `${BaseURL}${config.url}`;
    return config;
  };

  const onResponseSuccess = response => {
    put({ type: 'AXIOS_REQUEST_RESPONSE_SUCCESS', payload: response });
    return response;
  };

  const onResponseError = err => {
    put({ type: 'AXIOS_REQUEST_RESPONSE_ERROR', payload: err });
    const status = err.status || (err.response && err.response.status);
    if (status === 403) {
      toastr.error('You are unathorized to access this url ' + err.config.url);
    }
    if (status === 401) {
      store.dispatch(clearAuth(err));
      if (!location.href.includes('login')) {
        store.dispatch(logout());
      }
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosAuthInterceptors;
