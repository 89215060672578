export type IDatasource<T> = Array<T | any>;
export type LabelOption<T> = ((d: T, index: number) => string) | Array<string> | string;
export type IConvertToSelectOptionsOptions<T> = {
  label: LabelOption<T>;
  value: ((d: T, index: number) => any) | string;
};

export function labelExtracter<T>(labelOption: LabelOption<T>, item: any, index: number) {
  let label = '';
  if (typeof labelOption === 'function') {
    label = labelOption(item, index);
  } else if (Array.isArray(labelOption)) {
    label = labelOption.map(field => item[field]).join(' ');
  } else if (typeof labelOption === 'string') {
    label = item[labelOption];
  } else {
    label = '' + item;
  }
  return label;
}
export function convertListToSelectOptions<T>(
  datasource: IDatasource<T>,
  options: IConvertToSelectOptionsOptions<T>
): Array<T & { label: string; value: string }> {
  return (datasource || []).map((item, index) => {
    let label = labelExtracter(options.label, item, index);
    let value = '';
    if (typeof options.value === 'function') {
      value = options.value(item, index);
    } else if (typeof options.value === 'string') {
      value = item[options.value];
    } else {
      value = '' + item;
    }
    return {
      ...item,
      label,
      value,
    };
  });
}
