import { REQUEST, SUCCESS, FAILURE } from 'store/util';
import { FavoriteQuestion } from 'src/shared/models/OiQuestionDto';

export const ACTION_TYPES = {
  GET_FAV_QUESTIONS: 'questions/FAV/GET',
  CREATE_FAV_QUESTION: 'questions/FAV/CREATE',
  DELETE_FAV_QUESTION: 'questions/FAV/DELETE',
  /////////////////////////////
  GET_QUESTIONS: 'questions/GET',
  GET_QUESTION_BY_ID: 'questions/GET_QUESTION_BY_ID',
  GET_QUESTION_BY_ID_SUCCESS: 'questions/GET_QUESTION_BY_ID_SUCCESS',
  CREATE_QUESTION: 'questions/CREATE',
  DELETE_QUESTION: 'questions/DELETE',
};

const initialState = {
  favoriteQuestions: [] as Array<FavoriteQuestion>,

  loadingFav: false,
  loadFavSuccess: false,
  loadFavError: false,

  questionsList: [] as Array<any>,
  questionDtos: {},
  loading: false,
  loadSuccess: false,
  loadError: false,

  createSubmitting: false,
  createSuccess: false,
  createError: false,

  deleteSubmitting: false,
  deleteSuccess: false,
  deleteError: false,
  errorMessage: '' as string,
};

export type QuestionsState = Readonly<typeof initialState>;

// Reducer
export default (state: QuestionsState = initialState, { type, payload }): QuestionsState => {
  switch (type) {
    //Favorite Questions
    case REQUEST(ACTION_TYPES.GET_FAV_QUESTIONS):
      return {
        ...state,
        loadingFav: true,
      };
    case FAILURE(ACTION_TYPES.GET_FAV_QUESTIONS): {
      let response = payload.response;
      return {
        ...state,
        errorMessage:
          (payload && payload.fieldErrors) ||
          (response && response && (response.fieldErrors || JSON.stringify(response.messages))) ||
          payload.message,
        loadFavError: true,
        loadFavSuccess: false,
        loadingFav: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_FAV_QUESTIONS): {
      return {
        ...state,
        loadingFav: false,
        loadFavError: false,
        loadFavSuccess: true,
        favoriteQuestions: payload,
      };
    }

    // Questions ....
    case REQUEST(ACTION_TYPES.GET_QUESTIONS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ACTION_TYPES.GET_QUESTIONS): {
      let response = payload.response;
      return {
        ...state,
        errorMessage:
          (payload && payload.fieldErrors) ||
          (response && response && (response.fieldErrors || JSON.stringify(response.messages))) ||
          payload.message,
        loadError: true,
        loadSuccess: false,
        loading: false,
      };
    }
    case SUCCESS(ACTION_TYPES.GET_QUESTIONS): {
      return {
        ...state,
        loading: false,
        loadError: false,
        loadSuccess: true,
        questionsList: payload,
      };
    }

    case REQUEST(ACTION_TYPES.CREATE_QUESTION):
      return {
        ...state,
        createSubmitting: true,
      };
    case FAILURE(ACTION_TYPES.CREATE_QUESTION): {
      let response = payload.response;

      return {
        ...state,
        createError: true,
        createSuccess: false,
        errorMessage: (response && response.data.messages) || payload.message,
        createSubmitting: false,
      };
    }
    case SUCCESS(ACTION_TYPES.CREATE_QUESTION): {
      return {
        ...state,
        createSubmitting: false,
        createError: false,
        createSuccess: true,
      };
    }

    case REQUEST(ACTION_TYPES.DELETE_QUESTION):
      return {
        ...state,
        deleteSubmitting: true,
      };

    case FAILURE(ACTION_TYPES.DELETE_QUESTION): {
      let response = payload.response;

      return {
        ...state,
        deleteError: true,
        deleteSuccess: false,
        errorMessage: (response && response.data.messages) || payload.message,
        deleteSubmitting: false,
      };
    }

    case SUCCESS(ACTION_TYPES.DELETE_QUESTION): {
      return {
        ...state,
        deleteSubmitting: false,
        questionsList: state.questionsList.filter(q => q.id !== payload.id),
        deleteError: false,
        deleteSuccess: true,
      };
    }
    case ACTION_TYPES.GET_QUESTION_BY_ID_SUCCESS:
      return {
        ...state,
        questionDtos: { ...state.questionDtos, [payload.id]: payload },
      };
    default:
      return state;
  }
};
