import OiIcon from 'src/assets/icons';
import AddFilter from 'assets/icons/AddFilter';
import AddGroupObjectsIcon from 'assets/icons/AddGroupObjectsIcon';
import AddViewColumnFilledIcon from 'assets/icons/AddViewColumnFilledIcon';
import ControlButton from 'components/ControlButton';
import React from 'react';
import AddOiFilterWizard from 'src/modules/questions/studio/SideToolBar/AddOiFilterWizard';
import AddOiGroupWizard from 'src/modules/questions/studio/SideToolBar/AddOiGroupWizard';
import AddOiViewWizard from 'src/modules/questions/studio/SideToolBar/AddOiViewWizard';
import LimitSelect from 'src/modules/questions/studio/SideToolBar/LimitSelectWizard';
import SelectDataset from 'src/modules/questions/studio/SideToolBar/SelectDataset';
import Styled from './Styled';
import { IProps } from './models';
import RawDataWizard from './AddRawDataWizard';
import confirmUtils from 'src/store/confirmationPopup/utils';

const QuestionStudioSideToolbar: React.StatelessComponent<IProps> = props => {
  const {
    questionId,
    selectedTable,
    selectedDatabase,
    columns,
    functionsList,
    onLimitChanged,
    onPickFilterItemToAdd,
    onReset,
    onPickGroupToAdd,
    onPickCalculatedColumn,
    onAddAdvanceFilter,
    relatedTables,
    limit,
    classes,
    groupItems,
    viewsItems,
    computedColumns,
    hasRawData,
  } = props;

  const disableChangeDataSet = Boolean(questionId);
  //  const questionStudioState =
  const hasComputedColumns = computedColumns.some(c => {
    return c.expression && Array.isArray(c.expression.value) && c.expression.value.length > 0;
  });
  const hasViews = Boolean(viewsItems.length);
  const hasGroups = Boolean(groupItems.length);

  const disableAddView = !Boolean(selectedTable);
  const disableAddGroup = !(hasViews || hasComputedColumns) || hasRawData;
  const disableAddFilter = !(hasViews || hasGroups || hasComputedColumns);
  const disableSetLimit = !(hasViews || hasGroups || hasComputedColumns);

  const dataSetTitle = (
    <span style={{ color: 'black', fontSize: '12px' }}>
      {!selectedTable && 'Select Table'}
      {selectedTable ? <> {selectedTable.displayName.substr(0, 25)} </> : ''}
    </span>
  );
  const tables = selectedTable ? [selectedTable, ...(relatedTables || [])] : [];

  const addCalculator = () => {
    if (hasRawData) {
      let confirmationMsg = `Can't add the selected View with Raw Data, do you want to reset ?`;
      confirmUtils.confirm({
        msg: confirmationMsg,
        ok: () => {
          onReset();
          onPickCalculatedColumn();
        },
      });
    } else {
      onPickCalculatedColumn();
    }
  };

  return (
    <div className={classes.Wrapper}>
      <div className='controls-card'>
        <SelectDataset selectedDatabase={selectedDatabase} selectedTable={selectedTable} open={Boolean(props.autoOpenDb)}>
          <ControlButton disabled={disableChangeDataSet} title={dataSetTitle} subTitle='Data Set' icon={<OiIcon path={'DatabaseIcon'} />} />
        </SelectDataset>
      </div>
      <div className='controls-card'>
        <RawDataWizard />
      </div>
      <div className='controls-card'>
        <AddOiViewWizard
          columns={columns}
          oiFunctionsList={functionsList}
          tables={tables}
          addRowsCount={props.addRowsCount}
          addGroupItem={props.addGroupItem}
          addViewItem={props.addViewItem}
          buttonComponent={
            <ControlButton
              disabled={disableAddView || !Boolean(selectedTable)}
              title={<div className={classes.H14px}>+ Add View </div>}
              subTitle=''
              icon={<AddViewColumnFilledIcon width='30px' fill='#048edd' />}
              borderColor='#048edd'
            />
          }
        />
      </div>

      <div className='controls-card'>
        <ControlButton
          disabled={disableAddView || !Boolean(selectedTable)}
          title={<div className={classes.H14px}> Calculator </div>}
          subTitle=''
          icon={<OiIcon path={'Math'} />}
          borderColor='#d44b00'
          onClick={addCalculator}
        />
      </div>

      <div className='controls-card'>
        <AddOiGroupWizard
          columns={columns}
          onDone={onPickGroupToAdd}
          tables={tables}
          buttonComponent={
            <ControlButton
              disabled={Boolean(disableAddGroup) || !Boolean(selectedTable)}
              title={<div className={classes.H14px}>+ Add Group</div>}
              subTitle=''
              icon={<AddGroupObjectsIcon width='30px' />}
              borderColor='#d44b00'
            />
          }
        />
      </div>

      <div className='controls-card'>
        <AddOiFilterWizard
          filteringOperators={props.filtersList}
          onDone={onPickFilterItemToAdd}
          onAddAdvanceFilter={onAddAdvanceFilter}
          tables={tables}
          buttonComponent={
            <ControlButton
              disabled={disableAddFilter || !Boolean(selectedTable)}
              title={<div className={classes.H14px}>+ Add Filter</div>}
              subTitle=''
              icon={<AddFilter width='30px' />}
              borderColor='#10bb8b'
            />
          }
        />
      </div>

      <div className='controls-card'>
        <LimitSelect
          value={parseInt(limit)}
          onValueChanged={onLimitChanged}
          onOk={onLimitChanged}
          buttonComponent={
            <ControlButton
              disabled={disableSetLimit || !Boolean(selectedTable)}
              title={<div className={classes.H14px}>Set Limit</div>}
              subTitle={limit}
              icon={<OiIcon path={'TimeLimitIcon'} />}
              borderColor='#37abd3'
            />
          }
        />
      </div>
    </div>
  );
};

export default Styled(QuestionStudioSideToolbar);
