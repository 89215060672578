import * as React from 'react';
import { Paper, withStyles, Typography, Dialog } from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';

import classNames from 'classnames';
import TableCellMUI from '@material-ui/core/TableCell';

import { VirtualTable } from './grid/components/virtual-table';
import { TableColumnResizing } from './grid/components/table-column-resizing';
import { TableHeaderRow } from './grid/components/table-header-row';
import { TableSummaryRow } from './grid/components/table-summary-row';
import { TableFixedColumns } from './grid/components/table-fixed-columns';
import { PagingPanel } from './grid/components/paging-panel';
import { OiTableGrid } from './grid/table-grid';
import { PagingState } from './grid/plugins/paging-state';
import { IntegratedPaging } from './grid/plugins/integrated-paging';
import { SortingState } from './grid/plugins/sorting-state';
import { IntegratedSorting } from './grid/plugins/integrated-sorting';
import { SummaryState } from './grid/plugins/summary-state';
import { IntegratedSummary } from './grid/plugins/integrated-summary';
import { TableGroupRow } from './grid/components/table-group-row';
import { GroupingState } from './grid/plugins/grouping-state';
import { IntegratedGrouping } from './grid/plugins/integrated-grouping';
import OiIconButton from 'src/components/OiIconButton';
import { getAnchorElementFromEvent } from 'src/shared/util';
import { ColumnMetadatum } from 'src/shared/models/ColumnMetadatum';
import ColumnSummary from './ColumnSummary';
import { styles } from './style';
import ColumnActionsMenu from './ColumnActionsMenu';
import {
  NumericSorting,
  SortingDate,
  NumberTypeProvider,
  DateTypeProvider,
  DateDiffTypeProvider,
  SpecialTypeProvider,
  summaryCalculator,
  messages,
} from './tableUtils';
import { ColumnExpression } from 'src/shared/models/OiQueryColumn';
import { OiFunction } from 'src/shared/models/OiFunction';
import toastr from '../toastr';

class CustomEnhancedTableComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);

    let defaultMyColumnWidth: Array<any> = [];
    let myColumns: Array<any> = [];
    let myTableColumnExtensions: Array<any> = [];
    let mySortingColumnExtensions: Array<any> = [];
    let myNumberColumns: Array<any> = [];
    let isDistribution: boolean = false;
    let myPinColumns: Array<any> = [];
    let myGroupingColumns: Array<any> = [];
    let myDateColumn: Array<any> = [];
    let dateDiffColumns: Array<any> = [];
    let mySpecialTypeColumns: Array<any> = [];
    let mySpecialTypeColumnsFormats: Array<any> = [];

    (props.meta as ColumnMetadatum[]).forEach(col => {
      const firstColumnExepressionItem = (col as any).expression[0];
      if (col.title === 'CreationDate') isDistribution = true;
      const headerLabel = col.label;

      myColumns.push({
        meta: col,
        name: headerLabel,
        title: headerLabel,
        type: col.oceanDataType,
        dataPattern: col.dataPattern,
      });

      if (col.pin) myPinColumns.push(col.title);
      if (col.grouped) myGroupingColumns.push({ columnName: col.title });
      defaultMyColumnWidth.push({ columnName: col.title, width: 180 });
      myTableColumnExtensions.push({ columnName: col.title, width: 180 });
      if (col.oceanDataType === 'NUMBER' || col.oceanDataType === 'DATE') {
        let compareFunction = col.oceanDataType === 'NUMBER' ? NumericSorting : col.oceanDataType === 'DATE' ? SortingDate : '';
        mySortingColumnExtensions.push({ columnName: col.title, compare: compareFunction });
      }
      // Formats
      let _name = col.title || ('' as String);

      if (_name.toLowerCase().includes('id')) {
      } else if (col.dataPattern != null) {
        mySpecialTypeColumns.push(col.title);
        mySpecialTypeColumnsFormats.push({ name: col.title, specialType: col.dataPattern });
      } else if (col.type === 'MEASURE' && col.oceanDataType === 'NUMBER') {
        myNumberColumns.push(col.title);
      } else if (col.type === 'DIMENSION' && col.oceanDataType === 'DATE' && (col.expression[0] as ColumnExpression).function === 'NONE') {
        myDateColumn.push(col.title);
      } else if (firstColumnExepressionItem && firstColumnExepressionItem.function == OiFunction.DATE_DIFF) {
        dateDiffColumns.push(col.title);
      } else {
      }
    });

    this.state = {
      columns: myColumns,
      columnWidths: defaultMyColumnWidth,
      columnExtensions: myTableColumnExtensions,
      sortingColumnExtensions: mySortingColumnExtensions,
      rows: props.data,
      currentPage: 0,
      pageSizes: [10, 20, 50, this.props.mode.toLowerCase() == 'view' ? 100 : 1000],
      pageSize: this.props.mode.toLowerCase() == 'view' ? 20 : 1000,
      sorting: [{ columnName: 'id', direction: 'asc' }],
      totalSummaryItems: [],
      groupingColumns: myGroupingColumns,
      beforeColumnNames: myPinColumns,
      afterColumnNames: [],
      forNumberColumns: myNumberColumns,
      forDateDiffColumns: dateDiffColumns,
      tableSummaryMenuEl: undefined,
      filterMenuData: {},
      tableDistributionMenuEl: undefined,
      tableHasCreationDateColumn: isDistribution,
      forDateColumns: myDateColumn,
      forSpecialTypeColumns: mySpecialTypeColumns,
      mySpecialTypeColumnsFormats: mySpecialTypeColumnsFormats,
    };
  }

  changeCurrentPage = currentPage => {
    const { pageSize, onPaginationChanged } = this.props;
    onPaginationChanged && onPaginationChanged(currentPage, pageSize);
    this.setState({ currentPage });
  };

  changePageSize = pageSize => {
    const { currentPage, onPaginationChanged } = this.props;
    onPaginationChanged && onPaginationChanged(currentPage, pageSize);
    this.setState({ pageSize });
  };

  changeColumnWidths = columnWidths => {
    this.setState({ columnWidths });
  };

  changeSorting = sorting => {
    this.setState({ sorting });
  };
  handleSummaryOpen = event => {
    this.setState({ tableSummaryMenuEl: event.currentTarget });
  };
  handleSummaryClose = () => {
    this.setState({ tableSummaryMenuEl: undefined });
  };
  handleSummaryFunctions = (_funtion, columnName) => {
    let _totalSummaryItems = this.state.totalSummaryItems.slice(0);
    let obj = { columnName: columnName, type: _funtion };
    let v = _totalSummaryItems.find(element => element.columnName === obj.columnName && element.type === obj.type);
    v ? '' : _totalSummaryItems.push(obj);
    this.setState({ totalSummaryItems: _totalSummaryItems });
  };
  handleDistributionOpen = e => {
    this.setState({ tableDistributionMenuEl: e.currentTarget });
  };
  handleDistributionClose = e => {
    this.setState({ tableDistributionMenuEl: undefined });
  };
  getColumnData = col => {
    return (
      this.state.rows.reduce((acc, r) => {
        acc.push(r[col.title]);
        return acc;
      }, []) || []
    );
  };

  CustomHeaderContentComponent = ({ column, align, children, classes, className, ...restProps }) => {
    const activeFilterBtn: boolean =
      this.props.mode.toLowerCase() !== 'view' &&
      (window.location.pathname === '/dashboards/new' || window.location.pathname.includes('/dashboards/edit/'));
    const { mode, onOpenStatics } = this.props;
    const { tableHasCreationDateColumn } = this.state;
    const _classes = this.props.classes;
    return (
      <>
        {' '}
        <div
          className={classNames(
            {
              [_classes.content]: true,
              [_classes.alignCenter]: align === 'center',
              [_classes.alignRight]: align === 'right',
            },
            className,
            'table-content-custom'
          )}
          {...restProps}
        >
          <span className={_classes.headerTitleWrap}>{children}</span>
          {this.props.viewOptions.enableDataZooming && !activeFilterBtn ? (
            <>
              <OiIconButton
                className={classNames(_classes.tableIconBtn, ' table-content-iconBtn')}
                onClick={e => {
                  const sqlColumn = this.props.meta.find(c => {
                    return c.title == column.name;
                  });
                  if (!sqlColumn) {
                    return toastr.warn('Cant get summary for this column');
                  }
                  this.setState({ popoverSummaryCol: sqlColumn, summaryAnchorEl: getAnchorElementFromEvent(e) });
                }}
              >
                <HelpIcon />
              </OiIconButton>

              <ColumnActionsMenu
                classes={_classes}
                mode={mode}
                onSelectOption={option => onOpenStatics(column, option)}
                tableHasCreationDateColumn={tableHasCreationDateColumn}
              />
            </>
          ) : (
            ''
          )}
        </div>
      </>
    );
  };

  CustomTableCell = ({ column, value, children, classes, tableRow, tableColumn, row, className, ...restProps }) => {
    const _classes = this.props.classes;
    return (
      <>
        <TableCellMUI
          className={classNames(
            {
              [_classes.cell]: true,
              [_classes.cellRightAlign]: tableColumn && tableColumn.align === 'right',
              [_classes.cellCenterAlign]: tableColumn && tableColumn.align === 'center',
              [_classes.cellNoWrap]: !(tableColumn && tableColumn.wordWrapEnabled),
            },
            className
          )}
          classes={{ footer: _classes.footer }}
          {...restProps}
          onDoubleClick={e => {
            let obj = {};
            obj[column.name] = row[column.name];
          }}
        >
          {children || value}
        </TableCellMUI>
      </>
    );
  };

  render() {
    const { mode, classes, onPaginationChanged, totalCount: remoteTotalRowsCount, isLoadingQueryNextChunk } = this.props;
    const {
      columns,
      columnExtensions,
      sortingColumnExtensions,
      sorting,
      groupingColumns,
      columnWidths,
      pageSizes,
      pageSize,
      currentPage,
      totalSummaryItems,
      beforeColumnNames,
      afterColumnNames,
      forNumberColumns,
      forDateDiffColumns,
      summaryAnchorEl,
      popoverSummaryCol,
      forDateColumns,
      forSpecialTypeColumns,
      mySpecialTypeColumnsFormats,
    } = this.state;

    let isDashboardDesign =
      mode.toLowerCase() !== 'view' &&
      (window.location.pathname === '/dashboards/new' || window.location.pathname.includes('/dashboards/edit/'));
    return (
      <>
        {popoverSummaryCol && (
          <Dialog open={summaryAnchorEl} onClose={e => this.setState({ summaryAnchorEl: null })}>
            <Paper>
              <Typography variant='subtitle1' style={{ borderBottom: '1px solid #a3a3a3', padding: 10 }}>
                {popoverSummaryCol.title} Column Result Summary
              </Typography>
              <ColumnSummary columnData={this.getColumnData(popoverSummaryCol)} columnMeta={popoverSummaryCol} />
            </Paper>
          </Dialog>
        )}
        <Paper className={classNames('table-wrapper', classes.tableWrapper)} style={{ height: '100%' }} elevation={0}>
          <OiTableGrid style={{ height: '100%', position: 'relative' }} rows={this.props.data} columns={columns}>
            <NumberTypeProvider for={forNumberColumns} />
            <DateTypeProvider for={forDateColumns} />
            <DateDiffTypeProvider for={forDateDiffColumns} />
            <SpecialTypeProvider for={forSpecialTypeColumns} specialFormats={mySpecialTypeColumnsFormats} />

            <SortingState sorting={sorting} onSortingChange={this.changeSorting.bind(this)} />
            <IntegratedSorting columnExtensions={sortingColumnExtensions} />

            <SummaryState totalItems={totalSummaryItems} />
            <IntegratedSummary calculator={(t, r, f) => summaryCalculator(t, this.props.data, f)} />

            <GroupingState grouping={groupingColumns} />
            <IntegratedGrouping />

            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedPaging />

            {/* <Table columnExtensions={columnExtensions} cellComponent={CustomTableCell} /> */}

            <VirtualTable columnExtensions={columnExtensions} cellComponent={this.CustomTableCell} />

            {!isDashboardDesign ? (
              <TableColumnResizing
                minColumn={columnWidths}
                defaultColumnWidths={columnWidths}
                minColumnWidth={180}
                onColumnWidthsChange={this.changeColumnWidths}
              />
            ) : (
              ''
            )}

            {totalSummaryItems && totalSummaryItems.length > 0 ? <TableSummaryRow messages={messages} /> : ''}
            <TableHeaderRow contentComponent={this.CustomHeaderContentComponent} showSortingControls />
            <TableGroupRow />
            <TableFixedColumns beforeColumnNames={beforeColumnNames} afterColumnNames={afterColumnNames} />
            <PagingPanel
              pageSizes={pageSizes}
              totalCount={this.props.totalCount}
              remoteTotalRowsCount={remoteTotalRowsCount}
              isLoadingQueryNextChunk={this.props.isLoadingQueryNextChunk}
              loadNextQueryChunk={this.props.loadNextQueryChunk}
            />
          </OiTableGrid>
        </Paper>
      </>
    );
  }
}

export default withStyles(styles)(CustomEnhancedTableComponent);
