import { withStyles, StyleRulesCallback, createStyles, WithStyles } from '@material-ui/core';

export const styles = () =>
  createStyles({
    cardWidth: {
      width: '400px',
    },
    errorWrapper: {
      display: 'flex',
      padding: '10px 15px',
      justifyContent: 'center',
    },
    transition: {
      WebkitTransition: 'all .5s linear !important',
      transition: 'all .5s linear !important',
    },
  });

export interface styleProps extends WithStyles<typeof styles> {}

const Styled = withStyles(styles);
export default Styled;
