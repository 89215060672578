import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { IProps } from '.';
import paths from './paths';
export const OiIcon = ({ path, className, color, ...props }: IProps) => {
  const scale = props.scale || '24px';
  return (
    <SvgIcon
      className={`oi-Icon`}
      fill={props.fill || undefined}
      style={{
        width: scale,
        height: scale,
        fill: props.fill || undefined,
        fontSize: props.fontSize || scale || '24px',
        color: color || 'inherit',
      }}
      viewBox='0 0 50 50'
      {...props}
    >
      {props.children ? props.children : <path fill={props.fill || undefined} d={paths[path]} />}
    </SvgIcon>
  );
};

export default OiIcon;
