import { Card } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SimpleTable from 'src/components/simple-table';
import { ISimpleTableColumn } from 'src/components/simple-table/models';
import { GDAFilter } from 'src/shared/models/GdaFilterDto';
import GDAListToolbar from './GDAListToolbar';
import { RowActionsCell } from './RowActionsCell';
import oceancoreApis from 'src/shared/apis/oceancoreApis';
import toastr from 'src/components/toastr';
import GDAFormModal from '../GDAFilterForm/GDAFormModal';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import AddIcon from '@material-ui/icons/Add';

interface IProps {}

export default function GDAFiltersList(props: IProps) {
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([] as any[]);

  const [filtersList, setFiltersList] = useState([] as any[]);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [refreshser, setRefreshser] = useState(Math.random());
  const [itemToEdit, setItemToEdit] = useState(undefined as any);

  const onEditItem = item => {
    setItemToEdit(item);
  };

  let filtersData = search.trim()
    ? filtersList.filter(f =>
        [f.username, f.databaseName, f.tableName, f.columnName, f.columnValue]
          .join('')
          .toLowerCase()
          .includes(search)
      )
    : filtersList.slice();

  const columns: ISimpleTableColumn[] = [
    { field: 'username', title: 'User Name' },
    { field: 'databaseName', title: 'Database' },
    { field: 'tableName', title: 'Table' },
    { field: 'columnName', title: 'Column' },
    { field: 'columnValue', title: 'Value' },
    {
      field: 'id',
      title: 'actions',
      cellRenderer: (id, filterItem, column) => (
        <RowActionsCell
          rowItem={filterItem}
          column={column}
          id={id}
          onDelete={handleDeleteSelected.bind({}, filterItem)}
          onEdit={e => onEditItem(filterItem)}
        />
      ),
    },
  ];

  useEffect(() => {
    loadItems();
  }, []);

  const loadItems = () => {
    setIsLoadingFilters(true);
    oceancoreApis
      .getGdaFilters()
      .then(
        filters => setFiltersList(filters),
        err => {
          toastr.error('Error loading GDA filters list');
        }
      )
      .then(e => {
        setIsLoadingFilters(false);
      });
  };

  const handleDeleteSelected = item => {
    item.isLoading = true;
    let _filtersList: any[] = filtersList.slice();
    setRefreshser(Math.random());
    oceancoreApis
      .deleteGdaFilter(item.id)
      .then(
        d => {
          toastr.success('GDA filter deleted successfully');
          loadItems();
          item.isLoading = false;
          setFiltersList(_filtersList);
        },
        err => {
          toastr.error('Failed to delete Gda Filter');
          console.log('Failed to delete Gda Filter', err);
        }
      )
      .then(() => {
        item.isLoading = false;
        setFiltersList(_filtersList);
      });
  };

  const handleSaveItem = (item, index) => {
    loadItems();
    setItemToEdit(undefined);
  };
  return (
    <Card style={{ width: '100%' }}>
      <GDAListToolbar
        search={search}
        handleSearch={setSearch}
        handleDeleteSelected={handleDeleteSelected}
        onAdd={loadItems}
        selectedItems={selected}
      />
      {itemToEdit && (
        <GDAFormModal
          onSave={handleSaveItem}
          itemModel={itemToEdit}
          onCancel={e => setItemToEdit(undefined)}
          autoOpen={Boolean(itemToEdit)}
        />
      )}
      <SimpleTable columns={columns} data={filtersData} showLoading={isLoadingFilters} selectable onSelect={setSelected} />
    </Card>
  );
}
