import * as React from 'react';
import { Tooltip } from '@material-ui/core';

const wrapperStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  fontSize: '20px',
};
export default ({ toolBarStartButtons }) => {
  return (
    <div style={wrapperStyle}>
      Question Deleted
      <div>{toolBarStartButtons}</div>
    </div>
  );
};
