export function getPaginationState(data, paginator) {
  let renderedData = data.slice();
  let defaultPaging = { page: 1, pageSize: 20 };
  let pageOptions = [1, 2, 3, 4, 5, 6, 7];
  if (paginator) {
    let { pageSize, page, total, itemsInPage } = paginator;
    let lastPage = Math.ceil(data.length / pageSize);
    if (lastPage < 7) pageOptions = pageOptions.slice(0, lastPage);
    if (!paginator.isServerRendering) {
      renderedData = data.slice(Math.max(page - 1, 0) * pageSize, Math.min(page, lastPage) * pageSize);
      paginator.itemsInPage = renderedData.length;
    }
    if (page > 5) {
      pageOptions = [];
      for (let i = Math.max(page - 3, 1); i < Math.min(page + 4, lastPage + 1); i++) {
        pageOptions.push(i);
      }
      if (pageOptions.length < 6) {
        if (page > lastPage - 3) {
          // pad right
        }
      }
    }
  }
  return { renderedData, paginator, pageOptions };
}
