import React from 'react';
import { ListItem, ListItemText, Typography, Tooltip } from '@material-ui/core';
import FavIcon from '@material-ui/icons/Star';
import Info from '@material-ui/icons/Info';
import OiIconButton from 'src/components/OiIconButton';

export default function AddQuestionListItem(props) {
  return (
    <ListItem
      onClick={e => props.AddQuestion(props.question)}
      style={{
        display: 'flex',
        justifyContent: 'stretch',
      }}
    >
      <ListItemText
        className='list-text'
        style={{
          flex: 3,
          paddingRight: 0,
        }}
        primary={
          <Typography variant='body1' noWrap>
            {/* <Star fontSize={'18px !important'} nativeColor="#048edd" /> */}
            {props.question.name}
            <span className='flex' />
          </Typography>
        }
      />

      <span
        style={{
          marginRight: 0,
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        {' '}
        <Tooltip title={props.question.description || props.question.name || 'No Description'}>
          <OiIconButton>
            <Info scale='2' />{' '}
          </OiIconButton>
        </Tooltip>
        <FavIcon
          scale='2'
          style={{
            cursor: 'default',
            color: props.isFavoriteQuestions(props.question) ? '#ffeb3b' : '',
          }}
        />
      </span>
    </ListItem>
  );
}
