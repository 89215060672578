import * as React from 'react';
import * as PropTypes from 'prop-types';

import {
  Plugin,
  Getter,
  Action,
  createStateHelper,
  memoize,
  tableColumnsWithWidths,
  tableColumnsWithDraftWidths,
  changeTableColumnWidth,
  draftTableColumnWidth,
  cancelTableColumnWidthDraft,
} from '../../core';

const pluginDependencies = [{ name: 'Table' }];

export class TableColumnResizing extends React.PureComponent {
  render() {
    const { minColumnWidth, ...restProps } = this.props;
    return <TableColumnResizingBase {...restProps} minColumnWidth={minColumnWidth} />;
  }
}
export class TableColumnResizingBase extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      columnWidths: props.columnWidths || props.defaultColumnWidths,
      draftColumnWidths: [],
    };
    const { onColumnWidthsChange, minColumnWidth } = this.props;

    const stateHelper = createStateHelper(this, {
      columnWidths: () => onColumnWidthsChange,
    });

    this.tableColumnsComputed = memoize(columnWidths => ({ tableColumns }) => tableColumnsWithWidths(tableColumns, columnWidths));
    this.tableColumnsDraftComputed = memoize(draftColumnWidths => ({ tableColumns }) =>
      tableColumnsWithDraftWidths(tableColumns, draftColumnWidths)
    );

    this.changeTableColumnWidth = stateHelper.applyReducer.bind(stateHelper, (prevState, payload) =>
      changeTableColumnWidth(prevState, { ...payload, minColumnWidth })
    );
    this.draftTableColumnWidth = stateHelper.applyReducer.bind(stateHelper, (prevState, payload) =>
      draftTableColumnWidth(prevState, { ...payload, minColumnWidth })
    );
    this.cancelTableColumnWidthDraft = stateHelper.applyReducer.bind(stateHelper, cancelTableColumnWidthDraft);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { columnWidths = prevState.columnWidths } = nextProps;

    return {
      columnWidths,
    };
  }

  render() {
    const { columnWidths, draftColumnWidths } = this.state;

    const tableColumnsComputed = this.tableColumnsComputed(columnWidths);
    const tableColumnsDraftComputed = this.tableColumnsDraftComputed(draftColumnWidths);

    return (
      <Plugin name='TableColumnResizing' dependencies={pluginDependencies}>
        <Getter name='tableColumnResizingEnabled' value />
        <Getter name='tableColumns' computed={tableColumnsComputed} />
        <Getter name='tableColumns' computed={tableColumnsDraftComputed} />
        <Action name='changeTableColumnWidth' action={this.changeTableColumnWidth} />
        <Action name='draftTableColumnWidth' action={this.draftTableColumnWidth} />
        <Action name='cancelTableColumnWidthDraft' action={this.cancelTableColumnWidthDraft} />
      </Plugin>
    );
  }
}

TableColumnResizingBase.propTypes = {
  defaultColumnWidths: PropTypes.array,
  columnWidths: PropTypes.array,
  onColumnWidthsChange: PropTypes.func,
  minColumnWidth: PropTypes.number.isRequired,
};

TableColumnResizingBase.defaultProps = {
  defaultColumnWidths: [],
  columnWidths: undefined,
  onColumnWidthsChange: undefined,
};

TableColumnResizing.propTypes = {
  minColumnWidth: PropTypes.number,
};

TableColumnResizing.defaultProps = {
  minColumnWidth: 40,
};
