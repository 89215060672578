import * as React from 'react';
// router
// Styles
import { withStyles } from '@material-ui/core';
import { styles } from './footer.style';
// material components
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';

export interface footerLayoutProps {
  style: any;
}

class FooterComponent extends React.Component<any, footerLayoutProps> {
  render() {
    const { classes } = this.props;
    const year = new Date().getFullYear();
    return (
      <Toolbar
        id='main-footer'
        className={classes.footer}
        style={{
          borderTop: '1px solid rgb(219, 219, 219)',
          position: 'fixed',
          bottom: '0',
        }}
      >
        <Typography style={{ width: '100%' }} variant='subtitle1' align='center' gutterBottom noWrap>
          {/* <strong>Ocean Insight</strong> &copy; 2018 All Rights Reserved */}
          {`Copyright © ${year} IQVIA. All Rights Reserved. Terms & Conditions`}
        </Typography>
      </Toolbar>
    );
  }
}
export default withStyles(styles)(FooterComponent);
