import { withStyles, createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    selectedItem: {
      display: 'flex',
      alignContent: 'center',
      color: '#048edd',
    },
    innerList: {
      paddingLeft: '20px',
    },
    popover: {
      height: '400px',
      width: '350px',
      overflow: 'hidden',
    },
    scrollBar: {
      height: '350px !important',
      width: '350px !important',
    },
    stepHeader: {
      borderBottom: '1px solid #e3e3e3',
      padding: 10,
      display: 'flex',
      height: 40,
      alignContent: 'center',
      color: '#048edd',
      svg: {
        color: '#666b6f',
        cursor: 'pointer',
      },
    },
  });
export default withStyles(styles);
