import { REQUEST, SUCCESS, FAILURE } from 'store/util';
import types from './actionTypes';

interface FavoriteDashboard {
  id: number;
  [k: string]: any;
}

const initialState = {
  favoriteDashboards: [] as Array<FavoriteDashboard>,

  loadingFav: false,
  loadFavSuccess: false,
  loadFavError: false,

  dashboardsList: [] as Array<any>,

  loading: false,
  loadSuccess: false,
  loadError: false,

  createSubmitting: false,
  createSuccess: false,
  createError: false,

  deleteSubmitting: false,
  deleteSuccess: false,
  deleteError: false,
  errorMessage: '' as string,

  tabs: [] as Array<any>,
  selectedTab: 0 as any,
  filters: [] as Array<{ tab: number; filters: Array<any> }>,
};

export type DashboardsState = Readonly<typeof initialState>;
// Reducer
export default (state: DashboardsState = initialState, { type, payload }): DashboardsState => {
  switch (type) {
    //Favorite dashboards
    case REQUEST(types.GET_FAV_DASHBOARDS):
      return {
        ...state,
        loadingFav: true,
      };
    case FAILURE(types.GET_FAV_DASHBOARDS): {
      let response = payload.response;
      return {
        ...initialState,
        errorMessage:
          (payload && payload.fieldErrors) ||
          (response && response && (response.fieldErrors || JSON.stringify(response.messages))) ||
          payload.message,
        loadFavError: true,
        loadFavSuccess: false,
        loadingFav: false,
      };
    }
    case SUCCESS(types.GET_FAV_DASHBOARDS): {
      return {
        ...state,
        loadingFav: false,
        loadFavError: false,
        loadFavSuccess: true,
        favoriteDashboards: payload,
      };
    }

    // dashboards ....
    case REQUEST(types.GET_DASHBOARDS):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(types.GET_DASHBOARDS): {
      let response = payload.response;
      return {
        ...initialState,
        errorMessage:
          (payload && payload.fieldErrors) ||
          (response && response && (response.fieldErrors || JSON.stringify(response.messages))) ||
          payload.message,
        loadError: true,
        loadSuccess: false,
        loading: false,
      };
    }
    case SUCCESS(types.GET_DASHBOARDS): {
      return {
        ...state,
        loading: false,
        loadError: false,
        loadSuccess: true,
        dashboardsList: payload,
      };
    }

    case REQUEST(types.CREATE_DASHBOARD):
      return {
        ...state,
        createSubmitting: true,
      };
    case FAILURE(types.CREATE_DASHBOARD): {
      let response = payload.response;

      return {
        ...initialState,
        createError: true,
        createSuccess: false,
        errorMessage: (response && response.data.messages) || payload.message,
        createSubmitting: false,
      };
    }
    case SUCCESS(types.CREATE_DASHBOARD): {
      return {
        ...state,
        createSubmitting: false,
        createError: false,
        createSuccess: true,
      };
    }

    case REQUEST(types.DELETE_DASHBOARD):
      return {
        ...state,
        deleteSubmitting: true,
      };
    case FAILURE(types.DELETE_DASHBOARD): {
      let response = payload.response;

      return {
        ...initialState,
        deleteError: true,
        deleteSuccess: false,
        errorMessage: (response && response.data.messages) || payload.message,
        deleteSubmitting: false,
      };
    }
    case SUCCESS(types.DELETE_DASHBOARD): {
      return {
        ...state,
        deleteSubmitting: false,
        dashboardsList: state.dashboardsList.filter(d => d.id !== payload.id),
        deleteError: false,
        deleteSuccess: true,
      };
    }

    case types.DASHBOARDS_TABS: {
      let tabs = payload.tabs;
      const tabsIndex = tabs.map(t => t.index);

      let nextFilters = JSON.parse(JSON.stringify(state.filters));

      const _filters = nextFilters.filter(f => tabsIndex.includes(f.tab));

      return {
        ...state,
        tabs,
        filters: _filters,
      };
    }
    case types.CHANGE_SELECTED_TAB: {
      let selectedTab = payload.index;
      return {
        ...state,
        selectedTab,
      };
    }
    case types.ADD_ALL_FILTERS_DASHBOARD: {
      const filters = payload.filters;

      // const shared = sharedFilter(state) ;

      // const checkedFilters =
      //   filters &&
      //   filters.filter(f => {
      //     return shared.findIndex(s => s.dbColumn === f.dbColumn && s.dbTable === f.dbTable) > 0;
      //   });

      return {
        ...state,
        filters: filters,
      };
    }
    case types.ADD_FILTER_DASHBOARD: {
      const newFilter = payload.filter;
      let nextFilters = JSON.parse(JSON.stringify(state.filters));

      const selected = state.selectedTab;
      if (nextFilters.length > 0) {
        const filterByTab = nextFilters.find(f => f.tab === selected);

        if (filterByTab) {
          filterByTab.filters.push(newFilter);
        } else {
          nextFilters.push({
            tab: selected,
            filters: [newFilter],
          });
        }
      } else {
        nextFilters.push({
          tab: selected,
          filters: [newFilter],
        });
      }

      return {
        ...state,
        filters: nextFilters,
      };
    }

    case types.REMOVE_FILTER_DASHBOARD: {
      const filter = payload.filter;

      let filters = JSON.parse(JSON.stringify(state.filters));

      const selected = state.selectedTab;
      const filterByTab = filters.find(f => f.tab === selected);

      const selectedFilters = filterByTab && filterByTab.filters;
      const nextFilters = selectedFilters.filter(f => {
        return f.dbColumn != filter.dbColumn;
      });

      filters.forEach(element => {
        if (element.tab === selected) {
          element.filters = nextFilters;
        }
      });

      return {
        ...state,
        filters: filters,
      };
    }

    case types.RESET: {
      return {
        ...state,
        filters: [],
        tabs: [],
        selectedTab: 0,
      };
    }

    default:
      return state;
  }
};
