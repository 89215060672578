import { connect } from 'react-redux';
import Styled from './Styled';

import { IRootState } from 'src/store/rootReducer';
import { ColumnSelectPopup } from './ColumnSelectPopup';

const mapStateToProps = ({  }: IRootState) => ({});
const mapDispatchToProps = dispatch => ({});

export type StateProps = ReturnType<typeof mapStateToProps>;
export type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const StyledPopup = Styled(ColumnSelectPopup);
const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledPopup);

export default Connected;
