import React from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

export function BooleanValueFilter({ value, onChange }) {
  return (
    <RadioGroup aria-label='' name='booleanField' value={value} onChange={onChange}>
      <FormControlLabel value='1' control={<Radio />} label='True' />
      <FormControlLabel value='0' control={<Radio />} label='False' />
    </RadioGroup>
  );
}
