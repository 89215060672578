import * as React from 'react';

import ControlButton from 'components/ControlButton';
import ColumnSelectList from 'src/modules/questions/studio/components/ColumnSelectList';
import { Scrollbars } from 'react-custom-scrollbars';
import { QueryGroupViewItem } from 'src/modules/questions/studio/models';
import AddGroupObjectsIcon from 'assets/icons/AddGroupObjectsIcon';
import { Menu, MenuItem, Popover, withStyles } from '@material-ui/core';
import { SQLColumn, OceanDataType, ColumnType } from 'src/shared/models/SqlColumn';
import { SQLTable } from 'src/shared/models/SqlTable';
import { OiFunction } from 'src/shared/models/OiFunction';
import { dateGroupingFunctions } from 'src/shared/dataSources/OiFunctions';
import { IProps, IState } from './models';
import Styled from './Styled';
export class AddOiGroupWizard extends React.Component<IProps, IState> {
  state: IState = {
    step: 0,
    anchorEl: undefined,
    isOpen: false,
    dateMenuAnchor: null,
    column: {} as SQLColumn,
  };

  goToNextStep = () => {
    const step = this.state.step;
    this.setState({ step: (step + 1) % 3 });
  };

  goToPrevStep = () => {
    const step = this.state.step;
    this.setState({ step: step - 1 > -1 ? step - 1 : 0 });
  };

  onClosePopove = () => {
    this.setState({ isOpen: false, step: 0 });
  };

  onSelectColumn = (column: SQLColumn, e) => {
    this.props.onSelectColumn && this.props.onSelectColumn(column);
    if (column.oceanDataType === OceanDataType.DATE) {
      this.setState({ dateMenuAnchor: e.target, column });
    } else {
      this.setState({ isOpen: false });
      this.props.onDone &&
        this.props.onDone({
          column: column,
          displayName: column.displayName,
          groupingOperator: OiFunction.NONE,
        });
    }
  };

  handleOnDateOperationSelected = operation => {
    let column = this.state.column;
    this.props.onDone &&
      this.props.onDone({
        column: column,
        displayName: column.displayName + `${operation.displayName ? ` (${operation.displayName})` : ''}`,
        groupingOperator: operation.value,
      });
    this.setState({ isOpen: false });
    this.closeDatePickerMenu();
  };

  closeDatePickerMenu = () => {
    this.setState({ dateMenuAnchor: null });
  };

  render() {
    const props = this.props;
    const classes = props.classes;
    let tables = props.tables;
    const { dateMenuAnchor } = this.state;
    const dateGroupingFunctionMenu = (
      <Menu
        id='lock-menuiiu'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={dateMenuAnchor}
        open={Boolean(dateMenuAnchor)}
        onClose={this.closeDatePickerMenu}
      >
        {dateGroupingFunctions.map((option, index) => (
          <MenuItem key={option.value} onClick={event => this.handleOnDateOperationSelected(option)}>
            {option.displayName}
          </MenuItem>
        ))}
      </Menu>
    );
    return (
      <>
        <div ref='clickRef' onClick={e => this.setState({ isOpen: true, anchorEl: this.refs.clickRef })}>
          {this.props.buttonComponent || (
            <ControlButton title='+ Group' subTitle='' icon={<AddGroupObjectsIcon width='30px' />} borderColor='#d44b00' />
          )}
        </div>
        <Popover
          open={Boolean(this.state.isOpen)}
          anchorEl={this.state.anchorEl}
          onBackdropClick={e => this.setState({ isOpen: false })}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <div className={classes.StepWrapper} key={1}>
            <div className={classes.StepHeader}> Select Column </div>
            <Scrollbars style={{ height: '350px' }}>
              <ColumnSelectList columnTypes={[ColumnType.DIMENSION]} tables={tables} onSelectColumn={this.onSelectColumn} />
            </Scrollbars>
          </div>
        </Popover>
        {dateGroupingFunctionMenu}
      </>
    );
  }
}

export default Styled(AddOiGroupWizard);
