import * as React from 'react';
import moment from 'moment';
import SimpleTable from 'components/simple-table';
import { Card, Toolbar } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import BreadCrumb from 'src/layout/Breadcrumb/BreadCrumb';
import ContentPage from 'src/layout/Breadcrumb/ContentPage';
import databaseAccess from 'src/services/permissions/abilities/database';
import { canDo } from 'src/services/permissions/utils';
import toastr from 'src/components/toastr';
import { ToastMessages } from 'src/constants';
import CustomSearch from 'src/components/CustomSearch';
import ActionButtons from './ActionButtons';
import { RouteComponentProps } from 'react-router-dom';
import { StateProps, DispatchProps } from './container';
import TooltipedIconButton from 'src/components/TooltipedIconButton';
import ShareDataBaseFormModal from 'src/components/ShareDatabaseForm/ShareDatabaseFormModal';
import CanDo from 'src/components/Permissions/Can';

interface IDatabasesProps extends RouteComponentProps, StateProps, DispatchProps {}

interface IDatabasesState {
  search: string;
  data: Array<any>;
  selected: Array<any>;
  dbToShare: any;
  openShareDbModal: boolean;
}

class DatabasesTable extends React.Component<IDatabasesProps, IDatabasesState> {
  state = { search: '', data: [], selected: [], openShareDbModal: false, dbToShare: {} as any } as IDatabasesState;
  columns = [
    { field: 'displayName', title: 'Name' },
    {
      field: 'dbType',
      title: 'type',
      cellRenderer: v => (v === 'monetdb' ? 'Ocean (MT)' : v),
    },
    {
      field: 'createdDate',
      title: 'Creation Date',
      cellRenderer: v => moment(v, moment.defaultFormat).format('MMM DD, YYYY'),
    },
    {
      field: 'id',
      title: 'actions',
      cellRenderer: (id, row) => (
        <ActionButtons
          id={id}
          onEdit={e => this.handleEdit(id)}
          onDelete={e => this.handleDelete(id)}
          onRefresh={e => this.onRefreshDbAnalysis(id)}
          onDataModel={e => this.handleDataModel(id)}
          onShareDatabase={e => this.openShareDbModal(row)}
        />
      ),
    },
  ];

  componentWillMount() {
    this.props.getAllDatabases();
  }
  componentWillUpdate(nextProps) {
    const { databasesList } = this.props;

    if (databasesList !== nextProps.databasesList) {
      this.setState({ data: nextProps.databasesList });
    }
  }

  handleAdd = () => {
    const { history } = this.props;
    history.push('/databases/new');
  };

  handleEdit = id => {
    const { history } = this.props;
    history.push('/databases/edit/' + id);
  };

  handleDataModel = id => {
    const { history } = this.props;
    history.push('/databases/details/' + id);
  };

  openShareDbModal = dbItem => {
    this.setState({
      dbToShare: dbItem,
      openShareDbModal: true,
    });
  };

  handleDelete = id => {
    this.deleteDb(id);
  };

  deleteDb = dbId => {
    const { confirm, deleteDatabase } = this.props;
    confirm({
      msg: 'Are you sure you want to delete this Database ?',
      ok: () => deleteDatabase(dbId),
    });
  };

  handleSearch = e => {
    const keyWord = (e.target.value || '').toLowerCase();
    let _data = this.props.databasesList.slice().filter(value => (value.displayName || '').toLowerCase().includes(keyWord));
    this.setState({ search: e.target.value, data: _data });
  };

  onRowClick = e => {
    const { permissions, roles, history } = this.props;
    if (!canDo(permissions, roles, databaseAccess.view)) {
      toastr.warn(ToastMessages.unAuthorized);
    } else {
      history.push('/databases/details/' + e.row.id);
    }
  };

  handleSelection = selected => {
    this.setState({ selected: selected });
  };

  handleDeleteSelected = () => {
    const { selected, data } = this.state;
    const { confirm, deleteDatabase } = this.props;
    const dbNames = selected.map(id => data.find(d => d.id == id).displayName).join('\n');
    confirm({
      msg: 'Are you sure you want to delete these Databases ?\n' + dbNames,
      ok: () => {
        selected.forEach(dbId => {
          deleteDatabase(parseInt(dbId));
        });
      },
    });
  };

  handleShare = users => {
    const { selected } = this.state;
    const { shareDB } = this.props;

    selected.map(element => parseInt(element));

    shareDB({ usernames: users, dbIds: selected });
  };

  onRefreshDbAnalysis = id => {
    alert('Please Implement Me !!!');
  };

  render() {
    const { data, selected, search } = this.state;
    const { isLoadingList } = this.props;
    return (
      <>
        <BreadCrumb>
          Home <KeyboardArrowRight /> Databases
        </BreadCrumb>
        <ContentPage>
          <Card>
            <Toolbar>
              <CanDo action={databaseAccess.create}>
                <TooltipedIconButton tooltip={'Add New database'} rounded aria-label='Add' onClick={this.handleAdd}>
                  <AddIcon />
                </TooltipedIconButton>
              </CanDo>
              <CanDo action={databaseAccess.delete}>
                <TooltipedIconButton
                  tooltip={'Delete selected databases'}
                  disabled={!(selected.length > 0)}
                  rounded
                  aria-label='remove'
                  onClick={this.handleDeleteSelected}
                >
                  <DeleteIcon />
                </TooltipedIconButton>
              </CanDo>
              <div className='flex' />
              <CustomSearch onChange={this.handleSearch} value={search} placeholder='Search' />
            </Toolbar>
            <SimpleTable
              columns={this.columns}
              data={data}
              showLoading={isLoadingList}
              onRowClick={this.onRowClick}
              selectable
              onSelect={this.handleSelection}
            />
            <ShareDataBaseFormModal
              databaseItem={this.state.dbToShare}
              open={this.state.openShareDbModal}
              shareableUsers={this.props.shareableUsers}
              onClose={e =>
                this.setState({ openShareDbModal: false }, () => {
                  this.setState({ dbToShare: undefined });
                })
              }
            />
          </Card>
        </ContentPage>
      </>
    );
  }
}

export default DatabasesTable;
