import { ExpressionItem, ColumnExpression } from 'src/shared/models/OiQueryColumn';

export enum ExpressionTokenType {
  //   PLAIN_COLUMN,
  EMPTY,
  COLUMN,
  AGGREGATION,
  AGGREGATION_LPAREN,
  AGGREGATION_RPAREN,
  RIGHT_PAREN,
  LEFT_PAREN,
  PLAIN_VALUE,
  OPERATOR,
  NONE,
}

export type TokenizationAutoCompleteMenuType = 'COLUMN' | 'AGGREGATE' | 'NONE';

export function guessExpressionTokenType(textContent, selectionStart) {
  let [startText, endText] = [textContent.substring(0, selectionStart), textContent.substring(selectionStart)];
  const leftParenWtihAggPattern = /\w+\($/g;
  const colAfterAggLeftParenPattern = /\w+\(\w+$/g;
  const aggRightParenPattern = /\w+\(\w*\)$/g;
  const endsWithWordPatern = /\w+$/g;
  const operatorPattern = /[\+\-/\*]$/g;
  const groupRightParenPattern = /[\+\-/\*\)]\)$/g;
  const groupLeftParenPattern = /[\+\-/\*]?\($/g;
  const plainValuePattern = /[\+\-/\*\()](\d+|(\d+\.?\d*))$/g;

  startText = startText.trim();
  if (!startText) {
    return ExpressionTokenType.EMPTY;
  } else if (aggRightParenPattern.test(startText)) {
    return ExpressionTokenType.AGGREGATION_RPAREN;
  } else if (plainValuePattern.test(startText)) {
    return ExpressionTokenType.PLAIN_VALUE;
  } else if (endsWithWordPatern.test(startText)) {
    let match = startText.match(endsWithWordPatern)![0];
    let text = match + endText;
    if (colAfterAggLeftParenPattern.test(startText)) {
      return ExpressionTokenType.COLUMN;
    } else {
      return ExpressionTokenType.AGGREGATION;
    }
  } else if (leftParenWtihAggPattern.test(startText)) {
    return ExpressionTokenType.AGGREGATION_LPAREN;
  } else if (operatorPattern.test(startText)) {
    return ExpressionTokenType.OPERATOR;
  } else if (groupRightParenPattern.test(startText)) {
    return ExpressionTokenType.RIGHT_PAREN;
  } else if (groupLeftParenPattern.test(startText)) {
    return ExpressionTokenType.LEFT_PAREN;
  }
  return ExpressionTokenType.NONE;
}

export function getTokenSuggestion(textContent, selectionStart) {
  let [startText, endText] = [textContent.substring(0, selectionStart), textContent.substring(selectionStart)];
  startText = startText.trim();
  const endsWithWordPatern = /\w+$/g;
  let filterText = endsWithWordPatern.test(startText) ? startText.match(endsWithWordPatern)[0] : '';
  return filterText.toLowerCase();
}

export function getNextAutocompleteMenu(tokenType: ExpressionTokenType): TokenizationAutoCompleteMenuType {
  switch (tokenType) {
    case ExpressionTokenType.COLUMN:
    case ExpressionTokenType.AGGREGATION_LPAREN:
      return 'COLUMN';
    case ExpressionTokenType.EMPTY:
    case ExpressionTokenType.LEFT_PAREN:
    case ExpressionTokenType.OPERATOR:
    case ExpressionTokenType.AGGREGATION:
      return 'AGGREGATE';
    default:
      return 'NONE';
  }
}

export function replaceCurrentWord(sourceText, cursorPosition, replacement) {
  let [startText, endText] = [sourceText.substring(0, cursorPosition), sourceText.substring(cursorPosition)];
  startText = /\w+$/g.test(startText) ? startText.replace(/\w+$/g, replacement) : startText + replacement;
  endText = /^\w+/g.test(endText) ? endText.replace(/^\w+/g, '') : endText;
  return startText + endText;
}

export function computeNextCursorPosition(sourceText, cursorPosition, replacement) {
  let [startText, endText] = [sourceText.substring(0, cursorPosition), sourceText.substring(cursorPosition)];
  startText = /\w+$/g.test(startText) ? startText.replace(/\w+$/g, replacement) : startText + replacement;
  endText = /^\w+/g.test(endText) ? endText.replace(/^\w+/g, '') : endText;
  return startText.length;
}

export function getSuggestionMenuConfigs(textContent, cursorPosition) {
  let [startText, endText] = [textContent.substring(0, cursorPosition), textContent.substring(cursorPosition)];
  let tokenType = guessExpressionTokenType(textContent, cursorPosition);
  let suggestionKeyword = getTokenSuggestion(textContent, cursorPosition);
  let nextMenu = getNextAutocompleteMenu(tokenType);
  startText = startText.toLowerCase().trim();
  let showAggSelect = nextMenu == 'AGGREGATE';
  let showColumnSelect = nextMenu == 'COLUMN';
  if (!showAggSelect && !showColumnSelect) {
    suggestionKeyword = '';
  }
  return {
    suggestionKeyword,
    showAggSelect,
    showColumnSelect,
  };
}

export function expressionTreeToExpressionText(expTree: ExpressionItem): string {
  let type = expTree.t;
  let value = expTree.value as any;
  switch (type) {
    case 'col': {
      let val = value as ColumnExpression;
      if (val.function && val.function !== 'NONE') {
        return `${val.function}(${val.dbColumn})`;
      }
      return `${val.dbColumn}`;
    }
    case 'op':
      return '' + value;
    case 'value':
      return '' + value;
    case 'group':
      let val = value as ExpressionItem[];
      return '' + val.map(expressionTreeToExpressionText).join('');
    default:
      return '' + value;
  }
}

export function getAggrigationTokenSelection(contentText, selectionEnd) {
  var startSelection = selectionEnd - 1;
  var found = false;
  while (!found && startSelection > 0) {
    if (!/[a-z_A-Z]/.test(contentText[startSelection])) {
      if (contentText[startSelection] == '(' && /[a-z_A-Z]/.test(contentText[startSelection - 1])) {
        startSelection -= 2;
      } else {
        found = true;
        startSelection += 1;
        break;
      }
    } else {
      startSelection--;
    }
  }
  found = false;
  while (!found && selectionEnd < contentText.length) {
    if (contentText[selectionEnd] === ')') {
      found = true;
      selectionEnd++;
      break;
    } else {
      selectionEnd++;
    }
  }
  let res = contentText.substring(startSelection, selectionEnd);
  return [Math.max(0, startSelection), Math.min(selectionEnd, contentText.length), res];
}
export function getColumnTokenSelection(contentText, selectionEnd) {
  const regExx = /[a-zA-Z_0-9]/;
  var startSelection = selectionEnd - 1;
  var found = false;
  while (!found && startSelection > 0) {
    if (!regExx.test(contentText[startSelection])) {
      found = true;
      break;
    } else {
      startSelection--;
    }
  }
  found && (startSelection += 1);

  found = false;
  while (!found && selectionEnd < contentText.length) {
    if (!regExx.test(contentText[selectionEnd])) {
      found = true;
      break;
    } else {
      selectionEnd++;
    }
  }
  let res = contentText.substring(startSelection, selectionEnd);
  return [startSelection, selectionEnd, res];
}
